import { Loader, Result } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'
import {
  CastAudioTrack, CastAudioTracksDict, MIXER_CASTER_TRACKS_LIMIT, defaultMixerTrackLabel
} from '@/modules/audio/types'
import { TRACK_PREFIX_CASTER } from '@/types/streams'

const findFirstUnusedTrackLabel = (tracks: CastAudioTracksDict) => {
  for (let i = 1; i <= MIXER_CASTER_TRACKS_LIMIT; ++i) {
    const trackLabel = `${TRACK_PREFIX_CASTER}${i}` as defaultMixerTrackLabel
    if (tracks[trackLabel] === undefined) return trackLabel
  }
  return null
}

// eslint-disable-next-line max-lines-per-function
export function hasAudioTracks<TBase extends StudioBaseMixin> (Base: TBase) {
  return class hasAudioTracksClass extends Base {
    audioTracks: CastAudioTracksDict

    constructor (...arg: any[]) {
      super(...arg)
      this.audioTracks = Loader.loadKeyDictOrEmpty(arg[0], 'audioTracks', (data: unknown) => {
        return new CastAudioTrack(data)
      }, {})
      if (Object.keys(this.audioTracks).length === 0) {
        this.audioTracks.CasterTrack1 = new CastAudioTrack({ name: '0', order: 1 })
      }
    }
    // @ts-ignore
    private __hasAudioTracks: true = true

    get numTracks (): number {
      return Object.keys(this.audioTracks).length
    }

    addCasterTrack (): Result<this, 'invalid_operation'|'not_found'> {
      const currentTrackLabels = Object.keys(this.audioTracks)
      if (currentTrackLabels.length >= MIXER_CASTER_TRACKS_LIMIT) {
        return Result.fail('invalid_operation',
          `Cast already has maximum amount of ${MIXER_CASTER_TRACKS_LIMIT} tracks`)
      }
      const newTrackLabel = findFirstUnusedTrackLabel(this.audioTracks)
      if (newTrackLabel === null) {
        return Result.fail('not_found', 'Failed to find an unused track label')
      }
      const newOrder = currentTrackLabels.length + 1
      this.audioTracks[newTrackLabel] = new CastAudioTrack({ name: `${newOrder - 1}`, order: newOrder })
      return Result.success(this)
    }
  }
}

export type HasAudioTracks = Mixin<typeof hasAudioTracks>

export const hasAudioTracksMixin = (obj: unknown): obj is HasAudioTracks => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasAudioTracks' in obj) return true
  return false
}
