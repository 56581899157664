import { computed } from 'vue'
import store from '@/store'
import { CallTriage } from '@/types/casts'
import { CastParticipantInfo } from '../types'

const requestAddParticipant = async (participant: CastParticipantInfo) => {
  // TODO: Ideally we'd update the triage state and the participants in one combined DB Cast update. But that requires
  //       some refactoring, and also requires changes to the firestore.rules (because they only allow one individual
  //       cast property to be modified at a time by an anonymous user).
  await Promise.all([
    store.dispatch.casts.setCasterTriage({
      castId: participant.castId,
      userId: participant.userId,
      triage: CallTriage.Selftest
    }),
    store.dispatch.casts.addParticipantToCast({
      castId: participant.castId,
      userId: participant.userId,
      participant: { name: participant.name, lastCheckedIn: new Date() }
    })
  ])
}

const permissions = computed(() => store.state.usermedia.permissions)
const hasDeviceError = computed(() => store.state.usermedia.hasDeviceError)
const isAllowedPermissions = computed(() => permissions.value.video && permissions.value.audio)
const isRequestPermissions = computed(() =>
  (permissions.value.video === null || permissions.value.audio === null) && !hasDeviceError.value)

const useCastWelcome = () => {
  return {
    hasDeviceError,
    isAllowedPermissions,
    isRequestPermissions,
    requestAddParticipant
  }
}

export default useCastWelcome
