import { Loader } from 'kiswe-ui'
import { COUNTRIES, OLYMPIC_SPORTS } from 'kiswe-ui'
import { NameConvention } from '@/modules/common/types'
import { getNameConvention } from '@/modules/common/utils/i18n'
import { capitalizeFirstLetter, wrapString } from '@/modules/common/utils/string'
import { CAST_PROPERTY_BOX_MARKER, CAST_PROPERTY_BOX_NAMES } from '@/modules/layouts/types'
import { KeyDict } from '@/types'
import { Asset, AssetType } from '@/types/assets'
import { SceneContent } from '@/types/sceneContent'
import { SceneContentType } from '../types'
import { OLYMPIC_VENUES } from 'kiswe-ui'

const createSceneContentForCastPropertyBox = (boxId: string, boxContent: string): SceneContent => {
  const isImg = wrapString('flag', CAST_PROPERTY_BOX_MARKER) === boxId
  const content = {
    active: true,
    box: boxId,
    id: boxId,
    name: boxId,
    order: 0,
    type: isImg ? AssetType.Graphic : AssetType.SimpleCG,
    contentType: SceneContentType.Content
  }
  if (isImg) return { ...content, url: boxContent }
  else return { ...content, simplecgText: boxContent }
}

export default class Athlete {
  country: string|null = null
  discipline: string|null = null
  firstName: string = ''
  gender: string = ''
  lastName: string = ''
  venue: string|null = null

  constructor (data?: unknown) {
    this.country = Loader.loadStringOrNull(data, 'country', this.country)
    this.discipline = Loader.loadStringOrNull(data, 'discipline', this.discipline)
    this.firstName = Loader.loadString(data, 'firstName', this.firstName)
    this.gender = Loader.loadString(data, 'gender', this.gender)
    this.lastName = Loader.loadString(data, 'lastName', this.lastName)
    this.venue = Loader.loadStringOrNull(data, 'venue', this.venue)
  }

  get fullName (): string {
    const firstName = capitalizeFirstLetter(this.firstName)
    const lastName = this.lastName.toUpperCase()
    if (firstName === '' && lastName === '') return ''
    const nameConvention = getNameConvention(this.country)
    if (nameConvention === NameConvention.Eastern) {
      return `${ lastName } ${ firstName }`
    } else {
      return `${ firstName } ${ lastName }`
    }
  }

  get castPropertyBoxContents (): KeyDict<SceneContent> {
    const contents: KeyDict<SceneContent> = {}
    for (const flatBoxName of CAST_PROPERTY_BOX_NAMES) {
      const boxId = wrapString(flatBoxName, CAST_PROPERTY_BOX_MARKER)
      const boxContent = this.getCastPropertyBoxContent(flatBoxName)
      if (boxContent === '') continue
      contents[boxId] = createSceneContentForCastPropertyBox(boxId, boxContent)
    }
    return contents
  }

  get castPropertyBoxAssets (): KeyDict<Asset> {
    const assets: KeyDict<Asset> = {}
    Object.values(this.castPropertyBoxContents)
      .filter((content) => content.type === AssetType.Graphic)
      .forEach((content) => {
        assets[content.id] = new Asset({
          id: content.id,
          name: content.name,
          type: AssetType.Graphic,
          active: content.active,
          url: content.url,
          team_id: '',
          asset_group: ''
        })
      })
    return assets
  }

  // eslint-disable-next-line complexity
  private getCastPropertyBoxContent (flatBoxName: string): string {
    switch (flatBoxName) {
      case 'name': return this.fullName
      case 'tag': return this.country ?? ''
      case 'discipline': return this.getDataByCode(OLYMPIC_SPORTS, this.discipline)?.name ?? ''
      case 'flag': return this.getDataByCode(COUNTRIES, this.country)?.flagUrl ?? ''
      case 'venue': return this.getDataByCode(OLYMPIC_VENUES, this.venue)?.name ?? ''
      default: return ''
    }
  }

  private getDataByCode (data: KeyDict<any>, code: string|null) {
    return Object.values(data).find((item) => item.code === code) ?? null
  }
}
