import { Loader, Result } from 'kiswe-ui'
import { ClassType } from '@/modules/classBuilder/mixins/base'
import { CastType } from '@/types/casts'
import { CastTemplateSwitcher } from './casttemplates/CastTemplateSwitcher'
import { CastTemplateFanRoom } from './casttemplates/CastTemplateFanRoom'
import { CastTemplateStationSwitcher } from './casttemplates/CastTemplateStationSwitcher'


export type CastTemplate = ClassType<typeof CastTemplateFanRoom> |
                           ClassType<typeof CastTemplateSwitcher> |
                           ClassType<typeof CastTemplateStationSwitcher>

export const CastTemplate = {
  load (data: unknown): Result<CastTemplate, 'invalid params'|'unknown'> {
    try {
      const castType = Loader.loadString(data, 'castType', CastType.Switcher) as CastType
      switch (castType) {
        case CastType.Switcher:
          return Result.success(new CastTemplateSwitcher(data))
        case CastType.FanRoom:
          return Result.success(new CastTemplateFanRoom(data))
        case CastType.StationSwitcher:
          return Result.success(new CastTemplateStationSwitcher(data))
        default:
          //@ts-ignore
          return Result.fail('unknown', `Unknown CastType: ${castType} in cast: ${data.id ?? 'missing_id'}`)
      }
    } catch (error) {
      return Result.fromUnknownError('invalid params', error)
    }
  }
}
