<script lang="ts">
import { VideoObjectNetworkStats } from '@/classes/VideoObjectNetworkStats'
import { VideoCodec } from '@/modules/usermedia/types'
import { defineComponent, h, ref } from 'vue'
import WhipClient, { WhipClientJanusOptions } from '@/classes/Janus/WhipClient'
import NetworkStatsFetcher from '@/modules/usermedia/classes/NetworkStatsFetcher'
import { useAddLog } from '@/modules/logging/compositions'
import { useTranscodingRegions } from '@/modules/transcodingregions/compositions'
import env from '@/env'
import { IceTransport } from '@/modules/janus/classes/IceCandidateEntry'


export default defineComponent({
  name: 'JanusCasterCommunication',
  props: {
    streamToPlay: {
      type: String,
      required: true
    },
    wrtcServer: {
      type: String,
      required: true
    },
    localStream: {
      type: MediaStream,
      required: true
    },
    onAir: {
      type: Boolean,
      required: true
    },
    videoBitrate: {
      type: Number,
      required: false,
      default: 320
    },
    audioBitrate: {
      type: Number,
      required: false,
      default: 32
    },
    udpEnabled: {
      type: Boolean,
      default: false
    },
    videoCodec: {
      type: String as () => VideoCodec,
      default: VideoCodec.VP8
    },
    enableBandwidthCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    timeoutNoData: {
      type: Number,
      required: false,
      default: 10000
    }
  },
  setup () {
    const { addLogNoWait } = useAddLog()
    return {
      addLogNoWait,
      lastBytesSent: 0,
      lastBytesSentTime: Date.now(),
      lastNetworkStatsEmitted: Date.now(),
      networkStats: new VideoObjectNetworkStats(),
      networkStatsFetcher: null as NetworkStatsFetcher|null,
      statsInterval: null as NodeJS.Timeout|null,
      stopping: false,
      ...useTranscodingRegions(),
      whip: ref<WhipClient|null>(null)
    }
  },
  render () {
    return h('div')
  },
  async mounted () {
    await this.restartStream()
  },
  created () {
    if (this.enableBandwidthCheck) this.initBandwidthCheck()
  },
  async beforeUnmount () {
    await this.stopCommunication()
  },
  watch: {
    async localStream () {
      if (this.localStream && this.whip && this.onAir) {
        await this.restartStream()
      }
    },
    async onAir (newVal) {
      if (!newVal) {
        await this.stopCommunication()
      }
    }
  },
  methods: {
    async publish () {
      if (this.whip !== null) {
        console.error('Should not publish before cleaning up previous WhipClient')
        return
      }
      this.stopping = false
      const url = `https://${this.wrtcServer}/whip/endpoint/${this.streamToPlay}`
      const options: Partial<WhipClientJanusOptions> = {
        video_bitrate: this.videoBitrate,
        audio_bitrate: this.audioBitrate,
        force_protocol: this.udpEnabled ? IceTransport.UDP : IceTransport.TCP,
        closeCallback: () => this.onConnectionClosed()
      }
      if (this.myRegion?.chinaProxy === true && env.chinaIceCandidateList.length > 0) {
        options.chinaIceCandidatesIpList = env.chinaIceCandidateList
      }
      this.whip = new WhipClient(options)
      const hostname = this.wrtcServer
      const iceConfiguration: RTCConfiguration = {
        iceTransportPolicy: 'all',
        iceServers: [{
          urls: [`turn:${hostname}:3478?transport=tcp`],
          username: 'janususer',
          credential: 'password'
        }, {
          urls: [`turn:${hostname}:80?transport=tcp`],
          username: 'janususer',
          credential: 'password'
        }]
      }
      const peerConnection = new RTCPeerConnection(iceConfiguration)
      for (const track of this.localStream.getTracks()) {
        peerConnection.addTrack(track)
      }
      const token = null
      try {
        await this.whip.cleanup(url, token)
        await this.whip.publish(peerConnection, url, token)
        if (this.statsInterval) clearInterval(this.statsInterval)
        this.statsInterval = setInterval(() => { this.updateStats() }, 1000)
        this.lastBytesSentTime = Date.now()
        this.lastBytesSent = 0
      } catch (error) {
        if (this.statsInterval) {
          clearInterval(this.statsInterval)
          this.statsInterval = null
        }
        console.error('Janus Caster Communication errors', error)
      }
    },
    async restartStream () {
      await this.stopCommunication()
      this.networkStats = new VideoObjectNetworkStats()
      await this.publish()
    },
    async stopCommunication () {
      this.stopping = true
      if (this.whip !== null) await this.whip.stop()
      this.whip = null
      if (this.statsInterval) clearInterval(this.statsInterval)
      this.statsInterval = null
    },
    async updateStats () {
      const peerConnection = this.whip?.peerConnection
      if (peerConnection) {
        try {
          const statsReport = await peerConnection.getStats(null)
          this.parseStats(statsReport)
          if (this.lastNetworkStatsEmitted < Date.now() - 5000) {
            this.lastNetworkStatsEmitted = Date.now()
            this.$emit('onNetworkStats', this.networkStats)
          }
        } catch (error) {
          console.error('CasterCommunication.updateStats error ', error)
        }
        this.statsWatchDog()
      }
    },
    parseStats (report: RTCStatsReport) {
      if (!this.networkStats) return
      for (const item of report.values()) {
        this.networkStats.updateWithStats(item)
      }
    },
    statsWatchDog () {
      if (!this.networkStats || this.stopping) return
      const bytesSent = this.networkStats.bytesSent || this.networkStats.__lastVideoBytesSent || 0
      const hasNewBytesSent = this.lastBytesSent !== bytesSent
      this.lastBytesSent = bytesSent
      const now = Date.now()
      if (hasNewBytesSent) {
        this.lastBytesSentTime = now
      } else if (now - this.lastBytesSentTime > this.timeoutNoData) {
        const warningMessage = `No new data sent for ${this.timeoutNoData}ms. Restarting ${this.streamToPlay}`
        this.addLogNoWait(warningMessage, 'warning')
        console.warn(warningMessage)
        this.restartStream()
      }
    },
    initBandwidthCheck () {
      this.networkStatsFetcher = new NetworkStatsFetcher(this.wrtcServer, (networkStats: VideoObjectNetworkStats) => {
        if (networkStats.download) {
          this.networkStats.download  = networkStats.download
        }
        if (networkStats.upload) {
          this.networkStats.upload = networkStats.upload
        }
        this.$emit('onNetworkStats', networkStats)
      })
      this.networkStatsFetcher.startFetching()
    },
    onConnectionClosed () {
      if (this.stopping) return
      const errorMessage = `Connection closed unexpectedly. Restarting ${this.streamToPlay}`
      this.addLogNoWait(errorMessage, 'error')
      console.error(errorMessage)
      this.restartStream()
    }
  }
})
</script>
