import { KeyDict } from '../types'
import { SceneContent } from '../types/sceneContent'
import { AssetType } from '../types/assets'
import { Loader, Result } from 'kiswe-ui'
import { TwitterStyling } from '@/modules/layouts/classes'
import { BoxStyling } from '@/modules/layouts/classes/BoxStyling'
import { BoxStylingBase } from '@/modules/layouts/classes/BoxStylingBase'
import { BoxStylingSimpleCg } from '@/modules/layouts/classes/BoxStylingSimpleCg'

export class CasterCSS {
  color: string = '#FFFFFF'
  fontFamily?: string = '' // allow fallback when previewing
  fontSize: string = ''
  labelTop?: number = 0
  labelVisible: boolean = false
  muted: boolean = false

  constructor (data?: unknown) {
    if (data === undefined) return
    this.color = Loader.loadString(data, 'color', this.color)
    this.fontFamily = Loader.loadString(data, 'fontFamily', this.fontFamily)
    this.fontSize = Loader.loadString(data, 'fontSize', this.fontSize)
    this.labelTop = Loader.loadNumber(data, 'labelTop', this.labelTop)
    this.labelVisible = Loader.loadBoolean(data, 'labelVisible', this.labelVisible)
    this.muted = Loader.loadBoolean(data, 'muted', this.muted)
  }
}

export class ChatUserCSS {
  bg: string = '#000'
  text: string = '#fff'
}

export class ChatCSS {
  general: {
    font: string,
    fontsize: number
  } = {
    font: 'Helvetica',
    fontsize: 6
  }
  user1: ChatUserCSS = new ChatUserCSS()
  user2: ChatUserCSS = new ChatUserCSS()
  user3: ChatUserCSS = new ChatUserCSS()
  user4: ChatUserCSS = new ChatUserCSS()

  constructor (data?: unknown) {
    if (data === undefined) return
    this.general = Loader.loadAndCast(data, 'general', this.general)
  }
}

export class SimplecgCSS {
  background: {
    borderradius: number,
    color: string
  } = {
    borderradius: 3,
    color: '#818181'
  }
  text: {
    color: string,
    font: string,
    fontsize: number,
    left: number,
    ltr: boolean,
    top: number,
    width: number
  } = {
    color: 'rgba(255,255,255,1)',
    font: 'Noto Sans',
    fontsize: 16,
    left: 10,
    top: 40,
    ltr: true,
    width: 80
  }
}


export enum BoxGroupType {
  Front = 'front',
  Caster = 'caster',
  Back = 'back'
}

export enum DemoSize {
  Small = 'small',
  Large = 'large',
  Poll = 'poll'
}


export class LiveChatCSS {
  background: {
    borderradius?: number,
    color?: string,
  } = {}
  username: {
    color?: string,
    left?: number,
    top?: number,
    font?: string,
    fontsize?: number
  } = {}
  text: {
    color?: string,
    font?: string,
    fontsize?: number,
    padding?: number,
    left?: number,
    top?: number,
    visible?: boolean
  } = {}

  constructor (data?: unknown) {
    if (data === undefined) return
    this.background = Loader.loadAndCast(data, 'background', this.background)
    this.username = Loader.loadAndCast(data, 'username', this.username)
    this.text = Loader.loadAndCast(data, 'text', this.text)
  }
}

export interface SceneTransition {
  type: TransitionType,
  duration?: number,
  actions: SceneTransitionAction[]
}

export interface SceneTransitionAction {
  type: string,
  method: string,
  url: string,
  body: string,
  token: string,
  offsetDelay: number
}

export class Layout {
  id?: string
  name: string = ''
  info: string = ''
  // Layout contents have same interface as scene contents
  contents?: KeyDict<SceneContent>
  boxes: KeyDict<LayoutBox> = {}
  editable?: boolean
  existingLayout?: Omit<Layout, 'existingLayout'>
  transition: SceneTransition = { type: TransitionType.NONE, actions: [] }
  castercss: CasterCSS = new CasterCSS()
  chatcss: ChatCSS = new ChatCSS()
  tweetcss: TwitterStyling = new TwitterStyling()
  livechatcss: LiveChatCSS = new LiveChatCSS()
  /** @deprecated, use styling object inside a LayoutBox instead */
  simplecgcss: KeyDict<SimplecgCSS> = {} //Stores individual box stylings, unlike the others
  clips: { automute: boolean } = { automute: false }
  team_id: string = ''

  constructor (data?: unknown) {
    if (data === undefined) return
    const id = Loader.loadOptionalString(data, 'id')
    if (id !== undefined) this.id = id
    this.name = Loader.loadString(data, 'name', this.name)
    this.info = Loader.loadString(data, 'info', this.info)
    const contents = Loader.loadAndCast<KeyDict<SceneContent>>(data, 'contents', {})
    if (contents !== undefined) this.contents = contents
    this.boxes = Loader.loadKeyDict(data, 'boxes', (data) => new LayoutBox(data), this.boxes)
    const editable = Loader.loadOptionalBoolean(data, 'editable')
    if (editable !== undefined) this.editable = editable
    const existingLayout = Loader.loadOptionalClass(data, 'existingLayout', (data) => new Layout(data))
    if (existingLayout !== undefined) this.existingLayout = existingLayout
    this.transition = Loader.loadAndCast<SceneTransition>(data, 'transition', this.transition)
    this.castercss = Loader.loadClass(data, 'castercss', (data) => new CasterCSS(data), this.castercss)
    this.chatcss = Loader.loadClass(data, 'chatcss', (data) => new ChatCSS(data), this.chatcss)
    this.tweetcss = Loader.loadClass(data, 'tweetcss', (data) => new TwitterStyling(data), this.tweetcss)
    this.livechatcss = Loader.loadClass(data, 'livechatcss', (data) => new LiveChatCSS(data), this.livechatcss)
    this.simplecgcss = Loader.loadKeyDict(data, 'simplecgcss', (data: unknown) => data as SimplecgCSS, this.simplecgcss)
    if (Object.values(this.simplecgcss).length && Object.values(this.boxes).length) {
      Object.keys(this.simplecgcss).forEach((id) => {
        const box = Object.values(this.boxes).find((box) => box.id === id)
        if (box) {
          const simpleCgStyling = BoxStylingSimpleCg.mapSimpleCgCssToBoxStyling(this.simplecgcss[box.id], this.boxes[box.id].styling)
          this.boxes[box.id].styling = simpleCgStyling
        }
      })
    }
    this.clips = Loader.loadAndCast<{ automute: boolean }>(data, 'clips', this.clips)
    this.team_id = Loader.loadString(data, 'team_id', this.team_id)
  }

  static load (data: unknown): Result<Layout, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new Layout(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  static create (params: Pick<Layout, 'name'|'team_id'>): Result<Layout, 'invalid_params'> {
    const layout: Layout = new Layout({
      name: params.name,
      info: '',
      boxes: {},
      transition: {
        type: 'none',
        duration: 0,
        actions: []
      },
      castercss: new CasterCSS(),
      chatcss: new ChatCSS(),
      tweetcss: new TwitterStyling(),
      livechatcss: new LiveChatCSS(),
      simplecgcss: {},
      clips: {
        automute: false
      },
      team_id: params.team_id
    })
    return Result.success(layout)
  }
}

export interface LayoutBoxMap {
  [boxid: string]: LayoutBox
}

export enum LayoutBoxField {
  FRONT = 'front',
  BACK = 'back',
  CASTER = 'caster',
  TRANSITION = 'transition'
}

export enum LayoutBoxType {
  BOX = 'box',
  CONTAINER = 'container'
}

export enum ContentAlignment {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end'
}

export enum TransitionType {
  NONE = 'cut',
  TRANSLATE = 'translate',
  VIDEO = 'video'
}

export enum HttpRequestMethod {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete'
}

export class LayoutBox {
  active?: boolean
  autoActive?: boolean
  autoMute?: boolean
  boundContentSceneId?: string|null = null
  boxes?: KeyDict<Omit<LayoutBox, 'boxes'>>
  cascade?: boolean
  dedicated?: boolean
  field?: LayoutBoxField
  id: string = ''
  invisible?: boolean
  name: string = ''
  parent?: string
  status?: boolean
  stretchContent?: boolean
  stretchVertical?: boolean
  type: LayoutBoxType = LayoutBoxType.BOX
  types?: AssetType[] = [AssetType.Graphic]
  zindex: number = 900
  // DEPRECATED (?)
  'horizontal-align'?: string = ''
  'vertical-align'?: string = ''
  styling: BoxStyling = new BoxStylingBase()

  constructor (data?: unknown) {
    if (data === undefined) return
    const active = Loader.loadOptionalBoolean(data, 'active')
    if (active !== undefined) this.active = active
    const autoActive = Loader.loadOptionalBoolean(data, 'autoActive')
    if (autoActive !== undefined) this.autoActive = autoActive
    const autoMute = Loader.loadOptionalBoolean(data, 'autoMute')
    if (autoMute !== undefined) this.autoMute = autoMute
    this.boundContentSceneId = Loader.loadStringOrNull(data, 'boundContentSceneId', this.boundContentSceneId)
    this.boxes = Loader.loadKeyDictOrEmpty(data, 'boxes', (data: unknown) => new LayoutBox(data), {})
    const cascade = Loader.loadOptionalBoolean(data, 'cascade')
    if (cascade !== undefined) this.cascade = cascade
    const dedicated = Loader.loadOptionalBoolean(data, 'dedicated')
    if (dedicated !== undefined) this.dedicated = dedicated
    const field = Loader.loadOptionalEnum(data, 'field', Object.values(LayoutBoxField))
    if (field !== undefined) this.field = field
    this.id = Loader.loadString(data, 'id', this.id)
    const invisible = Loader.loadOptionalBoolean(data, 'invisible')
    if (invisible !== undefined) this.invisible = invisible
    this.name = Loader.loadString(data, 'name', this.name)
    const parent = Loader.loadOptionalString(data, 'parent')
    if (parent !== undefined) this.parent = parent
    const status = Loader.loadOptionalBoolean(data, 'status')
    if (status !== undefined) this.status = status
    const stretchContent = Loader.loadOptionalBoolean(data, 'stretchContent')
    if (stretchContent !== undefined) this.stretchContent = stretchContent
    const stretchVertical = Loader.loadOptionalBoolean(data, 'stretchVertical')
    if (stretchVertical !== undefined) this.stretchVertical = stretchVertical
    this.type = Loader.loadEnum(data, 'type', LayoutBoxType.BOX, Object.values(LayoutBoxType))
    const types = Loader.loadOptionalArray<AssetType>(data, 'types')
    if (types !== undefined) this.types = types
    this.zindex = Loader.loadNumber(data, 'zindex', this.zindex)

    if (typeof data === 'object' && data !== null && 'styling' in data) {
      this.styling = Loader.loadClass<BoxStyling>(data, 'styling', (data: unknown) => {
        return isAssetTypeVideoOrText(this.types) ? new BoxStylingSimpleCg(data) : new BoxStylingBase(data)
      })
    } else {
      this.styling = BoxStylingSimpleCg.getBoxStylingFromLegacyLayoutBox(data, this.types)
    }
  }
}

export const isAssetTypeVideoOrText = (types: AssetType[]|undefined) => {
  return types && [AssetType.SimpleCG, AssetType.Video].includes(types[0])
}

export enum LayoutBoxOptionType {
  BOOLEAN = 'boolean',
  COLOR = 'color',
  FONT = 'font',
  NUMBER = 'number',
}

export interface LayoutBoxOption {
  name: string,
  title?: string,
  type: LayoutBoxOptionType,
  value: any,
  min?: number,
  max?: number,
}

export enum LayoutPreviewMode {
  BoxFields = 'fields',
  StreamsOnly = 'streams'
}

export const LAYOUT_BOX_Z_INDEX: number = 1
