import studio from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CollectionNames } from '@/modules/database/utils'
import { AssetType } from '@/types/assets'
import { Scene } from '@/types/scenes'
import { cloneDeep } from 'lodash'

export const copyAssetToTeam = async (assetId: string, teamId: string): Promise<Result<string, 'database'>> => {
  const asset = await studio.db.collection(CollectionNames.ASSETS).doc(assetId).get()
  if (!asset.isSuccess) return asset.convert()
  if (asset.value.team_id === teamId) return Result.success(assetId) // Asset already in new team.
  let newId = assetId.replace(/_team_[^_]*/, '') // Note: Assumes that `teamId` will never include a '_'
  newId = `${newId}_team_${teamId}`
  asset.value.id = newId
  asset.value.team_id = teamId
  const result = await studio.db.collection(CollectionNames.ASSETS).doc(newId).set(asset.value, MergeOption.OVERWRITE)
  if (!result.isSuccess) return result.convert()
  return Result.success(newId)
}

export const copyScenesAssetsToTeam = async (scenes: KeyDict<Scene>, teamId: string) => {
  const promises = []
  for (const scene of Object.values(scenes)) {
    for (const content of Object.values(scene.contents ?? {})) {
      if (![AssetType.Graphic, AssetType.Widget].includes(content.type)) continue
      promises.push((async () => {
        const oldId = content.id
        const newId = await copyAssetToTeam(oldId, teamId)
        if (!newId.isSuccess) {
          newId.log(`copyScenesAssetsToTeam, could not copy asset: ${oldId} to other team: ${teamId}`)
          return
        }
        const newContent = cloneDeep(content)
        newContent.id = newId.value
        if (scene.contents === undefined) scene.contents = {}
        scene.contents[newId.value] = newContent
        if (newId.value !== oldId) delete scene.contents[oldId]
      })())
    }
  }
  await Promise.all(promises)
  return scenes
}
