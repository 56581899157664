import store from '@/store'
import { KeyDict } from '@/types'

const getMaxVolume = (databuffer: Float32Array): number => {
  const length = databuffer.length
  let maxv = databuffer[0]
  for (let i = 10; i < length; i += 10) {
    const v = databuffer[i]
    if (v > maxv) {
      maxv = v
    }
  }
  return maxv
}

const onaudioprocess = (streamId: string) => (event: AudioProcessingEvent) => {
  const numChannels = event.inputBuffer.numberOfChannels
  const channelVolumes: number[] = []
  for (let i = 0; i < numChannels; i++) {
    const channelData = event.inputBuffer.getChannelData(i)
    channelVolumes.push(getMaxVolume(channelData))
  }
  store.dispatch.streams.updateVuMeter({ streamId, channelVolumes }).catch((error) => {
    console.error('error updating vumeter', error)
  })
}

export const vumeterSubscriptionCount: KeyDict<number> = {}
const vuMeterContext: KeyDict<{
  context: AudioContext,
  source: MediaStream,
  mic: MediaStreamAudioSourceNode,
  script: ScriptProcessorNode
}> = {}
const cleanupVuMeter = (streamId: string) => {
  vuMeterContext[streamId].mic.disconnect()
  vuMeterContext[streamId].script.disconnect()
  vuMeterContext[streamId].script.onaudioprocess = null
  vuMeterContext[streamId].context.close()
  delete vuMeterContext[streamId]
  store.commit.streams.removeVuMeter(streamId)
}

let timeout: NodeJS.Timeout | null = null
const regularCheckStreamsVuMeters = (intervalInSeconds: number) => {
  if (timeout !== null) {
    clearTimeout(timeout)
    timeout = null
  }
  if (Object.keys(vumeterSubscriptionCount).length === 0) {
    return
  }
  timeout = setTimeout(() => {
    checkStreamsVuMeters()
    regularCheckStreamsVuMeters(intervalInSeconds)
  }, intervalInSeconds)
}

export const checkStreamsVuMeters = () => {
  regularCheckStreamsVuMeters(15000)
  Object.keys(vumeterSubscriptionCount).forEach((streamId) => {
    const stream = store.state.streams.castStreams[streamId]
    if (vuMeterContext[streamId] && stream?.srcObject !== vuMeterContext[streamId].source) {
      cleanupVuMeter(streamId)
    }
    if (!vuMeterContext[streamId] && stream?.srcObject) {
      const context = new window.AudioContext()
      const script = context.createScriptProcessor(2048, 2, 1)
      const mic = context.createMediaStreamSource(stream.srcObject)
      if (mic && script) {
        mic.connect(script)
        script.connect(context.destination)
        script.onaudioprocess = onaudioprocess(streamId)
        vuMeterContext[streamId] = { context, source: stream.srcObject, script, mic  }
      }
    }
  })
  const cleanUp = Object.keys(vuMeterContext).filter((streamId) => !vumeterSubscriptionCount[streamId])
  for (const streamId of cleanUp) {
    cleanupVuMeter(streamId)
  }
}
