import { Loader, Result } from 'kiswe-ui'
import { MAX_AUTO_SWITCH_GUESTS } from '../types'
import Athlete from './Athlete'

const emptyAutoSwitchSceneIds = [...(new Array(MAX_AUTO_SWITCH_GUESTS + 1))].map(() => null)

export default class ScenesSettings {
  isAutoSwitchEnabled: boolean = false
  isAutoCasterBoxEnabled: boolean = false
  isAutoAcceptGuestsEnabled: boolean = true
  autoSwitchSceneIds: (string|null)[] = emptyAutoSwitchSceneIds
  athlete: Athlete = new Athlete()
  isBackupStreamsEnabled: boolean = false

  constructor (data?: unknown) {
    if (!data) return
    this.isAutoSwitchEnabled = Loader.loadBoolean(data, 'isAutoSwitchEnabled', this.isAutoSwitchEnabled)
    this.isAutoCasterBoxEnabled = Loader.loadBoolean(data, 'isAutoCasterBoxEnabled', this.isAutoCasterBoxEnabled)
    this.isAutoAcceptGuestsEnabled = Loader.loadBoolean(
      data, 'isAutoAcceptGuestsEnabled', this.isAutoAcceptGuestsEnabled)
    this.autoSwitchSceneIds = Loader.loadArray(data, 'autoSwitchSceneIds', this.autoSwitchSceneIds)
    this.athlete = Loader.loadClass(data, 'athlete', (props) => new Athlete(props), this.athlete)

    if (this.autoSwitchSceneIds.length !== emptyAutoSwitchSceneIds.length) {
      this.autoSwitchSceneIds = emptyAutoSwitchSceneIds.map((x, i) => this.autoSwitchSceneIds[i] ?? x)
    }
    this.isBackupStreamsEnabled = Loader.loadBoolean(data, 'isBackupStreamsEnabled', this.isBackupStreamsEnabled)
  }

  toggleAutoSwitch (isEnabled: boolean) {
    this.isAutoSwitchEnabled = isEnabled
    return this
  }

  toggleAutoCasterBox (isEnabled: boolean) {
    this.isAutoCasterBoxEnabled = isEnabled
    return this
  }

  toggleAutoAcceptGuests (isEnabled: boolean) {
    this.isAutoAcceptGuestsEnabled = isEnabled
    return this
  }

  setAutoSwitchSceneId (index: number, sceneId: string|null): Result<this, 'invalid_index'> {
    if (index < 0 || index > MAX_AUTO_SWITCH_GUESTS + 1) {
      return Result.fail('invalid_index', `Invalid index [${ index }] for scene [${ sceneId }].`)
    }
    this.autoSwitchSceneIds[index] = sceneId
    return Result.success(this)
  }

  toggleUseBackupStreams (isEnabled: boolean) {
    this.isBackupStreamsEnabled = isEnabled
    return this
  }
}
