import { ChatState, RootState } from '@/store/types'
import { ActionContext } from 'vuex'
import { CollectionNames } from '@/modules/database/utils/collectionNames'
import fb from '@/firebase'
import store, { moduleActionContext } from '@/store'
import { CastChatMessage } from '@/modules/chat/classes'
import { defineModule } from 'direct-vuex'
import studio from '@/modules/database/utils'
import { FirestoreQuerySnapper, FirestoreSnapperType,
         QuerySnapShotWithParams } from '@/modules/database/utils/databaseQuerySubscriptions'

type ChatContext = ActionContext<ChatState, RootState>

const chatModule = defineModule({
  namespaced: true,
  state: {
    chatLastSeen: 0, // TODO Currently this is only assumed to be for the the "currentCast".
    chatOpen: false, // TODO Currently this is only assumed to be for the the "currentCast".
    messages: {}
  } as ChatState,
  getters: {
    totalNewMessages: (state: ChatState) => (chatId: string, targetUserId: string|null = null): number => {
      const messages = state.messages[chatId] ?? []
      const currentUserId = store.state.user.id ?? ''
      if (messages.length === 0) return 0

      return messages.reduce((total, msg) => {
        const isMsgVisible = targetUserId ? msg.isVisible([currentUserId, targetUserId]) : msg.isPublic
        const isNew = isMsgVisible &&
          msg.timestamp?.seconds &&
          msg.user_id !== currentUserId &&
          (msg.timestamp.seconds * 1000 + msg.timestamp.nanoseconds/(1000*1000)) > state.chatLastSeen
        return isNew ? total + 1 : total
      }, 0)
    }
  },
  actions: {
    async subscribeChatMessages (context: ChatContext, chatId: string) {
      if (chatId === '') throw new Error('Cannot subscribe to empty chat id')
      const { commit } = chatModuleActionContext(context)

      const key = `chat_${chatId}`
      const snapper: FirestoreQuerySnapper = {
        query: fb.db.collection(CollectionNames.CHATS).doc(chatId).collection('messages'),
        mutation: commit.updateChatMessages,
        type: FirestoreSnapperType.Query,
        params: { chatId }
      }
      const waitForIt = true
      await studio.subscriptions.subscribeFB(key, snapper, undefined, waitForIt)
    },
    unsubscribeChatMessages (context: ChatContext, chatId: string ) {
      if (chatId === '') throw new Error('Cannot unsubscribe from empty chat id')
      const { commit } = chatModuleActionContext(context)

      const key = `chat_${chatId}`
      studio.subscriptions.unsubscribeFB(key, () => {
        commit.clearChatMessages(chatId)
      })
    },
    async sendMessage (_context: ChatContext, payload: { chatId: string, msg: Partial<CastChatMessage> }) {
      const { chatId, msg } = payload
      try {
        await fb.db.collection(CollectionNames.CHATS).doc(chatId).collection('messages').add({
          ...msg,
          timestamp: fb.serverTimestamp()
        })
      } catch (error) {
        console.error('send message error', error)
      }
    },
    toggleChat (context: ChatContext) {
      const { commit } = chatModuleActionContext(context)
      commit.toggleChat()
    },
    resetChatLastSeen (context: ChatContext) {
      const { commit } = chatModuleActionContext(context)
      commit.resetChatLastSeen()
    }
  },
  mutations: {
    updateChatMessages (state: ChatState, snap: QuerySnapShotWithParams) {
      const chatId = snap.params.chatId
      if (!chatId) return

      // FIXME only update what is necessary
      const messages: CastChatMessage[] = []
      snap.forEach((message) => {
        messages.push(new CastChatMessage(message.data()))
      })
      state.messages[chatId] = messages
    },
    clearChatMessages (state: ChatState, chatId: string) {
      delete state.messages[chatId]
    },
    toggleChat (state: ChatState) {
      state.chatOpen = !state.chatOpen
      // remember when you last opened/closed the chat panel
      state.chatLastSeen = Date.now()
    },
    resetChatLastSeen (state: ChatState) {
      state.chatLastSeen = Date.now()
    }
  }
})

export default chatModule
export const chatModuleActionContext = (context: ChatContext) => moduleActionContext(context, chatModule)
