import { StreamType } from '@/types/streams'
import { CastChangeHandler } from '../castChangeHandler'
import { xor } from 'lodash'
import store from '@/store'
import { Cast } from '@/modules/casts/classes'

const findDifferences = (a: Set<string>, b: Set<string>): string[] => {
  return xor([...a], [...b])
}

export class CastChangeHandlerPresence extends CastChangeHandler {
  handleChanges (oldCast: Cast|null, newCast: Cast|null): void {
    const castId = newCast?.id
    if (!castId) return
    // Find user ids whose presence changed.
    const oldPresentUserIds = this.getPresentUsers(oldCast)
    const newPresentUserIds = this.getPresentUsers(newCast)
    const diff = new Set(findDifferences(oldPresentUserIds, newPresentUserIds))
    // And add user ids whose caster status changed.
    const oldInvitedCasterIds = this.getInvitedCasters(oldCast)
    const newInvitedCasterIds = this.getInvitedCasters(newCast)
    findDifferences(oldInvitedCasterIds, newInvitedCasterIds).forEach((casterId) => diff.add(casterId))

    for (const userId of diff) {
      const isPresent = newPresentUserIds.has(userId)
      const isCaster = newInvitedCasterIds.has(userId)
      store.commit.status.updatePresence({
        userId,
        castId,
        isPresent,
        isCaster
      })
    }
  }

  getPresentUsers (cast: Cast|null): Set<string> {
    const presentUserIds = new Set<string>()
    if (cast === null) return presentUserIds
    for (const [userId, userSessions] of Object.entries(cast.online_sessions ?? {})) {
      if (userSessions.length > 0) presentUserIds.add(userId)
    }
    for (const stream of Object.values(cast.source_streams ?? {})) {
      if (stream.type === StreamType.Caster && stream.user_id !== undefined) {
        presentUserIds.add(stream.user_id)
      }
    }
    return presentUserIds
  }

  getInvitedCasters (cast: Cast|null): Set<string> {
    const invitedCasterIds = new Set<string>()
    if (cast === null) return invitedCasterIds
    for (const [casterId, caster] of Object.entries(cast.invited_casters ?? {})) {
      if (caster !== undefined && caster.deleted !== true) {
        invitedCasterIds.add(casterId)
      }
    }
    return invitedCasterIds
  }
}
