import Axios from 'axios'
import { ActionContext } from 'vuex'
import { defineModule } from 'direct-vuex'
import { LayoutsState, RootState } from '../types'
import { moduleActionContext } from '..'
import { Layout, SceneTransitionAction } from '@/types/layouts'
import studio, { CollectionNames } from '@/modules/database/utils'
import { KeyDict } from '@/types'
import { MergeOption } from '@/modules/database/utils/database'
import { Result } from 'kiswe-ui'

type LayoutsContext = ActionContext<LayoutsState, RootState>

const layoutsModule = defineModule({
  namespaced: true,
  state: {
    layouts: [],
  } as LayoutsState,
  actions: {
    async subscribeLayouts (context: LayoutsContext) {
      const { commit } = layoutsModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        key: 'layouts',
        query: studio.db.collection(CollectionNames.LAYOUTS).where('team_id', '==', 'all'),
        callback: commit.updateLayouts
      })
      result.logIfError('Could not subscribe to layouts')
    },
    unsubscribeLayouts (_context: LayoutsContext) {
      studio.subscriptions.unsubscribe('layouts')
    },
    async addLayout (_context: LayoutsContext, layout: Layout) {
      return await studio.db.collection(CollectionNames.LAYOUTS).add(layout)
    },
    async deleteLayout (_context: LayoutsContext, layoutId: string) {
      return await studio.db.collection(CollectionNames.LAYOUTS).doc(layoutId).delete()
    },
    async saveLayout (_context: LayoutsContext, layout: Layout) {
      if (!layout.id) return Result.fail('id', 'Layout Id is not defined')
      return await studio.db.collection(CollectionNames.LAYOUTS).doc(layout.id).set(layout, MergeOption.OVERWRITE)
    },
    async performTransitionAction (_context: LayoutsContext, action: SceneTransitionAction) {
      const url = new URL(action.url)
      const body = JSON.parse(action.body)
      /*
      if (url.hostname.includes('.singular.') && Array.isArray(body)) {
        body.map((composition) => {
          if (delay < 0) return composition
          return composition.timestamp = Date.now() + delay
        })
      } else {
        await sleep(action.offsetDelay)
      }
      */
      const headers = { 'Content-Type': 'application/json', 'Authorization': '' }
      if (action.token) headers.Authorization = `Bearer ${ action.token }`

      switch (action.method) {
        case 'get':
          Axios.get(url.href, { headers })
          break
        case 'put':
          Axios.put(url.href, body, { headers })
          break
        case 'post':
          Axios.post(url.href, body, { headers })
          break
        case 'delete':
          Axios.delete(url.href, { headers })
          break
      }
    }
  },
  mutations: {
    updateLayouts (state: LayoutsState, layouts: KeyDict<Layout>) {
      const allLayouts = Object.keys(layouts).reduce((allLayouts: Layout[], currentLayout) => {
        allLayouts.push({ ...layouts[currentLayout], id: currentLayout })
        return allLayouts
      }, [])
      state.layouts = allLayouts
    }
  }
})

export default layoutsModule
export const layoutsModuleActionContext = (context: LayoutsContext) => moduleActionContext(context, layoutsModule)
