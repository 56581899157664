import { Cast } from '@/modules/casts/classes'

export abstract class CastChangeHandler {
  currentCastId: string|null

  constructor (currentCastId: string|null) {
    this.currentCastId = currentCastId
  }

  abstract handleChanges (oldCast: Cast|null, newCast: Cast|null): void
}
