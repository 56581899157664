import { UserAgent } from '@sentry/browser/dist/integrations'
import { NetworkStats } from '../types/networkstats'
import { KeyDict } from '../types'
import { SupportedLocale } from '../plugins/translations/types'
import { Email } from '../modules/profiles/types'
import firebase from 'firebase/compat/app'
import { Loader, Result } from 'kiswe-ui'
import { cloneDeep, pickBy } from 'lodash'
import { ChromaKey } from '@/modules/user/classes'
import { WebRtcEncoderSettings } from '@/modules/usermedia/classes'
import { isBoolean } from '@/modules/common/utils'
import { SelfTestResult } from '@/modules/selftest/types'

export const DEFAULT_NOTIFICATION_EXPIRATION = 5 * 60 * 1000 // 5 minutes

export class UserNotification {
  user_id: string = ''
  message: string = ''
  messageTranslationKey?: string = ''
  link?: string = ''
  linkLabel?: string = ''
  linkLabelTranslationKey?: string = ''
  footerLink?: string
  footerLabel?: string
  footerLabelTranslationKey?: string
  expires: number = DEFAULT_NOTIFICATION_EXPIRATION + Date.now()
}

export class SelfTestStats {
  camerapermission: boolean = false
  hearaudio: boolean = false
  hearpreview: boolean = false
  microphonepermission: boolean = false
  seevideo: boolean = false
  seepreview: boolean = false
  timestamp: number = 0
  region: string = ''
  network: {
    download: NetworkStats,
    upload: NetworkStats
  } = {
    download: new NetworkStats(),
    upload: new NetworkStats()
  }
}

export type maybeBoolean = boolean | null

export enum PreviewVideoQualityType {
  High = 'High',
  Low = 'Low'
}

export enum ChromaKeyPreviewQualityType {
  High = 'High',
  Low = 'Low',
  Off = 'Off'
}

export interface UserMediaDevice {
  id: string,
  name: string,
  type: 'audio' | 'video' | 'output'
}

export interface PreviewSettings {
  video: PreviewVideoQualityType
}

interface HotKeySettings {
  enabled: boolean,
  connectionId: null|string
}

interface LegalVersions {
  tosVersion: number,
  privacyVersion: number,
  lastUpdateDialog: number | null
}

interface UserMeta {
  created: number
}
export class UserProfile {
  agent: UserAgent|null
  avatar: string|null
  /** @deprecated: avoid using, soon to be deleted */
  id: string
  email: Email
  first_name: string
  last_name: string
  role: UserRole
  isSelftestDone: boolean
  introshown: boolean
  currentTeam: string
  teams: KeyDict<boolean>
  teamGroups: KeyDict<string[]>
  transcodingregion: string
  /**
   * @deprecated
   */
  user_id: string
  display_name: string|null
  display_image: string|null
  chromakey: ChromaKey|null
  notifications: KeyDict<UserNotification>
  selftestStats: SelfTestStats|null
  devices: UserMediaDevice[]
  selectedAudioDevice: string|null
  selectedVideoDevice: string|null
  encoderSettings: WebRtcEncoderSettings
  previewVideoQuality: PreviewVideoQualityType
  chromaKeyPreviewQuality: ChromaKeyPreviewQualityType
  hotkeys: HotKeySettings
  udpEnabled: boolean
  echoSuppression: boolean
  legalAgreements: LegalVersions
  anonymous: boolean
  anonymousId: string|null
  assignedId: string|null
  caster_volume: number|null
  isBatteryCharging: boolean|null
  batteryLevel: number|null
  locale: SupportedLocale
  meta: UserMeta|null
  deleted: boolean
  version: null|string

  constructor (data: unknown) {
    this.agent = Loader.loadAndCast<UserAgent>(data, 'agent', null)
    this.avatar = Loader.loadStringOrNull(data, 'avatar', null)
    this.id = Loader.loadString(data, 'id')
    this.user_id = Loader.loadString(data, 'user_id', this.id)
    this.email = Loader.loadString(data, 'email') as Email
    this.first_name = Loader.loadString(data, 'first_name')
    this.last_name = Loader.loadString(data, 'last_name')
    this.display_name = Loader.loadStringOrNull(data, 'display_name', null)
    this.display_image = Loader.loadStringOrNull(data, 'display_image', null)
    this.role = Loader.loadString(data, 'role', UserRole.User) as UserRole
    this.isSelftestDone = Loader.loadBoolean(data, 'isSelftestDone', false)
    this.introshown = Loader.loadBoolean(data, 'introshown', false)
    this.teams = Loader.loadAndCast<KeyDict<boolean>>(data, 'teams', {})
    this.currentTeam = Loader.loadString(data, 'currentTeam', this.activeTeams[0])
    this.teamGroups = Loader.loadAndCast<KeyDict<string[]>>(data, 'teamGroups', {})
    this.transcodingregion = Loader.loadString(data, 'transcodingregion', 'us-east')
    this.chromakey = Loader.loadAndCast<ChromaKey>(data, 'chromakey', null)
    this.notifications = Loader.loadAndCast<KeyDict<UserNotification>>(data, 'notifications', {})
    this.selftestStats = Loader.loadAndCast<SelfTestStats>(data, 'selftestStats', null)
    if (this.selftestStats === null) {
      this.selftestStats = Loader.loadAndCast<SelfTestStats>(data, 'selftest', null)
    }
    this.devices = Loader.loadAndCast<UserMediaDevice[]>(data, 'devices', [])
    this.selectedAudioDevice = Loader.loadStringOrNull(data, 'selectedAudioDevice', null)
    this.selectedVideoDevice = Loader.loadStringOrNull(data, 'selectedVideoDevice', null)
    this.encoderSettings = Loader.loadClass(data, 'encoderSettings', (data) => new WebRtcEncoderSettings(data), new WebRtcEncoderSettings())
    this.previewVideoQuality = Loader.loadString(data, 'previewVideoQuality', PreviewVideoQualityType.High) as PreviewVideoQualityType
    this.chromaKeyPreviewQuality = ChromaKeyPreviewQualityType.High
    this.hotkeys = Loader.loadAndCast<HotKeySettings>(data, 'hotkeys', { enabled: true, connectionId: null })
    this.udpEnabled = Loader.loadBoolean(data, 'udpEnabled', false)
    this.echoSuppression = Loader.loadBoolean(data, 'echoSuppression', false)
    this.legalAgreements = Loader.loadAndCast<LegalVersions>(data, 'legalAgreements', { tosVersion: 0, privacyVersion: 0, lastUpdateDialog: null })
    this.anonymous = Loader.loadBoolean(data, 'anonymous', false)
    this.anonymousId = Loader.loadStringOrNull(data, 'anonymousId', null)
    this.assignedId = Loader.loadStringOrNull(data, 'assignedId', null)
    this.caster_volume = Loader.loadNumberOrNull(data, 'caster_volume', null)
    this.isBatteryCharging = Loader.loadBooleanOrNull(data, 'isBatteryCharging', null)
    this.batteryLevel = Loader.loadNumberOrNull(data, 'batteryLevel', null)
    this.locale = Loader.loadString(data, 'locale', SupportedLocale.English) as SupportedLocale
    this.meta = Loader.loadAndCast<UserMeta>(data, 'meta', null)
    this.deleted = Loader.loadBoolean(data, 'deleted', false)
    this.version = Loader.loadStringOrNull(data, 'version', null)
  }

  get activeTeams (): string[] {
    return Object.keys(pickBy(this.teams, (enabled) => enabled))
  }

  selectAudioDevice (deviceId: string) {
    this.selectedAudioDevice = deviceId
    return this
  }

  selectVideoDevice (deviceId: string) {
    this.selectedVideoDevice = deviceId
    return this
  }

  static load (data: unknown): Result<UserProfile, 'invalid_params' | 'unknown'> {
    try {
      return Result.success(new UserProfile(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  static create (data: Pick<UserProfile, 'id'|'first_name'|'last_name'|'email'|'currentTeam'>
                       & Partial<UserProfile>): Result<UserProfile, 'invalid_params' | 'unknown'> {
    return UserProfile.load(data)
  }

  clone (): UserProfile {
    return cloneDeep(this)
  }

  get displayOrFirstLastName (): string {
    if (this.display_name) {
      return this.display_name
    } else {
      return `${this.first_name} ${this.last_name}`.trim()
    }
  }

  get firstLastWithDisplayName (): string {
    let name = `${this.first_name} ${this.last_name}`.trim()
    if (this.display_name) {
      if (name === '') name = this.display_name
      else name = `${name} (${this.display_name})`
    }
    return name
  }

  get fullName (): string {
    return `${this.first_name} ${this.last_name}`.trim()
  }

  get selftestResult (): SelfTestResult {
    if (!this.selftestStats) return SelfTestResult.UNKNOWN
    // NOTE: skip network check until we figure out what the actual problem
    // const { score: uploadScore } = this.selftestStats.network.upload
    // if (!uploadScore || uploadScore < 2) return SelfTestResult.FAIL
    for (const value of Object.values(this.selftestStats)) {
      if (!isBoolean(value)) continue
      if (!value) return SelfTestResult.FAIL
    }
    return SelfTestResult.SUCCESS
  }

  get isSuperUser (): boolean {
    return isSuperUser(this.role)
  }

  addToTeam (teamId: string) {
    this.teams[teamId] = true
    return this
  }

  setSelfTestStats (stats: SelfTestStats) {
    this.selftestStats = stats
    this.isSelftestDone = true
    return this
  }

  setName (first: string, last: string) {
    this.first_name = first
    this.last_name = last
    return this
  }
}

export enum UserRole {
  User = 'user',
  SuperUser = 'superuser',
  Sales = 'superuser_sales',
  Operator = 'superuser_operator',
  Developer = 'superuser_developer'
}

export const isSuperUser = (role: string) =>
  [UserRole.Sales, UserRole.Operator, UserRole.Developer, UserRole.SuperUser].includes(role as UserRole)

export interface UserOption {
  name: string,
  id: string
}

export enum BatteryStatus {
  All = 'all',
  Charging = 'charging',
  Level = 'level'
}

export interface UserConsent {
  consents: {
    age: boolean,
    terms: boolean,
    privacy?: boolean
  },
  createdDate?: firebase.firestore.FieldValue, // Allow for firestore.serverTimestamp()
  email: string,
  firstName: string,
  lastName: string,
  locale: SupportedLocale
}

export interface AddProfileToCastTeamParams {
  user_id: string,
  cast_id: string,
  token: string
}
