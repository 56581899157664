import { Loader, Result } from 'kiswe-ui'

export default class WebRtcEncoderSettings {
  videoBitrate: number = 320
  audioBitrate: number = 32
  width: number = 1280
  height: number = 720

  constructor (data?: unknown) {
    if (data === undefined) return
    this.videoBitrate = Loader.loadNumber(data, 'videoBitrate', this.videoBitrate)
    this.audioBitrate = Loader.loadNumber(data, 'audioBitrate', this.audioBitrate)
    this.width = Loader.loadNumber(data, 'width', this.width)
    this.height = Loader.loadNumber(data, 'height', this.height)
  }

  static load (data: unknown): Result<WebRtcEncoderSettings, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new WebRtcEncoderSettings(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  get resolution (): number {
    return this.height
  }

  set resolution (newVal: number) {
    this.height = newVal
    this.width = newVal * 16 / 9
  }
}
