/* eslint-disable unicorn/consistent-function-scoping */
import { Ref, computed } from 'vue'
import { SceneMultimediaController } from '../types'
import { useAddLog } from '@/modules/logging/compositions'
import { useReplayIngests, useReplayPlaylists } from '@/modules/replay/compositions'
import { IngestCommand, IngestStatus } from '@/modules/replay/types'

const getLogPayload = (sceneId?: string|null, playlistId?: string|null, clipIdId?: string|null): any => {
  return {
    sceneId: sceneId ?? undefined,
    playlistId: playlistId ?? undefined,
    clipIdId: clipIdId ?? undefined
  }
}

const useReplayMediaController = (sceneId: Ref<string|null>): SceneMultimediaController => {

    const { addLog } = useAddLog()
    const { getPlaylistIdOfReplayScene, getPrevClipId, getNextClipId } = useReplayPlaylists()
    const { getIngestStatus, getCurrentClipId, setIngestCommand } = useReplayIngests()

    const playlistId = computed(() => getPlaylistIdOfReplayScene(sceneId.value ?? '') ?? '')
    const replayStatus = computed(() => getIngestStatus(playlistId.value))
    const curClipId = computed(() => getCurrentClipId(playlistId.value) ?? '')

    const hasMediaContent = () => !!playlistId.value
    const isSceneReady = () => !!replayStatus.value
    const isCurrentMediaPlaying = () => replayStatus.value === IngestStatus.PLAYING
    const isAtStart = () => !getPrevClipId(playlistId.value, curClipId.value)
    const isAtEnd = () => !getNextClipId(playlistId.value, curClipId.value)

    const isPreviousVisible = () => true
    const isNextVisible = () => true
    const isPlayPauseVisible = () => true
    const isRestartVisible = () => true

    const doPrevious = async (): Promise<void> => {
      const prevClipId = getPrevClipId(playlistId.value, curClipId.value)
      if (!prevClipId) return
      await setIngestCommand({ ingestId: playlistId.value, command: IngestCommand.START,
        clipId: prevClipId })
      const logPayload = getLogPayload(sceneId.value, playlistId.value, prevClipId)
      addLog('Previous media button pressed', 'info', logPayload).catch((error) => console.error(error))
    }

    const doNext = async (): Promise<void> => {
      const nextClipId = getNextClipId(playlistId.value, curClipId.value)
      if (!nextClipId) return
      await setIngestCommand({ ingestId: playlistId.value, command: IngestCommand.START,
        clipId: nextClipId })
      const logPayload = getLogPayload(sceneId.value, playlistId.value, nextClipId)
      addLog('Next media button pressed', 'info', logPayload).catch((error) => console.error(error))
    }

    const doPlay = async (): Promise<void> => {
      if (!playlistId.value) return
      await setIngestCommand({ ingestId: playlistId.value, command: IngestCommand.RESUME })
      const logPayload = getLogPayload(sceneId.value, playlistId.value, curClipId.value)
      addLog('Play media button pressed', 'info', logPayload).catch((error) => console.error(error))
    }

    const doPause = async (): Promise<void> => {
      if (!playlistId.value) return
      await setIngestCommand({ ingestId: playlistId.value, command: IngestCommand.PAUSE })
      const logPayload = getLogPayload(sceneId.value, playlistId.value, curClipId.value)
      addLog('Pause media button pressed', 'info', logPayload).catch((error) => console.error(error))
    }

    const doRestart = async (): Promise<void> => {
      if (!playlistId.value || !curClipId.value ) return
      await setIngestCommand({ ingestId: playlistId.value, command: IngestCommand.START,
        clipId: curClipId.value })
      const logPayload = getLogPayload(sceneId.value, playlistId.value, curClipId.value)
      addLog('Restart media button pressed', 'info', logPayload).catch((error) => console.error(error))
    }

  return {
    doPrevious,
    doNext,
    doPlay,
    doPause,
    doRestart,
    hasMediaContent,
    isPreviousVisible,
    isNextVisible,
    isPlayPauseVisible,
    isRestartVisible,
    isAtEnd,
    isAtStart,
    isCurrentMediaPlaying,
    isSceneReady
  }
}

export default useReplayMediaController
