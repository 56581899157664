import { createClass } from '@/modules/classBuilder/classBuilder'
import { ClassType } from '@/modules/classBuilder/mixins/base'
import { CastType } from '../../../types/casts'
import { CastBase } from './CastBase'

export class CastStationSwitcherLeftOver extends CastBase {
  constructor (data: unknown) {
    super(data)
  }
}

export const CastStationSwitcher = createClass(CastStationSwitcherLeftOver)
  .addCastType(CastType.StationSwitcher)
  .addCasters()
  .addOnlineSessions()
  .addCustomOutputs()
  .addPresets({ castType: CastType.StationSwitcher })
  .addInputs()
  .addMutes({ useCastLevelMutes: true })
  .get()

export type CastStationSwitcher = ClassType<typeof CastStationSwitcher>
