import { Team } from '@/modules/teams/classes'
import CheckFeature from '../checkfeature'

export default class LayoutFeature extends CheckFeature {
  hasFeature (): boolean {
    return this.currentTeam?.features?.layout?.enabled ?? true
  }

  override get description (): string {
    return `Allow producers to create new layouts`
  }

  teamHasFeature (teamId: string): boolean {
    const team = this.teams[teamId]
    return team?.features?.layout?.enabled ?? true
  }

  assignTeamFeature (team: Partial<Team>, enabled: boolean): void {
    team.features = team.features ?? {}
    team.features.layout = { enabled }
  }

  override isGlobalFeature (): boolean {
    return false
  }
}
