<template>
  <KisweForm :hasSeparators="true">
    <KisweFormSection>
      <KisweFormGroup gap="lg">
        <KisweFormField>
          <KisweInput label="Name" v-model="validatorProfile.first_name.$model" />
        </KisweFormField>
        <KisweFormField>
          <KisweInput label="Last Name" v-model="validatorProfile.last_name.$model" />
        </KisweFormField>
      </KisweFormGroup>
      <KisweFormGroup gap="lg">
        <KisweFormField>
          <KisweInput label="Email" v-model="editProfile.email" disabled />
        </KisweFormField>
      </KisweFormGroup>
    </KisweFormSection>
    <KisweFormSection>
      <KisweFormGroup gap="lg">
        <KisweFormField>
          <KisweSelect
            label="Role"
            v-model="validatorRole.editRole.$model"
            data-qa="select-role"
            :options="roleOptions"
            :disabled="!isRoleEnabled"
            @update:modelValue="updateMemberRole"
          />
        </KisweFormField>
        <KisweFormField>
          <KisweSelect
            label="Team"
            v-model="editTeamGroups"
            data-qa="select-team-groups"
            :options="teamGroupsOptions"
            :disabled="!canEditMembers || inCast"
            multiple
          />
        </KisweFormField>
      </KisweFormGroup>
    </KisweFormSection>
    <KisweFormSection>
      <div class="user-streaming-settings">
        <UserEncoderSettings :encoderSettings="originProfile.encoderSettings" @update="updateEncoderSettings">
          <template v-slot:region>
            <KisweFormField>
              <SelectTranscodingRegion label="Region" v-model="editProfile.transcodingregion" />
            </KisweFormField>
          </template>
        </UserEncoderSettings>
        <KisweFormField v-if="canEditCasterStreams">
          <KisweSwitch
            label="UDP"
            data-qa="switch-udp"
            v-model="editProfile.udpEnabled"
            data-justify="flex-start"
          />
        </KisweFormField>
        <KisweFormField>
          <KisweSwitch
            label="Noise cancellation"
            data-qa="switch-echoSuppression"
            v-model="editProfile.echoSuppression"
            data-justify="flex-start"
          />
        </KisweFormField>
      </div>
    </KisweFormSection>
    <KisweFormSection>
      <UserPreviewQualitySettings
        :previewVideoQuality="editProfile.previewVideoQuality"
        @update="updatePreviewSettings"
      />
    </KisweFormSection>
  </KisweForm>
</template>

<script lang='ts'>
/* eslint-disable max-lines */
import { defineComponent, ref, computed, watch } from 'vue'
import { sortBy, isEqual } from 'lodash'
import { KisweForm, KisweFormSection, KisweFormGroup,
  KisweInput, KisweFormField, KisweSelect, KisweSwitch } from 'kiswe-ui'
import { SelectTranscodingRegion } from '@/modules/transcodingregions/components'
import { UserEncoderSettings, UserPreviewQualitySettings } from '@/modules/user/components'
import { useCurrentTeam } from '@/modules/teams/compositions'
import { useCanDo } from '@/modules/abilities/compositions'
import useCurrentTeamRoles from '@/compositions/teamRoles'
import store from '@/store'
import roleNames from '@/roleNames'
import { MemberRole } from '@/modules/teams/types'
import { UserProfile, PreviewSettings } from '@/types/users'
import { userProfileRules, memberRoleRules, encoderSettingsRules } from '@/modules/user/compositions/useUserProfile'
import useVuelidate, { Validation } from '@vuelidate/core'
import './UserProfileForm.scss'
import { WebRtcEncoderSettings } from '@/modules/usermedia/classes'
import { AbilityRole } from '@/abilitiesByRole'

export interface UserProfileValidators {
  profile?: Validation<typeof userProfileRules>,
  role?: Validation<typeof memberRoleRules>
  encoder?: Validation<ReturnType<typeof encoderSettingsRules>>
}

const isStreamingSettingsUnchanged = (currentProfile: UserProfile, originProfile: UserProfile) => {
  return currentProfile.udpEnabled === originProfile.udpEnabled &&
    currentProfile.echoSuppression === originProfile.echoSuppression &&
    currentProfile.transcodingregion === originProfile.transcodingregion &&
    isEqual(currentProfile.encoderSettings, originProfile.encoderSettings)
}

export default defineComponent({
  name: 'UserProfileForm',
  components: {
    KisweForm,
    KisweFormField,
    KisweFormGroup,
    KisweFormSection,
    KisweInput,
    KisweSelect,
    KisweSwitch,
    SelectTranscodingRegion,
    UserEncoderSettings,
    UserPreviewQualitySettings
  },
  props: {
    modelValue: {
      type: Object as () => UserProfile,
      required: true
    },
    short: {
      type: Boolean,
      default: false
    },
    anonymous: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    input (_profile: UserProfile) { return true },
    disableRestartStream (_disabled: boolean) { return true },
    updateWarningMessage (_error: { _key: string, _message: string }) { return true },
    updateValidatorError (_newValidators: UserProfileValidators) { return true }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props, { emit }) {
    const { teamId, currentTeam } = useCurrentTeam()
    const { canManageOperations, canEditTeamSettings, canEditCasterStreams, canEditMembers } = useCanDo()
    const originProfile = ref(props.modelValue.clone())
    const editProfile = ref(props.modelValue.clone())
    const originalRole = ref(currentTeam.value?.members[props.modelValue.id]?.role ?? MemberRole.GuestCaster)
    const editRole = ref(currentTeam.value?.members[props.modelValue.id]?.role ?? MemberRole.GuestCaster)
    const editTeamGroups = ref<string[]>(teamId.value !== null ? editProfile.value.teamGroups[teamId.value] : [])
    const validatorProfile = useVuelidate(userProfileRules, editProfile)
    const validatorRole = useVuelidate(memberRoleRules, { editRole })
    const inCast = computed(() => store.state.events.inCast)

    const userIsMe = computed(() => originProfile.value.id === store.state.user.id)
    const isRoleEnabled = computed(() =>
      canEditTeamSettings.value
        && (!userIsMe.value || canManageOperations.value)
        && !props.short
        && !props.anonymous
        && !inCast.value
    )

    const roleOptions = computed(() => {
      const availableRoles = roleNames()
      return Object.keys(availableRoles).map((key) => ({ value: key, label: availableRoles[key] }))
    })

    const teamGroupsOptions = computed(() => {
      const teamGroups = store.state.team.teamGroups
      if (teamGroups) {
        const sortedTeamGroups = sortBy(teamGroups, (group) => group.name.toLowerCase())
        return sortedTeamGroups.map((group) => ({ value: group.id, label: group.name }))
      }
      return []
    })

    const updateEncoderSettings = (newEncoderSettings: { encoderSettings: WebRtcEncoderSettings, validator: any }) => {
      editProfile.value.encoderSettings = newEncoderSettings.encoderSettings
      emit('updateValidatorError', {
        encoder: newEncoderSettings.validator
      })
    }

    const updatePreviewSettings = (previewSettings: PreviewSettings) => {
      editProfile.value.previewVideoQuality = previewSettings.video
    }

    const updateMemberRole = async (newRole: AbilityRole|string) => {
      const message = originalRole.value !== newRole ? 'Refresh the browser window to see these changes' : ''
      emit('updateWarningMessage', { key: 'refreshWindow', message })
      await store.dispatch.team.updateMemberRole({ id: editProfile.value.id, role: newRole })
    }

    watch(editProfile, (newProfile) => {
      emit('update:modelValue', newProfile)
      emit('disableRestartStream', isStreamingSettingsUnchanged(newProfile, originProfile.value))
    }, { deep: true })

    watch(editTeamGroups, (newTeam) => {
      if (teamId.value !== null) editProfile.value.teamGroups = { [teamId.value]: newTeam }
      emit('update:modelValue', { ...editProfile.value, teamGroups: { [teamId.value as string]: newTeam } })
    })

    watch([validatorProfile, validatorRole], ([newValidatorProfile, newValidatorRole]) => {
      emit('updateValidatorError', {
        profile: newValidatorProfile,
        role: newValidatorRole
      })
    })

    return {
      ...useCurrentTeamRoles(),
      canEditCasterStreams,
      canEditMembers,
      editProfile,
      editRole,
      editTeamGroups,
      inCast,
      isRoleEnabled,
      originProfile,
      roleOptions,
      teamId,
      teamGroupsOptions,
      updateEncoderSettings,
      updatePreviewSettings,
      updateMemberRole,
      validatorProfile,
      validatorRole
    }
  }
})
</script>
