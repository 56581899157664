import { KeyDict } from '@/types'
import { MutedProp } from '@/types/streams'
import { GConstructor, Mixin } from './base'
import { HasScenes } from './hasScenes'
import { HasStreams } from './hasStreams'

export interface MutesProps {
  useCastLevelMutes: boolean
}

// eslint-disable-next-line max-lines-per-function
export function hasMutes<TBase extends GConstructor<HasScenes> &
                                       GConstructor<HasStreams>> (Base: TBase, props: MutesProps) {
  return class hasMutesClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
    }
    // @ts-ignore
    private __hasMutes: true = true

    /* eslint-disable complexity */
    getSceneMutesForChannels (sceneId: string|null, audioChannelIds: string[]): KeyDict<boolean> {
      const sceneMuteStatus: KeyDict<boolean> = {}
      if (sceneId === null) return {}
      const scene = this.scenesWithPresets[sceneId] ?? null
      if (scene === null) return {}
      for (const channelId of audioChannelIds) {
        // Note: `channelId` is assumed to be <streamId>|<streamId>_left|<streamId>_right
        const [streamId, leftRight] = channelId.split('_')
        const content = scene.contents?.[streamId]
        // TODO: Should we return default mute (or true) instead of skipping it completely?
        if (!content) continue
        if (leftRight === undefined) {
          sceneMuteStatus[channelId] = content.muted ?? false
        } else if (leftRight === 'left') {
          sceneMuteStatus[channelId] = content.leftMuted ?? true
        } else {
          sceneMuteStatus[channelId] = content.rightMuted ?? false
        }
      }
      return sceneMuteStatus
    }
    // eslint-disable-next-line complexity
    getSourceStreamMutesForChannels (audioChannelIds: string[]) {
      const sourceStreamMutes: KeyDict<boolean> = {}
      for (const channelId of audioChannelIds) {
        const [streamId, channelType] = channelId.split('_')
        const sourceStream = this.source_streams[streamId]
        if (!sourceStream) continue
        let muted = false
        if (channelType === undefined) {
          muted = sourceStream.muted ?? false
        } else if (channelType === 'left') {
          muted = sourceStream.leftMuted ?? false
        } else if (channelType === 'right') {
          muted = sourceStream.rightMuted ?? false
        } else {
          muted = sourceStream.muted ?? false
        }
        sourceStreamMutes[channelId] = muted
      }
      return sourceStreamMutes
    }

    getAudibleMutes (sceneId: string|null, channelIds: string[]) {
      const sceneMutes = this.getSceneMutesForChannels(sceneId, channelIds)
      const sourceStreamMutes = props.useCastLevelMutes ? this.getSourceStreamMutesForChannels(channelIds) : {}
      const combinedMutes: KeyDict<boolean> = {}
      for (const channelId of channelIds) {
        const sceneMute = sceneMutes[channelId] ?? true
        const sourceStreamMute = sourceStreamMutes[channelId] ?? false
        combinedMutes[channelId] = sourceStreamMute || sceneMute
      }
      return combinedMutes
    }

    getVisibleMutes (sceneId: string|null, channelIds: string[]) {
      if (props.useCastLevelMutes) return this.getSourceStreamMutesForChannels(channelIds)
      else return this.getSceneMutesForChannels(sceneId, channelIds)
    }

    updateMutes (muteInfo: { status: boolean, name: MutedProp, id: string }) {
      if (props.useCastLevelMutes) this.updateSourceStreamMutes(muteInfo)
      else this.updateSceneMutes(muteInfo)
      return this
    }

    protected updateSourceStreamMutes (muteInfo: { status: boolean, name: MutedProp, id: string }) {
      if (muteInfo.name === undefined || muteInfo.id === undefined) return
      const streamId = muteInfo.id.split('_')[0]
      const sourceStream = this.source_streams[streamId]
      if (sourceStream) sourceStream[muteInfo.name] = muteInfo.status
    }

    protected updateSceneMutes (muteInfo: { status: boolean, name: MutedProp, id: string }) {
      if (muteInfo.name === undefined || muteInfo.id === undefined) return
      if (this.activeScene) {
        const streamId = muteInfo.id.split('_')[0]
        const sceneContents = this.scenes[this.activeScene]?.contents[streamId]
        if (sceneContents) {
          if (['leftMuted', 'rightMuted'].includes(muteInfo.name)) {
            sceneContents.leftMuted = true
            sceneContents.rightMuted = true
          }
          sceneContents[muteInfo.name] = muteInfo.status
        }
      }
    }

    updateScenePresetMute (sceneId: string|null, muteInfo: { status: boolean, name: MutedProp, id: string }) {
      if (sceneId === null) return this
      const presetId = this.getScenePresetIdBySceneId(sceneId)
      if (presetId) {
        const preset = this.scenePresets[presetId]
        if (preset) {
          const presetContent = preset.contents[muteInfo.id] ?? {}
          presetContent[muteInfo.name] = muteInfo.status
          preset.contents[muteInfo.id] = presetContent
        }
      }
      return this
    }
  }
}

export type HasMutes = Mixin<typeof hasMutes>

export const hasMutesMixin = (obj: unknown): obj is HasMutes => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasMutes' in obj) return true
  return false
}
