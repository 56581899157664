import { TranslationKeys } from '../../../plugins/translations/types'
import { SelftestStatsBooleanKeys } from '../classes/SelfTestStats'

export enum SelfTestState {
  IDLE = 'idle',
  CASTING = 'casting',
  REPLY = 'reply',
  NO_REPLY = 'noreply',
  CHECKING_NETWORK = 'checking_network',
  SUCCESS = 'success',
  FAIL = 'fail'
}

export enum SelfTestQuestionStep {
  SEE_STEAM = 'seepreview',
  HEAR_STEAM = 'hearpreview',
  SEE_YOURSELF = 'seevideo',
  HEAR_YOURSELF = 'hearaudio'
}

export enum SelfTestResult {
  UNKNOWN = 'unknown',
  SUCCESS = 'success',
  FAIL = 'fail'
}

export enum SelfTestAction {
  NextQuestion = 'nextQuestion',
  NextStep = 'nextStep',
  RestartLocalCamera = 'restartLocalCamera',
  RestartRemoteStreams = 'restartRemoteStreams',
  UnmuteCaster1 = 'unmuteCaster1',
  UnmuteCaster2 = 'unmuteCaster2',
  UnmuteCaster3 = 'unmuteCaster3',
  UnmuteCaster4 = 'unmuteCaster4',
  MuteCaster1 = 'muteCaster1',
  MuteCaster2 = 'muteCaster2',
  MuteCaster3 = 'muteCaster3',
  MuteCaster4 = 'muteCaster4',
  SwitchCamera = 'switchCamera',
  SwitchMicrophone = 'switchMicrophone',
  StartStreaming = 'startStreaming',
  GoToResult = 'finish',
  FailSelfTest = 'fail',
  SucceedSelfTest = 'success',
  ReportStats = 'reportStats'
}

export enum SelfTestStreams {
  MyLocalStream = 'mylocalstream',
  MyRemoteStream = 'myremotestream',
  Caster1 = 'caster1',
  Caster2 = 'caster2',
  Caster3 = 'caster3',
  Caster4 = 'caster4'
}

export interface SelfTestStreamState {
  hearable: boolean,
  visible: boolean
}

export interface SelfTestStep {
  questions: SelfTestQuestion[],
  streaming: boolean,
  streams: Record<SelfTestStreams, SelfTestStreamState>,
  statsProp?: SelftestStatsBooleanKeys
}

export enum SelfTestQuestionType {
  VisibleQuestion = 'visibleQuestion',
  SetupQuestion = 'setupQuestion',
  PerformanceQuestion = 'performanceQuestion'
}

export interface SelfTestQuestion {
  message: TranslationKeys,
  body?: TranslationKeys,
  type: SelfTestQuestionType,
  onSuccess?: SelfTestAction[],
  onFail?: SelfTestAction[]
}

export interface SelfTestNonVisibleAction extends SelfTestQuestion {
  readonly type: SelfTestQuestionType.PerformanceQuestion | SelfTestQuestionType.SetupQuestion
}

export interface SelfTestVisibleQuestion extends SelfTestQuestion {
  answers: Record<SelfTestResult, TranslationKeys>,
  readonly type: SelfTestQuestionType.VisibleQuestion
}

export interface SelfTestConfig {
  steps: SelfTestStep[]
}

export type SelfTestActionCallback = (action: SelfTestAction) => void
export type SelfTestNewQuestionCallback = (question: SelfTestQuestion) => void
