import PortalVue from 'portal-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import studio from './modules/database/utils'
import { App } from 'vue'
import { hasFeature } from './featureFlags/featureFlags'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import freeIcons from '@fortawesome/free-regular-svg-icons'

export const setupVueEnv = (app: App) => {
  // library.add(freeIcons)
  app.component('icon', FontAwesomeIcon)

  // app.config.productionTip = false
  // app.config.devtools = false

  app.config.globalProperties.$hasFeature = hasFeature

  if (process.env.NODE_ENV === 'test') {
    // In the vue test environment, we want the modals to be shown inside the component. Else we cannot query them.
    // We cannot use the same approach as in the production environment, because the modals are not rendered op top
    // https://github.com/LinusBorg/portal-vue/issues/260
    // app.config.ignoredElements = ['portal']
    app.config.compilerOptions.isCustomElement = (tag) => tag === 'portal'
  } else {
    // In production they are showed in the App.vue component on top of everything
    app.use(PortalVue)
  }

  try {
    afterEach(() => {
      if (studio.subscriptions.runningKeys().length > 0) {
        console.error('Running keys are still', studio.subscriptions.runningKeys())
        studio.subscriptions.runningKeys().forEach((key) => studio.subscriptions.unsubscribeFB(key))
      }
    })
    afterAll(() => {
      jest.restoreAllMocks()
      global.gc && global.gc()
    })
    /* process.on('unhandledRejection', reason => {
      throw reason
    }) */
  } catch {}
}
