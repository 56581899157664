const CAST_TALKBACK_TARGET_PREFIX = 'cast/'

export function constructCastTalkbackTarget (castId: string): string {
  return `${CAST_TALKBACK_TARGET_PREFIX}${castId}`
}

export function isCastTalkbackTarget (talkbackTarget: string, castId: string): boolean {
  if (!talkbackTarget.startsWith(CAST_TALKBACK_TARGET_PREFIX)) return false
  if (talkbackTarget.substr(CAST_TALKBACK_TARGET_PREFIX.length) !== castId) return false
  return true
}
