import { NetworkstatsState } from './networkstats.state'
import { NgcvpStats, UserNetworkStats } from '@/types/networkstats'
import { TranscodingStats, CefStatInstances } from '@/modules/statistics/classes'
import { KeyDict } from '@/types'
import store from '@/store'

export const mutations = {
  updateUserStats (state: NetworkstatsState, stats: UserNetworkStats) {
    state.userStats[stats.userId] = stats
    store.commit.status.updateNetworkStats(stats.userId)
  },
  updateNgcvpStats (state: NetworkstatsState, stats: NgcvpStats) {
    state.ngcvpStats[stats.castId] = stats
  },
  updateTranscodingStats (state: NetworkstatsState, stats: KeyDict<TranscodingStats>) {
    state.transcodingStats = stats
  },
  updateCefStats (state: NetworkstatsState, stats: CefStatInstances) {
    if (stats.castId === null || stats.cefmode === null) return
    if (state.cefStats[stats.castId] == undefined) {
      state.cefStats[stats.castId] = { castId: stats.castId, front: null, back: null }
    }
    if (stats.cefmode === 'full') return
    state.cefStats[stats.castId][stats.cefmode] = stats
  }
} as const
