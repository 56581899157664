import { Result } from 'kiswe-ui'
import { CloudCastEvent } from '@/types/events'
import dummyCast from './dummycast'

export const dummyCastEvent = (data: unknown): Result<CloudCastEvent, 'invalid params'|'unknown'> => {
  const eventResult = dummyCast<CloudCastEvent>()(data)
  if (eventResult.isFailure) return eventResult

  try {
    const event = eventResult.value
    if (!(event.start_date instanceof Date)) {
      // @ts-ignore
      event.start_date = new Date(event.start_date.seconds * 1000 + event.start_date.nanoseconds / 1000000)
    }
    if (!(event.end_date instanceof Date)) {
      // @ts-ignore
      event.end_date = new Date(event.end_date.seconds * 1000 + event.end_date.nanoseconds / 1000000)
    }
    return eventResult
  } catch (error) {
    return Result.fromUnknownError('invalid params', error)
  }
}
