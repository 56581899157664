import { ReplayPlaylist } from '@/modules/replay/classes'
import { Asset, AssetType } from '@/types/assets'
import { Layout, LayoutBoxField } from '@/types/layouts'
import { Stream } from '@/types/streams'
import { UserProfile } from '@/types/users'

export type BoxTypesInfo = {} | {
  types: AssetType[],
  field: LayoutBoxField
}

export enum RenderMode {
  Front = 'front',
  Back = 'back',
  Full = 'full'
}

export enum SceneContentType {
  Content = 'content',
  GlobalLayer = 'globalLayer',
  Layer = 'layer',
  Transition = 'transition',
  GlobalTransition = 'GlobalTransition'
}

export enum SceneSourceType {
  Broadcast = 'broadcast',
  Cast = 'cast',
  Generic = 'generic',
  Graphic = 'graphic',
  Layout = 'layout',
  Output = 'output',
  Playlist = 'playlist',
  Replay = 'replay',
  Talent = 'talent',
  TalentAnonymous = 'TalentAnonymous',
  Station = 'Station',
  Twitter = 'twitter',
  Websource = 'websource'
}

export const sceneContentAllowedSources: Record<SceneContentType, SceneSourceType[]> = {
  [SceneContentType.Content]: [
    SceneSourceType.Broadcast, SceneSourceType.Cast, SceneSourceType.Graphic, SceneSourceType.Layout,
    SceneSourceType.Playlist, SceneSourceType.Replay, SceneSourceType.Talent, SceneSourceType.TalentAnonymous,
    SceneSourceType.Station, SceneSourceType.Websource
  ],
  [SceneContentType.GlobalLayer]: [SceneSourceType.Graphic, SceneSourceType.Websource, SceneSourceType.Twitter],
  [SceneContentType.Layer]: [SceneSourceType.Graphic, SceneSourceType.Websource],
  [SceneContentType.Transition]: [],
  [SceneContentType.GlobalTransition]: []
}

export interface SceneSourceSelection {
  name: string,
  description: string,
  icon: string,
  type: SceneSourceType,
  isDisabled: boolean
}

export type CasterSourceInfo = Pick<UserProfile, 'id'|'first_name'>

export interface SelectedSceneSourceTypeBase {
  type: SceneSourceType
}

export interface SelectedBroadcastSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Broadcast,
  stream: Stream
}

export interface SelectedCastSourceType extends SelectedSceneSourceTypeBase {
  castId: string,
  name: string,
  type: SceneSourceType.Cast
}

export interface SelectedCasterSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Talent,
  casterInfo: CasterSourceInfo
}

export interface SelectedCasterAnonymousSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.TalentAnonymous,
  casterInfo: CasterSourceInfo
}

export interface SelectedGraphicSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Graphic,
  assets: Asset[]
}

export interface SelectedLayoutSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Layout,
  layout: Layout
}

export interface SelectedOutputSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Output,
  stream: Stream
}

export interface SelectedPlaylistSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Playlist,
  assets: Asset[]
}

export interface SelectedStationSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Station,
  casterInfo: CasterSourceInfo
}

export interface SelectedTwitterSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Twitter
}

export interface SelectedWebsourceSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Websource,
  assets: Asset[]
}

export interface SelectedReplayPlaylistSourceType extends SelectedSceneSourceTypeBase {
  type: SceneSourceType.Replay,
  replayPlaylist: ReplayPlaylist
}


export type SelectedStreamSourceType = SelectedBroadcastSourceType|SelectedOutputSourceType
export type SelectedSceneSourceType = SelectedCastSourceType|SelectedCasterSourceType|SelectedCasterAnonymousSourceType
  |SelectedLayoutSourceType|SelectedGraphicSourceType|SelectedPlaylistSourceType|SelectedReplayPlaylistSourceType
  |SelectedStationSourceType|SelectedStreamSourceType|SelectedTwitterSourceType|SelectedWebsourceSourceType

export const MAX_GLOBAL_LAYERS = 5
export const GLOBAL_LAYER_PREFIX = 'global'
export const MAX_AUTO_SWITCH_GUESTS = 5
export const GLOBAL_STINGER_ID = 'globalstinger'
export const HIDDEN_GLOBAL_PRESETS = [GLOBAL_STINGER_ID]

// Interface to be implemented by controller to work with SceneMultimediaControlPanel to manage media playlists
export interface SceneMultimediaController {
  // Methods to expose playlist state
  hasMediaContent: () => boolean, //True if scene has content to be managed by controller
  isSceneReady: () => boolean, // True if scene is ready to be managed by media controller
  isAtStart: () => boolean, // True if current media is at start of the playlist
  isAtEnd: () => boolean, // True if current media is at end of the playlist
  isCurrentMediaPlaying: () => boolean, // True if current video media is playing, false if paused

  // Methods to show/hide various media control buttons
  isPreviousVisible: () => boolean,
  isNextVisible: () => boolean,
  isPlayPauseVisible: () => boolean,
  isRestartVisible: () => boolean,

  // Methods to perform multimedia action on the media playlist
  doPrevious: () => Promise<void>,
  doNext: () => Promise<void>,
  doPlay: () => Promise<void>,
  doPause: () => Promise<void>,
  doRestart: () => Promise<void>
}


