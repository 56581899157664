import createDatabase from './database'
import { FirebaseDatabase } from './databases/firebase'
import fb from '@/firebase'
import DatabaseInterface from './databaseInterface'
import { CollectionNames, AdminDocNames } from './collectionNames'
import { DatabaseQuerySubscriptions } from './databaseQuerySubscriptions'
import { createAuth } from './auth'
import { FirebaseAuthInterface } from './auth/firebase'

const studio = {
  db: createDatabase(new FirebaseDatabase(fb.db)),
  auth: createAuth(new FirebaseAuthInterface(fb.auth)),
  updateDatabase: (dbInterface: DatabaseInterface) => {
    studio.db = createDatabase(dbInterface)
  },
  subscriptions: new DatabaseQuerySubscriptions()

}

export default studio

export {
  CollectionNames,
  AdminDocNames
}
