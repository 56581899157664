/* eslint-disable unicorn/consistent-function-scoping */
import { Ref } from 'vue'
import { SceneMultimediaController } from '../types'
import useSceneMedia from './sceneMedia'
import { VideoPlayerLocalState, VideoPlayerPlayState } from '@/types/videoplayer'
import { SceneContent } from '@/types/sceneContent'
import { useAddLog } from '@/modules/logging/compositions'

const getLogPayload = (sceneId?: string|null, content?: SceneContent|null,
  clipPlayState?: VideoPlayerLocalState|null): any => {
  const logPayload: any = { }
  if (sceneId) logPayload.sceneId = sceneId
  if (content) {
    logPayload.assetId = content.id
    logPayload.assetType = content.type
  }
  if (clipPlayState) {
    logPayload.playState = clipPlayState.playstate
    logPayload.playHead = clipPlayState.playhead
  }
  return logPayload
}

const useAssetMediaController = (sceneId: Ref<string|null>): SceneMultimediaController => {

    const { addLog } = useAddLog()
    const { sceneMediaContentsOrdered, isSceneSourcePlaylist, isClipPlayerReady, isAtStart,
      isAtEnd, currentClipPlayState, activeMediaIndex, goToPreviousAsset, goToNextAsset,
      playCurrentClip, pauseCurrentClip, restartCurrentClip } = useSceneMedia(sceneId)
    const hasMediaContent = () => {
      return sceneMediaContentsOrdered.value.length > 0
    }
    const isSceneReady = () => !isSceneSourcePlaylist.value ||
      (isSceneSourcePlaylist.value && isClipPlayerReady.value)
    const isCurrentMediaPlaying = () => currentClipPlayState.value?.playstate === VideoPlayerPlayState.PLAYING

    const isPreviousVisible = () => true
    const isNextVisible = () => true
    const isPlayPauseVisible = () => isSceneSourcePlaylist.value
    const isRestartVisible = () => isSceneSourcePlaylist.value

    const doPrevious = async (): Promise<void> => {
      const logPayload = getLogPayload(sceneId.value, sceneMediaContentsOrdered.value[activeMediaIndex.value],
        currentClipPlayState.value)
      addLog('Previous media button pressed', 'info', logPayload).catch((error) => console.error(error))
      const result = await goToPreviousAsset()
      result.logIfError(`Failed to set asset#: ${activeMediaIndex.value} active for scene: ${sceneId.value}`)
    }

    const doNext = async (): Promise<void> => {
      const logPayload = getLogPayload(sceneId.value, sceneMediaContentsOrdered.value[activeMediaIndex.value],
        currentClipPlayState.value)
      addLog('Next media button pressed', 'info', logPayload).catch((error) => console.error(error))
      const result = await goToNextAsset()
      result.logIfError(`Failed to set asset#: ${activeMediaIndex} active for scene: ${sceneId}`)
    }

    const doPlay = async (): Promise<void> => {
      const logPayload = getLogPayload(sceneId.value, sceneMediaContentsOrdered.value[activeMediaIndex.value],
        currentClipPlayState.value)
      addLog('Play media button pressed', 'info', logPayload).catch((error) => console.error(error))
      await playCurrentClip()
    }

    const doPause = async (): Promise<void> => {
      const logPayload = getLogPayload(sceneId.value, sceneMediaContentsOrdered.value[activeMediaIndex.value],
        currentClipPlayState.value)
      addLog('Pause media button pressed', 'info', logPayload).catch((error) => console.error(error))
      await pauseCurrentClip()
    }

    const doRestart = async (): Promise<void> => {
      const logPayload = getLogPayload(sceneId.value, sceneMediaContentsOrdered.value[activeMediaIndex.value],
        currentClipPlayState.value)
      addLog('Restart media button pressed', 'info', logPayload).catch((error) => console.error(error))
      await restartCurrentClip()
    }

  return {
    doPrevious,
    doNext,
    doPlay,
    doPause,
    doRestart,
    hasMediaContent,
    isPreviousVisible,
    isNextVisible,
    isPlayPauseVisible,
    isRestartVisible,
    isAtEnd: () => isAtEnd.value,
    isAtStart: () => isAtStart.value,
    isCurrentMediaPlaying,
    isSceneReady
  }
}

export default useAssetMediaController
