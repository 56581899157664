<template>
  <KisweSelect
    class="select-mixer-region"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :filterable="filterable"
    :size="size"
    :theme="theme"
    v-model="selectedRegion"
    :options="regionOptions"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import useCastProperties from '@/modules/castconfiguration/compositions/castProperties'
import { KeyDict } from '@/types'
import { CastPropertyType } from '@/types/admin'
import { KisweSelect, useSizes } from 'kiswe-ui'
import { KisweSelectOption } from 'kiswe-ui'

export default defineComponent({
  name: 'SelectMixerRegion',
  components: {
    KisweSelect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String as () => string|null,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    filterable: {
      type: Boolean,
      required: false,
      default: true
    },
    theme: {
      type: String,
      required: false,
      default: 'default'
    },
    exclude: {
      type: Array as () => string[],
      required: false,
      default: () => []
    },
    ...useSizes().props
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const selectedRegion = computed({
      get () {
        return props.modelValue
      },
      set (newVal) {
        emit('update:modelValue', newVal)
      }
    })
    // const selectedRegion = ref(props.modelValue)
    // watch(selectedRegion, (newValue) => {
    //   emit('update:modelValue', newValue)
    // })
    // watch(() => props.modelValue, (newRegion) => {
    //   selectedRegion.value = newRegion
    // })
    return {
      selectedRegion,
      ...useCastProperties()
    }
  },
  computed: {
    regionOptions (): KisweSelectOption[] {
      const castPropertyRegions: KeyDict<string> = {}
      Object.keys(this.castProperties).forEach((castPropertyId) => {
        const castProperty = this.castProperties[castPropertyId]
        if (castProperty.type === CastPropertyType.Region) {
          castPropertyRegions[castProperty.name] = castPropertyId
        }
      })
      const sortedNames = Object.keys(castPropertyRegions).sort()
      const regionlist: KisweSelectOption[] = []
      sortedNames.forEach((name: string) => {
        if (!this.exclude.includes(castPropertyRegions[name])) {
          regionlist.push({ label: name, key: castPropertyRegions[name], value: castPropertyRegions[name] })
        }
      })
      return regionlist
    }
  }
})
</script>
