import { CastType } from '@/types/casts'
import { Mixin, StudioBaseMixin } from './base'

export function hasCastType<TBase extends StudioBaseMixin, CT extends CastType> (Base: TBase, castType: CT) {
  return class CastTypeClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.castType = castType
    }
    castType: CT
  }
}

export type HasCastType = Mixin<typeof hasCastType>
