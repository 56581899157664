import { MVar } from '@/helpers/async_helpers'
import { Cast } from '@/modules/casts/classes'
import { Result } from 'kiswe-ui'
import Athlete from '@/modules/scenes/classes/Athlete'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import store from '@/store'
import { CastType } from '@/types/casts'
import { Region } from '@/types/ops'
import { computed, onUnmounted, watch } from 'vue'
import { cloneDeep } from 'lodash'

type UpdateTemplateOptions = {
  replace?: boolean
}

const teamId = computed(() => store.state.team.activeTeam)
const templates = computed(() => store.state.castTemplates.templates)

const normalizeCastTemplate = <T extends Cast|CastTemplate>(data: T): T => {
  const tmpData = cloneDeep(data)
  tmpData.scenesSettings.athlete = new Athlete()
  return tmpData
}

const createCastTemplate = async (templateName: string, data: Cast) => {
  const cast = normalizeCastTemplate(data)
  cast.name = templateName
  await store.dispatch.castTemplates.saveCastAsTemplate(cast)
}

const updateCastTemplate = async (
  templateId: string,
  data: Cast|CastTemplate,
  newName?: string,
  options?: UpdateTemplateOptions
) => {
  const template = normalizeCastTemplate(data)
  const templateName = newName ? newName : templates.value[templateId]?.name ?? data.name
  const resultLoadTemplate = CastTemplate.load({ ...template, id: templateId, name: templateName, create_event: true })
  if (resultLoadTemplate.isSuccess) {
    const resultUpdateTemplate = await store.dispatch.castTemplates.updateCastTemplate({
      template: resultLoadTemplate.value,
      replace: options?.replace ?? false
    })
    resultUpdateTemplate.logIfError(`Error updating cast template [${ templateId }] - store updateCastTemplate`)
  } else {
    resultLoadTemplate.log(`Error updating cast template [${ templateId }] - CastTemplate.load`)
  }
}

const getTempCastFromTemplate = (templateId: string): Result<Cast, 'not_found'|'invalid_params'|'unknown'> => {
  const template = templates.value[templateId] ?? null
  if (template === null) return Result.fail('not_found', `Template with id [${ templateId }] not found`)
  const result = Cast.load({
    ...template,
    templateId: template.id,
    isTempCast: true,
    create_event: false,
    team_id: teamId.value,
    start_date: new Date(),
    end_date: new Date(),
    region: (store.state.team.team?.default_region as Region) ?? Region.USEast,
  })
  return result
}

const useCastTemplates = (castType?: CastType) => {
  let hasSubscribed = false

  const subscribeLock = new MVar<void>()
  subscribeLock.put() // Allow onUnmounted to continue in case we never subscribe.

  watch (teamId, async (newVal, oldVal) => {
    if (hasSubscribed && oldVal) await store.dispatch.castTemplates.unsubscribeTeamCastTemplates(castType)
    hasSubscribed = false
    if (newVal === '') return
    subscribeLock.empty()
    const result = await store.dispatch.castTemplates.subscribeTeamCastTemplates({ teamId: newVal, castType  })
    if (result.isSuccess) hasSubscribed = true
    subscribeLock.put()
  }, { immediate: true })

  onUnmounted (async () => {
    await subscribeLock.read()
    if (hasSubscribed) await store.dispatch.castTemplates.unsubscribeTeamCastTemplates(castType)
  })

  return {
    createCastTemplate,
    getTempCastFromTemplate,
    templates,
    updateCastTemplate
  }
}

export default useCastTemplates
