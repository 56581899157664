import { moduleActionContext } from '@/store'
import state from './casts.state'
import actions, { CastsContext } from './casts.actions'
import mutations from './casts.mutations'
import getters from './casts.getters'
import { defineModule } from "direct-vuex"


const castsModule = defineModule({
  namespaced: true,
  state,
  actions,
  getters,
  mutations
})

export default castsModule
export const castsModuleActionContext = (context: CastsContext) => moduleActionContext(context, castsModule)
