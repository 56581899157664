import { Loader } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'

interface HasTeamProps {
  required: boolean
}

export function hasTeam<TBase extends StudioBaseMixin> (Base: TBase, props: Partial<HasTeamProps> = {}) {
  return class DeleteClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.team_id = Loader.loadString(arg[0], 'team_id', props.required ? undefined : '')
    }
    // @ts-ignore
    private __hasTeam: true = true
    team_id: string

    setTeam (teamId: string): this {
      this.team_id = teamId
      return this
    }
  }
}

export type Teamed = Mixin<typeof hasTeam>

export const hasTeamMixin = (obj: unknown): obj is Teamed => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasTeam' in obj) return true
  return false
}
