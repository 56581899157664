import store from '@/store'
import { computed } from 'vue'
import releaseNotes from '@/ReleaseNotes'

const currentVersion = computed(() => store.state.team.team?.version ?? null)
const currentTagId = computed(() => releaseNotes().tagId)

const useTeamVersion = () => {
  return {
    currentVersion,
    currentTagId
  }
}

export default useTeamVersion
