import store from '@/store'

const addLog = async (message: string, level: 'info'|'warning'|'error', payload?: object) => {
  await store.dispatch.logs.addLog({
    ...payload,
    message,
    level
  })
}

const addLogError = async (message: string, error: any, payload?: object) => {
  await store.dispatch.logs.addLog({
    ...payload,
    message,
    level: 'error',
    data: {
      error: error
    }
  })
}

const addLogWarning = async (message: string, error: any, payload?: object) => {
  await store.dispatch.logs.addLog({
    ...payload,
    message: message,
    level: 'warning',
    data: {
      error: error
    }
  })
}


const addLogNoWait = (message: string, level: 'info'|'warning'|'error', payload?: object) => {
  addLog(message, level, payload).catch((error) => console.error('Error adding log', error, message, payload))
}


const useAddLog = () => {
  return {
    addLog,
    addLogWarning,
    addLogError,
    addLogNoWait
  }
}

export default useAddLog
