import { moduleActionContext } from '@/store'
import state from './usermedia.state'
import actions, { UserMediaContext } from './usermedia.actions'
import mutations from './usermedia.mutations'
import getters from './usermedia.getters'
import { defineModule } from 'direct-vuex'

const usermediaModule = defineModule({
  namespaced: true,
  state,
  actions,
  getters,
  mutations
})

export default usermediaModule
export const usermediaModuleActionContext = (context: UserMediaContext) => moduleActionContext(context, usermediaModule)
