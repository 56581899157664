import { CastStream } from '@/types/streams'
import { Loader } from 'kiswe-ui'
import { LABEL_TO_MIXER_TRACK_MAP } from '../../../types/streams'

export type ChannelType = 'Broadcast'|'Caster'|'Nats'|'Crew'|'ALL'|'Output'|'Playlist'|'RemoteCrew'|'ScreenShare'
  |'Replay'

export interface AudioControl extends CastStream {
  name: string
  channelType: ChannelType
  channel?: number
  disabledUserId?: string
}

export type TrackControlKeyInfo = { trackName: string, controlKeys: string[] }

export const DEFAULT_TRACK_GROUP = '_default_'
export const CREW_TRACK_GROUP = '_crew_'
export const OUTPUT_TRACK_GROUP = '_outputs_'
// The maximum amount of `CasterTrackX` labels that we support (in the mixer/video backend).
export const MIXER_CASTER_TRACKS_LIMIT = 13
export const MAX_CASTERS_PER_TRACK = 4
export const MAX_TRACKS_SWITCHER = 10 // Limits the amount of tracks in the UI, can't exceed MIXER_CASTER_TRACKS_LIMIT
export const DEFAULT_TRACKS_SWITCHER = 2

export class CastAudioTrack {
  name: string
  order: number

  constructor (data: unknown) {
    this.name = Loader.loadString(data, 'name')
    this.order = Loader.loadNumber(data, 'order')
  }
}

export type defaultMixerTrackLabel = keyof typeof LABEL_TO_MIXER_TRACK_MAP
export type MixerTrackLabel = keyof typeof LABEL_TO_MIXER_TRACK_MAP | string
export type MixerTrackLabelWithDefault = MixerTrackLabel|typeof DEFAULT_TRACK_GROUP
                                         |typeof CREW_TRACK_GROUP|typeof OUTPUT_TRACK_GROUP

export type CastAudioTracksDict = Partial<Record<MixerTrackLabel, CastAudioTrack>>

export interface AudioTrackInfo {
  trackId: 1      // set to number if we ever need multiple input tracks from a caster
}

export type AudioTrackInfoDict = Partial<Record<MixerTrackLabel, AudioTrackInfo>>

export enum TrackGroupType {
  DEFAULT = 'default',
  CASTERS = 'casters',
  OUTPUTS = 'outputs'
}
