export interface DeviceOption {
  value: string,
  text: string,
  deviceId: string
}

export class DeviceList {
  audio = {
    input: [] as DeviceOption[],
    output: [] as DeviceOption[]
  }
  video = {
    input: [] as DeviceOption[]
  }
}

export interface Resolution {
  width: number,
  height: number
}

export enum MediaDevice {
  Camera = 'camera',
  Microphone = 'microphone'
}

export enum MediaType {
  Audio = 'audio',
  Video = 'video',
  Image = 'image'
}

export interface UserMediaPermissions {
  audio: boolean|null
  video: boolean|null
}

export enum ConnectStatus {
  Connected = 'connected',
  Connecting = 'connecting',
  Disconnected = 'disconnected',
  Disconnecting = 'disconnecting',
  Error = 'error'
}
