import { AssetType } from '@/types/assets'
import { LayoutBox, LayoutBoxField, LayoutBoxType } from '@/types/layouts'
import { Scene } from '@/types/scenes'
import { BoxTypesInfo, SceneSourceType } from '../types'

export const numberOfCasterBoxes = (scene?: Scene) => {
  return Object.values(scene?.scene_layout.boxes ?? {}).filter((box) => box.types?.includes(AssetType.Caster)).length
}

export const createFullscreenBox = (
  boxId: string,
  zIndex: number,
  contentType: AssetType,
  layoutBoxField: LayoutBoxField,
  autoActive?: boolean
): LayoutBox => {
  return new LayoutBox({
    active: autoActive ?? false,
    autoActive: autoActive ?? false,
    dedicated: contentType === AssetType.Caster,
    field: layoutBoxField,
    id: boxId,
    name: boxId,
    type: LayoutBoxType.BOX,
    types: [contentType],
    zindex: zIndex
  })
}

// eslint-disable-next-line complexity
export const getBoxTypeInfoForSceneSourceType = (sourceType: SceneSourceType): BoxTypesInfo => {
  switch (sourceType) {
    case SceneSourceType.Broadcast:
      return { types: [AssetType.Broadcast], field: LayoutBoxField.BACK }
    case SceneSourceType.Cast:
      return { types: [AssetType.Cast], field: LayoutBoxField.BACK }
    case SceneSourceType.Graphic:
      return { types: [AssetType.Graphic, AssetType.Video], field: LayoutBoxField.BACK }
    case SceneSourceType.Playlist:
      return { types: [AssetType.Video], field: LayoutBoxField.BACK }
    case SceneSourceType.Replay:
      return { types: [AssetType.Broadcast], field: LayoutBoxField.BACK }
    case SceneSourceType.Station:
    case SceneSourceType.Talent:
    case SceneSourceType.TalentAnonymous:
      return { types: [AssetType.Caster], field: LayoutBoxField.CASTER }
    case SceneSourceType.Websource:
      return { types: [AssetType.Widget], field: LayoutBoxField.BACK }
    default:
      console.warn(`Unsupported sceneSourceType ${sourceType} for asset types`)
      return {}
  }
}
