import fb from '@/firebase'
import { UserProfile } from '@/types/users'
import { KeyDict } from '@/types'
import { CollectionNames } from '@/modules/database/utils'
import firebase from 'firebase/compat/app'

export type DefaultIdsMapping = {
  layoutIds: Map<string, string>,
  assetIds: Map<string, string>,
  assetGroupIds: Map<string, string>,
  streamIds: Map<string, string>
}

export const removeExpiredNotifications = async (member: UserProfile) => {
  const now = new Date().getTime()
  const expiredKeys = Object.keys(member.notifications).filter((key) => Number.parseInt(key,10) < now)
  if (expiredKeys.length > 0) {
    // mark keys for deletion
    const deleteupdate: KeyDict<firebase.firestore.FieldValue> = {}
    expiredKeys.forEach((expiredKey) => {
      deleteupdate['notifications.' + expiredKey] = fb.deleteField
    })

    await fb.db.collection(CollectionNames.PROFILES)
      .doc(member.user_id)
      .update(deleteupdate)
  }
}
