import { SocialMediaQuality, StreamRateWithProps } from '@/classes/SocialMediaQuality'
import { Loader, Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CastOutputStream, CompositeOutputStream } from '@/types/casts'
import { CustomRtmpDestination, Stream, StreamRate, StreamType } from '@/types/streams'
import { Mixin, GConstructor } from './base'
import { Framerate, HasQuality } from './hasQuality'
import { parseQuality } from '@/modules/streams/utils/quality'

// eslint-disable-next-line max-lines-per-function
export function hasCustomOutputs<TBase extends GConstructor<HasQuality>> (Base: TBase) {
  return class CustomOutputsClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.custom_rtmp_destinations = Loader
        .loadAndCast<KeyDict<CustomRtmpDestination>>(arg[0], 'custom_rtmp_destinations', {})
    }
    custom_rtmp_destinations: KeyDict<CustomRtmpDestination>

    addOutput (outputStream: Stream, streamToAdd: string, active: boolean, backup?: boolean): Result<this, 'unknown'> {
      const stream: CustomRtmpDestination = {
        url: outputStream.srcurl,
        type: outputStream.type === StreamType.StationOutput ? StreamType.StationOutput : StreamType.Stream,
        streamname: outputStream.name,
        valid: true,
        start_time: Date.now() / 1000,
        active,
        backup
      }
      if (outputStream.rate !== undefined) stream.rate = outputStream.rate
      const socialmedia = SocialMediaQuality.getSocialMedia(stream.url)
      if (socialmedia) {
        stream.socialmedia = socialmedia
        let rate = stream.rate !== undefined ? stream.rate : this.castRate()
        const newRate = this.getDefinedStreamRateProps(rate)
        rate = SocialMediaQuality.getMaxQuality(socialmedia, newRate)
        if (rate.adjusted) stream.rate = rate
      }
      this.custom_rtmp_destinations[streamToAdd] = stream
      return Result.success(this)
    }

    getDefinedStreamRateProps (rate: StreamRate): StreamRateWithProps {
      const newRate: StreamRateWithProps = {
        ...rate,
        fps: rate.fps ?? this.castRate().fps,
        height: rate.height ?? this.castRate().height,
        width: rate.width ?? this.castRate().width,
        video_bitrate: rate.video_bitrate ?? this.castRate().video_bitrate
      }
      return newRate
    }

    toggleOutputActive (streamId: string, active?: boolean): Result<this, 'not_found'> {
      if (!this.custom_rtmp_destinations[streamId]) {
        return Result.fail('not_found', `Output stream with id ${ streamId } not found`)
      }
      this.custom_rtmp_destinations[streamId].active = active ?? !this.custom_rtmp_destinations[streamId].active
      return Result.success(this)
    }

    removeOutput (streamId: string): Result<this, 'not_found'> {
      if (!this.custom_rtmp_destinations[streamId]) {
        return Result.fail('not_found', `Output stream with id ${ streamId } not found`)
      }
      delete this.custom_rtmp_destinations[streamId]
      return Result.success(this)
    }

    getOutputStream (
      key: string,
      outputStreams: KeyDict<Stream>,
      customStreams: KeyDict<CustomRtmpDestination>
    ): CompositeOutputStream {
      // this output stream is a combo of outputStream and custom stream
      const outputStream = outputStreams?.[key] !== undefined ? outputStreams[key] : {} as CastOutputStream
      const customStream = customStreams?.[key] !== undefined ? customStreams[key] : {} as CustomRtmpDestination
      const newStream =  { ...customStream, ...outputStream } as CompositeOutputStream
      return newStream
    }

    getOutputStreamName (stream: CustomRtmpDestination, defaultName: string, teamStreams: KeyDict<Stream>): string {
      let name = stream.streamname.trim() ? stream.streamname : defaultName
      name = name.charAt(0).toUpperCase() + name.slice(1)
      let existingFound = false
      Object.values(teamStreams).forEach((teamStream) => {
        if (teamStream.srcurl === stream.url && teamStream.type === StreamType.Output) {
          if (teamStream.deleted === false) {
            name = teamStream.name
            existingFound = true
          } else if (!existingFound && teamStream.name !== undefined) {
            name = teamStream.name
          }
        }
      })
      return name
    }

    // TODO: Should we support 4k here instead of limiting to 1080p? Note that this method seems to only be indirectly
    //       used for SocialMedia outputs. So are there any social media platforms that support 4k?
    private castRate (): { video_bitrate: number, fps: Framerate, width: number, height: number } {
      const parsedQuality = parseQuality(this.quality)
      const rate = {
        video_bitrate: parsedQuality.bitrate,
        fps: this.framerate,
        width: 1280,
        height: 720
      }
      if (parsedQuality.resolution >= 1080) {
        rate.width = 1920
        rate.height = 1080
      }
      return rate
    }
  }
}
export type HasCustomOutputs = Mixin<typeof hasCustomOutputs>
