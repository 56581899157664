import { CastChangeHandler } from '../castChangeHandler'
import { isEqual } from 'lodash'
import store from '@/store'
import { Cast } from '@/modules/casts/classes'

export class CastChangeHandlerCustomStreams extends CastChangeHandler {
  handleChanges (oldCast: Cast|null, newCast: Cast|null): void {
    if (isEqual(oldCast?.custom_rtmp_destinations, newCast?.custom_rtmp_destinations)) return
    if (!newCast?.id || this.currentCastId === null || this.currentCastId !== newCast.id) return
    store.commit.events.updateCustomStreams(newCast.custom_rtmp_destinations ?? {})
  }
}
