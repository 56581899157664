import CheckFeature from '../checkfeature'
import { Feature } from '../featureFlags'
import { Team } from '@/modules/teams/classes'

export default class H264StreamingFeature extends CheckFeature {

  override hasFeature (): boolean {
    return this.featureFlags.get(Feature.H264_STREAMING) ?? false
  }

  override teamHasFeature (_teamId: string): boolean {
    return false
  }

  override updateTeamFeature (_teamId: string, _enabled: boolean) : void {
  }

  override isGlobalFeature () : boolean {
    return true
  }

  override get description (): string {
    return `Every caster will stream using the h264 codec instead of vp8.`
  }

  override assignTeamFeature (_team: Partial<Team>, _enabled: boolean) {
  }
}
