import fb from '@/firebase'
import helpers from '@/helpers'
import { UnsubscribeFunction } from '@/store/types'
import { AudioMixType, InvitedCaster } from '@/types/casts'
import {
  CustomRtmpDestination, StreamType, SourceStream, SourceStreamCaster, SourceStreamModerator, SourceStreamNonCaster
} from '@/types/streams'
import { AssetType, AssetGroup, SocialMediaTypes } from '@/types/assets'
import { contentSortByOrder, SceneContent } from '@/types/sceneContent'
import { Scene } from '@/types/scenes'
import store from '@/store'
import { cloneDeep, merge, reduce } from 'lodash'
import { Widget } from '@/types/widgets'
import { KeyDict, TalkbackMode, DeepPartial, Optional, BreakoutMode } from '@/types'
import { AdminDocNames, CollectionNames } from '@/modules/database/utils'
import { ClipEndAction, VideoPlayer } from '@/types/videoplayer'
import type { CloudCastEvent, EventCast } from '@/types/events'
import { difference } from '@/helpers/difference'
import firebase from 'firebase/compat/app'
import router from '@/router'
import { LayoutBox } from '@/types/layouts'
import Convertable from '@/classes/Convertable'
import { MVar } from '@/helpers/async_helpers'
import { AudioPayload } from '@/types/audio'
import moment from 'moment'
import { LinkPointer } from '@/classes/LinkPointer/LinkPointer'
import { PointerTypes } from '@/classes/LinkPointer/LinkPointerBase'
import { EventsContext, eventsModuleActionContext } from '.'
import { isAssetInCast, maxOrderOfType, removeCasterFromInvitedCasters, updateCast } from './events.helpers'
import studio from '@/modules/database/utils'
import { DatabaseAction, MergeOption } from '@/modules/database/utils/database'
import { CreateClip, CreateClipType } from '@/types/admin'
import { AudioTrackInfoDict, MixerTrackLabel, MixerTrackLabelWithDefault } from '@/modules/audio/types'
import { Cast } from '@/modules/casts/classes'
import { CastInputStream } from '@/modules/streams/types'
import { generateRandomString, sanitizeStringForUrl } from '@/modules/common/utils'
import { Result, ResultVoid } from 'kiswe-ui'
import { CastK360Info } from '@/modules/casts/types'
import { FirestoreDocSnapper, FirestoreQuerySnapper, FirestoreSnapperType } from '@/modules/database/utils/databaseQuerySubscriptions'
import FirebasePresence from '@/classes/FirebasePresence'
import { RenderMode, SceneContentType } from '@/modules/scenes/types'
import { UpdateFunction } from '@/modules/base/types'
import { hasCastersMixin } from '@/modules/classBuilder/mixins/hasCasters'
import { ScenePreset, ScenesSettings } from '@/modules/scenes/classes'
import { getGlobalLayerIds } from '@/modules/scenes/utils/globalPresets'
import { ActionPaletteTabs } from '@/modules/ui/types'
import { ClipContainerFormatType, ClipInfo } from '@/modules/archiver/types'
import { UpdateEventProps } from '@/modules/events/types'

interface RequestCustomAudioMix {
  castId: string
  casterId: string
  volumes: KeyDict<number>
  audioMixType: AudioMixType
}

interface RequestSwitchToAudioMix {
  castId: string
  casterId: string
  audioMixType: AudioMixType
}
export interface SetCasterStreamInfo {
  streamId: string
  type: StreamType.Caster|StreamType.Moderator|StreamType.NonCaster,
  sessionId: string
  userId: string
  muted?: boolean
}

interface EventSubscriptions {
  outputStreams: { [streamId: string]: UnsubscribeFunction }
  updateSourceStreamDocs: { [streamId: string]: UnsubscribeFunction }
}

const subscriptions: EventSubscriptions = {
  outputStreams: {},
  updateSourceStreamDocs: {}
}

const actions = {
  async subscribeCurrentPlaylist (context: EventsContext, castId: string) {
    const { commit } = eventsModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'currentplaylist',
      query: studio.db.collection(CollectionNames.PLAYLIST_STATUS).doc(castId),
      callback: commit.updateCurrentPlaylist
    })
    result.logIfError('subscribeCurrentPlaylist')
  },
  async unsubscribeCurrentPlaylist (_context: EventsContext) {
    studio.subscriptions.unsubscribe('currentplaylist')
  },
  async switchCurrentCast (context: EventsContext, castId: string) {
    const { commit } = eventsModuleActionContext(context)
    commit.setCurrentCastId(castId)
    studio.subscriptions.updateDocQueryFB('currentcast', fb.db.collection('casts').doc(castId))
  },
  async subscribeCurrentCast (context: EventsContext, castId: string): Promise<ResultVoid<'subscription'>> {
    const { state, commit, dispatch } = eventsModuleActionContext(context)
    if (state.currentCast !== null && state.currentCastId !== castId && studio.subscriptions.exists('currentcast')) {
      throw new Error('Cannot subscribe to a new cast while there is another current cast. Use switchCurrentCast instead.')
    }
    commit.setCurrentCastId(castId)
    // FIXME -> this needs to go, components needs to subscribe themselfe
    await dispatch.subscribeCurrentPlaylist(castId)
    const result = await studio.subscriptions.subscribe({
      key: 'currentcast',
      query: studio.db.collection(CollectionNames.CASTS).doc(castId),
      callback: commit.updateCurrentCast,
      waitForIt: true
    })
    if (result.isFailure) return result.convert()
    const sessionId = store.state.user.sessionId
    const currentUserId = store.state.user.id
    if (store.state.user.presence?.sessionId !== sessionId && currentUserId !== null) {
      const presence = new FirebasePresence({ sessionId, uid: currentUserId, castId })
      store.dispatch.user.setPresence(presence)
    }
    store.state.user.presence?.addCast(castId)
    return Result.ok()
  },
  async unsubscribeCurrentCast (context: EventsContext) {
    const { dispatch, commit } = eventsModuleActionContext(context)
    await dispatch.unsubscribeCurrentPlaylist()
    studio.subscriptions.unsubscribe('currentcast', commit.clearCurrentCast)
  },
  async subscribeCurrentCastCef (context: EventsContext, castId: string): Promise<ResultVoid<'subscription'>> {
    const { commit, dispatch } = eventsModuleActionContext(context)
    // FIXME -> this needs to go, components needs to subscribe themselfe
    dispatch.subscribeCurrentPlaylist(castId)
    commit.setCurrentCastId(castId)
    // -------------------------
    const result = await studio.subscriptions.subscribe({
      key: 'currentcastCef',
      query: studio.db.collection(CollectionNames.CASTS).doc(castId),
      callback: commit.updateCurrentCast,
      waitForIt: true
    })
    if (result.isFailure) return result.convert()
    return Result.ok()
  },
  async unsubscribeCurrentCastCef (context: EventsContext) {
    const { dispatch } = eventsModuleActionContext(context)
    studio.subscriptions.unsubscribe('currentcastCef')
    await dispatch.unsubscribeCurrentPlaylist()
  },
  async addNewCompletedEvent (context: EventsContext, event: CloudCastEvent) {
    const { commit } = eventsModuleActionContext(context)
    commit.addNewCompletedEvent(event)
  },
  async subscribeEvents (context: EventsContext, teamId: string) {
    const { commit } = eventsModuleActionContext(context)
    const now = new Date()
    const result = await studio.subscriptions.subscribe({
      key: 'currentevents',
      query: studio.db.collection(CollectionNames.EVENTS)
        .where('team_id', '==', teamId)
        .where('end_date', '>', now)
        .where('deleted', '==', false),
      callback: commit.updateEvents,
      waitForIt: true
    })
    result.logIfError('Error subscribing to current events')
  },
  async unsubscribeEvents (_context: EventsContext) {
    studio.subscriptions.unsubscribe('currentevents')
  },
  async subscribeCompletedEvent (context: EventsContext, teamId: string) {
    const { commit } = eventsModuleActionContext(context)
    const now = new Date()
    const result = await studio.subscriptions.subscribe({
      key: 'completedevents',
      query: studio.db.collection(CollectionNames.EVENTS)
        .where('team_id', '==', teamId)
        .where('end_date', '<', now)
        .where('deleted', '==', false),
      callback: commit.updateCompletedEvents
    })
    result.logIfError('Error subscribing to completed events')
  },
  async unsubscribeCompletedEvent (_context: EventsContext) {
    studio.subscriptions.unsubscribe('completedevents')
  },
  async subscribeDumpStateRequests (context: EventsContext, castId: string) {
    const { commit } = eventsModuleActionContext(context)
    const snapper : FirestoreDocSnapper = {
      doc: fb.db.collection(CollectionNames.CAST_DUMPS).doc(castId),
      mutation: commit.updateDumpStateRequests,
      type: FirestoreSnapperType.Document
    }
    const key = `cast_dumps/${castId}`
    await studio.subscriptions.subscribeFB(key, snapper, undefined, true)
  },
  async unsubscribeDumpRequests (_context: EventsContext, castId: string) {
    const key = `cast_dumps/${castId}`
    studio.subscriptions.unsubscribeFB(key)
  },
  async requestDumpState (_context: EventsContext, params: { castId:string, teamId:string } ) : Promise<void> {
    console.log('requestDumpState params=', params)
    const now = '' + (new Date().getTime())
    const update = {
      [now] : {},
      team_id: params.teamId
    }
    try {
      const docRef = fb.db.collection(CollectionNames.CAST_DUMPS).doc(params.castId)
      await docRef.set(update, { merge : true })
    } catch (error) {
      console.error('requestDumpState', error)
    }
  },
  async subscribeActiveCasts (context: EventsContext, teamId: string) {
    const { commit } = eventsModuleActionContext(context)
    const now = new Date()
    const snapper: FirestoreQuerySnapper = {
      query: fb.db
               .collection(CollectionNames.CASTS)
               .where('team_id', '==', teamId)
               .where('end_date', '>', now)
               .where('deleted', '==', false),
      mutation: commit.updateActiveCasts,
      type: FirestoreSnapperType.Query
    }
    studio.subscriptions.subscribeFB(`currentcasts`, snapper)
  },
  async unsubscribeActiveCasts (_context: EventsContext) {
    studio.subscriptions.unsubscribeFB(`currentcasts`)
  },
  async clearCurrentCast (context: EventsContext) {
    // FIXME needs to go, each thing should unsubscribe itself
    const { dispatch, commit } = eventsModuleActionContext(context)
    commit.clearCurrentCast()
    await dispatch.unsubscribeCurrentCast()
    await dispatch.unsubscribeCurrentPlaylist()
  },
  //
  setInCast (context: EventsContext, flag: boolean) {
    const { commit } = eventsModuleActionContext(context)
    commit.setInCast(flag)
  },
  async cefHeartbeat (_state: EventsContext, instanceData: any) {
    if (!instanceData || !instanceData.castId || !instanceData.cefmode) {
      return
    }

    const documentId = instanceData.castId + '_' + instanceData.cefmode.toLowerCase()
    const documentData = {
      castId: instanceData.castId,
      cefmode: instanceData.cefmode,
      instances: {
        [instanceData.key]: instanceData
      }
    }
    try {
      await fb.db
        .collection('cefstats')
        .doc(documentId)
        .set(documentData, { merge: true })
    } catch (error) {
      console.error('cef heartbeat error ', error)
    }
  },
  updateCurrentCastFromLocalStorage (context: EventsContext, payload: string | null) {
    if (payload) {
      const { commit } = eventsModuleActionContext(context)
      const cast = Cast.load(JSON.parse(payload))
      if (cast.isFailure) {
        cast.log('Could not parse cast from local storage')
        return
      }
      commit.updateCurrentCastFromDoc(cast.value)
    }
  },
  async createEvent (_context: EventsContext, event: CloudCastEvent) {
    try {
      const now = (new Date()).getTime()
      const db = fb.db
      const teamRef = db.collection('teams').doc(event.team_id)
      const eventRef = db.collection('events').doc() // generated client side...

      const eventBatch = db.batch()
      eventBatch.set(eventRef, event)
      eventBatch.set(teamRef, { lastEventTimestamp: now}, {merge : true})
      await eventBatch.commit()
    } catch (error) {
      console.error('Error adding event: ', error)
    }
  },
  async makeClip (_context: EventsContext, clip: ClipInfo): Promise<ResultVoid<'database'|'unknown'>> {
    try {
      const clipIdElem = clip.clipId !== undefined ? { clip_id: clip.clipId } : null
      const createClip: CreateClip = {
        k360event: clip.k360event,
        mode: CreateClipType.Clip,
        start: clip.start,
        end: clip.end,
        name: clip.name,
        tracks: clip.tracks,
        status: 'new',
        message: null,
        progress_pct: 0,
        stream: clip.stream ?? 'stream1', // Default name for output stream
        containerFormat: clip.containerFormat ?? ClipContainerFormatType.Mp4,
        ...clipIdElem
      }
      const id = 'clip' + new Date().getTime()
      const data = {
        [clip.cast]: {
          [id]: createClip
        }
      }
      const result = await studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.MP4_CREATE).set(data, MergeOption.MERGE)
      if (!result.isSuccess) return result
      const data2: DeepPartial<CastK360Info> = {
        clips: {
          [id]: {
            start: clip.start,
            end: clip.end,
            name: clip.name,
            url: 'running',
            ...clipIdElem
          }
        }
      }
      const result2 = await studio.db.collection(CollectionNames.CASTS).doc(clip.cast).set(data2, MergeOption.MERGE)
      if (!result2.isSuccess) return result2
    } catch (error) {
      return Result.fail('unknown', `Error in create clip ${error}`)
    }
    return Result.ok()
  },
  async updateScene (_context: EventsContext, params: { castId: string, sceneId: string, update: DeepPartial<Scene> }) {
    const result = await studio.db.collection(CollectionNames.CASTS).doc(params.castId).set({
      scenes: {
        [params.sceneId]: params.update
      }
    }, MergeOption.MERGE)
    result.logIfError('Could not updateScene')
  },
  async deleteClip (_context: EventsContext, event: { clipId: string, cast: string }) {
    const data: DeepPartial<Cast> = {
      clips: {
        [event.clipId]: { deleted: true }
      }
    }
    const result = await studio.db.collection(CollectionNames.CASTS).doc(event.cast).set(data, MergeOption.MERGE)
    result.logIfError('Could not delete Clip')
  },
  async updateEvent (context: EventsContext, event: UpdateEventProps) {
    const { dispatch } = eventsModuleActionContext(context)
    const eventProps: UpdateEventProps & { meta?: { casts: KeyDict<Pick<EventCast, 'athlete'>> }} = { ...event }
    delete eventProps.castId
    delete eventProps.scenesSettings
    const endDate = eventProps.end_date
    eventProps.end_date = helpers.getAtLeastStartDate(eventProps, endDate)

    const athlete = event.scenesSettings?.athlete
    if (athlete && (athlete.discipline || athlete.gender) && event.castId) {
      const metaCastProps: Required<Pick<EventCast, 'athlete'>> = { athlete: {} }
      if (athlete.discipline) metaCastProps.athlete.discipline = athlete.discipline
      if (athlete.gender) metaCastProps.athlete.gender = athlete.gender
      eventProps.meta = { casts: { [event.castId]: metaCastProps } }
    }

    const result = await studio.db.collection(CollectionNames.EVENTS).doc(eventProps.id)
      .set(eventProps, MergeOption.MERGE)
    if (result.isSuccess) await dispatch.updateCastEventProps(event)
    else result.log('Could not update event')
  },
  async updateCastEventProps (_context: EventsContext, event: UpdateEventProps) {
    try {
      const results = await studio.db
        .collection(CollectionNames.CASTS)
        .where('team_id', '==', event.team_id)
        .where('event_id', '==', event.id)
        .where('deleted', '==', false)
        .get()
      if (!results.isSuccess) return
      const nowSeconds = Date.now() / 1000
      for (const [castId, cast] of Object.entries(results.value)) {
        if (cast.end_date.getTime() / 1000 > nowSeconds) {
          const startDateEvent = !!event.start_date ? moment(event.start_date) : null
          const startDateCast = cast.start_date.getTime() / 1000
          const updates: DeepPartial<Cast> = {
            name: event.name,
            end_date: event.end_date,
            scenesSettings: new ScenesSettings(event.scenesSettings)
          }
          //Include new 'start_date' unless cast already started
          if (startDateCast > nowSeconds && startDateEvent !== null) {
            updates.start_date = startDateEvent.toDate() > new Date() ? startDateEvent.toDate() : new Date()
          }
          const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).set(updates, MergeOption.MERGE)
          result.logIfError('Update Cast Start & End')
        }
      }
    } catch (error) {
      console.error('Update Cast Start & Ends', error)
    }
  },
  /** @deprecated */
  async createCast (_context: EventsContext, castParams: Partial<Cast>) : Promise<string> {
    let savedCast //: DeepPartial<Cast> = {}
    try {
      if (castParams.team_id === undefined) throw new Error('New cast must have a team id.')
      const castName = castParams.name
      if (castName === undefined) throw new Error('New cast must have a name.')
      const teamId = castParams.team_id

      // Note: A Transaction doesn't have `add()`, so we need to get a ref first and then use `set()`
      // Note: A Transaction requires all reads to happen before the writes.
      const releaseDocRef = fb.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.RELEASE)
      const chatDocRef = fb.db.collection(CollectionNames.CHATS).doc()
      const castDocRef = fb.db.collection(CollectionNames.CASTS).doc()
      const videoDocRef = fb.db.collection(CollectionNames.VIDEO_PLAYER).doc(castDocRef.id)
      const widgetDocRef = fb.db.collection(CollectionNames.WIDGETS).doc()
      const eventDocRef = fb.db.collection(CollectionNames.EVENTS).doc()
      const teamDocRef = fb.db.collection(CollectionNames.TEAMS).doc(teamId)
      const pointerDocRef = fb.db.collection(CollectionNames.POINTERS).doc()

      return await fb.db.runTransaction(async (transaction: firebase.firestore.Transaction) => {
        let defaultVersion = '1.0.0'
        //let defaultVersion = store.state.admin.release?.default ?? '1.0.0' // Do we need to `subscribeRelease()`?
        // FIXME: Because of merging issues I now collect this from the db directly. A better approach is to get it
        // from somewhere else in the store, so this can be rewritten as soon as those changes are on dev or stage
        const releases = await transaction.get(releaseDocRef)
        if (releases.exists) {
          const data = releases.data()
          if (data?.default) defaultVersion = data.default
        }
        const version = store.state.team.team?.version ?? defaultVersion
        const eventWithId: CloudCastEvent = {
          id: '',
          team_id: teamId,
          name: castName,
          asset_groups: {},
          deleted: false,
          start_date: castParams.start_date ?? new Date(),
          end_date: castParams.end_date ?? new Date()
        }
        const event: Partial<CloudCastEvent> = eventWithId
        delete event.id

        transaction.set(eventDocRef, event)
        transaction.set(teamDocRef, { lastEventTimestamp: Date.now() }, { merge: true })

        transaction.set(chatDocRef, { team_id: castParams.team_id, messages: [] })
        let baseScenes: KeyDict<DeepPartial<Scene>> = {}
        const sourceStreams: KeyDict<SourceStream> = {
          playlist: {
            active: true,
            start_time: Date.now() / 1000,
            type: StreamType.Playlist,
            volume: 0
          }
        }
        let activeScene: string|null = null
        if (castParams.copying || (castParams.scenes !== undefined && Object.keys(castParams.scenes).length > 0)) {
          if (castParams.scenes) {
            baseScenes = castParams.scenes
          }
          activeScene = castParams.activeScene ?? Object.keys(baseScenes)[0]
        } else {
          activeScene = helpers.makeId()

          const teamLayouts = store.state.team.teamLayouts
          const defaultTeamLayoutId = store.state.team.defaultTeamLayout
          const defaultLayout = teamLayouts.find(layout => {
            return layout && layout.id === defaultTeamLayoutId
          })
          if (!defaultLayout) {
            console.error('Team of cast has no default layout!', castParams)
          }

          // ADD LAYOUT CONTENT
          let defaultContents: KeyDict<SceneContent> = {}
          if (defaultLayout?.contents !== undefined) {
            defaultContents = {
              ...defaultLayout.contents
            }
          }

          baseScenes[activeScene] = {
            contents: defaultContents,
            name: 'Scene 1',
            id: activeScene,
            status: 'active',
            layout_id: defaultLayout?.name ?? 'unknown',
            scene_layout: defaultLayout ?? {}
          }
        }

        const baseStream: SourceStream = {
          active: true,
          start_time: Date.now() / 1000,
          type: StreamType.Broadcast,
          volume: 0,
          volumeLeft: 0,
          volumeRight: 0,
          muted: false,
          leftMuted: false,
          rightMuted: false
        }

        // loop through and add the streams we defined
        if (castParams.input_streams !== undefined) {
          Object.keys(castParams.input_streams).forEach(key => {
            sourceStreams[key] = baseStream
          })
        }

        // cleanup all the scenes broadcast streams to make sure they match what has been chosen
        if (castParams.scenes !== undefined) {
          for (const scene of Object.values(castParams.scenes)) {
            if (scene.contents) {
              for (const key in scene.contents) {
                const tmpContent = scene.contents[key]
                if (tmpContent.type === AssetType.Broadcast && castParams.input_streams !== undefined && castParams.input_streams[tmpContent.id] === undefined) {
                  delete scene.contents[key]
                }
              }
            }
          }
        }

        if (castParams.copying) {
          if (castParams.id !== undefined) delete castParams.id
          if (castParams.k360_event_id !== undefined) delete castParams.k360_event_id
          if (castParams.status !== undefined) delete castParams.status
          if (castParams.ngcvp_status !== undefined) delete castParams.ngcvp_status
          if (castParams.m3u8 !== undefined) delete castParams.m3u8
          if (castParams.video_start_time !== undefined) delete castParams.video_start_time
          if (hasCastersMixin(castParams) && Object.keys(castParams.moderators).length > 0) castParams.moderators = {}
        }

        castParams.anonymous_caster_rules = {
          max_anonymous_accounts: 50,
          max_anonymous_casters: 12,
          max_total_casters: 12
        }

        const token = generateRandomString(24)
        const pointerData = LinkPointer.create({
          pointerType: PointerTypes.EVENT,
          eventId: castDocRef.id,
          token,
          urlSuffix: sanitizeStringForUrl(castParams.name ?? ''),
          studioVersion: version,
          team_id: event.team_id ?? ''
        })
        if (pointerData.isFailure) throw new Error(pointerData.message)
        transaction.set(pointerDocRef, Convertable.toObject(pointerData.value))

        savedCast = {
          // Converting the class to get rid of internal classes
          // (first time this gave issues is when the scenes cache was added
          // to the scenes mixin, but other subclasses would have introduced the same errors)
          ...Convertable.toObject(castParams) as Partial<Cast>,
          invited_casters: Convertable.toObject(castParams.invited_casters),
          chat_id: chatDocRef.id,
          online_sessions: {},
          activeScene,
          scenes: Convertable.toObject(baseScenes),
          widgets: {},
          source_streams: sourceStreams,
          version,
          creator_id: store.getters.user.currentUserId,
          token,
          event_id: eventDocRef.id
        }

        transaction.set(castDocRef, savedCast)
        savedCast.id = castDocRef.id

        const widgets: KeyDict<Widget> = {}
        if (!castParams.copying && castParams.widgets && Object.values(castParams.widgets).length) {
          for (const widget of Object.values(castParams.widgets as KeyDict<Widget>)) {
            const isNewWidget = widget.id === widget.type && widget.type === 'chat'
            if (isNewWidget && castParams.team_id) {
              widget.id = await store.dispatch.chatwidget.createWithTransaction({
                transaction,
                docRef: widgetDocRef,
                data: {
                  teamid: castParams.team_id,
                  castid: savedCast.id
                }
              })
            }
            widgets[widget.id] = cloneDeep(widget)
          }
        }

        const vp: VideoPlayer = new VideoPlayer('player1')
        // TODO: Do we really want to save the id property to the DB?
        transaction.set(castDocRef, { id: savedCast.id, widgets }, { merge: true })
        transaction.set(videoDocRef, { 'player1': vp.toJSON(), team_id: castParams.team_id }, { merge: true })
        return castDocRef.id
      })
    } catch (error) {
      console.error(`Error creating cast ${error}`, savedCast)
    }
    throw new Error('Something error')
  },
  /** @deprecated */
  async copyCast (_context: EventsContext, castCopy: Partial<Cast>): Promise<string> {
    try {
      // * CREATE *
      // create a minimal new Cast
      castCopy.copying = true
      if (castCopy.start_date === undefined) throw new Error('start_date is required')
      if (castCopy.start_date < new Date()) {
        castCopy.start_date = new Date()
      }
      const castId = await store.dispatch.events.createCast(castCopy)
      const createdCastDoc = await studio.db
        .collection(CollectionNames.CASTS)
        .doc(castId)
        .get()
      if (!createdCastDoc.isSuccess) {
        console.error('Error while copying - Failed to create event')
        return Promise.reject()
      }
      const createdCast = createdCastDoc.value
      // * UPDATE *
      // Add properties as needed
      createdCast.id = castId

      // update with original cast info:
      const originalCast: Partial<Cast> = JSON.parse(castCopy.originalCast ?? '{}')
      const widgets = cloneDeep(originalCast.widgets || {})
      createdCast.widgets = widgets

      // sourcestreams
      const all_sourceStreams = cloneDeep(originalCast.source_streams)
      const sourceStreams = reduce(
        all_sourceStreams,
        (result: KeyDict<SourceStream>, value, key) => {
          if (key === 'playlist' || value.type === StreamType.Broadcast) {
            // copy specific properties only
            // @ts-ignore
            result[key] = {
              active: !!value.active,
              start_time: Date.now() / 1000,
              type: value.type ?? StreamType.Broadcast,
              volume: value.volume || 0,
              volumeLeft: value.volumeLeft || 0,
              volumeRight: value.volumeRight || 0,
              muted: !!value.muted,
              leftMuted: !!value.leftMuted,
              rightMuted: !!value.rightMuted
            }
          }
          return result
        },
        {}
      )
      createdCast.source_streams = sourceStreams
      createdCast.copying = false
      await store.dispatch.events.updateCastLegacy(createdCast)
      return createdCast.id
    } catch (error) {
      console.error(`Error copying event ${error}`)
    }
    return Promise.reject()
  },
  async copyCastOutputs (_context: EventsContext, castCopy: Partial<Cast> & { id: string }) {
    try {
      const copiedCastDoc = await studio.db
        .collection(CollectionNames.CASTS)
        .doc(castCopy.id)
        .get()
      if (!copiedCastDoc.isSuccess) {
        return
      }
      const copiedCast = copiedCastDoc.value


      // get latest original cast (internal copy might be behind, eg deleted etc)
      if (!copiedCast.originalCast) {
        console.error('Error while copying cast outputs - Copied cast has no originalCast')
        return
      }
      const originalCast = JSON.parse(copiedCast.originalCast)
      const originalCastDoc = await studio.db
        .collection(CollectionNames.CASTS)
        .doc(originalCast.id)
        .get()
      if (!originalCastDoc.isSuccess) {
        console.error('Error while copying cast outputs - Failed to get original cast')
        return
      }
      const originalCastData = originalCastDoc.value
      copiedCast.custom_rtmp_destinations = cloneDeep(originalCastData.custom_rtmp_destinations || {})
      await store.dispatch.events.updateCastLegacy(copiedCast)

      originalCastData.custom_rtmp_destinations = {}
      await store.dispatch.events.updateCastLegacy(originalCastData)

    } catch (error) {
      console.error(`Error copy event ${error}`)
    }
  },
  async requestCasterRepublish (_context: EventsContext, params: { cast: string, caster: string, turnOff?: boolean }) {
    try {
      await fb.db.collection(CollectionNames.CASTS).doc(params.cast).set({
        republishRequested: {
          [params.caster]: params.turnOff === true ? false : true
        }
      } as Partial<Cast>, { merge: true })
    } catch (error) {
      console.error('requestCasterRepublish error', error)
    }
  },
  // TODO: try-catches
  async clearRequestedCustomAudioMix (_context: EventsContext, params: { castId: string, casterId: string }) {
    try {
      await fb.db.collection('casts').doc(params.castId).update({
        [`requestCustomAudioMix.${params.casterId}`]: fb.deleteField
      })
    } catch (error) {
      console.error('clearRequestedCustomAudioMix error', error)
    }
  },
  async requestCasterCustomAudioMix (_context: EventsContext, params: RequestCustomAudioMix) {
    try {
      await fb.db.collection('casts').doc(params.castId).set({
        requestCustomAudioMix: {
          [params.casterId]: {
            volumes: params.volumes,
            audioMixType: params.audioMixType
          }
        }
      }, { merge: true })
    } catch (error) {
      console.error('requestCasterCustomAudioMix error', error)
    }
  },
  async clearRequestedSwitchToAudioMix (_context: EventsContext, params: { castId: string, casterId: string }) {
    try {
      await fb.db.collection('casts').doc(params.castId).update({
        [`requestSwitchToAudioMix.${params.casterId}`]: fb.deleteField
      })
    } catch (error) {
      console.error('clearRequestedSwitchToAudioMix error', error)
    }
  },
  async requestCasterSwitchToAudioMix (_context: EventsContext, params: RequestSwitchToAudioMix) {
    try {
      await fb.db.collection(CollectionNames.CASTS).doc(params.castId).set({
        requestSwitchToAudioMix: {
          [params.casterId]: params.audioMixType
        }
      }, { merge: true })
    } catch (error) {
      console.error('requestCasterSwitchToAudioMix error', error)
    }
  },
  restartClipsplayback (_context: EventsContext, cast: { id: string }) {
    return new Promise<void>(resolve => {
      studio.db.collection(CollectionNames.CASTS).doc(cast.id).set(
        {
          source_streams: {
            playlist: {
              delete: true
            }
          }
        },
        MergeOption.MERGE
      ).then(() => {
        resolve()
      }).catch(error => {
        console.error('Could not restart Clips playback', error)
      })
    })
  },
  async restartCef (_context: EventsContext, castid: string) {
    try {
      await fb.db.collection(CollectionNames.CASTS).doc(castid).set(
        { restart_cef: true},
        { merge: true })
    } catch (error) {
      console.error('Could not restart Cef', error)
    }
  },
  setStreamNames (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.setStreamNames({ teamStreams: store.state.team.streams })
  },
  async activateWidget (_context: EventsContext, { widget, active, castid }: { widget: string, active: boolean, castid: string }) {
    await fb.db.collection(CollectionNames.CASTS).doc(castid).set({
      widgets: {
        [widget]: { active }
      }
    }, { merge: true })
  },
  async inviteCasterToOtherCast (_context: EventsContext, params: { castId: string, casterId: string }) {
    const invitedCaster = new InvitedCaster({ userId: params.casterId })
    return studio.db.collection(CollectionNames.CASTS).doc(params.castId).set({
      invited_casters: {
        [params.casterId]: invitedCaster
      }
    }, MergeOption.MERGE)
  },
  async increaseMaxAnonymousAccounts (_context: EventsContext, castId: string) {
    const increment = fb.increment(10)
    await fb.db.collection(CollectionNames.CASTS).doc(castId).set({
      anonymous_caster_rules: {
        max_anonymous_accounts: increment
      }
    }, { merge: true })
  },
  /** @deprecated */
  async updateCastLegacy (_context: EventsContext, cast: Partial<Cast>): Promise<void> {
    if (!cast.id) return Promise.reject()
    // remember cast param can be a partial cast
    const origCastDoc = await studio.db.collection(CollectionNames.CASTS).doc(cast.id).get()
    // TODO: Properly convert firebase data to Cast object.
    if (!origCastDoc.isSuccess) {
      return Promise.reject()
    }
    const origCast = origCastDoc.value

    // Handle deleted items
    // need to clear this field because its a merged updated - this is ugly, we should fix it somewhere else
    const deleteupdate: KeyDict<firebase.firestore.FieldValue> = {}
    if (cast.invited_casters !== null) {
      for (const caster in origCast.invited_casters) {
        if (cast.invited_casters !== undefined && !(caster in cast.invited_casters)) {
          deleteupdate['invited_casters.' + caster] = fb.deleteField
        }
      }
      if (cast.input_streams !== undefined) {
        for (const input in origCast.input_streams) {
          if (!(input in cast.input_streams)) {
            deleteupdate['input_streams.' + input] = fb.deleteField
          }
        }
      }
    }
    if (cast.source_streams !== undefined && cast.input_streams !== undefined) {
      for (const key in cast.source_streams) {
        const input = cast.source_streams[key]
        if (input.type === StreamType.Broadcast && !(key in cast.input_streams)) {
          deleteupdate['source_streams.' + key] = fb.deleteField
          delete cast.source_streams[key]
        }
      }
    }
    if (origCast.asset_groups !== undefined && cast.asset_groups !== undefined)
    for (const assetgroup in origCast.asset_groups) {
      if (!(assetgroup in cast.asset_groups)) {
        deleteupdate['asset_groups.' + assetgroup] = fb.deleteField
      }
    }
    Object.keys(origCast.widgets ?? {}).forEach(key => {
      if (cast.widgets !== undefined && !(key in cast.widgets)) {
        deleteupdate['widgets.' + key] = fb.deleteField
      }
    })
    if (cast.custom_rtmp_destinations !== undefined && cast.custom_rtmp_destinations !== null) {
      for (const outputId of Object.keys(origCast.custom_rtmp_destinations ?? {})) {
        if (cast.custom_rtmp_destinations[outputId] === undefined) {
          deleteupdate['custom_rtmp_destinations.' + outputId] = fb.deleteField
        }
      }
    }

    const updateWidgets = JSON.parse(JSON.stringify(cast.widgets || {}))
    cast.widgets = {}
    if (updateWidgets && Object.values(updateWidgets).length) {
      for (const widget of Object.values(updateWidgets as KeyDict<Widget>) as Widget[]) {
        const missingId = !widget.id || widget.id === widget.type
        if (missingId) {
          widget.id = await store.dispatch.chatwidget.create({
            teamid: cast.team_id ?? origCast.team_id,
            castid: cast.id
          })
        }
        cast.widgets[widget.id] = JSON.parse(JSON.stringify(widget))
        console.log('widget.id update', widget.id, widget)
      }
    }
    //
    // if we don't have the source_stream made from the input_streams add them
    //
    if (cast.input_streams !== undefined) {
      cast.source_streams = cast.source_streams ?? {}
      Object.keys(cast.input_streams).forEach(key => {
        if (cast.source_streams![key] === undefined) {
          const baseStream = {
            active: true,
            type: 'broadcast',
            start_time: new Date().getTime() / 1000,
            volume: 0,
            volumeLeft: 0,
            volumeRight: 0
          } as SourceStream

          cast.source_streams![key] = baseStream
        }
      })
    }
    if (cast.start_date !== undefined && cast.end_date !== undefined) {
      if (cast.end_date < cast.start_date) {
        console.warn('Cast has end_date that is before start_date', cast)
        cast.end_date = cast.start_date
      }
    }

    if (Object.keys(deleteupdate).length > 0) {
      await fb.db.collection(CollectionNames.CASTS).doc(cast.id).update(deleteupdate)
    }
    await fb.db.collection(CollectionNames.CASTS).doc(cast.id).set(Convertable.toObject(cast), { merge: true })

    // Update event if needed.
    const eventId = cast.event_id // We also consider empty string as invalid here.
    if (!eventId) {
      console.error('Cast does not have a valid event_id', cast)
    } else {
      const eventUpdates: Partial<CloudCastEvent> = {}
      if (cast.start_date !== undefined && origCast.start_date !== undefined) {
        if (cast.start_date.getTime() != origCast.start_date.getTime()) {
          // @ts-ignore
          eventUpdates.start_date = cast.start_date
        }
      }
      if (cast.end_date !== undefined && origCast.end_date !== undefined) {
        if (cast.end_date.getTime() != origCast.end_date.getTime()) {
          // @ts-ignore
          eventUpdates.end_date = cast.end_date
        }
      }
      if (cast.name && cast.name !== origCast.name) {
        eventUpdates.name = cast.name
      }
      if (Object.keys(eventUpdates).length > 0) {
        await fb.db.collection(CollectionNames.EVENTS).doc(eventId).set(eventUpdates, { merge: true })
      }
    }
  },
  updateCastPlaylistStatus (_context: EventsContext, cast: Optional<Cast>) {
    return new Promise<void>(resolve => {
      if (cast === undefined || cast.id === undefined) {
        resolve()
      } else {
        fb.db
          .collection(CollectionNames.PLAYLISTCONNECTIONS)
          .doc(cast.id)
          .set(cast, { merge: true })
          .then(() => {
            resolve()
          })
          .catch(error => {
            console.error('Update Cast Playlist Status error ', error)
          })
      }
    })
  },
  unsubscribeUpdateSourceStreamDocs (_context: EventsContext) {
    // TODO: make async...
    Object.values(subscriptions.updateSourceStreamDocs).forEach((unsubscription) => {
      unsubscription()
    })
    subscriptions.updateSourceStreamDocs = {}
  },
  updateSourceStreamDocs (context: EventsContext, sourceStreams?: KeyDict<SourceStream>) {
    const { commit, state } = eventsModuleActionContext(context)
    const prevSubIds = Object.keys(subscriptions.updateSourceStreamDocs)
    const newSubIds = Object.keys(sourceStreams ?? state.sourceStreams)
    const unsubIds = prevSubIds.filter((id) => !newSubIds.includes(id))
    const subIds = newSubIds.filter((id) => !prevSubIds.includes(id))

    for (const id of unsubIds) {
      subscriptions.updateSourceStreamDocs[id]() // unsubscribe
      delete subscriptions.updateSourceStreamDocs[id]
    }

    for (const key of subIds) {
      if (key === 'playlist') continue
      if (key in subscriptions.updateSourceStreamDocs) {
        console.error('warning updateSourceStreamDocs ' + key + ' : duplicate key')
      }
      const isRemoteTalkBack = state.sourceStreams[key]?.type === StreamType.RemoteTalkback
      const isCefIngestPreview = state.sourceStreams[key]?.type === StreamType.Cef
      const baseStreamId = isRemoteTalkBack ? key.split('_')[1] : key
      let fireBaseStreamId = baseStreamId
      if (isCefIngestPreview) fireBaseStreamId = `fanroompreview${key}`
      const remoteTalkbackName = isRemoteTalkBack ? key.split('_')[0] : ''
      const result = studio.db
        .collection(CollectionNames.STREAMS)
        .doc(fireBaseStreamId)
        .onSnapshot(
          (stream) => {
            commit.streamDocLoaded({ data: stream, id: baseStreamId })
            const srtpreviewkey = `srtpreview${baseStreamId}${state.currentCast?.id ?? ''}${remoteTalkbackName}`
            // BERT stream.srt_state - should be streamdoc.protocol == Srt
            // @ts-ignore
            const isSrt = stream.srt_state !== undefined
            if (isSrt && !subscriptions.updateSourceStreamDocs.hasOwnProperty(srtpreviewkey)) {
              const srtResult = studio.db
                .collection(CollectionNames.STREAMS)
                .doc(srtpreviewkey)
                .onSnapshot(srtStream => commit.setOutputStreamObject({ data: srtStream, id: srtpreviewkey }))
              if (srtResult.isSuccess) {
                subscriptions.updateSourceStreamDocs[key] = srtResult.value
              } else {
                srtResult.log('error updateOutputStreams srtpreviewkey ' + srtpreviewkey)
              }
            }
          }
        )
      if (result.isSuccess) {
        subscriptions.updateSourceStreamDocs[baseStreamId] = result.value
      } else {
        result.log(`error in updateSourceStreamDocs ${ baseStreamId }`)
      }
    }
  },
  async removeAssetGroupFromCast (context: EventsContext, assetGroupId: string) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not removeAssetGroupFromCast, currentCast is null')
    const update = { asset_groups: { [assetGroupId]: false } }
    await fb.db
      .collection(CollectionNames.CASTS)
      .doc(state.currentCast.id)
      .set(update, { merge: true })
    await store.dispatch.assets.selectFolder('')
  },
  async updateCastAssetGroups (context: EventsContext, assetGroups: KeyDict<boolean>) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not updateCastAssetGroups, currentCast is null')
    const result = await studio.db
      .collection(CollectionNames.CASTS)
      .doc(state.currentCast.id)
      .set({ asset_groups: assetGroups }, MergeOption.UPDATE)
    result.logIfError('Failed to update assetGroups')
  },
  unsubscribeUpdateOutputStreams (_context: EventsContext) {
    Object.values(subscriptions.outputStreams).forEach(streamFunc => {
      streamFunc()
    })
    subscriptions.outputStreams = {}
  },
  async updateOutputStreams (context: EventsContext) {
    const { commit, state, dispatch } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not updateOutputStreams, currentCast is null')
    if (state.outputStreams) {
      await dispatch.unsubscribeUpdateOutputStreams()
      const _subscribe = function (key: string, variantId: string): void {
        const result = studio.db
          .collection(CollectionNames.STREAMS)
          .doc(variantId)
          .onSnapshot((data) => {
            const stream = { data, id: key }
            commit.setOutputStreamObject(stream)
          })
        if (result.isFailure) result.log(`error updateOutputStreams ${variantId}`)
        else subscriptions.outputStreams[variantId] = result.value
      }

      const keys = ['rtsppreview', 'rtmppreview', 'rtsppreviewlow', 'rtmppreviewlow', 'rtsplowres', 'rtmplowres']
      for (const key of keys) {
        if (state.outputStreams.hasOwnProperty(key)) {
          const variantId = state.outputStreams[key].stream
          if (variantId === undefined) break
          _subscribe(key, variantId)
        }
      }
      const playlistVariant = `playlist${state.currentCast.id}`
      _subscribe('playlist', playlistVariant)
    }
  },
  async getSingleCast (_context: EventsContext, castId: string): Promise<Cast|null> {
    const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).get()
    if (result.isFailure) return null
    return result.value
  },
  async getAllTeamAssetGroupIds (_context: EventsContext, teamId: string): Promise<string[]> {
    try {
      const result = await fb.db.collection(CollectionNames.ASSET_GROUPS)
      .where('team_id', '==', teamId)
      .where('deleted', '==', false)
      .get()

      return result.docs.map(doc => doc.id)
    } catch (error) {
      console.log('Error retrieving all of team Asset Groups from fb', error)
      return []
    }
  },
  async getAllTeamAssetGroups (_context: EventsContext, teamId: string): Promise<KeyDict<AssetGroup>> {
    try {
      const result = await fb.db.collection(CollectionNames.ASSET_GROUPS)
      .where('team_id', '==', teamId)
      .where('deleted', '==', false)
      .get()

      const groups: KeyDict<AssetGroup> = {}
      result.forEach(doc => {
        groups[doc.id] = {...doc.data(), id: doc.id } as AssetGroup
      })
      return groups
    } catch (error) {
      console.log('Error retrieving all of team Asset Groups from fb', error)
      return {}
    }
  },
  updateLiveEditVolumes (context: EventsContext, payload: KeyDict<number>) {
    const { commit } = eventsModuleActionContext(context)
    commit.updateLiveEditVolumes(payload)
  },
  removeLiveEditVolumes (context: EventsContext, audioChannelIds: string[]) {
    const { commit } = eventsModuleActionContext(context)
    commit.removeLiveEditVolumes(audioChannelIds)
  },
  clearLiveEditVolumes (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.clearLiveEditVolumes()
  },
  changeAudioMix (context: EventsContext, val: AudioMixType) {
    const { commit } = eventsModuleActionContext(context)
    commit.changeAudioMix(val)
  },
  async updateVolumeOfStream (context: EventsContext, {id, val}: { id: string, val: KeyDict<number> }) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not updateVolumeOfStream, currentCast is null')
    const currentCastId = state.currentCast.id
    const sourceStreamsCopy: KeyDict<Partial<SourceStream>> = {}
    sourceStreamsCopy[id] = {}
    Object.keys(val).forEach(volumeName => {
      if (['volume', 'volumeLeft', 'volumeRight', 'leftMuted', 'rightMuted'].includes(volumeName)) {
        // @ts-ignore
        sourceStreamsCopy[id][volumeName] = val[volumeName]
      }
    })
    const result = await studio.db.collection(CollectionNames.CASTS)
                                  .doc(currentCastId)
                                  .set({ source_streams: sourceStreamsCopy }, MergeOption.MERGE)
    result.logIfError('Update volume of Stream ')
  },
  updateStreamVolumes (context: EventsContext, payload: AudioPayload[]) {
    const { commit } = eventsModuleActionContext(context)
    commit.updateStreamVolumes(payload)
  },
  applyMonitorToLive (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.applyMonitorToLive()
  },
  changeGlobalTalkbackVolume (context: EventsContext, payload: number) {
    const { commit } = eventsModuleActionContext(context)
    commit.changeGlobalTalkbackVolume(payload)
  },
  changeTalkbackMode (context: EventsContext, payload: TalkbackMode) {
    const { commit } = eventsModuleActionContext(context)
    commit.changeTalkbackMode(payload)
  },
  toggleTalkbackGlobalMutes (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.toggleTalkbackGlobalMutes()
  },
  changeLocalGlobalVolume (context: EventsContext, payload: number) {
    const { commit } = eventsModuleActionContext(context)
    commit.changeLocalGlobalVolume(payload)
  },
  duplicateScene (context: EventsContext, scene: { scene: Scene }) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not duplicateScene, currentCast is null')
    const currentCastId = state.currentCast.id
    const currentScenes: KeyDict<Scene> = cloneDeep(state.currentCast.scenes)
    const currentScenePresets: KeyDict<ScenePreset> = cloneDeep(state.currentCast.scenePresets)
    const oldSceneId = scene.scene.id
    const oldPresetContents = state.currentCast.getScenePresetBySceneId(oldSceneId)?.contents ?? {}
    const newSceneId = helpers.makeId()
    const newScenePresetId = helpers.makeId()
    scene.scene.id = newSceneId
    scene.scene.name += ' copy'
    scene.scene.scenePresetIds = [...getGlobalLayerIds(), newScenePresetId]
    const sceneOrders = Object.values(currentScenes).map(scene => scene.order ?? 0)
    scene.scene.order = Math.max(...sceneOrders) + 1
    currentScenes[newSceneId] = scene.scene
    currentScenePresets[newScenePresetId] = new ScenePreset({
      id: newScenePresetId,
      name: scene.scene.name,
      contents: cloneDeep(oldPresetContents)
    })
    fb.db
      .collection(CollectionNames.CASTS)
      .doc(currentCastId)
      .set({ scenes: currentScenes, scenePresets: Convertable.toObject(currentScenePresets) }, { merge: true })
      .catch((error) => {
        console.error('Duplicate Scene ', error)
      })
  },
  setCefEvent (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.setCefEvent()
  },
  setPreviewingOutput (context: EventsContext, value: boolean) {
    const { commit } = eventsModuleActionContext(context)
    commit.setPreviewingOutput(value)
  },
  setCefMode (context: EventsContext, mode: RenderMode) {
    const { commit } = eventsModuleActionContext(context)
    commit.setCefMode(mode)
  },
  setCefMixer (context: EventsContext, mixerid: string) {
    const { commit } = eventsModuleActionContext(context)
    commit.setCefMixer(mixerid)
  },
  bleepCaster (context: EventsContext, caster: string) {
    const { commit } = eventsModuleActionContext(context)
    commit.bleepCaster(caster)
  },
  toggleSimpleMode (context: EventsContext, value: boolean) {
    const { commit } = eventsModuleActionContext(context)
    commit.toggleSimpleMode(value)
  },
  toggleFullscreen (context: EventsContext, value: boolean) {
    const { commit } = eventsModuleActionContext(context)
    commit.toggleFullscreen(value)
  },
  async setProgramSceneId (context: EventsContext, sceneId: string) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not setProgramSceneId, currentCast is null')
    const newProgramScene: Scene|undefined = state.currentCast.scenes[sceneId]
    if (!newProgramScene) {
      throw new Error(`setProgramSceneId: Scene with id ${sceneId} is not in the list of scenes`)
    }

    const oldActiveId = state.currentCast.programSceneId
    const castIds: string[] = [state.currentCast.id]
    const promises: Promise<void>[] = []
    castIds.forEach(castId => {
      const switchScene = async () => {
        const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).set({
          activeScene: sceneId,
          previewSceneId: oldActiveId
        }, MergeOption.MERGE)
        result.logIfError('Could not switch scene for cast' + castId)
      }
      promises.push(switchScene())
    })
    await Promise.all(promises)
  },
  async muteAssetInAllScenes (context: EventsContext, params : { assetId: string, mute: boolean , mvar? : MVar<void> }) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not muteAssetInAllScenes, currentCast is null')
    const update: KeyDict<boolean> = {}
    const scenes = state.currentCast.scenes
    for (const sceneKey in scenes) {
      const scene = scenes[sceneKey]
      const contentsDict = scene.contents ?? {}
      const contents = contentsDict[params.assetId]
      if (contents != undefined) {
        const path = `scenes.${sceneKey}.contents.${params.assetId}.muted` // better use FieldPath !
        update[path] = params.mute
      }
    }
    if (Object.keys(update).length == 0) {
      return
    }
    try {
      await fb.db.collection(CollectionNames.CASTS).doc(state.currentCast.id).update(update)
      if (params.mvar !== undefined) {
        params.mvar.put()
      }
    } catch (error) {
      console.error('muteAssetInAllScenes failed', error)
    }
  },
  async updateCastScenes (context: EventsContext, info: { scenes: DeepPartial<KeyDict<Scene>>, castId?: string }) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not updateCastScene, currentCast is null')
    const castId = info.castId ?? state.currentCast.id
    const result = await studio.db.collection(CollectionNames.CASTS).doc(castId)
                                                                    .set({ scenes: info.scenes }, MergeOption.MERGE)
    result.logIfError('Failed to update cast scenes')
  },
  updatePlaylistDoc (context: EventsContext,
                     clipstate: { position: number, team_id: string, active: string | null}) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not updatePlaylistDoc, currentCast is null')
    fb.db
      .collection('playliststatus')
      .doc(state.currentCast.id)
      .set(clipstate, { merge: true })
      .catch(error => {
        console.error('Update playlist Doc error', error)
      })
  },
  async toggleSelfMute (context: EventsContext, isSelfMuted: boolean) {
    if (store.getters.user.currentUserId === null) {
      throw new Error('Cannot toggle self mute if you dont have a userid')
    }
    const { commit, dispatch, state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not toggleSelfMute, currentCast is null')
    commit.toggleSelfMute(isSelfMuted) //Update local state for immediate caster feedback
    try {
      dispatch.updateSelfMutedState({ //Store value update in db
        castId: state.currentCast.id,
        userId: store.getters.user.currentUserId,
        isSelfMuted: isSelfMuted
      })
    } catch (error) {
      console.error('toggleSelfMute', state.currentCast.id, store.getters.user.currentUserId, isSelfMuted, error)
    }
  },
  async setPreviewScene (context: EventsContext, sceneData: { id: string }) {
    const { commit } = eventsModuleActionContext(context)
    commit.setPreviewScene(sceneData)
  },
  async reloadSceneLayout (context: EventsContext, params: { scene: Scene, type: 'scenes'|'preview_scenes' }): Promise<void> {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not reloadSceneLayout, currentCast is null')
    const layout = cloneDeep(params.scene.scene_layout)
    const sceneLayout = store.state.team.teamLayouts.find((layout) => layout.name === params.scene.layout_id)
    const newScene: Scene = cloneDeep(params.scene)
    if (sceneLayout !== undefined) {
      newScene.scene_layout = sceneLayout
      // If some of the scene_layout's default assets were deleted from the normal `scene.contents`, re-add them
      // when reloading the layout.
      newScene.contents = {
        ...sceneLayout.contents,
        ...newScene.contents
      }
    }
    if (!newScene.id || !layout) {
      throw new Error(`No Id in updateData (id = ${params.scene.id}, layout = ${layout})`)
    }

    const currentCastId = state.currentCast.id
    const oldPreviewScene = cloneDeep(state.currentCast[params.type][newScene.id])
    const oldSceneLayout = oldPreviewScene?.scene_layout
    const incompleteData = !!newScene.contents && Object.values(newScene.contents).some(o => !o.id)
    const changes = difference(newScene, oldPreviewScene) as Partial<Scene>
    // console.warn('---------> CHANGES', changes)

    const noChanges = Object.keys(changes || {}).length === 0
    if (noChanges) {
      // console.warn('---------> XXX NO CHANGES', changes)
      return
    }

    if (incompleteData) {
      // When showing/hiding items in scene you only get a true/false
      // instead of a whole object  e.g. { aGc56uIP: true }
      // console.log('INCOMPLETE DATA')

      // get data from existing preview
      for (const key in newScene.contents) {
        if (newScene.contents) {
          newScene.contents[key] = {
            ...(oldPreviewScene?.contents || {})[key],
            ...newScene.contents[key]
          }
          // console.log('updated updateData.contents[key]: ', sceneData.contents[key])
        }
      }

      // also don't trust the layout data
      // @ts-ignore Should we even be assigning `{}` when oldPreviewScene has no `scene_layout`?
      newScene.scene_layout = {
        ...(oldSceneLayout ?? {})
      }
      // console.log('updateData is now: ', sceneData)
    }

    const update = { [params.type]: { [newScene.id]: { scene_layout: layout, contents: {} } } }
    const hasLayoutChanges = changes.scene_layout !== undefined

    // ADD LAYOUT CONTENT
    let updatedContents = newScene.contents || {}
    const layoutContents: KeyDict<SceneContent> =  newScene.scene_layout?.contents ?? {}
    const layoutBoxes: KeyDict<LayoutBox> = newScene.scene_layout?.boxes ?? {}

    // NEED TO UPDATE LAYOUT ?
    if (hasLayoutChanges) {
      // Add new layout items
      // make sure only one item is active per box
      Object.values(layoutContents).forEach((layoutItem: SceneContent) => {
        if (layoutItem.active) {
          const alreadyInContent = updatedContents.hasOwnProperty(layoutItem.id)
          const isOtherActive = (c: SceneContent) => c.active && c.id !== layoutItem.id && c.box === layoutItem.box
          const boxHasActiveContent = Object.values(updatedContents).some(isOtherActive)
          if (!alreadyInContent && boxHasActiveContent) {
            layoutItem.active = false
          }
        }
      })

      /*
        Add to scene all Simple CG assets newly included in layout.
        Accounts for any newly added Simple CG boxes, since they are otherwise only added to scenes when the scenes are first created in the cast.
        This partially recreates functionality already defined in SceneList.vue.
        We should possibly refactor to use a shared function instead.
      */
      const currentScenes: KeyDict<Scene> = state.currentCast?.scenes ?? {}
      Object.values(layoutBoxes).forEach((box: LayoutBox) => {
        //If it's a new SimpleCG box
        if (box.types?.includes(AssetType.SimpleCG) && !Object.keys(updatedContents).includes(box.id)) {
          //Set up some base property values
          let newActive = false
          let newSimplecgText = 'Sample Text'
          //Match new asset properties to an existing instance
          for (const existingScene of Object.values(currentScenes)) {
            if (existingScene.contents?.[box.id]?.type === AssetType.SimpleCG) {
              //Update active flag and text content
              newActive = existingScene.contents[box.id].active ?? false
              newSimplecgText = existingScene.contents[box.id].simplecgText ?? 'Sample Text'
              break; //Stop loop early, only one value retrieval needed since they are supposed to be the same for all scenes anyway
            }
          }

          //Add new SimpleCG asset to scene
          updatedContents[box.id] = {
            id: box.id,
            name: box.name,
            order: 0,
            active: newActive,
            box: box.id,
            simplecgText: newSimplecgText,
            type: AssetType.SimpleCG,
            contentType: SceneContentType.Content
          }
        }
      })

      updatedContents = {
        ...layoutContents,
        ...updatedContents,
      }
    }

    update[params.type][newScene.id].contents = updatedContents
    update[params.type][newScene.id].scene_layout = newScene.scene_layout
    await fb.db.collection(CollectionNames.CASTS).doc(currentCastId).set(update, { merge: true })
    // console.log('Firestore update ', update)

    // Cleanup assets that were deleted from layout
    if (hasLayoutChanges) {
      // console.log('oldPreviewScene.scene_layout.contents ', oldSceneLayout?.contents)
      // console.log('changes.scene_layout.contents ', changes.scene_layout?.contents)

      for (const key of Object.keys(oldSceneLayout?.contents ?? {})) {
        const wasRemoved = !!newScene.scene_layout.contents && !newScene.scene_layout.contents[key]
        if (wasRemoved) {
          // NOTE: we could also opt to never take away layout content automatically
          // this way the user has more control and will not see things dissappearing

          // set to true if you want to remove from preview content also
          const removeFromContent = true
          if (removeFromContent && newScene.contents?.[key] === undefined) {
            // Only remove from the preview scene's contents if the non-preview scene also no longer contains it.
            const contentsPath = `${params.type}.${newScene.id}.contents.${key}`
            await fb.db
              .collection(CollectionNames.CASTS)
              .doc(currentCastId)
              .update({ [contentsPath]: fb.deleteField })
          }

          // always remove from scene layout
          const layoutContentsPath = `${params.type}.${newScene.id}.scene_layout.contents.${key}`
          await fb.db
            .collection(CollectionNames.CASTS)
            .doc(currentCastId)
            .update({ [layoutContentsPath]: fb.deleteField })
        }
      }
    }

    // check for deleted boxes
    for (const box of Object.values(oldSceneLayout?.boxes ?? {})) {
      if (box.id && !sceneLayout?.boxes[box.id]) {
        const boxLocation = `${params.type}.${newScene.id}.scene_layout.boxes.${box.id}`
        await fb.db
          .collection(CollectionNames.CASTS)
          .doc(currentCastId)
          .update({ [boxLocation]: fb.deleteField })
      }
    }
  },
  async applyChangesFromPreview (context: EventsContext) {
    const { state, commit } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not applyChangesFromPreview, currentCast is null')
    if (state.sceneInPreview === null) throw new Error('Could not applyChangesFromPreview, sceneInPreview is null')
    const currentCastId = state.currentCast.id
    const updateData = JSON.parse(JSON.stringify(state.currentCast.preview_scenes[state.sceneInPreview]))
    commit.leavePreviewScene()
    if (updateData.id && state.currentCast.scenes[updateData.id]) {
      const scene: Scene = state.currentCast.scenes[updateData.id]
      if (scene.scene_layout) {
        for (const box of Object.values(scene.scene_layout.boxes)) {
          if (box.id && updateData.scene_layout && !updateData.scene_layout.boxes[box.id]) {
            const boxlocation = 'scenes.' + updateData.id + '.scene_layout.boxes.' + box.id
            try {
              await fb.db
                .collection('casts')
                .doc(currentCastId)
                .update({
                  [boxlocation]: fb.deleteField
                })
            } catch (error) {
              console.error('Update Scene from Preview - delete boxes', error)
            }
          }
        }
      }
    }
    if (updateData.id) {
      const currentCastScenes = JSON.parse(JSON.stringify(state.currentCast.scenes))
      updateData.active = currentCastScenes[updateData.id].active
      currentCastScenes[updateData.id] = updateData
      const sourceStreamsCopy = state.sourceStreams
      const changedData = {
        scenes: currentCastScenes,
        preview_scenes: {},
        source_streams: sourceStreamsCopy
      }
      try {
        await fb.db.collection('casts').doc(currentCastId).update({ scenes: changedData.scenes, preview_scenes: changedData.preview_scenes })
      } catch (error) {
        console.error('Update Scene from Preview - set data', error)
      }
    }
    // TODO set state also for quick response
  },
  clearPreviewScene (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.clearPreviewScene()
  },
  async copyFromLiveToPreview (context: EventsContext, sceneId: string): Promise<void> {
    const { commit, state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not copyFromLiveToPreview, currentCast is null')
    const currentCastId = state.currentCast.id
    const currentSceneVer = state.currentCast.scenes[sceneId]
    const currentSceneCopy = cloneDeep(currentSceneVer)
    try {
      // @ts-ignore
      const fsPath = new fb.firebase.firestore.FieldPath('preview_scenes', sceneId)
      await fb.db.collection(CollectionNames.CASTS).doc(currentCastId).update(fsPath, currentSceneCopy)
    } catch (error) {
      console.error('Copy live to Preview ', error)
    }
    if (currentSceneCopy === undefined) {
      throw new Error('events.ts::copyFromLiveToPreview id is undefined')
    }
    commit.setPreviewScene({ id: currentSceneCopy.id })
  },
  addNewSceneToCast (context: EventsContext, scene: Scene) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not addNewSceneToCast, currentCast is null')
    if (scene.id === undefined) {
      console.error('events.addNewSceneToCast: scene.id is undefined')
      return
    }
    const currentScenes = JSON.parse(JSON.stringify(state.currentCast.scenes)) as KeyDict<Scene>
    // ADD LAYOUT CONTENT
    if (scene.scene_layout?.contents !== undefined) {
      scene.contents = {
        ...scene.scene_layout.contents,
        ...scene.contents
      }
    }
    const sceneUpdates: KeyDict<Partial<Scene> & { contents: KeyDict<SceneContent> }> = { [scene.id]: { contents: {} } }
    sceneUpdates[scene.id] = scene

    let videoOrder = maxOrderOfType(state.currentCast, AssetType.Video)
    if (scene.contents) {
      Object.entries(scene.contents).forEach(([contentId, content]) => {
        if (scene.contents === undefined || state.currentCast === null) return
        if (content.type === AssetType.Video) {
          videoOrder++
          const newVideo = !isAssetInCast(state.currentCast, contentId)
          if (newVideo) scene.contents[contentId].order = videoOrder
          Object.keys(currentScenes).forEach(sceneId => {
            if (sceneUpdates[sceneId] === undefined) sceneUpdates[sceneId] = { contents: {}}
            sceneUpdates[sceneId].contents[contentId] = { ...content }
            const box = Object.entries(currentScenes[sceneId].scene_layout.boxes).find(([_boxid, box]) => {
              return box.types?.includes(AssetType.Video) === true
            })
            if (box !== undefined) sceneUpdates[sceneId].contents[contentId].box = box[0]
            if (newVideo) sceneUpdates[sceneId].contents[contentId].order = videoOrder
          })
        }
      })
    }

    const scenePresetId = helpers.makeId()
    const scenePresetsUpdates = Convertable.toObject({
      [scenePresetId]: new ScenePreset({ id: scenePresetId, name: scene.name })
    })
    sceneUpdates[scene.id].scenePresetIds = [...getGlobalLayerIds(), scenePresetId]

    const currentCastId = state.currentCast.id
    fb.db
      .collection('casts')
      .doc(currentCastId)
      .set({ scenes: sceneUpdates, scenePresets: scenePresetsUpdates }, { merge: true })
      .catch(error => {
        console.error('Add Scene To Cast ', error)
      })
  },
  async deleteSceneFromCast ({ state }: EventsContext, scene: { id: string; name: string }) {
    if (state.currentCast === null) throw new Error('Could not deleteSceneFromCast, currentCast is null')
    const currentCastId = state.currentCast.id
    if (currentCastId === undefined) {
      throw Error('You are not in a cast, cannot delete scene')
    }
    const updates = { [`scenes.${scene.id}`]: fb.deleteField }
    if (state.currentCast.preview_scenes[scene.id] !== undefined) {
      updates[`preview_scenes.${scene.id}`] = fb.deleteField
    }
    await fb.db
      .collection(CollectionNames.CASTS)
      .doc(currentCastId)
      .update(updates)
    await store.dispatch.logs.addLog({
      message: 'Deleted scene from cast',
      cast: currentCastId,
      scene: scene.name,
      level: 'info'
    })
  },
  async deleteItemFromScene ({ state }: EventsContext, params: { all_scenes: boolean; scene: string; item: string, preview: boolean }) {
    if (state.currentCast === null) throw new Error('Could not deleteItemFromScene, currentCast is null')
    const currentCastId = state.currentCast.id
    if (currentCastId === undefined) {
      throw Error('You are not in a cast, cannot delete scene item')
    }
    const deleteItems: KeyDict<any> = {}
    const prefix = params.preview ? 'preview_scenes' : 'scenes'
    if (params.all_scenes === true) {
      for (const sceneId of Object.keys(state.currentCast.scenes)) {
        deleteItems[`${prefix}.${sceneId}.contents.${params.item}`] = fb.deleteField
      }
    } else {
      deleteItems[`${prefix}.${params.scene}.contents.${params.item}`] = fb.deleteField
    }
    await fb.db.collection('casts').doc(currentCastId).update(deleteItems)
    await store.dispatch.logs.addLog({
      message: `Deleted item from scene`,
      cast: currentCastId,
      item: params.item,
      scene: params.scene,
      level: 'info'
    })
  },
  async setAutoPlayNextForVideo (context: EventsContext, params: { assetId: string, enabled: boolean }) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not setAutoPlayNextForVideo, currentCast is null')
    const currentCastId = state.currentCast.id
    if (currentCastId === undefined) throw Error('You are not in a cast, cannot set auto_play_next on scene item')
    const castUpdate: DeepPartial<Cast> = {
      scenes: {}
    }
    let sceneCount = 0
    for (const [sceneId, scene] of Object.entries(state.currentCast.scenes)) {
      if (scene.contents === undefined) return
      const content: SceneContent|undefined = scene.contents[params.assetId]
      if (content === undefined) continue
      const newClipEndAction = params.enabled ? ClipEndAction.Next : ClipEndAction.Stop
      const changesNeeded = content.auto_play_next !== params.enabled || content.clip_end_action !== newClipEndAction
      if (changesNeeded && castUpdate.scenes !== undefined) {
        sceneCount++
        castUpdate.scenes[sceneId] = {
          contents: {
            [params.assetId]: {
              auto_play_next: params.enabled,
              clip_end_action: newClipEndAction
            }
          }
        }
      }
    }
    if (sceneCount > 0) {
      // FIXME: look for the cause why this is called so often -> VideoController::watch(video)
      const result = await studio.db.collection(CollectionNames.CASTS)
                                    .doc(currentCastId).set(castUpdate, MergeOption.MERGE)
      result.logIfError('setAutoPlayNextForVideo error writing to database')
    }
  },
  async setOrdersOfVideos (context: EventsContext, orders: KeyDict<number>) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw Error('You are not in a cast, cannot set video orders')
    const castUpdate: DeepPartial<Cast> = { scenes: {} }
    for (const [sceneId, scene] of Object.entries(state.currentCast.scenes)) {
      if (scene.contents === undefined) return
      for (const [assetId, order] of Object.entries(orders)) {
        const asset: undefined|SceneContent = scene.contents[assetId]
        if (asset && castUpdate.scenes !== undefined) {
          if (castUpdate.scenes[sceneId] === undefined) castUpdate.scenes[sceneId] = { contents: {} }
          const scene = castUpdate.scenes[sceneId]
          if (scene?.contents !== undefined) scene.contents[assetId] = { order }
        }
      }
    }
    if (Object.keys(castUpdate.scenes!).length === 0) return
    await fb.db.collection('casts').doc(state.currentCast.id).set(castUpdate, { merge: true })
  },
  async moveOrderOfAssets (context: EventsContext, params: {sceneId: string, fromContentId: string, toContentId: string}) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not moveOrderOfAssets, currentCast is null')
    const assetInSyncBetweenScenes = cloneDeep(SocialMediaTypes)
    assetInSyncBetweenScenes.push(AssetType.Video)
    const scene = state.currentCast.scenes[params.sceneId]
    if (scene === undefined) throw Error('moveOrderOfAssets: Scene not found')
    if (scene.contents === undefined) return
    const fromContent = scene.contents[params.fromContentId]
    const toContent = scene.contents[params.toContentId]
    if (fromContent === undefined) throw Error('moveOrderOfAssets: fromContentId does not exists in the scene')
    if (toContent === undefined) throw Error('moveOrderOfAssets: toContentId does not exists in the scene')
    if (fromContent.box !== toContent.box) throw Error('moveOrderOfAssets: toContent and fromContent are not in the same box')

    const updateScene = (sceneId: string) => {
      const contents = state.currentCast?.scenes[sceneId].contents
      if (contents === undefined) return {}
      const contentUpdates: KeyDict<{order: number, id: string}> = {}
      const direction = toContent.order > fromContent.order ? 0.5 : -0.5
      contentUpdates[params.fromContentId] = { order: toContent.order + direction, id: params.fromContentId }
      for (const [id, content] of Object.entries(contents)) {
        if (content.box === fromContent.box && id !== params.fromContentId) {
          contentUpdates[id] = { order: content.order, id }
        }
      }
      const updates: DeepPartial<Cast> = {
        scenes: {
          [sceneId]: {
            contents: {
            }
          }
        }
      }
      let order = 0
      const orderedContents = Object.values(contentUpdates).sort(contentSortByOrder)
      for (const content of orderedContents) {
        updates.scenes![sceneId]!.contents![content.id] = { order }
        order++
      }
      return updates
    }
    let updates: DeepPartial<Cast> = {}
    if (assetInSyncBetweenScenes.includes(fromContent.type)) {
      Object.keys(state.currentCast.scenes).forEach(sceneId => { updates = merge(updates, updateScene(sceneId))})
    } else {
      updates = updateScene(params.sceneId)
    }
    await fb.db.collection('casts').doc(state.currentCast.id).set(updates, { merge: true })
  },
  clearCurrentCasterStream (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.clearCurrentCasterStream()
  },
  async setCurrentCasterStream (context: EventsContext, stream: SetCasterStreamInfo) {
    const { state, commit, dispatch } = eventsModuleActionContext(context)
    const currentCastId = state.currentCast?.id
    if (currentCastId) {
      await dispatch.addCasterStreamToCast({streamInfo: stream, castId: currentCastId})
    } else {
      console.log('Skipped adding caster stream because no current cast id.')
    }
    commit.setCurrentCasterStream(stream)
  },
  async addCasterStreamToCast (context: EventsContext, params: { streamInfo: SetCasterStreamInfo, castId: string }) {
    const { state } = eventsModuleActionContext(context)
    const id = store.state.user.id
    const { streamInfo, castId } = params
    const volume = store.state.user.profile?.caster_volume ?? 100
    const sourceStreamUpdate: SourceStreamCaster|SourceStreamModerator|SourceStreamNonCaster = {
      id: streamInfo.streamId,
      type: streamInfo.type,
      active: true,
      start_time: Date.now() / 1000,
      volume,
      muted: streamInfo.muted ?? false,
      session_id: streamInfo.sessionId,
      user_id: streamInfo.userId,
    }
    const existingStreamIds = state.currentCast?.getAllSourceStreamIdsByUserId(streamInfo.userId) ?? []
    // TODO: change "any" to FieldAction
    for (const streamId of existingStreamIds) {
      const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).set({
        source_streams: {
          [streamId]: DatabaseAction.deleteField()
        }
      }, MergeOption.MERGE)
      result.logIfError(`Failed to remove stream ${ streamId } in cast ${ castId } for user ${ id }`)
    }
    const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).set({
      source_streams: {
        [streamInfo.streamId]: sourceStreamUpdate,
      }
    }, MergeOption.MERGE)
    result.logIfError(`Failed to add stream in cast ${ castId } for user ${ id }`, sourceStreamUpdate)
  },
  async removeCasterStreamFromCast (_context: EventsContext, params: { streamId: string, castId: string }) {
    const { streamId, castId } = params
    if (streamId === '' || castId === '') throw new Error('Stream and cast id must be valid')

    const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).set({
      source_streams: {
        [streamId]: DatabaseAction.deleteField(),
        [`${streamId}broadcast`]: DatabaseAction.deleteField()
      }
    }, MergeOption.MERGE)
    result.logIfError(`Failed to remove ${streamId} from cast ${castId}.`)
  },
  setCasting (context: EventsContext, casting: boolean) {
    const { commit } = eventsModuleActionContext(context)
    commit.setCasting(casting)
  },
  async setOpenScene (context: EventsContext, scene: string) {
    const { commit } = eventsModuleActionContext(context)
    commit.setOpenScene(scene)
  },
  setSceneFilter (context: EventsContext, filter: string) {
    const { commit } = eventsModuleActionContext(context)
    commit.setSceneFilter(filter)
  },
  async updateDelay (context: EventsContext, params: { streamId: string, delay: number } ) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not updateDelay, currentCast is null')
    await fb.db.collection(CollectionNames.CASTS).doc(state.currentCast.id).set({
      source_streams: {
        [params.streamId]: {
          delay: params.delay
        }
      }
    } as DeepPartial<Cast>, { merge: true })
  },
  async deleteEvent (_context: EventsContext, eventId: string): Promise<ResultVoid<'database'|'unknown'>> {
    // TOOD: Maybe make this a transaction?
    try {
      const date = new Date()
      const eventDoc = await studio.db.collection(CollectionNames.EVENTS).doc(eventId).get()
      if (!eventDoc.isSuccess) throw new Error(`Can't delete event '${eventId}' if it doesn't exist.`)
      const event = eventDoc.value
      await fb.db.collection(CollectionNames.EVENTS).doc(eventId)
                 .set({ deleted: true, end_date: helpers.getAtLeastStartDate(event, date) }, { merge: true })
      const casts = await studio.db.collection(CollectionNames.CASTS)
                                   .where('event_id', '==', eventId)
                                   .where('team_id', '==', event.team_id)
                                   .get()
      if (!casts.isSuccess) throw new Error(`Can't delete event '${eventId}' if it has no casts.`)
      const promises: Promise<void>[] = []
      Object.entries(casts.value).forEach(([castId, cast]) => {
        if (cast.end_date > date) {
          promises.push(
            fb.db.collection(CollectionNames.CASTS).doc(castId)
                 .set({ deleted: true, end_date: helpers.getAtLeastStartDate(cast, date) }, { merge: true })
                 .catch(error => { console.error('Delete event - list ', error) })
          )
        } else {
          promises.push(
            fb.db.collection(CollectionNames.CASTS).doc(castId)
                 .set({ deleted: true }, { merge: true })
                 .catch(error => { console.error('Delete event - set delete true ', error) })
          )
        }
      })
      await Promise.all(promises)
      return Result.ok()
    } catch (error) {
      return Result.fromUnknownError('database', error)
    }
  },
  async stopEvent (_context: EventsContext, eventId: string): Promise<void|any> {
    // TOOD: Maybe make this a transaction?
    try {
      const date = new Date()
      const eventDoc = await studio.db.collection(CollectionNames.EVENTS).doc(eventId).get()
      if (!eventDoc.isSuccess) throw new Error(`Can't stop event '${eventId}' if it doesn't exist.`)
      const event = eventDoc.value
      const endDate = event.end_date
      if (endDate > date) {
        const newDate = helpers.getAtLeastStartDate(event, date)
        const updatedEvent = await studio.db.collection(CollectionNames.EVENTS)
          .doc(eventId).set({ end_date: newDate }, MergeOption.MERGE)
        updatedEvent.logIfError(`Could not update end_date of event ${ eventId }`)
      } else {
        console.warn(`Event ${eventId} already ended.`)
      }
      const casts = await studio.db.collection(CollectionNames.CASTS)
        .where('event_id', '==', eventId)
        .where('team_id', '==', event.team_id)
        .get()
      if (!casts.isSuccess) {
        casts.log('Something wrong getting casts from the db')
        return
      }
      const promises: Promise<void>[] = []
      Object.entries(casts.value).forEach(([castId, cast]) => {
        if (cast.end_date > date) {
          const updateCastFn = async () => {
              const result = await studio.db
                .collection(CollectionNames.CASTS)
                .doc(castId)
                .set({ end_date: helpers.getAtLeastStartDate(cast, date) }, MergeOption.MERGE)
              if (!result.isSuccess) result.log('Could not update end_date of cast')
            }
          promises.push(updateCastFn())
        }
      })
      await Promise.all(promises)
    } catch (error) {
      console.error('StopEvent error', error)
    }
  },
  setAudioInit (context: EventsContext, init: boolean): void {
    const { commit } = eventsModuleActionContext(context)
    commit.setAudioInit(init)
  },
  async addStreamToCast (context: EventsContext, stream: string) {
    const { state } = eventsModuleActionContext(context)
    console.log('I AM HERE', stream, state.currentCast)
    if (state.currentCast === null) throw new Error('Could not addStreamToCast, currentCast is null')
    // add the stream to the input_streams map
    const inputStreams: KeyDict<CastInputStream> = cloneDeep(state.currentCast.input_streams)
    inputStreams[stream] = new CastInputStream()

    const sourceStreams: KeyDict<SourceStream> = cloneDeep(state.currentCast.source_streams)
    sourceStreams[stream] = {
      active: true,
      type: StreamType.Broadcast,
      volume: 0,
      volumeLeft: 0,
      volumeRight: 0
    }
    // Now place it on the lowest order in each scene:

    const scenesContent: KeyDict<DeepPartial<Scene>> = {}
    Object.values(state.currentCast.scenes).forEach(scene => {
      if (scene.id) {
        scenesContent[scene.id] = { contents: {} }
        if (scene.contents) {
          const broadcaststreams = Object.values(scene.contents).filter(it => {
            return it.type === AssetType.Broadcast
          })
          scenesContent[scene.id]!.contents![stream] = { order: broadcaststreams.length + 1 }
        }
      }
    })
    // TODO: in all commits: put async fb calls in actions, Mutations Must Be Synchronous
    // see https://vuex.vuejs.org/guide/mutations.html
    const payload = Convertable.toObject({ source_streams: sourceStreams, scenes: scenesContent, input_streams: inputStreams })
    fb.db
      .collection(CollectionNames.CASTS)
      .doc(state.currentCast.id)
      .set(payload, { merge: true })
      .catch(error => {
        console.error('Add Stream to Cast ', error)
      })
  },
  deleteStreamFromCast (context: EventsContext, stream: string) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not deleteStreamFromCast, currentCast is null')
    const ss = 'source_streams.' + stream
    const is = 'input_streams.' + stream
    fb.db.collection(CollectionNames.CASTS).doc(state.currentCast.id).update({
      [ss]: fb.deleteField,
      [is]: fb.deleteField
    }).catch(error => {
      console.error('Delete Stream from Cast ', error)
    })
  },
  updateMonitorVolume (context: EventsContext, volume: KeyDict<number>) {
    const { commit } = eventsModuleActionContext(context)
    commit.updateMonitorVolume(volume)
  },
  updateMonitorMutes (context: EventsContext, payload: KeyDict<boolean>) {
    const { commit } = eventsModuleActionContext(context)
    commit.updateMonitorMutes(payload)
  },
  updateActiveAudioTracks (context: EventsContext,
                            payload: { castId: string, trackLabel: MixerTrackLabelWithDefault, enabled: boolean})
  {
    eventsModuleActionContext(context).commit.updateActiveAudioTracks(payload)
  },
  clearActiveAudioTracks (context: EventsContext, castId: string) {
    eventsModuleActionContext(context).commit.clearActiveAudioTracks(castId)
  },
  updateTalkbackVolumes (context: EventsContext, payload: KeyDict<number>) {
    const { commit } = eventsModuleActionContext(context)
    commit.updateTalkbackVolumes(payload)
  },
  updateTalkbackMutes (context: EventsContext, payload: KeyDict<boolean>) {
    const { commit } = eventsModuleActionContext(context)
    commit.updateTalkbackMutes(payload)
  },
  // TODO: remove function below and refactor all code to use the function from the casts module
  // WARN: Only use this method to remove commentators. Because this only removes the online sessions and marks the
  //       invited_casters entry as deleted, it is not sufficient for correctly removing casters and anonymous guests
  //       ever since we started using Switcher style casts.
  //       For those the corresponding methods in sceneSources.ts must be used instead (to properly clean up the
  //       scenes associated with casters and guests).
  async removeCasterFromCast (context: EventsContext, castInfo: { casterId: string, castId: string, anonymousId?: string }) {
    try {
      const { state } = eventsModuleActionContext(context)
      if (state.currentCast === null) throw new Error('Could not removeCasterFromCast, currentCast is null')
      if (state.currentCast.invited_casters === null) throw new Error('Could not removeCasterFromCast, invited_casters is null')
      const onlineSessions = state.currentCast.online_sessions
      if (castInfo.anonymousId !== undefined) {
        const sessionIdsToRemove = onlineSessions[castInfo.anonymousId]
        const sessions = onlineSessions[castInfo.casterId]
        if (sessions !== undefined && sessionIdsToRemove !== undefined) {
          onlineSessions[castInfo.casterId] = sessions.filter((sessionId) => !sessionIdsToRemove.includes(sessionId))
        }
        onlineSessions[castInfo.anonymousId] = []
      }

      const invitedCasters = removeCasterFromInvitedCasters(state.currentCast.invited_casters, castInfo.casterId)
      await fb.db.collection('casts').doc(castInfo.castId).update({
        online_sessions: onlineSessions,
        invited_casters: Convertable.toObject(invitedCasters)
      })
    } catch (error) {
      console.error('Remove Caster From Cast ', error)
    }
  },
  async addCasterToCast (context: EventsContext, payload: { userId: string, audioTrack?: MixerTrackLabel }):
    Promise<ResultVoid<'not_exists'|'invalid_data'|'database'>>
  {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) return Result.fail('not_exists', 'No current cast')
    const profile = store.state.team.currentTeam[payload.userId]
    if (profile !== undefined) {
      const config: Pick<InvitedCaster, 'anonymous'|'userId'|'deleted'> & { audioTracks?: AudioTrackInfoDict } = {
        anonymous: !!profile.anonymous,
        userId: payload.userId,
        deleted: false
      }
      if (payload.audioTrack !== undefined) config.audioTracks = { [payload.audioTrack]: { trackId: 1 }}
      const result = await studio.db.collection(CollectionNames.CASTS).doc(state.currentCast.id).set({
        invited_casters: {
          [payload.userId]: new InvitedCaster(config)
        }
      }, MergeOption.MERGE)
      if (result.isSuccess) {
        store.dispatch.logs.addLog({
          message: 'User added as caster to cast.',
          cast: state.currentCast.id,
          data: { user: payload.userId, cast: state.currentCast.id },
          level: 'info'
        }).catch((error) => console.error(error))
        return Result.ok()
      } else {
        store.dispatch.logs.addLog({
          message: 'Failed to add caster to cast',
          cast: state.currentCast?.id,
          data: { error: result.message },
          level: 'error'
        }).catch((error) => console.error(error))
        return result
      }
    } else {
      store.dispatch.logs.addLog({
        message: 'Failed to add caster to cast',
        cast: state.currentCast?.id,
        data: { error: `No profile found with id "${ payload.userId }".` },
        level: 'error'
      }).catch((error) => console.error(error))
      return Result.fail('invalid_data', 'No profile found')
    }
  },
  async addOutputToCast (context: EventsContext, payload: KeyDict<CustomRtmpDestination>) {
    const { commit } = eventsModuleActionContext(context)
    commit.addOutputToCast(payload)
  },
  async deleteCastOutput (context: EventsContext, payload: string) {
    const { commit } = eventsModuleActionContext(context)
    commit.deleteCastOutput(payload)
  },

  async toggleCastOutput (_context: EventsContext, payload: {castId: string, streamId: string, active: boolean}) {
    const currentCastId = payload.castId
    const streamId = payload.streamId
    const active = payload.active
    if (active  === true) {
      fb.db.collection(CollectionNames.CASTS).doc(currentCastId).set(
        {
          custom_rtmp_destinations: { [streamId]: {'active': active, 'start_time': new Date().getTime() / 1000} }
        },
        { merge: true }
      )
      .catch(error=>{
        console.error('toggleCastOutput:', error)
      })
    }
    else {
      fb.db.collection(CollectionNames.CASTS).doc(currentCastId).set(
        {
          custom_rtmp_destinations: { [streamId]: {'active':active}}
        },
        { merge: true }
      )
      .catch(error=>{
        console.error('toggleCastOutput:', error)
      })
    }
  },
  async setCastReady (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.setCastReady()
  },
  async setActionPaletteTab (context: EventsContext, tab: ActionPaletteTabs) {
    const { commit } = eventsModuleActionContext(context)
    const activeTab = store.state.events.actionPaletteTab
    if (activeTab !== tab) commit.updateActionPaletteTab(tab)
  },
  setTalkbackStatus (context: EventsContext, talkback: boolean) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not set talkbackstatus, currentcast is null')
    const currentCastId = state.currentCast.id
    const talkbackCopy: KeyDict<boolean> = cloneDeep(state.currentCast.talk_back)
    if (state.currentCasterStream !== null) {
      talkbackCopy[state.currentCasterStream] = talkback
    }
    fb.db
      .collection(CollectionNames.CASTS)
      .doc(currentCastId)
      .set({ talk_back: talkbackCopy }, { merge: true })
      .catch(error => {
        console.error('Set Talkback Status ', error)
      })
  },
  clearEvents (context: EventsContext) {
    const { commit } = eventsModuleActionContext(context)
    commit.clearEvents()
  },
  async setUserModerator (_context: EventsContext, { userId, castId, isConnected }: { userId: string, castId: string, isConnected: boolean }) {
    let updates: DeepPartial<Cast> = { moderators: { [userId]: { connected: isConnected } } }
    if (!isConnected) {
       // @ts-ignore
      updates = { moderators: { [userId]: DatabaseAction.deleteField() } }
    }
    return studio.db.collection(CollectionNames.CASTS).doc(castId).set(updates, MergeOption.MERGE)
  },
  async refreshCastToken (context: EventsContext, cast: string) {
    try {
      const { state } = eventsModuleActionContext(context)
      if (state.currentCast === null) throw new Error('Could not refreshCastToken, currentCast is null')
      const token = generateRandomString(24)
      const tokenResult = await studio.db.collection(CollectionNames.CASTS).doc(cast).set({ token }, MergeOption.MERGE)
      if (tokenResult.isFailure) throw new Error(tokenResult.message)

      //Delete now-invalidated event link pointer
      const pointerId = store.state.pointers.currentPointerId
      if (pointerId) await store.dispatch.pointers.deletePointer(pointerId)

      //Generate a new pointer
      const newPointer = LinkPointer.create({
        pointerType: PointerTypes.EVENT,
        eventId: cast,
        token,
        urlSuffix: sanitizeStringForUrl(state.currentCast.name ?? ''),
        studioVersion: store.state.team.team?.version ?? '1.0.0',
        team_id: store.getters.user.teamId ?? ''
      })
      if (newPointer.isFailure) throw new Error(newPointer.message)
      const resultPointer = await studio.db.collection(CollectionNames.POINTERS).add(newPointer.value)
      if (resultPointer.isFailure) throw new Error(resultPointer.message)
    } catch (error) {
      console.error('events.ts:refreshCastToken', error)
    }
  },
  openActionPaletteInNewWindow (context: EventsContext) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not openActionPalette in new window, currentCast is null')
    const baseurl = window.location.origin
    const params = { id: state.currentCast.id, mode: BreakoutMode.ActionPalette }
    window.open(baseurl + helpers.getURL(router, 'liveEventBreakout', params))
  },
  openPreviewLocalInNewWindow (context: EventsContext) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not openPreviewLocal in new window, currentCast is null')
    const baseurl = window.location.origin
    const params = { id: state.currentCast.id, mode: BreakoutMode.PreviewLocal }
    window.open(baseurl + helpers.getURL(router, 'liveEventBreakout', params))
  },
  async updateSelfMutedState (_context: EventsContext, { castId, userId, isSelfMuted } : { castId: string, userId: string, isSelfMuted: boolean }) {
    if (!(castId && userId)) return
    const payload = {
      self_muted: {
        [userId]: isSelfMuted
      }
    }
    try {
      await fb.db.collection(CollectionNames.CASTS).doc(castId).set(payload, { merge: true })
    } catch (error) {
      console.error('error from updateSelfMutedState', error, payload, castId)
    }
  },
  updateSimplecgText (context: EventsContext, payload: { simplecgId: string, newText: string, scenesIncluded: string[] }) {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) throw new Error('Could not updateSimpleCgText, currentCast is null')
    if (payload.simplecgId && payload.scenesIncluded?.length > 0 && state.currentCast?.scenes) {
      //Update text content of the asset across all scenes
      payload.scenesIncluded.forEach((scene) => {
        if (state.currentCast?.scenes[scene]?.contents?.[payload.simplecgId]?.simplecgText) {
          fb.db.collection('casts').doc(state.currentCast.id).update({
            [`scenes.${scene}.contents.${payload.simplecgId}.simplecgText`]: payload.newText
          }) //Update caster's entry in dictionary in db
        }
      })
    }
  },
  async preloadCast (context: EventsContext, cast: Cast|null) {
    const { commit } = eventsModuleActionContext(context)
    if (cast === null) commit.setPreloadSourceStreams({})
    else {
      commit.setPreloadSourceStreams(cast.source_streams)
      const promises: Promise<void>[] = []
      Object.keys(cast.source_streams).forEach((streamId) => {
        promises.push((async () => {
          const result = await studio.db.collection(CollectionNames.STREAMS).doc(streamId).get()
          if (result.isSuccess) commit.streamDocLoaded({ data: result.value, id: streamId })
        })())
      })
      await Promise.all(promises)
    }
  },
  async setActive (_context: EventsContext, payload: { castId: string, active: boolean }) {
    const update: DeepPartial<Cast> = {
      activeState: {
        idle: !payload.active
      }
    }
    if (payload.active) update!.activeState!.lastEntered = new Date()
    const result = await studio.db.collection(CollectionNames.CASTS).doc(payload.castId).set(update, MergeOption.MERGE)
    result.logIfError('Could not set cast to active')
  },
  async setEditPresetId (context: EventsContext, presetId: string|null) {
    const { commit } = eventsModuleActionContext(context)
    commit.setEditPresetId(presetId)
  },
  async setEditSceneId (context: EventsContext, sceneId: string|null) {
    const { commit } = eventsModuleActionContext(context)
    commit.setEditSceneId(sceneId)
  },
  async findAndCleanupGhostStream (_context: EventsContext, payload: { castId: string, streamIds: string[] }) {
    const streamIdsToCleanup: string[] = []
    for (const streamId of payload.streamIds) {
      const streamDoc = await studio.db.collection(CollectionNames.STREAMS).doc(streamId).get()
      if (streamDoc.isFailure) continue
      const stream = streamDoc.value
      if (Object.keys(stream.variants).length > 0) continue
      streamIdsToCleanup.push(streamId)
    }
    if (streamIdsToCleanup.length === 0) return
    const castUpdates: KeyDict<firebase.firestore.FieldValue> = {}
    for (const streamId of streamIdsToCleanup) {
      castUpdates[`source_streams.${ streamId }`] = fb.deleteField
    }
    try {
      await fb.db.collection(CollectionNames.CASTS).doc(payload.castId).update(castUpdates)
    } catch (error) {
      console.error(error)
    }
  },
  async updateCast<E extends string> (
    _context: EventsContext, payload: { cast: Cast, update: UpdateFunction<Cast, E> }
  ): Promise<ResultVoid<'database'|'unknown'|E>> {
    return updateCast(payload.cast, payload.update)
  },
  async updateCurrentCast<E extends string> (
    context: EventsContext, updateFunction: UpdateFunction<Cast, E>
  ): Promise<ResultVoid<'not_exists'|'database'|'unknown'|E>> {
    const { state } = eventsModuleActionContext(context)
    if (state.currentCast === null) return Result.fail('not_exists', 'No current cast')
    return updateCast(state.currentCast, updateFunction)
  },
  async getCastList (_context: EventsContext, payload: { teamId: string, completedCasts: boolean }) {
    const endDateComparator = payload.completedCasts ? '<=' : '>'
    return await studio.db.collection(CollectionNames.CASTS)
      .where('team_id', '==', payload.teamId)
      .where('end_date', endDateComparator, new Date())
      .where('deleted', '==', false)
      .get()
  },
  async getCastOfEvent (_content: EventsContext, payload: { teamId: string, eventId: string }) {
    const result = await studio.db.collection(CollectionNames.CASTS)
      .where('team_id', '==', payload.teamId)
      .where('event_id', '==', payload.eventId)
      .get()
    if (result.isFailure) {
      result.logIfError('Error fetching cast for event')
      return
    }
    const castDict = result.value
    return Object.values(castDict).find((cast) => cast.event_id === payload.eventId)
  }
}

export default actions
