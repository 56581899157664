import { LocalAudioSource } from '@/store/types'
import { MutedProp } from './streams'

export type AudioPayload = {
  key: string,
  value: number,
  name: string,
}

export type AudioMutePayload = {
  status: boolean,
  name: MutedProp,
  id: string
}

export enum MonitorMixGlobalChannels {
  MASTER = 'master',
  TALKBACK = 'talkback'
}

export type ActiveLocalAudio = {
  localSource: LocalAudioSource|null,
  localChannels: string[]
}

export enum AudioContext {
  GridPanel = 'GridPanel',
  ScenesPanel = 'ScenesPanel',
  PresetsPanel = 'PresetsPanel'
}
