import { Loader } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'

export function hasDelete<TBase extends StudioBaseMixin> (Base: TBase) {
  return class DeleteClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.deleted = Loader.loadBoolean(arg[0], 'deleted', false)
    }
    deleted: boolean

    setDeleted (deleted: boolean): this {
      this.deleted = deleted
      return this
    }
  }
}

export type Deletable = Mixin<typeof hasDelete>
