import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import env from '@/env'
import { FirestoreConnections, NewTestAppParams } from '@/modules/firebase/types'

// eslint-disable-next-line max-lines-per-function
const createFirebaseDatabase = (): FirestoreConnections => {
  const app = firebase.initializeApp(env.firebase)
  const projectId = env.firebase.projectId
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
  const rtdbServerTimestamp = firebase.database.ServerValue.TIMESTAMP
  const rtdb = app.database()
  const fb: FirestoreConnections = {
    // @ts-ignore
    firebase,
    auth: app.auth(),
    firestorage: firebase.storage(),
    functions: firebase.functions(),
    db: firebase.firestore(app),
    rtdb,
    projectId,
    serverTimestamp,
    rtdbServerTimestamp,
    deleteField: firebase.firestore.FieldValue.delete(),
    arrayUnionField: firebase.firestore.FieldValue.arrayUnion,
    arrayRemoveField: firebase.firestore.FieldValue.arrayRemove,
    increment: (amount: number) => firebase.firestore.FieldValue.increment(amount),
    test: {
      cleanupConnection: () => { throw new Error('Cleanup connection only implemented for testing env') },
      clearDB: () => { throw new Error('ClearDB only implemented for testing env') },
      get dbAdmin (): firebase.firestore.Firestore { throw new Error('dbAdmin only implemented for testing env')},
      get deleteField (): firebase.firestore.FieldValue {
        throw new Error('deleteField only implemented for testing env')
      },
      initializeTestApp: (_params: Partial<NewTestAppParams>) => {
        throw new Error('initializeTestApp only implemented for testing env')
      },
      assertFails: (_pr: Promise<any>) => { throw new Error('assertFails only implemented for testing env') },
      assertSucceeds: (_pr: Promise<any>) => { throw new Error('assertSucceeds only implemented for testing env') },
      loadFirestoreRules: () => { throw new Error('loadFirestoreRules only implemented for testing env') }
    }
  }
  return fb
}

export { createFirebaseDatabase }
