import store from '@/store'
import { useCurrentCast } from '@/modules/casts/compositions'
import { AssetType } from '@/types/assets'
import { computed, ComputedRef, reactive, ref, watch } from 'vue'
import { orderBy } from 'lodash'
import { SceneContentType, SceneSourceType } from '../types'
import { SceneContent } from '@/types/sceneContent'
import { Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { ClipEndAction, VideoPlayerPlayState } from '@/types/videoplayer'
import useScenePresets from './scenePresets'
import { useVideoPlayer } from '@/modules/video/compositions'
import { sleep } from '@/testing'

interface DesiredAsset {
  assetIndex: number,
  sceneId?: string,
  timeStamp?: number
}

// export to be able to reset in unit tests
export const desiredAsset: DesiredAsset = reactive({ assetIndex: -1 })

const getManagedMediaContent = (sceneContents: KeyDict<SceneContent>,
  sceneSourceType: SceneSourceType): SceneContent[] => {
  const managedContent: SceneContent[] = []

  for (const content of Object.values(sceneContents)) {
    if (content.contentType !== SceneContentType.Content) continue
    if (sceneSourceType === SceneSourceType.Graphic) {
      if (content.type === AssetType.Graphic) managedContent.push(content)
    } else if (sceneSourceType === SceneSourceType.Playlist) {
      if (content.type === AssetType.Video) managedContent.push(content)
    }
  }
  return managedContent
}

const useSceneMedia = (sceneId: ComputedRef<string|null|undefined>) => {
  const { currentCast } = useCurrentCast()
  const { isValidSceneContent } = useScenePresets()
  const { currentClipPlayState, playCurrentClip, pauseCurrentClip,
    restartCurrentClip } = useVideoPlayer(ref('player1'))

  watch(sceneId, (_newSceneId, oldSceneId) => {
    if (desiredAsset.assetIndex !== -1 && desiredAsset.sceneId === oldSceneId) desiredAsset.assetIndex = -1
  })

  const isClipPlayerReady = computed(() => {
    return !!currentClipPlayState.value && (currentClipPlayState.value.playstate !== VideoPlayerPlayState.LOADING)
  })

  const sceneMediaContentsOrdered: ComputedRef<SceneContent[]> = computed(() => {
    if (!currentCast.value || !sceneId?.value) return []

    const sceneSourceType = currentCast.value.getSceneSourceType(sceneId.value)
    if (![SceneSourceType.Graphic, SceneSourceType.Playlist].includes(sceneSourceType)
      || !currentCast.value.scenesWithPresets[sceneId.value]?.contents) return []

    const sceneContents = currentCast.value.scenesWithPresets[sceneId.value].contents
    const managedContent = getManagedMediaContent(sceneContents, sceneSourceType)
    return orderBy(managedContent, ['order'])
  })

  const activeMediaIndexActual = computed(() => sceneMediaContentsOrdered.value.findIndex((content) => content.active))

  const isSceneSourcePlaylist =
    computed(() => currentCast.value?.getSceneSourceType(sceneId.value ?? '') === SceneSourceType.Playlist)

  const hasChainedClips = computed(() => sceneMediaContentsOrdered.value.some((content) =>
    content.clip_end_action === ClipEndAction.Next))

  const isAtStart = computed(() => sceneMediaContentsOrdered.value.length <= 1
    || (!hasChainedClips.value && activeMediaIndex.value <= 0))

  const isAtEnd = computed(() => sceneMediaContentsOrdered.value.length <= 1
    || (!hasChainedClips.value && activeMediaIndex.value >= sceneMediaContentsOrdered.value.length - 1))

  const setAssetActiveForScene = async (assetId: string) => {
    if (!currentCast.value || !sceneId.value) {
      console.error(`Setting asset active failed for scene: ${sceneId.value}, assetId: ${assetId}`)
      return Result.fail('invalid_param', 'No current cast')
    }

    const sceneContents =  currentCast.value.scenesWithPresets[sceneId.value].contents ?? {}
    if (!sceneContents[assetId]) {
      console.error(`AssetId: ${assetId} not found in scene: ${sceneId.value}. Failed to set asset active.`)
      return Result.fail('invalid_param', 'Asset not in scene')
    }

    const assetActiveStates: KeyDict<boolean> = {}
    Object.values(sceneContents).forEach((content) => {
      if (!isValidSceneContent(content, SceneContentType.Content)) return
      else assetActiveStates[content.id] = false
    })
    assetActiveStates[assetId] = true

    const result = await store.dispatch.events.updateCurrentCast((cast) =>
      cast.updateContentActiveForScene(sceneId.value ?? '', assetActiveStates))
    if (result.isSuccess && sceneContents[assetId].type === AssetType.Video) {
      await store.dispatch.videoPlayer.playAsset({ playerName: 'player1', assetId })
    }
    return result
  }

  const goToPreviousAsset = async () => {
    if (isSceneSourcePlaylist.value && !isClipPlayerReady.value) {
      return Result.fail('invalid_state', 'Cannot change active asset while loading assets')
    }

    let prevIndex = 0
    if (activeMediaIndex.value > 0) prevIndex = activeMediaIndex.value - 1
    else if (hasChainedClips.value) prevIndex = sceneMediaContentsOrdered.value.length - 1

    return await setDesiredAssetActive(prevIndex)
  }

  const goToNextAsset = async () => {
    if (isSceneSourcePlaylist.value && !isClipPlayerReady.value) {
      return Result.fail('invalid_state', 'Cannot change active asset while loading assets')
    }

    let nextIndex = 0
    if (activeMediaIndex.value < sceneMediaContentsOrdered.value.length - 1) nextIndex = activeMediaIndex.value + 1
    else if (hasChainedClips.value) nextIndex = 0

    return await setDesiredAssetActive(nextIndex)
  }

  // Handle clips differently to manage possible flip flop of activeIndex as backend switches clips
  // TODO: Remove this special handling once flip flop issue is resolve
  const setDesiredAssetActive = async (assetIndex: number) => {
    const desiredContent = sceneMediaContentsOrdered.value[assetIndex]
    if (!desiredContent) return Result.fail('invalid_param',
      `Failed to make asset active for scene ${sceneId.value}. Asset index:${assetIndex} out of bounds`)

    if (desiredContent.type === AssetType.Video) {
      const setActiveTimestamp = Date.now()
      desiredAsset.assetIndex = assetIndex
      desiredAsset.sceneId = sceneId.value ?? ''
      desiredAsset.timeStamp = setActiveTimestamp

      const result = await setAssetActiveForScene(desiredContent.id)
      result.logIfError(`Failed to set asset#: ${assetIndex} active for scene:${sceneId.value}`)
      if (result.isSuccess) await sleep(5000)
      //To protect against another activate request that might have occurred during the sleep wait
      if (desiredAsset.timeStamp === setActiveTimestamp) {
        desiredAsset.assetIndex = -1
      }
      return result
    } else {
      const result = await setAssetActiveForScene(desiredContent.id)
      result.logIfError(
        `Failed to set asset#: ${assetIndex} active for scene:${sceneId.value}`)
      return result
    }
  }

  const activeMediaIndex = computed(() => {
    let activeIndexDerived = activeMediaIndexActual.value
    if (desiredAsset.assetIndex !== -1 && desiredAsset.sceneId === sceneId.value) {
        activeIndexDerived = desiredAsset.assetIndex
    }
    return activeIndexDerived
  })

  return {
    sceneMediaContentsOrdered,
    hasChainedClips,
    activeMediaIndex,
    currentClipPlayState,
    isClipPlayerReady,
    isSceneSourcePlaylist,
    isAtStart,
    isAtEnd,
    setAssetActiveForScene,
    playCurrentClip,
    pauseCurrentClip,
    restartCurrentClip,
    goToPreviousAsset,
    goToNextAsset
  }
}

export default useSceneMedia
