import { ActionContext } from 'vuex'
import { UiState, Modals, Popover, Modes } from '../types'
import { defineModule } from 'direct-vuex'
import { moduleActionContext } from '@/store'
import store from '@/store'
import { RootState } from '@/store/types'

type UiContext = ActionContext<UiState, RootState>

const getPopoverFullName = (name: Popover, suffix?: string) => suffix?.trim() ? `${name}.${suffix.trim()}` : name

const state =  {
  modes: {},
  openModals: [],
  popovers: {}
} as UiState

const getters = {
  isModalActive: (state: UiState) => (modal: Modals): boolean => {
    return state.openModals.length > 0 ? state.openModals[0] === modal : false
  },
  isModalOpen: (state: UiState) => (modal: Modals): boolean => {
    return state.openModals.includes(modal)
  },
  isModeOn: (state: UiState) => (name: string): boolean => {
    return !!state.modes[name]
  },
  isPopoverOpen: (state: UiState) => (name: Popover, suffix?: string): boolean => {
    const fullName = getPopoverFullName(name, suffix)
    return !!state.popovers[fullName]
  }
}

const actions = {
  openModal (context: UiContext, modal: Modals) {
    const { commit } = uiModuleActionContext(context)
    if (modal === Modals.USER_MEDIA_CONNECT && store.state.usermedia.isConnected) {
      console.warn('User media already connected. Not opening dialog')
      return
    }
    commit.openModal(modal)
  },
  closeModal (context: UiContext, modal: Modals) {
    const { commit } = uiModuleActionContext(context)
    commit.closeModal(modal)
  },
  async toggleModal (context: UiContext, modal: Modals) {
    const { getters, dispatch } = uiModuleActionContext(context)
    if (getters.isModalOpen(modal)) await dispatch.closeModal(modal)
    else await dispatch.openModal(modal)
  },
  closeAllModals (context: UiContext) {
    const { state, commit } = uiModuleActionContext(context)
    while (state.openModals.length > 0) {
      commit.closeModal(state.openModals[0])
    }
  },
  async togglePopover (context: UiContext, params: { name: Popover, suffix?: string, showSingle?: boolean }) {
    const { getters, commit, dispatch } = uiModuleActionContext(context)
    const fullName = getPopoverFullName(params.name, params.suffix)
    if (params.showSingle && !getters.isPopoverOpen(params.name, params.suffix)) {
      await dispatch.showSinglePopover({ name: params.name, suffix: params.suffix })
    } else {
      commit.showPopover({ name: fullName, show: !getters.isPopoverOpen(params.name, params.suffix) })
    }
  },
  async showPopover (context: UiContext, params: { name: Popover, suffix?: string, isOpen?: boolean,
    showSingle?: boolean }) {
    const { commit, dispatch } = uiModuleActionContext(context)
    const fullName = getPopoverFullName(params.name, params.suffix)
    const isOpen = params.isOpen ?? true
    if (params.showSingle && isOpen) {
      await dispatch.showSinglePopover({ name: params.name, suffix: params.suffix })
    } else {
      commit.showPopover({ name: fullName, show: isOpen })
    }
  },
  showSinglePopover (context: UiContext, params: { name: Popover, suffix?: string }) {
    const { state, commit } = uiModuleActionContext(context)
    const fullNames = Object.keys(state.popovers)
    for (const fullName of fullNames) {
      const popoverName = fullName.split('.')[0]
      if (popoverName === params.name) commit.showPopover({ name: fullName, show: false })
    }
    const fullName = getPopoverFullName(params.name, params.suffix)
    commit.showPopover({ name: fullName, show: true })
  },
  closeAllPopovers (context: UiContext) {
    const { commit } = uiModuleActionContext(context)
    commit.clearPopovers()
  },
  setMode (context: UiContext, params: { name: Modes, mode: boolean }) {
    const { commit } = uiModuleActionContext(context)
    commit.setMode(params)
  }
}

const mutations = {
  openModal (state: UiState, modal: Modals) {
    if (state.openModals.includes(modal)) {
      state.openModals.splice(state.openModals.indexOf(modal), 1)
    }
    state.openModals.unshift(modal)
  },
  closeModal (state: UiState, modal: Modals) {
    state.openModals.splice(state.openModals.indexOf(modal), 1)
  },
  showPopover (state: UiState, params: { name: string, show: boolean } ) {
    state.popovers[params.name] = params.show
  },
  clearPopovers (state: UiState ) {
    state.popovers = {}
  },
  setMode (state: UiState, params: { name: string, mode: boolean }) {
    state.modes[params.name] = params.mode
  }
}

const uiModule = defineModule({
  namespaced: true,
  state,
  getters,
  mutations,
  actions
})

export default uiModule
export const uiModuleActionContext = (context: UiContext) => moduleActionContext(context, uiModule)

