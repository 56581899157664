import fb from '@/firebase'
import firebase from 'firebase/compat/app'
import { ActionContext } from 'vuex'
import { AdminState, RootState } from '../types'
import { LegalInfo, IOSSettings, LibratoConfig, TranscodingRegion, Notifications, ClosestRegion, NimbleRegion, WRTCServerType } from '@/types/admin'
import { moduleActionContext } from '..'
import { Release, Version } from '@/modules/ops/types/release'
import { Feature, FeatureMap, updateGlobalFeatureFlags } from '@/featureFlags/featureFlags'
import { DeepPartial, KeyDict } from '@/types'
import studio, { CollectionNames, AdminDocNames } from '@/modules/database/utils'
import { cloneDeep, pickBy } from 'lodash'
import { defineModule } from 'direct-vuex'
import { DatabaseAction, MergeOption } from '@/modules/database/utils/database'
import { Result, ResultVoid } from 'kiswe-ui'
import { FirestoreDocSnapper, FirestoreSnapperType } from '@/modules/database/utils/databaseQuerySubscriptions'
import { VersionNumber } from '@/modules/classBuilder/mixins/hasVersion'
import { Olympics } from '@/modules/olympics/classes'

type AdminContext = ActionContext<AdminState, RootState>

const SERVICES_IN_EACH_VERSION = ['ftpupload', 'BillingCalculator']

// initial state
const state: AdminState = {
  featureFlags: new Map(),
  hasLoadedFeatureFlags: false,
  iOS: new IOSSettings({}),
  legalinfo: {
    // @ts-ignore
    privacyLastPublished: {
      seconds: (new Date(2020, 5, 1)).getTime() / 1000
    },
    privacyUrl: "https://kiswe.com/cloudcast/privacy",
    // @ts-ignore
    tosLastPublished: {
      seconds: (new Date(2020, 5, 1)).getTime() / 1000 //Fallback of an existing published date
    },
    tosUrl: "https://kiswe.com/cloudcast/termsofservice" //Fallback URL
  },
  librato: null,
  nimbleregions: {},
  notifications: null,
  olympics: null,
  release: null,
  transcodingregions: {}
}

const actions = {
  async subscribeTranscodingRegions (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'transcodingregions',
      query: studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.TRANSCODING_REGIONS),
      callback: commit.updateTranscodingRegions
    })
    result.logIfError('transcodingregions')
  },
  unsubscribeTranscodingRegions (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    studio.subscriptions.unsubscribe('transcodingregions', commit.clearTranscodingRegions)
  },
  subscribeNimbleRegions (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const snapper: FirestoreDocSnapper = {
      doc: fb.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.NIMBLE_REGIONS),
      mutation: commit.updateNimbleRegions,
      type: FirestoreSnapperType.Document
    }
    studio.subscriptions.subscribeFB('nimbleregions', snapper)
  },
  unsubscribeNimbleRegions (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    studio.subscriptions.unsubscribeFB('nimbleregions', commit.clearNimbleRegions)
  },
  subscribeLibrato (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const snapper: FirestoreDocSnapper = {
      doc: fb.db.collection('admin').doc('librato'),
      mutation: commit.updateLibrato,
      type: FirestoreSnapperType.Document
    }
    studio.subscriptions.subscribeFB('librato', snapper)
  },
  unsubscribeLibrato (_context: AdminContext) {
    studio.subscriptions.unsubscribeFB('librato')
  },
  subscribeIOS (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const snapper: FirestoreDocSnapper = {
      doc: fb.db.collection(CollectionNames.ADMIN).doc('iOS'),
      mutation: commit.updateIOS,
      type: FirestoreSnapperType.Document
    }
    studio.subscriptions.subscribeFB(`ios`, snapper)
  },
  unsubscribeIOS (_context: AdminContext) {
    studio.subscriptions.unsubscribeFB('ios')
  },
  async subscribeFeatureFlags (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'featureflags',
      query: studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.FEATURE_FLAGS),
      callback: commit.updateFeatureFlags
    })
    result.logIfError('subscribeFeatureFlags')
  },
  unsubscribeFeatureFlags (_context: AdminContext) {
    studio.subscriptions.unsubscribe('featureflags')
  },
  subscribeNotifications (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const snapper: FirestoreDocSnapper = {
      doc: fb.db.collection(CollectionNames.ADMIN).doc('notifications'),
      mutation: commit.updateNotifications,
      type: FirestoreSnapperType.Document
    }
    studio.subscriptions.subscribeFB('notifications', snapper)
  },
  unsubscribeNotifications (_context: AdminContext) {
    studio.subscriptions.unsubscribeFB('notifications')
  },
  async subscribeOlympics (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'olympics',
      query: studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.OLYMPICS),
      callback: commit.updateOlympics
    })
    result.logIfError('subscribeOlympics')
  },
  unsubscribeOlympics (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    studio.subscriptions.unsubscribe('olympics', commit.clearOlympics)
  },
  async deleteWowzaServer (context: AdminContext, serverId: string) {
    const { state } = adminModuleActionContext(context)
    for (const [regionId, region] of Object.entries(state.transcodingregions)) {
      const keys = Object.keys(region.wrtcserver)
      if (keys.find(key => key === serverId) !== undefined) {
        if (keys.length === 1) throw new Error('Could not delete the only server in a region')
        // FIXME (tomc): This is really ugly, can make race condition, but tried for maybe 2h to get this to work
        // with the code above, if you get know what the issue is, please let me know?
        const newTranscodingRegions = JSON.parse(JSON.stringify(state.transcodingregions))
        delete newTranscodingRegions[regionId].wrtcserver[serverId]
        fb.db.collection(CollectionNames.ADMIN).doc('transcodingregions').set(newTranscodingRegions)
        return
      }
    }
    throw new Error('Could not find server in any region')
  },
  async enableWowzaServer (context: AdminContext, params: { serverId: string, enabled: boolean }) {
    const { state } = adminModuleActionContext(context)
    for (const [regionId, region] of Object.entries(state.transcodingregions)) {
      const keys = Object.keys(region.wrtcserver)
      const otherEnabledKeys = Object.keys(pickBy(region.wrtcserver, (server, key) => server.enabled && key !== params.serverId))
      if (keys.find(key => key === params.serverId) !== undefined) {
        if (!params.enabled && otherEnabledKeys.length === 0) throw new Error('Could not disable the only server in a region')
        await fb.db.collection(CollectionNames.ADMIN).doc('transcodingregions').set({
          [regionId]: {
            wrtcserver: {
              [params.serverId]: {
                enabled: params.enabled
              }
            }
          }
        } as KeyDict<Partial<TranscodingRegion>>, { merge: true })
        return
      }
    }
    throw new Error('Could not find server in any region')
  },
 async addWowzaServerToRegion (context: AdminContext, params: { serverId: string, region: string }) {
    const { state } = adminModuleActionContext(context)
    const exists = Object.values(state.transcodingregions)
                         .find(region => region.wrtcserver[params.serverId] !== undefined)
    if (exists) throw new Error('Server already exists in a region')
    const region = state.transcodingregions[params.region]
    if (region === undefined) throw new Error('Could not find the region')
    const servers = JSON.parse(JSON.stringify(region.wrtcserver))
    servers[params.serverId] = { url: `${params.serverId}.kiswe.com`, type: WRTCServerType.JANUS}
    const data = {
      [params.region]: {
        wrtcserver: servers
      }
    }
    await fb.db.collection(CollectionNames.ADMIN).doc('transcodingregions').set(data, { merge: true })
  },
  async updateChinaProxySettingForTranscodingRegion (context: AdminContext, params: { region: string, chinaProxy: boolean}) {
    const { state } = adminModuleActionContext(context)
    const region = state.transcodingregions[params.region]
    if (region === undefined) throw new Error('Cannot update china proxy setting for unknown region')
    await fb.db.collection(CollectionNames.ADMIN).doc('transcodingregions').set({
      [params.region]: {
        chinaProxy: params.chinaProxy
      }
    } as KeyDict<Partial<TranscodingRegion>>, { merge: true })
  },
  async updateVisibilityForTranscodingRegion (context: AdminContext, params: { region: string, visible: boolean }) {
    const { state } = adminModuleActionContext(context)
    const region = state.transcodingregions[params.region]
    if (region === undefined) throw new Error('Cannot update visibility for unknown region')
    await fb.db.collection(CollectionNames.ADMIN).doc('transcodingregions').set({
      [params.region]: {
        visible: params.visible
      }
    } as KeyDict<Partial<TranscodingRegion>>, { merge: true })
  },
  async setClosestRegionForTranscodingRegion (context: AdminContext, params: { region: string, closestServers: KeyDict<ClosestRegion> }) {
    const { state } = adminModuleActionContext(context)
    const region = cloneDeep(state.transcodingregions[params.region])
    if (region === undefined) throw new Error('Cannot update closest regions for unknown region')
    region.close_regions = params.closestServers
    await fb.db.collection(CollectionNames.ADMIN).doc('transcodingregions').update({
      [params.region]: JSON.parse(JSON.stringify(region))
    } as KeyDict<Partial<TranscodingRegion>>)
  },
  async updateIOSEnabled (_context: AdminContext, enabled: boolean) {
    await fb.db.collection(CollectionNames.ADMIN).doc('iOS').set({ enabled }, {merge: true})
  },
  async clearTranscodingLoadbalancerCache (_context: AdminContext) {
    await fb.db.collection(CollectionNames.ADMIN).doc('cloudcast_manager_data').set({
      clear_loadbalancer_cache: true
    }, { merge: true })
  },
  async updateFeatureFlag (_context: AdminContext, params: { feature: Feature, enabled: boolean}) {
    return await studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.FEATURE_FLAGS).set({
      [params.feature]: params.enabled
    }, MergeOption.MERGE)
  },
  async subscribeRelease (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'release',
      query: studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.RELEASE),
      callback: commit.updateRelease
    })
    result.logIfError('subscribeRelease')
  },
  unsubscribeRelease (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    studio.subscriptions.unsubscribe('release', commit.cleanupRelease)
  },
  subscribeLegalInfo (context: AdminContext) {
    const { commit } = adminModuleActionContext(context)
    const snapper: FirestoreDocSnapper = {
        doc: fb.db.collection(CollectionNames.ADMIN).doc('legalinfo'),
        mutation: commit.updateLegalInfo,
        type: FirestoreSnapperType.Document
      }
      studio.subscriptions.subscribeFB('legalinfo', snapper)
  },
  unsubscribeLegalInfo () {
    studio.subscriptions.unsubscribeFB('legalinfo')
  },
  async setReleaseFirestoreRules (context: AdminContext, version: string) {
    const { state } = adminModuleActionContext(context)
    if (state.release === null ) throw new Error('No release information to adjust')
    const releaseVersion = state.release.versions[version]
    if (releaseVersion === undefined) throw new Error('Version does not exists')
    const updates: DeepPartial<Release> = { versions: {} }
    Object.entries(state.release.versions).forEach(([versionId, entry]) => {
      if (entry.deploy_firebase?.rules) updates.versions![versionId] = { deploy_firebase : {rules: false} }
      if (versionId === version) updates.versions![versionId] = { deploy_firebase : {rules: true} }
    })
    await fb.db.collection(CollectionNames.ADMIN).doc('release').set(updates, { merge: true })
  },
  async setReleaseFirestoreFunctions (context: AdminContext, version: string) {
    const { state } = adminModuleActionContext(context)
    if (state.release === null ) throw new Error('No release information to adjust')
    const releaseVersion = state.release.versions[version]
    if (releaseVersion === undefined) throw new Error('Version does not exists')
    const updates: DeepPartial<Release> = { versions: {} }
    Object.entries(state.release.versions).forEach(([versionId, entry]) => {
      if (entry.deploy_firebase?.functions) updates.versions![versionId] = { deploy_firebase : {functions: false} }
      if (versionId === version) updates.versions![versionId] = { deploy_firebase : {functions: true} }
    })
    await fb.db.collection(CollectionNames.ADMIN).doc('release').set(updates, { merge: true })
  },
  async setReleaseManifests (context: AdminContext, version: string) {
    const { state } = adminModuleActionContext(context)
    if (state.release === null ) throw new Error('No release information to adjust')
    const releaseVersion = state.release.versions[version]
    if (releaseVersion === undefined) throw new Error('Version does not exists')
    const updates: DeepPartial<Release> = { versions: {} }
    Object.entries(state.release.versions).forEach(([versionId, entry]) => {
      if (entry.deploy_firebase?.manifests) updates.versions![versionId] = { deploy_firebase : {manifests: false} }
      if (versionId === version) updates.versions![versionId] = { deploy_firebase : {manifests: true} }
    })
    await fb.db.collection(CollectionNames.ADMIN).doc('release').set(updates, { merge: true })
  },
  async setReleaseService (context: AdminContext, {version, service}: { version: string, service: string }) {
    const { state } = adminModuleActionContext(context)
    if (state.release === null ) throw new Error('No release information to adjust')
    const releaseVersion = state.release.versions[version]
    if (releaseVersion === undefined) throw new Error('Version does not exists')
    const updates: DeepPartial<Release> = { versions: {} }
    Object.entries(state.release.versions).forEach(([versionId, entry]) => {
      if (versionId === version) updates.versions![versionId] = { services_enabled : { [service]: true} }
      else if (entry.services_enabled === undefined) updates.versions![versionId] = { services_enabled : { [service]: false} }
      else if (entry.services_enabled[service] && !SERVICES_IN_EACH_VERSION.includes(service)) {
        updates.versions![versionId] = { services_enabled : { [service]: false} }
      }
    })
    await fb.db.collection(CollectionNames.ADMIN).doc('release').set(updates, { merge: true })
  },
  async setTrialReleaseVersion (context: AdminContext,
                                { version }: { version: VersionNumber }): Promise<ResultVoid<'database'|'invalid_version'>> {
    const { state } = adminModuleActionContext(context)
    if (state.release === null ) return Result.fail('invalid_version', 'No release information to adjust')
    const releaseVersion = state.release.versions[version]
    if (releaseVersion === undefined) return Result.fail('invalid_version', 'Version does not exists')
    return await studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.RELEASE).set({
      trialVersion: version
    }, MergeOption.MERGE)
  },
  async addNewVersionToRelease (context: AdminContext, version: Version):
    Promise<ResultVoid<'database'|'invalid_params'>>
  {
    const { state } = adminModuleActionContext(context)
    if (version.id === '') return Result.fail('invalid_params', 'Version id cannot be empty')
    const versions = state.release?.versions
    if (versions === undefined) return Result.fail('invalid_params', 'No existing version info available')
    if (Object.keys(versions).includes(version.id)) return Result.fail('invalid_params', 'Version number is not unique')
    if (version.branch_back === '') return Result.fail('invalid_params', 'Branch Back cannot be empty')
    if (version.branch_front === '') return Result.fail('invalid_params', 'Branch Front cannot be empty')
    if (version.ngcvp_version) version.ngcvp_version = version.ngcvp_version.trim()
    return await studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.RELEASE).set({
      versions: {
        [version.id]: version
      }
    }, MergeOption.MERGE)
  },
  async deleteReleaseVersion (context: AdminContext, version: string): Promise<ResultVoid<'database'|'protected'>> {
    const { state } = adminModuleActionContext(context)
    if (state.release?.default === version) return Result.fail('protected', 'Cannot delete default version')
    return studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.RELEASE).set({
      versions: {
        [version]: DatabaseAction.deleteField()
      }
    }, MergeOption.MERGE)
  },
  async setDefaultReleaseVersion (context: AdminContext, version: string) {
    const { state } = adminModuleActionContext(context)
    if (state.release?.versions[version] === undefined) throw new Error('Cannot set unknown version default')
    await fb.db.collection(CollectionNames.ADMIN).doc('release').set({
      default: version
    }, { merge: true })
  },
  async setNGCVPForRelease (context: AdminContext, { version, ngcvpVersion }: { version: string, ngcvpVersion: string })
    : Promise<ResultVoid<'database'|'invalid_params'>>
  {
    const { state } = adminModuleActionContext(context)
    if (state.release?.versions[version] === undefined) {
      return Result.fail('invalid_params', 'Cannot update ngcvp version for unknown version')
    }
    return await studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.RELEASE).set({
      versions: {
        [version]: {
          ngcvp_version: ngcvpVersion.trim()
        }
      }
    }, MergeOption.MERGE)
  }
}

const mutations = {
  updateTranscodingRegions (state: AdminState, regionsData: KeyDict<TranscodingRegion>) {
    state.transcodingregions = regionsData
  },
  updateNimbleRegions (state: AdminState, doc: firebase.firestore.DocumentSnapshot) {
    if (!doc.exists) return
    const regionData = doc.data()
    if (regionData === undefined) return
    state.nimbleregions = {}
    for (const [key, region] of Object.entries(regionData)) {
      state.nimbleregions[key] = new NimbleRegion(region)
    }
  },
  clearTranscodingRegions (state: AdminState) {
    state.transcodingregions = {}
  },
  clearNimbleRegions (state: AdminState) {
    state.nimbleregions = {}
  },
  updateLibrato (state: AdminState, doc: firebase.firestore.DocumentSnapshot) {
    if (!doc.exists) return
    state.librato = doc.data() as LibratoConfig
  },
  updateFeatureFlags (state: AdminState, features: FeatureMap) {
    state.featureFlags = new Map()
    for (const [key, value] of Object.entries(features)) {
      const feature = key as Feature
      if (Object.values(Feature).includes(feature)) {
        state.featureFlags.set(feature, value)
      }
    }
    updateGlobalFeatureFlags(state.featureFlags)
    state.hasLoadedFeatureFlags = true
  },
  updateOlympics (state: AdminState, olympics: Olympics) {
    state.olympics = olympics
  },
  clearOlympics (state: AdminState) {
    state.olympics = null
  },
  updateLegalInfo (state: AdminState, doc: firebase.firestore.DocumentSnapshot) {
    if (!doc.exists) return
    state.legalinfo = doc.data() as LegalInfo //Improve to avoid possible data mismatch and need for typecasting
  },
  updateIOS (state: AdminState, doc: firebase.firestore.DocumentSnapshot) {
    if (!doc.exists) return
    state.iOS = new IOSSettings(doc.data())
  },
  updateRelease (state: AdminState, releaseDoc: Release) {
    state.release = releaseDoc
  },
  cleanupRelease (state: AdminState) {
    state.release = null
  },
  updateNotifications (state: AdminState, doc: firebase.firestore.DocumentSnapshot) {
    if (!doc.exists) return
    state.notifications = doc.data() as Notifications
  }
}

const adminModule = defineModule({
  namespaced: true,
  state,
  actions,
  mutations
})

export default adminModule
export const adminModuleActionContext = (context: AdminContext) => moduleActionContext(context, adminModule)
