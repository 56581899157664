import { useCurrentCast } from '@/modules/casts/compositions'
import useReplayObjects from './replayObjects'
import store from '@/store'
import { ReplayPlaylist } from '../classes'
import { PartialRequired } from '@/types'
import useReplayIngests from './replayIngests'
import { AssetType } from '@/types/assets'
import { IngestCommand, IngestStatus } from '../types'
import { SceneSourceType } from '@/modules/scenes/types'

// NOTE: ID of an ingest associated with a playlist is the same at the playlist's ID.
// Hence, playlistId = ingestId where ingest exists for a given playlist
const useReplayPlaylists = () => {

  const { currentCastId, currentCast } = useCurrentCast()
  const { replayPlaylists, getPlaylistByName } = useReplayObjects(currentCastId)
  const { recreateIngest, getCurrentClipId, getIngestStatus, setIngestCommand } = useReplayIngests()

  const addPlaylist = async  (params: PartialRequired<Omit<ReplayPlaylist, 'id'>, 'name'>): Promise<string> => {
    if (!Object.values(replayPlaylists.value).every((playlist) => playlist.name !== params.name)) {
      throw new Error(`Cannot add playlist. Duplicate name: ${params.name}`)
    }
    const clipIdList = params.clipIdList ?? []
    const chainClips = params.chainClips !== undefined ? params.chainClips : true
    const restartClips = params.restartClips !== undefined ? params.restartClips : false
    const switchSceneAtEnd = params.switchSceneAtEnd !== undefined ? params.switchSceneAtEnd : false
    return await store.dispatch.replayConfig.addReplayPlaylist({ name: params.name, clipIdList,
      chainClips, restartClips, switchSceneAtEnd })
  }

  const addClipsToPlaylist = async (params: { playlistId: string, clipIds: string[] }) => {
    const playlist = replayPlaylists.value[params.playlistId]
    if (!playlist) {
      throw new Error(`Cannot add clips to playlist. Playlist: ${params.playlistId} not found`)
    }

    const updatedClipIdList = [...playlist.clipIdList, ...params.clipIds]
    await store.dispatch.replayConfig.updateReplayPlaylist({ id: params.playlistId, clipIdList: updatedClipIdList })
  }

  // Unlikely to be used directly by component as it does not update ingest
  const addClipsToNamedPlaylist = async (params: { name: string, clipIds: string[],
    skipCreate?: boolean }): Promise<string> =>
  {
    const playlist = getPlaylistByName(params.name)
    if (playlist) {
      await addClipsToPlaylist({ playlistId: playlist.id, clipIds: params.clipIds })
      return playlist.id
    }
    else if (!params.skipCreate) return await addPlaylist({ name: params.name, clipIdList: params.clipIds })
    else return ''
  }

  // Unlikely to be used directly by component as it does not update ingest
  const removeClipsFromPlaylist = async (params: { playlistId: string, clipIds: string[] }): Promise<void> => {
    const playlist = replayPlaylists.value[params.playlistId]
    if (!playlist) throw new Error(`Cannot remove clips from playlist: ${params.playlistId}. Playlist not found`)

    const updatedClipIdList = playlist.clipIdList.filter((clipId) => !params.clipIds.includes(clipId))
    await store.dispatch.replayConfig.updateReplayPlaylist({ id: params.playlistId, clipIdList: updatedClipIdList })
  }

  // Unlikely to be used directly by component as it does not update ingest
  const removeClipsFromNamedPlaylist = async (params: { name: string, clipIds: string[] }): Promise<void> => {
    const playlist = getPlaylistByName(params.name)
    if (playlist) {
      await removeClipsFromPlaylist({ playlistId: playlist.id, clipIds: params.clipIds })
    }
  }

  // Update playlist. Use this to change name, end action, reorder/add/remove clips from list.
  // Updates corresponding ingest. Assumes clip data in replay config is upto date
  // eslint-disable-next-line complexity
  const updatePlaylist = async (params: PartialRequired<ReplayPlaylist, 'id'>): Promise<void> => {
    const playlist = replayPlaylists.value[params.id]
    if (!playlist) {
      throw new Error(`Cannot update playlist: ${params.id}. Playlist not found`)
    }
    const updateInfo: PartialRequired<ReplayPlaylist, 'id'> = { id: params.id }

    if (params.name !== undefined) {
      if (!Object.values(replayPlaylists.value).every((playlist) => playlist.name !== params.name)) {
        throw new Error(`Cannot rename playlist: ${params.id}. Duplicate name: ${params.name}`)
      }
      updateInfo.name = params.name
    }

    if (params.clipIdList !== undefined) updateInfo.clipIdList = params.clipIdList
    if (params.chainClips !== undefined) updateInfo.chainClips = params.chainClips
    if (params.restartClips !== undefined) updateInfo.restartClips = params.restartClips
    if (params.switchSceneAtEnd !== undefined) updateInfo.switchSceneAtEnd = params.switchSceneAtEnd

    await store.dispatch.replayConfig.updateReplayPlaylist(updateInfo)

    if (params.clipIdList || params.chainClips !== undefined) {
      const refClipIds = params.clipIdList ?? playlist.clipIdList
      const chainClips = params.chainClips !== undefined ? params.chainClips : playlist.chainClips
      await recreateIngest({ ingestId: params.id, refClipIds, chainClips })
    }
  }

  const getPlaylistIdsInScene = (sceneId: string): string[]|null => {
    if (!currentCast.value) return null
    const result =  currentCast.value.getStreamContentsInSceneWithPresets({ sceneId, onlyTypes: [AssetType.Broadcast],
      isActive: true, isVisible: true })
    if (!result.isSuccess) return null
    const activeStreamIds = Object.keys(result.value)
    const allReplayPlaylistIds = Object.keys(replayPlaylists.value)
    return activeStreamIds.filter((id) => allReplayPlaylistIds.includes(id))
  }

  const getPlaylistIdOfReplayScene = (sceneId: string): string|null => {
    if (!currentCast.value || currentCast.value.scenes[sceneId]?.sourceType !== SceneSourceType.Replay) return null
    return currentCast.value.getFirstActiveStreamIdBySceneId(sceneId) ?? null
  }

  // Manage replay tasks when replay scene is switched to program
  // eslint-disable-next-line complexity
  const handleSceneSwitchToProgram = async (newSceneId: string, oldSceneId: string|null) => {
    const playlistIds = getPlaylistIdsInScene(newSceneId) ?? []
    const oldScenePlaylistIds = (oldSceneId ? getPlaylistIdsInScene(oldSceneId) : null) ?? []
    for (const playlistId of playlistIds) {
      const playlist = replayPlaylists.value[playlistId]
      if (!playlist) {
        console.warn(`Playlist ${playlistId} not found in scene: ${newSceneId}. Skipping switch to program actions.`)
        continue
      }
      if (playlist.clipIdList.length === 0 || oldScenePlaylistIds.includes(playlistId)) continue
      if (playlist.restartClips || getIngestStatus(playlistId) === IngestStatus.STOPPED) {
        const currentClipId = (playlist.chainClips ? playlist.clipIdList[0] : getCurrentClipId(playlist.id))
          ?? playlist.clipIdList[0]
        console.log('handleSceneSwitchToProgram. Sent START for clip', currentClipId)
        await setIngestCommand({ ingestId: playlistId, command: IngestCommand.START, clipId: currentClipId })
      } else if (getIngestStatus(playlistId) === IngestStatus.PAUSED) {
        console.log('handleSceneSwitchToProgram. Sent clip RESUME.')
        await setIngestCommand({ ingestId: playlistId, command: IngestCommand.RESUME })
      }
    }
  }

  const getPrevClipId = (playlistId: string, clipId: string): string|null => {
    const playlist = replayPlaylists.value[playlistId]
    if (!playlist) return null
    if (playlist.clipIdList.length <= 1) return null

    const clipIndex = playlist.clipIdList.findIndex((id) => id === clipId)
    if (clipIndex < 0 || (clipIndex === 0 && !playlist.chainClips)) return null
    if (clipIndex === 0) return playlist.clipIdList[playlist.clipIdList.length-1]
    return playlist.clipIdList[clipIndex-1]
  }

  const getNextClipId = (playlistId: string, clipId: string): string|null => {
    const playlist = replayPlaylists.value[playlistId]
    if (!playlist) return null
    if (playlist.clipIdList.length <= 1) return null

    const clipIndex = playlist.clipIdList.findIndex((id) => id === clipId)
    if (clipIndex < 0 || (clipIndex === playlist.clipIdList.length - 1 && !playlist.chainClips)) return null
    if (clipIndex === playlist.clipIdList.length - 1) return playlist.clipIdList[0]
    return playlist.clipIdList[clipIndex+1]
  }

  return {
    addClipsToNamedPlaylist,
    getPlaylistByName,
    getPlaylistIdOfReplayScene,
    getPlaylistIdsInScene,
    getNextClipId,
    getPrevClipId,
    handleSceneSwitchToProgram,
    removeClipsFromPlaylist,
    removeClipsFromNamedPlaylist,
    updatePlaylist
  }
}

export default useReplayPlaylists
