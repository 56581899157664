import { WrtcServerStatBase } from './WrtcServerStat'
import { Loader } from 'kiswe-ui'
import { WRTCServerType } from '@/types/admin'

export class JanusStat extends WrtcServerStatBase {
  cpuUsage: number = 0
  memoryUsedPercentage: number = 0
  publisherCount: number = 0
  serverType: WRTCServerType.JANUS = WRTCServerType.JANUS
  subscriberCount: number = 0

  constructor (data: unknown ) {
    super(data)
    this.cpuUsage = Loader.loadNumber(data, 'cpuUsage', 0)
    this.subscriberCount = Loader.loadNumber(data, 'subscriberCount', 0)
    this.memoryUsedPercentage = Loader.loadNumber(data, 'memoryUsedPercentage', 0)
    this.publisherCount = Loader.loadNumber(data, 'publisherCount', 0)
  }
}
