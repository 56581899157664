import { Marker, MarkerType } from '@/modules/markers/classes/Marker'
import { Mixin, StudioBaseMixin } from './base'
import { KeyDict } from '@/types'
import { Loader } from 'kiswe-ui'

export class MarkerMapping {
  selectedIndex: number|null = null

  constructor (data?: unknown) {
    if (data === undefined) return
    this.selectedIndex = Loader.loadNumberOrNull(data, 'selectedIndex', this.selectedIndex)
  }
}

export function hasMarkers<TBase extends StudioBaseMixin> (Base: TBase) {
  return class hasMarkersClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      if (Array.isArray(arg[0]?.markers)) {
        arg[0].markers.forEach((data: unknown)=> {
          const marker = Marker.load(data).unwrapOrFatal()
          this.addMarker(marker)
        })
      }
      this.markerMapping = Loader.loadKeyDictOrEmpty(
        arg[0], 'markerMapping', (data: unknown) => new MarkerMapping(data), {}
      )
    }
    public markers: Marker[] = []
    public markerMapping: KeyDict<MarkerMapping>

    public addMarker (marker: Marker) {
      this.markers.push(marker)
      return this
    }

    public getLatestMarker (type: MarkerType) {
      return this.markers.filter((marker) => marker.type === type)
                         .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())[0] ?? null
    }
  }
}

export type HasMarkers = Mixin<typeof hasMarkers>
