import { ExtractSubjectType, PureAbility, SubjectRawRule } from '@casl/ability'
import { App, computed, ref } from 'vue'
import { Ability, AbilityAction, AbilitySubject } from './abilitiesByRole'
import { abilitiesPlugin } from '@casl/vue'

// function subjectName (item: string) {
//   if (!item || typeof item === 'string') {
//     return item
//   }
//   // @ts-ignore
//   return item.__type
// }

const ability = new PureAbility<[AbilityAction, AbilitySubject]>()

// type Ability = PureAbility<AbilityAction>
// const Ability = PureAbility as unknown as AbilityClass<TeamRole>
// const { build } = new AbilityBuilder<AbilityAction>(Ability)
// const ability = build()

// const ability = AbilityBuilder.define({ subjectName }, () => {
//   // can(['read', 'create'], 'Todo')
//   // can(['update', 'delete'], 'Todo', { assignee: 'me' })
// })

const updateCount = ref(1)

const can = computed(() => {
  if (updateCount.value === 0) {
    return (_action: AbilityAction, _subject: AbilitySubject) => false
  }
  return (action: AbilityAction, subject: AbilitySubject) => ability.can(action, subject)
})


const updateAbilities = (rules: Ability[]) => {
  const newRules: SubjectRawRule<AbilityAction, ExtractSubjectType<AbilitySubject>, unknown>[] = []
  for (const rule of rules) {
    for (const action of rule.actions) {
      newRules.push({ subject: rule.subject, action })
    }
  }
  ability.update(newRules)
  updateCount.value++
}

const setupVueAbilities = (app: App) => {
  app.use(abilitiesPlugin, ability)
  app.config.globalProperties.$can = can.value
}

export { can, updateAbilities, setupVueAbilities }
