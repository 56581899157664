import { Loader } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { Mixin, StudioBaseMixin } from './base'

// eslint-disable-next-line max-lines-per-function
export function hasOnlineSessions<TBase extends StudioBaseMixin> (Base: TBase) {
  return class OnlineSessionsClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.online_sessions = Loader.loadAndCast<KeyDict<string[]>>(arg[0], 'online_sessions', {})
    }
    online_sessions: KeyDict<string[]>
  }
}

export type HasOnlineSessions = Mixin<typeof hasOnlineSessions>
