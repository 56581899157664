import { CastChangeHandler } from './castChangeHandler'
import { CastChangeHandlerCustomStreams } from './castchangehandlers/castChangeHandlerCustomStreams'
import { CastChangeHandlerOutputStreams } from './castchangehandlers/castChangeHandlerOutputStreams'
import { CastChangeHandlerPresence } from './castchangehandlers/castChangeHandlerPresence'
import { CastChangeHandlerSourceStreams } from './castchangehandlers/castChangeHandlerSourceStreams'

export class CastChangeHandlerFactory {
  static getChangeHandlers (currentCastId: string|null): CastChangeHandler[] {
    return [
      new CastChangeHandlerOutputStreams(currentCastId),
      new CastChangeHandlerCustomStreams(currentCastId),
      new CastChangeHandlerSourceStreams(currentCastId),
      new CastChangeHandlerPresence(currentCastId)
    ]
  }
}
