import CastFilter, { CastFilterPageDirection } from '@/classes/CastFilter'
import fb from '@/firebase'
import firebase from 'firebase/compat/app'
import { Order } from '@/modules/database/utils/database'
import { CastsState } from '@/store/types'
import { CollectionNames } from '@/modules/database/utils/collectionNames'
import { KeyDict } from '@/types'

export const createQuery = async (filter: CastFilter): Promise<firebase.firestore.Query<firebase.firestore.DocumentData>> => {
  if (filter.requestedCastIds?.length > 0) {
    return fb.db.collection(CollectionNames.CASTS).where(firebase.firestore.FieldPath.documentId(), 'in', filter.requestedCastIds)
  }

  const filterConditions: KeyDict<any[]> = {
    status: ['status', 'in', filter.status],
    castType: ['castType', 'in', filter.castType],
    teamId: ['team_id', '==', filter.teamId],
    startDate: ['start_date', '>=', filter.startDate?.toDate() ?? null],
    endDate: ['end_date', '<=', filter.endDate?.toDate() ?? null],
  }
  if (filter.activeOnly) filterConditions.activeOnly = ['activeState.idle', '==', false]

  let query = fb.db.collection(CollectionNames.CASTS).where('deleted', '==', false)
  for (const [key, condition] of Object.entries(filterConditions)) {
    // @ts-ignore
    // FIXME: need to find a better way to do this
    if (filter[key] === null) continue
    // @ts-ignore // const fieldPath = new firestore.FieldPath(condition)
    query = query.where(...condition)
  }

  let orderDirection: firebase.firestore.OrderByDirection = 'asc'
  if (filter.startDateOrder === Order.DESCENDING) orderDirection = 'desc'
  query = query.orderBy('start_date', orderDirection)

  if (filter.entriesPerPage !== null && filter.entriesPerPage > 0) {
    if (filter.pageDirection === CastFilterPageDirection.Forward) {
      if (filter.lastDoc !== null) {
        query = query.startAfter(filter.lastDoc)
      }
      query = query.limit(filter.entriesPerPage)
    } else if (filter.pageDirection === CastFilterPageDirection.Backward) {
      if (filter.firstDoc !== null) {
        query = query.endBefore(filter.firstDoc)
      }
      query = query.limitToLast(filter.entriesPerPage)
    }
  }
  return query
}

export const deleteUnwantedCasts = (state: CastsState) => {
  const wantedCastIds = new Set(Object.values(state.filters)
                                      .map(filter => filter.castIds)
                                      .reduce((acc, castIds) => acc.concat(castIds), []))
  const hasCastIds = new Set(Object.keys(state.filteredCasts))
  hasCastIds.forEach(id => {
    if (!wantedCastIds.has(id)) {
      delete state.filteredCasts[id]
    }
  })
}
