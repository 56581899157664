import { Loader } from 'kiswe-ui'
import { AspectRatio } from 'kiswe-ui'
import { UNIT_PX } from './BoxStyling'

export type BoxStylingBaseCss = Pick<CSSStyleDeclaration, 'top'|'left'|'width'|'height'|'alignContent'|'aspectRatio'>

export class BoxStylingBase {
  // TODO: use ContentAlignment.Center, move classes out of layouts.ts
  alignContent: string = 'center'
  aspectRatio: boolean = false
  height: number = 1080
  left: number = 0
  top: number = 0
  width: number = 1920
  // TODO: add zIndex

  constructor (data?: unknown) {
    if (!data) return
    this.alignContent = Loader.loadString(data, 'alignContent', this.alignContent)
    this.aspectRatio = Loader.loadBoolean(data, 'aspectRatio', this.aspectRatio)
    this.height = Loader.loadNumber(data, 'height', this.height)
    this.left = Loader.loadNumber(data, 'left', this.left)
    this.top = Loader.loadNumber(data, 'top', this.top)
    this.width = Loader.loadNumber(data, 'width', this.width)
  }

  get css (): BoxStylingBaseCss {
    const aspectRatio = this.aspectRatio ? AspectRatio.Aspect16x9 : AspectRatio.Auto

    return {
      alignContent: this.alignContent,
      aspectRatio,
      height: this.height + UNIT_PX,
      left: this.left + UNIT_PX,
      top: this.top + UNIT_PX,
      width: this.width + UNIT_PX
    }
  }
}
