/* eslint-disable max-lines */
import { ActionContext } from 'vuex'
import { CAST_REPLAYS_SUB_KEY, CastReplaysState, IngestCommand } from '../types'
import { RootState } from '@/store/types'
import { defineModule } from 'direct-vuex'
import store, { moduleActionContext } from '@/store'
import studio, { CollectionNames } from '@/modules/database/utils'
import { DatabaseAction, MergeOption } from '@/modules/database/utils/database'
import { DeepPartial } from '@/types'
import { errorIfFalsy } from '@/modules/common/utils'
import { CastReplaysDoc, IngestClipDoc, ReplayControlsDoc } from '../classes'

// Utility functions


// Store module components
type CastReplaysContext = ActionContext<CastReplaysState, RootState>

const state = {
  castId: '',
  ingests: {}
} as CastReplaysState

const getters = {
  ingestCommandTimestamp: (state: CastReplaysState) => (ingestId: string): number => {
    const lastTimestamp = state.ingests[ingestId]?.controls?.command_time ?? 0
    return lastTimestamp > Date.now() ? lastTimestamp + 1 : Date.now()
  }
}

const actions = {
  async subscribeCastReplays (context: CastReplaysContext, query: { castId: string }) {
    const { commit } = castReplaysModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: `${CAST_REPLAYS_SUB_KEY}_${query.castId}`,
      query: studio.db.collection(CollectionNames.CAST_REPLAYS).doc(query.castId),
      callback: commit.updateCastReplays
    })
    result.logIfError('subscribeCastReplays')
  },
  async unsubscribeCastReplays (_context: CastReplaysContext, query: { castId: string }) {
    const key = `${CAST_REPLAYS_SUB_KEY}_${query.castId}`
    studio.subscriptions.unsubscribe(key, () => { void store.dispatch.castReplays.cleanup() })
  },
  async createCastReplays (context: CastReplaysContext, params: { castId: string, teamId: string }) {
    if (!params.castId) throw new Error('Cannot create CastReplays. Missing Cast ID')
    if (!params.teamId) throw new Error('Cannot create CastReplays. Missing Team ID')

    const { dispatch } = castReplaysModuleActionContext(context)

    const castReplaysDoc = CastReplaysDoc.newDocForCast(params.castId, params.teamId)
    const result = await dispatch.updateCastReplaysDoc(castReplaysDoc)
    result.logIfError('createCastReplays')
  },
  cleanup (context: CastReplaysContext) {
    const { commit } = castReplaysModuleActionContext(context)
    commit.cleanup()
  },
  async updateCastReplaysDoc (_context: CastReplaysContext, replaysDoc: DeepPartial<CastReplaysDoc>) {
    if (!replaysDoc.cast_id) throw new Error('Cannot update replay config. Cast ID not set')

    return await studio.db.collection(CollectionNames.CAST_REPLAYS).doc(replaysDoc.cast_id)
      .set(replaysDoc, MergeOption.MERGE)
  },
  async addReplayIngest (context: CastReplaysContext, params: { ingestId: string, clipList: IngestClipDoc[],
    command?: IngestCommand, commandClipId?: string }) {

    const { state, dispatch, getters } = castReplaysModuleActionContext(context)
    // TODO: Replace command_time with fb.serverTimestamp()
    const controlsElem: ReplayControlsDoc = {
      command: params.command ?? IngestCommand.PAUSE,
      command_time: getters.ingestCommandTimestamp(params.ingestId),
      replay_id: params.commandClipId ?? ''
    }
    if (controlsElem.command === IngestCommand.START && !controlsElem.replay_id)
      throw new Error('Failed to (re)start ingest. Clip ID not found')

    const playlistElem: IngestClipDoc[] = [ ...errorIfFalsy(params.clipList, 'clipList', 'addReplayIngest') ]
    const castReplaysDoc: DeepPartial<CastReplaysDoc> = {
      cast_id: state.castId,
      ingests: {
        [params.ingestId]: {
          controls: controlsElem,
          playlist: playlistElem,
          status: {}
        }
      }
    }
    const result = await dispatch.updateCastReplaysDoc(castReplaysDoc)
    result.logIfError('addReplayIngest')
  },
  async setIngestCommand (context: CastReplaysContext, params: { ingestId: string,
    command: IngestCommand, commandClipId?: string }) {

    const { state, dispatch, getters } = castReplaysModuleActionContext(context)
    const ingestToUpdate = state.ingests[params.ingestId]
    if (!ingestToUpdate) throw new Error(`Command: ${params.command} failed. Ingest ${params.ingestId} not found`)
    if (params.command === IngestCommand.START && !params.commandClipId)
      throw new Error('Failed to (re)start ingest. Clip ID not found')

    // TODO: Replace command_time with fb.serverTimestamp()
    const castReplaysDoc: DeepPartial<CastReplaysDoc> = {
      cast_id: state.castId,
      ingests: {
        [params.ingestId]: {
          controls: {
            command: params.command,
            command_time: getters.ingestCommandTimestamp(params.ingestId),
            replay_id: params.commandClipId ?? ''
          }
        }
      }
    }
    const result = await dispatch.updateCastReplaysDoc(castReplaysDoc)
    result.logIfError('setIngestCommand')
  },
  async updateIngestPlaylist (context: CastReplaysContext, params: { ingestId: string,
    clipList: IngestClipDoc[] }) {

    const { state, dispatch } = castReplaysModuleActionContext(context)
    const ingestToUpdate = state.ingests[params.ingestId]
    if (!ingestToUpdate) throw new Error(`Cannot update ingest playlist. Ingest ${params.ingestId} not found`)

    const castReplaysDoc: DeepPartial<CastReplaysDoc> = {
      cast_id: state.castId,
      ingests: {
        [params.ingestId]: {
          playlist: params.clipList ?? []
        }
      }
    }
    const result = await dispatch.updateCastReplaysDoc(castReplaysDoc)
    result.logIfError('updateIngestPlaylist')
  },
  async deleteReplayIngest (context: CastReplaysContext, ingestId: string) {
    const { state } = castReplaysModuleActionContext(context)
    const ingestToDelete = state.ingests[ingestId]
    if (!ingestToDelete) {
      console.warn(`Attempt to delete non-existing replay clip ${ingestId}. Ignoring.`)
      return
    }
    if (!state.castId) throw new Error(`Cannot delete replay ingest ${ingestId}. No active cast`)

    // Delete ingest
    const castReplaysDoc = {
      ingests: {
        [ingestId]: DatabaseAction.deleteField()
      }
    }
    const result = await studio.db.collection(CollectionNames.CAST_REPLAYS).doc(state.castId)
      .set(castReplaysDoc, MergeOption.MERGE)
    result.logIfError('deleteReplayIngest')
  }
}

const mutations = {
  updateCastReplays (state: CastReplaysState, snapData: CastReplaysDoc) {
    if (snapData) {
      state.castId = snapData.cast_id
      state.ingests = snapData.ingests
    } else {
      console.warn('Received empty CastReplaysDoc. Ignoring')
    }
  },
  cleanup (state: CastReplaysState) {
    state.castId = ''
    state.ingests = ''
  }
}

const castReplaysModule = defineModule({
  namespaced: true,
  state,
  getters,
  mutations,
  actions
})

export default castReplaysModule
export const castReplaysModuleActionContext = (context: CastReplaysContext) =>
  moduleActionContext(context, castReplaysModule)
