import { StringTranslation } from "../types"
const translation: StringTranslation = {
  "cast_letsgetstarted_text": "시작합니다.",
  "cast_getstarted_button": "시작합니다.",
  "cast_loadingcast_text": "이벤트 로딩 중",
  "cast_pleasefillinname_question": "세션 참여 전 본인에 대한 자세한 정보를 적어 주세요.",
  "cast_consentterms_text": "저는 Athlete Moment<a href=\"{tosUrl}\" target=\"_blank\">약관 및 개인 정보 보호 정책</a>을 읽고 이를 수락하여 본인의 이미지, 초상권, 목소리 및 기여전체 또는 그 일부가 올림픽 경기장뿐만 아니라 올림픽 대회 및 그 중계방송에서 OBS, IOC 및 재실시권자 및 양수인 (예: 공인 방송사) 에 의해 배포되고 사용될 수 있도록 동의합니다.",
  "cast_consentterms_error": "약관에 동의해야 합니다.",
  "cast_consentprivacy_text": "본인은 참여 시 개인정보 수집 및 이용이 필수적이며 이러한 처리가 <a href=\"https://www.obs.tv/prx/asset.php?tgt=DelegatesSKAMOonboardingENSK-208d50ae628f.pdf&gen=1\" target=\"_blank\">외국 기관</a> 에 위임될 수 있음을 설명하는 <a href=\"chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://bpobstvdocumentscdne01.azureedge.net/bpobstvdocumentscnt01protected/public%2FP2024-ATHLETE-MOMENT-TERMS-ENG-KO-040624.pdf?sv=2020-10-02&st=2024-06-07T05%3A21%3A17Z&se=2024-06-07T06%3A21%3A17Z&sr=c&sp=r&sig=A6mZ6y0QpmN1%2FQDuxzS4wRiXylygdLaD00bv9S%2Bz2kg%3D&rscd=inline%3B%20filename%3D%22P2024-ATHLETE-MOMENT-TERMS-ENG-KO-040624.pdf%22\" target=\"_blank\">개인정보 보호정책</a>을 읽고 동의했습니다.",
  "cast_consentprivacy_error": "You must agree to the Privacy Policy.",
  "cast_consentage_text": "본인은 <span>{age}</span>살이고 해당 법률에 따라 법적 연령입니다.",
  "cast_consentage_error": "최소 {age}세 이상이어야 합니다.",
  "cast_is_scheduled_text": "이 프로덕션은 라이브가 아니며 동영상 미리보기가 비활성화되어 있습니다.",
  "cast_enter_text": "참여를 위해 입장버튼을 클릭하세요.",
  "cast_enter_button": "입장",
  "cast_tryingtoconnect_text": "연결 중입니다.",
  "cast_waitingforcast_title": "전설적인 순간 만들기",
  "cast_waitingforcast_text": "진행자와의 연결을 기다리는 중입니다. 시간이 좀 걸릴 수 있습니다. 페이지를 새로고침 하지마세요.",
  "cast_waitingforcast_guide1_title": "적절한 위치를 선정하세요",
  "cast_waitingforcast_guide2_title": "당신의 열정을 표현하세요",
  "cast_waitingforcast_guide3_title": "보여지는 모습과 행동에 주의하여 주세요",
  "cast_waitingforcast_guide1_text": "화면 중앙에 앉아 바른 자세를 유지하면서 올바른 자세를 취해야 합니다. 전 세계가 지켜보고 있다는 사실을 기억하세요!",
  "cast_waitingforcast_guide2_text": "큰 소리로 여러분의 열정과 응원를 전달하세요.",
  "cast_waitingforcast_guide3_text": "상품 홍보, 정치적 발언, 모욕적인 표현등을 삼가해주세요.",
  "cast_waitingforcastautoaccept_text": "진행자를 만날 준비가 되었습니다.",
  "cast_switch_team_text": "This cast requires you to switch to a different team",
  "cast_switch_team_button": "팀 변경하기",
  "complete_join_text": "Athlete Moment 등록",
  "general_pleasewait_text": "기다려 주세요",
  "general_yourname_label": "성명",
  "general_firstname_label": "이름",
  "general_lastname_label": "성",
  "general_email_label": "이메일",
  "general_country_label": "국가",
  "general_firstname_error": "이름을 기입하세요",
  "general_lastname_error": "성을 기입하세요",
  "general_requiredallfields_error": "모든 항목은 필수 입력 사항입니다.",
  "general_pleasefillinyourname_placeholder": "전체 이름을 기입하세요.",
  "general_pleasefillinfirstname_placeholder": "이름을 기입하세요.",
  "general_pleasefillinlastname_placeholder": "성을 기입하세요.",
  "general_pleasefillinemail_placeholder": "이메일 주소를 기입하세요.",
  "general_join_button": "가입",
  "general_send_button": "전송",
  "general_yes_button": "네",
  "general_no_button": "아니요",
  "general_ok_button": "네",
  "general_close_button": "닫기",
  "general_done_button": "완료",
  "general_register_button": "등록",
  "general_restart_button": "재시작",
  "general_continue_button": "계속",
  "chat_nomessages_placeholder": "해당 캐스트에 대해서는 아직 메시지가 없습니다.",
  "chat_enteryourmessage_placeholder": "당신의 메시지를 이 곳에 입력하세요.",
  "chat_chat_label": "채팅하기",
  "chat_unreadmessage_text": "{count} 새로운 메시지",
  "chat_unreadmessages_text": "{count} 새로운 메시지들",
  "redirect_welcometokiswe_text": "환영합니다.",
  "redirect_pleasewaittoredirect_text": "재연결중입니다...기다려 주세요.",
  "redirect_end_cast_text": "경기 시작 1시간 전부터 진행자와의 접속이 가능 합니다. 가능한 시간에 재접속 바랍니다. ",
  "redirect_cast_reach_maximum_text": "현재 최대 참여 인원이 초과하여 참여하실 수 없습니다. 참여 가능하실때까지 대기 하여 주시기 바랍니다.",
  "redirect_disabled_existing_caster_text": "문제가 발생했습니다. 이 브라우저 탭을 닫거나 브라우저를 완전히 닫고 원래 링크를 다시 클릭하세요.",
  "redirect_invalidlink_title": "유효하지 않은 링크입니다.",
  "redirect_invalidlink_text": "사용한 링크가 더 이상 유효하지 않습니다.",
  "redirect_castnotactive_title": "이 링크에 연결된 활성 방이 없습니다.",
  "redirect_castnotactive_paragraph": "잠시 후 재시도 하시거나 다른 링크를 사용하여 보세요.",
  "actiontab_assets_label": "Assets",
  "actiontab_casters_label": "Casters",
  "actiontab_inputs_label": "Inputs",
  "actiontab_outputs_label": "Output",
  "actiontab_audio_label": "Audio Mixer",
  "actiontab_settings_label": "Settings",
  "actiontab_clips_label": "Clips",
  "actiontab_commentators_label": "Tracks",
  "actiontab_crew_label": "Crew",
  "actiontab_talkback_label": "Talkback",
  "actiontab_chat_label": "TextCast",
  "actiontab_simplecg_label": "Simple CG",
  "actiontab_sctemarkers_label": "SCTE Markers",
  "actiontab_interactive_label": "인터랙티브",
  "actiontab_switcher_label": "Scenes",
  "actiontab_layout_label": "Layers",
  "actiontab_preset_label": "Preset",
  "topbar_untileventstarts_text": "이벤트가 시작될 때까지",
  "topbar_untileventends_text": "이벤트가 끝날 때까지",
  "usermedia_startcasting_button": "캐스팅 시작",
  "usermedia_connect_button": "연결",
  "usermedia_detectdevices_button": "기기 검색",
  "usermedia_detecting_text": "검색중",
  "usermedia_clickbutton_tooltip": "캐스팅을 시작하려면 캐스트 버튼을 클릭하세요.",
  "usermedia_echocancellation_text": "에코 제거를 활성화합니다.",
  "usermedia_camera_label": "카메라",
  "usermedia_microphone_label": "마이크",
  "usermedia_learnaccess_button": "디바이스 접근 권한 허용 방법",
  "usermedia_pressallow_text": "팝업에서 '허용'을 누르세요.",
  "castgrawl_mutedbycrew_label": "The crew muted you",
  "castgrawl_mutedyourself_label": "You are muted",
  "castgrawl_streamhasissues_label": "There are connection issues. Refresh the page to solve it",
  "audio_copyfromprogram_button": "프로그램 믹스 복사",
  "audio_applytoprogram_button": "프로그램 믹스에 적용",
  "audio_programmix_label": "프로그램 믹스",
  "audio_monitor_label": "모니터 믹스",
  "talkback_nomembers_text": "토크백에 연결된 크루맴버가 없습니다.",
  "talkback_setincomingmaster_button": "마스터 입력신호 설정",
  "talkback_setincomingindividual_button": "개별 입력신호 설정",
  "error_offlinenotice_text": "현재 키와의 연결이 끊어졌습니다. 다시 연결을 시도하고 있습니다. 이 상황이 몇 초 더 지속될 경우, 인터넷 연결상태를 확인 후 페이지를 새로고침 해주세요.",
  "cast_waitforqueue_text": "접속자가 많습니다. 기다려 주세요…",
  "cast_yourpositioninqueue_text": "대기열 내 위치 : <strong class=\"primary\">{currentPositionInQueue}</strong> / {totalInQueue}",
  "cast_needpermissions_text": "디바이스 접근을 허용해주세요",
  "cast_deviceerror_text": "{device}에 문제가 발견되었습니다. 브라우저를 닫고 {device} 연결을 확인한 후 게스트 링크를 다시 클릭하세요.",
  "cast_deniedpermission_text": "접근 요청이 거부되어, 브라우저 설정을 통해 접근을 허용하기 전까지는 입장할 수 없습니다. 브라우저를 닫고 제공된 링크로 다시 이동하세요.",
  "cast_deniedguestlink_text": "이미 Kiswe에 로그인되어 있습니다. 이 링크를 사용하려면 먼저 로그아웃하세요.",
  "cast_deniednotinvited_text": "해당 이벤트에 대한 참여 권한이 없습니다.",
  "cast_everythingsetup_text": "감사합니다, <span class=\"primary\">{display_name}</span>, 방에 입장할 준비가 됐습니다!",
  "cast_moderatorpickyouup_text": "진행자가 곧 당신을 입장시킬 것입니다.",
  "cast_chattingwithmoderator_text": "입장을 환영합니다",
  "cast_moderatorrejected_text": "죄송합니다. 진행자가 당신의 입장을 허용하지 않기로 결정했습니다.",
  "endsession_thanks_text": "참여해주셔서 감사합니다. 이 창은 {remainingSecs} 초 후에 닫힙니다.",
  "endsession_thankforcoming_text": "참여해주셔서 감사합니다.",
  "endsession_thankforcomingtocast_text": "오늘{castName}에 참여해주셔서 감사합니다.",
  "endsession_closetab_text": "지금 해당 탭을 닫거나 기존 계정을 사용해 로그인 할 수 있습니다.",
  "endsession_backtologin_button": "로그인으로 돌아가기",
  "tally_roomactive_text": "라이브가 준비됐습니다.",
  "tally_roomlive_text": "라이브!",
  "leavecast_popup_title": "Leave production",
  "leavecast_popup_description": "Are you sure you want to leave the production? The production will not stop, you just leave it.",
  "leavecast_popupanonymous_title": "Leave production",
  "leavecast_popupanonymous_description": "Are you sure you want to leave the production? The production will not stop, you just leave it. You won't be able to come back.",
  "browsercheck_browser_label": "브라우져",
  "browsercheck_version_label": "버젼",
  "browsercheck_supportbrowser_text": "Athlete Moment는 크롬(Chrome), 엣지(Edge), 파이어폭스(Firefox) 또는 사파리(Safari)브라우저에서만 접근가능합니다.",
  "browsercheck_supportmobilebrowser_text": "Athlete Moment는 크롬(Chrome) 또는 사파리(Safari) 브라우저에서만 접근가능합니다.",
  "browsercheck_download_chrome": "Chrome 다운로드",
  "browsercheck_download_edge": "Edge 다운로드",
  "browsercheck_download_safari": "Safari 다운로드",
  "browsercheck_download_firefox": "Firefox 다운로드",
  "browsercheck_appstore_paragraph": "키스위 스튜디오(Kiswe Studio)는 애플 스토어에서 구입할 수 있습니다.",
  "browsercheck_systemnotsupported_paragraph": "This device does not meet the minimal requirements for using Kiswe Studio.",
  "statusstrip_leaveevent_tooltip": "Leave production",
  "statusstrip_selectdevices_tooltip": "기기 선택",
  "screenmode_pleaserotate_title": "기기를 회전 시키세요",
  "screenmode_pleaserotateios_text": "Athlete Moment는 기기의 가로모드에서 최적화된 경험을 하실 수 있습니다. 현재 세로모드를 사용하고 계십니다. 기기를 회전시켜주세요.",
  "screenmode_pleaserotateandroid_text": "Athlete Moment는 기기의 가로모드에서 최적화된 경험을 하실 수 있습니다. 현재 세로모드를 사용하고 계십니다. 기기를 회전시키거나 아래의 버튼을 사용해주세요.",
  "screenmode_pleaserotate_actionbutton": "기기를 회전 시키세요",
  "athletemoments_welcome_title": "Athlete Moment에 방문 하신 것을 환영 합니다!",
  "athletemoments_redirect_paragraph": "곧 연결 됩니다.",
  "castwelcome_wowzaissues_error": "현재 접속자가 초과 되었습니다. 잠시 후에 다시 시도 하여 주시기 바랍니다.",
  "privatecall_nolocalstream_error": "스트림을 설정 하기 위해서는 당신의 승인이 필요합니다. 단 이미 권한을 부여했다면 웹캠에 문제가 있을 수 있습니다.",
  "privatecall_sendwarning_notification": "운영자가 당신의 비디오와 오디오를 확인할 수 없습니다. 마이크와 카메라가 제대로 작동하는지 확인한 다음 다시 시도하세요.",
  "privatecall_sendwarning_label": "다시 시도 하세요",
  "privatecall_sendwarning_footer": "관리자의 소리가 (명확하게) 들리지 않으시나요? 여기를 클릭하세요.",
  "browsercheck_ioscontinueweb_paragraph": "환영합니다! 여러분이 보유하신 iOS 디바이스가 3년 이상( 2018년 이전의 기기) 되었다면, 애플 스토어에서 '선수의 순간(Kiswe Studio)' 앱을 설치해주세요. 그렇지 않으면 최고의 경험을 위해 아래에서 '웹에서 계속'을 선택하세요.",
  "browsercheck_ioscontinueweb_button": "웹에서 계속",
  "statusstrip_selectdevicesdisabled_tooltip": "현재 디바이스 변경은 허용되지 않습니다.",
  "cast_moderatorrequestedstreamrestart_text": "관리자가 스트리밍 재시작을 요청했습니다. 잠시만 기다려주세요.",
  "selftest_checkcpuandmemory_message": "현재 기기를 확인하는 중입니다.",
  "selftest_setup_text": "설정을 선택해주세요",
  "selftest_running_text": "미디어 연결 테스트",
  "selftest_fail_text": "설정이 작동하지 않습니다",
  "selftest_restart_text": "인터넷 연결상태 확인 또는 다른 디바이스를 선택해주세요",
  "selftest_casting_text": "연결 중입니다. 잠시 기다려 주세요.",
  "selftest_noreply_text": "연결할 수 없습니다. 인터넷 연결을 확인하세요.",
  "selftest_pleasecloseapps_question": "다른 모든 앱/탭을 닫으세요.",
  "selftest_selectothercamera_question": "다른 카메라를 선택 하세요",
  "selftest_checkyourvolume_question": "Please check if your volume is on",
  "selftest_seeotherguests_question": "Can you see the other 4 streams?",
  "selftest_checkyourheadset_question": "If you are using a headset? Can you hear yourself now?",
  "selftest_hearotherguests_question": "Can you hear this stream?",
  "selftest_performance_question": "selftest_performance_question",
  "selftest_canyouhearlocal_question": "본인의 목소리가 들리시나요?",
  "selftest_canyouhearremote_question": "소리가 들리시나요?",
  "selftest_canyouseelocal_question": "본인의 모습이 보이시나요?",
  "selftest_canyouseeremote_question": "영상이 보이시나요?",
  "selftest_canyouseeyourselflocal_body": "selftest_canyouseeyourselflocal_body",
  "selftest_canyouseeyourselfremote_body": "selftest_canyouseeyourselfremote_body",
  "selftest_onsuccess_button": "selftest_onsuccess_button",
  "selftest_onfail_button": "selftest_onfail_button",
  "selftest_successresult_message": "selftest_successresult_message",
  "selftest_successresult_body": "selftest_successresult_body",
  "selftest_failresult_message": "selftest_failresult_message",
  "selftest_failresult_body": "selftest_failresult_body",
  "selfteststats_canseeselflocal_term": "selfteststats_canseeselflocal_term",
  "selfteststats_canseeselfremote_term": "selfteststats_canseeselfremote_term",
  "selfteststats_canhearselfremote_term": "selfteststats_canhearselfremote_term",
  "selfteststats_canseeothers_term": "selfteststats_canseeothers_term",
  "selfteststats_canhearothers_term": "selfteststats_canhearothers_term",

  "casttypeselector_switcher_name": "",
  "casttypeselector_switcher_description": "",
  "casttypeselector_switcher_short_description": "",
  "casttypeselector_fanroom_name": "",
  "casttypeselector_fanroom_description": "",
  "casttypeselector_fanroom_short_description": "",
  "casttypeselector_stationswitcher_name": "Station",
  "casttypeselector_stationswitcher_description": "Broadcast your Rooms",
  "casttypeselector_stationswitcher_short_description": "Station",
  "srt_type_passthrough_label": "Passthrough",
  "srt_type_multitrack_label": "Multi-Track"
}

export default translation
