import env from '@/env'
import {
  AnalyticsResponse,
  AthleteMomentData,
  FanRoomData,
  GuestRegistrationData,
  PayloadAnalyticsEvent
} from '../types'
import ThorApiCall from './ThorApiCall'
import { Result } from 'kiswe-ui'
import { SelfTestState } from '@/modules/selftest/types'
import { CallTriage } from '@/types/casts'
import { useAddLog } from '@/modules/logging/compositions'

const { addLogNoWait } = useAddLog()

const API_PATH_EVENT = '/thor/event/'
const DEF_DEVICE_ID = 'studio'
// Add additional event types as needed
const EVENT_TYPE_DEFAULT = 'studio_default'
const EVENT_TYPE_PAGE_LOAD = 'page_load'
const EVENT_TYPE_AM_CREATED = 'am_created'
const EVENT_TYPE_GUEST_REGISTERED = 'am_guest_registered'
const EVENT_TYPE_SELFTEST_FAILED = 'am_st_failed'
const EVENT_TYPE_SELFTEST_SUCCESS = 'am_st_success'
const EVENT_TYPE_SELFTEST_GENERIC = 'am_st_generic'
const EVENT_TYPE_MODERATION_ACCEPTED = 'am_mod_accepted'
const EVENT_TYPE_MODERATION_REJECTED = 'am_mod_rejected'
const EVENT_TYPE_MODERATION_GENERIC = 'am_mod_generic'

// Add addition pages on which events are to be logged
const PAGE_GUEST_REGISTRATION = 'guest_registration'
const PAGE_STATION_SWITCHER = 'station_switcher'
const PAGE_GUEST_SELFTEST = 'guest_selftest'
const PAGE_GUEST_MODERATION = 'guest_moderation'

export default class Analytics extends ThorApiCall {

  private getDefaultPayload (): PayloadAnalyticsEvent {
    return {
      event_time: (new Date()).toISOString(),
      event_type: EVENT_TYPE_DEFAULT,
      event_category: null,
      event_data: null,
      event_duration: 0,
      qualifier: null,
      user_info: {
        user_id: env.thorApi.analytics.userId,
        username: env.thorApi.analytics.userName
      },
      device_id: DEF_DEVICE_ID,
      sw_type: null
    }
  }

  async logAnalyticsEvent (payload: any): Promise<Result<AnalyticsResponse, 'axios_error'|'http_error'>> {
    const result = await this.sendRequest('POST', API_PATH_EVENT, payload, true)
    if (result.isFailure) {
      result.logIfError('Error logging analytics event')
      return result.convert()
    }
    const apiResponse = result.value
    return Result.success({
      currentServerTime: new Date(apiResponse.data.current_server_time),
      clientCountryCode: apiResponse.data.country
    })
  }

  async guestRegistrationPageLoad (castId: string, isTest: boolean):
   Promise<Result<AnalyticsResponse, 'axios_error'|'http_error'>>
  {
    const payload = this.getDefaultPayload()
    payload.event_type = EVENT_TYPE_PAGE_LOAD
    payload.qualifier = PAGE_GUEST_REGISTRATION
    payload.event_category = castId
    payload.sw_type = isTest ? 'test' : 'prod'
    return this.logAnalyticsEvent(payload)
  }

  athleteMomentCreated (castId: string, athleteMomentData: Partial<AthleteMomentData>) {
    const payload = this.getDefaultPayload()
    payload.event_type = EVENT_TYPE_AM_CREATED
    payload.qualifier = PAGE_STATION_SWITCHER
    payload.event_category = castId
    payload.event_duration = athleteMomentData.duration ?? 0
    payload.event_data = athleteMomentData
    payload.sw_type = athleteMomentData.isTest ? 'test' : 'prod'
    this.logAnalyticsEvent(payload).then((result) => {
      if (result.isFailure) {
        addLogNoWait('Failed to send analytics athlete moment created', 'error', { 'error': result.message })
      }
    }).catch((error) => {
      addLogNoWait('Exception when sending a log analytics event to thor', 'error', { 'error': error, 'data': payload })
    })
  }

  anonymousGuestRegistered (castId: string, anonymousGuestRegistrationData: Partial<GuestRegistrationData>) {
    const payload = this.getDefaultPayload()
    payload.event_type = EVENT_TYPE_GUEST_REGISTERED
    payload.qualifier = PAGE_GUEST_REGISTRATION
    payload.event_category = castId
    payload.event_data = anonymousGuestRegistrationData
    payload.sw_type = anonymousGuestRegistrationData.isTest ? 'test' : 'prod'
    this.logAnalyticsEvent(payload).then((result) => {
      if (result.isFailure) {
        addLogNoWait('Failed to send analytics guest registered', 'error', { 'error': result.message })
      }
    }).catch((error) => {
      addLogNoWait('Exception when sending a log analytics event to thor', 'error', { 'error': error, 'data': payload })
    })
  }

  selfTestResult (castId: string, selfTestState: SelfTestState,
     fanRoomData: Partial<FanRoomData>, duration: number) {
    const payload = this.getDefaultPayload()
    if (selfTestState === SelfTestState.FAIL) payload.event_type = EVENT_TYPE_SELFTEST_FAILED
    if (selfTestState === SelfTestState.SUCCESS) payload.event_type = EVENT_TYPE_SELFTEST_SUCCESS
    if (![SelfTestState.FAIL, SelfTestState.SUCCESS].includes(selfTestState))
      payload.event_type = EVENT_TYPE_SELFTEST_GENERIC
    payload.qualifier = PAGE_GUEST_SELFTEST
    payload.event_category = castId
    payload.event_data = fanRoomData
    payload.event_duration = duration
    payload.sw_type = fanRoomData.isTest ? 'test' : 'prod'
    this.logAnalyticsEvent(payload).then((result) => {
      if (result.isFailure) {
        addLogNoWait('Failed to send analytics self test result', 'error', { 'error': result.message })
      }
    }).catch((error) => {
      addLogNoWait('Exception when sending a log analytics event to thor', 'error', { 'error': error, 'data': payload })
    })
  }

  moderationResult (castId: string, callTriageState: CallTriage, fanRoomData: Partial<FanRoomData>, duration: number) {
    const payload = this.getDefaultPayload()
    if (callTriageState === CallTriage.Accepted) payload.event_type = EVENT_TYPE_MODERATION_ACCEPTED
    if (callTriageState === CallTriage.Rejected) payload.event_type = EVENT_TYPE_MODERATION_REJECTED
    if (![CallTriage.Accepted, CallTriage.Rejected].includes(callTriageState))
      payload.event_type = EVENT_TYPE_MODERATION_GENERIC
    payload.qualifier = PAGE_GUEST_MODERATION
    payload.event_category = castId
    payload.event_data = fanRoomData
    payload.event_duration = duration
    payload.sw_type = fanRoomData.isTest ? 'test' : 'prod'
    this.logAnalyticsEvent(payload).then((result) => {
      if (result.isFailure) {
        addLogNoWait('Failed to send analytics moderation result', 'error', { 'error': result.message })
      }
    }).catch((error) => {
      addLogNoWait('Exception when sending a log analytics event to thor', 'error', { 'error': error, 'data': payload })
    })
  }
}


