import fb from '../firebase'
import firebase from 'firebase/compat/app'

interface FirebasePresenceConfig {
  uid: string,
  castId?: string|undefined,
  sessionId: string
}

interface RTDBStatus {
  uid: string,
  state: 'online' | 'offline',
  last_changed: any,
  castids: string[]
}

class FirebasePresence {
  uid: string
  castIds: string[]
  sessionId: string
  userStatusFirestoreRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  userStatusDatabaseRef: firebase.database.Reference

  isOfflineForDatabase: RTDBStatus
  isOnlineForDatabase: RTDBStatus

  constructor (config: FirebasePresenceConfig) {
    this.uid = config.uid
    this.castIds = config.castId !== undefined ? [config.castId] : []
    this.sessionId = config.sessionId
    this.userStatusFirestoreRef = fb.db.doc('/session/' + this.sessionId)
    this.userStatusDatabaseRef = fb.rtdb.ref('/sessions/' + this.sessionId)
    this.isOfflineForDatabase = {
      uid: this.uid,
      state: 'offline',
      last_changed: fb.rtdbServerTimestamp,
      castids: this.castIds
    }
    this.isOnlineForDatabase = {
      uid: this.uid,
      castids: this.castIds,
      state: 'online',
      last_changed: fb.rtdbServerTimestamp
    }

    // setup our connection
    try {
      this.setupPresence()
    } catch (error) {
      console.error(error)
      setTimeout(() => this.setupPresence(), 4000)
    }
  }

  setupPresence () {
    try {
      fb.auth.onAuthStateChanged(user => {
        if (user) {
          // Create a reference to the special '.info/connected' path in
          // Realtime Database. This path returns `true` when connected
          // and `false` when disconnected.
          fb.rtdb.ref('.info/connected').on('value', snapshot => {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() === false) {
              return
            }

            // If we are currently connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once this
            // client has disconnected by closing the app,
            // losing internet, or any other means.
            this.userStatusDatabaseRef.onDisconnect().set(this.isOfflineForDatabase).then(() => {
              // The promise returned from .onDisconnect().set() will
              // resolve as soon as the server acknowledges the onDisconnect()
              // request, NOT once we've actually disconnected:
              // https://fb.google.com/docs/reference/js/fb.database.OnDisconnect

              // We can now safely set ourselves as 'online' knowing that the
              // server will mark us as offline once we lose connection.
              this.userStatusDatabaseRef.set(this.isOnlineForDatabase).catch(error => {
                console.error('setupPresence: set online session', error)
              })
            }).catch(error => {
              console.error('On Disconnect Error', error)
            })
          })

          // already part of the settings
          // const settings = { timestampsInSnapshots: true }
          // fb.firestore().settings(settings)

          // Firestore uses a different server timestamp value, so we'll
          // create two more constants for Firestore state.
          const isOfflineForFirestore = {
            state: 'offline',
            last_changed: fb.serverTimestamp()
          }

          const isOnlineForFirestore = {
            state: 'online',
            last_changed: fb.serverTimestamp()
          }

          fb.rtdb.ref('.info/connected').on('value', snapshot => {
            if (snapshot.val() === false) {
              // Instead of simply returning, we'll also set Firestore's state
              // to 'offline'. This ensures that our Firestore cache is aware
              // of the switch to 'offline.'
              this.userStatusFirestoreRef.set(isOfflineForFirestore).catch(error => console.error('Could not set user status', error))
              return
            }

            this.userStatusDatabaseRef.onDisconnect().set(this.isOfflineForDatabase).then(() => {
              this.userStatusDatabaseRef.set(this.isOnlineForDatabase).catch(error => console.error('Could not set online status in db', error))

              // We'll also add Firestore set here for when we come online.
              this.userStatusFirestoreRef.set(isOnlineForFirestore).catch(error => console.error('Could not set online status', error))
            }).catch(error => {
              console.error('On disconnect error', error)
            })
          })
        }
      })
    } catch (error) {
      console.error('Could not set presence', error)
    }
  }

  async closeCast () {
    const isLoggedIn = fb.auth.currentUser !== null
    if (!isLoggedIn) return // nothing needs to be done here

    const isOfflineForFirestore = {
      state: 'offline',
      last_changed: fb.serverTimestamp()
    }
    try {
      await this.userStatusFirestoreRef.set(isOfflineForFirestore)
    } catch (error) {
      console.error('Error in closeCast ', error)
    }

    const isOfflineForDatabase = {
      uid: this.uid,
      castids: this.castIds,
      state: 'offline',
      last_changed: fb.rtdbServerTimestamp
    }
    try {
      await this.userStatusDatabaseRef.set(isOfflineForDatabase)
    } catch (error) {
      console.error('Error in closeCast ', error)
    }
    console.log('Close presence', isOfflineForDatabase, isOfflineForFirestore)
  }

  protected updateCastsPresence () {
    const update = {
      uid: this.uid,
      castids: this.castIds,
      state: 'online',
      last_changed: fb.rtdbServerTimestamp
    }
    this.userStatusDatabaseRef.set(update).catch(error => {
      console.error('Could not update cast info in firebasepresence', error)
    })
    this.isOfflineForDatabase.castids = this.castIds
    this.isOnlineForDatabase.castids = this.castIds
    this.setupPresence()
  }

  addCast (castId: string) {
    if (this.castIds.includes(castId)) return
    this.castIds.push(castId)
    this.updateCastsPresence()
  }

  removeCast (castId: string) {
    if (!this.castIds.includes(castId)) return
    this.castIds = this.castIds.filter((cid) => cid !== castId)
    this.updateCastsPresence()
  }
}

//
export default FirebasePresence
