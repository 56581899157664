import CheckFeature from '../checkfeature'
import { Team } from '@/modules/teams/classes'

export default class SCTEMarkersFeature extends CheckFeature {

  override hasFeature (): boolean {
    return this.currentTeam?.features?.markers?.enabled ?? false
  }

  override teamHasFeature (teamId: string): boolean {
    const team = this.teams[teamId]
    return team?.features?.markers?.enabled ?? false
  }

  override get description (): string {
    return `Allow producers to add scte markers to the srt outputs`
  }

  override isGlobalFeature (): boolean {
    return false
  }

  override assignTeamFeature (team: Partial<Team>, enabled: boolean) {
    team.features = team.features ?? {}
    team.features.markers = { enabled }
  }
}
