<script lang="ts">
import { defineComponent, h, ref } from 'vue'
import { setCurrentLanguage, setLanguageOfBrowser } from '@/plugins/translations/translation'
import { UserProfile } from '@/types/users'
import store from '@/store'

export default defineComponent({
  name: 'LanguageController',
  setup () {
    return {
      isDropdownToggled: ref(false)
    }
  },
  computed: {
    userProfile (): UserProfile|null {
      return store.state.user.profile
    }
  },
  watch: {
    userProfile: {
      immediate: true,
      handler () {
        this.updateTranslation()
      }
    }
  },
  methods: {
    updateTranslation () {
      if (this.userProfile !== null && this.userProfile.locale !== undefined) {
        setCurrentLanguage(this.userProfile.locale)
      } else {
        setLanguageOfBrowser()
      }
    }
  },
  render: () => h('div')
})
</script>
