import { Loader, Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CefMode } from '../types'
import CefStatInstance from './CefStatInstance'

export default class CefStatInstances {
  castId: string|null = null
  cefmode: CefMode|null = null
  instances: KeyDict<CefStatInstance>

  constructor (data: unknown) {
    this.castId = Loader.loadStringOrNull(data, 'castId', null)
    this.cefmode = Loader.loadEnumOrNull(data, 'cefmode', this.cefmode, Object.values(CefMode))
    this.instances = Loader.loadKeyDict(data, 'instances', (data: unknown) => new CefStatInstance(data))
  }

  static load (data: unknown): Result<CefStatInstances, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new CefStatInstances(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
