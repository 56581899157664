import fb from '../../firebase'
import env from '../../env'
import { ActionContext } from 'vuex'
import { LogsState, RootState } from '../types'
import { ClientInfo } from '@/types/logs'
import { VideoObjectNetworkStats } from '@/classes/VideoObjectNetworkStats'
import store, { moduleActionContext } from '..'
import firebase from 'firebase/compat/app'
import { MVar } from '@/helpers/async_helpers'
import { KeyDict } from '@/types'
import { defineModule } from 'direct-vuex'

type LogsContext = ActionContext<LogsState, RootState>

// initial state
const state = {
  clientInfo: {
    agent: navigator.userAgent,
    application: 'cloudcast',
    KENV: env.environment,
    version: env.version
  } as ClientInfo
  // messages: [] as LogMessage[]
} as LogsState

export interface LogMessage {
  message: string,
  cast?: string,
  event?: string,
  caster?: string,
  scene?: string,
  error?: any,
  data?: any,
  item?: string,
  k360event?: number | string,
  level: 'info' | 'error' | 'warning'
  logger_name?: 'metrics'
  stats?: VideoObjectNetworkStats,
  UIevent?: string,
  UItarget?: string,
  UIcontext?: string,
  castTemplate?: string,
  cc_uid?: string,
  jitter?: number,
  latency?: number
}

const prepareState = () => {
  // need to make sure there are no cycles,
  // throw out state.user.presence`
  const state = store.state
  const user = state.user
  user.presence
  const user_dumpable = { ...user, presence: null }
  const toDump = { ...state, user: user_dumpable }
  const toDumpStr = JSON.stringify(toDump)
  return toDumpStr
}

const dumpStateImpl = async (requestId: string): Promise<string> => {
  const toDumpStr = prepareState()
  const sizeMB =  toDumpStr.length / (1024.0 * 1024.0)
  console.log('dumpState is', sizeMB, 'MB')
  const teamId = store.state.team.team?.id ?? 'unknown'
  const objectName = `${teamId}-${requestId}.json`
  const bucketName = 'ops-dumps'
  const task = fb.firestorage.ref(bucketName)
                              .child(objectName)
                              .putString(toDumpStr)
  const finished = new MVar<void>()
  task.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      console.log(error)
      finished.put()
    },
    () => {
      console.log('done')
      finished.put()
    })
  await finished.read()
  const downloadURL : string = await task.snapshot.ref.getDownloadURL()
  return downloadURL
}

// actions
const actions = {
  async updateClientInfo (context: LogsContext, info: Partial<ClientInfo>) {
    const { commit } = logsModuleActionContext(context)
    commit.updateClientInfo(info)
  },
  async addLog (_context: LogsContext, payload: LogMessage) {
    // console.log('addLog ', payload)
    const userId = (store.state.user.userLoggedIn) ? store.state.user.id : 'non-logged-in'
    const date = new Date()
    payload.cc_uid = userId ?? 'non-logged-in'
    // @ts-ignore
    payload['@timestamp'] = date.toISOString()

    let dataObj: any = { event_id: payload.k360event }
    const allowed = ['@timestamp', 'cc_uid', 'message', 'level', 'clientip',
                     'caster', 'event_id', 'jitter', 'latency', 'logger_name']
    if (payload.message === 'MetricReport: CasterNetwork') {
      payload.latency = payload.data.latency ?? 0
      payload.jitter = payload.data.jitter ?? 0
    }
    for (const p in payload) {
      if (allowed.includes(p)) {
        // @ts-ignore
        dataObj[p] = payload[p]
      } else {
        const newKey = 'cc_' + p
        // @ts-ignore
        dataObj[newKey] = payload[p]
      }
    }
    dataObj = { ...state.clientInfo, ...dataObj }
    const sendLog = fb.functions.httpsCallable('sendLog')
    sendLog(dataObj)
  },
  async dumpComponent (_context: LogsContext, {component, message}: { component: any, message: string}) {
    const dumpdata: KeyDict<any> = {}
    Object.keys(component).forEach(key => {
      if (!key.includes('$') && !key.includes('_')) dumpdata[key] = component[key]
    })
    console.error('Internal Dump', message, JSON.stringify(dumpdata))
  },
  async dumpState (_context: LogsContext, parameter: { requestId: string } ) : Promise<string> {
    const downloadUrl = await dumpStateImpl(parameter.requestId)
    return downloadUrl
  },
  async logSDP (_context: LogsContext, payload: { sdpPre: string, sdpPost: string }) {
    const userId = store.state.user?.id
    if (userId === undefined || userId === null) return
    await fb.db.collection('sdp').doc(userId).set({
      sdpPre: payload.sdpPre,
      sdpPost: payload.sdpPost
    })
  }
}

// mutations
const mutations = {
  updateClientInfo (state: LogsState, info: Partial<ClientInfo>) {
    state.clientInfo = { ...info, ...state.clientInfo }
  }
}

const logsModule = defineModule({
  namespaced: true,
  state,
  actions,
  mutations
})

export default logsModule
export const logsModuleActionContext = (context: LogsContext) => moduleActionContext(context, logsModule)
