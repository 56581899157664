import { StringTranslation } from "../types"
const translation: StringTranslation = {
  "cast_letsgetstarted_text": "Empecemos",
  "cast_getstarted_button": "Empezar",
  "cast_loadingcast_text": "Cargando evento",
  "cast_pleasefillinname_question": "Complete sus datos antes de unirse a la sesión.",
  "cast_consentterms_text": "He leído y acepto los <a href=\"{tosUrl}\" target=\"_blank\">Términos y condiciones y la Política de privacidad de Athlete Moment</a> para que mi imagen, retrato, voz y participación, en su totalidad o en parte, puedan ser distribuidos y utilizados por OBS, el COI y sus sublicenciatarios y cesionarios (por ejemplo, organizaciones de radiodifusión autorizadas) en el contexto de los Juegos Olímpicos y su retransmisión, así como en las sedes olímpicas.",
  "cast_consentterms_error": "Debes aceptar los Términos y Condiciones",
  "cast_consentage_text": "Soy mayor de <span>{age}</span> años y soy mayor de edad conforme a la legislación aplicable.",
  "cast_consentage_error": "Debes tener al menos {age} años",
  "cast_is_scheduled_text": "This production is not live and the video previews are disabled",
  "cast_enter_text": "Press to enter the production",
  "cast_enter_button": "Entrar",
  "cast_tryingtoconnect_text": "Trying to connect.",
  "cast_waitingforcast_title": "Haga este momento único",
  "cast_waitingforcast_text": "Espere al próximo moderador disponible. Esto puede llevar algún tiempo. No actualices la página.",
  "cast_waitingforcast_guide1_title": "Colóquese apropiadamente",
  "cast_waitingforcast_guide2_title": "Expresese con entusiasmo",
  "cast_waitingforcast_guide3_title": "Cuide su conducta y apariencia",
  "cast_waitingforcast_guide1_text": "Asegurese de que está bien colocado, sentado en el centro de la pantalla y manteniendo una buena postura. ¡Recuerde, el mundo está viéndole!",
  "cast_waitingforcast_guide2_text": "Muestre entusiasmo y anime en voz alta para verbalizar su emoción",
  "cast_waitingforcast_guide3_text": "Evite mostrar marcas, alegatos políticos y cualquier forma de insulto y mantenga el decoro",
  "cast_waitingforcastautoaccept_text": "Está listo para conocer un moderador.",
  "cast_switch_team_text": "Este reparto requiere que cambies a un equipo diferente",
  "cast_switch_team_button": "Equipo Switch",
  "complete_join_text": "Inscripción para Athlete Moment",
  "general_pleasewait_text": "Espere por favor",
  "general_yourname_label": "Tu nombre",
  "general_firstname_label": "Nombre",
  "general_lastname_label": "Apellido",
  "general_email_label": "correo electrónico",
  "general_country_label": "País",
  "general_firstname_error": "Debes proporcionar un nombre",
  "general_lastname_error": "Debe proporcionar un apellido",
  "general_requiredallfields_error": "Todos los apartados son obligatorios.",
  "general_pleasefillinyourname_placeholder": "Por favor, ingese su nombre",
  "general_pleasefillinfirstname_placeholder": "Por favor ingrese su nombre propio",
  "general_pleasefillinlastname_placeholder": "Por favor ingrese su apellido",
  "general_pleasefillinemail_placeholder": "Por favor ingrese su correo electrónico",
  "general_join_button": "Entrar",
  "general_send_button": "Enviar",
  "general_yes_button": "Sí",
  "general_no_button": "No",
  "general_ok_button": "OK",
  "general_close_button": "Cerrar",
  "general_done_button": "Hecho",
  "general_register_button": "Inscripción",
  "general_restart_button": "Reinice",
  "general_continue_button": "Continuar",
  "chat_nomessages_placeholder": "Aún no hay mensajes para este elenco",
  "chat_enteryourmessage_placeholder": "Ingrese su mensaje aquí",
  "chat_chat_label": "Conversación",
  "chat_unreadmessage_text": "{count} nuevo mensaje",
  "chat_unreadmessages_text": "{count} nuevos mensajes",
  "redirect_welcometokiswe_text": "Bienvenidos",
  "redirect_pleasewaittoredirect_text": "Espere mientras estamos conectando a ...",
  "redirect_end_cast_text": "La sala del moderador estará disponible una hora antes del inicio del evento del atleta. Por favor vuelva más tarde.",
  "redirect_cast_reach_maximum_text": "Este Athlete Moment ha alcanzado su máximo número de participantes. Por favor espere hasta que quede un sitio libre.",
  "redirect_disabled_existing_caster_text": "Hemos detectado un problema. Cierre esta pestaña del navegador o cierre el navegador por completo y haga clic nuevamente en el enlace original.",
  "redirect_invalidlink_title": "Enlace inválido",
  "redirect_invalidlink_text": "El enlace que proporcionaste no es válido.",
  "redirect_castnotactive_title": "No hay ninguna sala activa adjunta a este enlace",
  "redirect_castnotactive_paragraph": "Vuelva más tarde o intente con un enlace diferente.",
  "actiontab_assets_label": "Assets",
  "actiontab_casters_label": "Casters",
  "actiontab_inputs_label": "Inputs",
  "actiontab_outputs_label": "Output",
  "actiontab_audio_label": "Audio Mixer",
  "actiontab_settings_label": "Settings",
  "actiontab_clips_label": "Clips",
  "actiontab_commentators_label": "Tracks",
  "actiontab_crew_label": "Crew",
  "actiontab_talkback_label": "Talkback",
  "actiontab_chat_label": "TextCast",
  "actiontab_simplecg_label": "Simple CG",
  "actiontab_sctemarkers_label": "SCTE Markers",
  "actiontab_interactive_label": "Interactivo",
  "actiontab_switcher_label": "Scenes",
  "actiontab_layout_label": "Layers",
  "actiontab_preset_label": "Preset",
  "topbar_untileventstarts_text": "hasta que comience el evento",
  "topbar_untileventends_text": "hasta que termine el evento",
  "usermedia_startcasting_button": "Comenzar a transmitir",
  "usermedia_connect_button": "Conectar",
  "usermedia_detectdevices_button": "Detectar dispositivos",
  "usermedia_detecting_text": "Detector",
  "usermedia_clickbutton_tooltip": "Haz clic en el botón de transmitir para comenzar a transmitir",
  "usermedia_echocancellation_text": "Habilitar la cancelación de eco",
  "usermedia_camera_label": "Cámara",
  "usermedia_microphone_label": "Micrófono",
  "usermedia_learnaccess_button": "Aprende cómo dar acceso",
  "usermedia_pressallow_text": "Presione 'allow' en la ventana emergente",
  "castgrawl_mutedbycrew_label": "The crew muted you",
  "castgrawl_mutedyourself_label": "You are muted",
  "castgrawl_streamhasissues_label": "There are connection issues. Refresh the page to solve it",
  "audio_copyfromprogram_button": "Copy from Program Mix",
  "audio_applytoprogram_button": "Apply to Program Mix",
  "audio_programmix_label": "Program mix",
  "audio_monitor_label": "Monitor mix",
  "talkback_nomembers_text": "No crew members are connected to talkback",
  "talkback_setincomingmaster_button": "Set incoming master levels",
  "talkback_setincomingindividual_button": "Set incoming individual levels",
  "error_offlinenotice_text": "Actualmente está desconectado. Estamos intentando reconectarte. Si esto continúa más de unos segundos, verifique su conexión a Internet y actualice esta página.",
  "cast_waitforqueue_text": "Parece que está un poco abarrotado. Espere por favor...",
  "cast_yourpositioninqueue_text": "En cola de espera: <strong class = \"primary \"> {currentPositionInQueue} </strong> / {totalInQueue}",
  "cast_needpermissions_text": "Permita el acceso a dispositivos",
  "cast_deviceerror_text": "Detectamos un problema con su {device}. Cierra el navegador, verifica la conexión de tu {device} y haz clic nuevamente en el enlace de invitado.",
  "cast_deniedpermission_text": "El acceso ha sido denegado y no puede registrarse",
  "cast_deniedguestlink_text": "You are already logged in. If you want to use this link, please log out first.",
  "cast_deniednotinvited_text": "No puede unirse a este evento.",
  "cast_everythingsetup_text": "Gracias, <span class = \"primary \"> {display_name} </span>, ¡ya está listo para ingresar a la sala!",
  "cast_moderatorpickyouup_text": "El moderador te hará ingresar pronto.",
  "cast_chattingwithmoderator_text": "Bienvenido a la conversacion",
  "cast_moderatorrejected_text": "Lo sentimos, el moderador ha decidido no dejarte entrar.",
  "endsession_thanks_text": "Gracias por unirse. Esta página se cerrará en {remainingSecs} segundos.",
  "endsession_thankforcoming_text": "Gracias por unirte",
  "endsession_thankforcomingtocast_text": "Gracias por unirte a {castName} hoy",
  "endsession_closetab_text": "Puede cerrar esta pestaña ahora o iniciar sesión con una cuenta existente.",
  "endsession_backtologin_button": "Reservar la sesión de inicio.",
  "tally_roomactive_text": "Preparando para la transmisión",
  "tally_roomlive_text": "¡La habitación está en vivo!",
  "leavecast_popup_title": "Leave production",
  "leavecast_popup_description": "Are you sure you want to leave the production? The production will not stop, you just leave it.",
  "leavecast_popupanonymous_title": "Leave production",
  "leavecast_popupanonymous_description": "Are you sure you want to leave the production? The production will not stop, you just leave it. You won't be able to come back.",
  "browsercheck_browser_label": "Navegador",
  "browsercheck_version_label": "Versión",
  "browsercheck_supportbrowser_text": "Solo puede acceder a Athlete Moment con un navegador Chrome, Edge, Safari, Firefox.",
  "browsercheck_supportmobilebrowser_text": "Solo puede acceder a Athlete Moment con un navegador Chrome, Safari.",
  "browsercheck_download_chrome": "Descargue Chrome",
  "browsercheck_download_edge": "Descargue Edge",
  "browsercheck_download_safari": "Descargue Safari",
  "browsercheck_download_firefox": "Descargue Firefox",
  "browsercheck_appstore_paragraph": "Kiswe Studio está disponible en Apple Store.",
  "browsercheck_systemnotsupported_paragraph": "This device does not meet the minimal requirements for using Kiswe Studio.",
  "statusstrip_leaveevent_tooltip": "Leave production",
  "statusstrip_selectdevices_tooltip": "Seleccionar dispositivos",
  "screenmode_pleaserotate_title": "Por favor gire su dispositivo",
  "screenmode_pleaserotateios_text": "Athlete Moment, experimenta mejor en modo horizontal. Actualmente estás usando el modo retrato. Gire su dispositivo.",
  "screenmode_pleaserotateandroid_text": "Athlete Moment, experimenta mejor en modo horizontal. Actualmente estás usando el modo retrato. Gire su dispositivo o use el botón de abajo.",
  "screenmode_pleaserotate_actionbutton": "Rotar mi dispositivo ",
  "athletemoments_welcome_title": "¡Bienvenidos a los Momentos del Atleta!",
  "athletemoments_redirect_paragraph": "Serás redirigido en seguida.",
  "castwelcome_wowzaissues_error": "Actualmente, el sistema está manejando muchas cargas. Vuelva a intentarlo en unos minutos",
  "privatecall_nolocalstream_error": "Necesitamos su permiso para configurar la transmisión. Si ya otorgó el permiso, es probable que haya un problema con su cámara web.",
  "privatecall_sendwarning_notification": "El moderador no pudo escuchar ni verlo. Verifique que el micrófono y la cámara funcionen correctamente y vuelva a intentarlo.",
  "privatecall_sendwarning_label": "Vuelva a intentarlo",
  "privatecall_sendwarning_footer": "¿No has escuchado al moderador (claramente)? Pincha aquí.",
  "browsercheck_ioscontinueweb_paragraph": "¡Bienvenido! Si su dispositivo iOS tiene 3 años o más (2018 o anterior), instala la app Kiswe Studio desde el Apple Store. Si no es así, selecciona 'Continuar en la web' a continuación para obtener la mejor experiencia.",
  "browsercheck_ioscontinueweb_button": "Continuar en la web",
  "statusstrip_selectdevicesdisabled_tooltip": "Actualmente no se permite cambiar de dispositivo.",
  "cast_moderatorrequestedstreamrestart_text": "El moderador ha pedido que se reinicie el stream. Un momento, por favor.",
  "selftest_checkcpuandmemory_message": "Actualmente estamos comprobando su dispositivo.",
  "selftest_setup_text": "Seleccione su micrófono y cámara",
  "selftest_running_text": "Compruebe su conexión",
  "selftest_fail_text": "esta configuración no funciona",
  "selftest_restart_text": "Compruebe su conexión a internet o seleccione otro dispositivo",
  "selftest_casting_text": "Conectando, espere por favor",
  "selftest_noreply_text": "No podemos conectarte. Verifique la conexión a Internet.",
  "selftest_canyouhearlocal_question": "¿Puede oirse?",
  "selftest_canyouhearremote_question": "¿Puede oir el audio?",
  "selftest_canyouseelocal_question": "¿Puede verse?",
  "selftest_canyouseeremote_question": "¿Puede ver el video?",
  "selftest_pleasecloseapps_question": "Cierre todas las demás aplicaciones / pestañas",
  "selftest_selectothercamera_question": "Intenta seleccionar otra cámara",
  "selftest_checkyourvolume_question": "Please check if your volume is on",
  "selftest_seeotherguests_question": "Can you see the other 4 streams?",
  "selftest_checkyourheadset_question": "If you are using a headset? Can you hear yourself now?",
  "selftest_hearotherguests_question": "Can you hear this stream?",
  "selftest_performance_question": "selftest_performance_question",
  "selftest_canyouseeyourselflocal_body": "selftest_canyouseeyourselflocal_body",
  "selftest_canyouseeyourselfremote_body": "selftest_canyouseeyourselfremote_body",
  "selftest_onsuccess_button": "selftest_onsuccess_button",
  "selftest_onfail_button": "selftest_onfail_button",
  "selftest_successresult_message": "selftest_successresult_message",
  "selftest_successresult_body": "selftest_successresult_body",
  "selftest_failresult_message": "selftest_failresult_message",
  "selftest_failresult_body": "selftest_failresult_body",
  "selfteststats_canseeselflocal_term": "selfteststats_canseeselflocal_term",
  "selfteststats_canseeselfremote_term": "selfteststats_canseeselfremote_term",
  "selfteststats_canhearselfremote_term": "selfteststats_canhearselfremote_term",
  "selfteststats_canseeothers_term": "selfteststats_canseeothers_term",
  "selfteststats_canhearothers_term": "selfteststats_canhearothers_term",

  "cast_consentprivacy_text": "본인은 참여가 개인 정보의 의무적인 수집과 사용을 요구한다는 것, 또한 특정 <a href=\"https://www.obs.tv/prx/asset.php?tgt=DelegatesSKAMOonboardingENSK-208d50ae628f.pdf&gen=1\" target=\"_blank\">해외 법인들</a>과 함께 그러한 처리를 위임할 수 있음을 설명한 <a href=\"https://www.obs.tv/prx/asset.php?tgt=B2022ATHLETESMOMENTTERMSANDCONDITIONSEN-SK-e74519b05996.pdf&gen=1\" target=\"_blank\">개인정보 보호정책</a>을 읽고 승인했습니다.",
  "cast_consentprivacy_error": "",  // cast_consentprivacy_error is only available for Korean

  "casttypeselector_switcher_name": "",
  "casttypeselector_switcher_description": "",
  "casttypeselector_switcher_short_description": "",
  "casttypeselector_fanroom_name": "",
  "casttypeselector_fanroom_description": "",
  "casttypeselector_fanroom_short_description": "",
  "casttypeselector_stationswitcher_name": "Station",
  "casttypeselector_stationswitcher_description": "Broadcast your Rooms",
  "casttypeselector_stationswitcher_short_description": "Station",
  "srt_type_passthrough_label": "Passthrough",
  "srt_type_multitrack_label": "Multi-Track"
}

export default translation
