import { useCastProfiles, useCurrentCast } from '@/modules/casts/compositions'
import { useCurrentTeam } from '@/modules/teams/compositions'
import { useCurrentUserProfile } from '@/modules/user/compositions'
import { useCastStreams } from '@/modules/streams/compositions'
import { KeyDict } from '@/types'
import { CastStream, SourceStreamKeyed, StreamType } from '@/types/streams'
import { computed } from 'vue'
import { pickBy } from 'lodash'
import { WRTCServerType } from '@/types/admin'

const useCrewControls = () => {

  const { currentCast } = useCurrentCast()
  const { currentTeamMembers: teamProfiles } = useCurrentTeam()
  const { currentUserProfile } = useCurrentUserProfile()
  const { crew: unsortedCrew } = useCastProfiles(currentCast, teamProfiles)
  const { sourceStreams, castStreams } = useCastStreams()

  const crew = computed(() => Object
    .values(unsortedCrew.value)
    .filter((user) => user.id !== currentUserProfile.value?.id)
    .sort((a, b) => a.fullName < b.fullName ? -1 : 1))

  const remoteCrewStreamIds = computed(() => {
    return Object.keys(pickBy(sourceStreams.value, (stream) => stream.type === StreamType.RemoteTalkback))
  })

  const crewTalkbackStreams = computed(() => {
    const talkbackStreams: KeyDict<SourceStreamKeyed> = {}
    for (const streamId of Object.keys(sourceStreams.value)) {
      const stream = sourceStreams.value[streamId]
      if ((stream.type === StreamType.NonCaster || stream.type === StreamType.Moderator) && stream.user_id) {
        talkbackStreams[stream.user_id] = { id: streamId, stream }
      } else if (stream.type === StreamType.RemoteTalkback) {
        talkbackStreams[streamId] = { id: streamId, stream }
      }
    }
    return talkbackStreams
  })

  const getCrewCastStream = (userId: string): CastStream|null => {
    let userCastStream: CastStream|null = null
    const userSourceStream = crewTalkbackStreams.value[userId]
    if (userSourceStream) {
      const isJanus = currentCast.value?.wrtcServerType === WRTCServerType.JANUS
      const castStreamKey = isJanus ? userSourceStream.id : `${userSourceStream.id}_lowrate`
      userCastStream = castStreams.value[castStreamKey]
    }
    return userCastStream
  }

  return {
    crew,
    remoteCrewStreamIds,
    crewTalkbackStreams,
    getCrewCastStream
  }
}

export default useCrewControls
