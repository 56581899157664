import { RootState } from '../../types'
import store from '@/store'
import { cloneDeep } from 'lodash'
import fb from '@/firebase'
import { ActionContext } from 'vuex'
import { StreamType, CastStream, Stream, SourceStreamBroadCast } from '@/types/streams'
import { KeyDict } from '@/types'
import getWrtcForCasting from '@/modules/transcodingregions/classes/getWrtcForCasting'
import studio, { CollectionNames } from '@/modules/database/utils'
import { DatabaseAction, MergeOption } from '@/modules/database/utils/database'
import { StreamsState } from './streams.state'
import { streamsModuleActionContext } from '.'
import { checkStreamsVuMeters, vumeterSubscriptionCount } from './streams.vumeters'

export type StreamsContext = ActionContext<StreamsState, RootState>

export const actions = {
  async subscribeVuMeter (context: StreamsContext, streamId: string) {
    const { commit } = streamsModuleActionContext(context)
    if (!vumeterSubscriptionCount[streamId]) {
      vumeterSubscriptionCount[streamId] = 1
      commit.setVuMeter({streamId, channelVolumes: [0, 0]})
      checkStreamsVuMeters()
    } else {
      vumeterSubscriptionCount[streamId]++
    }
  },
  async unsubscribeVuMeter (context: StreamsContext, streamId: string) {
    const { commit } = streamsModuleActionContext(context)
    if (vumeterSubscriptionCount[streamId] === undefined) {
      console.warn('should not unsubscribe vumeter more than subscribed', streamId)
      return
    }
    vumeterSubscriptionCount[streamId]--
    if (vumeterSubscriptionCount[streamId] === 0) {
      commit.removeVuMeter(streamId)
      checkStreamsVuMeters()
    }
  },
  updateVuMeter (context: StreamsContext, vumeter: { streamId: string, channelVolumes: number[]}) {
    const { state, commit } = streamsModuleActionContext(context)
    if ((state.vumeters[vumeter.streamId]?.timestamp ?? 0) < Date.now() - state.vumeterUpdateInterval) {
      commit.setVuMeter(vumeter)
    }
  },
  updateVuMeterInterval (context: StreamsContext, interval: number) {
    const { commit } = streamsModuleActionContext(context)
    if (interval > 0 && interval < 2000) {
      commit.setVuMeterInterval(interval)
    } else {
      console.warn('invalid vumeter interval, should be between 0 and 2000ms', interval)
    }
  },
  setStreamManager (context: StreamsContext, payload: KeyDict<CastStream>) {
    const { commit } = streamsModuleActionContext(context)
    commit.setStreamsManager(payload)
    checkStreamsVuMeters()
  },
  deleteStreamFromManager (context: StreamsContext, payload: string) {
    const { commit } = streamsModuleActionContext(context)
    commit.deleteStreamFromManager(payload)
  },
  addStreamToManager (context: StreamsContext, stream: CastStream) {
    const { commit } = streamsModuleActionContext(context)
    commit.addStream(stream)
  },
  async copyCasterToBroadcast (_context: StreamsContext, streamId: string) {
    if (store.state.events.currentCast === null) {
      throw new Error('Could not copy caster to broadcast if currentCast is null')
    }
    const mainStreamId = streamId.split('_')[0]
    const origStream = store.state.events.currentCast.source_streams[mainStreamId]
    if (origStream === undefined) {
      throw new Error('Could not find sourcestream with id ' + mainStreamId)
    }
    if (origStream.type !== StreamType.Caster) {
      throw new Error(`Cannot assign a sourcestream with type ${origStream.type} to a broadcast stream`)
    }
    const sourceStream: SourceStreamBroadCast = { ...cloneDeep(origStream), type: StreamType.Broadcast }
    const userId = origStream.user_id ?? ''
    const user = store.state.team.currentTeam[userId]
    sourceStream.type = StreamType.Broadcast
    if (sourceStream.cef_feed_id) {
      delete sourceStream.cef_feed_id
    }
    const stream = await fb.db.collection(CollectionNames.STREAMS).doc(mainStreamId).get()
    const streamData = stream.data()
    if (streamData === undefined) {
      throw Error('caster stream does not exists')
    }
    if (streamData.variants) {
      delete streamData.variants
    }
    streamData.type = 'broadcast'
    streamData.name = user?.display_name ?? user?.first_name ?? 'Caster'
    streamData.deleted = false
    const newStreamId = `${mainStreamId}broadcast`
    await fb.db.collection(CollectionNames.STREAMS).doc(newStreamId).set(streamData)
    await fb.db.collection(CollectionNames.CASTS).doc(store.state.events.currentCast.id).set({
      source_streams: {
        [newStreamId]: sourceStream
      }
    }, { merge: true })
  },
  async clearStreamAuthError (_context: StreamsContext, streamId: string) {
    if (streamId === '') throw Error('empty stream id')
    // @ts-ignore
    const fieldPath = new fb.firebase.firestore.FieldPath('auth_info', 'error')
    await fb.db.collection(CollectionNames.STREAMS).doc(streamId).update(fieldPath, fb.deleteField)
  },
  async fetchStream (_context: StreamsContext, streamId: string): Promise<Stream|null> {
    try {
      const streamData = (await fb.db.collection(CollectionNames.STREAMS).doc(streamId).get()).data()
      if (streamData !== undefined) {
        return streamData as Stream
      }
    } catch (error) {
      console.error(`Failed to fetch data of stream ${streamId}:`, error)
    }
    return null
  },
  unsubscribeStream (context: StreamsContext, streamId: string) {
    const { state } = streamsModuleActionContext(context)
    if (!streamId) throw new Error('A stream id must be provided.')
    studio.subscriptions.unsubscribeFB(`stream_${streamId}`, () => {
      if (state.subscribedStreams[streamId]) {
        delete state.subscribedStreams[streamId]
      }
    })
  },
  async subscribeStream (context: StreamsContext, streamId: string) {
    if (!streamId) throw new Error('A stream id must be provided.')
    const { commit } = streamsModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: `stream_${streamId}`,
      query: studio.db.collection(CollectionNames.STREAMS).doc(streamId),
      callback: commit.updateSubscribedStream,
    })
    result.logIfError('Failed to subscribe to stream')
  },
  async stopScreenSharing (context: StreamsContext) {
    const { commit, state } = streamsModuleActionContext(context)
    const castId = store.state.events.currentCast?.id ?? null
    // const casterId = store.state.user.id
    const profile = store.state.user.profile
    if (castId === null) throw new Error('Cannot stop streaming desktop output a cast, currentCast is null')
    if (profile === null) throw new Error('Cannot stop streaming desktop, userProfile is null')
    if (state.desktopStreamId === null) throw new Error('Cannot stop streaming desktop, never started')
    const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).set({
      source_streams: {
        [state.desktopStreamId]: DatabaseAction.deleteField()
      }
    }, MergeOption.MERGE)
    result.logIfError('Could not stop screensharing, could not remove the source stream')
    commit.setDesktopStreamId(null)
    store.dispatch.usermedia.setLocalDesktopStream(null)
  },
  async addScreenshareToCast (context: StreamsContext, stream: MediaStream) {
    store.dispatch.usermedia.setLocalDesktopStream(stream)
    const { commit } = streamsModuleActionContext(context)
    const profile = store.state.user.profile
    const castId = store.state.events.currentCast?.id
    const casterId = store.state.user.id
    const sessionId = store.state.user.presence?.sessionId
    if (profile === null) throw new Error('Cannot start streaming desktop, userProfile is null')
    if (castId === null) throw new Error('Cannot start streaming desktop output a cast, currentCast is null')
    if (sessionId === null) throw new Error('Cannot start streaming desktop, sessionId is null')
    const pubts = Math.round(Date.now() / 1000)
    const newsref = fb.db.collection('streams').doc()
    commit.setDesktopStreamId(newsref.id)
    const wrtcserver = getWrtcForCasting(profile)
    const srcPrefix = `rtsp://${wrtcserver}:1935/livetcp/`
    if (newsref.id !== '') {
      await fb.db.collection('streams').doc(newsref.id).set({
        user: casterId,
        name: `ScreenShare ${profile.first_name} ${profile.last_name}`,
        active: true,
        persistent: false,
        team_id: profile.currentTeam,
        srcurl: `${srcPrefix}${newsref.id}`,
        type: StreamType.ScreenShare,
        pubts: pubts
      })
      await fb.db.collection('casts').doc(castId).set({
        source_streams: {
          [newsref.id]: {
            user_id: casterId,
            name: `ScreenShare ${profile.first_name} ${profile.last_name}`,
            session_id: sessionId,
            active: true,
            volume: 0,
            type: StreamType.ScreenShare
          }
        }
      }, { merge: true })
    }
  },
  async startScreenSharing (context: StreamsContext) {
    const { dispatch } = streamsModuleActionContext(context)
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          frameRate: 5
        }
      })
      const audioCtx = new window.AudioContext()
      const osc = audioCtx.createOscillator();
      const destination = audioCtx.createMediaStreamDestination()
      osc.connect(destination)
      osc.start()
      const tracks = destination.stream.getAudioTracks()
      tracks[0].enabled = false
      stream.addTrack(tracks[0])
      stream.getVideoTracks()[0].onended = () => { dispatch.stopScreenSharing() }
      await dispatch.addScreenshareToCast(stream)
    } catch (error) {
      console.error('Could not create screenshare documents', error)
    }
  },
  async getStreamDocById (_context: StreamsContext, streamDocId: string) {
    return await studio.db.collection(CollectionNames.STREAMS).doc(streamDocId).get()
  }
}
