import fb from '@/firebase'
import { UnsubscribeFunction } from '@/store/types'
import { MemberInvite, FaceBookPage, TeamGroup } from '@/types/teams'
import { TeamCustomerType } from '@/modules/teams/types'
import { Team, TeamBranding } from '@/modules/teams/classes'
import { Stream, DefaultStream, StreamType, SrtInfo } from '@/types/streams'
import { WarningMessage } from '@/classes/Admin/WarningMessage'
import { UserNotification, UserProfile } from '@/types/users'
import { Layout } from '@/types/layouts'
import { some } from 'lodash'
import store from '../..'
import { removeExpiredNotifications } from '../../../modules/teams/utils/team.helpers'
import { TeamContext, teamModuleActionContext } from '.'
import { DeepPartial, KeyDict } from '@/types'
import { normalizeTransition } from '@/helpers/normalize'
import firebase from 'firebase/compat/app'
import { MVar } from '@/helpers/async_helpers'
import createTeamStream from '@/modules/teams/utils/createTeamStream'
import createNewTeam, { CreateTeamParams } from '@/modules/teams/utils/createNewTeam'
import studio, { AdminDocNames } from '@/modules/database/utils'
import { APIToken } from '@/modules/api/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { Result, ResultVoid } from 'kiswe-ui'
import { CollectionNames } from '@/modules/database/utils'
import { DatabaseSubscription, FirestoreDocSnapper, FirestoreQuerySnapper, FirestoreSnapperType } from '@/modules/database/utils/databaseQuerySubscriptions'
import Convertable from '@/classes/Convertable'


interface TeamsSubscriptions {
  activeTeam: null | UnsubscribeFunction,
  warningMessages: null | UnsubscribeFunction
}

const subscriptions = {
  activeTeam: null,
  warningMessages: null
} as TeamsSubscriptions

const teamUnsubscribe: KeyDict<UnsubscribeFunction> = {}
const teamLayoutUnsubscribe: KeyDict<UnsubscribeFunction> = {}
const teamGroupsUnsubscribe: KeyDict<UnsubscribeFunction> = {}

let teamStreamSubscription: null|DatabaseSubscription<KeyDict<Stream>>

const makeSrtInfo = (form_info : any) : SrtInfo => {
  const srtInfo : SrtInfo = {
     nimbleserver : form_info.nimbleserver,
     port: parseInt(form_info.port),
     ip: form_info?.ip ?? '0.0.0.0',
     mode: form_info?.mode ?? 'listen'
  }
  return srtInfo
}

// actions
const actions = {
  async subscribeTeamInvites (context: TeamContext, teamId: string) {
    const { commit } = teamModuleActionContext(context)
    store.commit.assets.assetsLoading(true)
      const snapper: FirestoreQuerySnapper = {
      query: fb.db.collection(CollectionNames.INVITES).where('team_id', '==', teamId),
      mutation: commit.setInvites,
      type: FirestoreSnapperType.Query
    }
    studio.subscriptions.subscribeFB(`teaminvites_${teamId}`, snapper)
  },
  async unsubscribeTeamInvites (_context: TeamContext, teamId: string) {
    studio.subscriptions.unsubscribeFB(`teaminvites_${teamId}`)
  },
  async subscribeAllTeams (context: TeamContext) {
    const { commit } = teamModuleActionContext(context)
    const key = 'allteams'
    const result = await studio.subscriptions.subscribe({
      key,
      query: studio.db.collection(CollectionNames.TEAMS),
      callback: commit.setAllTeams
    })
    result.logIfError('subscribeAllTeams')
  },
  async unsubscribeAllTeams (context: TeamContext) {
    const key = 'allteams'
    studio.subscriptions.unsubscribe(key, () => {
      const { commit } = teamModuleActionContext(context)
      commit.clearAllTeams()
    })
  },
  async checkIfUserHasProfile (_context: TeamContext, email: string) {
    const res = await fb.db.collection(CollectionNames.PROFILES).where('email', '==', email).get()
    return res
  },
  async inviteTeamMember (_context: TeamContext, member: MemberInvite) {
    await fb.db.collection('invites').add({ ...member })
  },
  async deleteInvite (_context: TeamContext, invite: MemberInvite) {
    await fb.db.collection('invites').doc(invite.id).delete()
  },
  async resendInvite (_context: TeamContext, invite: MemberInvite) {
    await fb.db.collection('invites').doc(invite.id).set({ email_sent: false }, { merge: true })
  },
  unsubscribeActiveTeam (_context: TeamContext) {
    if (subscriptions.activeTeam !== null) {
      subscriptions.activeTeam()
      subscriptions.activeTeam = null
    }
  },
  async setActiveTeam (context: TeamContext, teamId: string|null) {
    const { commit, dispatch, state } = teamModuleActionContext(context)
    if (state.activeTeam === teamId) {
      return
    }
    await dispatch.unsubscribeActiveTeam()
    if (teamId === null) {
      commit.setActiveTeam('')
      return
    }
    commit.setActiveTeam(teamId)
    // TODO: Use storehelper so we can easily waitForIt and we only need to store the subscription key instead of the
    //       unsubscribe function
    //       Applies to other `onSnapshots()` subscriptions in this module too.
    const mvar = new MVar<void>()
    const result = studio.db.collection(CollectionNames.TEAMS).doc(teamId).onSnapshot(team => {
      if (team) {
        team.id = team.id ?? teamId // make sure id property is set
        commit.setTeam(team)
      }
      mvar.put()
    })
    if (result.isSuccess) subscriptions.activeTeam = result.value
    result.logIfError('setActiveTeam')
    await mvar.read()
  },
  async notifyTeamMember (context: TeamContext, newNotification: UserNotification ) {
    const { state } = teamModuleActionContext(context)
    const member = state.currentTeam[newNotification.user_id]
    if (!member) {
      return
    }
    removeExpiredNotifications(member)

    const now = Date.now()
    const alreadyExists = some(member.notifications || {}, function (value) {
      const notExpired = value.expires > now
      return notExpired
        && value.link === newNotification.link
        && value.linkLabel === newNotification.linkLabel
        && value.linkLabelTranslationKey === newNotification.linkLabelTranslationKey
        && value.user_id === newNotification.user_id
        && value.message === newNotification.message
        && value.messageTranslationKey === newNotification.messageTranslationKey
    })

    if (alreadyExists) {
      console.log('Notification alreadyExists')
      return
    }

    const updateObject: KeyDict<UserNotification> = {}
    updateObject[newNotification.expires] = {
      ...newNotification
    }
    if (newNotification.user_id) {
      const result = await studio.db.collection(CollectionNames.PROFILES).doc(newNotification.user_id).set({
        notifications: updateObject
      }, MergeOption.MERGE)
      result.logIfError('notifyTeamMember')
    }
  },
  undoNotifyTeamMember: async (context: TeamContext, undoNotification: UserNotification) => {
    const { state } = teamModuleActionContext(context)
    const member = state.currentTeam[undoNotification.user_id]
    if (!member) {
      return
    }
    const deleteupdate: KeyDict<firebase.firestore.FieldValue> = {}
    deleteupdate['notifications.' + undoNotification.expires] = fb.deleteField
    await fb.db.collection(CollectionNames.PROFILES).doc(member.user_id).update(deleteupdate)
  },
  async removeNotification (context: TeamContext, notification: UserNotification ) {
    const { state } = teamModuleActionContext(context)
    const member = state.currentTeam[notification.user_id]
    if (!member) {
      throw new Error('Could not remove notification, notification.user_id does not exists in current Team')
    }

    removeExpiredNotifications(member)

    const deleteupdate: KeyDict<firebase.firestore.FieldValue> = {}
    deleteupdate['notifications.' + notification.expires] = fb.deleteField
    await fb.db.collection(CollectionNames.PROFILES).doc(member.user_id).update(deleteupdate)
  },
  async updateTeamMember (_context: TeamContext, { teamId, member }: { teamId: string, member: DeepPartial<UserProfile> }) {
    if (!member.user_id || !teamId) return
    delete member.role
    const result = await studio.db
      .collection(CollectionNames.PROFILES)
      .doc(member.user_id)
      .set(Convertable.toObject(member), MergeOption.MERGE)
    result.logIfError('updateTeamMember')
  },
  setDefaultTeamLayout (_context: TeamContext, data: { team_id: string, layout: string }) {
    return new Promise<void>(resolve => {
      fb.db.collection(CollectionNames.TEAMS).doc(data.team_id).set({ default_layout: data.layout }, { merge: true }).then(() => {
        resolve()
      }).catch(error => {
        console.log('Error creating user: ', error)
      })
    })
  },
  async setRedirectUrl (context: TeamContext, data: { redirectUrl: string | null }) : Promise<boolean> {
    const { state } = teamModuleActionContext(context)
    if (state.team === null) return false
    try {
      await fb.db.collection(CollectionNames.TEAMS)
                 .doc(state.team.id)
                 .update({ redirectUrl: data.redirectUrl })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setDefaultRegion (context: TeamContext, newRegion: string) {
    const { state } = teamModuleActionContext(context)
    if (newRegion && state.team !== null) {
      await fb.db.collection(CollectionNames.TEAMS).doc(state.team.id).set({ default_region: newRegion }, { merge: true })
    }
  },
  unsubscribeCurrentTeams (_context: TeamContext) {
    Object.keys(teamUnsubscribe).forEach(team => {
      teamUnsubscribe[team]()
      delete teamUnsubscribe[team]
    })
    Object.keys(teamLayoutUnsubscribe).forEach(team => {
      teamLayoutUnsubscribe[team]()
      delete teamLayoutUnsubscribe[team]
    })
    Object.keys(teamGroupsUnsubscribe).forEach(group => {
      teamGroupsUnsubscribe[group]()
      delete teamGroupsUnsubscribe[group]
    })
  },
  async subscribeCurrentTeamMembers (context: TeamContext, teamId: string) {
    const { commit } = teamModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: `teammembers_${teamId}`,
      query: studio.db.collection(CollectionNames.PROFILES).where(`teams.${teamId}`, '==', true),
      callback: commit.setTeamMembers
    })
    result.logIfError('Subscribing current Team members')
  },
  unsubscribeCurrentTeamMembers (context: TeamContext, teamId: string) {
    const { commit } = teamModuleActionContext(context)
    studio.subscriptions.unsubscribe(`teammembers_${teamId}`, commit.clearTeamMembers)
  },
  subscribeCurrentTeam (context: TeamContext, teamId: string) {
    // FIXME, cleanup this function
    const { commit } = teamModuleActionContext(context)
    commit.clearTeamStreams()
    if (teamUnsubscribe[teamId] === undefined) {
      const resultLayouts = studio.db.collection(CollectionNames.LAYOUTS).where('team_id', '==', teamId).onSnapshot((docs) => {
        const layouts: Layout[] = []
        for (const layout of Object.values(docs)) {
          layout.transition = normalizeTransition(layout.transition)
          layouts.push(layout)
        }
        commit.setTeamLayouts(layouts)
      })
      resultLayouts.logIfError(`error onSnapshot layouts ${teamId}`)
      if (resultLayouts.isSuccess) teamLayoutUnsubscribe[teamId] = resultLayouts.value

      const resultGroups = studio.db.collection(CollectionNames.TEAM_GROUPS).where('team_id', '==', teamId).onSnapshot((docs) => {
        commit.setTeamGroups(Object.values(docs))
      })
      resultGroups.logIfError(`error onSnapshot team groups ${teamId}`)
      if (resultGroups.isSuccess) teamGroupsUnsubscribe[teamId] = resultGroups.value

      const resultTeam = studio.db.collection(CollectionNames.TEAMS).doc(teamId).onSnapshot((team) => {
        if (team.default_layout) commit.setTeamDefaultLayout(team.default_layout)
      })
      resultTeam.logIfError(`error subscribeCurrentTeam ${teamId}`)
      if (resultTeam.isSuccess) teamUnsubscribe[teamId] = resultTeam.value
    }
  },
  async subscribeTeamCastProperties (context: TeamContext) {
    const { commit } = teamModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'castProperties',
      query: studio.db.collection(CollectionNames.CAST_PROPERTIES),
      callback: commit.setCastProperties
    })
    result.logIfError('subscribeCastProperties')
  },
  async unsubscribeTeamCastProperties (_context: TeamContext) {
    studio.subscriptions.unsubscribe('castProperties')
  },
  async getDefaultStreams (context: TeamContext): Promise<ResultVoid<'database'>> {
    const { commit } = teamModuleActionContext(context)
    const result = await studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.DEFAULT_STREAMS).get()
    if (result.isFailure) return result.convert()
    const streams: DefaultStream[] = []
    for (const stream of result.value.streams ?? []) {
      streams.push(new DefaultStream(stream))
    }
    commit.setDefaultStreams(streams)
    return Result.ok()
  },
  unsubscribeWarningMessages (_context: TeamContext) {
    studio.subscriptions.unsubscribeFB('warningmessages')
  },
  async subscribeWarningMessages (context: TeamContext) {
    const { commit } = teamModuleActionContext(context)
    const snapper: FirestoreDocSnapper = {
      doc: fb.db.collection(CollectionNames.ADMIN).doc('updates'),
      mutation: commit.setWarningMessages,
      type: FirestoreSnapperType.Document
    }
    await studio.subscriptions.subscribeFB('warningmessages', snapper)
  },
  addWarningMessage (_context: TeamContext, message: WarningMessage) {
    const key: string = '' + (new Date()).getTime() / 1000
    fb.db.collection(CollectionNames.ADMIN).doc('updates').set({ [key]: message.toDict() }, { merge: true })
  },
  deleteWarningMessage (_context: TeamContext, id: string) {
    fb.db.collection(CollectionNames.ADMIN).doc('updates').set({ [id]: fb.deleteField }, { merge: true })
  },
  unsubscribeTeamStreams (context: TeamContext) {
    const { commit } = teamModuleActionContext(context)
    studio.subscriptions.unsubscribe('teamStreams', () => { commit.setTeamStreamTeamId(null) })
  },
  async subscribeTeamStreams (context: TeamContext, teamId: string) {
    const { commit, state } = teamModuleActionContext(context)
    if (state.streamsTeamId !== null && state.streamsTeamId !== teamId && teamStreamSubscription !== null) {
      const query = studio.db.collection(CollectionNames.STREAMS).where('team_id', '==', teamId)
      const result = teamStreamSubscription.update(query)
      result.logIfError('Could not update team streams subscription')
    }
    commit.setTeamStreamTeamId(teamId)
    const result = await studio.subscriptions.subscribe({
      key: 'teamStreams',
      query: studio.db.collection(CollectionNames.STREAMS).where('team_id', '==', teamId),
      callback: commit.setTeamStreams
    })
    if (result.isSuccess) teamStreamSubscription = result.value
  },
  removeMemberFromTeam (context: TeamContext, member: { teamId: string, profile: UserProfile }) {
    const { dispatch } = teamModuleActionContext(context)
    return new Promise<void>(resolve => {
      fb.db.collection(CollectionNames.TEAMS).doc(member.teamId).get().then(snap => {
        if (snap.exists) {
          const key = 'members.' + member.profile.user_id
          fb.db.collection(CollectionNames.TEAMS).doc(member.teamId).update({ [key]: fb.deleteField }).then(() => {
            resolve()
          }).catch(error => {
            console.error('Remove Member from Team error - update1', error)
          })
          if (member.profile.user_id === undefined) {
            console.error('removeMember from Team: user has no user_id')
            return
          }
          store.dispatch.castTemplates.cleanupTeamTemplates({ id: member.profile.user_id, itemType: 'invited_casters' })
        }
      }).catch(error => {
        console.error('Remove Member from Team error - get1', error)
      })
      fb.db.collection(CollectionNames.PROFILES).doc(member.profile.user_id).get().then(snap => {
        if (snap.exists) {
          const key = 'teams.' + member.teamId
          const updateObj: KeyDict<string|firebase.firestore.FieldValue> = { [key]: fb.deleteField }
          if (snap.data()?.currentTeam === member.teamId ) {
            // If another team is available set that as the new `currentTeam`, otherwise remove the property altogether.
            let newCurrentTeam: string|null = null
            for (const teamId of Object.keys(snap.data()?.teams ?? {})) {
              if (teamId !== member.teamId) {
                newCurrentTeam = teamId
                break
              }
            }
            updateObj.currentTeam = newCurrentTeam ?? fb.deleteField
          }
          fb.db.collection(CollectionNames.PROFILES).doc(member.profile.user_id).update(updateObj).then(() => {
            resolve()
          }).catch(error => {
            console.error('Remove Member from Team error - update2', error)
          })
        }
      }).catch(error => {
        console.error('Remove Member from Team error - get2', error)
      })
      dispatch.subscribeCurrentTeam(member.teamId)
    })
  },
  async addFacebookPages (context: TeamContext, pages: KeyDict<FaceBookPage>) {
    const { state } = teamModuleActionContext(context)
    await fb.db.collection(CollectionNames.TEAMS).doc(state.activeTeam).set({
      facebook: pages
    }, {merge: true })
  },
  async removeFacebookPages (context: TeamContext) {
    const { state } = teamModuleActionContext(context)
    await fb.db.collection(CollectionNames.TEAMS).doc(state.activeTeam).update({
      facebook: fb.deleteField
    })
  },
  async getTeamBrandingByPointerId (context: TeamContext, pointerId: string): Promise<void> {
    const { commit } = teamModuleActionContext(context)
    const getTeamBranding = fb.functions.httpsCallable('getTeamBranding')
    const result = await getTeamBranding({ pointerId })
    if (result.data.success) {
      const branding = new TeamBranding(result.data.branding)
      commit.setTempTeamBranding(branding)
    } else {
      console.error(result.data.status, result.data.reason)
    }
  },
  async updateTeamBranding (context: TeamContext, branding: TeamBranding): Promise<void> {
    const { state } = teamModuleActionContext(context)
    const result = await studio.db.collection(CollectionNames.TEAMS).doc(state.activeTeam).set({ branding }, MergeOption.MERGE)
    result.logIfError('Failed to update team branding')
  },
  async addTeamGroup (_context: TeamContext, params : { group: TeamGroup, userIds: string[] }): Promise<void> {
    const doc_ref = await fb.db.collection('team_groups').add(params.group)
    const groupId = doc_ref.id
    await store.dispatch.user.assignTeamGroupToUsers({ userIds: params.userIds, groupId: groupId })
  },
  async editTeamGroup (_context: TeamContext, params : { group: TeamGroup, userIds: string[] }) {
    if (params.group.id) {
      await fb.db.collection('team_groups').doc(params.group.id).set(params.group)
      await store.dispatch.user.assignTeamGroupToUsers({ userIds: params.userIds, groupId: params.group.id })
    }
  },
  async removeTeamGroup (context: TeamContext, groupId: string) {
    const { state } = teamModuleActionContext(context)
    try {
      //Remove reference to group from all its members
      const batch = fb.db.batch()
      const usersSnap = await fb.db.collection('profiles').where('teamGroups.' + state.activeTeam, 'array-contains', groupId).get()
      usersSnap.docs.forEach(user => {
          batch.update(user.ref, { ['teamGroups.' + state.activeTeam]: fb.arrayRemoveField(groupId)})
        })
      //Remove reference to group from all casts it was assigned to
      const castsSnap = await fb.db.collection('casts').where('team_id', '==', state.activeTeam).where('teamGroups', 'array-contains', groupId).get()
      castsSnap.docs.forEach(cast => {
        batch.update(cast.ref, { ['teamGroups']: fb.arrayRemoveField(groupId)})
      })
      const groupDocRef = fb.db.collection('team_groups').doc(groupId)
      batch.delete(groupDocRef)
      batch.commit()
    } catch (error) {
      console.error(`Error removing member group ${groupId}.`, error)
      throw error; // this will end up in the 'growl' for the user. TODO: maybe reword the message?
    }
  },
  async createTeamStream (_context: TeamContext, payload: { stream: Stream, team: Partial<Team> }): Promise<Result<string, 'unknown'|'invalid params'>> {
    const teamId = payload.team.id
    if (teamId === undefined) return Result.fail('invalid params', 'team::createTeamStream: Team requires an id')
    try {
      const result = await fb.db.runTransaction(async (transaction) => {
        const result = createTeamStream(transaction, fb.db, payload.stream, teamId)
        if (result.isFailure) return Promise.reject(result.message)
        return result.value
      })
      return Result.success(result)
    } catch (error) {
      return Result.fromUnknownError('invalid params', error)
    }
  },
  async updateTeamStream (_context: TeamContext, stream: Stream) {
    const streamdoc: Stream = { srcurl: stream.srcurl, type: stream.type, name: stream.name, deleted: false }
    if (stream.type === StreamType.Output) {
      if (stream.reencode) {
        streamdoc.reencode = stream.reencode
        streamdoc.framerate = stream.framerate
      } else {
        streamdoc.reencode = false
      }
      if (stream.rate !== undefined) {
        streamdoc.rate = stream.rate
      }
    }
    if (streamdoc.type === StreamType.Broadcast) {
      streamdoc.leftrightsplit = stream.leftrightsplit
      if (stream.auth_info !== undefined) {
        streamdoc.auth_info = stream.auth_info
      }
    }
    if (stream.srt_info !== undefined) {
      const srtInfo = makeSrtInfo(stream.srt_info)
      streamdoc.srt_info = srtInfo
    }
    if (stream.ingest_region) streamdoc.ingest_region = stream.ingest_region
    if (stream.protocol) streamdoc.protocol = stream.protocol
    if (stream.nimble_server) streamdoc.nimble_server = stream.nimble_server
    if (stream.nimble_server_old) streamdoc.nimble_server_old = stream.nimble_server_old
    if (stream.nimble_configured !== undefined) streamdoc.nimble_configured = stream.nimble_configured
    if (stream.nimble_config_time !== undefined) streamdoc.nimble_config_time = stream.nimble_config_time
    if (stream.videopid) streamdoc.videopid = stream.videopid
    if (stream.nimble_params !== undefined) streamdoc.nimble_params = stream.nimble_params
    if (stream.mixer_params !== undefined) streamdoc.mixer_params = stream.mixer_params
    if (stream.nimble_server) streamdoc.nimble_server = stream.nimble_server
    if (stream.audioTracks) streamdoc.audioTracks = stream.audioTracks
    if (stream.tracks) streamdoc.tracks = stream.tracks
    if (stream.nimble_stream_url) streamdoc.nimble_stream_url = stream.nimble_stream_url
    if (stream.provider) streamdoc.provider = stream.provider
    if (stream.audioOnly !== undefined) streamdoc.audioOnly = stream.audioOnly
    if (stream.srt_state) streamdoc.srt_state = stream.srt_state
    if (stream.srt_state_override) streamdoc.srt_state_override = stream.srt_state_override
    if (stream.resolution) streamdoc.resolution = stream.resolution
    if (stream.delay !== undefined) streamdoc.delay = stream.delay
    try {
      await fb.db.collection(CollectionNames.STREAMS).doc(stream.id).set(streamdoc, { merge: true })
      const doc = (await fb.db.collection(CollectionNames.STREAMS).doc(stream.id).get()).data()
      const fieldsToDelete: KeyDict<firebase.firestore.FieldValue> = {}
      if (doc) {
        if (doc.srt_info !== undefined && streamdoc.srt_info === undefined) {
          fieldsToDelete.srt_info = fb.deleteField
        }
        if (doc.auth_info !== undefined && streamdoc.auth_info === undefined) {
          fieldsToDelete.auth_info = fb.deleteField
        }
        if (doc.rate !== undefined && streamdoc.rate === undefined) {
          fieldsToDelete.rate = fb.deleteField
        }
        if (doc.delay !== undefined && streamdoc.delay === undefined) fieldsToDelete.delay = fb.deleteField
        if (doc.rate?.fps && streamdoc.rate?.fps === undefined) {
          await fb.db.collection(CollectionNames.STREAMS).doc(stream.id).update({
            'rate.fps': fb.deleteField
          })
        }
      }
      if (Object.keys(fieldsToDelete).length > 0) {
        await fb.db.collection(CollectionNames.STREAMS).doc(stream.id).update(fieldsToDelete)
      }
    } catch (error) {
      console.error('Error updating stream:', error)
    }
  },
  deleteTeamStream (_context: TeamContext, stream: Stream) {
    console.log('deleteTeamStream', stream, stream.type)
    return new Promise<void>(resolve => {
      fb.db.collection(CollectionNames.STREAMS).doc(stream.id).set({ deleted: true, deleteTime: new Date() }, { merge: true }).then(() => {
        const itemType = stream.type === 'broadcast' ? 'input_streams' : 'custom_rtmp_destinations'
        if (stream.id !== undefined) {
          store.dispatch.castTemplates.cleanupTeamTemplates({ id: stream.id, itemType: itemType })
        }
        resolve()
      }).catch(error => {
        console.log('Error deleting stream: ', error)
      })
    })
  },
  async createTeam (_context: TeamContext, team: CreateTeamParams) {
    return createNewTeam(fb.db, team, window.location.origin)
  },
  async updateTeam (_context: TeamContext, team: DeepPartial<Team> & { id: string }):
          Promise<ResultVoid<'database'|'pointer'|'store'>> {
    const result = await studio.db.collection(CollectionNames.TEAMS).doc(team.id).set(team, MergeOption.MERGE)
    if (result.isFailure) return result
    if (team.version !== undefined) {
      const castUpdateResult = await store.dispatch.casts.updateScheduledCastsWithTeamVersion({
        id: team.id,
        version: team.version
      })
      if (castUpdateResult.isFailure) return castUpdateResult
    }
    return Result.ok()
  },
  async deleteTeam (_context: TeamContext, team: { id: string }) {
    const result = await studio.db.collection(CollectionNames.TEAMS)
                                  .doc(team.id).set({ deleted: true }, MergeOption.MERGE)
    result.logIfError(`Could not delete team with id ${team.id}`)
  },
  async activateTeam (context: TeamContext, params: { id: string }): Promise<ResultVoid<'subscriptions'|'database'>> {
    const { state } = teamModuleActionContext(context)
    const team = state.allTeams[params.id]
    if (team === undefined) {
      return Result.fail('subscriptions', 'Could not reactivate team if not in the subscribed allTeams')
    }
    const release = store.state.admin.release
    if (release === null) {
      return Result.fail('subscriptions', 'Could not reactivate team')
    }
    const updates: DeepPartial<Team> = { deleted: false, expirationDate: null, customerType: TeamCustomerType.Regular }
    if (!Object.keys(release.versions).includes(team.version)) {
      updates.version = release.trialVersion
    }
    return studio.db.collection(CollectionNames.TEAMS).doc(params.id).set(updates, MergeOption.MERGE)
  },
  resetTeam (context: TeamContext) {
    const { commit } = teamModuleActionContext(context)
    commit.resetTeam()
  },
  async recalculateBilling (context: TeamContext) {
    const { state } = teamModuleActionContext(context)
    await fb.db.collection(CollectionNames.ADMIN).doc('billing').set({
      [state.activeTeam]: true
    }, { merge: true })
  },
  async subscribeBilling (context: TeamContext, teamId: undefined|string) {
    const { state, commit } = teamModuleActionContext(context)
    const billingTeamId = teamId ?? state.activeTeam
    const snapper: FirestoreDocSnapper = {
      doc: fb.db.collection(CollectionNames.BILLING_SUMMARIES).doc(billingTeamId),
      mutation: commit.updateBilling,
      type: FirestoreSnapperType.Document
    }
    studio.subscriptions.subscribeFB('billing', snapper)
  },
  async unsubscribeBilling (context: TeamContext) {
    const { commit } = teamModuleActionContext(context)
    studio.subscriptions.unsubscribeFB('billing', commit.removeBilling)
  },
  setCustomTheme (context: TeamContext, customTheme: string|null) {
    const { commit } = teamModuleActionContext(context)
    commit.setCustomTheme(customTheme)
  },
  subscribeTokens (context: TeamContext) {
    const { state, commit } = teamModuleActionContext(context)
    const teamId = state.activeTeam
    const snapper: FirestoreQuerySnapper = {
      query: fb.db.collection(CollectionNames.APITOKENS).where('team_id', '==', teamId),
      mutation: commit.updateAPITokens,
      type: FirestoreSnapperType.Query
    }
    studio.subscriptions.subscribeFB('apitokens', snapper)
  },
  unsubscribeTokens (_context: TeamContext) {
    studio.subscriptions.unsubscribeFB('apitokens')
  },
  async addToken (_context: TeamContext, token: APIToken) {
    const result = await studio.db.collection(CollectionNames.APITOKENS).doc(token.token).set(token, MergeOption.OVERWRITE)
    if (!result.isSuccess) {
      console.error('Error adding token', result.error)
    }
  },
  async updateMemberRole (context: TeamContext, member: { id: string, role: string }) {
    const { state } = teamModuleActionContext(context)
    const result = await studio.db.collection(CollectionNames.TEAMS).doc(state.activeTeam).set({
      members: {
        [member.id]: {
          role: member.role
        }
      }
    }, MergeOption.MERGE)
    result.logIfError('Error updating member role')
  },
  async updateMembersUdpSettings (_context: TeamContext, payload:{ udpEnabled: boolean, team: string } ) {
    const profiles = await studio.db.collection(CollectionNames.PROFILES).where(`teams.${payload.team}`, '==', true).get()
    profiles.logIfError(`Could not update profiles with correct udp settings`)
    const promises: Promise<void>[] = []
    if (profiles.isSuccess) {
      Object.keys(profiles.value).forEach((profileId) => {
        promises.push((async () => {
          const result = await studio.db.collection(CollectionNames.PROFILES).doc(profileId).set({
            'udpEnabled': payload.udpEnabled
          }, MergeOption.MERGE)
          result.logIfError(`Could not update profile ${profileId} with udp setting ${payload.udpEnabled}`)
        })())
      })
    }
    await Promise.all(promises)
  }
}

export default actions
