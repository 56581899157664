import { KeyDict } from '@/types'
import { isSceneContentTransition, SCENE_CONTENT_Z_INDEX, SceneContent } from '@/types/sceneContent'
import { SceneContentType } from '../types'
import { AssetType } from '@/types/assets'
import { Scene } from '@/types/scenes'

export const getHighestContentOrder = (contents: KeyDict<SceneContent>, contentType?: SceneContentType): number => {
  const allOrderValues = Object.values(contents)
    .filter((content) => contentType === undefined ? true : content.contentType === contentType)
    .map((content) => content.order ?? 0)
  return Math.max(...allOrderValues, 0)
}

export const getZIndex = (contentType: SceneContentType, base: number, order: number): number => {
  return SCENE_CONTENT_Z_INDEX[contentType] + base - order
}

export const moveContentUp = (contentId: string, contents: KeyDict<SceneContent>): KeyDict<SceneContent> => {
  const currContent = contents[contentId]
  const prevContent = Object.values(contents)
    .filter((content) => {
      if (content.contentType === SceneContentType.Content && content.type !== currContent.type) return false
      if ([AssetType.Broadcast, AssetType.Playlist].includes(content.type)) return false
      return content.id !== contentId && content.order !== undefined
    })
    .sort((a, b) => a.order < b.order ? -1 : 1)
    .reduce((a, b) => (a.order < b.order && b.order < currContent.order) ? b : a)
  return {
    ...contents,
    [currContent.id]: { ...currContent, order: prevContent.order },
    [prevContent.id]: { ...prevContent, order: currContent.order }
  }
}

export const isTransitionContent = (assetType: AssetType, contentType?: SceneContentType) => {
  if (assetType === AssetType.Stinger) return true
  if (!contentType) return false
  return [SceneContentType.Transition, SceneContentType.GlobalTransition].includes(contentType)
}

export const getContentIdByUserId = (userId: string, scenes: KeyDict<Scene>): string|null => {
  for (const scene of Object.values(scenes)) {
    for (const [contentId, content] of Object.entries(scene.contents)) {
      if (content.user === userId && content.type === AssetType.Caster) {
        return contentId
      }
    }
  }
  return null
}

export const removeGlobalStingerIfLocalStinger = (scene: Scene): Scene => {
  let globalStingerContentId: string|null = null
  let stingerContentCount: number = 0
  for (const [contentId, content] of Object.entries(scene.contents)) {
    if (!isSceneContentTransition(content)) continue
    if (!content.active) continue
    stingerContentCount++
    if (content.contentType === SceneContentType.GlobalTransition) {
      globalStingerContentId = contentId
    }
  }
  if (globalStingerContentId && stingerContentCount > 1) {
    delete scene.contents[globalStingerContentId]
    delete scene.scene_layout.boxes[globalStingerContentId]
  }
  return scene
}
