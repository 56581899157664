import fb from '@/firebase'
import { KeyDict } from '@/types'
import { AssetType } from '@/types/assets'
import { Cast } from '@/modules/casts/classes'
import { CasterCSS, ChatCSS, Layout, LiveChatCSS, SimplecgCSS } from '@/types/layouts'
import { isEmpty } from 'lodash'
import { CallTriage, CasterDeletionReason, InvitedCaster } from '@/types/casts'
import { TwitterStyling } from '@/modules/layouts/classes'
import { UpdateFunction } from '@/modules/base/types'
import { Result, ResultVoid } from 'kiswe-ui'
import { objectDbDiff } from '@/modules/database/utils/objectDbDiff'
import studio, { CollectionNames } from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import firebase from 'firebase/compat/app'
import { consoleLog } from '@/modules/logging/utils'

export const updateCast = async <E extends string>(
  cast: Cast, updateFunction: UpdateFunction<Cast, E>
): Promise<ResultVoid<'database'|'unknown'|E>> => {
  // NOTE: if we ever need to update name, start_date or end_date, we will also have to update the event doc!
  const oldCast = cast.clone()
  const newCast = Result.fromPossibleResult(updateFunction(oldCast))
  if (newCast.isFailure) return newCast.convert()
  const diff = objectDbDiff(cast, newCast.value)
  if (isEmpty(diff)) return Result.ok()
  return studio.db.collection(CollectionNames.CASTS).doc(oldCast.id).set(diff, MergeOption.MERGE)
}

export const updateCastWithTransaction = async <E extends string>(
  transaction: firebase.firestore.Transaction, cast: Cast, updateFunction: UpdateFunction<Cast, E>
): Promise<ResultVoid<'database'|'unknown'|E>> => {
  // NOTE: if we ever need to update name, start_date or end_date, we will also have to update the event doc!
  const oldCast = cast.clone()
  const newCast = Result.fromPossibleResult(updateFunction(oldCast))
  if (newCast.isFailure) return newCast.convert()
  const diff = objectDbDiff(cast, newCast.value)
  if (isEmpty(diff)) return Result.ok()
  const castRef = fb.db.collection(CollectionNames.CASTS).doc(cast.id)
  transaction.set(castRef, diff, { merge: true })
  return Result.ok()
}


export const maxOrderOfType = (cast: Cast, type: AssetType): number => {
  let order = 0
  Object.values(cast.scenes).forEach(scene => {
    if (scene.contents === undefined) return
    Object.values(scene.contents).forEach(content => {
      if (content.type === type && content.order > order) order = content.order
    })
  })
  return order
}

export const isAssetInCast = (cast: Cast, assetId: string): boolean => {
  let found = false
  Object.values(cast.scenes).forEach(scene => {
    if (scene.contents === undefined) return
    found = found || scene.contents[assetId] !== undefined
  })
  return found
}

export const fillMissingCssObjects = (layout: Omit<Layout, 'team_id'>, castId: string, sceneId: string): void => {
  //Populates missing CSS objects with default values to prevent errors at presentation time.
  //These should never be coming up empty, but it apparently happens (CC-3530).
  if (!layout) return //No scene_layout passed in!
  const updates = {} as Layout
  if (!layout.hasOwnProperty('tweetcss')) updates.tweetcss = new TwitterStyling()
  if (!layout.hasOwnProperty('castercss')) updates.castercss = new CasterCSS()
  if (!layout.hasOwnProperty('chatcss')) updates.chatcss = new ChatCSS()
  if (!layout.hasOwnProperty('simplecgcss')) updates.simplecgcss = {} as KeyDict<SimplecgCSS>
  if (!layout.hasOwnProperty('livechatcss')) updates.livechatcss = new LiveChatCSS()

  if (!isEmpty(updates)) {
    //Must use JSON conversion to remove custom classes,
    //since Firestore only accepts plain Objects,
    //and we want to retain use of TS typings
    const updatesPayload = JSON.parse(JSON.stringify(updates))

    //Update actual layout in db if there were any missing
    if (layout.id) {
      fb.db
        .collection('layouts')
        .doc(layout.id)
        .set(updatesPayload, { merge: true })
        .catch((error) => console.log(`fillMissingCssObjects update layout: ${error}`))
    }
    //Backfill empty objects for loaded scene, as above doesn't trigger updates for loaded scene
    if (castId && sceneId) {
      fb.db.collection('casts')
        .doc(castId)
        .set(
        {
          scenes: {
            [sceneId]: {
              scene_layout: updatesPayload
            }
          }
        },
          { merge: true })
        .catch((error) => console.log(`fillMissingCssObjects update cast: ${error}`))
    }
  }
}

export const removeCasterFromInvitedCasters = (invitedCasters: KeyDict<InvitedCaster>, casterId: string): KeyDict<InvitedCaster> => {
  const currentCaster = invitedCasters[casterId]
  currentCaster.deleted = true
  currentCaster.deleted_reason = CasterDeletionReason.ModeratorRemoved
  currentCaster.audioTracks = {}
  return { ...invitedCasters, [casterId]: currentCaster }
}

const isNewTriageAllowed = (curTriage: CallTriage, newTriage: CallTriage): boolean => {
  if ([CallTriage.Accepted, CallTriage.Rejected].includes(curTriage)) return false
  if (newTriage === CallTriage.InModeration && curTriage !== CallTriage.Unverified) return false
  return true
}

export const setGuestTriageTransaction = async (castId: string, casterId: string, newTriage: CallTriage,
  moderatorUserId: string): Promise<ResultVoid<'guest_triage'>> =>
{
  const newModeratorId = (newTriage === CallTriage.InModeration) ? moderatorUserId : null
  const castRef = fb.db.collection(CollectionNames.CASTS).doc(castId)
  try {
    const result = await fb.db.runTransaction(async (transaction: firebase.firestore.Transaction) => {
      const result = Cast.load((await transaction.get(castRef)).data())
      if (result.isFailure) return result
      const castDoc = result.value
      const curTriage = castDoc.invited_casters[casterId]?.triage
      const curModeratorId = castDoc.invited_casters[casterId]?.moderator
      consoleLog('Trying setGuestTriageTransaction', { castId, casterId, curTriage, newTriage, curModeratorId, newModeratorId })
      if (curTriage === newTriage && newModeratorId === curModeratorId) {
        consoleLog('Completed transaction. Nothing to do', { castId, casterId, curTriage, newTriage, moderatorUserId })
        return Result.ok()
      }
      if (!isNewTriageAllowed(curTriage, newTriage)) {
        return Result.fail('guest_triage', `Unexpected triage change from ${curTriage} to ${newTriage}`)
      }
      if (castDoc.invited_casters[casterId]?.deleted) {
        return Result.fail('guest_triage', `Cannot update triage state for deleted guest ${casterId}`)
      }
      if (newTriage === CallTriage.InModeration) {
        if (curModeratorId && curModeratorId !== moderatorUserId) {
          return Result.fail('guest_triage', `Guest already being moderated by user: ${curModeratorId}`)
        }
      } else {
        if (curModeratorId !== moderatorUserId) {
          return Result.fail('guest_triage', 'Cannot change triage status. Current user not moderator for guest')
        }
      }
      return await updateCastWithTransaction(transaction, castDoc, (cast) =>
        cast.setCasterTriage(casterId, newTriage).setCasterModerator(casterId, newModeratorId))
    })
    if (result.isFailure) {
      result.logIfError(`Could not update guest triage. ${ { castId, casterId, newTriage } }`)
      return Result.fail('guest_triage', `Could not update guest triage ${JSON.stringify({ castId, casterId, newTriage })}`)
    }
    consoleLog('Completed transaction', { castId, casterId, newTriage, moderatorUserId })
    return Result.ok()
  } catch (error) {
    console.error('guest_triage', error)
    return Result.fail('guest_triage', `Could not update guest triage ${JSON.stringify({ castId, casterId, newTriage })}`)
  }
}
