<!--
  This component will hold the actual connection with the camera and mic
  and will be an invisible component in the root
-->

<template>
  <div class="usermedia-connector">
    <CasterCommunication
      v-if="enableCasterCommunication && !isJanus"
      :streamToPlay="streamDocId"
      :onAir="isCasting"
      :localStream="localCameraStream"
      :videoBitrate="requiredVideoBitrate"
      :audioBitrate="requiredAudioBitrate"
      :wrtcServer="selectedWrtcServer"
      :videoCodec="videoCodec"
      :udpEnabled="useUdp"
      @onNetworkStats="onUpdateNetworkStats"
      @wsConnectionClosed="wsConnectionClosed"
    />
    <CasterCommunication
      v-if="enableCasterCommunication && makeLowrateVariant && !isJanus"
      :streamToPlay="streamDocId + '_lowratecaster'"
      :onAir="isCasting"
      :localStream="localCameraStream"
      :videoBitrate="lowrateVideoBitrate"
      :audioBitrate="lowrateAudioBitrate"
      :wrtcServer="selectedWrtcServer"
      :videoCodec="videoCodec"
      :udpEnabled="useUdp"
      @wsConnectionClosed="wsConnectionClosed"
    />
    <JanusCasterCommunication
      v-if="enableCasterCommunication && isJanus"
      :streamToPlay="streamDocId"
      :onAir="isCasting"
      :localStream="localCameraStream"
      :videoBitrate="requiredVideoBitrate"
      :audioBitrate="requiredAudioBitrate"
      :wrtcServer="selectedWrtcServer"
      :videoCodec="videoCodec"
      :udpEnabled="useUdp"
      :enableBandwidthCheck="enableBandwidthCheck"
      @onNetworkStats="onUpdateNetworkStats"
      @wsConnectionClosed="wsConnectionClosed"
    />
    <JanusCasterCommunication
      v-if="enableCasterCommunication && makeLowrateVariant && isJanus"
      :streamToPlay="streamDocId + '_lowratecaster'"
      :onAir="isCasting"
      :localStream="localCameraStream"
      :videoBitrate="lowrateVideoBitrate"
      :audioBitrate="lowrateAudioBitrate"
      :wrtcServer="selectedWrtcServer"
      :videoCodec="videoCodec"
      :udpEnabled="useUdp"
      @wsConnectionClosed="wsConnectionClosed"
    />
    <JanusCasterCommunication
      v-if="enableCasterCommunication && enableStationChinaProxy && isJanus"
      :streamToPlay="streamDocId"
      :onAir="isCasting"
      :localStream="localCameraStream"
      :videoBitrate="requiredVideoBitrate"
      :audioBitrate="requiredVideoBitrate"
      :wrtcServer="chinaWrtcServer"
      :videoCodec="videoCodec"
      :udpEnabled="useUdp"
      @wsConnectionClosed="wsConnectionClosed"
    />
    <JanusCasterCommunication
      v-if="enableCasterCommunication && makeLowrateVariant && enableStationChinaProxy && isJanus"
      :streamToPlay="streamDocId + '_lowratecaster'"
      :onAir="isCasting"
      :localStream="localCameraStream"
      :videoBitrate="lowrateVideoBitrate"
      :audioBitrate="lowrateAudioBitrate"
      :wrtcServer="chinaWrtcServer"
      :videoCodec="videoCodec"
      :udpEnabled="useUdp"
      @wsConnectionClosed="wsConnectionClosed"
    />
  </div>
</template>

<script lang="ts">
import { LogLevel } from '@/types/logs'
import { LogMessage } from '@/store/modules/logs'
import { UserProfile } from '@/types/users'
import { DeviceList, MediaDevice, Resolution, UserMediaPermissions } from '@/types/usermedia'
import * as NetworkStatCalculator from '@/modules/status/classes/NetworkScoreCalculator'
import { VideoObjectNetworkStats } from '@/classes/VideoObjectNetworkStats'
import { UserNetworkStats } from '@/types/networkstats'
import { StreamType } from '@/types/streams'
import { debounce } from 'lodash'
import CasterCommunication from '@/components/CasterCommunication'
import JanusCasterCommunication from '@/components/JanusCasterCommunication'
import { Optional } from '@/types'
import { computed, defineComponent } from 'vue'
import { useCurrentCast, useCurrentInvitedCaster } from '@/modules/casts/compositions'
import { useCodecs } from '@/modules/usermedia/compositions'
import { Feature, hasFeature } from '@/featureFlags/featureFlags'
import env from '@/env'
import store from '@/store'

export default defineComponent({
  name: 'UserMediaConnector',
  props: {
    devices: {
      type: Array as () => string[],
      required: false,
      default: () => [MediaDevice.Camera, MediaDevice.Microphone]
    }
  },
  setup () {
    const { currentCast } = useCurrentCast()
    const { isRejected } = useCurrentInvitedCaster(currentCast)
    const enableBandwidthCheck = computed(() => {
      return !store.state.user.profile?.anonymous && !store.state.usermedia.isModerator
    })
    return {
      currentCast,
      enableBandwidthCheck,
      isRejected,
      ...useCodecs()
    }
  },
  data: () => ({
    error: '',
    mediaDevices: new DeviceList(),
    audioTrack: null as MediaStreamTrack|null, // TODO => cache to seamlessly update stream constraints/devices
    videoTrack: null as MediaStreamTrack|null, // TODO => cache to seamlessly update stream constraints/devices
    statsLogCounter: 0,
    checkedResolution: 0,
    currentRate: 0,
    supportedResolutions: [] as Resolution[],
    defaultVideoBitRate: 320,
    lowrateAudioBitrate: 32,
  }),
  mounted () {
    if (!navigator.mediaDevices?.getUserMedia) {
      this.addLog({
        message: 'Client browser does not support getUserMedia',
        level: LogLevel.Error
      })
    }

    // Listen to MediaDevices API ondevicechange event
    // see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/ondevicechange
    navigator.mediaDevices.addEventListener('devicechange', this.debounceGetDevices)
  },
  beforeUnmount () {
    navigator.mediaDevices.removeEventListener('devicechange', this.debounceGetDevices)
  },
  computed: {
    chinaWrtcServer () : string | null {
      return this.$store.direct.state.usermedia.wrtcServerChina
    },
    enableStationChinaProxy (): boolean {
      return this.$store.direct.state.usermedia.forceChinaProxy
    },
    isJanus (): boolean {
      return hasFeature(Feature.JANUS) ?? false
    },
    selectedWrtcServer (): string|null {
      if (env.chinaBuild) return this.$store.direct.state.usermedia.wrtcServerChinaProxy
      return this.$store.direct.state.usermedia.wrtcServer
    },
    currentUserId (): string|null {
      return this.$store.direct.state.user.id
    },
    localCameraStream (): MediaStream|null {
      return this.$store.direct.state.usermedia.localCameraStream
    },
    hasEchoSuppression (): boolean {
      return this.$store.direct.getters.user.hasEchoSuppression
    },
    hasNoiseSuppression (): boolean {
      return this.$store.direct.getters.user.hasNoiseSuppression
    },
    lowrateVideoBitrate (): number {
      return this.defaultVideoBitRate
    },
    userMediaPermissions: {
      get (): UserMediaPermissions {
        return this.$store.direct.state.usermedia.permissions
      },
      set (permissions: UserMediaPermissions) {
        this.$store.direct.dispatch.usermedia.setPermissions(permissions)
      }
    },
    streamDocId: {
      get (): string|null {
        return this.$store.direct.state.usermedia.streamDocId
      },
      set (id: string|null) {
        this.$store.direct.dispatch.usermedia.setStreamDocId(id)
      }
    },
    isCasting: {
      get (): boolean {
        return this.$store.direct.state.events.casting
      },
      set (isCasting: boolean) {
        this.$store.direct.dispatch.events.setCasting(isCasting)
      }
    },
    userProfile (): UserProfile|null {
      return this.$store.direct.state.user.profile
    },
    requiredAudioBitrate (): number|undefined {
      return this.userProfile?.encoderSettings?.audioBitrate
    },
    requiredVideoBitrate (): number|undefined {
      return this.userProfile?.encoderSettings?.videoBitrate
    },
    requiredVideoWidth (): number|undefined {
      return this.userProfile?.encoderSettings?.width
    },
    requiredVideoHeight (): number|undefined {
      return this.userProfile?.encoderSettings?.height
    },
    useUdp (): boolean {
      return this.$store.direct.getters.user.useUdp
    },
    makeLowrateVariant (): boolean {
      return this.requiredVideoBitrate !== undefined && this.requiredVideoBitrate >= (this.defaultVideoBitRate * 2)
    },
    isModerator (): boolean {
      return this.$store.direct.state.usermedia.isModerator
    },
    streamType (): StreamType|null {
      return this.$store.direct.getters.usermedia.publishType
    },
    enableCasterCommunication (): boolean {
      return !!this.streamDocId && !!this.localCameraStream && !!this.selectedWrtcServer && !this.isRejected
    },
    selectedAudioDevice (): string|null {
      return this.userProfile?.selectedAudioDevice ?? null
    },
    selectedVideoDevice (): string|null {
      return this.userProfile?.selectedVideoDevice ?? null
    },
    needRepublish (): boolean {
      return this.$store.direct.state.user.needRepublish
    }
  },
  watch: {
    selectedAudioDevice () {
      this.restartStream()
    },
    selectedVideoDevice () {
      this.restartStream()
    },
    needRepublish (newVal: boolean) {
      if (!newVal) return
      this.restartStream()
    }
  },
  methods: {
    killLocalCameraStream () {
      this.$store.direct.dispatch.usermedia.killLocalCameraStream()
    },
    setTalkbackStatus (talkback: boolean) {
      this.$store.direct.dispatch.usermedia.setTalkbackStatus(talkback)
    },
    restartStream () {
      if (this.localCameraStream === null) return
      this.$store.direct.dispatch.usermedia.restartStream()
    },
    debounceGetDevices: debounce(function (this: any): void {
      // needs debounce because is triggered more than once even for one device (eg headset with mic = input + output)
      // debounce of eg 100ms = all devices connected in this time will trigger a single update
      this.$store.direct.dispatch.usermedia.getDevices()
    }, 100),
    // Logging
    addLog ({ message, data = {}, level = LogLevel.Info, error = null} : { message: string, data?: any, level?: LogLevel, error?: any }): void {
      let lvl = level
      if (!lvl) {
        if (error) lvl = LogLevel.Error
        else lvl = LogLevel.Info
      }

      const payload: LogMessage = {
        message: (error) ? message + ' | ' + error : message,
        cast: this.currentCast?.id ?? undefined,
        data: data,
        level: lvl
      }

      if (!payload.message) return
      this.$store.direct.dispatch.logs.addLog(payload)

      if (error) console.error(message, error, data)
      else console.log(message)
    },
    wsConnectionClosed () {
      this.addLog({ message: 'wsConnectionClosed' })
    },
    onUpdateNetworkStats (stats: VideoObjectNetworkStats) {
      const profileStats: Partial<UserNetworkStats> = {
        upload: {
          jitter: this.cleanStats(stats.jitter),
          latency: this.cleanStats(stats.latency),
          score: this.cleanStats(stats.score),
          bandwidth: this.cleanStats(stats.upload),
          region: this.userProfile?.transcodingregion ?? 'unknown'
        },
        download: {
          bandwidth: this.cleanStats(stats.download),
        }
      }
      const myDownloadScore = this.$store.direct.getters.networkStats.myNetworkStats?.download
      if (myDownloadScore !== undefined && myDownloadScore.jitter === undefined) {
        if (stats.download !== undefined && profileStats.download !== undefined) {
          profileStats.download.score = NetworkStatCalculator.getScoreBasedOnBandwidth(stats.download)
        }
      }
      if (stats.encoder !== undefined) {
        profileStats.encoder = {
          adaptation: stats.encoder.adaptation ?? 'none',
          cpu_usage: this.cleanStats(stats.encoder.cpu_usage),
          fps_in: this.cleanStats(stats.encoder.fps_in),
          fps_out: this.cleanStats(stats.encoder.fps_out),
          width: this.cleanStats(stats.encoder.width),
          height: this.cleanStats(stats.encoder.height),
          video_bitrate: this.cleanStats(stats.encoder.video_bitrate),
          audio_bitrate: this.cleanStats(stats.encoder.audio_bitrate)
        }
      }
      this.$store.direct.dispatch.user.updateStats(profileStats)

      const userId = this.userProfile?.assignedId ?? this.currentUserId
      const castId = this.currentCast?.id
      if (!userId || !castId) return
      this.$store.direct.dispatch.logs.addLog({
        message: 'MetricReport: CasterNetwork',
        cast: castId,
        level: 'info',
        data: {
          jitter: stats.jitter,
          latency: stats.latency,
        },
        k360event: this.currentCast?.k360_event_id ?? undefined,
        logger_name: 'metrics',
        caster: userId
      })
      if (this.statsLogCounter % 5 === 0) {
        this.$store.direct.dispatch.logs.addLog({
          message: `Bandwidth info of ${ userId } (score = ${ stats.score })`,
          level: 'info',
          data: { stats: JSON.parse(JSON.stringify(stats)) }
        })
      }
      this.statsLogCounter += 1
    },
    cleanStats (stat: Optional<number>): number {
      if (stat === undefined) return 0
      else return stat
    },
  },
  components: {
    CasterCommunication,
    JanusCasterCommunication
  }
})
</script>
