import { computed, ComputedRef } from 'vue'
import { Cast } from '@/modules/casts/classes'
import { SourceStream, StreamStatusCode, StreamType } from '@/types/streams'
import { useNetworkStats, useUserCastStatus } from '.'
import { KeyDict } from '@/types'
import { SystemStatus } from '@/types/ops'
import { CastType } from '@/types/casts'
import { CLIENT_STATS_MULTIPLIER } from '@/types/networkstats'
import { useCurrentCast } from '@/modules/casts/compositions'
import { WRTCServerType } from '@/types/admin'
import { useCrewTalkback } from '@/modules/talkback/compositions'

const getCasterSourceStream = (casterId: string, sourceStreams: KeyDict<SourceStream>|undefined) => {
  if (!sourceStreams) return null

  let sourceStream: SourceStream|null = null
  let bestStartTime = 0
  for (const stream of Object.values(sourceStreams)) {
    if (stream.type === StreamType.Caster && stream.user_id === casterId) {
      if (stream.start_time !== undefined && bestStartTime < stream.start_time) {
        sourceStream = stream
        bestStartTime = stream.start_time
      }
    }
  }
  return sourceStream
}

const useCasterCastStatus = (casterId: ComputedRef<string>, cast: ComputedRef<Cast|null>) => {
  const { stats } = useNetworkStats(casterId.value)
  const { currentCast } = useCurrentCast()
  const isJanus = computed(() => cast.value?.wrtcServerType === WRTCServerType.JANUS)
  const isCrew = computed(() => !currentCast.value?.invited_casters[casterId.value]
    || currentCast.value?.invited_casters[casterId.value].deleted)

  const { userCastStatus } = useUserCastStatus(casterId.value, cast.value?.id, !isCrew.value, isJanus.value)
  const { isCrewConnected } = useCrewTalkback(casterId)

  const videoBitrate = computed(() => {
    if (stats?.value?.encoder === undefined) return 0
    return Math.floor(stats.value.encoder.video_bitrate / 102.4) / 10
  })
  const audioBitrate = computed(() => {
    if (stats?.value?.encoder === undefined) return 0
    return Math.floor(stats.value.encoder.audio_bitrate / 102.4) / 10
  })
  const totalBitrate = computed(() => {
    if (stats?.value?.encoder === undefined) return 0
    return Math.floor((stats.value.encoder.audio_bitrate + stats.value.encoder.video_bitrate) / 102.4) / 10
  })

  const casterCpuUsage = computed(() => (Math.round((stats.value?.encoder?.cpu_usage ?? 0) * CLIENT_STATS_MULTIPLIER)))
  const casterStatusCode = computed(() => {
    if (isCrew.value && isCrewConnected.value) return StreamStatusCode.CrewConnected
    return userCastStatus.value?.statusCode ?? StreamStatusCode.None
  })
  const casterSourceStream = computed(() => getCasterSourceStream(casterId.value, cast.value?.source_streams))
  const isCasting = computed(() => {
    return casterSourceStream.value?.ngcvp_status === SystemStatus.OK || cast.value?.castType === CastType.FanRoom
  })

  const isUserPresent = computed(() => {
    const onlineSessions = cast.value?.online_sessions ?? {}
    const onlineSessionIds = onlineSessions[casterId.value] ?? []
    return onlineSessionIds.length > 0
  })

  const generalNetworkScore = computed(() => {
    if (!isUserPresent.value) return -1
    const uploadScore = stats.value?.upload?.score ?? 0
    const downloadScore = stats.value?.download?.score ?? 0
    let cpuScore = 5
    if (casterCpuUsage.value >= 90) cpuScore = 1
    else if (casterCpuUsage.value >= 75) cpuScore = 2
    const allScores = [downloadScore, cpuScore]
    if (isCasting.value) allScores.push(uploadScore)
    return Math.min(...allScores)
  })

  return {
    casterCpuUsage,
    casterCastStatus: userCastStatus,
    casterSourceStream,
    casterStatusCode,
    isCasting,
    networkStats: stats,
    bitrates: {
      videoBitrate,
      audioBitrate,
      totalBitrate
    },
    generalNetworkScore,
    isUserPresent
  }
}

export default useCasterCastStatus
