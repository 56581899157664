import { Result } from 'kiswe-ui'
import { Constructor, Mixin, Flatten } from './base'

export function hasLoader<TBase extends Constructor> (Base: TBase) {
  return class Loader extends Base {
    constructor (...args: any[]) {
      super(...args)
    }

    // @ts-ignore
    static load (data: unknown): Result<Flatten<InstanceType<typeof this>>, 'invalid params'|'unknown'> {
      try {
        // @ts-ignore
        return Result.success(new Base(data) as Flatten<InstanceType<typeof this>>)
      } catch (error) {
        return Result.fromUnknownError('invalid params', error)
      }
    }
  }
}

export type Loadable = Mixin<typeof hasLoader>
