import { computed, onMounted, watch } from 'vue'
import store from '@/store'
import { getMediaOptions } from '@/modules/usermedia/utils/usermedia'
import { MediaDevice } from '@/types/usermedia'

const permissions = computed(() => store.state.usermedia.permissions)
const localCameraStream = computed(() => store.state.usermedia.localCameraStream)

const tryInitLocalMediaStream = async () => {
  if (localCameraStream.value) return
  try {
    await store.dispatch.usermedia.initLocalMediaStream([MediaDevice.Camera, MediaDevice.Microphone])
  } catch (error) {
    console.error('Failed to init LocalMediaStream', error)
    return
  }
}

const stopCasting = async () => {
  await store.dispatch.usermedia.stopCasting()
}

const restartLocalMediaStream = async () => {
  try {
    await store.dispatch.usermedia.restartStream()
  } catch (error) {
    console.error('Failed to restart LocalMediaStream', error)
    return
  }
}

const useUserMedia = (showRequestPermissions: boolean = true) => {
  const deviceOptions = computed(() => {
    const devices = store.state.usermedia.deviceList
    return { audio: getMediaOptions(devices?.audio.input), video: getMediaOptions(devices?.video.input) }
  })

  onMounted(async () => {
    if (showRequestPermissions) {
      if (permissions.value.video && permissions.value.audio) return
      await store.dispatch.usermedia.requestPermissions()
    }
  })

  watch(permissions, async (newVal) => {
    if (!newVal.audio || !newVal.video) return
    await store.dispatch.usermedia.getDevices()
  }, { immediate: true })

  return {
    deviceOptions,
    localCameraStream,
    restartLocalMediaStream,
    stopCasting,
    tryInitLocalMediaStream
  }
}

export default useUserMedia
