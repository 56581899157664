import { Mixin, StudioBaseMixin } from './base'


export function hasNoCustomOutputs<TBase extends StudioBaseMixin> (Base: TBase) {
  return class NoCustomOutputsClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
    }

    custom_rtmp_destinations: null = null
  }
}

export type HasNoCustomOutputs = Mixin<typeof hasNoCustomOutputs>
