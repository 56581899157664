
import { VideoObjectNetworkStats } from './VideoObjectNetworkStats'
import { ICECandidate, StreamInfo } from '@/types/webrtc'
import { isIOS } from 'mobile-device-detect'

export interface StreamToPlay {
  url: string
}

export interface VideoObjectConfig {
  id: string,
  name: string,
  streamToPlay: StreamToPlay,
  logNetwork: boolean,
  killStream?: VideoObjectKillCallback,
  restartStream?: VideoObjectRestartCallback,
  callback?: VideoObjectOnStartCallback,
  streamid?: string,
  udpEnabled?: boolean,
  srcObject?: MediaStream|null
  chinaSupport: boolean
}

export const CHINA_INDEX_ICE_CANDIDATES = 6

class VideoObject {
  id: string = ''
  name: string = ''
  url: string = ''
  streamid: string = ''
  logNetwork: boolean = false
  streamToPlay: StreamToPlay = { url: '' }
  wsURL?: string
  streamInfo?: StreamInfo
  peerConnection: RTCPeerConnection|null = null
  lastStatsTs: number = 0
  lastBytesReceivedTs: number = Date.now()
  lastBytesReceived: number = 0
  lastRestartAttemptTs: number = Date.now()
  wsConnection: WebSocket|null = null
  srcObject?: MediaStream|null
  dead: boolean = false
  udpEnabled: boolean = false
  chinaSupport: boolean = false
  onKilledCallback?: VideoObjectKillCallback
  onRestartCallback?: VideoObjectRestartCallback
  onStartupCallback?: VideoObjectOnStartCallback
  onNetworkStatsCallback?: VideoObjectNetworkStatsCallback
  onLogCallback?: VideoObjectLogCallback
  repeaterRetryCount: number = 0
  requestRestartSent: boolean = false
  streamStarted: boolean = false
  networkstats: VideoObjectNetworkStats = new VideoObjectNetworkStats()
  lastICESwitch: number = 0
  nextICEIndex: number = 0
  bandwidthcounter: number = 0
  statInterval: number|null = null
  restartInterval: number|null = null
  watchdogInterval: number|null = null
  requestRestartTimeout: number|null = null
  description: RTCSessionDescriptionInit|null = null
  iceCandidates: ICECandidate[] = []

  constructor (config: VideoObjectConfig) {
    this.id = config.id
    this.name = config.name
    this.url = config.streamToPlay.url
    this.udpEnabled = config.udpEnabled !== undefined ? config.udpEnabled : false
    this.chinaSupport = config.chinaSupport !== undefined ? config.chinaSupport : false
    if (config.streamid) this.streamid = config.streamid
    this.logNetwork = config.logNetwork
    this.streamToPlay = config.streamToPlay
    this.srcObject = config.srcObject
    this.onStartupCallback = config.callback
    this.onRestartCallback = config.restartStream
    this.onKilledCallback = config.killStream
    try {
      // TODO: Add support for appname with slashes?
      const server = this.url.split('/')[2].split(':')[0]
      const port = isIOS ? ':7443' : ''
      this.wsURL = `wss://${server}${port}/webrtc-session.json`
      this.streamInfo = {
        applicationName: this.url.split('/')[3],
        streamName: this.url.split('/')[4],
        sessionId: '[empty]'
      }
      this.initWebSocket()
    } catch (error) {
      console.error('Could not get stream parameters ', error)
    }
    this.startWatchDog()
  }
  private initWebSocket () {
    if (!this.wsURL) {
      throw Error('No websocket url defined')
    }
    this.closeWebSocket()
    this.onOpen = this.onOpen.bind(this)
    this.onMsg = this.onMsg.bind(this)
    this.wsError = this.wsError.bind(this)
    this.wsConnection = new WebSocket(this.wsURL)
    this.wsConnection.onopen = this.onOpen
    this.wsConnection.onmessage = this.onMsg
    this.wsConnection.onerror = this.wsError
  }
  private closeWebSocket () {
    if (this.wsConnection !== null) {
      this.wsConnection.close()
      this.wsConnection = null
    }
  }
  private onOpen () {
    this.peerConnection = new RTCPeerConnection()
    this.parseStats = this.parseStats.bind(this)
    this.gotRemoteTrack = this.gotRemoteTrack.bind(this)
    this.peerConnection.ontrack = this.gotRemoteTrack
    this.sendPlayGetOffer()
  }
  private onMsg (evt: MessageEvent) {
    const msg = JSON.parse(evt.data)
    const msgStatus = Number(msg.status)
    const msgCommand = msg.command
    if (msgStatus === 514) {
      // repeater stream not ready
      this.repeaterRetryCount++
      if (this.repeaterRetryCount < 10) {
        setTimeout(this.sendPlayGetOffer, 500)
      } else {
        if (this.streamInfo) {
          console.log(`live stream repeater timeout: ${this.streamInfo.streamName}`)
        }
        this.stopPlay()
      }
    } else if (msgStatus === 502 || msgStatus === 504) {
      if (this.requestRestartSent === false && this.dead === false) {
        this.requestRestartTimeout = window.setTimeout(() => {
          this.requestRestart()
        }, 2000)
      }
    } else if (msgStatus !== 200) {
      this.onLogCallback?.(`Unexpected WebSocket status ${msgStatus}. Stopping ${this.streamInfo?.streamName}`)
      this.stopPlay()
    } else {
      this.setupPeerConnections(msg)
      this.lastBytesReceivedTs = Date.now()
      this.lastBytesReceived = 0
      this.streamStarted = false
      if (this.statInterval) {
        window.clearInterval(this.statInterval)
      }
      this.statInterval = window.setInterval(() => {
        this.updateStats()
      }, 1000)
      if (this.restartInterval) {
        window.clearInterval(this.restartInterval)
      }
      this.restartInterval = window.setInterval(() => {
        this.checkForRestart()
      }, 1000)
    }
    if ('sendResponse'.localeCompare(msgCommand) === 0) {
      if (this.wsConnection !== null) {
        this.wsConnection.close()
      }
      this.wsConnection = null
    }
    if ('getAvailableStreams'.localeCompare(msgCommand) === 0) {
      this.stopPlay()
    }
  }
  private startWatchDog () {
    if (this.watchdogInterval) {
      window.clearInterval(this.watchdogInterval)
    }
    this.watchdogInterval = window.setInterval(() => {
      if (this.dead === true) {
        return
      }
      const now = Date.now()
      const name = (this.streamInfo && this.streamInfo.streamName) || 'unknown'
      if (now - this.lastBytesReceivedTs > 15000 && !this.streamStarted) {
        console.warn(`Stream ${name} havent started after 15s, requesting to restart`)
        if (this.requestRestartSent && now - this.lastRestartAttemptTs > 10000) {
          const restartRetryMsg = `Restart of ${name} didn't succeed after 10s, retrying restart`
          console.warn(restartRetryMsg)
          this.onLogCallback?.(restartRetryMsg)
          this.requestRestartSent = false
        }
        if (!this.requestRestartSent) this.requestRestart()
      }
      if (now - this.lastBytesReceivedTs > 10000 && this.streamStarted) {
        this.requestRestart()
      }
      if (now - this.lastBytesReceivedTs > 3000 && this.streamStarted) {
        if (this.onLogCallback && now - this.lastBytesReceivedTs < 4000) {
          this.onLogCallback(`${name} is down for more than 3 seconds`)
        }
        const delta = Math.floor((now - this.lastBytesReceivedTs) / 1000)
        console.warn(`Seems like stream ${name} is down for ${delta} seconds`)
      }
    }, 1000)
  }
  private setupPeerConnections (msgJSON: any) {
    this.requestRestartSent = false
    const streamInfoResponse = msgJSON.streamInfo
    if (streamInfoResponse && this.streamInfo) {
      this.streamInfo.sessionId = streamInfoResponse.sessionId
    }
    const sdpData = msgJSON.sdp
    if (sdpData) {
      msgJSON.sdp.sdp = msgJSON.sdp.sdp.replace('a=mid:audio\r\n', 'a=mid:audio\r\na=fmtp:96 stereo=1\r\n')
      this.peerCreateAnswer = this.peerCreateAnswer.bind(this)
      if (this.peerConnection) {
        const remotedescription = new RTCSessionDescription(msgJSON.sdp)
        this.peerConnection.setRemoteDescription(remotedescription).then(this.peerCreateAnswer).catch(this.errorHandler)
      }
    }
    this.iceCandidates = msgJSON.iceCandidates
    if (this.iceCandidates !== undefined) {
      let indexToLoad = 0
      let foundUDP = false
      if (this.udpEnabled) {
        for (let i = 0; i < this.iceCandidates.length; i++) {
          if (this.iceCandidates[i].candidate.indexOf('UDP') !== -1) {
            indexToLoad = i
            foundUDP = true
          }
        }
      }
      if (this.chinaSupport) {
        indexToLoad += CHINA_INDEX_ICE_CANDIDATES
        console.log('china requested, started looking for index', indexToLoad)
      }
      console.log('Trying iceCandidates: ', JSON.stringify(this.iceCandidates[indexToLoad]))
      if (this.peerConnection !== null) {
        this.peerConnection.addIceCandidate(new RTCIceCandidate(this.iceCandidates[indexToLoad])).catch(error => {
          console.error('VideoObject:setupPeerConnections Could not add ICE candidate', error)
        })
      }
      if (foundUDP) {
        this.nextICEIndex = 0
      } else {
        this.nextICEIndex = 1
      }
    } else {
      this.iceCandidates = []
    }
  }
  private updateStats () {
    if (this.dead && this.statInterval) {
      window.clearInterval(this.statInterval)
      this.statInterval = null
    }
    if (this.peerConnection === null) {
      if (this.statInterval) {
        window.clearInterval(this.statInterval)
        this.statInterval = null
      }
    } else {
      this.peerConnection.getStats(null).then(this.parseStats).then(() => {
        if (this.networkstats.averageLatency > 2000) {
          console.warn(`Average latency of videoobject is > 2s (${this.networkstats.averageLatency})`)
        }
        if (this.networkstats.averageLatency > 5000) {
          this.requestRestart()
        }
        this.bandwidthcounter += 1
        if (this.bandwidthcounter % 10 === 0 && this.logNetwork) {
          if (this.onNetworkStatsCallback) {
            this.onNetworkStatsCallback(this.networkstats)
          }
        }
      }).catch(error => {
        console.error('VideoObject: get stats error ', error)
      })
    }
  }
  private parseStats (res: RTCStatsReport) {
    for (const result of res.values()) {
      this.networkstats.updateWithStats(result)
    }
  }
  private hasReceivedNewData () {
    const bytesReceived = this.networkstats.bytesReceived || 0
    const newBytesReceived = this.lastBytesReceived !== bytesReceived
    this.lastBytesReceived = bytesReceived

    return newBytesReceived
  }
  private checkForRestart () {
    if (this.dead === true) return
    const now = Date.now()
    if (this.peerConnection !== null) {
      const iceConnectionState = this.peerConnection.iceConnectionState
      if (now - this.lastBytesReceivedTs > 3000 && now - this.lastICESwitch > 5000) {
        const hasRemainingIceCandidates = this.nextICEIndex < this.iceCandidates.length
        if (['failed', 'checking'].includes(iceConnectionState) && hasRemainingIceCandidates) {
          console.log('Switching to ICE ' + this.iceCandidates[this.nextICEIndex].candidate)
          if (this.onLogCallback) {
            this.onLogCallback('Switching to ICE ' + this.iceCandidates[this.nextICEIndex].candidate)
          }
          this.peerConnection.addIceCandidate(new RTCIceCandidate(this.iceCandidates[this.nextICEIndex])).catch(error => {
            console.error('VideoObject:checkForRestart Could not add ICE candidate', error)
          })
          this.lastICESwitch = now
          this.nextICEIndex += 1
        } else {
          const isDisconnectedOrFullyFailed = iceConnectionState === 'disconnected'
            || (iceConnectionState === 'failed' && !hasRemainingIceCandidates)
          if (isDisconnectedOrFullyFailed && !this.requestRestartSent) {
            const streamName = this.streamInfo?.streamName ?? 'unknown'
            const logMessage = `Attempting restart of ${streamName} due to ICE connection state: ${iceConnectionState}`
            console.log(logMessage)
            if (this.onLogCallback) this.onLogCallback(logMessage)
            this.requestRestart()
          }
        }
      }
    }
    if (this.hasReceivedNewData()) {
      this.lastBytesReceivedTs = now
      this.streamStarted = true
    }
  }
  public onNetworkStats (callback: VideoObjectNetworkStatsCallback) {
    this.onNetworkStatsCallback = callback
  }
  public onLogs (callback: VideoObjectLogCallback) {
    this.onLogCallback = callback
  }
  private requestRestart () {
    const name = this.streamInfo?.streamName || ''
    if (this.hasOwnProperty('srcObject')) {
      delete this.srcObject
      if (this.onKilledCallback) {
        this.onKilledCallback(this)
      }
    }
    if (!this.requestRestartSent) {
      console.warn(`Something wrong with stream ${name}: want to restart`)
      if (this.peerConnection) {
        this.peerConnection.close()
        this.peerConnection = null // Needed to make sure the RTCPeerConnection gets garbage collected.
      }
      if (this.onRestartCallback) {
        this.onRestartCallback(this)
      }
      this.requestRestartSent = true
      this.lastRestartAttemptTs = Date.now()
      if (!this.dead) this.initWebSocket()
      if (this.onLogCallback) {
        this.onLogCallback(`${name} stream requests to restart`)
      }
    }
  }
  private peerCreateAnswer () {
    this.gotDescription = this.gotDescription.bind(this)
    if (this.peerConnection) {
      this.peerConnection.createAnswer().then(this.gotDescription).catch(this.errorHandler)
    }
  }
  public stopPlay () {
    // FIXME? This can result in:
    // "WebSocket connection to 'wss://wrtc-dev.kiswe.com/webrtc-session.json'
    // failed: WebSocket is closed before the connection is established."
    // warnings when the connection is still being established (i.e. readySate = 0 (CONNECTING)).
    // To prevent this we would need to postpone the close() until the readyState = 1 (OPEN).
    try {
      this.closeWebSocket()
    } catch (e) {
      console.error(e)
    } finally {
      this.wsConnection = null
      this.cleanUp()
    }
  }
  private sendPlayGetOffer () {
    try {
      const streamInfo = JSON.stringify(this.streamInfo)
      if (this.wsConnection) {
        this.wsConnection.send(`{"direction":"play", "command":"getOffer", "streamInfo":${streamInfo}}`)
      } else {
        console.error('Could not send offer, wsConnection does not exists')
      }
    } catch (error) {
      console.error('Could not send play offer', error)
    }
  }
  private gotDescription (description: RTCSessionDescriptionInit): void {
    this.sendAnswer = this.sendAnswer.bind(this)
    if (description.sdp) {
      description.sdp = description.sdp.replace('a=mid:audio\r\n', 'a=mid:audio\r\na=fmtp:96 stereo=1\r\n')
      this.description = description
      if (this.peerConnection) {
        this.peerConnection.setLocalDescription(description).then(this.sendAnswer).catch(this.errorHandler)
      }
    }
  }
  private sendAnswer () {
    if (this.wsConnection) {
      const description = JSON.stringify(this.description)
      const streamInfo = JSON.stringify(this.streamInfo)
      const data = `{"direction":"play", "command": "sendResponse", "streamInfo": ${streamInfo}, "sdp": ${description}}`
      this.wsConnection.send(data)
    }
  }
  private gotRemoteTrack (event: RTCTrackEvent) {
    this.srcObject = event.streams[0]
    if (this.srcObject.getTracks().length === 1 && this.srcObject.getTracks()[0].kind === 'video') {
      console.error('VideoObject::gotRemoteTrack does not have 2 tracks (audio and video), restarting...')
      this.requestRestart()
      return
    }
    if (this.onStartupCallback) {
      this.onStartupCallback(this)
    }
    // https://stackoverflow.com/questions/60636439/webrtc-how-to-detect-when-a-stream-or-track-gets-removed-from-a-peerconnection
    this.srcObject.onremovetrack = ({ track }) => {
      console.log(`${track.kind} track was removed.`);
      if (!this.srcObject?.getTracks().length) {
        console.log(`stream ${this.srcObject?.id} emptied (effectively removed).`);
      }
    }
  }
  private errorHandler (error: Error) {
    console.log(error)
  }
  private wsError (error: Event) {
    console.warn('Error with websocket of video object:', error)
  }
  private cleanUp () {
    if (this.dead) return
    this.dead = true
    this.peerConnection?.close()
    this.peerConnection = null
    if (this.statInterval) {
      window.clearInterval(this.statInterval)
      this.statInterval = null
    }
    if (this.restartInterval) {
      window.clearInterval(this.restartInterval)
      this.restartInterval = null
    }
    if (this.watchdogInterval) {
      window.clearInterval(this.watchdogInterval)
      this.watchdogInterval = null
    }
    if (this.requestRestartTimeout) {
      window.clearTimeout(this.requestRestartTimeout)
      this.requestRestartTimeout = null
    }
    this.onKilledCallback?.(this)
  }
}
//
export default VideoObject
export type VideoObjectKillCallback = (VideoObject: VideoObject) => void
export type VideoObjectRestartCallback = (VideoObject: VideoObject) => void
export type VideoObjectOnStartCallback = (VideoObject: VideoObject) => void
export type VideoObjectNetworkStatsCallback = (VideoObjectNetworkStats: VideoObjectNetworkStats) => void
export type VideoObjectLogCallback = (string: string) => void
