import { Loader } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'
import { Overrides } from '@/modules/overrides/classes'
import { OverrideEntity } from '@/modules/overrides/types'

// eslint-disable-next-line max-lines-per-function
export function hasOverrides<TBase extends StudioBaseMixin> (Base: TBase) {
  return class hasOverridesClass extends Base {

    public overrides: Overrides

    constructor (...arg: any[]) {
      super(...arg)
      this.overrides = Loader.loadClass(arg[0], 'overrides', (data: unknown) => new Overrides(data),
        new Overrides())
    }

    getDisplayName (entity: OverrideEntity, id: string) {
      return this.overrides[entity][id]?.displayName
    }

    setDisplayName (entity: OverrideEntity, id: string, displayName: string) {
      if (this.overrides[entity][id]) this.overrides[entity][id].displayName = displayName
      else this.overrides[entity][id] = { displayName }
      return this
    }
  }
}

export type HasOverrides = Mixin<typeof hasOverrides>
