import store from '@/store'
import { computed, onMounted, ref } from 'vue'

const useUserCastStatus = (
  userId: string,
  castId: string|null = null,
  isCaster: boolean = true,
  isJanus: boolean = false
) => {
  const userStateTouched = ref(false)

  onMounted(async () => {
    if (castId) await store.dispatch.status.touchUserState({ userId, castId, isCaster, isJanus })
    userStateTouched.value = true
  })

  const userCastStatus = computed(() => {
    let ref = null
    if (castId) ref = store.state.status.userState[userId]?.[castId] ?? null
    if (ref === null && userStateTouched.value) {
      console.error(`Failed to get status reference for user: ${userId} of cast: ${castId}.`)
    }
    return ref
  })

  return {
    userCastStatus
  }
}

export default useUserCastStatus
