export function parseQuality (quality: string): { bitrate: number, resolution: number } {
  const result = { resolution: Number.NaN, bitrate: Number.NaN }
  const parts = quality.split('p')
  if (parts.length < 2) return result
  result.resolution = Number.parseInt(parts[0])
  result.bitrate = Number.parseInt(parts[1])
  return result
}

export function getQualityBitrate (quality: string): number {
  return parseQuality(quality).bitrate
}

export function getQualityResolution (quality: string): number {
  return parseQuality(quality).resolution
}
