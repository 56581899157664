import { Loader } from 'kiswe-ui'
import { createClass } from '@/modules/classBuilder/classBuilder'
import { KeyDict } from '@/types'
import { CastType, InvitedCaster } from '@/types/casts'
import { CastTemplateBase } from '../castTemplateBase'

export class CastTemplateFanRoomLeftOvers extends CastTemplateBase {
  invited_casters: KeyDict<InvitedCaster>

  constructor (data: unknown) {
    super(data)
    this.invited_casters = {}
    const casters = Loader.loadAndCast<KeyDict<InvitedCaster>>(data, 'invited_casters', {})
    for (const key of Object.keys(casters)) {
      this.invited_casters[key] = new InvitedCaster(casters[key])
    }
  }
}

export const CastTemplateFanRoom = createClass(CastTemplateFanRoomLeftOvers).addCastType(CastType.FanRoom)
                                                                                  .get()
