export enum ChatMessageType {
  Text = 'text',
  File = 'file',
  Emoji = 'emoji',
  Tweet = 'tweet'
}

export interface ChatMessage {
  id: string,
  author: string,
  data: {
    text?: string,
    twitterId?: string,
    file?: File|{url: string}
    emoji?: string
  },
  timestamp: number,
  user: string,
  type: ChatMessageType,
  editable?: boolean,
  isEdited?: boolean,
  deleted?: boolean
}

export interface Widget {
  id?: string,
  cast_id: string,
  team_id: string,
  type: string,
  box?: string
}

export interface ChatWidget extends Widget {
  istyping: { [ userId: string ]: number },
  messages: ChatMessage[],
  type: 'chat'
}

export interface ChatColorStyle {
  bg: string,
  text: string
}

export interface ChatParticipant {
  id: string,
  name: string,
  colors?: ChatColorStyle,
  initials: string,
  imageUrl: string
}
