<template>
  <div class="chrome-message">
    <KisweLogo class="chrome-message__logo" />
    <div class="message">
      Sorry you are not currently assigned to an active team within Kiswe Studio.
    </div>
    <br>
    <button v-if="showLogout" @click="logout">Logout</button>
    <a :href="getURL('login')">Return to login</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import helpers from '@/helpers'
import { KisweLogo } from 'kiswe-ui'
import { KeyDict } from '@/types'

export default defineComponent({
  name: 'NoTeamMessage',
  props: {
    showLogout: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    logout () {
      this.$store.direct.dispatch.user.logoutUser()
    },
    getURL (name: string, params: KeyDict<string>) {
      return helpers.getURL(this.$router, name, params)
    }
  },
  components: {
    KisweLogo
  }
})
</script>

<style lang="scss" scoped>
button {
  padding: 8px;
  background-color: var(--color-neutral--lighter);
  border-color: #666;
  font-size: 0.9em;
  color: var(--color-default);
  cursor: pointer;
  &:hover {
    background-color: #666;
    border-color: #777;
  }
}

a {
  color: var(--color-neutral--lightest);
}

.chrome-message__logo {
  width: 10rem;
  margin: 0.5rem auto;
}
</style>
