import { SceneContentType } from '@/modules/scenes/types'
import { KeyDict } from '.'
import { Asset, AssetType, ClipTranscodingState, VideoAsset } from '../types/assets'
import { LiveChatCSS, SimplecgCSS } from './layouts'
import { ClipEndAction } from './videoplayer'
import { TwitterStyling } from '@/modules/layouts/classes'

export const contentSortByOrder = (content1: { order: number }, content2: { order: number }) => {
  return content1.order < content2.order ? -1 : 1
}

export interface VideoAssetContent extends VideoAsset {
  /** @deprecated Prefer use of `clip_end_action` */
  auto_play_next?: boolean,
  clip_end_action?: ClipEndAction,
  muted?: boolean,
  order: number
}

export interface SceneContentBase {
  active: boolean,
  box: string,
  id: string,
  idx?: number,
  position?: number,
  url?: string,
  state?: ClipTranscodingState,
  name: string,
  order: number,
  type: AssetType,
  leftMuted?: boolean,
  rightMuted?: boolean,
  muted?: boolean,
  isDefault?: boolean, // = added to a layout, appears default in scene
  user?: string,
  simplecgText?: string,
  /** @deprecated Prefer use of `clip_end_action` */
  auto_play_next?: boolean,
  clip_end_action?: ClipEndAction,
  contentType: SceneContentType,
  timestamp?: number,
  reloadKey?: number
}

export interface SceneContentCast extends SceneContentBase {
  castId: string,
  type: AssetType.Cast
}

export interface SceneContentTransition extends SceneContentBase {
  contentType: SceneContentType.Transition|SceneContentType.GlobalTransition
  url: string
  transitionDelay: number
}
export interface SceneContentGlobalLayer extends SceneContentBase {
  contentType: SceneContentType.GlobalLayer
}

export interface SceneContentRendering extends SceneContentBase {
  volume?: number
  volumeLeft?: number
  volumeRight?: number
  leftrightsplit?: boolean
  slot?: string
  style: Partial<CSSStyleDeclaration>,
  stretchstyle: Partial<CSSStyleDeclaration>,
  asset?: Partial<Asset>,
  boxWidth: number,
  boxHeight: number,
  tweetcss?: TwitterStyling,
  simplecgcss?: KeyDict<SimplecgCSS>,
  livechatcss?: LiveChatCSS,
  stretchVertical?: boolean,
  stretchContent?: boolean,
  rawText?: string
}

export type SceneContent = SceneContentBase|SceneContentCast|SceneContentTransition|SceneContentGlobalLayer

export const isSceneContentCast = (content: SceneContent): content is SceneContentCast =>
  content.type === AssetType.Cast

export const isSceneContentGlobal = (content: SceneContent): content is SceneContentGlobalLayer =>
  content.contentType === SceneContentType.GlobalLayer

export const isSceneContentTransition = (content: SceneContent): content is SceneContentTransition =>
  [SceneContentType.Transition, SceneContentType.GlobalTransition].includes(content.contentType)

export const SCENE_CONTENT_Z_INDEX: Record<SceneContentType, number> = {
  [SceneContentType.Content]: 0,
  [SceneContentType.Layer]: 1000,
  [SceneContentType.GlobalLayer]: 2000,
  [SceneContentType.Transition]: 3000,
  [SceneContentType.GlobalTransition]: 4000
} as const
