import { AbilitiesState, RootState } from '../types'
import store, { moduleActionContext } from '..'
import { ActionContext } from 'vuex'
import { TeamRole } from '@/abilitiesByRole'
import { CollectionNames } from '@/modules/database/utils/collectionNames'
import { defineModule } from 'direct-vuex'
import studio from '@/modules/database/utils'
import { KeyDict } from '@/types'
import { MergeOption } from '@/modules/database/utils/database'
import { Result, ResultVoid } from 'kiswe-ui'

type AbilitiesContext = ActionContext<AbilitiesState, RootState>

const abilitiesModule = defineModule({
  namespaced: true,
  state: {
    teamRoles: {},
    teamRolesReady: false
  } as AbilitiesState,
  actions: {
    async subscribeTeamRoles (context: AbilitiesContext, teamId: string) {
      const { commit } = abilitiesModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        key: `teams_roles_${teamId}`,
        query: studio.db.collection(CollectionNames.TEAMS_ROLES)
                        .where('team_id', '==', teamId)
                        .where('deleted', '==', false),
        callback: commit.updateTeamRoles
      })
      result.logIfError('Could not subscribe to teams_roles')
    },
    async unsubscribeTeamRoles (_context: AbilitiesContext, teamId: string) {
      studio.subscriptions.unsubscribe(`teams_roles_${teamId}`)
    },
    async addRole (_context: AbilitiesContext, role: TeamRole) {
      return await studio.db.collection(CollectionNames.TEAMS_ROLES).add(role)
    },
    async updateRole (_context: AbilitiesContext,
                      { id, role }: { id: string, role: TeamRole }): Promise<ResultVoid<'database'|'invalid id'>> {
      if (id === '') return Result.fail('invalid id', 'Can\'t update team role without id.')
      return studio.db.collection(CollectionNames.TEAMS_ROLES).doc(id).set(role, MergeOption.OVERWRITE)
    },
    async deleteRole (_context: AbilitiesContext, id: string): Promise<ResultVoid<'database'|'invalid id'|'has members'>> {
      if ( id === '' ) return Result.fail('invalid id', 'Can\'t delete team role without id.')
      const team = store.state.team.team
      const members = Object.values(team?.members ?? {}).filter(member => member.role === id)
      if (members.length > 0) {
        return Result.fail('has members', `There are ${members.length} member(s) assigned to this role`)
      }
      return studio.db.collection(CollectionNames.TEAMS_ROLES).doc(id).set({ deleted: true }, MergeOption.MERGE)
    }
  },
  mutations: {
    updateTeamRoles (state: AbilitiesState, roles: KeyDict<TeamRole>) {
      state.teamRoles = roles
      state.teamRolesReady = true
    }
  }
})

export default abilitiesModule
export const abilitiesModuleActionContext = (context: AbilitiesContext) => moduleActionContext(context, abilitiesModule)
