import { Loader, Result } from 'kiswe-ui'
import { CastInputStream, InputStreamType } from '@/modules/streams/types'
import { KeyDict } from '@/types'
import { SystemStatus } from '@/types/ops'
import {
  SourceStream, SourceStreamBroadCast, SourceStreamCaster, SourceStreamModerator,
  SourceStreamNonCaster, StreamType
} from '@/types/streams'
import { GConstructor, Mixin, StudioBaseMixin } from './base'
import { getAllSourceStreamIdsByUserId, getSourceStreamIdByUserId } from '@/modules/streams/utils'

type UserStreamType = StreamType.Caster|StreamType.NonCaster|StreamType.Moderator
type UserSourceStream = SourceStreamCaster|SourceStreamNonCaster|SourceStreamModerator

// eslint-disable-next-line max-lines-per-function
export function hasStreams<TBase extends StudioBaseMixin> (Base: TBase) {
  return class hasStreamsClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.input_streams = Loader.loadAndCast<KeyDict<CastInputStream>>(arg[0], 'input_streams', {})
      this.source_streams = Loader.loadAndCast<KeyDict<SourceStream>>(arg[0], 'source_streams', {})
      for (const [key, value] of Object.entries(this.input_streams ?? {})) {
        this.input_streams[key] = new CastInputStream(value)
      }
    }
    // @ts-ignore
    private __hasStreams: true = true
    static __constructsHasStreams: true = true
    input_streams: KeyDict<CastInputStream> = {}
    source_streams: KeyDict<SourceStream> = {}

    addCefPagePreviewStream (contentId: string, castId: string, isStreamActive?: boolean) {
      const hasCefSource = Object.values(this.source_streams).some((sourceStream) => {
        return sourceStream.type === StreamType.Cef && sourceStream.castId === castId
      })
      if (!hasCefSource) {
        this.source_streams[contentId] = {
          active: isStreamActive ?? true,
          type: StreamType.Cef,
          castId: castId,
          volume: 100
        }
      }
      return this
    }

    addUserSourceStream (streamId: string, userId: string, type: UserStreamType = StreamType.Caster,
                           updateFunction?: (ss: UserSourceStream) => UserSourceStream): Result<this, 'exists'> {
      if (this.source_streams[streamId]) return Result.fail('exists', 'source stream already exists')
      // TODO: Convert SourceStreamCaster to a class and init all properties correctly
      let sourceStream: UserSourceStream = {
        type,
        ngcvp_status: SystemStatus.OK,
        user_id: userId,
        session_id: 'session',
        start_time: Date.now() / 1000
      }
      if (updateFunction) sourceStream = updateFunction(sourceStream)
      this.source_streams[streamId] = sourceStream
      return Result.success(this)
    }

    addBroadcastStream (
      streamId: string,
      inputType?: InputStreamType,
      updateFunction?: (sourceStream: SourceStreamBroadCast) => SourceStreamBroadCast
    ) {
      const type = inputType ?? InputStreamType.Any

      this.input_streams[streamId] = {
        enabled: true,
        type
      }
      let sourceStream: SourceStreamBroadCast = {
        // Note: cef_feed_id & ngcvp_status are supposed to be set by backend.
        active: true,
        start_time: Date.now() / 1000,
        type: StreamType.Broadcast,
        volume: 0,
        volumeLeft: 0,
        volumeRight: 0
      }
      if (updateFunction) sourceStream = updateFunction(sourceStream)
      this.source_streams[streamId] = sourceStream
      return this
    }

    updateSourceStreamActive (streamId: string, active: boolean) {
      const sourceStream = this.source_streams[streamId]
      if (sourceStream) sourceStream.active = active
      return this
    }

    public getAllSourceStreamIdsByUserId (userId: string): string[] {
      return getAllSourceStreamIdsByUserId(userId, this.source_streams)
    }

    public getUserSourceStreamId (userId: string): string|null {
      return getSourceStreamIdByUserId(userId, this.source_streams)
    }

    public getCastSourceStreamId (castId: string): string|null {
      for (const [key, sourceStream] of Object.entries(this.source_streams)) {
        if (sourceStream.type !== StreamType.Cef) continue
        if (sourceStream.castId === castId) return key
      }
      return null
    }

    public setSourceStreamVolume (streamId: string, volume: number): Result<this, 'not_found'> {
      if (!this.source_streams[streamId]) return Result.fail('not_found', `Source stream [${ streamId }] not found`)
      this.source_streams[streamId].volume = volume
      return Result.success(this)
    }

    public setSourceStreamMuted (streamId: string, isMuted: boolean): Result<this, 'not_found'> {
      if (!this.source_streams[streamId]) return Result.fail('not_found', `Source stream [${ streamId }] not found`)
      this.source_streams[streamId].muted = isMuted
      return Result.success(this)
    }

    public setSourceStreamMutedByUserId (userId: string, isMuted: boolean): Result<this, 'not_found'> {
      const sourceStreamId = getSourceStreamIdByUserId(userId, this.source_streams)
      if (sourceStreamId === null) return Result.fail('not_found', `Source stream for user [${ userId }] not found`)
      return this.setSourceStreamMuted(sourceStreamId, isMuted)
    }
  }
}

export type HasStreams = Mixin<typeof hasStreams>

export const hasStreamsMixin = (obj: unknown): obj is HasStreams => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasStreams' in obj) return true
  return false
}

export const constructsStreamsMixin = (func: unknown): func is GConstructor<HasStreams> => {
  if (typeof func !== 'function' || func === null) return false
  if ('__constructsHasScenes' in func) return true
  return false
}
