import { VideoObjectNetworkStats } from '@/classes/VideoObjectNetworkStats'
// @ts-ignore
import Network from 'network-js'
export type NetworkStatsFetcherCallback = (NetworkStats: VideoObjectNetworkStats) => void


export default class NetworkStatsFetcher {
  wrtcServer: string
  network: Network|undefined
  networkStats: VideoObjectNetworkStats
  callback: NetworkStatsFetcherCallback

  constructor (wrtcServer: string, callback: NetworkStatsFetcherCallback) {
    this.wrtcServer = wrtcServer
    this.networkStats = new VideoObjectNetworkStats()
    this.callback = callback
  }

  // eslint-disable-next-line max-lines-per-function
  startFetching (): void {
    const endpoint = `https://${this.wrtcServer}/benchmark/server.php`
    const settings = {
      latency: {
        measures: 30,
        attemps: 1
      },
      upload: {
        endpoint,
        delay: 1500,
        data: {
          size: 0.1 * 1024 * 1024, // 0.1 MB
          multiplier: 1.5
        }
      },
      download: {
        endpoint,
        delay: 1500,
        data: {
          size: 0.1 * 1024 * 1024, // 0.1 MB
          multiplier: 1.5
        }
      }
    }
    this.network = new Network(settings)
    // const calculatedLatency = 0
    // const jitter = 0
    // this.network.upload
    //   .on('start', () => {})
    //   .on('restart', () => {})
    //   .on('end', (averageSpeed: number, allInstantSpeeds: number[]) => {
    //     if (this.networkStats && averageSpeed > 0) {
    //       this.networkStats.updateWithUploadSpeed(averageSpeed, allInstantSpeeds, calculatedLatency, jitter)
    //       this.callback(this.networkStats)
    //     }
    //     this.network.download
    //       .on('start', () => {})
    //       .on('restart', () => {})
    //       .on('end', (averageSpeed: number, _allInstantSpeeds: number[]) => {
    //         if (this.networkStats) {
    //           this.networkStats.updateWithDownloadSpeed(averageSpeed)
    //           this.callback(this.networkStats)
    //         }
    //       })
    //     this.network.download.start()
    //   }).start()
    this.network.download
      .on('start', () => {})
      .on('restart', () => {})
      .on('end', (averageSpeed: number, _allInstantSpeeds: number[]) => {
        if (this.networkStats) {
          this.networkStats.updateWithDownloadSpeed(averageSpeed)
          this.callback(this.networkStats)
        }
      })
    this.network.download.start()
  }
}
