import { MarkerType } from './Marker'

export abstract class MarkerBase<T extends MarkerType> {
  public id: string
  public timestamp: Date
  public type: T

  constructor (id: string, type: T, timestamp: Date = new Date()) {
    this.id = id
    this.timestamp = timestamp
    this.type = type
  }

  setTimestampNow () {
    this.timestamp = new Date()
  }
}
