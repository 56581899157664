import { Loader } from 'kiswe-ui'
import { SupportedLocale } from '@/plugins/translations/types'
import { KeyDict } from '@/types'
import firebase from 'firebase/compat/app'

// NOTE: we have an extension on Firebase which automatically picks up the value of key 'message' and translates it in
// a predefined set of languages. The extension then returns those translations in a dict 'translations'.

export default class CastChatMessage {
  '.uid': string = ''
  message: string = ''
  timestamp: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now()
  /** @deprecated: use userId instead */
  user_id: string = ''
  targetUserId: string|null = null
  isSuperUser: boolean = false
  translations: KeyDict<string> = {}

  constructor (data?: unknown) {
    if (!data) return
    this['.uid'] = Loader.loadString(data, '.uid', this['.uid'])
    this.message = Loader.loadString(data, 'message', this.message)
    this.timestamp = Loader.loadAndCast<firebase.firestore.Timestamp>(data, 'timestamp', this.timestamp)
    this.user_id = Loader.loadString(data, 'user_id', this.user_id)
    this.targetUserId = Loader.loadStringOrNull(data, 'targetUserId', this.targetUserId)
    this.isSuperUser = Loader.loadBoolean(data, 'isSuperUser', this.isSuperUser)
    this.translations = Loader.loadAndCast<KeyDict<string>>(data, 'translations', this.translations)
  }

  get userId (): string {
    return this.user_id
  }

  set userId (newVal: string) {
    this.user_id = newVal
  }

  get isPublic (): boolean {
    return this.targetUserId === null
  }

  public isVisible (userIds: (string|null)[]|null = null): boolean {
    if (userIds === null && this.isPublic) return true
    if (userIds === null) return this.isPublic
    return userIds.every((id) => id !== null && (id === this.targetUserId || id === this.userId))
  }

  public getTranslatedMessage (locale: SupportedLocale|null = null): string {
    if (locale === null) return this.message
    return this.translations[locale] ?? this.message
  }
}
