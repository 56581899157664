/* eslint-disable max-lines */
import { KeyDict, DeepPartial } from '@/types'
import { CollectionNames, AdminDocNames } from './collectionNames'
import DatabaseInterface from './databaseInterface'
import Convertable from '@/classes/Convertable'
import { Result, Success } from 'kiswe-ui'
import { admindocs, mapper } from './datamodel'
import { generateRandomString } from '@/modules/common/utils'

type UnsubscribeFunction = () => void


export type QueryOperation = '==' | '<' | '>' | '<=' | '>=' | 'in'

interface QueryKeyInfo {
  key: string,
  operation: QueryOperation,
  value: string|number|boolean|string[]|number[]|boolean[],
  type: 'key'
}

interface QueryKeyOrder {
  key: string,
  order: Order
  type: 'order'
}

interface QueryKeyLimit {
  limit: number,
  type: 'limit'
}

export type QueryInfo = QueryKeyInfo | QueryKeyOrder | QueryKeyLimit

// eslint-disable-next-line @stylistic/max-len
const createGetDocument = <T, S extends string>(db: DatabaseInterface, collectionId: CollectionNames, docId: S, mapper: (data: unknown) => Result<T, 'unknown'|'invalid params'>) => {
  return async (): Promise<Result<T, 'database'>> => {
    // const markNameStart = `doc_${collectionId}.${docId}-start`
    // const markNameEnd = `doc_${collectionId}.${docId}-end`
    // const measureName = `doc_${collectionId}.${docId}`
    // performance.mark?.(markNameStart)
    const data = await db.get(collectionId, docId)
    // performance.mark?.(markNameEnd)
    // performance.measure?.(measureName, markNameStart, markNameEnd)
    if (!data.isSuccess) return data.convert()
    const value = mapper(data.value)
    if (!value.isSuccess) {
      return Result.fail('database', value.message)
    }
    return value.convert()
  }
}

export enum Order {
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

export enum MergeOption {
  OVERWRITE = 'overwrite',
  UPDATE = 'update',
  MERGE = 'merge'
}

class ArrayAction<T> {
  value: T
  databaseAction: 'addToArray'|'removeFromArray'

  constructor (value: T, action: 'add'|'remove') {
    this.value = value
    if (action === 'add') {
      this.databaseAction = 'addToArray'
    } else {
      this.databaseAction = 'removeFromArray'
    }
  }
}

class FieldAction {
  databaseAction: 'deleteField'

  constructor () {
    this.databaseAction = 'deleteField'
  }
}

export type WithFieldActions<T> = {
  [P in keyof T]: WithFieldActions<T[P]> | FieldAction
}

type WithArray<T> = {
  [P in keyof T]: T[P] extends Array<infer U> ? WithArray<T[P]> | ArrayAction<U> : WithArray<T[P]>
}

const mapperWithIds = <T>(mapper: (data: unknown) => Result<T, 'invalid params'|'unknown'>,
                          data: unknown, id: string) => {
  if (typeof data !== 'object') return Result.fail<T, 'invalid params'|'unknown'>('unknown', 'data is not an object')
  return mapper({ ...data, id })
}

const createSetDocument = <T, S extends string>(db: DatabaseInterface, collectionId: CollectionNames, docId: S) => {
  return async <M extends MergeOption>(data: M extends MergeOption.MERGE ?
                                             DeepPartial<WithFieldActions<WithArray<T>>> :
                                             (M extends MergeOption.UPDATE ? Partial<T> : T), mergeOption: M) => {
    const filteredData = Convertable.toObject(data)
    if (mergeOption === MergeOption.OVERWRITE) {
      return db.set(collectionId, docId, filteredData)
    } else if (mergeOption === MergeOption.UPDATE) {
      return db.update(collectionId, docId, filteredData)
    }
    return db.merge(collectionId, docId, filteredData)
  }
}

const createDeleteDocument = <S extends string>(db: DatabaseInterface, collectionId: CollectionNames, docId: S) => {
  // eslint-disable-next-line @stylistic/max-len
  return async () => {
    return db.delete(collectionId, docId)
  }
}

// eslint-disable-next-line @stylistic/max-len
const createGetMultiDocument = <T>(db: DatabaseInterface, collectionId: CollectionNames, query: QueryInfo[], mapper: (data: unknown) => Result<T, 'invalid params'|'unknown'>) => {
  return async (): Promise<Result<KeyDict<T>, 'database'>> => {
    // const markNameStart = `collection_${collectionId}-start`
    // const markNameEnd = `collection_${collectionId}-end`
    // const measureName = `collection_${collectionId}`
    // performance.mark?.(markNameStart)
    const data = await db.query(collectionId, query)
    // performance.mark?.(markNameEnd)
    // performance.measure?.(measureName, markNameStart, markNameEnd)
    if (!data.isSuccess) return data.convert()
    const docs: KeyDict<T> = {}
    for (const [key, doc] of Object.entries(data.value)) {
      const loaded = mapperWithIds(mapper, doc, key)
      if (!loaded.isSuccess) {
        console.error('Error loading data from the database', loaded.message)
      } else {
        docs[key] = loaded.value
      }
    }
    return Result.success(docs)
  }
}
// eslint-disable-next-line @stylistic/max-len
const createCollectionSnapshot = <T>(db: DatabaseInterface, collectionId: CollectionNames, query: QueryInfo[], mapper: (data: unknown) => Result<T, 'invalid params'|'unknown'>) => {
  return (callback: (docs: KeyDict<T>) => void): Result<UnsubscribeFunction, 'database'> => {
    return db.querySnapshot(collectionId, query, (data: KeyDict<unknown>) => {
      const docs: KeyDict<T> = {}
      for (const [id, doc] of Object.entries(data)) {
        const result = mapperWithIds(mapper, doc, id)
        if (result.isSuccess) {
          docs[id] = result.value
        } else {
          console.warn('Error loading data from the database', result.message)
        }
      }
      callback(docs)
    })
  }
}

// eslint-disable-next-line @stylistic/max-len
const createDocumentSnapshot = <T, S extends string>(db: DatabaseInterface, collectionId: CollectionNames, docId: S, mapper: (data: unknown) => Result<T, 'invalid params'|'unknown'>) => {
  return (callback: (doc: T) => void): Result<UnsubscribeFunction, 'database'> => {
    return db.docSnapshot(collectionId, docId, (data: unknown) => {
      let result: Result<T, 'invalid params'|'unknown'>
      if (collectionId === CollectionNames.ADMIN) result = mapper(data)
      else result = mapperWithIds(mapper, data, docId)
      if (result.isSuccess) {
        callback(result.value)
      } else {
        console.warn('Could not load document', result.message)
      }
    })
  }
}

type CollectionMapper = typeof mapper
type AdminMapper = typeof admindocs
export type RightType<T> = T extends Success<infer S, infer _> ? S : never

const createDocument = <T extends CollectionNames>(db: DatabaseInterface, collection: T) => {
  type DocType = T extends CollectionNames.ADMIN ? AdminDocNames : string
  return <S extends DocType>(docId: S extends '' ? never : S) => {
    type Loader = T extends CollectionNames.ADMIN ?
                      (S extends AdminDocNames ? AdminMapper[S] : never) :
                      CollectionMapper[T]
    type R = RightType<ReturnType<Loader>>
    // eslint-disable-next-line @stylistic/max-len
    // @ts-ignore
    const localMapper = collection === CollectionNames.ADMIN  ? admindocs[docId] : mapper[collection]
    return {
      get: createGetDocument<R, S>(db, collection, docId, localMapper),
      set: createSetDocument<R, S>(db, collection, docId),
      delete: createDeleteDocument<S>(db, collection, docId),
      onSnapshot: createDocumentSnapshot<R, S>(db, collection, docId, localMapper)
    }
  }
}

const createAddDocument = <T>(db: DatabaseInterface, collectionId: CollectionNames) => {
  // eslint-disable-next-line @stylistic/max-len
  return async (data: T) => {
    const filteredData = Convertable.toObject(data)
    const docId = generateRandomString(20)
    const result = await db.set(collectionId, docId, filteredData)
    return result.map((_input) => docId)
  }
}

const createOrderBy = <T extends CollectionNames>(db: DatabaseInterface, collectionName: T, query: QueryInfo[]) => {
  type R = RightType<ReturnType<CollectionMapper[T]>>
  // Note: For firebase, if there was a where() used with an operation different from '==', then the first orderBy()
  //       needs to use the same key as that where(). This is currently not something we enforce via this TS interface.
  return (key: Extract<keyof R, string>, order: Order) => {
    const newQuery: QueryInfo[] = [...query]
    newQuery.push({
      key,
      order,
      type: 'order'
    })
    // @ts-ignore
    const mapping: any = mapper[collectionName]
    return {
      orderBy: createOrderBy<T>(db, collectionName, newQuery),
      limit: createLimit<T>(db, collectionName, newQuery),
      get: createGetMultiDocument<R>(db, collectionName, newQuery, mapping),
      onSnapshot: createCollectionSnapshot<R>(db, collectionName, newQuery, mapping)
    }
  }
}

const createLimit = <T extends CollectionNames>(db: DatabaseInterface, collectionName: T, query: QueryInfo[]) => {
  type R = RightType<ReturnType<CollectionMapper[T]>>
  return (limit: number) => {
    const newQuery: QueryInfo[] = [...query]
    newQuery.push({
      limit,
      type: 'limit'
    })
    // @ts-ignore
    const mapping: any = mapper[collectionName]
    return {
      get: createGetMultiDocument<R>(db, collectionName, newQuery, mapping),
      onSnapshot: createCollectionSnapshot<R>(db, collectionName, newQuery, mapping)
    }
  }
}

type PathsToStringProps<T> = T extends string|boolean|number|Date ? [] : {
    [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>]
}[Extract<keyof T, string>]

type Join<T extends string[], D extends string> =
    T extends [] ? never :
    T extends [infer F] ? F :
    T extends [infer F, ...infer R] ?
    F extends string ?
    `${F}${D}${Join<Extract<R, string[]>, D>}` : never : string

type PathToKeys<T extends string> = T extends `${infer A}.${infer B}` ? [A, ...PathToKeys<B>] : [T]
type RemoveFirst<T extends string[]> = T extends [infer _, ...infer R] ? R : []
type TypeOfPath<T, Path extends string[]> =
  Path extends [] ? T
                  : Path[0] extends keyof T ? TypeOfPath<T[Path[0]], RemoveFirst<Path>> : never
type TypeOfStringPath<T, Path extends string> = TypeOfPath<T, PathToKeys<Path>>

const createQuery = <T extends CollectionNames>(db: DatabaseInterface, collectionName: T, query: QueryInfo[]) => {
  type R = RightType<ReturnType<CollectionMapper[T]>>
  type DottedLanguageObjectStringPaths = Join<PathsToStringProps<R>, '.'>
  return <K extends DottedLanguageObjectStringPaths,
          V extends TypeOfStringPath<R, K>,
  /*return <K extends keyof R,
          V extends R[K],*/
          Q extends QueryOperation>(key: K, operation: Q, value: Q extends 'in' ? V[]: V) => {
    const newQuery: QueryInfo[] = [...query]
    newQuery.push({
      key: key as string,
      operation,
      value,
      type: 'key'
    })
    // @ts-ignore
    const mapping: any = mapper[collectionName]
    return {
      where: createQuery<T>(db, collectionName, newQuery),
      orderBy: createOrderBy<T>(db, collectionName, newQuery),
      limit: createLimit<T>(db, collectionName, newQuery),
      get: createGetMultiDocument<R>(db, collectionName, newQuery, mapping),
      onSnapshot: createCollectionSnapshot<R>(db, collectionName, newQuery, mapping)
    }
  }
}

const createCollection = (db: DatabaseInterface) => {
  return <T extends CollectionNames>(collectionName: T) => {
    // @ts-ignore
    const mapping: any = mapper[collectionName]
    type R = RightType<ReturnType<CollectionMapper[T]>>
    return {
      add: createAddDocument<R>(db, collectionName),
      doc: createDocument<T>(db, collectionName),
      where: createQuery<T>(db, collectionName, []),
      orderBy: createOrderBy<T>(db, collectionName, []),
      limit: createLimit<T>(db, collectionName, []),
      get: createGetMultiDocument<R>(db, collectionName, [], mapping),
      onSnapshot: createCollectionSnapshot<R>(db, collectionName, [], mapping)
    }
  }
}

const createCloseDatabase = (db: DatabaseInterface) => {
  return async () => {
    return db.close()
  }
}

const createDatabase = (db: DatabaseInterface) => {
  return {
    collection: createCollection(db),
    close: createCloseDatabase(db),
    getRawInterface: () => {
      if (process.env.NODE_ENV !== 'test') throw new Error('getRawInterface() is only allowed to be used during tests')
      return db
    }
  }
}

export type DataBase = ReturnType<typeof createDatabase>

export const DatabaseAction = {
  addToArray: <T>(value: T) => new ArrayAction(value, 'add'),
  removeFromArray: <T>(value: T) => new ArrayAction(value, 'remove'),
  deleteField: () => new FieldAction()
}

export default createDatabase
