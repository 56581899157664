import { Loader, Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CastType, InvitedCaster } from '@/types/casts'
import { CustomRtmpDestination } from '@/types/streams'
import firebase from 'firebase/compat/app'

export interface CsvCast extends BillingCast {
  resolution: string
  start_date: string
  end_date: string
  outputs: string
  casters: string
}

export interface BillingUpdate {
  by: string
  timestamp: Date
  took: number
}

export interface BillingCast {
  name: string
  id: string
  event_id: string
  start_time: firebase.firestore.Timestamp
  end_time: firebase.firestore.Timestamp
  duration: number
  crosscontinent: boolean
  deleted: boolean
  is_copy: boolean
  watermark: boolean
  record_isos: boolean
  quality: string
  event_name: string
  last_update?: firebase.firestore.Timestamp // only filled in (by server) if there was an update after creation
  // "invited_casters":{}
  // "custom_rtmp_destinations":{}
  custom_rtmp_destinations: KeyDict<CustomRtmpDestination>
  invited_casters: KeyDict<InvitedCaster>
  cast_type: CastType
  audio_track_count: number
}

export class BillingMonth {
  time_calculated: number
  test_duration: number
  free_duration: number
  production_duration: KeyDict<number>
  n_casts: number
  cast_ids: KeyDict<boolean>
  year: number
  month: number

  constructor (data: unknown) {
    this.time_calculated = Loader.loadNumber(data, 'time_calculated', 0)
    this.test_duration = Loader.loadNumber(data, 'test_duration', 0)
    this.free_duration = Loader.loadNumber(data, 'free_duration', 0)
    this.production_duration = Loader.loadAndCast(data, 'production_duration', {})
    this.n_casts = Loader.loadNumber(data, 'n_casts', 0)
    this.cast_ids = Loader.loadAndCast(data, 'cast_ids', {})
    this.year = Loader.loadNumber(data, 'year', 0)
    this.month = Loader.loadNumber(data, 'month', 0)
  }

  static load (data: unknown): Result<BillingMonth, 'invalid_params' | 'unknown'> {
    try {
      return Result.success(new BillingMonth(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}

export class BillingSummary {
  last_update?: BillingUpdate
  months: KeyDict<KeyDict<BillingMonth>>

  constructor (data: unknown) {
    this.last_update = Loader.loadAndCast(data, 'last_update')
    this.months = Loader.loadAndCast(data, 'months', {})
  }

  static load (data: unknown): Result<BillingSummary, 'invalid_params' | 'unknown'> {
    try {
      return Result.success(new BillingSummary(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}

export interface Billing {
  loaded: boolean
  summary: BillingSummary
}
