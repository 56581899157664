import { generateRandomString } from '@/modules/common/utils'
import { KeyDict } from '@/types'
import JanusVideoObject from './JanusVideoObject'

type StreamCallback = (streamId: string) => void

interface WRTCPreviewManagerConfig {
  onNewStreamCallback?: StreamCallback,
  onKillStreamCallback?: StreamCallback
}

export default class WRTCPreviewManager {
  private previewStreams: KeyDict<JanusVideoObject> = {}
  private requestedStream: KeyDict<boolean> = {}
  onNewStreamCallback: StreamCallback|undefined = undefined
  onKillStreamCallback: StreamCallback|undefined = undefined

  constructor (config: WRTCPreviewManagerConfig) {
    this.onNewStreamCallback = config.onNewStreamCallback
    this.onKillStreamCallback = config.onKillStreamCallback
  }

  get streams (): KeyDict<JanusVideoObject> {
    return this.previewStreams
  }

  public addStreamVideoObject (streamUrl: string, streamId: string, useChinaProxy:boolean = false, useUdp: boolean = true ): JanusVideoObject {
    const previewId = generateRandomString(5, false)
    const preview = new JanusVideoObject({
      id: previewId,
      url: streamUrl,
      chinaSupport: useChinaProxy,
      name: 'wrtc-preview-' + previewId,
      logNetwork: false,
      udpEnabled: useUdp,
      srcObject: null,
      callback: () => {
        if (this.onNewStreamCallback) this.onNewStreamCallback(streamId)
      },
      killStream: () => {
        if (this.onKillStreamCallback) this.onKillStreamCallback(streamId)
      },
      restartStream: () => {
        console.log("WRTCPreviewManager: Restart stream requested.", this.requestedStream, streamId, streamUrl)
      }
    })
    return preview
  }

  public addStream (streamUrl: string, streamId: string, useChinaProxy:boolean = false, useUdp: boolean = true ): void {
    this.requestedStream[streamId] = true
    if (!streamUrl || !streamId) {
      console.error('WRTCPreviewManager::addStream:: Stream URL or ID not provided.', streamUrl, streamId)
      return
    }
    if (this.previewStreams[streamId] !== undefined) return
    const preview = this.addStreamVideoObject(streamUrl, streamId, useChinaProxy, useUdp)
    this.previewStreams[streamId] = preview
  }

  public removeStream (streamId: string): void {
    this.requestedStream[streamId] = false
    const stream = this.previewStreams[streamId]
    if (stream !== undefined) {
      stream.stopPlay()
      delete this.previewStreams[streamId]
    } else {
      console.error('WRTCPreviewManager::removeStream:: No valid stream ID provided.', streamId)
    }
  }

  /* Use before destroying view component to prevent memory leaks */
  public removeAllStreams (): void {
    for (const streamId in this.previewStreams) {
      this.previewStreams[streamId].stopPlay()
      delete this.previewStreams[streamId]
    }
  }
}
