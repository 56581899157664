/* eslint-disable max-lines */
import { isObjectWithKey, KeyDict } from '../../../types'
import {
  AudioMixType, CasterRequestCustomAudioMix, CastMessage, CastOutputStream, WATERMARK_DEFAULT
} from '../../../types/casts'
import { Region, SystemStatus } from '../../../types/ops'
import { StreamProtocol } from '../../../types/streams'
import { Widget } from '../../../types/widgets'
import { CastK360Info, RegionalInfo } from '@/modules/casts/types'
import { CastTemplateParameter } from '@/modules/templates/utils/castTemplateParameter'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import { cloneDeep, pickBy } from 'lodash'
import { CastTemplateParameterBase } from '@/modules/templates/utils/castTemplateParameterBase'
import { MediaDevice } from '@/types/usermedia'
import { createClass } from '@/modules/classBuilder/classBuilder'
import { WRTCServerType } from '@/types/admin'
import { PreviewVideoQualityType } from '@/types/users'

// TODO refactor Typescripts Partials (+ any) to full types
class CastBaseLeftOvers extends CastK360Info {
  /** @deprecated */
  id: string = ''
  /** @deprecated This is now basically only used by the deprecated events::copyCast as an internal state variable */
  copying: boolean = false // true when being copied
  originalCast: string|null = null // JSON serialized original this cast was copied from
  asset_groups: KeyDict<boolean> = {}
  create_event: boolean = true
  anonymous_caster_rules = {
    max_anonymous_casters: 5,
    max_total_casters: 10,
    max_anonymous_accounts: 50
  }
  event_id: string = ''
  feedname?: KeyDict<string> = {}
  messages: CastMessage[] = []
  media_upload_url: string = ''
  notes: string = ''
  ngcvp_status?: SystemStatus = SystemStatus.OK
  output_streams: KeyDict<CastOutputStream> = {}
  presets: string[] = []
  self_muted: KeyDict<boolean> = {}
  private srt: boolean = false
  ptsPassthrough: boolean = false
  requestCustomAudioMix?: KeyDict<CasterRequestCustomAudioMix>
  requestSwitchToAudioMix?: KeyDict<AudioMixType>
  bleeped: KeyDict<number> = {}
  talk_back: KeyDict<boolean> = {}
  transcodingregion: Region = Region.USEast
  regionalInfo: RegionalInfo = { wrtcserver: '', fixed: false }
  widgets: KeyDict<Widget> = {}
  teamGroups: string[] = []
  caster_delay?: number
  templateId: string|null = null
  isTempCast: boolean = false
  gop_size: number = 4
  watermark: boolean = WATERMARK_DEFAULT
  version?: string
  creator_id: string = ''
  token: string = ''
  hasLiveClipping: boolean = false
  parameters: KeyDict<CastTemplateParameter>
  wrtcServerType: WRTCServerType = WRTCServerType.WOWZA

  get numTracks (): number {
    return 1
  }

  get canSelectMultipleStreams (): boolean {
    return true
  }

  get showInputStreamsAsList (): boolean {
    return false
  }

  get canModerateGuests (): boolean {
    return true
  }

  get allowedStreamProtocols (): StreamProtocol[] {
    return Object.values(StreamProtocol)
  }

  get allowedMediaDevices (): MediaDevice[] {
    return Object.values(MediaDevice)
  }

  get showInviteLink (): boolean {
    return true
  }

  get isFullscreenAllowed (): boolean {
    return true
  }

  get isCrossContinent (): boolean {
    return this.srt
  }

  set isCrossContinent (crossContinent: boolean) {
    this.srt = crossContinent
  }

  updateCrossContinent (isCrossContinent: boolean): this {
    this.srt = isCrossContinent
    return this
  }

  get isUsingJanus (): boolean {
    return this.wrtcServerType === WRTCServerType.JANUS
  }

  //eslint-disable-next-line max-lines-per-function
  constructor (data?: unknown) {
    super({
      start_date: new Date(),
      end_date: new Date(Date.now() + 4 * 60 * 60 * 1000),
      // @ts-ignore
      ...(data ?? {})
    })
    // @ts-ignore
    for (const key of Object.keys(data ?? {})) {
      // @ts-ignore
      if (this[key] !== undefined) {
        // @ts-ignore
        this[key] = data[key]
      }
    }

    // TODO: Shared with CastTemplate. Should probably become a mixin similar to CastK360Info
    this.parameters = {}
    if (isObjectWithKey(data, 'parameters') && typeof data.parameters === 'object' && data.parameters !== null) {
      Object.entries(data.parameters).forEach(([key, parameter]) => {
        const result = CastTemplateParameter.load(parameter)
        if (result.isSuccess) this.parameters[key] = result.value
        else result.log('Loading CastTemplateParameter failed:')
      })
    }
  }

  // TODO: Shared with CastTemplate. Should probably become a mixin similar to CastK360Info
  // TODO: Ideally this would return a copy instead of modifying the existing object. But that would require us to know
  //       the correct derived class to behave properly.
  async applyParameters (templateParamsData?: KeyDict<unknown>) {
    if (!templateParamsData || Object.keys(this.parameters).length === 0) return
    for (const [parameterId, parameter] of Object.entries(this.parameters)) {
      if (!(parameter instanceof CastTemplateParameterBase)) {
        console.warn(`Parameter ${parameterId} is not a CastTemplateParameterBase derived object`)
        continue
      }
      const paramData = templateParamsData[parameterId]
      if (!isObjectWithKey(paramData, 'value')) continue // No data.value provided for this parameter
      if (paramData.value === null) continue // TODO: Should we support "clearing" a parameter?
      // FIXME: We shouldn't have to use `as Cast` to apply the parameters.
      // @ts-ignore
      const result = await parameter.applyUpdate(this, paramData.value)
      if (!result.isSuccess) result.log(`Failed to apply parameter data '${parameterId}':`)
      else this.parameters[parameterId].value = paramData.value
    }
  }

  updateWithTemplate (template: CastTemplate, templateId: string) {
    for (const [key, value] of Object.entries(template)) {
      // @ts-ignore
      this[key] = value
    }
    this.templateId = templateId
  }

  copy () {
    const copyCast = cloneDeep(this)
    copyCast.originalCast = JSON.stringify({ ...this, originalCast: null })
    return copyCast
  }

  streamFeedNames (streamId: string): string[] {
    const feedNames: string[] = []
    const match = Object.keys(pickBy(this.feedname || {}, (val) => val.startsWith(streamId)))
    if (match) feedNames.push(...match)
    return feedNames
  }

  getPreviewStreamURL (quality: PreviewVideoQualityType = PreviewVideoQualityType.Low): string | undefined {
    if (this.srt && this.isUsingJanus) {
      const server = this.regionalInfo.wrtcserver
      let whepStreamId = `${this.id}_rtsplowres`
      if (quality === PreviewVideoQualityType.High) whepStreamId = `${this.id}_rtsphighres`
      return `https://${server}/whep/endpoint/${whepStreamId}`
    } else {
      return undefined
    }
  }
}

const CastBaseLeftOversWithMixins = createClass(CastBaseLeftOvers)
  .isDeletable()
  .addTeam({ required: false })  // FIXME: remove
  .addName({ required: false })  // FIXME: remove
  .addVersion()
  .addDuration({ required: false }) // FIXME remove
  .addRegion()
  .addChat()
  .addQuality()
  .addStreams()
  .addMarkers()
  .addScenes()
  .addMixer(true) // FIXME: need to move to base classes
  .addOverrides()
  .get()

export class CastBase extends CastBaseLeftOversWithMixins {
  protected updateParametersWithTemplate (newCast: this, template: CastTemplate) {
    // FIXME: If the current cast has for example a template parameter for the "background" box, and the new template
    //        does not have that parameter but does have the "background" box in its scenes, then currently the
    //        `applyParameters()` will still update that box's asset. If we would replace the casts's parameters
    //        with that of the template, we would lose the parameter values of the cast (which we want to keep around
    //        in case we for example change the template back to the old one (which does have the parameters)).
    //        => We should probably store the parameter "values" separately from the parameter "definitions".
    const originalParameterIds = Object.keys(newCast.parameters)
    const templateParameterIds = Object.keys(template.parameters)
    for (const templateParamId of templateParameterIds) {
      const templateParam = template.parameters[templateParamId]
      const castParam = newCast.parameters[templateParamId]
      if (castParam && !castParam.isCompatible(templateParam)) {
        console.warn(`Replacing incompatible cast template parameter ${templateParamId}.`)
      } else if (castParam?.value !== undefined && castParam?.value !== null) templateParam.value = castParam.value
      newCast.parameters[templateParamId] = templateParam
    }
    const missingParameters = originalParameterIds.filter((castParamId) => !templateParameterIds.includes(castParamId))
    if (missingParameters.length > 0) console.error('More Cast parameters than Template Parameters:', missingParameters)
  }

  public getUpdatesWithTemplate (templateId: string, template: CastTemplate): this {
    const newCast = cloneDeep(this)
    newCast.templateId = templateId
    if (template.asset_groups) newCast.asset_groups = template.asset_groups
    if (template.scenes) newCast.scenes = template.scenes
    if (template.scenePresets) newCast.scenePresets = template.scenePresets
    if (newCast.activeScene === null || newCast.scenes[newCast.activeScene] === undefined) {
      newCast.activeScene = newCast.getFirstSceneId() ?? ''
    }
    this.updateParametersWithTemplate(newCast, template)
    return newCast
  }
}
