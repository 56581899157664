import { Email } from '@/modules/profiles/types'
import { KeyDict } from '@/types'

const isBoolean = (val: unknown): val is Boolean => typeof val === 'boolean'

const getKeyDictKeyByValue = <KD extends KeyDict<any>, F extends Function>(dict: KD, lookupFn: F): (keyof KD)|null => {
  for (const key of Object.keys(dict)) {
    if (lookupFn(dict[key])) return key
  }
  return null
}

const generateRandomString = (length: number = 16, includeNumbers: boolean = true): string => {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (includeNumbers) chars += '0123456789'

  let result = ''

  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return result
}

const sanitizeStringForUrl = (url: string): string => {
  //Parses the passed string into a format safe & appropriate for use in a URL,
  //with capitalization removed and all special/reserved characters replaced with dashes instead.
  // eslint-disable-next-line unicorn/better-regex
  return url.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '') //Last regex removes any trailing '-'
}

const trimmedOrNullEmail = (prop: Email|null) => {
  if (prop === null) return null
  const trimmed = prop.trim()
  if (trimmed === '') return null
  return trimmed as Email
}

const trimmedOrNullString = (prop: string|null) => {
  if (prop === null) return null
  const trimmed = prop.trim()
  if (trimmed === '') return null
  return trimmed
}

const trimOrError = (param: string, paramName: string, moduleName: string): string => {
  const trimParam = param?.trim()
  if (!trimParam) throw new Error(`Check failed in ${moduleName}. ${paramName} cannot be empty`)
  return trimParam
}

const errorIfNegative = (param: number, paramName: string, moduleName: string): number => {
  if (param < 0) throw new Error(`Check failed in ${moduleName}. ${paramName}=${param} cannot be negative`)
  return param
}

const errorIfFalsy = (param: any, paramName: string, moduleName: string): any => {
  if (!param) throw new Error(`Check failed in ${moduleName}. ${paramName} cannot be empty`)
  return param
}

// Courtesy: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
const stringHash = async (str: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(str)
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8)
  const hashArray = [...new Uint8Array(hashBuffer)]
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}

const padStartZero = (numText: number) => {
  return String(numText).padStart(2, '0')
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

type TestFunction = () => boolean|Promise<boolean>
const sleepUntilTrue = async (testFunction: TestFunction, maxTimeout: number = 2000) => {
  let count = 0
  const maxIterations = maxTimeout / 10
  while (!(await testFunction()) && count < maxIterations) {
    await sleep(10)
    count++
  }
  return await testFunction()
}

const ceil = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.ceil(value * multiplier) / multiplier
}

const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export {
  ceil,
  errorIfFalsy,
  errorIfNegative,
  generateRandomString,
  getKeyDictKeyByValue,
  isBoolean,
  padStartZero,
  round,
  sanitizeStringForUrl,
  sleep,
  sleepUntilTrue,
  stringHash,
  trimOrError,
  trimmedOrNullEmail,
  trimmedOrNullString
}
