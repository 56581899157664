<template>
  <div
    :class="{
      'app': true,
      'locked': fullscreen,
      'cefEvent': cefEvent,
      'iosFront': isIosView,
      'is-mobile': isMobile
    }"
  >
    <BatteryMonitor v-if="userLoggedIn" />
    <UserMediaConnector />
    <AuthManager />
    <UserProfileContainer />
    <LanguageController v-if="isLanguageControllerEnabled" />
    <LegalUpdatePrompt v-if="userLoggedIn && !isCefOrIosView" />
    <BrowserCheck v-if="!isSupportedBrowserAndSystem" />
    <div
      v-if="showRouter"
      class="base-content"
      :class="{'user-logged-in': userLoggedIn}"
    >
      <router-view />
    </div>
    <portal-target name="app" />
  </div>
</template>

<script lang="ts">
import './App.scss'
import fb from '@/firebase'
import store from '@/store'
import BrowserCheck from '@/components/BrowserCheck'
import AuthManager from '@/components/AuthManager'
import LegalUpdatePrompt from '@/components/LegalUpdatePrompt'
import UserProfileContainer from '@/containers/UserProfileContainer'
import UserMediaConnector from '@/components/UserMediaConnector'
import BatteryMonitor from '@/components/BatteryMonitor'
import LanguageController from '@/modules/translation/components/LanguageController'
import { CallTriage, InvitedCaster } from '@/types/casts'
import { defineComponent, watch, computed } from 'vue'
import useFeatureFlags from './compositions/featureflags'
import { useBrowser } from '@/modules/dashboard/compositions'
import { KeyDict } from './types'
import { AddProfileToCastTeamParams } from './types/users'
import useCurrentCast from './modules/casts/compositions/currentCast'
import { LOCAL_DEV_HOST } from './env'
import { useCurrentUserProfile } from '@/modules/user/compositions'
import { useTeamVersion } from '@/modules/releases/compositions'
import { VersionNumber } from './modules/classBuilder/mixins/hasVersion'
import { useTeamBranding } from './modules/teams/compositions'
import { useCanDo } from '@/modules/abilities/compositions'

export default defineComponent({
  name: 'App',
  setup () {
    const { branding } = useTeamBranding()
    const currentTeam = computed(() => store.state.team.team)
    const { canManageAllTeams } = useCanDo()
    watch(currentTeam, (newTeam, oldTeam) => {
      if (newTeam && newTeam !== oldTeam) {
        document.title = newTeam.name
        const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
        if (link !== null && branding.value.logoUrl) link.href = branding.value.logoUrl
      }
    }, { immediate: true })

    return {
      ...useFeatureFlags(),
      ...useBrowser(),
      ...useCurrentCast(),
      ...useCurrentUserProfile(),
      ...useTeamVersion(),
      canManageAllTeams
    }
  },
  data: () => ({
    fullscreen: false,
    isIosView: false,
    addProfileToCastTeam: fb.functions.httpsCallable('addProfileToCastTeam')
  }),
  computed: {
    customTheme (): string|null {
      return store.state.team.customTheme
    },
    showRouter (): boolean {
      const show = this.isSupportedBrowserAndSystem && !this.showTeamMessage && !this.userHasNoTeams
      if (!this.userLoggedIn || this.cefEvent) return show
      return show && this.isFeatureLoaded
    },
    userLoggedIn (): boolean {
      return store.state.user.userLoggedIn
    },
    loginComplete (): boolean {
      return store.state.user.loginComplete
    },
    cefEvent (): boolean {
      return store.state.events.cefEvent
    },
    isCefOrIosView (): boolean {
      return this.cefEvent || this.isIosView
    },
    inCast (): boolean {
      return store.state.events.inCast
    },
    teamCheck (): boolean|null {
      return store.state.user.teamCheck
    },
    userHasNoTeams (): boolean {
      return store.state.user.userHasNoTeams
    },
    castName (): string {
      return this.currentCast !== null ? this.currentCast.name : ''
    },
    canSwitchTeams (): boolean {
      return this.canManageAllTeams
    },
    showTeamMessage (): boolean {
      let status = false
      if (!this.isCefOrIosView) {
        if (this.teamCheck === false && this.userLoggedIn) {
          status = true
        }
      }
      return status
    },
    isStation (): boolean {
      return this.$route.name === 'station'
    },
    isIngest (): boolean {
      return this.$route.name === 'ingest'
    },
    isPreview (): boolean {
      return this.$route.name === 'preview'
    },
    isLayoutEditor (): boolean {
      return this.$route.name === 'layouteditor'
    },
    isFeatureLoaded (): boolean {
      return store.state.team.isFeatureLoaded
    },
    isAnonymous (): boolean {
      const profile = store.state.user.profile
      return !!profile?.anonymous
    },
    currentInvitedCaster (): InvitedCaster | null {
      if (this.currentUserId === null) return null
      return this.currentCast?.invited_casters?.[this.currentUserId] ?? null
    },
    isLanguageControllerEnabled (): boolean {
      if (this.isCefOrIosView) return false
      if (this.isPreview) return false
      return true
    },
    currentTeamId (): string {
      return store.state.team.activeTeam
    },
    currentTriage (): CallTriage {
      return this.currentInvitedCaster?.triage ?? CallTriage.Init
    },
    isSupportedBrowserAndSystem (): boolean {
      if (this.isCefOrIosView) return true
      if (this.$route.query.noBrowserCheck === 'true') return true
      if (!this.isBrowserSupported) return false
      if (!this.isSystemSupported) return false
      return true
    }
  },
  async created () {
    if (this.redirectOldHostname()) return
    this.$router.afterEach((to) => {
      this.fullscreen = (to.name === 'liveEvent')
    })
  },
  watch: {
    currentVersion: {
      handler (newVal: VersionNumber, oldVal: VersionNumber|null|undefined) {
        // Only redirect if newVal is set and oldVal is either undefined or an empty string
        // This will prevent redirecting during an active cast.
        if (newVal && !oldVal) this.redirect(newVal)
      }, immediate: true
    },
    currentUserId (newVal: string) {
      if (newVal) this.addCurrentUserToCastTeam()
    },
    '$route.name' (newVal: string) {
      this.setBodyData({ route: newVal })

      // FIXME: not using the ios composition for this as the router is not yet defined in the setup. Due to this,
      // it's also not reactive and doesn't update the computed values depending on the router. This issue only
      // occurs in the App.vue, not in other components using that composition.
      this.isIosView = ['iosWrapper', 'iosFront', 'iosBack'].includes(newVal)

      const requiresAuth = this.$route.meta?.requiresAuth ?? false
      if (!requiresAuth) this.setBodyData({ customTheme: 'false' })
    },
    currentTriage () {
      this.trySetCustomTheme()
    },
    customTheme () {
      this.trySetCustomTheme()
    },
    currentTeamId (newVal: string) {
      // if (!this.isAnonymous) return
      store.dispatch.team.setCustomTheme(newVal)
      this.trySetCustomTheme()
    }
  },
  methods: {
    async addCurrentUserToCastTeam (): Promise<void> {
      // If this.currentCast.id is set we're already part of that team. We cannot fetch the cast doc
      // of a team we're not part of. So skip if this.currentCast.id is set
      if (!this.userLoggedIn || this.currentCast?.id) return
      const url = new URL(window.location.href)
      const token = url.searchParams.get('token')
      const castId = url.pathname.split('/event/')[1]
      if (!token || !castId) return
      const result = await this.addProfileToCastTeam({ user_id: this.currentUserId, cast_id: castId, token } as AddProfileToCastTeamParams)
      console.log('addCurrentUserToCastTeam', { url, token, castId, 'result.data': result.data, success: result.data.success })
      if (result.data.success) window.location.reload()
      else store.dispatch.logs.addLog({
        message: `Unable to add user ${ this.currentUserId } to team of cast ${ castId }`,
        level: 'info',
        data: { ...result.data, userId: this.currentUserId, castId: castId, token: token }
      })
    },
    redirect (versionTeam: VersionNumber): void {
      if (this.isCefOrIosView) return
      const url = new URL(window.location.href)
      const path = url.pathname.split('/')
      const versionUrl = path[1] // [0] is an empty string

      if (versionTeam !== versionUrl && url.hostname !== LOCAL_DEV_HOST && !url.hostname.startsWith('192.168')) {
        path[1] = versionTeam
        url.pathname = path.join('/')
        window.location.href = url.href
        return
      }

      let route = url.searchParams.get('redirect')
      if (!route) return

      route = route.replace('%2F', '/')
      this.$router.push(route)
    },
    redirectOldHostname (): boolean {
      const oldHostRegex = /^cloudcast/i
      const url = new URL(window.location.href)
      if (oldHostRegex.test(url.hostname)) {
        url.hostname = url.hostname.replace(oldHostRegex, 'studio')
        window.location.href = url.href
        return true
      }
      return false
    },
    setBodyData (data: KeyDict<string>) {
      const body: HTMLBodyElement|null = document.querySelector('body')
      if (body === null) return
      for (const [key, value] of Object.entries(data)) {
        body.dataset[key] = value
      }
    },
    trySetCustomTheme () {
      const noCustomThemeTriages = [CallTriage.Accepted, CallTriage.Assigned]
      if (noCustomThemeTriages.includes(this.currentTriage)) {
        this.setBodyData({ customTheme: 'false' })
      } else if (this.isAnonymous || !this.userLoggedIn) {
        this.setBodyData({ customTheme: 'true' })
      } else {
        this.setBodyData({ customTheme: 'false' })
      }
    }
  },
  components: {
    BrowserCheck,
    AuthManager,
    UserProfileContainer,
    LegalUpdatePrompt,
    UserMediaConnector,
    BatteryMonitor,
    LanguageController
  }
})
</script>
