<template>
  <portal to="app">
    <div class="modal-mask">
      <!-- tabindex="0" is required to be able to catch keyboard presses -->
      <div
        class="modal-wrapper"
        tabindex="0"
        ref="wrapper"
        @click.self="closeFromOutside"
      >
        <div
          class="modal-container"
          :class="customClass"
          :style="style"
        >
          <div class="modal-header" :class="{'modal-hidden': customPopup}">
            <slot name="header">
              <div class="title">
                {{ title }}
                <sup v-if="beta">Beta</sup>
              </div>
              <KisweButton
                v-if="showClose"
                class="close-button"
                theme="tertiary"
                :size="KisweSize.Small"
                shape="square"
                @click="close"
                data-qa="button-close"
              >
                <KisweIcon name="close" />
              </KisweButton>
            </slot>
          </div>
          <div class="modal-body" :class="{'modal-nopadding': customPopup}">
            <slot />
          </div>
          <div class="modal-footer" :class="{'modal-hidden': customPopup}">
            <slot name="footer"></slot>
            <div class="footer-btns">
              <KisweButton
                v-if="showCancel"
                :size="buttonSize"
                theme="none-hover"
                @click="close"
                data-qa="button-cancel"
              >{{ cancelText }}</KisweButton>
              <slot name="footer-button"></slot>
              <KisweButton
                v-if="showFinish"
                class="submit-btn"
                theme="primary"
                :size="buttonSize"
                data-qa="button-submit"
                @click="submit"
                :disabled="!buttonActive"
                :isLoading="isSubmitLoading"
                v-bind:class="{ disabled: !buttonActive }"
              >{{ submitText }}</KisweButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script lang="ts">
import './Modal.scss'
import { KisweButton, KisweIcon, KisweSize } from 'kiswe-ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Modal',
  components: {
    KisweButton,
    KisweIcon
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    beta: {
      type: Boolean,
      required: false,
      default: false
    },
    submitText: {
      type: String,
      required: false,
      default: 'Submit'
    },
    cancelText: {
      type: String,
      required: false,
      default: 'Cancel'
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true
    },
    showFinish: {
      type: Boolean,
      required: false,
      default: true
    },
    outsideClose: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonActive: {
      type: Boolean,
      required: false,
      default: true
    },
    width: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false
    },
    customPopup: {
      type: Boolean,
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    buttonSize:{
      type: String as () => KisweSize,
      required: false,
      default: KisweSize.Small,
      validator: (value: KisweSize) => Object.values(KisweSize).includes(value)
    },
    isSubmitLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup () {
    return {
      KisweSize
    }
  },
  created () {
    // @keyup.esc isn't reliable for triggering close
    // this works reliably
    document.onkeydown = evt => {
      const keyEvent = (evt ? evt : window.event) as KeyboardEvent|undefined
      if (keyEvent === undefined) return
      if (keyEvent.keyCode === 27) this.close()
    }
  },
  beforeUnmount () {
    document.onkeydown = null
  },
  computed: {
    style (): Partial<CSSStyleDeclaration> {
      const modalStyle: Partial<CSSStyleDeclaration> = {}
      if (this.width !== undefined) {
        modalStyle.width = this.width
      }
      if (this.height !== undefined) {
        modalStyle.height = this.height
      }
      return modalStyle
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    closeFromOutside () {
      if (this.outsideClose) this.close()
    },
    submit () {
      if (this.buttonActive) {
        this.$emit('submitted')
      }
    }
  }
})
</script>
