import { IceCandidateEntry, IceTransport } from '../classes/IceCandidateEntry'

export const filterSdpDebugInfo = (sdp: string) => {
  const lines = sdp.split('\r\n')
  const logLines: string[] = []
  for (const line of lines) {
    if (line.startsWith('a=candidate:') || line.startsWith('c=IN IP4')) {
      logLines.push(line)
    }
  }
  return logLines.join('\r\n')
}

export const filterIpsFromSdp = (sdp: string, ipList: string[]): string => {
  const lines = sdp.split('\r\n')
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    if (line.startsWith('a=candidate:')) {
      const candidate = new IceCandidateEntry(line)
      if (!ipList.includes(candidate.connectionAddress)) {
        lines.splice(i, 1)
        i--
      }
    }
  }
  return lines.join('\r\n')
}

export const filterTransportProtocolFromSdp = (sdp: string, transportProtocol: IceTransport): string => {
  const lines = sdp.split('\r\n')
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    if (line.startsWith('a=candidate:')) {
      const candidate = new IceCandidateEntry(line)
      if (candidate.transport === transportProtocol) {
        lines.splice(i, 1)
        i--
      }
    }
  }
  return lines.join('\r\n')
}
