import helpers from '@/helpers'
import store from '@/store'
import { Team } from '@/modules/teams/classes'
import { computed, onUnmounted, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { useCanDo } from '@/modules/abilities/compositions'

const team = computed(() => store.state.team.team)
const currentUserId = computed(() => store.state.user.id ?? null)
const opsTeams = computed(() => store.state.team.allTeams)
const { canManageAllTeams } = useCanDo()

const useUserTeam = () => {
  let allTeamsSubscribed = false

  const subscribeAllTeams = async () => {
    if (!allTeamsSubscribed) {
      allTeamsSubscribed = true
      await store.dispatch.team.subscribeAllTeams()
    }
  }

  const unsubscribeAllTeams = async () => {
    if (allTeamsSubscribed) {
      allTeamsSubscribed = false
      await store.dispatch.team.unsubscribeAllTeams()
    }
  }

  watch(canManageAllTeams, async (newValue) => {
    if (newValue === true) await subscribeAllTeams()
    if (newValue === false) await unsubscribeAllTeams()
  }, { immediate: true })
  onUnmounted(async () => {
    await unsubscribeAllTeams()
  })
  const allTeams = computed(() => canManageAllTeams.value ? opsTeams.value : undefined)

  const userTeams = computed(() => {
    let sortedTeams: Team[] = []
    if (team.value !== null) sortedTeams.push(team.value)
    if (allTeams.value !== undefined && Object.keys(allTeams.value).length > 0) {
      sortedTeams = cloneDeep(Object.values(allTeams.value))
    } else if (Object.values(store.state.user.teams)?.length > 0) {
      // Non-ops don't have access to all teams, so use the user profile's teams instead.
      sortedTeams = Object.values(cloneDeep(store.state.user.teams))
    }
    sortedTeams.sort((a, b) => a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1)
    return sortedTeams.filter((team) => team.deleted !== true)
  })

  const switchTeam = async (teamId: string) => {
    const newTeamVersion = userTeams.value.find((team) => team.id === teamId)?.version ?? null
    if (currentUserId.value === null) {
      console.error('Cannot switch team if current user is not logged in.')
      return
    }
    await store.dispatch.user.updateProfile({ id: currentUserId.value, currentTeam: teamId })

    const newUrl = helpers.replaceUrlVersion(window.location.toString(), newTeamVersion)
    if (newUrl !== '') {
      window.location.assign(newUrl)
    } else {
      window.location.reload()
    }
  }
  return {
    userTeams,
    switchTeam
  }
}

export default useUserTeam
