import { Loader, Result } from 'kiswe-ui'
import { ClassType } from '@/modules/classBuilder/mixins/base'
import { PointerTypes } from './LinkPointerBase'
import EventLinkPointer from './LinkPointerTypes/EventLinkPointer'
import RedirectLinkPointer from './LinkPointerTypes/RedirectLinkPointer'

export interface PointersState {
  currentPointer: LinkPointer | null
  currentPointerId: string
}

export type LinkPointerRecordType = Readonly<{
  [PointerTypes.EVENT]: typeof EventLinkPointer,
  [PointerTypes.REDIRECT]: typeof RedirectLinkPointer
}>

export type LinkPointer = EventLinkPointer | RedirectLinkPointer
export type MinimumLinkPointerParams<T extends PointerTypes> = Pick<LinkPointer, 'studioVersion'|'team_id'> &
                                                               Partial<LinkPointer> &
                                                               { pointerType: T }
export const LinkPointer = {
  load (data: unknown): Result<LinkPointer, 'invalid_params'|'unknown'> {
    try {
      const type = Loader.loadString(data, 'pointerType', PointerTypes.EVENT) as PointerTypes
      if (type === PointerTypes.EVENT) {
        return Result.success(new EventLinkPointer(data))
      } else if (type === PointerTypes.REDIRECT) {
        return Result.success(new RedirectLinkPointer(data))
      }
      return Result.fail('unknown', 'PointerType does not exist')
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  },

  create <T extends PointerTypes> (params: MinimumLinkPointerParams<T>) {
    return LinkPointer.load(params) as Result<ClassType<LinkPointerRecordType[T]>, 'invalid_params'|'unknown'>
  }
}

export const isEventLinkPointer = (pointer: LinkPointer|null): pointer is EventLinkPointer => {
  return pointer?.pointerType === PointerTypes.EVENT
}
export const isRedirectLinkPointer = (pointer: LinkPointer|null): pointer is RedirectLinkPointer => {
  return pointer?.pointerType === PointerTypes.REDIRECT
}

