import store from '@/store'
import { computed, onUnmounted, watch } from 'vue'

// if undefined, means it is the own user
const useNetworkStats = (userId: string|null = null) => {
  const subUser = computed(() => userId ? userId : store.state.user.id)
  const stats = computed(() => subUser.value ? store.state.networkStats.userStats[subUser.value] : null)

  let activeSubscription: null|string = null
  watch(subUser, async (newSubUser, oldSubUser) => {
    if (newSubUser !== null) {
      activeSubscription = newSubUser
      await store.dispatch.networkStats.subscribeUserStats({ userId: newSubUser })
    }
    if (oldSubUser !== null && oldSubUser !== undefined) {
      activeSubscription = null
      await store.dispatch.networkStats.unsubscribeUserStats(oldSubUser)
    }
  }, { immediate: true })

  onUnmounted(async () => {
    if (activeSubscription !== null) {
      await store.dispatch.networkStats.unsubscribeUserStats(activeSubscription)
    }
  })

  return {
    stats
  }
}

export default useNetworkStats
