import firebase from 'firebase/compat/app'
import { Loader } from 'kiswe-ui'

export const WOWZA_THREAD_LIMIT = 1500
export const WOWZA_CONNECTION_LIMIT = 850
export const JANUS_CONNECTION_LIMIT = 850
export const DEFAULT_WOWZA_SERVER = 'wrtc-va2.kiswe.com'

export class WrtcServerStatBase {

  lastUpdate: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromMillis(0)

  constructor (data: unknown|undefined = undefined) {
    if (data === undefined) return
    this.lastUpdate = Loader.loadAndCast(data, 'lastUpdate', firebase.firestore.Timestamp.fromMillis(0))
  }
}

