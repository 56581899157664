import { Team } from '@/modules/teams/classes'
import CheckFeature from '../checkfeature'

export default class Uhd4kFeature extends CheckFeature {
  override hasFeature (): boolean {
    return this.currentTeam?.features?.uhd4k?.enabled ?? false
  }

  override get description (): string {
    return 'Allow use of 4K UHD resolutions'
  }

  override teamHasFeature (teamId: string): boolean {
    const team = this.teams[teamId]
    return team?.features?.uhd4k?.enabled ?? false
  }

  override assignTeamFeature (team: Partial<Team>, enabled: boolean): void {
    team.features = team.features ?? {}
    team.features.uhd4k = { enabled }
  }

  override isGlobalFeature (): boolean {
    return false
  }
}
