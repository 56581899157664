import { useCanDo } from '@/modules/abilities/compositions'
import store from '@/store'
import { computed, onUnmounted, watch } from 'vue'

const allTeams = computed(() => store.state.team.allTeams)
const { canManageAllTeams } = useCanDo()

const useAllTeams = () => {
  let allTeamsSubscribed = false

  const subscribeAllTeams = async () => {
    if (!allTeamsSubscribed) {
      allTeamsSubscribed = true
      await store.dispatch.team.subscribeAllTeams()
    }
  }

  const unsubscribeAllTeams = async () => {
    if (allTeamsSubscribed) {
      allTeamsSubscribed = false
      await store.dispatch.team.unsubscribeAllTeams()
    }
  }

  watch(canManageAllTeams, async (newValue) => {
    if (newValue === true) await subscribeAllTeams()
    if (newValue === false) await unsubscribeAllTeams()
  }, { immediate: true })
  onUnmounted(async () => {
    await unsubscribeAllTeams()
  })

  return {
    allTeams
  }
}

export default useAllTeams
