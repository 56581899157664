import { Loader, Result } from 'kiswe-ui'
import { ClassType } from '@/modules/classBuilder/mixins/base'
import { CastType } from '@/types/casts'
import { CastSwitcher } from './CastSwitcher'
import { CastFanRoom } from './CastFanRoom'
import { CastStationSwitcher } from './CastStationSwitcher'


export type CastRecordType = Readonly<{
  [CastType.FanRoom]: typeof CastFanRoom,
  [CastType.StationSwitcher]: typeof CastStationSwitcher,
  [CastType.Switcher]: typeof CastSwitcher
}>

export type Cast = CastSwitcher|CastFanRoom|CastStationSwitcher
export type MinimumCastParams<T extends CastType> = Pick<Cast, 'name'|'team_id'|'start_date'|'end_date'> &
                                                    Partial<Cast> &
                                                    { castType: T }

export const Cast = {
  load (data: unknown): Result<Cast, 'invalid_params'|'unknown'> {
    try {
      const castType = Loader.loadString(data, 'castType', CastType.Switcher) as CastType
      switch (castType) {
        case CastType.FanRoom:
          return Result.success(new CastFanRoom(data))
        case CastType.StationSwitcher:
          return Result.success(new CastStationSwitcher(data))
        case CastType.Switcher:
          return Result.success(new CastSwitcher(data))
        default:
          return Result.fail('unknown', 'CastType does not exist')
      }
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  },

  create <T extends CastType> (params: MinimumCastParams<T>) {
    return Cast.load(params) as Result<ClassType<CastRecordType[T]>, 'invalid_params'|'unknown'>
  }
}
