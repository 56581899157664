import { createDirectStore } from 'direct-vuex'
import abilities from './modules/abilities'
import admin from './modules/admin'
import assets from './modules/assets'
import audio from './modules/audio'
import bots from './modules/bots'
import casts from './modules/casts'
import castTemplates from './modules/castTemplates'
import chat from '@/modules/chat/store/chat'
import chatwidget from './modules/chatwidget'
import events from './modules/events'
import layouts from './modules/layouts'
import logs from './modules/logs'
import markers from '@/modules/markers/store'
import { moderationQueue } from '@/modules/moderation/store'
import networkStats from './modules/networkstats'
import opsmonitor from './modules/opsmonitor'
import pointers from './modules/pointers'
import startstopmanager from '@/modules/startstopmanager/store'
import status from './modules/status'
import streams from './modules/streams'
import talkback from '@/modules/talkback/store'
import team from './modules/team/'
import ui from '@/modules/ui/store'
import user from './modules/user'
import usermedia from './modules/usermedia/'
import videoPlayer from './modules/videoplayer'
import { castReplays, replayConfig } from '@/modules/replay/store'

// Does this EVER need to be true?
const debug = false // process.env.NODE_ENV !== 'production'

export const storeDoc = {
  modules: {
    abilities,
    admin,
    assets,
    audio,
    bots,
    castReplays,
    casts,
    castTemplates,
    chat,
    chatwidget,
    events,
    layouts,
    logs,
    markers,
    moderationQueue,
    networkStats,
    opsmonitor,
    pointers,
    replayConfig,
    startstopmanager,
    status,
    streams,
    talkback,
    team,
    ui,
    user,
    usermedia,
    videoPlayer
  },
  strict: debug
} as const

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
} = createDirectStore(storeDoc)

export type AppStore = typeof store

declare module 'vuex' {
  interface Store<S> {  // eslint-disable-line @typescript-eslint/no-unused-vars
    direct: AppStore
  }
}

// https://vuejs.org/api/utility-types#componentcustomproperties
declare module 'vue' {
  interface ComponentCustomProperties {
    $store: typeof store.original
  }
}

export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext }
export default store
