export enum SupportedLocale {
  Chinese = 'zh',
  // Dutch = 'nl',
  English = 'en',
  French = 'fr',
  // Hindi = 'hi',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Portuguese = 'pt',
  Russian = 'ru',
  Spanish = 'es'
}

interface LocaleInfo {
  flag: string,
  title: string
}

export const localeInfo: Record<SupportedLocale, LocaleInfo> = {
  [SupportedLocale.Chinese]: {
    title: '中文',
    flag: 'cn'
  },
  // [SupportedLocale.Dutch]: {
  //   title: 'Nederlands',
  //   flag: 'nl'
  // },
  [SupportedLocale.English]: {
    title: 'English',
    flag: 'us'
  },
  [SupportedLocale.French]: {
    title: 'Français',
    flag: 'fr'
  },
  // [SupportedLocale.Hindi]: {
  //   title: 'हिन्दी',
  //   flag: 'in'
  // },
  [SupportedLocale.Italian]: {
    title: 'Italiano',
    flag: 'it'
  },
  [SupportedLocale.Japanese]: {
    title: '日本語',
    flag: 'jp'
  },
  [SupportedLocale.Korean]: {
    title: '한국어',
    flag: 'kr'
  },
  [SupportedLocale.Portuguese]: {
    title: 'Português',
    flag: 'pt'
  },
  [SupportedLocale.Russian]: {
    title: 'Русский',
    flag: 'ru'
  },
  [SupportedLocale.Spanish]: {
    title: 'Español',
    flag: 'es'
  }
}

export type TranslationKeys =
  'cast_letsgetstarted_text'                      |
  'cast_getstarted_button'                        |
  'cast_loadingcast_text'                         |
  'cast_pleasefillinname_question'                |
  'cast_is_scheduled_text'                        |
  'cast_enter_text'                               |
  'cast_enter_button'                             |
  'cast_consentterms_text'                        |
  'cast_consentterms_error'                       |
  'cast_consentprivacy_text'                      |
  'cast_consentprivacy_error'                     |
  'cast_consentage_text'                          |
  'cast_consentage_error'                         |
  'cast_switch_team_text'                         |
  'cast_switch_team_button'                       |
  'complete_join_text'                            |
  'general_pleasewait_text'                       |
  'general_yourname_label'                        |
  'general_firstname_label'                       |
  'general_lastname_label'                        |
  'general_email_label'                           |
  'general_country_label'                         |
  'general_firstname_error'                       |
  'general_lastname_error'                        |
  'general_requiredallfields_error'               |
  'general_pleasefillinyourname_placeholder'      |
  'general_pleasefillinfirstname_placeholder'     |
  'general_pleasefillinlastname_placeholder'      |
  'general_pleasefillinemail_placeholder'         |
  'general_join_button'                           |
  'general_send_button'                           |
  'general_yes_button'                            |
  'general_no_button'                             |
  'general_ok_button'                             |
  'general_close_button'                          |
  'general_done_button'                           |
  'general_register_button'                       |
  'general_restart_button'                        |
  'general_continue_button'                       |
  'chat_nomessages_placeholder'                   |
  'chat_enteryourmessage_placeholder'             |
  'chat_chat_label'                               |
  'chat_unreadmessage_text'                       |
  'chat_unreadmessages_text'                      |
  'redirect_welcometokiswe_text'                  |
  'redirect_pleasewaittoredirect_text'            |
  'redirect_end_cast_text'                        |
  'redirect_cast_reach_maximum_text'              |
  'redirect_disabled_existing_caster_text'        |
  'redirect_invalidlink_title'                    |
  'redirect_invalidlink_text'                     |
  'redirect_castnotactive_title'                  |
  'redirect_castnotactive_paragraph'              |
  'castwelcome_wowzaissues_error'                 |
  'actiontab_assets_label'                        |
  'actiontab_casters_label'                       |
  'actiontab_inputs_label'                        |
  'actiontab_outputs_label'                       |
  'actiontab_audio_label'                         |
  'actiontab_settings_label'                      |
  'actiontab_clips_label'                         |
  'actiontab_commentators_label'                  |
  'actiontab_crew_label'                          |
  'actiontab_talkback_label'                      |
  'actiontab_chat_label'                          |
  'actiontab_simplecg_label'                      |
  'actiontab_sctemarkers_label'                   |
  'actiontab_interactive_label'                   |
  'actiontab_switcher_label'                      |
  'actiontab_layout_label'                        |
  'actiontab_preset_label'                        |
  'topbar_untileventstarts_text'                  |
  'topbar_untileventends_text'                    |
  'usermedia_startcasting_button'                 |
  'usermedia_connect_button'                      |
  'usermedia_detectdevices_button'                |
  'usermedia_detecting_text'                      |
  'usermedia_clickbutton_tooltip'                 |
  'usermedia_echocancellation_text'               |
  'usermedia_camera_label'                        |
  'usermedia_microphone_label'                    |
  'usermedia_learnaccess_button'                  |
  'usermedia_pressallow_text'                     |
  'castgrawl_mutedbycrew_label'                   |
  'castgrawl_mutedyourself_label'                 |
  'castgrawl_streamhasissues_label'               |
  'audio_copyfromprogram_button'                  |
  'audio_applytoprogram_button'                   |
  'audio_programmix_label'                        |
  'audio_monitor_label'                           |
  'talkback_nomembers_text'                       |
  'talkback_setincomingmaster_button'             |
  'talkback_setincomingindividual_button'         |
  'error_offlinenotice_text'                      |
  'cast_waitforqueue_text'                        |
  'cast_yourpositioninqueue_text'                 |
  'cast_needpermissions_text'                     |
  'cast_deniedpermission_text'                    |
  'cast_deniedguestlink_text'                     |
  'cast_deviceerror_text'                         |
  'cast_deniednotinvited_text'                    |
  'cast_tryingtoconnect_text'                     |
  'cast_waitingforcast_title'                     |
  'cast_waitingforcast_text'                      |
  'cast_waitingforcast_guide1_title'              |
  'cast_waitingforcast_guide2_title'              |
  'cast_waitingforcast_guide3_title'              |
  'cast_waitingforcast_guide1_text'               |
  'cast_waitingforcast_guide2_text'               |
  'cast_waitingforcast_guide3_text'               |
  'cast_waitingforcastautoaccept_text'            |
  'cast_everythingsetup_text'                     |
  'cast_moderatorpickyouup_text'                  |
  'cast_chattingwithmoderator_text'               |
  'cast_moderatorrejected_text'                   |
  'cast_moderatorrequestedstreamrestart_text'     |
  'casttypeselector_switcher_name'                |
  'casttypeselector_switcher_description'         |
  'casttypeselector_switcher_short_description'   |
  'casttypeselector_fanroom_name'                 |
  'casttypeselector_fanroom_description'          |
  'casttypeselector_fanroom_short_description'    |
  'casttypeselector_stationswitcher_name'         |
  'casttypeselector_stationswitcher_description'  |
  'casttypeselector_stationswitcher_short_description' |
  'endsession_thanks_text'                        |
  'endsession_thankforcoming_text'                |
  'endsession_thankforcomingtocast_text'          |
  'endsession_closetab_text'                      |
  'endsession_backtologin_button'                 |
  'tally_roomactive_text'                         |
  'tally_roomlive_text'                           |
  'leavecast_popup_title'                         |
  'leavecast_popup_description'                   |
  'leavecast_popupanonymous_title'                |
  'leavecast_popupanonymous_description'          |
  'selftest_setup_text'                           |
  'selftest_running_text'                         |
  'selftest_fail_text'                            |
  'selftest_restart_text'                         |
  'selftest_casting_text'                         |
  'selftest_noreply_text'                         |
  'selftest_canyouseelocal_question'              |
  'selftest_canyouseeremote_question'             |
  'selftest_canyouseeyourselflocal_body'          |
  'selftest_canyouseeyourselfremote_body'         |
  'selftest_canyouhearlocal_question'             |
  'selftest_canyouhearremote_question'            |
  'selftest_pleasecloseapps_question'             |
  'selftest_selectothercamera_question'           |
  'selftest_checkyourvolume_question'             |
  'selftest_checkyourheadset_question'            |
  'selftest_seeotherguests_question'              |
  'selftest_hearotherguests_question'             |
  'selftest_performance_question'                 |
  'selftest_successresult_message'                |
  'selftest_successresult_body'                   |
  'selftest_failresult_message'                   |
  'selftest_failresult_body'                      |
  'selftest_onsuccess_button'                     |
  'selftest_onfail_button'                        |
  'selftest_checkcpuandmemory_message'            |
  'selfteststats_canseeselflocal_term'            |
  'selfteststats_canseeselfremote_term'           |
  'selfteststats_canhearselfremote_term'          |
  'selfteststats_canseeothers_term'               |
  'selfteststats_canhearothers_term'              |
  'browsercheck_browser_label'                    |
  'browsercheck_version_label'                    |
  'browsercheck_supportbrowser_text'              |
  'browsercheck_supportmobilebrowser_text'        |
  'browsercheck_download_chrome'                  |
  'browsercheck_download_edge'                    |
  'browsercheck_download_safari'                  |
  'browsercheck_download_firefox'                 |
  'browsercheck_appstore_paragraph'               |
  'browsercheck_systemnotsupported_paragraph'     |
  'browsercheck_ioscontinueweb_paragraph'         |
  'browsercheck_ioscontinueweb_button'            |
  'statusstrip_leaveevent_tooltip'                |
  'statusstrip_selectdevices_tooltip'             |
  'statusstrip_selectdevicesdisabled_tooltip'     |
  'screenmode_pleaserotate_title'                 |
  'screenmode_pleaserotateios_text'               |
  'screenmode_pleaserotateandroid_text'           |
  'screenmode_pleaserotate_actionbutton'          |
  'athletemoments_welcome_title'                  |
  'athletemoments_redirect_paragraph'             |
  'privatecall_nolocalstream_error'               |
  'privatecall_sendwarning_notification'          |
  'privatecall_sendwarning_label'                 |
  'privatecall_sendwarning_footer'                |
  'srt_type_passthrough_label'                    |
  'srt_type_multitrack_label'

export type StringTranslation = Record<TranslationKeys, string>
