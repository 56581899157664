import { KeyDict } from '@/types'
import { filterTransportProtocolFromSdp, filterIpsFromSdp } from '@/modules/janus/utils/utils'
import { IceTransport } from '@/modules/janus/classes/IceCandidateEntry'

type CloseCallbackFunction = () => void

export interface WhipClientJanusOptions {
  audio_bitrate: number,
  chinaIceCandidatesIpList: string[],
  closeCallback: CloseCallbackFunction|null,
  connectionCheckInterval: number,
  force_protocol: IceTransport|null,
  video_bitrate: number
}

interface WhipLinkData {
  url: string,
  params: KeyDict<string>
}

interface IceServer {
  urls: string,
  [key:string]: string
}

interface TransceiverInfo {
  mid: string,
  kind: string,
  candidates: RTCIceCandidate[]
}

const addAuthorizationHeader = (headers: Record<string, string>, token: string|null) => {
  if (token) headers.Authorization = 'Bearer ' + token
}

//still need to join parts of whip/whep client.

export class WhipClient {
  options: WhipClientJanusOptions
  iceUsername: string|null = null
  icePassword:string|null = null
  candidates: RTCIceCandidate[] = []
  endOfCandidates: boolean = false
  token: string|null = null
  pc: RTCPeerConnection|null = null
  iceTrickleTimeout: NodeJS.Timeout|null = null
  connectedTimeout: NodeJS.Timeout|null = null
  resourceURL: URL|null = null
  restartIce: boolean = false
  onCloseCallback: CloseCallbackFunction|null
  connectionClosed: boolean = false

  constructor (options: Partial<WhipClientJanusOptions>)	{
    const defaultOptions: WhipClientJanusOptions = {
      closeCallback: null,
      force_protocol: null,
      video_bitrate: 2000 * 1000,
      audio_bitrate: 128 * 1000,
      connectionCheckInterval: 500,
      chinaIceCandidatesIpList: [],
    }
    this.options = { ...defaultOptions, ...options }
    //Ice properties
    this.iceUsername = null
    this.icePassword = null
    //Pending candidadtes
    this.candidates = []
    this.endOfCandidates = false
    this.onCloseCallback = this.options.closeCallback ?? null
  }

	public removeCandidates (sdp: string, protocol: IceTransport|undefined) {
    let modifiedSdp = sdp
    if (protocol) {
		  modifiedSdp = filterTransportProtocolFromSdp(sdp, protocol)
    }
		if (this.options.chinaIceCandidatesIpList && this.options.chinaIceCandidatesIpList.length > 0) {
			modifiedSdp = filterIpsFromSdp(sdp, this.options.chinaIceCandidatesIpList)
		}
		return modifiedSdp
	}

  async setEncoding () {
    if (!this.pc) {
      console.warn('Setting Encoding without connection')
      return
    }
    this.connectedTimeout = null
    const senders = this.pc.getSenders()
    for (const sender of senders) {
      if (sender.track?.kind === 'video') {
        const parameters = sender.getParameters()
        if (parameters.encodings && this.options.video_bitrate) {
          parameters.encodings[0].maxBitrate = this.options.video_bitrate * 1000
        }
        await sender.setParameters(parameters)
      }
      if (sender.track?.kind === 'audio') {
        const parameters = sender.getParameters()
        if (parameters.encodings && this.options.audio_bitrate) {
          parameters.encodings[0].maxBitrate = this.options.audio_bitrate * 1000
        }
        await sender.setParameters(parameters)
      }
    }
  }

  async publish (pc: RTCPeerConnection, url: string, token: string|null)	{
    console.debug('publishing', { pc, url, token })
    //If already publishing
    if (this.pc) {
      console.log('Publishing failed, already publishing')
      return
    }
    this.connectionClosed = false
    //Store pc object and token
    this.token = token
    this.pc = pc
    //Listen for state change events
    pc.onconnectionstatechange = async () => {
      console.debug('onconnectionstatechange', pc.connectionState)
      switch (pc.connectionState) {
        case 'connected':
          await this.setEncoding()
          break
        case 'disconnected':
          break
        case 'failed':
          // One or more transports has terminated unexpectedly or in an error
          break
        case 'closed':
          // The connection has been closed
          break
      }
    }

    pc.onsignalingstatechange = () => {
      console.debug('signal state changes', pc.signalingState)
    }

    pc.onicegatheringstatechange = () => {
      console.debug('onicegatheringstatechange', pc.iceGatheringState)
    }

    //Listen for candidates
    pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      console.debug('onicecandidate', event.candidate)
      if (event.candidate) {
        //Ignore candidates not from the first m line
        if (event.candidate.sdpMLineIndex && event.candidate.sdpMLineIndex > 0) return
        //Store candidate
        this.candidates.push(event.candidate)
      } else {
        //No more candidates
        this.endOfCandidates = true
      }
      //Schedule trickle on next tick
      /* eslint-disable @typescript-eslint/no-misused-promises */
      if (!this.iceTrickleTimeout) {
        this.iceTrickleTimeout = setTimeout(() => this.trickle(), 0)
      }
    }
    //Create SDP offer
    const offer = await pc.createOffer()

    //Request headers
    const headers: HeadersInit = { 'Content-Type': 'application/sdp' }
    addAuthorizationHeader(headers, token)

    //Do the post request to the WHIP endpoint with the SDP offer
    console.debug('start fetching whip POST', offer.sdp)
    const fetched = await fetch(url, {
      method: 'POST',
      body: offer.sdp,
      headers: headers
    })
    if (!fetched.ok) {
      console.warn('Request rejected with status ' + fetched.status)
      return
    }
    const locationFromHeader = fetched.headers.get('location')
    if (!locationFromHeader) {
      console.warn('Response missing location header')
      return
    }
    this.resourceURL = new URL(locationFromHeader, url)
    //Get the links
    const links: KeyDict<WhipLinkData[]> = {}
    //If the response contained any
    if (fetched.headers.has('link')) {
      //Get all links headers
      const linkHeaders = fetched.headers.get('link')?.split(/,\s+(?=<)/) ?? []

      //For each one
      for (const header of linkHeaders) {
        try	{
          const params: KeyDict<string> = {}
          let rel: string|null = null
          //Split in parts
          const items = header.split(';')
          //Create url server
          const url = items[0].trim().replace(/<(.*)>/, '$1').trim()
          //For each other item
          for (let i = 1; i < items.length; ++i) {
            //Split into key/val
            const subitems = items[i].split(/=(.*)/)
            //Get key
            const key = subitems[0].trim()
            //Unquote value
            const value = subitems[1]
              ? subitems[1].trim().replaceAll('"', '').replaceAll("'", '')
              : subitems[1]
            //Check if it is the rel attribute
            if (key == 'rel') {
              //Get rel value
              rel = value
            } else {
              //Unquote value and set them
              params[key] = value
            }
          }
          //Ensure it is an ice server
          if (!rel) continue
          if (!links[rel]) {
            links[rel] = []
          }
          //Add to config
          links[rel].push({url, params})
        } catch (e) {
          console.warn(e)
        }
      }
    }
    //Get current config
    const config = pc.getConfiguration()
    //If it has ice server info and it is not overriden by the client
    if ((!config.iceServers || !config.iceServers.length) && links.hasOwnProperty('ice-server')) {
      //ICe server config
      config.iceServers = []
      //For each one
      for (const server of links['ice-server']) {
        try {
          //Create ice server
          const iceServer: IceServer = { urls : server.url }
          //For each other param
          for (const [key,value] of Object.entries(server.params)) {
            //Get key in cammel case
            const cammelCase = key.replace(/([-_][a-z])/ig, $1 => $1.toUpperCase().replace('-', '').replace('_', ''))
            //Unquote value and set them
            iceServer[cammelCase] = value
          }
          console.debug('Adding link-header ICE Server', iceServer)
          //Add to config
          config.iceServers.push(iceServer)
        } catch {}
      }

      //If any configured, set it
      if (config.iceServers.length) {
        pc.setConfiguration(config)
      }
    }

    //Get the SDP answer
    let answer = await fetched.text()

    // filter only udp/tcp if we want to restrict transport
    let remove_protocol = null
    if (this.options.force_protocol === 'udp') {
      remove_protocol = IceTransport.TCP
    }
    if (this.options.force_protocol === 'tcp') {
      remove_protocol = IceTransport.UDP
    }
    if (remove_protocol) {
      answer = this.removeCandidates(answer, remove_protocol)
    }
    //Schedule trickle on next tick
    if (!this.iceTrickleTimeout) {
      this.iceTrickleTimeout = setTimeout(() => this.trickle(), 0)
    }
    //Set local description
    await pc.setLocalDescription(offer)
    if (offer.sdp) {
      const match_username = offer.sdp.match(/a=ice-ufrag:(.*)\r\n/)
      const match_password = offer.sdp.match(/a=ice-pwd:(.*)\r\n/)
      if (match_username && match_username.length > 1) this.iceUsername = match_username[1]
      if (match_password && match_password.length > 1) this.icePassword = match_password[1]
    }
    //And set remote description
    await pc.setRemoteDescription({ type: 'answer', sdp: answer })
    if (this.connectedTimeout === null) {
      this.connectedTimeout = setInterval(() => this.checkConnectionStatus(), this.options.connectionCheckInterval)
    }
  }

  restart () {
    //Set restart flag
    this.restartIce = true
    //Schedule trickle on next tick
    if (this.iceTrickleTimeout) return
    this.iceTrickleTimeout = setTimeout(() => this.trickle(), 0)
  }

  async trickle () {
    this.iceTrickleTimeout = null
    //Check if there is any pending data
    const hasPendingData = (this.candidates.length || this.endOfCandidates || this.restartIce)
    if (!hasPendingData || !this.resourceURL) return
    //Get data
    const candidates = this.candidates
    let endOfCandidates = this.endOfCandidates
    const restartIce = this.restartIce
    //Clean pending data before async operation
    this.candidates = []
    this.endOfCandidates = false
    this.restartIce = false
    //If we need to restart
    if (restartIce && this.pc) {
      //Restart ice
      this.pc.restartIce()
      //Create a new offer
      const offer = await this.pc.createOffer({iceRestart: true})
      //Update ice
      if (offer.sdp) {
        const match_username = offer.sdp.match(/a=ice-ufrag:(.*)\r\n/)
        const match_password = offer.sdp.match(/a=ice-pwd:(.*)\r\n/)
        if (match_username && match_username.length > 1) this.iceUsername = match_username[1]
        if (match_password && match_password.length > 1) this.icePassword = match_password[1]
      }
      //Set it
      await this.pc.setLocalDescription(offer)
      //Clean end of candidates flag as new ones will be retrieved
      endOfCandidates = false
    }
    //Prepare fragment
    let fragment =
      'a=ice-ufrag:' + this.iceUsername + '\r\n' +
      'a=ice-pwd:' + this.icePassword + '\r\n'
    //Get peerconnection transceivers
    const transceivers = this.pc?.getTransceivers() ?? []
    //Get medias
    const medias: KeyDict<TransceiverInfo> = {}
    //If doing something else than a restart
    if (candidates.length || endOfCandidates && transceivers.length > 0) {
      //Create media object for first media always
      if (transceivers[0].mid) {
        medias[transceivers[0].mid] = {
          mid: transceivers[0].mid,
          kind: transceivers[0].receiver.track.kind,
          candidates: [],
        }
      }
    }
    //For each candidate
    for (const candidate of candidates) {
      //Get mid for candidate
      const mid = candidate.sdpMid
      //Get associated transceiver
      const transceiver = transceivers.find(t=>t.mid==mid)
      //Get media
      if (mid) {
        let media = medias[mid] ?? undefined
        //If not found yet
        if (!media && transceiver) {
          //Create media object
          media = medias[mid] = {
            mid,
            kind: transceiver.receiver.track.kind,
            candidates: [],
          }
        }
        //Add candidate
        media.candidates.push(candidate)
      }
    }
    //For each media
    for (const media of Object.values(medias)) {
      //Add media to fragment
      fragment +=
        'm='+ media.kind + ' 9 RTP/AVP 0\r\n' +
        'a=mid:'+ media.mid + '\r\n'
      //Add candidate
      for (const candidate of media.candidates) {
        fragment += 'a=' + candidate.candidate + '\r\n'
      }
      if (endOfCandidates) {
        fragment += 'a=end-of-candidates\r\n'
      }
    }
    const headers: HeadersInit = { 'Content-Type': 'application/trickle-ice-sdpfrag' }
    addAuthorizationHeader(headers, this.token)
    //Do the post request to the WHIP resource
    console.debug('PATCH', fragment)
    const fetched = await fetch(this.resourceURL.toString(), {
      method: 'PATCH',
      body: fragment,
      headers
    })
    if (!fetched.ok) {
      console.warn('Request rejected with status ' + fetched.status)
      return
    }
    //If we have got an answer
    if (fetched.status == 200) {
      //Get the SDP answer
      const answer = await fetched.text()
      //Get remote icename and password
      let iceUsername = null
      let icePassword = null
      if (answer) {
        const match_username = answer.match(/a=ice-ufrag:(.*)\r\n/)
        const match_password = answer.match(/a=ice-pwd:(.*)\r\n/)
        if (match_username && match_username.length > 1) iceUsername = match_username[1]
        if (match_password && match_password.length > 1) icePassword = match_password[1]
      }
      if (this.pc && iceUsername && icePassword) {
        //Get current remote description
        const remoteDescription = this.pc.remoteDescription?.toJSON()
        //Patch
        if (remoteDescription) {
          remoteDescription.sdp = remoteDescription.sdp.replaceAll(/(a=ice-ufrag:)(.*)\r\n/gm	, '$1' + iceUsername + '\r\n')
          remoteDescription.sdp = remoteDescription.sdp.replaceAll(/(a=ice-pwd:)(.*)\r\n/gm	, '$1' + icePassword + '\r\n')
          await this.pc.setRemoteDescription(remoteDescription)
        }
      }
    }
  }

  private checkConnectionStatus () {
    if (!this.pc) return
    if (this.pc.connectionState === 'closed' && !this.connectionClosed) {
      this.connectionClosed = true
      if (this.onCloseCallback) this.onCloseCallback()
    }
  }

  async mute (muted: boolean)	{
    //Request headers
    const headers: HeadersInit = { 'Content-Type': 'application/json' }
    addAuthorizationHeader(headers, this.token)
    //Do the post request to the WHIP resource
    if (this.resourceURL) {
      await fetch(this.resourceURL.toString(), {
        method: 'POST',
        body: JSON.stringify(muted),
        headers
      })
    }
  }

  async cleanup (url: string, token: string|null) {
    const headers: HeadersInit = {}
    addAuthorizationHeader(headers, token)
    await fetch(url, { method: 'DELETE', headers })
  }

  async stop ()	{
    this.connectionClosed = true
    //Cancel any pending timeout
    if (this.iceTrickleTimeout) clearTimeout(this.iceTrickleTimeout)
    this.iceTrickleTimeout = null
    if (this.connectedTimeout) clearTimeout(this.connectedTimeout)
    this.connectedTimeout = null
    //Close peerconnection
    if (this.pc) {
      this.pc.close()
      this.pc = null
    }
    if (this.resourceURL) {
      await this.cleanup(this.resourceURL.toString(), this.token)
    }
  }

  get peerConnection () {
    return this.pc
  }
}

export default WhipClient
