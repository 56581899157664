import { Cast } from '@/modules/casts/classes'
import { generateRandomString } from '@/modules/common/utils'
import studio from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { Loader, Result, ResultVoid } from 'kiswe-ui'
import { maybeReplaceAssetBoxContents } from '@/modules/scenes/utils/sceneAssets'
import { isObjectWithKey, KeyDict } from '@/types'
import { CollectionNames } from '@/modules/database/utils'
import { Asset, AssetType } from '@/types/assets'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import { SceneContent } from '@/types/sceneContent'
import { Scene } from '@/types/scenes'
import { CastTemplateParameterType, CastTemplateParameterValueType } from './castTemplateParameter'
import { CastTemplateParameterBase } from './castTemplateParameterBase'
import { SceneContentType } from '@/modules/scenes/types'

type SupportedValueTypesBox = CastTemplateParameterValueType.Country|CastTemplateParameterValueType.Url
const isSupportedValueTypeBox = (value: CastTemplateParameterValueType): value is SupportedValueTypesBox => {
  return [CastTemplateParameterValueType.Country, CastTemplateParameterValueType.Url].includes(value)
}

export class CastTemplateParameterBox extends CastTemplateParameterBase {
  override type = CastTemplateParameterType.Box
  override valueType: SupportedValueTypesBox
  boxId: string

  constructor (data: unknown) {
    super(data)
    // FIXME: Is there a way to let TypeScript know `valueType` is being loaded inside super()?
    // @ts-ignore
    if (!isSupportedValueTypeBox(this.valueType)) {
      // @ts-ignore
      throw new Error(`Unsupported CastTemplateParameterValueType ${this.valueType}`)
    }
    this.boxId = Loader.loadString(data, 'boxId')
  }

  protected getAssetUrl (paramValue: unknown): string|null {
    if (this.valueType === CastTemplateParameterValueType.Country && isObjectWithKey(paramValue, 'flagUrl')
        && typeof paramValue.flagUrl === 'string')
    {
      return paramValue.flagUrl || null // don't allow empty string
    }
    else if (this.valueType === CastTemplateParameterValueType.Url && typeof paramValue === 'string') {
      return paramValue || null
    }
    return null
  }

  protected maybeUpdateImageAsset (scenes: KeyDict<Scene>, assetId: string) {
    const newSceneContent: SceneContent = {
      active: true,
      box: this.boxId,
      id: assetId,
      name: `${this.boxId}_${assetId}`,
      order: 0,
      type: AssetType.Graphic,
      contentType: SceneContentType.Content
    }
    return maybeReplaceAssetBoxContents(scenes, assetId, newSceneContent, this.boxId)
  }

  protected constructAsset (teamId: string, assetId: string, assetUrl: string) {
    return new Asset({
      asset_group: '',
      deleted: false,
      id: assetId,
      name: `${this.boxId}_${assetId}`,
      original_url: assetUrl,
      stars: 0,
      team_id: teamId,
      thumbnail: assetUrl,
      timestamp: Date.now(),
      type: AssetType.Graphic,
      url: assetUrl
      //TAG_FIELD: true
    })
  }

  protected async createOrUpdateAsset (teamId: string, assetId: string, assetUrl: string) {
    if (!teamId || !assetId || !assetUrl) {
      return Result.fail('modify_template', `Invalid asset parameters ${teamId}, ${assetId} or ${assetUrl}`)
    }
    const asset = await studio.db.collection(CollectionNames.ASSETS).doc(assetId).get()
    let result: ResultVoid<'database'> = Result.ok()
    if (!asset.isSuccess) {
      const newAsset = this.constructAsset(teamId, assetId, assetUrl)
      result = await studio.db.collection(CollectionNames.ASSETS).doc(assetId).set(newAsset, MergeOption.OVERWRITE)
      // FIXME: Can we somehow log this to Logz without going via the store?
      console.log('Created new asset', assetId)
    } else if (asset.value.url !== assetUrl) {
      // Update existing asset if url no longer matches.
      const assetData: Partial<Asset> = {
        thumbnail: assetUrl,
        timestamp: Date.now(),
        url: assetUrl
      }
      result = await studio.db.collection(CollectionNames.ASSETS).doc(assetId).set(assetData, MergeOption.MERGE)
      // FIXME: Can we somehow log this to Logz without going via the store?
      console.log('Updated asset', assetId)
    }
    return result
  }

  async applyUpdate (templateOrCast: CastTemplate|Cast, paramValue: unknown):
                     Promise<ResultVoid<'casttemplate_param_update'>> {
    if (!templateOrCast.team_id) return Result.fail('casttemplate_param_update', 'Template/Cast has no team_id')
    const assetUrl = this.getAssetUrl(paramValue)
    if (assetUrl === null) return Result.fail('casttemplate_param_update', 'Unable to determine asset url')

    // FIXME: We should have a deterministic pattern, so we can check if the asset already exits, and if so, only update
    //        its url. Currently a new asset will get created each time `applyUpdate()` is called.
    const assetId = `cast_${generateRandomString()}_${this.boxId}`
    const shouldUpdateAsset = this.maybeUpdateImageAsset(templateOrCast.scenes, assetId)
    if (shouldUpdateAsset) {
      const assetResult = await this.createOrUpdateAsset(templateOrCast.team_id, assetId, assetUrl)
      if (!assetResult.isSuccess) {
        return Result.fail('casttemplate_param_update', `Failed to create/update asset: ${assetResult.message}`)
      }
    }

    return Result.ok()
  }

  override isCompatible (param: CastTemplateParameterBase): boolean {
    return super.isCompatible(param) && (param as CastTemplateParameterBox).boxId === this.boxId
  }
}
