import { moduleActionContext } from '@/store'
import state from './events.state'
import actions from './events.actions'
import mutations from './events.mutations'
import getters from './events.getters'
import { ActionContext } from 'vuex'
import { EventsState, RootState } from '@/store/types'
import { defineModule } from "direct-vuex"

export type EventsContext = ActionContext<EventsState, RootState>

const eventsModule = defineModule({
  namespaced: true,
  state,
  actions,
  getters,
  mutations
})

export default eventsModule
export const eventsModuleActionContext = (context: EventsContext) => moduleActionContext(context, eventsModule)
