import { Loader } from 'kiswe-ui'
import { GConstructor, Mixin, StudioBaseMixin } from './base'


type Resolution = '0720'|'1080'
type InterlacedMode = 'i'|'p'
type Bitrates = '001500'|'002500'|'003000'|'004500'|'006000'|'008000'|'010000'|'012000'|'016000'
type Bitrates4k = '016000'|'020000'|'025000'
type AudioChannels = ''|'c6'
export type Framerate = '25Hz'|'29.96Hz'|'30Hz'|'50Hz'|'59.94Hz'|'60Hz'

type Quality = `${Resolution}${InterlacedMode}${Bitrates}`|'1080p010000c6'|`2160p${Bitrates4k}${AudioChannels}`

export function hasQuality<TBase extends StudioBaseMixin> (Base: TBase) {
  return class QualityClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.quality = Loader.loadString(arg[0], 'quality', '0720p006000') as Quality
      this.framerate = Loader.loadString(arg[0], 'framerate', '30Hz') as Framerate
    }
    quality: Quality
    framerate: Framerate
    static __constructsHasQuality = true
  }
}

export type HasQuality = Mixin<typeof hasQuality>

export const constructsQualityMixin = (func: unknown): func is GConstructor<HasQuality> => {
  if (typeof func !== 'function' || func === null) return false
  if ('__constructsHasQuality' in func) return true
  return false
}
