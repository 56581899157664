import { Loader } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CastStatus, CastType, Clip } from '@/types/casts'

export abstract class CastK360Info {
  start_date: Date
  end_date: Date
  status: CastStatus

  mp4: string|null
  m3u8: string|null
  k360_event_id: number|string|null
  k360_event_id_backup: number|string|null
  liveClipping: boolean
  clips: KeyDict<Clip>
  video_start_time?: number = 0
  video_start_time_backup: number = 0

  abstract get numTracks (): number

  constructor (data: unknown) {
    this.start_date = Loader.loadDate(data, 'start_date')
    this.end_date = Loader.loadDate(data, 'end_date')
    this.status = Loader.loadString(data, 'status', CastStatus.Unknown) as CastStatus
    this.mp4 = Loader.loadStringOrNull(data, 'mp4', null)
    this.m3u8 = Loader.loadStringOrNull(data, 'm3u8', null)
    this.k360_event_id = Loader.loadAndCast<number | string>(data, 'k360_event_id', null)
    this.k360_event_id_backup = Loader.loadAndCast<number | string>(data, 'k360_event_id_backup', null)
    this.liveClipping = Loader.loadBoolean(data, 'liveClipping', false)
    this.clips = Loader.loadAndCast<KeyDict<Clip>>(data, 'clips', {})
    // @ts-ignore
    if (data.video_start_time !== undefined) {
      // @ts-ignore
      this.video_start_time = data.video_start_time // This should become a number or null
    }
    this.video_start_time_backup = Loader.loadNumber(data, 'video_start_time_backup', 0)
  }

  public cleanupK360Info () {
    this.status = CastStatus.Unknown
    this.mp4 = null
    this.m3u8 = null
    this.k360_event_id = null
    this.k360_event_id_backup = null
    this.video_start_time = 0
    this.video_start_time_backup = 0
  }
}

export enum ConnectionTabId {
  Connect = 'connect',
  Disconnect = 'disconnect',
  TalkbackCasterToCrew = 'talkback-caster-crew',
  TalkbackCrew = 'talkback-crew',
  TalkbackAll = 'talkback-all',
  SelfMuteActive = 'self-mute-active',
  SelfMuteInactive = 'self-mute-inactive',
  Chat = 'chat',
  ScreenShareStart = 'screen-share-start',
  ScreenShareStop = 'screen-share-stop'
}

export const CAST_TYPE_LABEL: Record<CastType, string> = {
  [CastType.Switcher]: 'Switcher',
  [CastType.FanRoom]: 'Fan room',
  [CastType.StationSwitcher]: 'Station switcher'
}

export enum CrewLocation {
  Continent = 'continent',
  Global = 'global'
}

export const CREW_LOCATION_LABELS: Record<CrewLocation, string> = {
  [CrewLocation.Continent]: '1 Continent',
  [CrewLocation.Global]: 'Global'
}

export interface CastDetailCsv {
  name: string
  url: string
  type: string
  template: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  bitRate: number
  resolution: string
  frameRate: string
  crewLocation: string
  region: string
  athleteFName: string
  athleteLName: string
  discipline: string
  country: string
  venue: string
  china: string
  backup: string
  backupRegion: string,
  guestLink: string
}

export interface CastParticipantInfo {
  userId: string
  castId: string
  name: string
}

export interface RegionalInfo {
  chinaProxyUrl?: string
  fixed: boolean,
  rtmpServer?: string,
  rtmpServerBackup?: string,
  wrtcserver: string,
  wrtcServerBackup?: string
}
