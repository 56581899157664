import { Team } from '@/modules/teams/classes'
import studio from '@/modules/database/utils'
import { KeyDict } from '@/types'
import { CollectionNames } from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { Feature } from './featureFlags'

export default abstract class CheckFeature {
  protected teams: KeyDict<Team> = {}
  protected currentTeamId: string|null = null
  protected featureFlags: Map<Feature, boolean> = new Map()
  public featuresLoaded: boolean = false

  protected get currentTeam (): Team|null {
    if (this.currentTeamId === null) return null
    return this.teams[this.currentTeamId] ?? null
  }

  constructor () {}
  updateTeamInfo (teams: KeyDict<Team>, currentTeamId: string) {
    this.teams = teams
    this.currentTeamId = currentTeamId
  }
  updateGlobalFeatureFlags (featureFlags: Map<Feature, boolean>) {
    this.featureFlags = featureFlags
    this.featuresLoaded = true
  }
  abstract hasFeature (): boolean
  abstract teamHasFeature (teamId: string): boolean
  abstract assignTeamFeature (team: Partial<Team>, enabled: boolean): void

  // If this is set to true, it is a feature that can only be enabled and disabled for everybody at the same time
  abstract isGlobalFeature (): boolean

  abstract get description (): string

  updateTeamFeature (teamId: string, enabled: boolean): void {
    const team = {
      id: teamId
    }
    this.assignTeamFeature(team, enabled)
    studio.db.collection(CollectionNames.TEAMS).doc(teamId).set(team, MergeOption.MERGE).then(result => {
      if (!result.isSuccess) {
        console.error('error updating team', result.message)
      }
    }).catch(error => {
      console.error('catch error updating team', error)
    })
  }
}
