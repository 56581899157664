const versionToInt = (version: string): number => {
  if (typeof version !== 'string') {
    return 0
  }
  const subversions = version.split('.')
  let versionnumber: number = 0
  while (subversions.length < 5) {
    subversions.push('0')
  }
  for (const num of subversions) {
    versionnumber *= 100
    versionnumber += parseInt(num)
  }
  return versionnumber
}

export { versionToInt }
