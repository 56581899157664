import CastFilter, { CastFilterConfig } from '@/classes/CastFilter'
import fb from '@/firebase'
import store from '@/store'
import { CastsState, RootState } from '@/store/types'
import { DeepPartial, KeyDict, Optional } from '@/types'
import { CallTriage, InvitedCaster, CastMessage, CasterDeletionReason, CastType } from '@/types/casts'
import { CollectionNames } from '@/modules/database/utils/collectionNames'
import { ActionContext } from 'vuex'
import { castsModuleActionContext } from '.'
import { createQuery } from './casts.helpers'
import studio from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { ParticipantInfo } from '@/modules/casts/classes/ParticipantInfo'
import { Scene } from '@/types/scenes'
import { FirestoreQuerySnapper, FirestoreSnapperType } from '@/modules/database/utils/databaseQuerySubscriptions'
import { Team } from '@/modules/teams/classes'
import { Result, ResultVoid } from 'kiswe-ui'
import { CastProperty } from '@/types/admin'
import { VersionNumber } from '@/modules/classBuilder/mixins/hasVersion'
import { PointerTypes } from '@/classes/LinkPointer/LinkPointerBase'
import { generateRandomString } from '@/modules/common/utils'
import { VideoPlayer } from '@/types/videoplayer'

const SUBSCRIPTION_KEY = 'currentCasts'
export type CastsContext = ActionContext<CastsState, RootState>

const getCastPropertiesAndNgcvpVersion = (version: VersionNumber):
        Result<{ castProperties: KeyDict<CastProperty>, ngcvpVersion: string }, 'store'> => {
  const castProperties = store.state.team.castProperties
  if (Object.keys(castProperties).length === 0) {
    return Result.fail('store', 'No CastProperties available',
                       'You should be subscribed to the CastProperties before calling this method')
  }
  const ngcvpVersion = store.state.team.mixerPresetsOverrides?.ngcvp_version ??
    store.state.admin.release?.versions[version]?.ngcvp_version ?? null
  if (ngcvpVersion === null) {
    return Result.fail('store', 'No ngcvp_version available',
                       'You should be subscribed to the Release info before calling this method')
  }
  return Result.success({ castProperties, ngcvpVersion })
}

const actions = {
  async getCast (_context: CastsContext, castId: string) {
    const result = await studio.db.collection(CollectionNames.CASTS).doc(castId).get()
    if (result.isSuccess) return result.value
    result.logIfError(`Error fetching cast ${ castId }`)
    return null
  },
  async enterCast (_context: CastsContext, castId: string) {
    if (store.state.events.inCast) {
      throw new Error(`Cannot enter cast ${castId} while already in cast ${store.state.events.currentCast?.id}`)
    }
    await store.dispatch.events.setInCast(true)
    const result = await store.dispatch.events.subscribeCurrentCast(castId)
    result.logIfError('Unable to subscribe to current cast')
    // FIXME temporarly disabled for Olympics
    // await store.dispatch.events.subscribeDumpStateRequests(castId)
    await store.dispatch.events.updateOutputStreams()
    const data = {
      cc_cast: castId,
      cc_event: store.state.events.currentCast?.event_id }
    await store.dispatch.logs.updateClientInfo(data)
    await store.dispatch.logs.addLog({ message: 'Cast loaded properly.', cast: castId, level: 'info' })
  },
  async switchCast (_context: CastsContext, castId: string) {
    await store.dispatch.events.setInCast(true)
    await store.dispatch.events.switchCurrentCast(castId)
    await store.dispatch.events.updateOutputStreams()
    const data = {
      cc_cast: castId,
      cc_event: store.state.events.currentCast?.event_id }
    await store.dispatch.logs.updateClientInfo(data)
  },
  async leaveCast (_context: CastsContext, keepPresence: boolean = false) {
    const castid = store.state.events.currentCast?.id
    if (castid === undefined) {
      throw new Error('Cannot call leaveCast without first calling enterCast')
    }
    await store.dispatch.events.clearActiveAudioTracks(castid)
    await store.dispatch.audio.clearAudioState()
    await store.dispatch.events.setInCast(false)
    await store.dispatch.events.unsubscribeUpdateOutputStreams()
    await store.dispatch.events.unsubscribeCurrentCast()
    if (store.state.user.presence !== null && !keepPresence) {
      await store.state.user.presence.closeCast()
    }
    await store.dispatch.logs.addLog({
      message: 'User has left cast.',
      cast: castid,
      level: 'info'
    })
  },
  async createFilter (context: CastsContext, filterId: string): Promise<Optional<CastFilter>> {
    const { state, dispatch } = castsModuleActionContext(context)
    if (state.filters[filterId]) return undefined
    const filter = new CastFilter(filterId)
    await dispatch.applyFilter(filter)
    return state.filters[filterId] as Optional<CastFilter>
  },
  async applyFilter (context: CastsContext, filter: CastFilter) {
    // do checks that the filter is correct
    const { commit, dispatch } = castsModuleActionContext(context)
    commit.setFilter(filter)
    await dispatch.refreshQuery(filter.id)
  },
  async resetFilter (context: CastsContext, filterId: string) {
    const { state, dispatch } = castsModuleActionContext(context)
    state.filters[filterId].reset()
    await dispatch.refreshQuery(filterId)
  },
  async updateOrCreateFilter (context: CastsContext, params: CastFilterConfig) {
    const { commit, dispatch, state } = castsModuleActionContext(context)
    if (!params.id) throw new Error('updateOrCreateFilter id must be provided')
    if (!state.filters[params.id]) commit.setFilter(new CastFilter(params.id))
    commit.updateFilter(params)
    await dispatch.refreshQuery(params.id)
  },
  async refreshQuery (context: CastsContext, filterId: string) {
    const { state } = castsModuleActionContext(context)
    const filter = state.filters[filterId] as Optional<CastFilter>
    if (filter === undefined) {
      console.warn('casts.refreshQuery warning: filter does not exists yet')
      return
    }
    const query = await createQuery(filter)
    if (!studio.subscriptions.exists(SUBSCRIPTION_KEY + '_' + filterId)) return
    studio.subscriptions.updateQueryFB(SUBSCRIPTION_KEY + '_' + filterId, query)
  },
  async nextPage (context: CastsContext, filterId: string) {
    const { state, dispatch } = castsModuleActionContext(context)
    state.filters[filterId].nextPage()
    await dispatch.refreshQuery(filterId)
  },
  async prevPage (context: CastsContext, filterId: string) {
    const { state, dispatch } = castsModuleActionContext(context)
    state.filters[filterId].prevPage()
    await dispatch.refreshQuery(filterId)
  },
  // TODO: remove function with the same name from the events module and refactor all code
  // WARN: Only use this method to remove commentators. Because this only removes the online sessions and marks the
  //       invited_casters entry as deleted, it is not sufficient for correctly removing casters and anonymous guests
  //       ever since we started using Switcher style casts.
  //       For those the corresponding methods in sceneSources.ts must be used instead (to properly clean up the
  //       scenes associated with casters and guests).
  async removeCasterFromCast (context: CastsContext, castInfo: { userId: string, castId: string, anonymousId?: string|undefined }) {
    try {
      const { state } = castsModuleActionContext(context)
      const currentCast = state.filteredCasts[castInfo.castId]
      if (currentCast.invited_casters === null) throw new Error('Cannot remove caster from cast without invited casters')
      const onlineSessions = currentCast.online_sessions
      const onlineSessionsUpdate: KeyDict<string[]> = {}
      if (castInfo.anonymousId !== undefined) {
        const sessionIdsToRemove = onlineSessions[castInfo.anonymousId]
        const sessions = onlineSessions[castInfo.userId]
        if (sessions !== undefined && sessionIdsToRemove !== undefined) {
          onlineSessionsUpdate[castInfo.userId] = sessions.filter((sessionId) => !sessionIdsToRemove.includes(sessionId))
        }
        onlineSessionsUpdate[castInfo.anonymousId] = []
      }

      const invitedCasters = currentCast.invited_casters
      invitedCasters[castInfo.userId].deleted = true

      const result = await studio.db.collection(CollectionNames.CASTS).doc(castInfo.castId).set({
        // online_sessions: onlineSessionsUpdate,
        invited_casters: {
          [castInfo.userId]: {
            deleted: true,
            deleted_reason: CasterDeletionReason.ModeratorRemoved
          }
        }
      }, MergeOption.MERGE)
      result.logIfError('Could not remove Caster from Cast')
    } catch (error) {
      console.error('Remove Caster From Cast ', error)
    }
  },
  async setCasterTriage (context: CastsContext, payload: { userId: string, triage: CallTriage, moderatorId?: string, castId: string }) {
    const { dispatch } = castsModuleActionContext(context)
    const casterUpdates: KeyDict<Partial<InvitedCaster>> = {
      [payload.userId]: {}
    }
    const invitedCaster = casterUpdates[payload.userId]
    if (payload.triage === CallTriage.InModeration && payload.moderatorId) {
      invitedCaster.moderator = payload.moderatorId
    } else {
      invitedCaster.moderator = null
    }
    invitedCaster.triage = payload.triage
    invitedCaster.deleted = (payload.triage === CallTriage.Rejected)
    await dispatch.updateInvitedCasters({ castId: payload.castId, invitedCasters: casterUpdates })
  },
  async addParticipantToCast (_context: CastsContext,
                              params: { castId: string, userId: string, participant: DeepPartial<ParticipantInfo> }) {
    const result = await studio.db.collection(CollectionNames.CASTS).doc(params.castId).set({
      participants: {
        [params.userId]: params.participant
      }
    }, MergeOption.MERGE)
    result.logIfError('Could not add participants to cast')
  },
  async updateInvitedCasters (_context: CastsContext, castInfo: { castId: string, invitedCasters: KeyDict<Partial<InvitedCaster>> }) {
    const result = await studio.db.collection(CollectionNames.CASTS).doc(castInfo.castId).set({
      invited_casters: castInfo.invitedCasters }, MergeOption.MERGE)
    result.logIfError(`Could not update invited casters ${castInfo.invitedCasters}`)
  },
  async updateCastSourceStreamVolume (_context: CastsContext, params: { castId: string, streamId: string, volume: number }) {
    await fb.db.collection(CollectionNames.CASTS).doc(params.castId).set({
      source_streams: {
        [params.streamId]: {
          volume: params.volume
        }
      }
    }, { merge: true })
  },
  async updateScenes (_context: CastsContext, params: { castId: string, scenes: KeyDict<DeepPartial<Scene>> }) {
    const { castId, scenes } = params
    return await studio.db.collection(CollectionNames.CASTS).doc(castId).set({ scenes }, MergeOption.MERGE)
  },
  async setTally (_context: CastsContext, { castId, message } : { castId: string, message: CastMessage|null }) {
    const messages: CastMessage[] = message === null ? [] : [message]
    return studio.db.collection(CollectionNames.CASTS).doc(castId).set({ messages }, MergeOption.MERGE)
  },
  async subscribe (context: CastsContext, filterId: string) {
    const { commit, state, dispatch } = castsModuleActionContext(context)
    let filter = state.filters[filterId] as Optional<CastFilter>
    if (filter === undefined) {
      filter = await dispatch.createFilter(filterId)
    }
    if (filter === undefined) {
      console.error('Could not subscribe to filter with Id', filterId)
      return
    }
    const query = await createQuery(filter)
    const snapper: FirestoreQuerySnapper = {
      query,
      mutation: commit.updateCasts,
      type: FirestoreSnapperType.Query,
      params: {
        filterId
      }
    }
    await studio.subscriptions.subscribeFB(SUBSCRIPTION_KEY + '_' + filterId, snapper)
  },
  async unsubscribe (context: CastsContext, filterId: string) {
    const { commit, state } = castsModuleActionContext(context)
    const cleanupAfterLastUnsubscribe = () => {
      const filter = state.filters[filterId]
      if (filter) {
        filter.reset()
        commit.removeFilter(filterId)
        commit.cleanupCasts()
      }
    }
    studio.subscriptions.unsubscribeFB(SUBSCRIPTION_KEY + '_' + filterId, cleanupAfterLastUnsubscribe)
  },
  async subscribeAllActiveFanRooms (context: CastsContext, teamId: string) {
    const { commit } = castsModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: `allActiveFanRooms_${ teamId }`,
      // @ts-ignore
      query: studio.db.collection(CollectionNames.CASTS)
        // @ts-ignore
        .where('activeState.idle', '==', false)
        .where('team_id', '==', teamId)
        .where('castType', '==', CastType.FanRoom)
        .where('end_date', '>', new Date())
        .where('deleted', '==', false),
      callback: commit.updateAllActiveFanRooms
    })
    result.logIfError('subscribeAllActiveFanRooms')
  },
  async unsubscribeAllActiveFanRooms (context: CastsContext, teamId: string) {
    const { commit } = castsModuleActionContext(context)
    studio.subscriptions.unsubscribe(`allActiveFanRooms_${ teamId }`, () => commit.updateAllActiveFanRooms({}))
  },
  async stopCast (_context: CastsContext, castId: string) {
    try {
      const castData = (await fb.db.collection(CollectionNames.CASTS).doc(castId).get()).data()
      if (castData === undefined) throw new Error(`Can't stop cast '${castId}' if it doesn't exist.`)
      await store.dispatch.events.stopEvent(castData.event_id)
    } catch (error) {
      console.error(`Failed to stop cast ${castId}:`, error)
    }
  },
  async updateScheduledCastsWithTeamVersion (_context: CastsContext, team: Pick<Team, 'id'|'version'>):
                                            Promise<ResultVoid<'database'|'pointer'|'store'>> {
    const castPropAndNgcvpResult = getCastPropertiesAndNgcvpVersion(team.version)
    if (castPropAndNgcvpResult.isFailure) return castPropAndNgcvpResult.convert()
    const { castProperties, ngcvpVersion } = castPropAndNgcvpResult.value
    const scheduledCasts = await studio.db.collection(CollectionNames.CASTS)
                                          .where('team_id', '==', team.id)
                                          .where('start_date', '>', new Date())
                                          .get()
    if (scheduledCasts.isFailure) return scheduledCasts.convert()
    if (Object.keys(scheduledCasts.value).length === 0) return Result.ok()
    const promises = []
    for (const [oldCastId, oldCast] of Object.entries(scheduledCasts.value)) {
      if (oldCast.version !== team.version && oldCast.deleted !== true) {
        const newCast = oldCast.clone()
        newCast.id = generateRandomString(20)
        newCast.version = team.version
        const message = `Replacing scheduled cast ${oldCast.id} (v${oldCast.version})`
                        + ` with ${newCast.id} (v${newCast.version}) for team ${team.id}.`
        store.dispatch.logs.addLog({
          message,
          cast: oldCast.id,
          level: 'info'
        }).catch((error) => console.error(error))
        newCast.cleanupK360Info()
        newCast.calculatePresets(castProperties, { ngcvpVersion })
        const isBackupEnabled = !!newCast.regionBackup
        if (isBackupEnabled) newCast.calculatePresets(castProperties, { ngcvpVersion, backup: true })
        const doCastUpdates = async (): Promise<ResultVoid<'database'|'pointer'>> => {
          // TODO: Wrap all in a single transaction
          let result = await studio.db.collection(CollectionNames.CASTS).doc(oldCastId)
                                                                        .set({ deleted: true }, MergeOption.MERGE)
          if (result.isFailure) return result
          result = await studio.db.collection(CollectionNames.CASTS).doc(newCast.id).set(newCast, MergeOption.OVERWRITE)
          if (result.isFailure) return result
          const pointerResult = await store.dispatch.pointers.getPointerForCastId({ castId: oldCastId,
                                                                                    teamId: oldCast.team_id })
          if (pointerResult.isFailure) return pointerResult.convert()
          if (pointerResult.value.pointer.pointerType === PointerTypes.EVENT) {
            pointerResult.value.pointer.eventId = newCast.id
            pointerResult.value.pointer.studioVersion = team.version
            const pointerUpdateResult = await store.dispatch.pointers.setPointerTarget({
              pointerId: pointerResult.value.id,
              pointer: pointerResult.value.pointer
            })
            if (pointerUpdateResult.isFailure) return pointerUpdateResult
          }
          return await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(newCast.id).set({
            // @ts-ignore
            player1: new VideoPlayer('player1').toJSON(),
            // @ts-ignore
            team_id: team.id
          }, MergeOption.OVERWRITE)
        }
        promises.push(doCastUpdates())
      }
    }
    const results = await Promise.all(promises)
    results.forEach((result) => result.logIfError('failed to update scheduled cast version'))
    return Result.ok()
  },
  async subscribeCast (context: CastsContext, castId: string): Promise<ResultVoid<'subscription'>> {
    const { commit } = castsModuleActionContext(context)
    const key = `singlecast_${castId}`
    const result = await studio.subscriptions.subscribe({
      key,
      query: studio.db.collection(CollectionNames.CASTS).doc(castId),
      callback: commit.updateSubscribedCast,
      waitForIt: true
    })
    if (result.isFailure) return result.convert()
    return Result.ok()
  },
  async unsubscribeCast (context: CastsContext, castId: string) {
    const { commit } = castsModuleActionContext(context)
    const key = `singlecast_${castId}`
    studio.subscriptions.unsubscribe(key, () => {
      commit.clearSubscribedCast(castId)
    })
  },
  setProcessSourceStreams (context: CastsContext, processSourceStreams: boolean) {
    const { commit } = castsModuleActionContext(context)
    commit.setProcessSourceStreams(processSourceStreams)
  }
}

export default actions
