import store from '@/store'
import { AudioMixType } from '@/types/casts'
import { computed } from 'vue'

const audioMix = computed(() => store.state.events.audioMix)
const monitorMode = computed (() => audioMix.value === AudioMixType.Monitor)

const changeAudioMix = async (mix: AudioMixType) => {
  return store.dispatch.events.changeAudioMix(mix)
}

export {
  audioMix,
  monitorMode,
  changeAudioMix
}
