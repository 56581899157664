import store from '@/store'
import { computed, onMounted, onUnmounted } from 'vue'

const release = computed(() => {
  return store.state.admin.release
})

const versionIds = computed(() => {
  if (release.value === null) return []
  else return Object.keys(release.value.versions)
})

const versions = computed(() => {
  if (release.value === null) return {}
  else return release.value.versions
})

const defaultVersion = computed(() => {
  if (release.value === null) return '3.9.0'
  else return release.value.default
})

const useRelease = () => {
  onMounted(async () => {
    await store.dispatch.admin.subscribeRelease()
  })

  onUnmounted(async () => {
    await store.dispatch.admin.unsubscribeRelease()
  })

  return {
    versionIds,
    versions,
    defaultVersion,
    release
  }
}

export default useRelease
