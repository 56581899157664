<template>
  <KisweSelect
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :filterable="filterable"
    :size="size"
    :theme="theme"
    v-model="selectedRegion"
    :options="regionOptions"
    data-qa="select-caster-region"
  />
</template>

<script lang="ts">
import { KisweSelectOption } from 'kiswe-ui'
import { KisweSelect, useSizes } from 'kiswe-ui'
import { computed, defineComponent } from 'vue'
import { useTranscodingRegions } from '@/modules/transcodingregions/compositions'
import { pickBy, sortBy } from 'lodash'
import { useCanDo } from '@/modules/abilities/compositions'

export default defineComponent({
  name: 'SelectTranscodingRegion',
  components: {
    KisweSelect
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    filterable: {
      type: Boolean,
      required: false,
      default: true
    },
    theme: {
      type: String,
      required: false,
      default: 'default'
    },
    ...useSizes().props
  },
  setup (props) {
    const { canManageOperations } = useCanDo()
    const { transcodingregions, visibleRegions } = useTranscodingRegions()
    const regionOptions = computed(() => {
      const options: KisweSelectOption[] = []
      const regions = canManageOperations.value ? transcodingregions.value : visibleRegions.value
      for (const key in regions) {
        options.push({ label: regions[key].name, value: key })
      }
      if (options.find((option) => option.value === props.modelValue) === undefined) {
        const objects = pickBy(transcodingregions.value, (_, id) => id === props.modelValue)
        Object.keys(objects).forEach((key) => options.push({ label: objects[key].name, value: key }))
      }
      return sortBy(options, (option) => option.label.toLowerCase())
    })
    return {
      regionOptions
    }
  },
  computed: {
    selectedRegion: {
      get (): string {
        return this.modelValue
      },
      set (newVal: string) {
        this.$emit('update:modelValue', newVal)
      }
    }
  }
})
</script>
