import fb from '@/firebase'
import { ActionContext } from 'vuex'
import { CastTemplatesState, RootState } from '../types'
import { moduleActionContext } from '@/store'
import store from '..'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import { versionToInt } from '@/classes/versions'
import { KeyDict } from '@/types'
import { CollectionNames } from '@/modules/database/utils'
import { defineModule } from 'direct-vuex'
import { Cast } from '@/modules/casts/classes'
import studio from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { generateRandomString } from '@/modules/common/utils'
import { copyScenesAssetsToTeam } from '@/modules/assets/utils/copyAssets'
import { isEmpty, pickBy } from 'lodash'
import { Result, ResultVoid } from 'kiswe-ui'
import { CastType, WATERMARK_DEFAULT } from '@/types/casts'
import { objectDbDiff } from '@/modules/database/utils/objectDbDiff'
import { UpdateFunction } from '@/modules/base/types'
type CastTemplatesContext = ActionContext<CastTemplatesState, RootState>

// initial state
const state = {
  templates: {}
} as CastTemplatesState

// actions
const actions = {
  async subscribeTeamCastTemplates (context: CastTemplatesContext, params: { teamId: string, castType?: CastType }): Promise<ResultVoid<'subscription'>> {
    const allowedCastTypes = params.castType ? [params.castType] : Object.values(CastType)
    const key = `teamcasttemplates_${ params.castType ?? 'all' }`
    const { commit } = castTemplatesModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      query: studio.db
        .collection(CollectionNames.CAST_TEMPLATES)
        .where('team_id', '==', params.teamId)
        .where('deleted', '==', false)
        .where('castType', 'in', allowedCastTypes),
      callback: commit.setCastTemplates,
      key
    })
    if (result.isFailure) return result.convert()
    return Result.ok()
  },
  async unsubscribeTeamCastTemplates (_context: CastTemplatesContext, castType?: CastType) {
    const key = `teamcasttemplates_${ castType ?? 'all' }`
    return studio.subscriptions.unsubscribe(key)
  },
  async saveCastAsTemplate ({ rootState }: CastTemplatesContext, cast: Cast) {
    const tmpCast = JSON.parse(JSON.stringify(cast))
    const currentUserId = rootState.user.id || ''
    delete tmpCast.ngcvp_status
    delete tmpCast.chat_id
    delete tmpCast.event_id
    delete tmpCast.start_date
    delete tmpCast.end_date
    delete tmpCast.k360_event_id
    delete tmpCast.k360_event_id_backup
    delete tmpCast.m3u8
    delete tmpCast.mp4
    delete tmpCast.status
    delete tmpCast.id
    delete tmpCast.video_start_time
    delete tmpCast.video_start_time_backup
    delete tmpCast.originalCast
    delete tmpCast.clips
    delete tmpCast.isos
    delete tmpCast.feedname

    tmpCast.watermark = WATERMARK_DEFAULT

    if (cast.invited_casters !== null) {
      for (const caster of Object.values(cast.invited_casters)) {
        if (!caster.anonymous) continue
        delete tmpCast.invited_casters[caster.userId]
      }
    }

    tmpCast.deleted = false
    tmpCast.copying = false
    tmpCast.messages = []
    tmpCast.online_sessions = {}
    tmpCast.output_streams = {}
    tmpCast.preview_scenes = {}
    tmpCast.source_streams = {}
    tmpCast.talk_back = {}
    tmpCast.created_date = new Date()
    tmpCast.created_by = currentUserId

    // for legacy casts under the old format
    if (tmpCast.input_stream !== undefined && tmpCast.input_streams === undefined) {
      tmpCast.input_streams = { [tmpCast.input_stream]: true }
    }

    return await studio.db.collection(CollectionNames.CAST_TEMPLATES).add(tmpCast)
  },
  async updateCastTemplate (_context: CastTemplatesContext, payload: { template: CastTemplate, replace: boolean }) {
    const templateId = payload.template.id
    return await studio.db
      .collection(CollectionNames.CAST_TEMPLATES)
      .doc(templateId)
      .set(payload.template, payload.replace ? MergeOption.OVERWRITE : MergeOption.MERGE)
  },
  async updateCastTemplateProps<E extends string> (_context: CastTemplatesContext, payload: {
    template: CastTemplate, updateFunction: UpdateFunction<CastTemplate, E>
  }): Promise<ResultVoid<'database'|'unknown'|E>> {
    const oldTemplate = payload.template.clone()
    const result = Result.fromPossibleResult(payload.updateFunction(oldTemplate))
    if (result.isFailure) return result.convert()
    const diff = objectDbDiff(payload.template, result.value)
    if (!isEmpty(diff)) return await studio.db.collection(CollectionNames.CAST_TEMPLATES).doc(payload.template.id).set(diff, MergeOption.MERGE)
    return Result.ok()
  },
  async deleteCastTemplate (_context: CastTemplatesContext, template: string) {
    return await fb.db
      .collection(CollectionNames.CAST_TEMPLATES)
      .doc(template)
      .set({ deleted: true }, { merge: true })
  },
  async copyCastTemplate (_context: CastTemplatesContext, params: { template: CastTemplate, teamId: string }) {
    params.template.team_id = params.teamId
    params.template.scenes = await copyScenesAssetsToTeam(params.template.scenes, params.teamId)

    const id = generateRandomString()
    return studio.db.collection(CollectionNames.CAST_TEMPLATES).doc(id).set(params.template, MergeOption.OVERWRITE)
  },
  async cleanupTeamTemplates (_context: CastTemplatesContext, { id, itemType }: { id: string; itemType: string }) {
    // itemType = either asset_groups, invited_casters, input_streams, custom_rtmp_destinations
    const team = store.state.team.activeTeam
    const templates = await fb.db
      .collection(CollectionNames.CAST_TEMPLATES)
      .where('team_id', '==', team)
      .where('deleted', '==', false)
      .get()
    for (let i = 0; i < templates.docs.length; i++) {
      const templateId = templates.docs[i].id
      const template = templates.docs[i].data()
      if (template[itemType][id] !== undefined) {
        const ss = `${itemType}.${id}`
        fb.db
          .collection(CollectionNames.CAST_TEMPLATES)
          .doc(templateId)
          .update({
            [ss]: fb.deleteField
          })
          .catch((error) => {
            console.error('Cleanup CastTemplate fields', error)
          })
      }
    }
  },
  async getCastTemplates (_context: CastTemplatesContext, teamId: string) {
    const result = await studio.db.collection(CollectionNames.CAST_TEMPLATES)
      .where('team_id', '==', teamId).where('deleted', '==', false).get()
    if (result.isSuccess) return result.value
    result.log(`Could not get cast templates of team: ${teamId}`)
    return {}
  }
}

// mutations
const mutations = {
  setCastTemplates (state: CastTemplatesState, templates: KeyDict<CastTemplate>) {
    const version = versionToInt(store.state.team.team?.version ?? '1.0.0')
    const filteredTemplates = pickBy(templates, (template) => versionToInt(template.version) <= version)
    state.templates = filteredTemplates
  }
}

const castTemplatesModule = defineModule({
  namespaced: true,
  state,
  actions,
  mutations
})

export default castTemplatesModule
export const castTemplatesModuleActionContext = (context: CastTemplatesContext) => moduleActionContext(context, castTemplatesModule)
