import CheckFeature from '../checkfeature'
import { Team } from '@/modules/teams/classes'

export default class JanusFeature extends CheckFeature {

  hasFeature (): boolean {
    return this.currentTeam?.features?.janus?.enabled ?? false
  }

  override get description (): string {
    return `Enables Janus WebRTC backend for a team.`
  }

  teamHasFeature (teamId: string): boolean {
    const team = this.teams[teamId]
    return team?.features?.janus?.enabled ?? false
  }

  assignTeamFeature (team: Partial<Team>, enabled: boolean): void {
    team.features = team.features ?? {}
    team.features.janus = { enabled: enabled }
  }

  isGlobalFeature (): boolean {
    return false
  }
}
