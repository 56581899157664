import { Loader } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'

export type VersionNumber = `${number}.${number}.${number}` | `${number}.${number}`

export function hasVersion<TBase extends StudioBaseMixin> (Base: TBase, params: { version?: VersionNumber } = {}) {
  return class VersionedClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.version = Loader.loadString(arg[0], 'version', params.version ?? '1.0.0') as VersionNumber
    }
    version: VersionNumber
  }
}

export type Versioned = Mixin<typeof hasVersion>
