import { Loader, Result } from 'kiswe-ui'

export class Session {
  last_changed: Date
  state: 'online'|'offline'

  constructor (data: unknown) {
    this.last_changed = Loader.loadDate(data, 'last_changed')
    this.state = Loader.loadString(data, 'state', 'offline') as 'online'|'offline'
  }


  static load (data: unknown): Result<Session, 'invalid param'|'unknown'> {
    try {
      return Result.success(new Session(data))
    } catch (error) {
      return Result.fromUnknownError('invalid param', error)
    }
  }
}
