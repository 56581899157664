import { createClass } from '@/modules/classBuilder/classBuilder'
import { ClassType } from '@/modules/classBuilder/mixins/base'
import { KeyDict } from '../../../types'
import { CastType } from '../../../types/casts'
import ActiveState from './ActiveState'
import { CastBase } from './CastBase'
import { ParticipantInfo } from './ParticipantInfo'

export class CastFanRoomLeftOver extends CastBase {
  participants: KeyDict<ParticipantInfo> = {}
  activeState: ActiveState

  constructor (data: unknown) {
    super(data)

    this.create_event = false
    this.participants = {}
    // @ts-ignore TODO
    for (const [key, participantInfo] of Object.entries(data?.participants ?? {})) {
      // @ts-ignore TODO
      this.participants[key] = new ParticipantInfo(participantInfo)
    }
    // @ts-ignore -> need to properly load
    this.activeState = new ActiveState(data?.activeState)
  }
}

export const CastFanRoom = createClass(CastFanRoomLeftOver)
  .addCastType(CastType.FanRoom)
  .addCasters()
  .addOnlineSessions()
  .disallowCustomOutputs()
  .addPresets()
  .addMutes({ useCastLevelMutes: true })
  .addScheduledDate()
  .get()

export type CastFanRoom = ClassType<typeof CastFanRoom>
