import { Result } from 'kiswe-ui'
import { Asset, AssetType, TweetAsset } from '@/types/assets'

export const dummyTweetUrl = 'https://twitter.com/KisweVideo/status/1583068353366700033'
export const getHiddenImportedTweetsFolderId = (castId: string): string => `.importedtweets_${ castId }`
export const getDummyTweetId = (castId: string): string => `.dummytweet_${ castId }`

export const getImportedTweetData = (
  teamId: string, folderId: string, urlString: string
): Result<TweetAsset & { tweet_id: string }, 'url_error'> => {
  const url = new URL(urlString)
  if (url.pathname === '') return Result.fail('url_error', 'Invalid URL')
  const urlParts = url.pathname.split('/')
  const tweetId = urlParts[urlParts.length - 1]
  const tweet: TweetAsset = {
    ...new Asset({
      name: 'Tweet',
      team_id: teamId,
      asset_group: folderId,
      type: AssetType.Tweet,
      url: url.href
    }),
    tweet_id: tweetId,
    type: AssetType.Tweet,
    parsed: false
  }
  return Result.success({ ...tweet, tweet_id: tweetId })
}
