import { Loader, Result } from 'kiswe-ui'
import { ScteMarker } from './ScteMarker'

export enum MarkerType {
  SCTE = 'SCTE'
}

export type Marker = ScteMarker
export const Marker = {
  load (params: unknown): Result<Marker, 'invalid_params' | 'unknown'> {
    try {
      const type = Loader.loadString(params, 'type') as MarkerType
      if (type === MarkerType.SCTE) {
        return Result.success(new ScteMarker(params))
      }
      return Result.fail('invalid_params', 'unknown')
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
