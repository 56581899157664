import store from '@/store'
import { computed, onMounted, onUnmounted } from 'vue'

const castProperties = computed(() => store.state.team.castProperties)
const useCastProperties = () => {
  onMounted(async () => await store.dispatch.team.subscribeTeamCastProperties())
  onUnmounted(async () => await store.dispatch.team.unsubscribeTeamCastProperties())
  return {
    castProperties
  }
}

export default useCastProperties
