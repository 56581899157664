import { moduleActionContext } from '@/store'
import { ActionContext } from 'vuex'
import { RootState } from '@/store/types'
import { StartStopState } from '@/store/types'
import { StartStopAlwaysOnServerParams } from '@/modules/startstopmanager/types'
import { StartStopIdleTimeServerParams } from '@/modules/startstopmanager/types'
import { defineModule } from 'direct-vuex'
import fb from '@/firebase'

type StartStopContext = ActionContext<StartStopState, RootState>

const startstopModule = defineModule({
  namespaced: true,
  state: {
    all_services_healthy: false,
    managed_machines: {},
    pollTimer: null,
    requests: [],
    services_healthy: [],
    services_to_look_for: [],
    services_unhealthy: []
  } as StartStopState,
  actions: {
    // temporarly function, must go to the database
    // eslint-disable-next-line max-lines-per-function
    async subscribeStartStopState (context: StartStopContext) {
      const { dispatch, commit } = startstopModuleActionContext(context)
      // eslint-disable-next-line
      const pollTimer = setInterval(function () { dispatch.poll() }, 10000)
      commit.setPollTimer(pollTimer)
      await dispatch.poll()
    },
    async unsubscribeStartStopState (context: StartStopContext) {
      const { commit, state } = startstopModuleActionContext(context)
      clearInterval(state.pollTimer)
      commit.setPollTimer(null)
    },
    async poll (context: StartStopContext) {
      const { commit } = startstopModuleActionContext(context)
      try {
        const runtest = false
        if (runtest) {
          const datastr = `{"requests":
            [{"requested_by": "dev-va-cec", "request_on": [["rtmp-va2.kiswe.com"],
            ["wrtc-dev.kiswe.com", "wrtc-dev5.kiswe.com"]]},
            {"requested_by": "dev-studio", "request_on": [["wrtc-dev1.kiswe.com"], ["wrtc-dev.kiswe.com"]]}],
            "services_to_look_for": ["dev-va-cec", "dev-studio"], "services_healthy": ["dev-studio"],
            "services_unhealthy": ["dev-va-cec"], "all_services_healthy": false,
            "managed_machines": {"wrtc-dev.kiswe.com": {"instance_id": "i-0661ff5a346ddd125",
            "region": "us-east-1", "always_on": true, "desired_state": "off", "aws_state": "running"},
            "wrtc-dev1.kiswe.com": {"instance_id": "i-0caba2d7d5718281f", "region": "us-east-1", "always_on":
            false, "desired_state": "on", "aws_state": "stopped"}}}`
          const datatest: StartStopState = JSON.parse(datastr)
          commit.update(datatest)
        } else {
          const getStartStopManagerFB = fb.functions.httpsCallable('getStartStopManager')
          const startstopResult = await getStartStopManagerFB('')
          const data: StartStopState = startstopResult.data
          commit.update(data)
        }
      } catch (error) {
        console.error('getStartStopManager err', error)
      }
    },
    async addServer (context: StartStopContext, server: string): Promise<boolean> {
      try {
        const addServerStartStopManagerFB = fb.functions.httpsCallable('addServerStartStopManager')
        const res = await addServerStartStopManagerFB({ server })
        if (res.data === true) {
          const { dispatch } = startstopModuleActionContext(context)
          await dispatch.poll()
        }
        return res.data
      } catch (error) {
        console.error('addServerStartStopManager err', error)
        return false
      }
    },
    async removeServer (context: StartStopContext, server: string): Promise<boolean> {
      try {
        const removeServerStartStopManagerFB = fb.functions.httpsCallable('removeServerStartStopManager')
        const res = await removeServerStartStopManagerFB(server)
        if (res.data === true) {
          const { dispatch } = startstopModuleActionContext(context)
          await dispatch.poll()
        }
        return res.data
      } catch (error) {
        console.error('removeServerStartStopManager err', error)
        return false
      }
    },
    async alwaysOnServer (context: StartStopContext, params: StartStopAlwaysOnServerParams): Promise<boolean> {
      try {
        const alwaysonStartStopManagerFB = fb.functions.httpsCallable('alwaysonStartStopManager')
        const res = await alwaysonStartStopManagerFB(params)
        if (res.data === true) {
          const { dispatch } = startstopModuleActionContext(context)
          await dispatch.poll()
        }
        return res.data
      } catch (error) {
        console.error('alwaysonStartStopManager err', error)
        return false
      }
    },
    async setServerIdleTime (context: StartStopContext, params: StartStopIdleTimeServerParams): Promise<boolean> {
      try {
        const idleTimeStartStopManagerFB = fb.functions.httpsCallable('idleTimeStartStopManager')
        const res = await idleTimeStartStopManagerFB(params)
        if (res.data === true) {
          const { dispatch } = startstopModuleActionContext(context)
          await dispatch.poll()
        }
        return res.data
      } catch (error) {
        console.error('idleTimeStartStopManager err', error)
        return false
      }
    }
  },
  mutations: {
    setPollTimer (state: StartStopState, pollTimer: any) {
      state.pollTimer = pollTimer
    },
    update (state: StartStopState, newState: StartStopState) {
      state.requests = newState.requests
      state.managed_machines = newState.managed_machines
      state.services_to_look_for = newState.services_to_look_for
      state.all_services_healthy = newState.all_services_healthy
      state.services_healthy = newState.services_healthy
      state.services_unhealthy = newState.services_unhealthy
    }
  },
  getters: {
  }
})

export default startstopModule
export const startstopModuleActionContext = (context: StartStopContext) => moduleActionContext(context, startstopModule)
