import { AssetType } from '@/types/assets'
import { Scene } from '@/types/scenes'

export const hasActiveClipsContent = (scene: Scene|null) => {
  if (scene === null) return false
  return Object.values(scene.contents ?? {})
    .some((content) => {
      return content.type === AssetType.Video
        && content.box !== 'none'
        && content.box !== ''
        && content.active
    })
}
