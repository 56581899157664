import { KeyDict } from '@/types'
import { SourceStream } from '@/types/streams'
import { isSourceStreamUser } from '../types'

const sortStreamByStartTimeDesc = (a: [string, SourceStream], b: [string, SourceStream] ): number => {
  const aStream = a[1]
  const bStream = b[1]
  if (!bStream.start_time) return -1
  if (!aStream.start_time) return 1
  return bStream.start_time - aStream.start_time
}

export const getSourceStreamIdByUserId = (
  userId: string,
  sourceStreams: KeyDict<SourceStream>
): string|null => {
  for (const [streamId, stream] of Object.entries(sourceStreams).sort(sortStreamByStartTimeDesc)) {
    if (!isSourceStreamUser(stream)) continue
    if (!stream.active) continue
    if (stream.user_id === userId) return streamId
  }
  return null
}

export const getAllSourceStreamIdsByUserId = (
  userId: string,
  sourceStreams: KeyDict<SourceStream>
): string[] => {
  const result: string[] = []
  for (const [streamId, stream] of Object.entries(sourceStreams)) {
    if (!isSourceStreamUser(stream)) continue
    if (!stream.active) continue
    if (stream.user_id === userId) result.push(streamId)
  }
  return result
}
