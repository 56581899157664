import CheckFeature from '../checkfeature'
import { Team } from '@/modules/teams/classes'

export default class FanRoomFeature extends CheckFeature {

  hasFeature (): boolean {
    return this.currentTeam?.features?.webRtcOnly?.enabled ?? false
  }

  override get description (): string {
    return `Enables the call in show (Kiswe Studio).
    It will make the Stations, rooms and call-in casts visible in the dashboard`
  }

  teamHasFeature (teamId: string): boolean {
    const team = this.teams[teamId]
    return team?.features?.webRtcOnly?.enabled ?? false
  }

  assignTeamFeature (team: Partial<Team>, enabled: boolean): void {
    team.features = team.features ?? {}
    team.features.webRtcOnly = { enabled }
  }

  isGlobalFeature (): boolean {
    return false
  }
}
