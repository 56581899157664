import fb from '@/firebase'
import helpers from '@/helpers'
import store from '@/store'
import { lazyUpdate } from '@/store/storehelper'
import { EventsState } from '@/store/types'
import { KeyDict, TalkbackMode } from '@/types'
import studio, { CollectionNames } from '@/modules/database/utils'
import { AudioMixType, CastOutputStream, CastSummary, PlaylistStatus } from '@/types/casts'
import { CloudCastEvent } from '@/types/events'
import { CustomRtmpDestination, SourceStream, Stream, StreamType, UserStreamType } from '@/types/streams'
import { UserProfile } from '@/types/users'
import firebase from 'firebase/compat/app'
import { cloneDeep, isEqual } from 'lodash'
import { CastChangeHandler } from './castChangeHandler'
import { CastChangeHandlerFactory } from './castChangeHandlerFactory'
import { fillMissingCssObjects } from './events.helpers'
import { Scene } from '@/types/scenes'
import { MixerTrackLabelWithDefault } from '@/modules/audio/types'
import { Cast } from '@/modules/casts/classes'
import { MergeOption } from '@/modules/database/utils/database'
import { Result } from 'kiswe-ui'
import { StreamChangeHandlerFactory } from '@/modules/streams/utils/streamChangeHandlerFactory'
import { RenderMode } from '@/modules/scenes/types'
import { ActionPaletteTabs } from '@/modules/ui/types'

let castChangeHandlers: CastChangeHandler[] = []
const streamChangeHandlers = StreamChangeHandlerFactory.getChangeHandlers()
let firstEventsChecked = false

const mutations = {
  setCurrentCastId (state: EventsState, castId: string|null) {
    state.currentCastId = castId
  },
  updateCurrentPlaylist (state: EventsState, playlistStatus: PlaylistStatus) {
    state.currentPlaylist = playlistStatus
  },
  async updateDumpStateRequests (_state: EventsState, snap: firebase.firestore.DocumentSnapshot) {
    const doc = snap.data()
    const castId = snap.id
    if (doc !== undefined) {
      const now = new Date().getTime() //ms
      const keys = Object.keys(doc)
      const sortedKeys = keys.sort().reverse() // most recent first
      for ( const key of sortedKeys) {
        let keyNumber = 0
        try {
           keyNumber = parseFloat(key)
           if (Number.isNaN(keyNumber)) {
             keyNumber = 0
           }
        } catch (error) {
          console.log(key, error)
        }
        const diff = now - keyNumber
        const LIMIT = 5000 // only requests entered less than LIMIT ms ago will be considered
        if (diff < LIMIT) {
          if (!store.state.user.isDumping) {
            const userState = store.state.user
            store.dispatch.user.setIsDumping(true)
            const t0 = new Date().getTime()
            try {
              const presence = userState.presence
              let sessionId :string | undefined = presence?.sessionId
              if (sessionId === undefined && store.state.events.cefEvent) {
                const cefMixer = store.state.events.cefMixer
                const cefMode = store.state.events.cefMode
                sessionId = `${cefMode}_${cefMixer}`
              }
              const dumps = doc[key]
              let downloadUrl : string | undefined = undefined
              const userId = userState.id
              const entryKey = `${userId}_${sessionId}`
              if (dumps !== undefined) {
                downloadUrl = dumps[entryKey]
              }
              if (downloadUrl === undefined) {
                const requestId = key
                downloadUrl = await store.dispatch.logs.dumpState({ requestId })
                const update = { [key] :  { [entryKey] : downloadUrl }}
                const result = await studio.db.collection(CollectionNames.CAST_DUMPS)
                                              .doc(castId).set(update, MergeOption.MERGE )
                result.logIfError('Could not dump state')
                const t1 = new Date().getTime()
                const diff = t1 - t0
                console.log('dumping took:', diff)
                break
              }
            } finally {
              store.dispatch.user.setIsDumping(false)
            }
          } else {
            console.log('already dumping')
          }
        }
      }
      console.log('updateDumpRequests', JSON.stringify(doc))
    }
  },
  updateCurrentCast (state: EventsState, cast: Cast) {
    if (state.currentCastId === null) {
      console.error('Cannot update CurrentCast if id not valid')
      return
    }
    cast.id = state.currentCastId
    store.commit.events.updateCurrentCastFromDoc(cast)
  },
  updateCurrentCastFromDoc (state: EventsState, currentCast: Cast|null) {
    // FIXME: this function needs to be cleaned up
    const previousCast = state.currentCast?.clone() ?? null
    if (state.currentCast === null) {
      state.currentCast = currentCast
    } else {
      lazyUpdate(state, 'currentCast', currentCast)
    }
    if (state.currentCast === null) return
    if (state.cefMode === 'back') {
      const currentCastString = JSON.stringify(currentCast)
      localStorage.setItem('currentCast', currentCastString)
      // console.log('cast updated To Local state')
    }

    // console.log('currentCast from db', currentCast)
    // manually override undefined order coming from db
    if (currentCast === null) return
    for (const key in currentCast.scenes) {
      if (currentCast.scenes[key].name === undefined) {
        delete currentCast.scenes[key]
      } else {
        for (const i in currentCast.scenes[key].contents) {
          // console.log(currentCast.scenes[key].contents[i], Object.keys(currentCast.scenes[key].contents[i]))
          if (!currentCast?.scenes?.[key].contents?.[i].hasOwnProperty('order')) {
            // @ts-ignore
            currentCast.scenes[key].contents[i].order = 10
          }
        }
        const sceneId = currentCast.scenes[key].id
        if (sceneId) {
          fillMissingCssObjects(currentCast.scenes[key].scene_layout, currentCast.id, sceneId) //Prevent errors on empty objects
        }
      }

    }
    for (const key in currentCast.preview_scenes) {
      for (const i in currentCast.preview_scenes[key].contents) {
        // console.log(currentCast.scenes[key].contents[i], Object.keys(currentCast.scenes[key].contents[i]))
        if (!currentCast?.preview_scenes?.[key].contents?.[i].hasOwnProperty('order')) {
          // @ts-ignore
          currentCast.preview_scenes[key].contents[i].order = 10
        }
      }
    }
    // console.log('currentCast from state: ', state.currentCast)

    if (previousCast === null || previousCast.id !== currentCast.id) {
      castChangeHandlers = CastChangeHandlerFactory.getChangeHandlers(currentCast.id)
    }
    castChangeHandlers.forEach((handler) => handler.handleChanges(previousCast, currentCast))

    store.commit.events.updateProgramVolumes()
  },
  updateProgramVolumes (state: EventsState): void {
    const newProgramVolumes: KeyDict<number> = {}
    const updateVolumeAttribute = (key: string, sourceAttributeName: 'volume'|'volumeLeft'|'volumeRight',
      targetKey: string
    ) => {
      const sourceStream = state.sourceStreams[key]
      let newVolume = sourceStream?.[sourceAttributeName]
      if (newVolume === undefined || isNaN(newVolume)) {
        newVolume = 0
      }
      newProgramVolumes[targetKey] = newVolume
    }
    // we need to loop through source_streams and update their volumes from sourceStreams
    for (const key in state.sourceStreams) {
      updateVolumeAttribute(key, 'volume', key)
      updateVolumeAttribute(key, 'volumeLeft', key + '_left')
      updateVolumeAttribute(key, 'volumeRight', key + '_right')
    }
    if (!isEqual(state.programVolumes, newProgramVolumes)) {
      state.programVolumes = newProgramVolumes
    }
  },
  updateSourceStreams (state: EventsState, payload: { sourceStreams: KeyDict<SourceStream>, castId: string }): void {
    const existingCastSourceStreamIds = Object.keys(state.sourceStreams).filter((key) => {
      return state.sourceStreams[key].castId === payload.castId
    })
    for (const [sourceStreamId, sourceStream] of Object.entries(payload.sourceStreams)) {
      const existingSourceStream = state.sourceStreams[sourceStreamId]
      if (payload.castId !== state.currentCastId && existingSourceStream?.castId === state.currentCastId) continue
      state.sourceStreams[sourceStreamId] = { ...sourceStream, castId: payload.castId }
    }
    for (const oldSourceStreamId of existingCastSourceStreamIds) {
      if (!payload.sourceStreams[oldSourceStreamId]) {
        delete state.sourceStreams[oldSourceStreamId]
      }
    }
  },
  clearSourceStreamsOfCast (state: EventsState, castId: string): void {
    Object.keys(state.sourceStreams).filter((key) => state.sourceStreams[key].castId === castId)
      .forEach((sourceStreamId) => delete state.sourceStreams[sourceStreamId])
  },
  updateOutputStreams (state: EventsState, outputStreams: KeyDict<CastOutputStream>): void {
    lazyUpdate(state, 'outputStreams', outputStreams)
  },
  updateCustomStreams (state: EventsState, customStreams: KeyDict<CustomRtmpDestination>): void {
    lazyUpdate(state, 'customStreams', customStreams)
  },
  updateEvents (state: EventsState, events: KeyDict<CloudCastEvent>) {
    state.events = Object.values(events)
    state.eventsLoaded = true
    if (firstEventsChecked) {
      state.eventsLoaded = true
    } else {
      firstEventsChecked = true
    }
  },
  updateCompletedEvents (state: EventsState, events: KeyDict<CloudCastEvent>) {
    state.completedEvents =  Object.values(events)
    setTimeout(() => {
      state.completedEventsLoaded = true
    }, 1000)
  },
  addNewCompletedEvent (state: EventsState, event: CloudCastEvent) {
    state.completedEvents.push(event)
  },
  updateActiveCasts (state: EventsState, snap: firebase.firestore.QuerySnapshot) {
    snap.forEach(castDoc => {
      const data = castDoc.data() as CastSummary
      state.activeCasts[castDoc.id] = {
        name: data.name,
        id: castDoc.id,
        start_date: data.start_date
      }
    })
  },
  bleepCaster (state: EventsState, caster: string) {
    if (state.currentCast === null || state.currentCastId === null) {
      return Result.fail('not_subscribed', 'Cannot bleepCaster, current cast is null')
    }
    const castUpdate: Pick<Cast, 'bleeped'> = {
      bleeped: {
      }
    }
    castUpdate.bleeped[caster] = (new Date()).getTime()
    return studio.db.collection(CollectionNames.CASTS).doc(state.currentCastId).set(castUpdate, MergeOption.MERGE)
  },
  testModeSetCurrentCast (state: EventsState, value: Partial<Cast>|null): void {
    if (process.env.NODE_ENV !== 'test') {
      console.warn('You are using the function testModeSetCurrentCast, which should never run in production')
    }
    if (value !== null && value.id) {
      state.currentCastId = value.id
    }
    // @ts-ignore
    state.currentCast = value
  },
  testModeSetCastTeam (state: EventsState, value: Partial<UserProfile>[]): void {
    console.warn('You are using the function testModeSetCastTeam, which should never run in production')
    // @ts-ignore
    state.castTeam = value
  },
  toggleSimpleMode (state: EventsState, value: boolean): void {
    state.simpleMode = value
  },
  toggleFullscreen (state: EventsState, value: boolean): void {
    state.fullscreen = value
  },
  setPreviewScene (state: EventsState, paramSceneData: { id: string }): void {
    state.sceneInPreview = paramSceneData.id
  },
  setStreamNames (state: EventsState, { teamStreams }: { teamStreams: KeyDict<Stream>}) {
    const names: KeyDict<string> = {}
    const images: KeyDict<string> = {}
    for (const [key, sourceStream] of Object.entries(state.sourceStreams)) {
      if ([StreamType.Broadcast, StreamType.ScreenShare].includes(sourceStream.type)) {
        // get the name of the stream from where ever the team streams
        if (teamStreams.hasOwnProperty(key)) {
          if (teamStreams[key].name !== '') {
            names[key] = teamStreams[key].name
          } else {
            const userId = teamStreams[key].user
            if (userId !== undefined) {
              const person = store.state.team.currentTeam[userId]
              if (person !== undefined) {
                names[key] = person.first_name
              } else {
                names[key] = 'Input Stream'
              }
            } else {
              names[key] = 'Input Stream'
            }
          }
        } else {
          names[key] = 'Input Stream'
        }
      } else if (sourceStream.type === StreamType.Caster && sourceStream.user_id !== undefined) {
        const person = store.state.team.currentTeam[sourceStream.user_id]
        if (person) {
          if (person.display_name) {
            names[key] = person.display_name
          } else {
            names[key] = `${person.first_name} ${person.last_name}`
          }
          if (person.display_image) {
            images[key] = person.display_image
          }
        }
      }
    }
    state.streamNames = names
    state.streamImages = images
  },
  leavePreviewScene (state: EventsState) {
    state.sceneInPreview = null
  },
  setCefMixer (state: EventsState, mixerid: string): void {
    state.cefMixer = mixerid
  },
  setCefEvent (state: EventsState, isCefEvent?: boolean) {
    state.cefEvent = isCefEvent ?? true
  },
  setPreviewingOutput (state: EventsState, value: boolean) {
    state.isPreviewingOutput = value
  },
  setCefMode (state: EventsState, mode: RenderMode) {
    state.cefMode = mode
  },
  clearPreviewScene (state: EventsState): void {
    state.sceneInPreview = null
  },
  streamDocLoaded (state: EventsState, stream: { data: Stream, id: string }) {
    const tmpStr = { id: stream.id, ...stream.data, streamDocLoaded: true }
    const allowedStreamTypes = [StreamType.Broadcast, StreamType.Caster, StreamType.NonCaster,
      StreamType.Moderator, StreamType.Replay, StreamType.ScreenShare, StreamType.Cef]
    if (!allowedStreamTypes.includes(tmpStr.type)) return
    const oldStream = state.streamDocs[stream.id] ?? null
    streamChangeHandlers.forEach((handler) => handler.handleChanges(oldStream, tmpStr))
    state.streamDocs[stream.id] = tmpStr
  },
  setOutputStreamObject (state: EventsState, stream: { data: Stream, id: string }) {
    if (stream.data.hasOwnProperty('castid')) {
      const tmpStr = { id: stream.id, ...stream.data, streamDocLoaded: true }
      state.streamDocs[stream.id] = tmpStr
    }
  },
  updateStreamVolumes (state: EventsState, updates: { key: string, value: number, name: string }[]) {
    if (state.currentCast === null) throw new Error('Cannot updateStreamVolumes, current cast is null')
    // takes in structure
    // console.log('updateStreamVolumes', payload)
    const currentCastId = state.currentCast.id
    const sourceStreamsCopy = JSON.parse(JSON.stringify(state.sourceStreams))
    for (const update of updates) {
      const streamId = update.key
      const volumeName = update.name
      const volume = update.value
      if (['volume', 'volumeLeft', 'volumeRight', 'leftMuted', 'rightMuted'].includes(volumeName)) {
        //console.log('[', streamId,'][', volumeName,']:=',volume)
        sourceStreamsCopy[streamId][volumeName] = volume
      }
    }
    // console.log(sourceStreamsCopy)
    return studio.db
      .collection(CollectionNames.CASTS)
      .doc(currentCastId)
      .set({ source_streams: sourceStreamsCopy }, MergeOption.MERGE)
  },
  applyMonitorToLive (state: EventsState) {
    if (state.currentCast === null) throw new Error('Cannot applyMonitorToLive, current cast is null')
    const volumeSource = cloneDeep(state.monitorVolumes)
    const muteSource = cloneDeep(state.monitorMutes)
    const currentCastId = state.currentCast.id
    const sourceStreamsCopy = JSON.parse(JSON.stringify(state.sourceStreams))
    console.log('applyMonitorToLive', volumeSource, muteSource)
    Object.keys(volumeSource).forEach(key => {
      const tempName = key.split('_')
      let volumeName = 'volume'
      let streamId = key
      const volume = volumeSource[key]
      if (tempName.length > 1) {
        volumeName += helpers.initialCaps(tempName[1])
        streamId = tempName[0]
      }
      // console.log(streamId, volumeName, volume)
      if (['volume', 'volumeLeft', 'volumeRight'].includes(volumeName)) {
        if (sourceStreamsCopy[streamId]) {
          sourceStreamsCopy[streamId][volumeName] = volume
        }
      }
    })
    // mutes are handled at the scene level so need to modify there
    const scenes = cloneDeep(state.currentCast.scenes)
    let activeScene: Scene|undefined = undefined
    if (state.currentCast.activeScene === null) {
      console.error('events.ts::applyMonitorToLive - state.currentCast.activeScene is null')
      return
    }
    activeScene = scenes[state.currentCast.activeScene]
    if (activeScene?.id === undefined) {
      console.error('events.ts::applyMonitorToLive - active scene does not exists')
      return
    }
    Object.keys(muteSource).forEach(key => {
      if (activeScene?.contents === undefined) return
      const tempName = key.split('_')
      let muteName = 'muted'
      let streamId = key
      const muteStatus = muteSource[key]
      if (tempName.length > 1) {
        muteName = tempName[1] + 'Muted'
        streamId = tempName[0]
      }
      console.log(streamId, muteName, muteStatus)
      if (['leftMuted', 'muted', 'rightMuted'].includes(muteName)) {
        // @ts-ignore
        activeScene.contents[streamId][muteName] = muteStatus
      }
    })
    scenes[activeScene.id] = activeScene
    return studio.db
      .collection(CollectionNames.CASTS)
      .doc(currentCastId)
      .set({ scenes: scenes, source_streams: sourceStreamsCopy }, MergeOption.MERGE)
  },
  addOutputToCast (state: EventsState, payload: KeyDict<CustomRtmpDestination>) {
    if (state.currentCast === null) throw new Error('addOutputToCast not available if currentCast is null')
    const currentCastId = state.currentCast.id
    let outputStreams = cloneDeep(state.currentCast.custom_rtmp_destinations ?? {})
    outputStreams = { ...outputStreams, ...payload }
    const ss = 'custom_rtmp_destinations'
    fb.db.collection('casts').doc(currentCastId).update({
      [ss]: outputStreams
    })
    .catch(error => {
      console.error('addOutputToCast:', error)
    })
  },
  deleteCastOutput (state: EventsState, payload: string) {
    if (state.currentCast === null) throw new Error('Cannot delete Cast Output, current cast is null')
    const currentCastId = state.currentCast.id
    const outputStreams = JSON.parse(JSON.stringify(state.currentCast.custom_rtmp_destinations))
    if (outputStreams !== undefined) {
      delete outputStreams[payload]
      const ss = 'custom_rtmp_destinations'
      fb.db.collection('casts').doc(currentCastId).update({
        [ss]: outputStreams
      })
      .catch(error => {
        console.error('addOutputToCast:', error)
      })
    }
  },
  clearCurrentCast (state: EventsState): void {
    state.actionPaletteTab = ActionPaletteTabs.Assets
    state.castReady = false
    state.completedEventsLoaded = false
    state.currentCast = null
    state.currentCastId = null
    state.editPresetId = null
    state.editSceneId = null
    state.eventsLoaded = false
    state.openScene = ''
    state.outputStreams = {}
    state.programVolumes = {}
    state.sceneFilter = ''
    state.sceneInPreview = null
    state.simpleMode = true
    state.sourceStreams = {}
    state.streamDocs = {}
  },
  clearEvents (state: EventsState) {
    state.events = []
  },
  setCurrentCasterStream (state: EventsState, stream: { streamId: string, type: UserStreamType }) {
    state.currentCasterStream = stream.streamId
    state.castingAs = stream.type
  },
  clearCurrentCasterStream (state: EventsState) {
    state.currentCasterStream = null
    state.castingAs = null
    state.casting = false
  },
  updateMonitorVolume (state: EventsState, volumes: KeyDict<number>) {
    state.monitorVolumes = volumes
  },
  updateActiveAudioTracks (state: EventsState,
                           payload: { castId: string, trackLabel: MixerTrackLabelWithDefault, enabled: boolean})
  {
    lazyUpdate(state.activeAudioTracks, payload.castId, { [payload.trackLabel]: payload.enabled}, false)
  },
  clearActiveAudioTracks (state: EventsState, castId: string) {
    lazyUpdate(state.activeAudioTracks, castId, {})
  },
  setInCast (state: EventsState, flag: boolean) {
    state.inCast = flag
  },
  updateLiveEditVolumes (state: EventsState, payload: KeyDict<number>) {
    Object.keys(payload).forEach(audioChannelId => state.liveEditVolumes[audioChannelId] = payload[audioChannelId])
  },
  removeLiveEditVolumes (state: EventsState, audioChannelIds: string[]) {
    const tmpVolumes = { ...state.liveEditVolumes }
    audioChannelIds.forEach(audioChannelId => delete tmpVolumes[audioChannelId])
    state.liveEditVolumes = tmpVolumes
  },
  clearLiveEditVolumes (state: EventsState) {
    state.liveEditVolumes = {}
  },
  changeAudioMix (state: EventsState, val: AudioMixType) {
    if (val === AudioMixType.Monitor && Object.keys(state.monitorVolumes).length === 0) {
      state.monitorVolumes = Object.assign({}, state.programVolumes)
    }
    state.audioMix = val
  },
  setCasting (state: EventsState, casting: boolean) {
    state.casting = casting
  },
  setOpenScene (state: EventsState, sceneId: string|null) {
    if (state.openScene === sceneId) {
      state.openScene = null
    } else {
      state.openScene = sceneId
    }
  },
  setSceneFilter (state: EventsState, filter: string) {
    state.sceneFilter = filter
  },
  toggleSelfMute (state: EventsState, status: boolean): void {
    state.selfMute = status
  },
  updateMonitorMutes (state: EventsState, payload: KeyDict<boolean>) {
    state.monitorMutes = payload
  },
  updateTalkbackVolumes (state: EventsState, payload: KeyDict<number>) {
    state.talkbackVolumes = {
      ...state.talkbackVolumes,
      ...payload
    }
  },
  updateTalkbackMutes (state: EventsState, payload: KeyDict<boolean>) {
    state.talkbackMutes = {
      ...state.talkbackMutes,
      ...payload
    }

    const someUnmuted = Object.values(payload || {}).some(muted => muted === false)
    const globalMuteOn = state.talkbackGlobalMute
    if (someUnmuted && globalMuteOn) {
      state.talkbackGlobalMute = false
    }
  },
  changeGlobalTalkbackVolume (state: EventsState, payload: number) {
    state.talkbackGlobalVolume = payload
    Object.keys(state.talkbackVolumes).forEach(key => {
      state.talkbackVolumes[key] = payload
    })
  },
  changeTalkbackMode (state: EventsState, payload: TalkbackMode) {
    state.talkbackMode = payload
  },
  changeLocalGlobalVolume (state: EventsState, payload: number) {
    state.localGlobalVolume = payload
  },
  toggleTalkbackGlobalMutes (state: EventsState) {
    state.talkbackGlobalMute = !state.talkbackGlobalMute
    for (const key in state.talkbackMutes) {
      state.talkbackMutes[key] = state.talkbackGlobalMute
    }
  },
  setCastReady (state: EventsState) {
    if (state.castReady) return
    state.castReady = true
  },
  updateActionPaletteTab (state: EventsState, payload: ActionPaletteTabs) {
    state.actionPaletteTab = payload
  },
  setAudioInit (state: EventsState, init: boolean) {
    state.audioInit = init
  },
  setPreloadSourceStreams (state: EventsState, sourceStreams: KeyDict<SourceStream>) {
    state.preloadSourceStreams = sourceStreams as KeyDict<SourceStream>
  },
  setEditPresetId (state: EventsState, presetId: string|null) {
    state.editPresetId = presetId
  },
  setEditSceneId (state: EventsState, sceneId: string|null) {
    state.editSceneId = sceneId
  }
}

export default mutations
