import * as Sentry from '@sentry/browser'
import store from '../store/'


const sanitizeMarkup = (markup: string): string => {
  return markup.replace(/<svg.*<\/svg>/gi,'--SVG--');
}

const elementToString = (element: HTMLElement) => {
  return element && sanitizeMarkup(element.outerHTML) || ''
}

const logClickEvents = (breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) => {
  // console.log('beforeBreadcrumb FilterLog ', breadcrumb, hint)

  let qaElementName = ''
  if (hint.event && hint.event.target) {
    // console.log('^^^^ FOUND EVENT ', hint.event.target)
    const qaElement = hint.event.target.closest('[data-qa]')
    qaElementName = (qaElement) ? qaElement.getAttribute('data-qa') : ''

    let DOMelement = hint.event.target
    if (DOMelement && DOMelement.tagName.toLowerCase() === 'path') {
      DOMelement = DOMelement.parentElement
    }
    if (DOMelement && DOMelement.tagName.toLowerCase() === 'svg') {
      DOMelement = DOMelement.parentElement
    }

    // console.log('- -qaElement ', qaElement)
    // console.log('- -DOMelement ', DOMelement)

    const elementMessage = (qaElementName) ? `data-qa:${qaElementName}` : elementToString(DOMelement)
    store.dispatch.logs.addLog({
      message: `${breadcrumb.category} ${elementMessage}`,
      UIevent: breadcrumb.category,
      UIcontext: qaElementName || 'undefined',
      UItarget: elementToString(DOMelement),
      level: 'info'
    })

  }

}

const logNavigationEvents = (breadcrumb: Sentry.Breadcrumb, _hint: Sentry.BreadcrumbHint) => {
  const navTarget = breadcrumb.data && breadcrumb.data.to
  // console.log('--- navigation', navTarget, breadcrumb, hint)

  store.dispatch.logs.addLog({
    message: `${breadcrumb.category} '${navTarget}' `,
    UIevent: breadcrumb.category,
    UIcontext: navTarget,
    level: 'info'
  })
}

export const filterLogs = (breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) => {
  // console.log('breadcrumb.category ', breadcrumb.category)
  switch (breadcrumb.category) {
    case 'ui.input':
    case 'ui.click':
      logClickEvents(breadcrumb, hint)
      break
    case 'navigation':
      logNavigationEvents(breadcrumb, hint)
      break
  }

  return breadcrumb; // pass to Sentry unaltered (until we decide otherwise)
}
