
import releaseNotes from '@/ReleaseNotes'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration, CaptureConsole } from '@sentry/integrations'
import { Breadcrumbs } from '@sentry/browser/dist/integrations'
import env from './env'
import { KENV } from './types/admin'
import { filterLogs } from '@/classes/logHooks'
import { App } from 'vue'

const setupDebug = (app: App, kenv: KENV) => {
  if (kenv !== KENV.Dev) {
    app.config.devtools = false
    app.config.performance = false
    app.config.silent = true
  } else {
    // app.config.devtools = true
    app.config.performance = false
  }

  // set true if you want DEV env to trigger logs
  const LOGS_ACTIVATED = false || kenv !== KENV.Dev
  if (LOGS_ACTIVATED) {
    Sentry.init({
      dsn: env.sentry_loc,
      release: releaseNotes().tagId.replace('v', ''),
      environment: kenv,
      debug: false,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      beforeBreadcrumb: filterLogs,
      integrations: [
        new VueIntegration({
          Vue: app,
          attachProps: true
        }),
        new CaptureConsole({
          levels: ['error']
        }),
        new Breadcrumbs({
          dom: true
        })
      ],
    })
  }
}

export default setupDebug
