import store from '@/store'
import { onMounted, onUnmounted, computed, ComputedRef, watch } from 'vue'


const vumeters = computed(() => store.state.streams.vumeters)
const useVuMeters = (streamIds: ComputedRef<string[]>) => {
  onMounted(async () => {
    const promises = []
    for (const streamId of streamIds.value) {
      promises.push(store.dispatch.streams.subscribeVuMeter(streamId))
    }
    await Promise.all(promises)
  })

  onUnmounted(async () => {
    const promises = []
    for (const streamId of streamIds.value) {
      promises.push(store.dispatch.streams.unsubscribeVuMeter(streamId))
    }
    await Promise.all(promises)
  })

  watch(streamIds, async (newStreamIds, oldStreamIds) => {
    for (const streamId of newStreamIds.filter((streamId) => !oldStreamIds.includes(streamId))) {
      await store.dispatch.streams.subscribeVuMeter(streamId)
    }
    for (const streamId of oldStreamIds.filter((streamId) => !newStreamIds.includes(streamId))) {
      await store.dispatch.streams.unsubscribeVuMeter(streamId)
    }
  })

  return {
    vumeters
  }
}

export default useVuMeters


