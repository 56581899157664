import helpers from '@/helpers'

export const getVolumeName = (key: string) => {
  const tempName = key.split('_')[1]
  let volumeName = 'volume'
  if (tempName !== undefined) volumeName += helpers.initialCaps(tempName)
  return volumeName
}

export const getMutedName = (key: string): 'muted'|'leftMuted'|'rightMuted' => {
  const keys = key.split('_')
  if (keys.length > 1) {
    if (keys[1] === 'left') return 'leftMuted'
    if (keys[1] === 'right') return 'rightMuted'
  }
  return 'muted'
}
