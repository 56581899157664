<template>
  <KisweOverlay
    v-if="showBrowserCheck"
    class="browser-check"
    :isModal="true"
    :showClose="false"
    :showLanguageSelector="true"
  >
    <template v-slot:message><span>{{ getTranslation(messageKey) }}</span></template>
    <div class="browser-check__buttons">
      <KisweButton
        theme="primary"
        @click="openChromeDownload"
      ><KisweIcon name="brands.chrome" />{{ getTranslation('browsercheck_download_chrome') }}</KisweButton>
      <KisweButton
        theme="primary"
        class="browser-check__button--mobile-hidden"
        @click="openEdgeDownload"
      ><KisweIcon name="brands.edge" />{{ getTranslation('browsercheck_download_edge') }}</KisweButton>
      <KisweButton
        theme="primary"
        @click="openSafariDownload"
      ><KisweIcon name="brands.safari" />{{ getTranslation('browsercheck_download_safari') }}</KisweButton>
      <KisweButton
        theme="primary"
        class="browser-check__button--mobile-hidden"
        @click="openFirefoxDownload"
      ><KisweIcon name="brands.firefox" />{{ getTranslation('browsercheck_download_firefox') }}</KisweButton>
    </div>
  </KisweOverlay>
</template>

<script lang="ts">
import './BrowserCheck.scss'
import { defineComponent, computed } from 'vue'
import { isMobile } from 'mobile-device-detect'
import { KisweButton, KisweOverlay, KisweIcon } from 'kiswe-ui'
import useTranslations from '@/compositions/translations'
import { useTeamBranding } from '@/modules/teams/compositions'
import store from '@/store'

const openChromeDownload = () => window.open('https://www.google.com/intl/en_uk/chrome/', '_blank')
const openEdgeDownload = () => window.open('https://www.microsoft.com/en-us/edge', '_blank')
const openFirefoxDownload = () => window.open('https://www.mozilla.org/en-US/firefox/new/', '_blank')
const openSafariDownload = () => window.open('https://support.apple.com/downloads/safari', '_blank')

export default defineComponent({
  name: 'BrowserCheck',
  components: {
    KisweButton,
    KisweIcon,
    KisweOverlay
  },
  setup () {
    const { isBranded, isBrandingLoaded } = useTeamBranding()
    return {
      ...useTranslations(),
      messageKey: computed(() => isMobile
        ? 'browsercheck_supportmobilebrowser_text' : 'browsercheck_supportbrowser_text'),
      openChromeDownload,
      openEdgeDownload,
      openFirefoxDownload,
      openSafariDownload,
      showBrowserCheck: computed(() => !isBranded || isBrandingLoaded)
    }
  },
  computed: {
    pointerId (): string|undefined {
      return this.$route.params.pointerId
    }
  },
  watch: {
    pointerId (newPointerId: string|undefined) {
      if (newPointerId) store.dispatch.team.getTeamBrandingByPointerId(newPointerId)
    }
  }
})
</script>
