import { LOCAL_DEV_HOST } from '@/env'
import { Loader } from 'kiswe-ui'
import { TeamDnsOverrides } from '@/modules/teams/types'
import { isObject } from 'lodash'

export enum PointerTypes {
  EVENT = 'event',
  REDIRECT = 'redirect'
}

export default abstract class LinkPointerBase {
  studioVersion = ''
  team_id = ''
  urlSuffix = ''

  constructor (data: unknown) {
    if (data === undefined || !isObject(data)) return
    //Set universally applicable properties for any/all pointer types
    //Should the below 2 even be considered universally applicable?
    this.studioVersion = Loader.loadString(data, 'studioVersion', this.studioVersion)
    this.team_id = Loader.loadString(data, 'team_id', this.team_id)
    this.urlSuffix = Loader.loadString(data, 'urlSuffix', this.urlSuffix)
    //Subclass constructors set props related to their specific pointer type here
  }

  //Needs to be overloaded in each subclass
  abstract get targetUrl (): string

  get targetBasePath (): string {
    // Include team version in path (when available) except when running localhost
    try {
      if (window.location.origin.includes(LOCAL_DEV_HOST)) return ''
    } catch {}
    return this.studioVersion ? '/' + this.studioVersion : ''
  }

  getPublicPointerUrl (pointerId: string, dnsOverrides: TeamDnsOverrides|null, isFixedRegion: boolean): string {
    const orgUrl = this.getPointerUrl(pointerId)
    let newHost = null
    if (isFixedRegion && dnsOverrides?.linkPointerChina) {
      newHost = dnsOverrides.linkPointerChina
    } else if (!isFixedRegion && dnsOverrides?.linkPointerInternational) {
      newHost = dnsOverrides.linkPointerInternational
    }
    if (!newHost) return orgUrl

    const newUrl = new URL(orgUrl)
    // Setting `host` will only update the port if the new host contains a port specification. If it doesn't we need
    // to clear the port manually.
    if (newHost.split(':')[1] === undefined) newUrl.port = ''
    newUrl.host = newHost
    return newUrl.toString()
  }

  //To be overloaded by a subclass if needed
  getPointerUrl (pointerId: string): string {
    //Returns the share link for this pointer,
    //but since pointerId is not stored in the class instance, it must be provided
    //We might want to change to storing the id instead so that this is a proper instance method
    let url = `https://studio.kiswe.com${ this.targetBasePath }/link/${ pointerId }`
    try {
      url = `${ window.location.origin }${ this.targetBasePath }/link/${ pointerId }`
    } catch {}
    if (this.urlSuffix) url += '#' + this.urlSuffix
    return url
  }

  static getPointerIdFromUrl (rawUrl: string): string|null {
    if (!rawUrl) return null
    try {
      return new URL(rawUrl).pathname.split('/link/')[1] ?? null
    } catch {
      return null
    }
  }
}
