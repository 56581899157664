import KisweDate from '@/modules/common/classes/KisweDate'

const msgWithTimestamp = (msg: string) => `${msg} @ ${KisweDate.format(Date.now()/1000, 'MM/DD/yy HH:mm:ss.SSS')}`
const pretty = (obj: any) => JSON.stringify(obj, null, 2)
const consoleDebug = (msg: string, ...optionalParams: any[]) => console.debug(msgWithTimestamp(msg), ...optionalParams)
const consoleLog = (msg: string, ...optionalParams: any[]) => console.log(msgWithTimestamp(msg), ...optionalParams)
const consoleWarn = (msg: string, ...optionalParams: any[]) => console.warn(msgWithTimestamp(msg), ...optionalParams)
const consoleError = (msg: string, ...optionalParams: any[]) => console.error(msgWithTimestamp(msg), ...optionalParams)

export {
  consoleDebug,
  consoleLog,
  consoleWarn,
  consoleError,
  pretty
}
