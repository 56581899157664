import { stripHTML } from '@/classes/stripHTML'
import { availableParameters } from '@/plugins/translations/availableParameters'
import { TranslationKeys } from '@/plugins/translations/types'
import { KeyDict } from '@/types'
import { mapValues } from 'lodash'
import VueI18n, { useI18n } from 'vue-i18n'

export type Translation = VueI18n.TranslateResult

const useTranslations = () => {
  const { t } = useI18n()

  const getTranslation = (key: TranslationKeys, values?: KeyDict<string>, removeHTML: boolean = true): Translation => {
    let strippedValues: KeyDict<string> = {}
    if (values !== undefined) {
      strippedValues = removeHTML ? mapValues(values, value => stripHTML(value)) : values
    }
    const requiredParams = availableParameters[key]
    const givenParameters = Object.keys(strippedValues)
    requiredParams.forEach(param => {
      if (!givenParameters.includes(param)) throw new Error(`Translation for ${key} requires the parameter ${param}`)
    })
    givenParameters.forEach(param => {
      if (!requiredParams.includes(param)) throw new Error(`Translation for ${key} does not need the parameter ${param}`)
    })
    return t(key, strippedValues)
  }

  return {
    getTranslation
  }
}

export default useTranslations
