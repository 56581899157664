import { ThorApiSettings } from '../types'
import Analytics from './Analytics'
import ThorApiCall from './ThorApiCall'
import { ThorApiRequest } from './ThorApiRequest'

export default class ThorApi {
  private thorApiRequest: ThorApiRequest
  public analytics: Analytics
  public generic: ThorApiCall

  constructor (baseUrl: string, appSettings: ThorApiSettings, apiToken: string = '') {
    this.thorApiRequest = new ThorApiRequest()
    this.analytics = new Analytics(this.thorApiRequest)
    this.generic = new ThorApiCall(this.thorApiRequest)
    this.setBaseUrl(baseUrl)
    this.updateSettings(appSettings)
    if (apiToken && apiToken !== '') this.setApiToken(apiToken)
  }

  public setBaseUrl (url: string) {
    this.thorApiRequest.baseUrl = url
  }

  public updateSettings (settings: ThorApiSettings) {
    this.thorApiRequest.acceptHeader =
      'application/json;' +
      `app=${settings.appName};` +
      `os_version=${settings.osVersion};` +
      `device_type=${settings.deviceType}`
  }

  public setApiToken (apiToken: string) {
    this.thorApiRequest.apiToken = apiToken
  }
}
