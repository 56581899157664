/* eslint-disable complexity */
import { REDIRECT_VERSION_PLACEHOLDER } from '../../../types/pointers'
import env from '../../../env'
import { CollectionNames } from '@/modules/database/utils'
import { Team } from '@/modules/teams/classes'
import firebase from 'firebase/compat/app'
import { cloneDeep } from 'lodash'
import { updateContents } from './addTemplatesToTeam'
import createTeamStream from './createTeamStream'
import { addAssetsToTeam, getAllDefaultAssets } from './defaultAssetsToTeam'
import DefaultIdsMapping from './defaultIdsMapping'
import { CreateTeamError, CreateTeamErrorCode } from './errors'
import { Result } from 'kiswe-ui'

export type CreateTeamParams = Partial<Team> & { default_region: string }

export const addDefaultLayoutsToTeam = async (
  transaction: firebase.firestore.Transaction,
  db: firebase.firestore.Firestore,
  teamId: string,
  idsMapping: DefaultIdsMapping
): Promise<Result<DefaultIdsMapping, CreateTeamErrorCode>> => {
  const snap = await db
    .collection(CollectionNames.LAYOUTS)
    .where('team_id', '==', 'all')
    .where('deleted', '==', false)
    .get()
  const newMapping = cloneDeep(idsMapping)
  snap.docs.forEach((doc) => {
    const layout = doc.data()
    layout.team_id = teamId
    updateContents(layout, idsMapping)
    if (layout.existingLayout !== undefined) updateContents(layout.existingLayout, idsMapping)

    const newLayoutRef = db.collection(CollectionNames.LAYOUTS).doc()
    const teamDocRef = db.collection(CollectionNames.TEAMS).doc(teamId)
    if (layout.name === 'Fullscreen') {
      transaction.set(teamDocRef, { 'default_layout': newLayoutRef.id }, { merge: true })
    }
    transaction.set(newLayoutRef, { ...layout, id: newLayoutRef.id })
    newMapping.layoutIds.set(doc.id, newLayoutRef.id)
  })
  return Result.success(newMapping)
}

// eslint-disable-next-line max-lines-per-function
const createNewTeam = async (
  db: firebase.firestore.Firestore,
  team: CreateTeamParams,
  appUrl: string
): Promise<Result<string, CreateTeamErrorCode>> => {
  const payload: Partial<Team> = {
    k360_channel_id: env.k360_channel_id,
      // Note: Teams created via localhost will have a localhost `redirectUrl`
    redirectUrl: `${appUrl}/${REDIRECT_VERSION_PLACEHOLDER}/roomredirect`,
    ...JSON.parse(JSON.stringify(team)) //Ensure plain objects are sent instead of custom classes
  }
  const assetsResults = await getAllDefaultAssets(db)
  if (assetsResults.isFailure) return assetsResults.convert()
  // TODO return Promise.Reject on Error
  let idsMapping: DefaultIdsMapping = {
    assetIds: assetsResults.value.assetIds,
    assetGroupIds: assetsResults.value.assetGroupIds,
    layoutIds: new Map<string, string>(),
    streamIds: new Map<string, string>()
  }
  try {
    // eslint-disable-next-line max-lines-per-function
    const teamId = await db.runTransaction(async (transaction) => {
      const allTeamsDocs = await db.collection(CollectionNames.TEAMS).get()
      allTeamsDocs.forEach((doc) => {
        if (payload.name?.toLowerCase() === doc.data().name.toLowerCase()) {
          throw new CreateTeamError(CreateTeamErrorCode.DUPLICATE_TEAM_NAME, 'That team name is already taken.')
        }
      })
      const teamDoc = db.collection(CollectionNames.TEAMS).doc()
      transaction.set(teamDoc, { ...payload, id: teamDoc.id })
      const teamId = teamDoc.id
      addAssetsToTeam(transaction, db, assetsResults.value, teamId)
      for (const stream of Object.values(team.streams ?? [])) {
        const result = createTeamStream(transaction, db, stream, teamId)
        if (result.isFailure) {
          throw new CreateTeamError(CreateTeamErrorCode.COULD_NOT_CREATE_STREAM, result.message)
        }
        if (stream.templateId) {
          idsMapping.streamIds.set(stream.templateId, result.value)
        }
      }
      const layoutResult = await addDefaultLayoutsToTeam(transaction, db, teamId, idsMapping)
      if (layoutResult.isFailure) throw layoutResult.error
      idsMapping = layoutResult.value
      return teamId
    })
    return Result.success(teamId)
  } catch (error) {
    if (error instanceof CreateTeamError) {
      return Result.fail(error.code, error.message)
    }
    return Result.fail(CreateTeamErrorCode.UNKNOWN, `${error}`)
  }
}

export default createNewTeam
