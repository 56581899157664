const sleep = (ms: number) => {
  return new Promise(resolve => {
    try {
      setTimeout(resolve, ms)
    } catch (error) { console.error('setTimeout error', error) }
  })
}

export type TestFunction = () => boolean|Promise<boolean>

export const sleepUntilTrue = async (testFunction: TestFunction, maxTimeout: number = 2000) => {
  let count = 0
  // Not using Dates anymore as this is often used in unit testing and sometimes the date is Mocked
  const maxIterations = maxTimeout / 10
  let result = await testFunction()
  while (!result && count < maxIterations) {
    await sleep(10)
    result = await testFunction()
    count++
  }
  return result
}

export default sleep
