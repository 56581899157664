import fb from '../../firebase'
import { RootState } from '../types'
import { moduleActionContext } from '..'
import { BotsState } from '@/store/types'
import firebase from 'firebase/compat/app'
import { ActionContext } from 'vuex'
import { KeyDict, DeepPartial } from '@/types'
import { Bot } from '@/types/bots'
import { defineModule } from 'direct-vuex'
import studio from '@/modules/database/utils'
import { FirestoreQuerySnapper, FirestoreSnapperType } from '@/modules/database/utils/databaseQuerySubscriptions'

type BotsContext = ActionContext<BotsState, RootState>

const botsModule = defineModule({
  namespaced: true,
  state: {
    bots: {}
  } as BotsState,
  actions: {
    subscribeBots (context: BotsContext, castId: string) {
      if (castId === '') return
      const { commit } = botsModuleActionContext(context)

      const key = `bots_${castId}`
      const snapper: FirestoreQuerySnapper = {
        query: fb.db.collection('bots').where('cast', '==', castId),
        mutation: commit.updateBots,
        type: FirestoreSnapperType.Query
      }
      studio.subscriptions.subscribeFB(key, snapper)
    },
    unsubscribeBots (_context: BotsContext, castId: string ) {
      if (castId === '') return
      const key = `bots_${castId}`
      studio.subscriptions.unsubscribeFB(key)
    },
    async updateBot (_context: BotsContext, params: { botId: string, update: DeepPartial<Bot>}) {
      await fb.db.collection('bots').doc(params.botId).set(params.update, { merge: true })
    },
    async newBot (_content: BotsContext, params: {castId: string, name: string }) {
      // TODO get from database orso
      const actions = [
        { type: 'switch_scenes', frequency: 5 },
        { type: 'activate_assets', frequency: 3 }
      ]
      const bot = {
        name: params.name,
        cast: params.castId,
        actions
      }
      await fb.db.collection('bots').add(bot)
    }
  },
  mutations: {
    updateBots (state: BotsState, snap: firebase.firestore.QuerySnapshot) {
      const bots : KeyDict<Bot> = {}
      snap.forEach(doc => {
        const data = doc.data()
        const bot = new Bot(data)
        bots[doc.id] = bot
      })
      state.bots = bots
    }
  }
})

export default botsModule
export const botsModuleActionContext = (context: BotsContext) => moduleActionContext(context, botsModule)
