import { ComputedRef, Ref, computed, ref, watch } from 'vue'
import { KeyDict } from '@/types'
import { IngestClipStatus } from '../types'
import { ReplayIngestDoc, ReplayIngestStatusDoc, ReplayPlaylist } from '../classes'
import useReplayObjects from './replayObjects'
import useReplayPlaylists from './replayPlaylists'
import { useCurrentCast } from '@/modules/casts/compositions'
import { SceneSourceType } from '@/modules/scenes/types'

const clipsNotReady: Ref<KeyDict<Set<string>>> = ref({})

const useReplayScene = (sceneId: Ref<string|null|undefined>) => {

  const { currentCast, currentCastId } = useCurrentCast()
  const isReplayScene = computed(() => sceneId.value
    ? currentCast.value?.isSceneSourceType(sceneId.value, SceneSourceType.Replay) ?? false
    : false
  )
  const replayObjects = useReplayObjects(currentCastId)
  const { getPlaylistIdOfReplayScene } = useReplayPlaylists()
  const replayPlaylistId = computed(() => sceneId.value ? getPlaylistIdOfReplayScene(sceneId.value)
    : null)
  const replayPlaylist: ComputedRef<ReplayPlaylist|null> =
    computed(() => replayObjects.replayPlaylists.value[replayPlaylistId.value ?? ''] ?? null)
  const replayIngest: ComputedRef<ReplayIngestDoc|null> =
    computed(() => replayObjects.replayIngests.value[replayPlaylistId.value ?? ''] ?? null)
  const replayIngestStatus = computed(() => replayIngest.value?.status ?? null)
  const replayClipIds = computed(() => (replayPlaylist.value) ? replayPlaylist.value.clipIdList : null)
  const replayClips = computed(() => replayClipIds.value ?
    replayClipIds.value.map((clipId) => replayObjects.replayClips.value[clipId]) : null)
  const sceneClipsNotReady = computed(() => clipsNotReady.value[replayPlaylistId.value ?? ''] ?? new Set([]))
  let watchIngestStatusHandler: any = null

  const addToWatchList = (clipId: string): void => {
    if (!replayPlaylistId.value) return
    if (isClipReady(clipId)) return
    const clipsNotReadyUpdate = Object.assign({}, clipsNotReady.value)
    if (!clipsNotReadyUpdate[replayPlaylistId.value]) clipsNotReadyUpdate[replayPlaylistId.value] = new Set([clipId])
    else clipsNotReadyUpdate[replayPlaylistId.value].add(clipId)
    clipsNotReady.value = clipsNotReadyUpdate
    startClipNotReadyWatcher()
  }

  const removeFromWatchList = (clipId: string): void => {
    if (replayPlaylistId.value) {
      if (clipsNotReady.value[replayPlaylistId.value]) {
        const clipsNotReadyUpdate = Object.assign({}, clipsNotReady.value)
        clipsNotReadyUpdate[replayPlaylistId.value].delete(clipId)
        clipsNotReady.value = clipsNotReadyUpdate
      }
    }
    stopClipStatusWatcherIfAllClipsReady()
  }

  const startClipNotReadyWatcher = (): void => {
    if (watchIngestStatusHandler) return
    watchIngestStatusHandler = watch(replayIngestStatus, async (newStatus) => {
      if (!newStatus) return
      const clipIdsNotReady = [...sceneClipsNotReady.value]
      for (const clipId of clipIdsNotReady) {
        if (isClipReady(clipId, newStatus)) removeFromWatchList(clipId)
      }
      stopClipStatusWatcherIfAllClipsReady()
    }, { immediate: true })
  }

  const stopClipStatusWatcherIfAllClipsReady = (): void => {
    if (sceneClipsNotReady.value.size === 0) {
      if (watchIngestStatusHandler) {
        watchIngestStatusHandler()
        watchIngestStatusHandler = null
      }
    }
  }

  const isClipReady = (clipId: string, ingestStatus?: KeyDict<ReplayIngestStatusDoc>): boolean => {
    const ingestStatusDoc = ingestStatus ?? replayIngestStatus.value ?? {}
    let isClipReady = Object.values(ingestStatusDoc).length > 0
    for (const ingestStatus of Object.values(ingestStatusDoc)) {
      isClipReady = isClipReady && (ingestStatus.replay_status[clipId]?.status === IngestClipStatus.LOADED)
    }
    return isClipReady
  }

  watch(replayClipIds, (newVal, oldVal) => {
    const newClipIds = newVal ?? []
    const oldClipIds = oldVal ?? []
    for (const clipId of oldClipIds) if (!newClipIds.includes(clipId)) removeFromWatchList(clipId)
    for (const clipId of newClipIds) if (!oldClipIds.includes(clipId)) addToWatchList(clipId)
  })

  return {
    isReplayScene,
    replayClipIds,
    replayClips,
    replayPlaylistId,
    replayPlaylist,
    replayIngest,
    replayIngestStatus,
    sceneClipsNotReady
  }

}

export default useReplayScene
