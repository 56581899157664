import fb from '@/firebase'
import store from '@/store'
import { UserMediaState } from '@/store/types'
import { KeyDict } from '@/types'
// import { StreamType } from '@/types/streams'
import { ConnectStatus, DeviceList, Resolution, UserMediaPermissions } from '@/types/usermedia'
import { cloneDeep } from 'lodash'

const mutations = {
  setAutoConnect (state: UserMediaState, isAutoConnect: boolean) {
    state.autoConnect = isAutoConnect
  },
  setConnectStatus (state: UserMediaState, connectStatus: ConnectStatus) {
    state.connectStatus = connectStatus
  },
  setForceChinaProxy (state: UserMediaState, forceChinaProxy: boolean) {
    state.forceChinaProxy = forceChinaProxy
  },
  setIsConnected (state: UserMediaState, isConnected: boolean) {
    state.isConnected = isConnected
  },
  setLocalCameraStream (state: UserMediaState, stream: MediaStream|null) {
    state.localCameraStream = stream
  },
  setLocalDesktopStream (state: UserMediaState, stream: MediaStream|null) {
    state.localDesktopStream = stream
  },
  setRemoteStreamSrcUrl (state: UserMediaState, srcUrl: string|null) {
    state.remoteStreamSrcUrl = srcUrl
  },
  setPermissions (state: UserMediaState, permissions: UserMediaPermissions) {
    state.permissions = permissions
  },
  killLocalCameraStream (state: UserMediaState) {
    // state.streamType = null
    if (state.localCameraStream === null) return
    if (state.localCameraStream.getAudioTracks().length > 0) {
      state.localCameraStream.getAudioTracks()[0].stop()
    }
    if (state.localCameraStream.getVideoTracks().length > 0) {
      state.localCameraStream.getVideoTracks()[0].stop()
    }
    state.localCameraStream = null
  },
  setDeviceList (state: UserMediaState, deviceList: DeviceList|null) {
    state.deviceList = deviceList
  },
  async setTalkbackStatus (_state: UserMediaState, talkback: boolean) {
    const currentCast = store.state.events.currentCast
    if (currentCast === null) throw new Error('Could not set talkbackstatus is currentcast is null')
    const talkbackCopy: KeyDict<boolean> = cloneDeep(currentCast.talk_back)
    const currentCasterStream = store.state.events.currentCasterStream
    try {
      if (currentCasterStream === null) {
        throw new Error('currentCasterStream cannot be null')
      }
      talkbackCopy[currentCasterStream] = talkback
      await fb.db.collection('casts').doc(currentCast.id).set({ talk_back: talkbackCopy }, { merge: true })
    } catch (error) {
      console.error('setTalkbackStatus', error)
    }
  },
  toggleSelfMute (state: UserMediaState, isMuted: boolean) {
    state.isSelfMuted = isMuted
    if (state.localCameraStream === null) return
    if (state.localCameraStream.getAudioTracks().length === 0) return
    state.localCameraStream.getAudioTracks()[0].enabled = !isMuted //Toggle actual device mute
  },
  isDetectingDevices (state: UserMediaState, isCurrentlyDetecting: boolean) {
    state.isDetectingDevices = isCurrentlyDetecting
  },
  setWrtcServer (state: UserMediaState, wrtcServer: string|null) {
    state.wrtcServer = wrtcServer
  },
  setWrtcServerChinaProxy (state: UserMediaState, wrtcServer: string|null) {
    state.wrtcServerChinaProxy = wrtcServer
  },
  setWrtcServerChina (state: UserMediaState, wrtcServer: string|null) {
    state.wrtcServerChina = wrtcServer
  },
  setStreamReady (state: UserMediaState, isReady: boolean) {
    state.isStreamReady = isReady
    if (!isReady || state.localCameraStream === null) return
    if (state.localCameraStream.getAudioTracks().length === 0) return
    const isAudioEnabledByDefault = store.getters.usermedia.currentUserCanCast || store.state.usermedia.isModerator
    state.localCameraStream.getAudioTracks()[0].enabled = isAudioEnabledByDefault
  },
  setSupportCamera (state: UserMediaState, supportCamera: boolean) {
    state.supportCamera = supportCamera
  },
  setStreamDocId (state: UserMediaState, docId: string|null) {
    state.streamDocId = docId
    if (docId === null) state.isConnected = false
  },
  setIsDetectingDevices (state: UserMediaState, isDetectingDevices: boolean) {
    state.isDetectingDevices = isDetectingDevices
  },
  setCurrentRate (state: UserMediaState, rate: number) {
    state.currentRate = rate
  },
  resetSupportedResolutions (state: UserMediaState) {
    state.checkedResolution = 0
    state.currentRate = 0
    state.supportedResolutions = []
  },
  addSupportedResolution (state: UserMediaState, resolution: Resolution) {
    state.supportedResolutions.push(resolution)
    state.checkedResolution += 1
  },
  setConstraints (state: UserMediaState, constraints: MediaStreamConstraints) {
    state.constraints = constraints
  },
  setIsModerator (state: UserMediaState, enabled: boolean) {
    state.isModerator = enabled
  },
  setDeleteOnCastCleanup (state: UserMediaState, enabled: boolean) {
    state.deleteOnCastCleanup = enabled
  },
  setIsRunningSelftest (state: UserMediaState, enabled: boolean) {
    state.isRunningSelftest = enabled
  },
  setIsSelfTestInSessionDone (state: UserMediaState, enabled: boolean) {
    state.isSelfTestInSessionDone = enabled
  },
  setHasDeviceError (state: UserMediaState, hasError: boolean) {
    state.hasDeviceError = hasError
  }
}

export default mutations
