import { Loader } from 'kiswe-ui'
import Convertable from '@/classes/Convertable'

export default class ActiveState extends Convertable {
  idle: boolean
  lastEntered: Date|null
  linked: number

  constructor (config: Partial<ActiveState> = {}) {
    super(config)

    this.idle = Loader.loadBoolean(config, 'idle', true)
    this.lastEntered = Loader.loadDateOrNull(config, 'lastEntered', null)
    this.linked = Loader.loadNumber(config, 'linked', 0)
  }
}
