import { ref, watch, computed, ComputedRef } from 'vue'
import { required, helpers, minLength, between } from '@vuelidate/validators'
import store from '@/store'
import { KeyDict } from '@/types'
import { UserProfile } from '@/types/users'
import { CallTriage } from '@/types/casts'
import useCurrentUserProfile from './useCurrentUserProfile'
import { useCurrentCast } from '@/modules/casts/compositions'

const { currentCast } = useCurrentCast()
const casters = computed(() => currentCast.value?.invited_casters ?? {})

const requestCasterRepublish = async (casterId: string) => {
  if (currentCast.value === null) throw new Error('Cannot requestCasterRepublish, currentCast is null')
  await store.dispatch.events.requestCasterRepublish({ cast: currentCast.value.id, caster: casterId })
}

export const userProfileRules = {
  first_name: {
    required: helpers.withMessage('First name is required', required),
    minLength: helpers.withMessage('First name must be at least 2 characters', minLength(2))
  },
  last_name: {
    required: helpers.withMessage('Last name is required', required),
    minLength: helpers.withMessage('Last name must be at least 2 characters', minLength(2))
  }
}

export const memberRoleRules = {
  editRole: {
    required: helpers.withMessage('Role is required', required)
  }
}

export const encoderSettingsRules = (maxVideoBitrate: number) => ({
  audioBitrate: {
    required: helpers.withMessage('An audio bitrate is required', required),
    between: helpers.withMessage('Audio bitrate should be between 16 and 256', between(16, 256))
  },
  videoBitrate: {
    required: helpers.withMessage('A video bitrate is required', required),
    between: helpers.withMessage(`Video bitrate should be between 200 and ${maxVideoBitrate}`,
      (value: number) => {
        if (value < 200) return false
        if (value > maxVideoBitrate) return false
        return true
      }
    )
  }
})

const useUserProfile = (userId: ComputedRef<string>) => {

  const { currentUserId, currentUserProfile } = useCurrentUserProfile()
  const userProfile = ref<UserProfile|null>(null)
  const needsRepublishing = ref<KeyDict<boolean>>({})
  const isAnonymous = computed(() => userProfile.value?.anonymous ?? false)
  const isUserLoggedIn = computed(() => store.state.user.userLoggedIn)

  watch(userId, (newValue: string) => {
    let profile = store.state.team.currentTeam[newValue]
    if (profile === undefined) {
      if (newValue === currentUserId.value && currentUserProfile.value !== null) {
        profile = currentUserProfile.value
      } else {
        profile = store.state.opsmonitor.users[userId.value]
      }
    }
    userProfile.value = profile?.clone() ?? null
  }, { immediate: true })

  const isInvitedCaster = computed(() => {
    return casters.value[userId.value] !== undefined && !casters.value[userId.value].deleted
  })

  const saveUserProfile = async (): Promise<boolean> => {
    if (userProfile.value === null || !isUserLoggedIn.value) return false
    const updatedMember: UserProfile = userProfile.value.clone()
    try {
      await store.dispatch.team.updateTeamMember({ teamId: store.state.team.activeTeam, member: updatedMember })
      const memberId = updatedMember.id
      if (needsRepublishing.value[memberId] === true && casters.value[memberId] !== undefined) {
        const callTriage = casters.value[memberId].triage
        if ([CallTriage.Unverified, CallTriage.InModeration].includes(callTriage)) {
          await requestCasterRepublish(memberId)
          needsRepublishing.value[memberId] = false
        }
      }
      return true
    } catch (error) {
      console.error('Error saveUserProfile', error)
    }
    return false
  }

  const setNeedsRepublishing = (newVal: { user_id: string, val: boolean }) => {
    needsRepublishing.value[newVal.user_id] = newVal.val
  }

  return {
    isAnonymous,
    isInvitedCaster,
    requestCasterRepublish,
    saveUserProfile,
    setNeedsRepublishing,
    userProfile
  }
}

export default useUserProfile
