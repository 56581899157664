import { ActionContext } from 'vuex'
import { UserMediaState, RootState } from '@/store/types'
import { usermediaModuleActionContext } from '.'
import { ConnectStatus, DeviceList, MediaDevice, UserMediaPermissions } from '@/types/usermedia'
import store from '@/store'
import fb from '@/firebase'
import { StreamDoc, StreamType } from '@/types/streams'
import { LogLevel } from '@/types/logs'
import WRTCLoadbalancer from '@/modules/transcodingregions/classes/WRTCLoadbalancer'
import { availableResolutions, getAudioConstraints, getAllMediaPermissions, getMediaPermission, getVideoConstraints, setupDevice, updateDefaultDevicesIfNecessary, constraintToUserMedia } from './usermedia.helpers'
import { UserProfile } from '@/types/users'
import { cloneDeep } from 'lodash'
import { CollectionNames } from '@/modules/database/utils/collectionNames'
import { sleepUntilTrue } from '@/testing/sleep'
import { MVar } from '@/helpers/async_helpers'
import studio from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { Result, ResultVoid } from 'kiswe-ui'
import { generateRandomString } from '@/modules/common/utils'
import { TranscodingRegion, WRTCServerType } from '@/types/admin'
import { Feature, hasFeature } from '@/featureFlags/featureFlags'
import { getContentIdByUserId } from '@/modules/scenes/utils/sceneContents'

export type UserMediaContext = ActionContext<UserMediaState, RootState>

let resolutionsToCheck = cloneDeep(availableResolutions)

// FIXME: needs to be cleaned up. Goal should be that it is clear which action you can call and in which environment.
// At this point, you need to call multiple calls in the right order in order for it to work
// + make sure that promises are awaited and make right functions async
// + never adjust state inside a dispatch
// + type check everything

// These vars assume that there is only 1 module in existence at all time
let insideConnectStreams = false
// TODO: With all functions being awaited on, the MVar might no longer be needed.
const startCastingMVar = new MVar()

const getDocId = (currentUserId: string): string => {
  const currentCast = store.state.events.currentCast
  if (currentCast) {
    const contentId = getContentIdByUserId(currentUserId, currentCast.scenes)
    if (contentId !== null) return contentId
  }
  return generateRandomString(20)
}

const getChinaOverrideRegion = (): TranscodingRegion|undefined => {
  //We are currently supporting only one region with one server
  const region = Object.values(store.state.admin.transcodingregions).find((transcodeRegion) => {
    return transcodeRegion.chinaProxy
  })
  return region
}

const getChinaServer = async () => {
  const isJanus = hasFeature(Feature.JANUS) ?? false
  const transcodingRegion = getChinaOverrideRegion()
  const loadbalancer = new WRTCLoadbalancer()
  let loadbalancedServer = null
  if (transcodingRegion) loadbalancedServer = await loadbalancer.getServerWhenReady(transcodingRegion, 12, isJanus)
  return loadbalancedServer
}

const actions = {
  setPermissions (context: UserMediaContext, permissions: UserMediaPermissions) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setPermissions(permissions)
  },
  async setForceChinaProxy (context: UserMediaContext, forceChinaProxy: boolean) {
    //This function configures a extra server to which the stream is send. and sets the proxy to the right china dns name
    const { commit, dispatch, state } = usermediaModuleActionContext(context)
    if (state.forceChinaProxy === forceChinaProxy) return
    commit.setForceChinaProxy(forceChinaProxy)
    if (state.forceChinaProxy) {
      const chinaServer = await getChinaServer()
      commit.setWrtcServerChina(chinaServer?.url ?? null)
      commit.setWrtcServerChinaProxy(chinaServer?.chinaProxyUrl ?? null)
      await dispatch.updateSrcUrlChina()
    }
  },
  setIsConnected (context: UserMediaContext, isConnected: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setIsConnected(isConnected)
  },
  setLocalCameraStream (context: UserMediaContext, stream: MediaStream|null) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setLocalCameraStream(stream)
    commit.setStreamReady(!!stream)
  },
  setLocalDesktopStream (context: UserMediaContext, stream: MediaStream|null) {
    const { commit, state } = usermediaModuleActionContext(context)
    if (state.localDesktopStream !== null && stream === null) {
      state.localDesktopStream.getTracks().forEach((track) => track.stop())
    }
    commit.setLocalDesktopStream(stream)
  },
  setSupportCamera (context: UserMediaContext, flag: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setSupportCamera(flag)
  },
  setStreamDocId (context: UserMediaContext, id: string|null) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setStreamDocId(id)
  },
  setIsRunningSelftest (context: UserMediaContext, enabled: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setIsRunningSelftest(enabled)
  },
  setIsSelfTestInSessionDone (context: UserMediaContext, enabled: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setIsSelfTestInSessionDone(enabled)
  },
  async updateSrcUrlChina (context:UserMediaContext) {
    const { state } = usermediaModuleActionContext(context)
    const streamDocId = state.streamDocId
    if (!streamDocId) return
    const srcPrefixChina = `https://${ state.wrtcServerChinaProxy }/whep/endpoint/`
    const payload: Partial<StreamDoc> =  {
      srcUrlChina: `${ srcPrefixChina }${ streamDocId }`
    }
    const result = await studio.db
    .collection(CollectionNames.STREAMS)
    .doc(streamDocId)
    .set(payload, MergeOption.MERGE)
    if (result.isFailure) {
      result.log('Could not set stream in database')
    return
   }
  },
  async connectStreamReference (context: UserMediaContext) {
    if (insideConnectStreams === true) {
      throw new Error('connectStreamReference cannot be called before last time is finished')
    }
    const { state, commit, dispatch, getters } = usermediaModuleActionContext(context)
    await dispatch.setOptimalWrtcServer()
    if (store.state.team.activeTeam === '') throw new Error('connectStreamReference: team is not set yet')
    const currentUserId = store.state.user.id
    if (!currentUserId) return

    // const disallowTriage = [CallTriage.Init, CallTriage.Queued]
    // const currentTriage = store.getters.user.currentTriage()
    // if (disallowTriage.includes(currentTriage)) return
    console.log('connectStreamReference', state.wrtcServer)
    insideConnectStreams = true
    if (state.streamDocId) await dispatch.cleanupCurrentStream()
    await store.dispatch.events.setCasting(true)
    const docId = getDocId(currentUserId)
    await dispatch.setStreamDocId(docId)
    await sleepUntilTrue(() => state.wrtcServer !== null, 3000)
    if (state.wrtcServer === null) {
      insideConnectStreams = false
      throw new Error('connectStreamReference error, wrtcserver is still null')
    }
    const currentCastServerWebRtcServer = store.state.events.currentCast?.wrtcServerType
    let srcPrefix = `rtsp://${ state.wrtcServer }:1935/livetcp/`
    let srcPrefixChina = `rtsp://${ state.wrtcServerChinaProxy }:1935/livetcp/`
    if (currentCastServerWebRtcServer === WRTCServerType.JANUS) {
      srcPrefix = `https://${ state.wrtcServer }/whep/endpoint/`
      srcPrefixChina = `https://${ state.wrtcServerChinaProxy }/whep/endpoint/`
    }
    try {
      const payload: StreamDoc = {
        user: currentUserId,
        active: true,
        persistent: false,
        team_id: store.state.team.activeTeam,
        team: store.state.team.activeTeam,
        leftrightsplit: false,
        variants: {},
        srcurl: `${ srcPrefix }${ docId }`,
        srcUrlChina: `${ srcPrefixChina }${ docId }`,
        type: store.getters.usermedia.publishType,
        deleted: false,
        name: currentUserId,
        noCleanup: !state.deleteOnCastCleanup,
        pubts: Math.round(Date.now() / 1000),
        audioOnly: !getters.needsCamera
      }
      const result = await studio.db
        .collection(CollectionNames.STREAMS)
        .doc(docId)
        .set(payload, MergeOption.OVERWRITE)
      if (result.isFailure) {
        result.log('Could not set stream in database')
        return
      }
      if (payload.srcurl) commit.setRemoteStreamSrcUrl(payload.srcurl)
      const sessionId = store.state.user.presence?.sessionId
      if (sessionId === undefined) throw new Error('connectStreamReference error, session is undefined')
      const currentCasterStream = {
        streamId: docId,
        userId: currentUserId,
        type: store.getters.usermedia.publishType ?? StreamType.Caster,
        sessionId
      }
      await store.dispatch.events.setCurrentCasterStream(currentCasterStream)
      store.dispatch.logs.addLog({
        message: `Stream reference made for new caster stream: ${docId}`,
        data: {
          caster_type: store.getters.usermedia.publishType,
          stream_id: docId
        },
        level: LogLevel.Info
      })
      commit.setIsConnected(true)
    } catch (error) {
      console.error('connectStreamReference', error)
      store.dispatch.logs.addLog({
        message: 'connectStreamReference',
        error: error,
        level: LogLevel.Error
      })
      commit.setIsConnected(false)
    } finally {
      await store.dispatch.user.needRepublish(false)
      insideConnectStreams = false
    }
  },
  async cleanupAll (_context: UserMediaContext) {
    await store.dispatch.usermedia.setIsSelfTestInSessionDone(false)
  },
  async cleanupCurrentStream (context: UserMediaContext) {
    const { state, commit } = usermediaModuleActionContext(context)
    const streamId = state.streamDocId
    if (!streamId) return
    const result = await studio.db
      .collection(CollectionNames.STREAMS)
      .doc(streamId)
      .set({ active: false }, MergeOption.MERGE)
    result.logIfError('Kill Local Camera Stream 1')
    commit.setStreamDocId(null)
    const currentCasterStream = store.state.events.currentCasterStream
    if (!currentCasterStream) return
    const currentCastId = store.state.events.currentCast?.id
    if (!currentCastId) {
      console.log('Skipping removal of caster stream from cast because no cast id.')
      return
    }
    try {
      await store.dispatch.events.removeCasterStreamFromCast({ streamId: currentCasterStream, castId: currentCastId })
    } catch (error) {
      console.error('Kill Local Camera Stream 2 ', error)
    }
  },
  async killLocalCameraStream (context: UserMediaContext) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setIsConnected(false)
    commit.killLocalCameraStream()
    await store.dispatch.usermedia.cleanupCurrentStream()
    await store.dispatch.events.clearCurrentCasterStream()
  },
  async setDeviceList (context: UserMediaContext, deviceList: DeviceList|null) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setDeviceList(deviceList)
    await store.dispatch.user.setDefaultDevices(deviceList)
  },
  setTalkbackStatus (context: UserMediaContext, talkback: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setTalkbackStatus(talkback)
  },
  async toggleSelfMute (context: UserMediaContext, isSelfMuted: boolean) {
    const castId = store.state.events.currentCast?.id
    const userId = store.getters.user.currentUserId
    if (!userId) {
      throw new Error('Cannot toggle self mute if you dont have a userId')
    }
    if (!castId) {
      throw new Error('Cannot toggle self mute if you dont have a castId')
    }
    const { commit, dispatch } = usermediaModuleActionContext(context)
    commit.toggleSelfMute(isSelfMuted) // Update local state for immediate caster feedback
    await dispatch.updateSelfMutedState({ castId, userId, isSelfMuted })
  },
  async updateSelfMutedState (_context: UserMediaContext, { castId, userId, isSelfMuted }: { castId: string, userId: string, isSelfMuted: boolean }) {
    try {
      const payload = { self_muted: { [userId]: isSelfMuted } }
      await fb.db.collection(CollectionNames.CASTS).doc(castId).set(payload, { merge: true })
    } catch (error) {
      console.error('updateSelfMutedState', error)
    }
  },
  isDetectingDevices (context: UserMediaContext, isCurrentlyDetecting: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.isDetectingDevices(isCurrentlyDetecting)
  },
  setStreamReady (context: UserMediaContext, isReady: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setStreamReady(isReady)
  },
  async setOptimalWrtcServer (context: UserMediaContext) {
    let server = store.state.events.currentCast?.regionalInfo?.wrtcserver ?? 'wrtc-va1.kiswe.com'
    let chinaServer = store.state.events.currentCast?.regionalInfo?.chinaProxyUrl ?? 'wrtc-va1.kiswe.com'
    let isJanus = hasFeature(Feature.JANUS) ?? false
    const chinaOverride = store.state.usermedia.forceChinaProxy
    if (store.state.events?.currentCast) {
      isJanus = store.state.events.currentCast?.wrtcServerType === WRTCServerType.JANUS
    }
    const isFixedServer = store.state.events.currentCast?.regionalInfo?.fixed ?? false
    const profile = store.state.user.profile
    console.log('OPTIMALWRTC', profile, profile?.transcodingregion)
    if (!profile) {
      console.log("NO PROFILE")
    }
    let transcodingRegion = null

    if (profile?.transcodingregion) transcodingRegion = store.state.admin.transcodingregions[profile?.transcodingregion] ?? null
    console.log("transcodingRegion", transcodingRegion, store.state.admin.transcodingregions)
    if (transcodingRegion && !isFixedServer) {
      const loadbalancer = new WRTCLoadbalancer()
      const loadbalancedServer = await loadbalancer.getServerWhenReady(transcodingRegion, 12, isJanus)
      if (loadbalancedServer !== undefined) {
        if (profile?.anonymous && !loadbalancedServer.healthy) {
          throw new Error('setOptimalWrtcServer, wowza server is unhealthy')
        }
        server = loadbalancedServer.url
        chinaServer = loadbalancedServer.chinaProxyUrl ?? server
      }
    }
    const { commit } = usermediaModuleActionContext(context)
    if (chinaOverride) chinaServer = (await getChinaServer())?.chinaProxyUrl ?? server
    console.log("OPTIMALWRTCSERVER", server)
    commit.setWrtcServer(server)
    commit.setWrtcServerChinaProxy(chinaServer)
  },
  async startCasting (context: UserMediaContext, options: { deleteOnCastCleanup: boolean}): Promise<ResultVoid<'already_connected'>> {
    const { dispatch, commit, state } = usermediaModuleActionContext(context)
    if (state.connectStatus !== ConnectStatus.Disconnected) {
      return Result.fail('already_connected', 'Can only start streaming when fully disconnected')
    }
    commit.setConnectStatus(ConnectStatus.Connecting)
    try {
      await dispatch.requestPermissions()
      commit.setDeleteOnCastCleanup(options.deleteOnCastCleanup)
      await store.dispatch.events.setAudioInit(true)
      await dispatch.setOptimalWrtcServer()
      await dispatch.startStream()
      commit.setConnectStatus(ConnectStatus.Connected)
    } catch (error) {
      console.error('StartCasting', error)
      commit.setConnectStatus(ConnectStatus.Disconnected)
    }
    return Result.ok()
  },
  async stopCasting (context: UserMediaContext) {
    const { commit, dispatch, state } = usermediaModuleActionContext(context)
    if (state.connectStatus !== ConnectStatus.Connected) {
      console.error('Can only stop casting when fully connected')
      return
    }
    try {
      commit.setConnectStatus(ConnectStatus.Disconnecting)
      await dispatch.killLocalCameraStream()
      commit.setConnectStatus(ConnectStatus.Disconnected)
    } catch {
      commit.setConnectStatus(ConnectStatus.Connected)
    }
    if (state.isSelfMuted) {
      await dispatch.toggleSelfMute(false)
    }
    await store.dispatch.talkback.clearTalkback()
  },
  setAutoConnect (context: UserMediaContext, isAutoConnect: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setAutoConnect(isAutoConnect)
  },
  async initLocalMediaStream (context: UserMediaContext, _devices: MediaDevice[] = [MediaDevice.Camera, MediaDevice.Microphone]) {
    const { commit, dispatch, state } = usermediaModuleActionContext(context)
    if (state.connectStatus !== ConnectStatus.Disconnected) {
      throw new Error('initLocalMediaStream cannot be called before last time is finished')
    }
    try {
      commit.setConnectStatus(ConnectStatus.Connecting)
      await dispatch.setOptimalWrtcServer()
      await dispatch.requestPermissions()
      await dispatch.startStream()
      commit.setConnectStatus(ConnectStatus.Connected)
    } catch (error) {
      commit.setConnectStatus(ConnectStatus.Disconnected)
      throw error
    }
  },
  async requestPermissions (context: UserMediaContext, options?: { simultaneous?: boolean }) {
    const { state, dispatch, commit } = usermediaModuleActionContext(context)
    if (state.permissions.audio === true && state.permissions.video === true) return
    try {
      let permissions: UserMediaPermissions = { ...state.permissions }
      if (options?.simultaneous) {
        permissions = await getAllMediaPermissions()
      } else {
        permissions = await getMediaPermission(MediaDevice.Camera, permissions) ?? permissions
        await dispatch.setPermissions(permissions)
        permissions = await getMediaPermission(MediaDevice.Microphone, permissions) ?? permissions
      }
      await dispatch.setPermissions(permissions)
      await dispatch.getDevices()
    } catch (error) {
      if (error instanceof DOMException) {
        if (error.name === 'NotAllowedError') {
          if (error.toString().includes('Permission denied by system')) commit.setHasDeviceError(true)
          else await dispatch.setPermissions({ audio: false, video: false })
        }
        if (error.name === 'NotFoundError') commit.setHasDeviceError(true)
      }
    }
  },
  async getDevices (context: UserMediaContext) {
    const { state, commit, dispatch } = usermediaModuleActionContext(context)
    commit.setIsDetectingDevices(true)
    const currentUserId = store.state.user.id
    if (currentUserId === null) return
    const mediaDevices = new DeviceList()
    const profile: Partial<UserProfile> = { id: currentUserId, devices: [] }
    let profileWithDefaults: Partial<UserProfile>|null = null
    // NOTE: Although we grant permission through getMediaPermission().
    // but when we stop all tracks, Firefox returns empty labels for devices because there is no active MediaStream.
    // due to Firefox's security policy to protect device information
    // so call getUserMedia() again for a temporary permission to access the devices
    // https://stackoverflow.com/a/69468263
    try {
      const tempStream = await constraintToUserMedia(state.constraints)
      const result = await navigator.mediaDevices.enumerateDevices()
      for (const device of result) {
        const { userMediaDevice, deviceOption, media, type } = setupDevice(device)
        if (!userMediaDevice || !deviceOption || !media || !type || !profile.devices) continue
        // @ts-ignore
        mediaDevices[media][type].push(deviceOption)
        profile.devices.push(userMediaDevice)
      }
      profileWithDefaults = updateDefaultDevicesIfNecessary(profile)
      await dispatch.setDeviceList(mediaDevices)
      if (tempStream === undefined) return
      tempStream.stream.getTracks().forEach((track) => track.stop())
      if (tempStream.deviceId !== undefined) await store.dispatch.user.setSelectedDevices({ selectedVideoDevice: tempStream.deviceId })
    } catch (error) {
      console.error('Could not update device list', error)
    } finally {
      setTimeout(() => commit.setIsDetectingDevices(false), 1000)
      if (profileWithDefaults) await store.dispatch.user.updateProfile(profileWithDefaults)
    }
  },
  async startStream (context: UserMediaContext) {
    const { dispatch, getters } = usermediaModuleActionContext(context)
    const profile = store.state.user.profile
    if (profile === null) console.error('startStream - profile is null')
    const selectedVideoDevice = profile?.selectedVideoDevice ?? null
    const selectedAudioDevice = profile?.selectedAudioDevice ?? null
    // this.addLog({ message: 'Starting caster stream' })
    if (!selectedVideoDevice && !selectedAudioDevice) return
    const isModerator = store.state.usermedia.isModerator
    if ((selectedVideoDevice && getters.needsCamera) || isModerator) {
      await dispatch.startCheckingResolutions()
    } else {
      await dispatch.connectAudioOnly()
    }
    await startCastingMVar.read()
  },
  async connectAudioOnly (context: UserMediaContext) {
    const { state, dispatch } = usermediaModuleActionContext(context)
    // this.addLog({ message: 'Connect audio only' })
    await dispatch.setStreamDocId(null)
    const profile = store.state.user.profile
    if (profile === null) console.error('connectAudioOnly - profile is null')
    const audioSource = profile?.selectedAudioDevice ?? null
    const constraints: MediaStreamConstraints = {
      audio: getAudioConstraints(audioSource)
    }
    try {
      const isCasting = store.state.events.casting
      if (isCasting || !!state.streamDocId) return
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      await dispatch.setLocalCameraStream(stream)
      await dispatch.connectStreamReference()
      startCastingMVar.put('done')
    } catch (error) {
      console.error('ConnectStreamReference error - connectAudioOnly', error)
      startCastingMVar.put('error')
    }
  },
  async startCheckingResolutions (context: UserMediaContext) {
    const { state, commit, dispatch } = usermediaModuleActionContext(context)
    commit.setHasDeviceError(false)
    if (state.localCameraStream) await dispatch.stopCasting()
    commit.resetSupportedResolutions()
    resolutionsToCheck = cloneDeep(availableResolutions)
    const width = store.state.user.profile?.encoderSettings?.width
    const height = store.state.user.profile?.encoderSettings?.height
    const resolution = resolutionsToCheck.find(resolution => resolution.width === width && resolution.height === height)
    if (resolution !== undefined) {
      resolutionsToCheck.unshift(resolution)
    }
    await dispatch.getSupportedResolutions()
  },
  async getSupportedResolutions (context: UserMediaContext) {
    const { state, dispatch } = usermediaModuleActionContext(context)
    if (state.checkedResolution >= resolutionsToCheck.length - 1 || state.supportedResolutions.length > 0) {
      await dispatch.onSetupComplete()
    } else {
      const profile = store.state.user.profile
      if (profile === null) console.error('getSupportedResolutions - profile is null')
      const resolution = resolutionsToCheck[state.checkedResolution]
      const videoSource = profile?.selectedVideoDevice ?? null
      const temporaryConstraints = {
        audio: true,
        video: {
          width: { exact: resolution.width },
          height: { exact: resolution.height },
          deviceId: videoSource ? { exact: videoSource } : undefined,
          frameRate: { max: 30 }
        }
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia(temporaryConstraints)
        await dispatch.onCheckResolutionSuccess(stream)
      } catch (error) {
        console.log('stream error', error)
        await dispatch.onCheckResolutionError()
      }
    }
  },
  async onCheckResolutionSuccess (context: UserMediaContext, stream: MediaStream) {
    const { state, commit, dispatch } = usermediaModuleActionContext(context)
    if (state.currentRate === 0) {
      const audioContext = new AudioContext()
      const microphone = audioContext.createMediaStreamSource(stream)
      commit.setCurrentRate(microphone.context.sampleRate)
      await audioContext.close()
    }
    const resolution = resolutionsToCheck[state.checkedResolution]
    commit.addSupportedResolution(resolution)
    stream.getTracks().forEach((track) => track.stop())
    await dispatch.getSupportedResolutions()
  },
  async onCheckResolutionError (context: UserMediaContext) {
    const { state, dispatch } = usermediaModuleActionContext(context)
    // const resolution = availableResolutions[state.checkedResolution]
    // this.addLog({
    //   message: 'error connecting to devices to cast with.',
    //   error,
    //   data: { resolution },
    //   level: LogLevel.Error
    // })
    // @ts-ignore
    state.checkedResolution += 1
    await dispatch.getSupportedResolutions()
  },
  async onSetupComplete (context: UserMediaContext) {
    const { state, dispatch, commit } = usermediaModuleActionContext(context)
    if (state.supportedResolutions.length === 0) {
      console.error('No available supported Resolutions')
      commit.setConnectStatus(ConnectStatus.Error)
      commit.setHasDeviceError(true)
      // this.error = 'Camera does not support 16/9 aspect ratio, please verify that the camera is connected and '
      // this.error += 'no other program or tab is using the camera and try again.'
      // this.addLog({
      //   message: 'Camera does not support 16/9 aspect ratio',
      //   level: LogLevel.Error
      // })
      startCastingMVar.put('error')
      return
    }
    commit.setStreamDocId(null)
    await dispatch.setupMediaStreamConstraints()
    startCastingMVar.put('done')
  },
  async setupMediaStreamConstraints (context: UserMediaContext) {
    const { state, dispatch, commit } = usermediaModuleActionContext(context)
    const profile = store.state.user.profile
    if (profile === null) console.error('setupMediaStreamConstraints - profile is null')
    const selectedAudioDevice = profile?.selectedAudioDevice ?? null
    const selectedVideoDevice = profile?.selectedVideoDevice ?? null
    if (!selectedAudioDevice || !selectedVideoDevice) return
    const constraints: MediaStreamConstraints = {
      audio: getAudioConstraints(selectedAudioDevice),
      video: getVideoConstraints(selectedVideoDevice)
    }
    commit.setConstraints(constraints)

    // this.$store.direct.dispatch.logs.addLog({
    //   message: 'Starting caster camera with constraints ' + JSON.stringify(constraints),
    //   data: { constraints },
    //   cast: this.currentCast.id, level: LogLevel.Info
    // })
    try {
      await store.dispatch.events.setAudioInit(true)
      await dispatch.setOptimalWrtcServer()
      const stream = await navigator.mediaDevices.getUserMedia(state.constraints)
      await dispatch.setLocalCameraStream(stream)
      if (state.autoConnect) await dispatch.connectStreamReference()
    } catch (error) {
      console.warn(error)
    }
  },
  async restartStream (context: UserMediaContext) {
    const { state, commit, dispatch } = usermediaModuleActionContext(context)
    if (state.isConnected) {
      console.log("stop casting")
      await dispatch.stopCasting()
      const result = await dispatch.startCasting({ deleteOnCastCleanup: true })
      result.logIfError('Could not restart stream')
    } else {
      commit.setConnectStatus(ConnectStatus.Disconnecting)
      await dispatch.killLocalCameraStream()
      commit.setConnectStatus(ConnectStatus.Disconnected)
      commit.setConnectStatus(ConnectStatus.Connecting)
      await dispatch.startStream()
      commit.setConnectStatus(ConnectStatus.Connected)
    }
  },
  updateStreamTracks (_context: UserMediaContext) {
    // Updating individual tracks without dropping stream is possible after all, see the MDN article below
    // https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpSender/replaceTrack

    // const { state, commit } = usermediaModuleActionContext(context)
    // if (state.localCameraStream === null) return
    // const audioTracks = state.localCameraStream.getAudioTracks()
    // const videoTracks = state.localCameraStream.getVideoTracks()
    // const selectedAudioDevice = store.state.user.profile?.selectedAudioDevice ?? null
    // const selectedVideoDevice = store.state.user.profile?.selectedVideoDevice ?? null
    // const constraints: MediaStreamConstraints = {
    //   audio: getAudioConstraints(selectedAudioDevice),
    //   video: getVideoConstraints(selectedVideoDevice)
    // }
    // if (typeof constraints.audio !== 'boolean') {
    //   audioTracks[0].applyConstraints(constraints.audio)
    // }
    // if (typeof constraints.video !== 'boolean') {
    //   videoTracks[0].applyConstraints(constraints.video)
    // }
    // commit.setConstraints(constraints)
  },
  setIsModerator (context: UserMediaContext, enabled: boolean) {
    const { commit } = usermediaModuleActionContext(context)
    commit.setIsModerator(enabled)
  }
}

export default actions
