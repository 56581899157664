/* eslint-disable max-lines */
import { DeepPartial, KeyDict } from '@/types'
import { Asset, AssetType, TweetAsset } from '@/types/assets'
import { Layout, LayoutBox, LayoutBoxField } from '@/types/layouts'
import { isSceneContentTransition, SCENE_CONTENT_Z_INDEX, SceneContent } from '@/types/sceneContent'
import { BoxTypesInfo, HIDDEN_GLOBAL_PRESETS, SceneContentType, SceneSourceType } from '@/modules/scenes/types'
import { HexCode } from 'kiswe-ui'
import { Loader, Result } from 'kiswe-ui'
import { getHighestContentOrder, getZIndex, isTransitionContent, moveContentUp } from '../utils/sceneContents'
import { TwitterStyling } from '@/modules/layouts/classes'
import { BoxStylingBase } from '@/modules/layouts/classes/BoxStylingBase'
import { createFullscreenBox } from '../utils/sceneBoxes'

// NOTE: sourceTypes are currently an array, even though only the first index is used
// this is because we want to expand on this in the future, to support a mix of source types

export type ScenePresetAssetGroup = { id: string, enabled: boolean }

export default class ScenePreset {
  id: string
  name: string = ''
  color: HexCode|null = null
  // FIXME: This is actually `KeyDict<Partial<SceneContent>>` (e.g. mutes) -> But quite a lot of (global preset) code
  //        currently assumes it contains full `SceneContent` objects
  contents: KeyDict<SceneContent> = {}
  scene_layout: DeepPartial<Layout> & { boxes: KeyDict<DeepPartial<LayoutBox>> }
  sourceTypes: SceneSourceType[]
  assetGroups: ScenePresetAssetGroup[] = []
  isGlobalPreset: boolean = false
  zIndex: number = 0
  private __twitterBoxId: string

  constructor (data: unknown) {
    this.id = Loader.loadString(data, 'id')
    this.name = Loader.loadString(data, 'name', '')
    this.color = Loader.loadAndCast<HexCode>(data, 'color', null)
    this.contents = Loader.loadAndCast<KeyDict<SceneContent>>(data, 'contents', {})
    this.scene_layout = Loader.loadAndCast<Layout>(data, 'scene_layout', { boxes: {} })
    this.sourceTypes = Loader.loadAndCast<SceneSourceType[]>(data, 'sourceTypes', [])
    this.assetGroups = Loader.loadAndCast<ScenePresetAssetGroup[]>(data, 'assetGroups', [])
    this.isGlobalPreset = Loader.loadBoolean(data, 'isGlobalPreset', false)
    this.__twitterBoxId = `${ this.id }_twitter`

    if (this.scene_layout.tweetcss !== undefined) {
      this.scene_layout.tweetcss = new TwitterStyling(this.scene_layout.tweetcss)
    }

    this.setupBaseZIndex()
    this.addMissingBoxes()
  }

  static load (data: unknown): Result<ScenePreset, 'invalid_param'|'unknown'> {
    try {
      return Result.success(new ScenePreset(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_param', error)
    }
  }

  get isEmpty (): boolean {
    return this.sourceTypes.length === 0
  }

  get isHiddenGlobalPreset (): boolean {
    return HIDDEN_GLOBAL_PRESETS.includes(this.id)
  }

  get hasContent (): boolean {
    return Object.keys(this.contents).length > 0
  }

  get isActive (): boolean {
    return !!Object.values(this.contents).find((content) => content.active)
  }

  set isActive (isActive: boolean) {
    Object.values(this.contents).forEach((content) => content.active = isActive)
  }

  get isVisible (): boolean {
    return !!Object.values(this.scene_layout.boxes).find((box) => box.active)
  }

  get currentActiveContent (): SceneContent|null {
    return Object.values(this.allContentsByOrder).find((content) => content.active) ?? null
  }

  get allContentsByOrder (): SceneContent[] {
    return Object.values(this.contents).sort((a, b) => (a.order > b.order) ? 1 : -1)
  }

  get layoutBoxTwitter (): DeepPartial<LayoutBox>|null {
    return this.scene_layout.boxes[this.__twitterBoxId] ?? null
  }

  set layoutBoxTwitter (newVal: DeepPartial<LayoutBox>|null) {
    if (newVal === null) throw new Error('Cannot assign null to layoutBoxTwitter')
    this.scene_layout.boxes[this.__twitterBoxId] = newVal
  }

  private get currentTransitionContent (): SceneContent|null {
    return Object.values(this.contents)
      .find((content) => isTransitionContent(content.type, content.contentType)) ?? null
  }

  clear () {
    this.color = null
    this.contents = {}
    this.scene_layout = { boxes: {} }
    this.sourceTypes = []
    this.assetGroups = []
    return this
  }

  toggleActive (isActive?: boolean) {
    this.isActive = (isActive === undefined) ? !this.isActive : isActive
    return this
  }

  setBoxesActive (isActive?: boolean): void {
    const active = isActive === undefined ? !this.isVisible : isActive
    for (const box of Object.values(this.scene_layout.boxes)) {
      box.active = active
    }
  }

  addSourceType (sourceType: SceneSourceType) {
    if (this.sourceTypes.includes(sourceType)) return this
    if (sourceType === SceneSourceType.Twitter) this.setupTwitterLayout()
    this.sourceTypes = [sourceType]
    return this
  }

  // eslint-disable-next-line complexity
  addLayoutBox (args: Partial<LayoutBox> & { id: string, contentType: SceneContentType, assetType: AssetType }) {
    const { id, name, contentType, styling, active, zindex, field, assetType } = args
    let isActive = contentType === SceneContentType.GlobalLayer ? false : true
    if (active !== undefined) isActive = active
    const zIndex = SCENE_CONTENT_Z_INDEX[contentType] + this.zIndex - (zindex ?? 0)
    const box = createFullscreenBox(id, zIndex, assetType, field ?? LayoutBoxField.FRONT)
    box.styling = styling ?? new BoxStylingBase()
    box.active = isActive
    box.name = name ?? id
    this.scene_layout.boxes[id] = box
    return this
  }

  updateLayoutBox (boxId: string, boxTypesInfo: BoxTypesInfo) {
    if (this.scene_layout.boxes[boxId] === undefined) this.scene_layout.boxes[boxId] = {}
    this.scene_layout.boxes[boxId] = { ...this.scene_layout.boxes[boxId], ...boxTypesInfo }
    return this
  }

  addContent (content: SceneContent) {
    if (isTransitionContent(content.type, content.contentType) && this.currentTransitionContent !== null) {
      this.removeContent(this.currentTransitionContent.id)
    }
    this.addSourceTypeByAssetType(content.type)
    this.contents[content.id] = content
    let layoutBoxField = LayoutBoxField.FRONT
    switch (content.type) {
      case AssetType.Video:
        layoutBoxField = LayoutBoxField.BACK
        break
      case AssetType.Caster:
        layoutBoxField = LayoutBoxField.CASTER
        break
    }
    if (isSceneContentTransition(content)) layoutBoxField = LayoutBoxField.TRANSITION
    this.addLayoutBox({ id: content.id, name: content.name, assetType: content.type,
       contentType: content.contentType, zindex: content.order, field: layoutBoxField })
    return this
  }

  removeContent (contentId: string) {
    if (this.contents[contentId]) {
      delete this.contents[contentId]
      delete this.scene_layout.boxes[contentId]
    }
  }

  addContents (contents: KeyDict<SceneContent>) {
    for (const content of Object.values(contents)) {
      this.addContent(content)
    }
    return this
  }

  addAssets (assets: Asset[]) {
    const highestOrder = getHighestContentOrder(this.contents)
    let contentType = this.isGlobalPreset ? SceneContentType.GlobalLayer : SceneContentType.Layer
    for (const [index, asset] of assets.entries()) {
      if (asset.type === AssetType.Stinger) contentType = SceneContentType.Transition
      this.addContent({
        active: this.isGlobalPreset ? !this.isActive : false,
        box: asset.id,
        id: asset.id,
        url: asset.url ?? '',
        name: asset.name ?? asset.id,
        order: highestOrder + index + 1,
        type: asset.type,
        contentType
      })
    }
    return this
  }

  addTweet (asset: TweetAsset, isActive?: boolean) {
    this.addSourceTypeByAssetType(AssetType.Tweet)
    const isFirstTweet = Object.values(this.contents).filter((content) => content.type === asset.type).length === 0
    this.contents[asset.id] = {
      active: (isActive === undefined) ? isFirstTweet : isActive,
      box: this.__twitterBoxId,
      id: asset.id,
      url: asset.url ?? '',
      name: asset.name ?? asset.data?.user.name ?? asset.id,
      order: 0,
      type: asset.type,
      contentType: this.isGlobalPreset ? SceneContentType.GlobalLayer : SceneContentType.Layer,
      timestamp: asset.data?.created_at ?? 0
    }
    this.reorderTweetsByTimestamp()
    return this
  }

  removeTweet (assetId: string) {
    if (this.contents[assetId].active) this.togglePrevContent()
    delete this.contents[assetId]
    return this
  }

  addAssetGroup (id: string) {
    if (!!this.assetGroups.find((group) => group.id === id)) return this
    this.assetGroups.push({ id, enabled: true })
    return this
  }

  removeAssetGroup (id: string) {
    const index = this.assetGroups.findIndex((group) => group.id === id)
    this.assetGroups.splice(index, 1)
    return this
  }

  toggleAssetGroup (id: string, enabled?: boolean) {
    const group = this.assetGroups.find((group) => group.id === id) ?? null
    if (group === null) return this
    group.enabled = (enabled !== undefined) ? enabled : !group.enabled
    return this
  }

  removeAndReplaceActiveContent (assetId: string) {
    this.removeContent(assetId)
    this.togglePrevContent()
    if (!this.currentActiveContent) this.toggleNextContent()
    return this
  }

  toggleNextContent () {
    const currentIndex = this.allContentsByOrder.findIndex((content) => content.id === this.currentActiveContent?.id)
    const nextContent = this.allContentsByOrder[currentIndex + 1] ?? this.allContentsByOrder[0]
    if (!nextContent) return this
    return this.setContentActive(nextContent.id)
  }

  togglePrevContent () {
    const currentIndex = this.allContentsByOrder.findIndex((content) => content.id === this.currentActiveContent?.id)
    const lastIndex = this.allContentsByOrder.length - 1
    const prevContent = this.allContentsByOrder[currentIndex - 1] ?? this.allContentsByOrder[lastIndex]
    if (!prevContent) return this
    return this.setContentActive(prevContent.id)
  }

  moveContentUp (contentId: string) {
    this.contents = moveContentUp(contentId, this.contents)
    this.recalculateLayoutBoxZIndexes()
    return this
  }

  recalculateLayoutBoxZIndexes () {
    for (const box of Object.values(this.scene_layout.boxes)) {
      if (box.id === undefined) continue
      const content = this.contents[box.id] ?? null
      if (content === null) continue
      this.scene_layout.boxes[box.id].zindex = getZIndex(content.contentType, this.zIndex, content.order)
    }
  }

  setLayerActive (contentId: string, isActive: boolean) {
    this.contents[contentId].active = isActive
    return this
  }

  setContentActive (contentId: string) {
    for (const content of Object.values(this.contents)) {
      this.contents[content.id].active = content.id === contentId
    }
    return this
  }

  private addSourceTypeByAssetType (assetType: AssetType) {
    const typeMap: Partial<Record<AssetType, SceneSourceType>> = {
      [AssetType.Graphic]: SceneSourceType.Graphic,
      [AssetType.Widget]: SceneSourceType.Websource,
      [AssetType.Tweet]: SceneSourceType.Twitter
    }
    const sourceType = typeMap[assetType] ?? null
    if (sourceType === null) return
    this.addSourceType(sourceType)
  }

  private reorderTweetsByTimestamp () {
    Object.values(this.contents)
      .filter((content) => content.type === AssetType.Tweet)
      .sort((a, b) => (a.timestamp ?? 0) > (b.timestamp ?? 0) ? -1 : 1)
      .forEach((content, index) => this.contents[content.id] = { ...content, order: index })
  }

  private setupTwitterLayout () {
    const contentType = this.isGlobalPreset ? SceneContentType.GlobalLayer : SceneContentType.Layer
    this.addLayoutBox({
      id: this.__twitterBoxId,
      name: 'Twitter',
      contentType,
      styling: new BoxStylingBase({ top: 800, left: 0, height: 200, width: 1500 }),
      assetType: AssetType.Tweet
    })
    this.scene_layout.tweetcss = new TwitterStyling()
  }

  private setupBaseZIndex () {
    if (!this.isGlobalPreset) return
    if (this.isHiddenGlobalPreset) return
    const index = Number.parseInt(this.id.replace('global', ''))
    this.zIndex = index * 100
  }

  private addMissingBoxes () {
    const boxIds = Object.keys(this.scene_layout.boxes)
    for (const content of Object.values(this.contents)) {
      if (![AssetType.Graphic, AssetType.Widget].includes(content.type)) continue
      if (boxIds.includes(content.id)) continue
      this.addLayoutBox({
        id: content.id,
        name: content.name,
        contentType: content.contentType,
        zindex: content.order,
        field: LayoutBoxField.FRONT,
        assetType: content.type
      })
    }
  }

  refreshContentReloadKey (contentId: string) {
    this.contents[contentId].reloadKey = Date.now()
    return this
  }
}
