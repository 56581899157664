import { KeyDict } from '@/types'
import { CefStatInstances } from '../classes'

export interface SrtStats {
  id?: string
}

export interface SystemNetworkStats {
  bytes_recv_per_sec: number,
  bytes_send_per_sec: number
}

export interface SystemLoad {
  cpu: number
  disk: number
  mem: number
  net: KeyDict<SystemNetworkStats>
}

export interface NgcvpSystemLoad extends SystemLoad {
  max_cpu: number
  max_disk: number
  max_mem: number
  machine: string
}

export enum CefMode {
  Front = 'front',
  Back = 'back',
  Full = 'full'
}

export interface CefMemory {
  limit: number
  total: number
  used: number
}

export interface CefStats {
  castId: string|null
  front: CefStatInstances|null
  back: CefStatInstances|null
}
