<template>
  <KisweModal
    :title="title"
    :size="KisweSize.Large"
    customClass="user-profile-form-modal"
    @submit="submitted"
    @close="close"
  >
    <template v-if="userProfile !== null">
      <UserProfileForm
        data-qa="select-user-profile-form"
        v-model="userProfile"
        :short="isAnonymous"
        :anonymous="isAnonymous"
        @disableRestartStream="disableRestartStream"
        @updateWarningMessage="updateWarningMessage"
        @updateValidatorError="updateValidatorError"
      />
    </template>
    <template v-slot:footer>
      <template v-if="isInvitedCaster">
        <p
          v-for="([key, value]) in Object.entries(warningMessage)"
          :key="key"
          class="warning-message"
          :data-qa="`text-userprofileformmodal-warning-${ key }`"
        >{{ value }}</p>
      </template>
      <KisweValidationErrors v-if="validators.profile" :errors="validators.profile.first_name.$errors" />
      <KisweValidationErrors v-if="validators.profile" :errors="validators.profile.last_name.$errors" />
      <KisweValidationErrors v-if="validators.role" :errors="validators.role.editRole.$errors" />
      <KisweValidationErrors v-if="validators.encoder" :errors="validators.encoder.audioBitrate.$errors" />
      <KisweValidationErrors v-if="validators.encoder" :errors="validators.encoder.videoBitrate.$errors" />
    </template>
    <template v-if="isInvitedCaster" v-slot:footer-button>
      <KisweButton
        theme="primary"
        class="submit-btn restart-stream"
        :size="KisweSize.Small"
        :disabled="isRestartStreamDisabled"
        @click="requestRestartStream"
        data-qa="button-restart-stream"
      >Restart Stream</KisweButton>
    </template>
  </KisweModal>
</template>

<script lang="ts">
import './UserProfileFormModal.scss'
import { computed, defineComponent, ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { KisweButton, KisweModal, KisweValidationErrors, KisweSize } from 'kiswe-ui'
import UserProfileForm, { UserProfileValidators } from '../UserProfileForm'
import { useUserProfile } from '../../compositions'
import { KeyDict } from '@/types'

export default defineComponent({
  name: 'UserProfileFormModal',
  components: {
    KisweButton,
    KisweModal,
    UserProfileForm,
    KisweValidationErrors
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Edit team member'
    },
    userId: {
      type: String,
      required: true
    }
  },
  emits: {
    close () { return true }
  },
  /* eslint-disable max-lines-per-function */
  setup (props, { emit }) {
    const userId = computed(() => props.userId)
    const { requestCasterRepublish, saveUserProfile, setNeedsRepublishing,
      ...restUserProfileCompositions } = useUserProfile(userId)
    const isRestartStreamDisabled = ref(true)
    const warningMessage = ref<KeyDict<string>>({})
    const validators = reactive({}) as UserProfileValidators

    const disableRestartStream = (disabled: boolean) => {
      setNeedsRepublishing({ user_id: userId.value, val: !disabled })
      isRestartStreamDisabled.value = disabled
      const message = disabled ? '' : 'Restart the stream to activate the changes.'
      updateWarningMessage({ key: 'restartStream', message })
    }

    const requestRestartStream = async () => {
      try {
        await requestCasterRepublish(userId.value)
      } catch (error) {
        console.error(error)
        ElMessage.error('Could not republish stream')
      }
    }

    const submitted = async () => {
      try {
        await saveUserProfile()
      } catch (error) {
        console.error(error)
        ElMessage.error('Could not update profile')
      } finally {
        close()
      }
    }

    const updateWarningMessage = (newWarning: { key: string, message: string }) => {
      if (newWarning.message === '') {
        delete warningMessage.value[newWarning.key]
      } else {
        warningMessage.value[newWarning.key] = newWarning.message
      }
    }

    const updateValidatorError = (newValidators: UserProfileValidators) => {
      validators.profile = newValidators.profile
      validators.role = newValidators.role
      validators.encoder = newValidators.encoder
    }

    const close = () => {
      emit('close')
    }

    return {
      ...restUserProfileCompositions,
      close,
      disableRestartStream,
      warningMessage,
      isRestartStreamDisabled,
      requestRestartStream,
      setNeedsRepublishing,
      submitted,
      updateWarningMessage,
      updateValidatorError,
      validators,
      KisweSize
    }
  }
})
</script>
