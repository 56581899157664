import { Loader, Result } from 'kiswe-ui'
import { Region } from '@/types/ops'
import { KeyDict } from '@/types'
import { VersionNumber } from '@/modules/classBuilder/mixins/hasVersion'
import { FaceBookPage, TeamReminderMail } from '@/types/teams'
import { TeamCustomerType } from '@/modules/teams/types'
import { Stream } from '@/types/streams'
import { TeamFeatures, TeamMember, TeamDnsOverrides, TeamStreamsAuth } from '@/modules/teams/types'
import { TeamBranding } from '.'

export default class Team {
  id: string = ''
  awsToken: string|null = null
  branding: TeamBranding|null = null
  cef_nobroadcast_final: boolean = false
  customerType?: TeamCustomerType|null = null
  default_layout: string|null = null
  default_region: Region = Region.USEast
  deleted: boolean = false
  dnsOverrides: TeamDnsOverrides|null = null
  expirationDate: number|null = null
  facebook?: KeyDict<FaceBookPage>|null = null
  features: Partial<TeamFeatures>|null = null
  k360_channel_id: number = 0
  lastEventTimestamp: number|null = null
  logo: string|null = null
  members: KeyDict<TeamMember> = {}
  mixerPresetsOverrides: KeyDict<string> = {}
  name: string = ''
  redirectUrl: string|null = null
  reminderMailsSent?: TeamReminderMail[]|null = null
  streams_auth: KeyDict<TeamStreamsAuth> = {}
  streams: Stream[] = []
  version: VersionNumber = '1.0.0'

  constructor (data?: unknown) {
    if (!data) return
    this.id = Loader.loadString(data, 'id', this.id)
    this.awsToken = Loader.loadStringOrNull(data, 'awsToken', null)
    this.branding = Loader.loadAndCast(data, 'branding', this.branding)
    this.cef_nobroadcast_final = Loader.loadBoolean(data, 'cef_nobroadcast_final', this.cef_nobroadcast_final)
    this.customerType = Loader.loadEnumOrNull(data, 'customerType', this.customerType, Object.values(TeamCustomerType))
    this.default_layout = Loader.loadStringOrNull(data, 'default_layout', null)
    this.default_region = Loader.loadString(data, 'default_region', this.default_region) as Region
    this.deleted = Loader.loadBoolean(data, 'deleted', false)
    this.dnsOverrides = Loader.loadAndCast(data, 'dnsOverrides', this.dnsOverrides)
    this.expirationDate = Loader.loadNumberOrNull(data, 'expirationDate', null)
    this.facebook = Loader.loadAndCast(data, 'facebook', {})
    this.features = Loader.loadAndCast(data, 'features', {})
    this.k360_channel_id = Loader.loadNumber(data, 'k360_channel_id', this.k360_channel_id)
    this.lastEventTimestamp = Loader.loadNumberOrNull(data, 'lastEventTimestamp', null)
    this.logo = Loader.loadStringOrNull(data, 'logo', null)
    this.members = Loader.loadAndCast(data, 'members', {})
    this.mixerPresetsOverrides = Loader.loadAndCast(data, 'mixerPresetsOverrides', {})
    this.name = Loader.loadString(data, 'name', this.name)
    this.redirectUrl = Loader.loadStringOrNull(data, 'redirectUrl', null)
    this.reminderMailsSent = Loader.loadArrayOrNull(data, 'reminderMailsSent', null) as TeamReminderMail[]
    this.streams = Loader.loadArray(data, 'streams', this.streams)
    this.streams_auth = Loader.loadAndCast(data, 'streams_auth', this.streams_auth)
    this.version = Loader.loadString(data, 'version', this.version) as VersionNumber
  }

  static load (data: Team): Result<Team, 'invalid_params' | 'unknown'> {
    try {
      return Result.success(new Team(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
