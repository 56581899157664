import { KeyDict } from '@/types'
import { CastProperty } from '@/types/admin'
import { TeamState } from '@/store/types'
import { TeamGroup } from '@/types/teams'
import { Layout } from '@/types/layouts'

// initial state
const state: TeamState = {
  activeTeam: '',
  allTeams: {},
  apiTokens: {},
  billing: {
    loaded: false,
    summary: {
      last_update : undefined,
      months : {}
    }
  },
  castProperties: {} as KeyDict<CastProperty>,
  currentTeam: {},
  customTheme: null,
  defaultStreams: [],
  defaultTeamLayout: '',
  isFeatureLoaded: false,
  invites: [],
  mixerPresetsOverrides: {},
  streams: {},
  streamsTeamId: null,
  team: null,
  teamGroups: [] as TeamGroup[],
  teamLayouts: [] as Layout[],
  teamLoaded: false,
  teamSize: 0,
  version: '',
  warningMessages: {},
  tempBranding: null
}

export default state
