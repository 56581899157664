import store from '@/store'
import { useCurrentCast } from '@/modules/casts/compositions'
import { computed } from 'vue'
import { useConfirmation } from 'kiswe-ui'
import { Result } from 'kiswe-ui'
import { useAddLog } from '@/modules/logging/compositions'

const { addLog } = useAddLog()
const { currentCast } = useCurrentCast()
const { askConfirmation } = useConfirmation()

const deviceList = computed(() => store.state.usermedia.deviceList)
const permissions = computed(() => store.state.usermedia.permissions)
const audioDevices = computed (() => {
  return (deviceList.value?.audio.input ?? []).map((device) => (
    { value: device.deviceId, label: device.value.replace(/ *\([^)]*\) */g, '') }
  ))
})
const videoDevices = computed (() => {
  return (deviceList.value?.video.input ?? []).map((device) => (
    { value: device.deviceId, label: device.value.replace(/ *\([^)]*\) */g, '') }
  ))
})

const requestStopCasting = async (question: string, title: string, confirmButtonText: string) => {
  if (currentCast.value === null) {
    return Result.fail('invalid_cast', `${title} cannot be initiated, currentCast is null`)
  }
  return await askConfirmation({
    question,
    action: async () => await store.dispatch.usermedia.stopCasting(),
    title,
    options: { confirmButtonText }
  })
}

const requestStopTalkback = async () => {
  addLog('Stop talkback modal shown', 'info', {
    cast: currentCast.value?.id
  }).catch((error) => console.error(error))
  const result = await requestStopCasting(
    'Are you sure you want to stop the talkback?',
    'Stop talkback',
    'Stop'
  )
  result.logIfError('Could not stop the talkback')
}

const requestStopStreaming = async () => {
  addLog('Stop streaming modal shown', 'info', {
    cast: currentCast.value?.id
  }).catch((error) => console.error(error))
  const result = await requestStopCasting(
    'Are you sure you want to stop streaming?',
    'Stop streaming',
    'Stop'
  )
  result.logIfError('Could not stop streaming')
}

const useUsermediaControls = () => {
  return {
    audioDevices,
    permissions,
    requestStopStreaming,
    requestStopTalkback,
    videoDevices
  }
}

export default useUsermediaControls
