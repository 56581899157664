import store from '@/store'
import { computed } from 'vue'

const useActiveScene = () => {
  const activeScene = computed(() => store.getters.events.activeScene)
  const currentClipAssetId = computed(() => store.state.videoPlayer.currentSelectedClip?.player1 ?? null)

  // FIXME -> Scene should become a class and this should be a function inside
  const isInActiveScene = (key: string) => {
    let status = false
    const tempKey = key.split('_')[0]
    if (tempKey === undefined) return false
    const contents = activeScene.value?.contents?.[tempKey]
    if (contents !== undefined && contents.box !== '' && contents.active) {
      status = true
    }
    return status
  }

  return {
    activeScene,
    currentClipAssetId,
    isInActiveScene
  }
}

export default useActiveScene
