import { ValueLoader } from 'kiswe-ui'

export enum IceTransport {
  UDP = 'udp',
  TCP = 'tcp'
}

export class IceCandidateEntry {
  foundation: string = ''
  component: string = ''
  transport: IceTransport = IceTransport.UDP
  priority: number = 0
  connectionAddress: string = ''
  connectionPort: number = 0

  constructor (data: string) {
    try {
      const parts = data.split(' ')
      this.foundation = parts[0].split(':')[1]
      this.component = parts[1]
      this.transport = parts[2] as IceTransport
      this.priority = ValueLoader.loadNumber(Number.parseInt(parts[3]))
      this.connectionAddress = parts[4]
      this.connectionPort = ValueLoader.loadNumber(Number.parseInt(parts[5]))
    } catch {
      console.error('Error parsing iceCandidateEntry')
    }
  }
}
