import {
  SourceStream, SourceStreamBroadCast, SourceStreamCaster, SourceStreamCef, SourceStreamModerator,
  SourceStreamNonCaster, SourceStreamOutput, SourceStreamPlaylist, SourceStreamRemoteTalkBack, SourceStreamReplay,
  SourceStreamScreenshare, SourceStreamStream, SourceStreamUrl, StreamType
} from '@/types/streams'

export enum InputStreamType {
  Main = 'main',
  Backup = 'backup',
  Any = 'any'
}

export class CastInputStream {
  enabled: boolean = true
  type: InputStreamType = InputStreamType.Any

  constructor (data?: Partial<CastInputStream>) {
    this.enabled = data?.enabled ?? true
    this.type = data?.type ?? InputStreamType.Any
  }
}

export const isSourceStreamCaster = (stream: SourceStream): stream is SourceStreamCaster =>
  stream.type === StreamType.Caster
export const isSourceStreamModerator = (stream: SourceStream): stream is SourceStreamModerator =>
  stream.type === StreamType.Moderator
export const isSourceStreamBroadcast = (stream: SourceStream): stream is SourceStreamBroadCast =>
  stream.type === StreamType.Broadcast
export const isSourceStreamCef = (stream: SourceStream): stream is SourceStreamCef =>
  stream.type === StreamType.Cef
export const isSourceStreamNonCaster = (stream: SourceStream): stream is SourceStreamNonCaster =>
  stream.type === StreamType.NonCaster
export const isSourceStreamOutput = (stream: SourceStream): stream is SourceStreamOutput =>
  stream.type === StreamType.Output
export const isSourceStreamPlaylist = (stream: SourceStream): stream is SourceStreamPlaylist =>
  stream.type === StreamType.Playlist
export const isSourceStreamRemoteTalkBack = (stream: SourceStream): stream is SourceStreamRemoteTalkBack =>
  stream.type === StreamType.RemoteTalkback
export const isSourceStreamReplay = (stream: SourceStream): stream is SourceStreamReplay =>
  stream.type === StreamType.Replay
export const isSourceStreamScreenshare = (stream: SourceStream): stream is SourceStreamScreenshare =>
  stream.type === StreamType.ScreenShare
export const isSourceStreamStream = (stream: SourceStream): stream is SourceStreamStream =>
  stream.type === StreamType.Stream
export const isSourceStreamUrl = (stream: SourceStream): stream is SourceStreamUrl =>
  stream.type === StreamType.Url

export const isSourceStreamUser = (stream: SourceStream): stream is SourceStreamCaster|SourceStreamModerator =>
  isSourceStreamCaster(stream) || isSourceStreamModerator(stream)
