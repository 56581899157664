import store from '@/store'
import { TalkbackTargetType } from '@/types/streams'
import { computed, ComputedRef } from 'vue'
import { useTalkback } from '.'
import { useCasterCastStatus } from '@/modules/status/compositions'
import { useCurrentCast } from '@/modules/casts/compositions'
import { Modals } from '@/modules/ui/types'

const localCameraStream = computed(() => store.state.usermedia.localCameraStream)

const useCasterTalkback = (casterId: ComputedRef<string>) => {
  const { talkbackTarget } = useTalkback()
  const { currentCast } = useCurrentCast()
  const { isUserPresent } = useCasterCastStatus(casterId, currentCast)

  const isTalkbackTarget = computed(() => {
    const target = talkbackTarget.value
    return !!target && target === casterId.value
  })

  const isTalentCasting = computed(() => {
    const sourceStreams = store.state.events.currentCast?.source_streams ?? {}
    return Object.values(sourceStreams).some((stream) => 'user_id' in stream && stream.user_id === casterId.value)
  })

  const isTalkbackAvailable = computed(() => {
    const currentUserIsCaster = !!store.getters.events.currentUserIsACaster
    const isCurrentUser = store.state.user.id === casterId.value
    return isUserPresent.value && !currentUserIsCaster && !isCurrentUser
  })

  const leaveTalkback = async () => {
    await talkToCaster(false, TalkbackTargetType.NONE)
  }

  const talkToCaster = async (active: boolean, target: string) => {
    if (!localCameraStream.value) {
      console.log('Current user should connect to audio device (stream) first')
      return
    }
    const talkbackTarget = (active) ? target || casterId.value : TalkbackTargetType.NONE
    if (active) await store.dispatch.talkback.startTalkback(talkbackTarget)
    else await store.dispatch.talkback.stopTalkback()
  }

  const toggleTalkback = async (isEnabled: boolean, target?: string|undefined) => {
    const isCurrentUserConnected = store.state.usermedia.isConnected
    if (!isCurrentUserConnected) {
      if (isEnabled) await store.dispatch.ui.openModal(Modals.USER_MEDIA_CONNECT)
      return
    }
    const talkbackTarget = target ?? casterId.value
    if (isEnabled) await talkToCaster(true, talkbackTarget)
    else await leaveTalkback()
  }

  return {
    isTalentCasting,
    isTalkbackTarget,
    isUserPresent,
    leaveTalkback,
    talkToCaster,
    isTalkbackAvailable,
    toggleTalkback
  }
}

export default useCasterTalkback
