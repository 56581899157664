import { Loader, Result } from 'kiswe-ui'
import { ClipEndAction, IngestClipStatus, IngestCommand, IngestStatus } from '../types'
import { KeyDict } from '@/types'
import { trimOrError } from '@/modules/common/utils'

// Classes for types in CastReplays collection (for Replay Ingests)
export class IngestClipStatusDoc {
  status: IngestClipStatus
  description: string

  constructor (snapData: unknown) {
    const rawStatus = Loader.loadString(snapData, 'status')
    if (!Object.values<string>(IngestClipStatus).includes(rawStatus))
      throw new Error(`IngestClipStatus: status not in ${Object.values(IngestClipStatus)}. Found: ${rawStatus}`)
    this.status = rawStatus as IngestClipStatus
    this.description = Loader.loadString(snapData, 'description')
  }
}

export class ReplayIngestStatusDoc {
  current_replay: string|null
  current_status: IngestStatus|null
  current_timestamp: number|null
  replay_status: KeyDict<IngestClipStatusDoc>

  constructor (snapData: unknown) {
    this.current_replay = Loader.loadStringOrNull(snapData, 'current_replay', null)
    const rawStatus = Loader.loadStringOrNull(snapData, 'current_status', null)
    if (rawStatus !== null && !Object.values<string>(IngestStatus).includes(rawStatus))
      throw new Error(`ReplayIngestStatus: current_status not in ${Object.values(IngestStatus)}. Found ${rawStatus}`)
    this.current_status = rawStatus as IngestStatus
    this.current_timestamp = Loader.loadNumberOrNull(snapData, 'current_timestamp', null)
    this.replay_status = Loader.loadKeyDictOrEmpty(snapData, 'replay_status', (data: unknown) =>
      new IngestClipStatusDoc(data), {})
  }
}

export class IngestClipDoc {
  id: string
  url: string
  start: number
  end: number
  next_clip_action: ClipEndAction

  constructor (snapData: unknown) {
    this.id = Loader.loadString(snapData, 'id')
    this.url = Loader.loadString(snapData, 'url')
    this.start = Loader.loadNumber(snapData, 'start')
    this.end = Loader.loadNumber(snapData, 'end')

    const rawNextClipAction = Loader.loadString(snapData, 'next_clip_action')
    if (!Object.values<string>(ClipEndAction).includes(rawNextClipAction))
      throw new Error(`IngestClip: next_clip_action not in ${Object.values(ClipEndAction)}. Found ${rawNextClipAction}`)
    this.next_clip_action = rawNextClipAction as ClipEndAction
  }
}

export class ReplayControlsDoc {
  command: IngestCommand
  command_time: number
  replay_id: string|null

  constructor (snapData: unknown) {
    const rawCommand = Loader.loadString(snapData, 'command')
    if (!Object.values<string>(IngestCommand).includes(rawCommand))
      throw new Error(`ReplayControls: command not in ${Object.values(IngestCommand)}. Found ${rawCommand}`)
    this.command = rawCommand as IngestCommand
    this.command_time = Loader.loadNumber(snapData, 'command_time')
    this.replay_id = Loader.loadStringOrNull(snapData, 'replay_id', null)
  }
}

export class ReplayIngestDoc {
  controls: ReplayControlsDoc
  playlist: IngestClipDoc[]
  status: KeyDict<ReplayIngestStatusDoc>

  constructor (snapData: unknown) {
    const rawControls = Loader.loadClass(snapData, 'controls', (data: unknown) => new ReplayControlsDoc(data))
    if (!rawControls) throw new Error('ReplayIngest: controls cannot be empty')
    this.controls = rawControls
    this.playlist = Loader.loadList(snapData, 'playlist', (data: unknown) => new IngestClipDoc(data))
    this.status = Loader.loadKeyDict(snapData, 'status', (data: unknown) => new ReplayIngestStatusDoc(data))
  }
}

export class CastReplaysDoc {
  cast_id: string
  team_id: string
  ingests: KeyDict<ReplayIngestDoc>

  constructor (snapData: unknown) {
    this.cast_id = Loader.loadString(snapData, 'cast_id')
    this.team_id = Loader.loadString(snapData, 'team_id')
    this.ingests = Loader.loadKeyDict(snapData, 'ingests', (data: unknown) => new ReplayIngestDoc(data))
  }

  static load (snapData: unknown): Result<CastReplaysDoc, 'invalid_params' | 'unknown'> {
    try {
      return Result.success(new CastReplaysDoc(snapData))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  static newDocForCast (castId: string, teamId: string): CastReplaysDoc {
    return {
      cast_id: trimOrError(castId, 'castId', 'newDocForCast'),
      team_id: trimOrError(teamId, 'teamId', 'newDocForCast'),
      ingests: {}
    }
  }
}
