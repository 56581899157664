import ThorApi from './classes/ThorApi'
import env from '@/env'
import helpers from '@/helpers'

const settings = env.thorApi.appSettings
const localAgent = helpers.getAnalyticsAgent()
settings.deviceType = localAgent.deviceType
settings.osVersion = localAgent.osVersion

const thorApi = new ThorApi(env.thorApi.baseUrl, settings, env.thorApi.token)

export default thorApi
