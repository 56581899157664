export class MVar<T> {
    promises : any []
    value: T | null

  constructor () {
    this.promises = [];
    this.value = null;
  }

  async read () : Promise<T> {
    if (this.value !== null) {
      return Promise.resolve(this.value);
    } else {
      return new Promise((resolve) => {
        this.promises.push(resolve);
      });
    }
  }

  put (value: T) {
    this.value = value;
    this.promises.forEach(resolve => resolve(value));
    this.promises = [];
  }

  empty () {
    this.value = null;
  }
}

export class Lock {
  private mvar: MVar<void>

  constructor () {
    this.mvar = new MVar<void>()
    this.mvar.put()
  }

  async acquire () {
    await this.mvar.read()
    this.mvar.empty()
  }

  release () {
    this.mvar.put()
  }
}
