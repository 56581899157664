import { Loader } from 'kiswe-ui'
import LinkPointerBase, { PointerTypes } from '../LinkPointerBase'

export default class RedirectLinkPointer extends LinkPointerBase {
  pointerType: PointerTypes.REDIRECT = PointerTypes.REDIRECT
  redirectUrl: string

  constructor (data: unknown) {
    super(data)
    this.redirectUrl = Loader.loadString(data, 'redirectUrl')
  }

  get targetUrl (): string {
    return this.redirectUrl
  }
}
