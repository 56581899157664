import { AbilityMap } from '@/abilitiesByRole'

export enum MemberRole {
  Admin = 'admin',
  GuestCaster = 'guestcaster',
  Member = 'member',
  Station = 'station'
}

export enum TeamCustomerType {
  Aws = 'aws',
  Demo = 'demo',
  Regular = 'regular',
  Trial = 'trial'
}

export interface TeamDnsOverrides {
  linkPointerChina?: string
  linkPointerInternational?: string
}

export interface TeamStreamsAuth {
  error?: string
  login?: string
  password_hash?: string
  pending?: boolean
  salt?: string
}

interface TeamFeatureConfig {
  enabled: boolean
}

type TeamFeatureKeys = 'audioMultiTrack'|'breakoutPreviewLocal'|'chatTranslations'|'dashboard'|'janus'
  |'layout'|'liveClipping'|'lowPerformance'|'manageLayouts'|'minimumDashboard'|'nbaPocGodMode'
  |'nbaPocTalkbackStream'|'newStreamEditor'|'newUserProfileForm'|'outputStreamSettings'
  |'producer'|'producerScenesPanel'|'ptsPassthrough'|'replayPanel'|'markers'
  |'sctePassthrough'|'selfServe'|'skipSelftest'|'smptePassthrough'|'switcher'|'switcherAnonymousGuests'
  |'switcherMultiTrack'|'webRtcOnly'|'liveOutput'|'uhd4k'

export type TeamFeatures = Partial<Record<TeamFeatureKeys, TeamFeatureConfig>>

export interface TeamRoleItem {
  abilities: AbilityMap
  deleted: boolean
  description: string
  id: string
  name: string
  team_id: string
}

export interface TeamMember {
  role: MemberRole|string
}
