import { getNetworkScore } from '../modules/status/classes/NetworkScoreCalculator'
import { KeyDict } from '../types'
import { isFirefox } from 'mobile-device-detect'

// Looking at https://docs.google.com/document/d/1z-D4SngG36WPiMuRvWeTMN7mWQXrf1XKZwVl3Nf1BIE/edit
// for moving stats to new implementation

enum NetworkStatsType {
  CandidatePair = 'candidate-pair',
  InboundRtp = 'inbound-rtp',
  MediaSource = 'media-source',
  OutboundRtp = 'outbound-rtp',
  RemoteInboundRtp = 'remote-inbound-rtp',
  Transport = 'transport'
}

export class VideoObjectNetworkStats {
  bytesReceived: number = 0
  bytesSent: number = 0
  upload?: number
  packetslost?: number
  minSpeed?: number    // TODO needs to be renamed, is min upload speed
  download?: number
  __lastAudioBytesSent: number = 0
  __lastAudioStatsTs: number = 0
  __lastVideoBytesSent: number = 0
  __lastVideoStatsTs: number = 0
  __lastFramesEncoded: number = 0
  video?: {
    bytesreceived: number
    packetslost: number
    packetsreceived: number
  }
  audio?: {
    bytesreceived: number
    packetslost: number
    packetsreceived: number
  }
  encoder: {
    adaptation?: 'none' | 'cpu' | 'bandwidth',
    cpu_usage?: number,
    fps_in?: number,
    fps_out?: number,
    width?: number,
    height?: number,
    video_bitrate?: number,
    audio_bitrate?: number
  } = {}

  jitter: number = 0
  latency: number = 0
  averageLatency: number = 0
  score: number = 0
  lastlatencies: number[] = []

  updateWithStats (newStats: KeyDict<any>) {
    if ((isFirefox && newStats.type === NetworkStatsType.RemoteInboundRtp) ||
        (!isFirefox && newStats.type === NetworkStatsType.CandidatePair)) {
      this.__parseNetworkScore(newStats)
      return
    }
    try {
      switch (newStats.type) {
        case NetworkStatsType.InboundRtp:
          this.__parseInboundRTP(newStats)
          break
        case NetworkStatsType.Transport:
          this.__parseTransport(newStats)
          break
        case NetworkStatsType.OutboundRtp:
          this.__parseOutboundRTP(newStats)
          break
        case NetworkStatsType.MediaSource:
          this.__parseMediaSource(newStats)
          break
      }
    } catch (error) {
      console.error('Was unable to parse incoming networkstats', newStats, error)
    }
  }

  updateWithUploadSpeed (averageSpeed: number, allInstantSpeeds: number[], latency?: number, jitter?: number) {
    this.upload = (averageSpeed / 1024 / 1024 * 8) || 0
    this.minSpeed = (Math.min.apply(null, allInstantSpeeds) / 1024 / 1024 * 8) || 0
    if (latency) {
      this.latency = latency
    }
    if (jitter) {
      this.jitter = jitter
    }
    this.packetslost = 0
    this.score = getNetworkScore(this.latency, this.jitter)
  }

  updateWithDownloadSpeed (averageSpeed: number) {
    this.download = (8 * averageSpeed / 1024 / 1024) || 0
  }

  __parseTransport (newStats: KeyDict<any>) {
    this.bytesReceived = newStats.bytesReceived || 0
    this.bytesSent = newStats.bytesSent || 0
  }

  __parseInboundRTP (newStats: KeyDict<any>) {
    const bandwidth = {
      bytesreceived: newStats.bytesReceived || 0,
      packetslost: newStats.packetsLost || 0,
      packetsreceived: newStats.packetsReceived || 0
    }
    if (newStats.mediaType === 'video') {
      this.video = bandwidth
    }
    if (newStats.mediaType === 'audio') {
      this.audio = bandwidth
    }
  }

  __parseMediaSource (newStats: KeyDict<any>) {
    if (newStats.framesPerSecond) {
      this.encoder.fps_in = newStats.framesPerSecond
    }
    if (newStats.width) {
      this.encoder.width = newStats.width
    }
    if (newStats.height) {
      this.encoder.height = newStats.height
    }
  }

  __parseOutboundRTP (newStats: KeyDict<any>) {
    const ts = (new Date()).getTime()
    if (newStats.mediaType === 'video') {
      this.encoder.adaptation = newStats.qualityLimitationReason
      // This is not the same as CPU usage, but asuming that 33ms is the maximum time it has to encode a frame
      const averageEncodeTime = (newStats.totalEncodeTime / newStats.framesEncoded * 1000) || 0
      this.encoder.cpu_usage = (averageEncodeTime / 33 * 100) || 0
      const deltaTime = ((ts - this.__lastVideoStatsTs) / 1000.0) || 0
      this.encoder.video_bitrate = ((newStats.bytesSent - this.__lastVideoBytesSent) * 8 / deltaTime) || 0

      this.__lastVideoBytesSent = newStats.bytesSent
      this.encoder.fps_out = ((newStats.framesEncoded - this.__lastFramesEncoded) / deltaTime) || 0
      this.__lastFramesEncoded = newStats.framesEncoded
      this.__lastVideoStatsTs = ts
    }
    if (newStats.mediaType === 'audio') {
      this.encoder.audio_bitrate = ((newStats.bytesSent - this.__lastAudioBytesSent) * 8 /
                                   ((ts - this.__lastAudioStatsTs) / 1000.0)) || 0
      this.__lastAudioBytesSent = newStats.bytesSent
      this.__lastAudioStatsTs = ts
    }
  }

  __parseNetworkScore (newStats: KeyDict<any>) {
    const roundTripTime = isFirefox ? newStats?.roundTripTime : newStats?.currentRoundTripTime
    if (roundTripTime) {
      this.latency = Math.floor(roundTripTime * 1000) || 0
      this.lastlatencies.push(this.latency)
      if (this.lastlatencies.length > 25) {
        this.lastlatencies.shift()
      }
      this.jitter = this.__standardDeviation(this.lastlatencies)
      this.score = getNetworkScore(this.latency, this.jitter)
    }
    this.averageLatency = this.__average(this.lastlatencies)
  }

  __average (data: number[]): number {
    const sum = data.reduce((sum, value) => sum + value, 0)
    return (sum / data.length) || 0
  }

  __standardDeviation (values: number[]): number {
    const avg = this.__average(values)
    const squareDiffs = values.map(value => {
      const diff = value - avg
      return diff * diff
    })
    const avgSquareDiff = this.__average(squareDiffs)
    return Math.sqrt(avgSquareDiff) || 0
  }
}
