import store from '@/store'
import { Ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { DownloadableClip, DownloadableClipState, REPLAY_PLAYLIST_ALL_CLIPS } from '../types'
import { KeyDict } from '@/types'
import { Clip } from '@/types/casts'
import { ReplayPlaylist } from '../classes'

let refCount: number = 0

const getDownloadableClipStatus = (clip: Clip): DownloadableClipState => {
  if (clip.url === 'running' || clip.status === 'pending' || clip.status === 'progress') {
    return DownloadableClipState.RUNNING
  }
  if (clip.status === 'success') return DownloadableClipState.SUCCESS
  return DownloadableClipState.FAILED
}

// For guarding against case when watcher in onMount is killed and cast cleaned-up before unsubscribing.
let lastSubscribedCastId: string|null = null

const subscribe = async (castId: string) => {
  lastSubscribedCastId = castId
  await store.dispatch.replayConfig.subscribeReplayConfig({ castId })
  await store.dispatch.castReplays.subscribeCastReplays({ castId })
}

const unsubscribe = async (castId: string) => {
  await store.dispatch.replayConfig.unsubscribeReplayConfig({ castId })
  await store.dispatch.castReplays.unsubscribeCastReplays({ castId })
  if (lastSubscribedCastId) {
    if (lastSubscribedCastId !== castId) {
      await store.dispatch.replayConfig.unsubscribeReplayConfig( { castId: lastSubscribedCastId })
      await store.dispatch.castReplays.unsubscribeCastReplays({ castId: lastSubscribedCastId })
    }
    lastSubscribedCastId = null
  }
}

// CastID is optional to skip subscription if not needed. Skip ONLY IF absolutely sure an active subscription exists
// from another component/composable. At least one active subscription is needed to receive updated from Firebase.
const useReplayObjects = (castId?: Ref<string|undefined>) => {

  if (castId && castId.value) {
    onMounted(() => {
      if (refCount === 0) {
        watch(castId, async (newCastId, oldCastId) => {
          if (oldCastId) await unsubscribe(oldCastId)
          if (newCastId) await subscribe(newCastId)
        }, { immediate: true })
      }
      refCount++
    })

    onUnmounted(async () => {
      refCount--
      if (refCount === 0) {
        if (castId.value) await unsubscribe(castId.value)
        if (lastSubscribedCastId) await unsubscribe(lastSubscribedCastId)
      }
      if (refCount < 0) refCount = 0
    })
  }

  const recordedSources = computed(() => store.state.replayConfig.recordedSources)
  const replayClips = computed(() => store.state.replayConfig.replayClips)
  const replayPlaylists = computed(() => store.state.replayConfig.replayPlaylists)
  const replayIngests = computed(() => store.state.castReplays.ingests)
  const downloadableClips = computed(() => {
    const clips: KeyDict<DownloadableClip> = {}
    const allClips = store.state.events.currentCast?.clips ?? {}
    for (const [id, clip] of Object.entries(allClips)) {
      if (clip.deleted || !clip.clip_id) continue

      //id is of format 'clipNNN' where NNN is the UTC time when download task was requested
      const clipTaskTime = Number.parseInt(id.split('clip')[1])
      const existingClip = clips[clip.clip_id]
      if (existingClip && clipTaskTime <= existingClip.taskTime) continue

      const downloadableClip: DownloadableClip = {
        clipId: clip.clip_id,
        taskTime: clipTaskTime,
        name: clip.name,
        start: clip.start,
        end: clip.end,
        url: clip.url !== 'running' ? clip.url : '',
        status: getDownloadableClipStatus(clip)
      }
      clips[clip.clip_id] = downloadableClip
    }
    return clips
  })

  const getPlaylistByName = (name: string): ReplayPlaylist|null => {
    return Object.values(replayPlaylists.value).find((playlist) => playlist.name === name) ?? null
  }

  const defaultPlaylist = computed(() => getPlaylistByName(REPLAY_PLAYLIST_ALL_CLIPS))
  const defaultIngest = computed(() => (defaultPlaylist.value ?
    replayIngests.value[defaultPlaylist.value.id] : null) ?? null)

  return {
    downloadableClips,
    defaultIngest,
    defaultPlaylist,
    recordedSources,
    replayClips,
    replayPlaylists,
    replayIngests,
    getPlaylistByName
  }
}

export default useReplayObjects
