import { APIToken } from '@/modules/api/utils'
import { Release } from '@/modules/ops/types/release'
import { CastProperty, MP4Create, NimbleRegion } from '@/types/admin'
import { Asset, AssetGroup } from '@/types/assets'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import { DefaultStream, StreamDoc } from '@/types/streams'
import { MemberInvite, TeamGroup } from '@/types/teams'
import { Team } from '@/modules/teams/classes'
import { UserProfile } from '@/types/users'
import { Session } from '@/modules/presence/utils/session'
import dummyCast from './casters/dummycast'
import { dummyCastEvent } from './casters/dummycastevent'
import { AdminDocNames, CollectionNames } from './collectionNames'
import { GlobalAccelerator } from '@/types/ops'
import { KeyDict } from '@/types'
import { FeatureMap } from '@/featureFlags/featureFlags'
import { TeamRole } from '@/abilitiesByRole'
import { VideoPlayer } from '@/types/videoplayer'
import { Layout } from '@/types/layouts'
import { Cast } from '@/modules/casts/classes'
import { LinkPointer } from '@/classes/LinkPointer/LinkPointer'
import { TranscodingLoader } from '@/modules/transcodingregions/classes/transcoding'
import { PlaylistStatus } from '@/types/casts'
import { NgcvpStats, UserNetworkStats } from '@/types/networkstats'
import { CastReplaysDoc, ReplayConfigDoc } from '@/modules/replay/classes'
import { BillingSummary } from '@/modules/billing/types'
import { TranscodingRegionsLoader } from '@/modules/transcodingregions/classes/transcodingRegions'
import { Olympics } from '@/modules/olympics/classes'
import { ModerationGuest } from '@/modules/moderation/classes/ModerationGuest'
import { WrtcServerStat } from '@/modules/transcodingregions/types'
import { ScteMarker } from '@/modules/markers/classes/ScteMarker'
import { CefStatInstances, TranscodingStats } from '@/modules/statistics/classes'

export const admindocs = {
  [AdminDocNames.CLOUDCAST_MANAGER_DATA]: dummyCast<any>(),
  [AdminDocNames.DEFAULT_STREAMS]: dummyCast<{ streams: DefaultStream[] }>(),
  [AdminDocNames.FEATURE_FLAGS]: dummyCast<FeatureMap>(),
  [AdminDocNames.GLOBAL_ACCELERATOR]: GlobalAccelerator.load,
  [AdminDocNames.MP4_CREATE]: dummyCast<MP4Create>(),
  [AdminDocNames.NIMBLE_REGIONS]: dummyCast<KeyDict<NimbleRegion>>(),
  [AdminDocNames.OLYMPICS]: Olympics.load,
  [AdminDocNames.RELEASE]: dummyCast<Release>(),
  [AdminDocNames.STREAMS_AUTH]: dummyCast<any>(),
  [AdminDocNames.TRANSCODING_REGIONS]: TranscodingRegionsLoader.load
} as const

export const mapper = {
  [CollectionNames.ADMIN]: dummyCast<never>(),
  [CollectionNames.APITOKENS]: APIToken.load,
  [CollectionNames.ASSETS]: dummyCast<Asset>(),
  [CollectionNames.ASSET_GROUPS]: dummyCast<AssetGroup>(),
  [CollectionNames.BOTS]: dummyCast<any>(),
  [CollectionNames.BILLING_SUMMARIES]: BillingSummary.load,
  [CollectionNames.CASTS]: Cast.load,
  [CollectionNames.CAST_REPLAYS]: CastReplaysDoc.load,
  [CollectionNames.CAST_TEMPLATES]: CastTemplate.load,
  [CollectionNames.CEF_STATS]: CefStatInstances.load,
  [CollectionNames.CHATS]: dummyCast<any>(),
  [CollectionNames.EVENTS]: dummyCastEvent,
  [CollectionNames.INVITES]: dummyCast<MemberInvite>(),
  [CollectionNames.LAYOUTS]: Layout.load,
  [CollectionNames.MARKERS]: ScteMarker.load,
  [CollectionNames.PROFILES]: UserProfile.load,
  [CollectionNames.STREAMS]: dummyCast<StreamDoc>(),
  [CollectionNames.TEAM_GROUPS]: dummyCast<TeamGroup>(),
  [CollectionNames.TEAMS]: Team.load,
  [CollectionNames.TEAMS_ROLES]: TeamRole.load,
  [CollectionNames.VIDEO_PLAYER]: dummyCast<KeyDict<VideoPlayer>>(),
  [CollectionNames.WIDGETS]: dummyCast<any>(),
  [CollectionNames.CAST_DUMPS]: dummyCast<any>(),
  [CollectionNames.CAST_PROPERTIES]: dummyCast<CastProperty>(),
  [CollectionNames.MODERATION_QUEUE]: ModerationGuest.load,
  [CollectionNames.NETWORK_STATS]: UserNetworkStats.load,
  [CollectionNames.NGCVP_STATS]: NgcvpStats.load,
  [CollectionNames.PLAYLISTCONNECTIONS]: dummyCast<any>(),
  [CollectionNames.PLAYLIST_STATUS]: dummyCast<PlaylistStatus>(),
  [CollectionNames.POINTERS]: LinkPointer.load,
  [CollectionNames.REPLAY_CONFIG]: ReplayConfigDoc.load,
  [CollectionNames.SESSION]: Session.load,
  [CollectionNames.TEAMS_FTP]: dummyCast<any>(),
  [CollectionNames.TRANSCODING]: TranscodingLoader.load,
  [CollectionNames.TRANSCODING_STATS]: TranscodingStats.load,
  [CollectionNames.USER_CONSENTS]: dummyCast<any>(),
  [CollectionNames.WOWZA_STATS]: WrtcServerStat.load
} as const
