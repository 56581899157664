<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    timeout: {
      type: Number,
      default: 1000
    }
  },
  data: () => ({
    interval: null as NodeJS.Timeout|null
  }),
  watch: {
    timeout () {
      this.stop()
      this.start()
    }
  },
  mounted () {
    this.update()
    this.start()
  },
  beforeUnmount () {
    this.stop()
  },
  methods: {
    start () {
      this.interval = setInterval(() => {
        this.update()
      }, this.timeout)
    },
    stop () {
      if (this.interval) {
        clearInterval(this.interval)
      }
    },
    update () {
      this.$emit('onInterval')
    }
  },
  render: () => null as any
})
</script>
