import { Loader } from 'kiswe-ui'
import { WrtcServerStatBase } from './WrtcServerStat'
import { WRTCServerType } from '@/types/admin'

export class WowzaStat extends WrtcServerStatBase {
    connectionCount: number = 0
    cpuIdle: number = 0
    cpuSystem: number = 0
    cpuUser: number = 0
    diskFree: number = 0
    diskUsed: number = 0
    heapFree: number = 0
    heapUsed: number = 0
    memoryFree: number = 0
    memoryUsed: number = 0
    serverType: WRTCServerType.WOWZA = WRTCServerType.WOWZA
    serverUptime: number = 0
    threadCount: number = 0

    constructor (data: unknown) {
      if (data === undefined) return
      super(data)
      this.connectionCount  = Loader.loadNumber(data, 'connectionCount', 0)
      this.cpuIdle = Loader.loadNumber(data, 'cpuIdle', 0)
      this.cpuSystem = Loader.loadNumber(data, 'cpuSystem', 0)
      this.cpuUser = Loader.loadNumber(data, 'cpuUser', 0)
      this.diskFree = Loader.loadNumber(data, 'diskFree', 0)
      this.diskUsed = Loader.loadNumber(data, 'diskUsed', 0)
      this.heapFree = Loader.loadNumber(data, 'heapFree', 0)
      this.heapUsed = Loader.loadNumber(data, 'heapUsed', 0)
      this.memoryFree = Loader.loadNumber(data, 'memoryFree', 0)
      this.memoryUsed = Loader.loadNumber(data, 'memoryUsed', 0)
      this.serverUptime = Loader.loadNumber(data, 'serverUptime', 0)
      this.threadCount = Loader.loadNumber(data, 'threadCount', 0)
    }
  }
