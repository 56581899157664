import { StringTranslation } from "../types"

const translation: StringTranslation = {
  "cast_letsgetstarted_text": "开始。",
  "cast_getstarted_button": "开始。",
  "cast_loadingcast_text": "正在加载活动",
  "cast_pleasefillinname_question": "参与会话之前，请填写您的详细信息。",
  "cast_consentterms_text": "我已阅读并接受<a href=\"{tosUrl}\" target=\"_blank\">《Athlete Moment条款和隐私政策》</a>，并特此授权奥林匹克广播服务公司（OBS）以及国际奥林匹克委员会（IOC）分许可证持有人和受让人（如授权广播公司）在奥运会及其转播平台以及奥运场馆中分发和使用我的全部或部分图像、肖像、声音和贡献。",
  "cast_consentterms_error": "必须同意条款。",
  "cast_consentage_text": "本人年满<span>{age}</span>周岁，已达到法律规定的法定年龄。",
  "cast_consentage_error": "最小{age}岁。",
  "cast_is_scheduled_text": "This production is not live and the video previews are disabled",
  "cast_enter_text": "Press to enter the production",
  "cast_enter_button": "进入",
  "cast_tryingtoconnect_text": "Trying to connect.",
  "cast_waitingforcast_title": "创造传奇时刻",
  "cast_waitingforcast_text": "等待下一位可用的主持人。这可能需要一些时间。请勿刷新页面。",
  "cast_waitingforcast_guide1_title": "请做好出镜准备",
  "cast_waitingforcast_guide2_title": "表达您的热情吧！",
  "cast_waitingforcast_guide3_title": "请注意您的仪表和举止",
  "cast_waitingforcast_guide1_text": "请位于屏幕中央，保持好姿态，您正在被万众瞩目!",
  "cast_waitingforcast_guide2_text": "热情而大声传递您的激情和喜悦吧！",
  "cast_waitingforcast_guide3_text": "避免使用品牌名称、政治声明和侮辱性言论",
  "cast_waitingforcastautoaccept_text": "您将与主持人连接",
  "cast_switch_team_text": "This cast requires you to switch to a different team",
  "cast_switch_team_button": "Switch team",
  "complete_join_text": "注册Athlete Moment",
  "general_pleasewait_text": "感谢等待。",
  "general_yourname_label": "姓名",
  "general_firstname_label": "名",
  "general_lastname_label": "姓",
  "general_email_label": "电子邮箱",
  "general_country_label": "国家",
  "general_firstname_error": "请填写名字。",
  "general_lastname_error": "请填写姓。",
  "general_requiredallfields_error": "请填写所有信息",
  "general_pleasefillinyourname_placeholder": "请填写全名。",
  "general_pleasefillinfirstname_placeholder": "请填写名字。",
  "general_pleasefillinlastname_placeholder": "请填写姓。",
  "general_pleasefillinemail_placeholder": "请填写电子邮箱地址。",
  "general_join_button": "注册",
  "general_send_button": "发送",
  "general_yes_button": "是",
  "general_no_button": "否",
  "general_ok_button": "是",
  "general_close_button": "关闭",
  "general_done_button": "完成",
  "general_register_button": "注册",
  "general_restart_button": "重试",
  "general_continue_button": "继续",
  "chat_nomessages_placeholder": "有关该广播，还没有留言内容。",
  "chat_enteryourmessage_placeholder": "请把您的留言信息填写在此处。",
  "chat_chat_label": "开始聊天",
  "chat_unreadmessage_text": "{count} 新留言信息",
  "chat_unreadmessages_text": "{count} 各种新留言信息",
  "redirect_welcometokiswe_text": "欢迎。",
  "redirect_pleasewaittoredirect_text": "请稍等，重新连接中。",
  "redirect_end_cast_text": "聊天室将在比赛开始前1小时内开放，请稍后再试.",
  "redirect_cast_reach_maximum_text": "当前参与人数已达上线，请稍等片刻。",
  "redirect_disabled_existing_caster_text": "我们遇到了问题。请关闭此浏览器选项卡或完全关闭浏览器，然后再次单击原始链接。",
  "redirect_invalidlink_title": "链接地址错误。",
  "redirect_invalidlink_text": "您使用的链接地址从此无效。",
  "redirect_castnotactive_title": "没有激活空间 可连接到本连接地址。",
  "redirect_castnotactive_paragraph": "稍后请重新进入，或者请使用其他链接地址。",
  "actiontab_assets_label": "Assets",
  "actiontab_casters_label": "Casters",
  "actiontab_inputs_label": "Inputs",
  "actiontab_outputs_label": "Output",
  "actiontab_audio_label": "Audio Mixer",
  "actiontab_settings_label": "Settings",
  "actiontab_clips_label": "Clips",
  "actiontab_commentators_label": "Tracks",
  "actiontab_crew_label": "Crew",
  "actiontab_talkback_label": "Talkback",
  "actiontab_chat_label": "TextCast",
  "actiontab_simplecg_label": "Simple CG",
  "actiontab_sctemarkers_label": "SCTE Markers",
  "actiontab_interactive_label": "互动",
  "actiontab_switcher_label": "Scenes",
  "actiontab_layout_label": "Layers",
  "actiontab_preset_label": "Preset",
  "topbar_untileventstarts_text": "直到开始进行活动为止",
  "topbar_untileventends_text": "直到活动结束为止",
  "usermedia_startcasting_button": "开始邀请。",
  "usermedia_connect_button": "连接。",
  "usermedia_detectdevices_button": "搜索机器。",
  "usermedia_detecting_text": "进行搜索中。",
  "usermedia_clickbutton_tooltip": "需要邀请时，请点击广播按钮。",
  "usermedia_echocancellation_text": "激活有关取消回声功能。",
  "usermedia_camera_label": "照相机",
  "usermedia_microphone_label": "麦克风",
  "usermedia_learnaccess_button": "了解如何进入系统",
  "usermedia_pressallow_text": "在弹出窗口中按“允许”",
  "castgrawl_mutedbycrew_label": "The crew muted you",
  "castgrawl_mutedyourself_label": "You are muted",
  "castgrawl_streamhasissues_label": "There are connection issues. Refresh the page to solve it",
  "audio_copyfromprogram_button": "Copy from Program Mix",
  "audio_applytoprogram_button": "Apply to Program Mix",
  "audio_programmix_label": "Program mix",
  "audio_monitor_label": "Monitor mix",
  "talkback_nomembers_text": "No crew members are connected to talkback",
  "talkback_setincomingmaster_button": "Set incoming master levels",
  "talkback_setincomingindividual_button": "Set incoming individual levels",
  "error_offlinenotice_text": "当前您与的链接状态为切断状态。我们正在尝试重新进行连接。如果这种状态继续持续几秒的话,请确认网络连接状态后进行刷新。",
  "cast_waitforqueue_text": "人比较多。请稍等。",
  "cast_yourpositioninqueue_text": "在列表中本人的位置：<strong class=\"primary\">{currentPositionInQueue}</strong> / {totalInQueue}",
  "cast_needpermissions_text": "请允许系统访问您的设备",
  "cast_deviceerror_text": "我们检测到您的{device}存在问题。请关闭浏览器，检查{device}连接，然后再次点击访客链接。",
  "cast_deniedpermission_text": "您无法访问系统，在允许通过设置浏览器访问之前，您将无法进入。您可以关闭浏览器并再次转到所提供的链接。",
  "cast_deniedguestlink_text": "You are already logged in. If you want to use this link, please log out first.",
  "cast_deniednotinvited_text": "您无法参加此活动。",
  "cast_everythingsetup_text": "谢谢。<span class=\"primary\">{display_name}</span>，您进入空间的准备已就绪！",
  "cast_moderatorpickyouup_text": "管理人员将允许您入场。",
  "cast_chattingwithmoderator_text": "欢迎进入聊天室",
  "cast_moderatorrejected_text": "对不起。管理人员决定不允许您入场。",
  "endsession_thankforcoming_text": "感谢您的参与。",
  "endsession_thanks_text": "感谢您的参与，该窗口将在{remainingSecs}秒钟后关闭。",
  "endsession_thankforcomingtocast_text": "感谢您参与今天的{castName}。",
  "endsession_closetab_text": "现在请关闭网络页面或者使用已有的帐号进行登录。",
  "endsession_backtologin_button": "返回到登录页面。",
  "tally_roomactive_text": "现场直播准备就绪。",
  "tally_roomlive_text": "现场直播！",
  "leavecast_popup_title": "Leave production",
  "leavecast_popup_description": "Are you sure you want to leave the production? The production will not stop, you just leave it.",
  "leavecast_popupanonymous_title": "Leave production",
  "leavecast_popupanonymous_description": "Are you sure you want to leave the production? The production will not stop, you just leave it. You won't be able to come back.",
  "browsercheck_browser_label": "浏览器",
  "browsercheck_version_label": "版本",
  "browsercheck_supportbrowser_text": "Athlete Moment只能通过Chrome、Edge、Firefox或 Safari浏览器访问。",
  "browsercheck_supportmobilebrowser_text": "Athlete Moment只能通过Chrome或 Safari浏览器访问。",
  "browsercheck_download_chrome": "下载 Chrome",
  "browsercheck_download_edge": "下载 Edge",
  "browsercheck_download_safari": "下载 Safari",
  "browsercheck_download_firefox": "下载 Firefox",
  "browsercheck_appstore_paragraph": "Kiswe Studio可以通过Apple Store购买。",
  "browsercheck_systemnotsupported_paragraph": "This device does not meet the minimal requirements for using Kiswe Studio.",
  "statusstrip_leaveevent_tooltip": "Leave production",
  "statusstrip_selectdevices_tooltip": "选择设备",
  "screenmode_pleaserotate_title": "请旋转机器",
  "screenmode_pleaserotateios_text": "Athlete Moment在横屏模式中可以体验最佳状态的视频。您现在使用的是竖屏模式。请旋转机器。",
  "screenmode_pleaserotateandroid_text": "Athlete Moment在横屏模式中可以体验最佳状态的视频。您现在使用的是竖屏模式。请旋转机器或使用下方按钮。",
  "screenmode_pleaserotate_actionbutton": "请旋转机器。",
  "athletemoments_welcome_title": "欢迎访问 Athlete Moment！",
  "athletemoments_redirect_paragraph": "即将进行连接。",
  "castwelcome_wowzaissues_error": "当天上线人数过多。请稍后重试。",
  "privatecall_nolocalstream_error": "为了设置流，需要得到您的许可。但是，已经赋予权限的话，可能网络摄像头出现了问题。",
  "privatecall_sendwarning_notification": "管理人员无法确认您的视频和音频。请先确认麦克风和照相机是否正常启动后重试。",
  "privatecall_sendwarning_label": "请重试。",
  "privatecall_sendwarning_footer": "无法听清主持人说话？点击这里。",
  "browsercheck_ioscontinueweb_paragraph": "欢迎！如果您的iOS设备寿命大于等于3年（2018年或更早），请通过Apple Store安装运动员瞬间app（Kiswe Studio app）。如果设备不属于该范畴，请选择“在网页版继续”以获取最佳体验。",
  "browsercheck_ioscontinueweb_button": "在网页版继续",
  "statusstrip_selectdevicesdisabled_tooltip": "目前不允许更换设备。",
  "cast_moderatorrequestedstreamrestart_text": "主持人需重启您的视频流。请稍等。",
  "selftest_checkcpuandmemory_message": "正在确认机器中。",
  "selftest_setup_text": "请设置您的麦克风和摄像头",
  "selftest_running_text": "检查设备连接状态",
  "selftest_fail_text": "此步骤无法正常运行",
  "selftest_restart_text": "请检查您的网络连接状态或者使用其他设备",
  "selftest_casting_text": "正在连接，请稍候",
  "selftest_noreply_text": "我们无法连接您。检查互联网连接。",
  "selftest_pleasecloseapps_question": "请关闭其他所有程序、网络窗。",
  "selftest_selectothercamera_question": "请选择其他照相机。",
  "selftest_checkyourvolume_question": "Please check if your volume is on",
  "selftest_seeotherguests_question": "Can you see the other 4 streams?",
  "selftest_checkyourheadset_question": "If you are using a headset? Can you hear yourself now?",
  "selftest_hearotherguests_question": "Can you hear this stream?",
  "selftest_performance_question": "selftest_performance_question",
  "selftest_canyouhearlocal_question": "是否能够听到自己的声音？",
  "selftest_canyouhearremote_question": "是否能够听到音频？",
  "selftest_canyouseelocal_question": "是否能够看到自己？",
  "selftest_canyouseeremote_question": "是否能够看到视频？",
  "selftest_canyouseeyourselflocal_body": "selftest_canyouseeyourselflocal_body",
  "selftest_canyouseeyourselfremote_body": "selftest_canyouseeyourselfremote_body",
  "selftest_onsuccess_button": "selftest_onsuccess_button",
  "selftest_onfail_button": "selftest_onfail_button",
  "selftest_successresult_message": "selftest_successresult_message",
  "selftest_successresult_body": "selftest_successresult_body",
  "selftest_failresult_message": "selftest_failresult_message",
  "selftest_failresult_body": "selftest_failresult_body",
  "selfteststats_canseeselflocal_term": "selfteststats_canseeselflocal_term",
  "selfteststats_canseeselfremote_term": "selfteststats_canseeselfremote_term",
  "selfteststats_canhearselfremote_term": "selfteststats_canhearselfremote_term",
  "selfteststats_canseeothers_term": "selfteststats_canseeothers_term",
  "selfteststats_canhearothers_term": "selfteststats_canhearothers_term",

  "cast_consentprivacy_text": "본인은 참여가 개인 정보의 의무적인 수집과 사용을 요구한다는 것, 또한 특정 <a href=\"https://www.obs.tv/prx/asset.php?tgt=DelegatesSKAMOonboardingENSK-208d50ae628f.pdf&gen=1\" target=\"_blank\">해외 법인들</a>과 함께 그러한 처리를 위임할 수 있음을 설명한 <a href=\"https://www.obs.tv/prx/asset.php?tgt=B2022ATHLETESMOMENTTERMSANDCONDITIONSEN-SK-e74519b05996.pdf&gen=1\" target=\"_blank\">개인정보 보호정책</a>을 읽고 승인했습니다.",
  "cast_consentprivacy_error": "",  // cast_consentprivacy_error is only available for Korean

  "casttypeselector_switcher_name": "",
  "casttypeselector_switcher_description": "",
  "casttypeselector_switcher_short_description": "",
  "casttypeselector_fanroom_name": "",
  "casttypeselector_fanroom_description": "",
  "casttypeselector_fanroom_short_description": "",
  "casttypeselector_stationswitcher_name": "Station",
  "casttypeselector_stationswitcher_description": "Broadcast your Rooms",
  "casttypeselector_stationswitcher_short_description": "Station",
  "srt_type_passthrough_label": "Passthrough",
  "srt_type_multitrack_label": "Multi-Track",
}

export default translation
