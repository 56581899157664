import { DeepPartial } from '@/types'
import { DatabaseAction, WithFieldActions } from './database'
import { isEqual } from 'lodash'

const getArrayDiff = (oldValue: unknown[], newValue: unknown[]): WithFieldActions<unknown>|null => {
  if (isEqual(oldValue, newValue)) return null
  if (oldValue.length + 1 === newValue.length && oldValue.every((v, i) => v === newValue[i])) {
    return DatabaseAction.addToArray(newValue[newValue.length - 1])
  }
  return newValue
}

const hasOwnProperties = (obj: object|null): boolean => {
  return !!obj && Object.keys(obj).length > 0
}

// eslint-disable-next-line max-lines-per-function
export const objectDbDiff = <T extends object> (oldObject: T, newObject: T): WithFieldActions<DeepPartial<T>> => {
  // @ts-ignore
  const diff: WithFieldActions<DeepPartial<T>> = {}
  // eslint-disable-next-line complexity, max-lines-per-function
  Object.entries(newObject).forEach(([key, value]) => {
    if (oldObject === null && value !== null) {
      // @ts-ignore
      diff[key] = value
    } else if (typeof value === 'object' && value !== null && typeof oldObject === 'object'
        // @ts-ignore
        && oldObject !== null && oldObject[key] !== undefined) {
      if (Array.isArray(value)) {
        // @ts-ignore
        const arrayDiff = getArrayDiff(oldObject[key], value)
        if (arrayDiff !== null) {
          // @ts-ignore
          diff[key] = arrayDiff
        }
        // @ts-ignore
      } else if (!hasOwnProperties(value) && !hasOwnProperties(oldObject[key]) && !isEqual(oldObject[key], value)) {
        // @ts-ignore
        diff[key] = value
      } else {
        // @ts-ignore
        const deepDiff = objectDbDiff(oldObject[key], value)
        if (Object.keys(deepDiff).length > 0) {
          // @ts-ignore
          diff[key] = deepDiff
        }
      }
    }
    // @ts-ignore
    else if (value !== oldObject[key]) {
      // @ts-ignore
      diff[key] = value
    }
  })
  if (oldObject !== null) {
    Object.keys(oldObject).forEach((key) => {
      if (!(key in newObject)) {
        // @ts-ignore
        diff[key] = DatabaseAction.deleteField()
      }
    })
  }
  return diff
}

