import CastFilter from '@/classes/CastFilter'
import { CastsState } from '@/store/types'
import { CastStatus } from '@/types/casts'

const state: CastsState = {
  allActiveFanRooms: {},
  currentCast: null,
  filteredCasts: {},
  filters: {
    active: new CastFilter('active', {
      status: [CastStatus.InProgress, CastStatus.Booting], lastDoc: null, entriesPerPage: null, id: 'active'
    }),
    default: new CastFilter('default')
  },
  processSourceStreams: true,
  subscribedCasts: {}
}

export default state
