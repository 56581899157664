import { isObjectWithKey, KeyDict } from '@/types'
import { CastOutputStream, CastType } from '@/types/casts'
import { Region } from '@/types/ops'
import { SourceStream, CustomRtmpDestination } from '@/types/streams'
import { Widget } from '@/types/widgets'
import { WATERMARK_DEFAULT } from '@/types/casts'
import { CastInputStream } from '@/modules/streams/types'
import { CastTemplateParameter } from '@/modules/templates/utils/castTemplateParameter'
import { CastTemplateParameterBase } from '@/modules/templates/utils/castTemplateParameterBase'
import { Loader } from 'kiswe-ui'
import { createClass } from '@/modules/classBuilder/classBuilder'

class CastTemplateBaseLeftOvers {
  /** @deprecated Will soon be deleted */
  id: string
  asset_groups: KeyDict<boolean>
  castType: CastType
  srt: boolean
  watermark: boolean
  created_by: string|null
  created_date: Date|null
  transcodingregion: Region
  caster_delay: number
  custom_rtmp_destinations: KeyDict<CustomRtmpDestination>
  input_streams: KeyDict<CastInputStream>
  output_streams: KeyDict<CastOutputStream>
  source_streams: KeyDict<Partial<SourceStream>>
  widgets: KeyDict<Widget>

  parameters: KeyDict<CastTemplateParameter>

  // eslint-disable-next-line max-lines-per-function
  constructor (data: unknown) {
    this.id = Loader.loadString(data, 'id', '')
    this.asset_groups = Loader.loadAndCast<KeyDict<boolean>>(data, 'asset_groups', {})
    this.castType = Loader.loadString(data, 'castType', CastType.Switcher) as CastType
    this.srt = Loader.loadBoolean(data, 'srt', false)
    this.watermark = Loader.loadBoolean(data, 'watermark', WATERMARK_DEFAULT)
    this.created_by = Loader.loadStringOrNull(data, 'created_by', null)
    this.created_date = Loader.loadDateOrNull(data, 'created_date', null)
    this.transcodingregion = Loader.loadString(data, 'transcodingregion', Region.USEast) as Region
    this.caster_delay = Loader.loadNumber(data, 'caster_delay', 0)
    this.custom_rtmp_destinations = Loader.loadAndCast<KeyDict<CustomRtmpDestination>>(data,
                                                                                       'custom_rtmp_destinations', {})
    this.input_streams = Loader.loadAndCast<KeyDict<CastInputStream>>(data, 'input_streams', {})
    this.output_streams = Loader.loadAndCast<KeyDict<CastOutputStream>>(data, 'output_streams', {})
    this.source_streams = Loader.loadAndCast<KeyDict<Partial<SourceStream>>>(data, 'source_streams', {})
    this.widgets = Loader.loadAndCast<KeyDict<Widget>>(data, 'widgets', {})

    this.parameters = {}
    if (isObjectWithKey(data, 'parameters') && typeof data.parameters === 'object' && data.parameters !== null) {
      Object.entries(data.parameters).forEach(([key, parameter]) => {
        const result = CastTemplateParameter.load(parameter)
        if (result.isSuccess) this.parameters[key] = result.value
        else result.log('Loading CastTemplateParameter failed:')
      })
    }
  }

  async applyParameters (templateParamsData?: KeyDict<unknown>) {
    if (!templateParamsData || Object.keys(this.parameters).length === 0) return
    for (const [parameterId, parameter] of Object.entries(this.parameters)) {
      if (!(parameter instanceof CastTemplateParameterBase)) {
        console.warn(`Parameter ${parameterId} is not a CastTemplateParameterBase derived object`)
        continue
      }
      const paramData = templateParamsData[parameterId]
      if (!isObjectWithKey(paramData, 'value')) continue // No data.value provided for this parameter
      if (paramData.value === null) continue // TODO: Should we support "clearing" a parameter?
      // @ts-ignore -> dont know yet to type check this properly
      const result = await parameter.applyUpdate(this, paramData.value)
      if (!result.isSuccess) result.log(`Failed to apply parameter data '${parameterId}':`)
      else this.parameters[parameterId].value = paramData.value
    }
  }
}

export const CastTemplateBase = createClass(CastTemplateBaseLeftOvers)
  .addName()
  .addTeam({ required: true })
  .addRegion()
  .addQuality()
  .addPresets()
  .addVersion()
  .addScenes()
  .isDeletable()
  .addOverrides()
  .get()
