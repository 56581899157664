import fb from '../../firebase'
import * as Sentry from '@sentry/browser'
import { UserState, RootState } from '../types'
import { ActionContext } from 'vuex'
import { BatteryStatus, UserConsent, UserProfile } from '@/types/users'
import { Team } from '@/modules/teams/classes'
import store, { moduleActionContext } from '@/store'
import { UserNetworkStats } from '@/types/networkstats'
import { DeepPartial, KeyDict, Optional } from '@/types'
import { CallTriage, InvitedCaster } from '@/types/casts'
import firebase from 'firebase/compat/app'
import FirebasePresence from '@/classes/FirebasePresence'
import { cloneDeep, pickBy } from 'lodash'
import { DeviceList } from '@/types/usermedia'
import axios from 'axios'
import { sleepUntilTrue } from '@/testing/sleep'
import { TranscodingRegion } from '@/types/admin'
import { setCurrentLanguage } from '@/plugins/translations/translation'
import { defineModule } from 'direct-vuex'
import { StreamType } from '@/types/streams'
import studio, { CollectionNames } from '@/modules/database/utils'
import { generateRandomString } from '@/modules/common/utils'
import { Cast } from '@/modules/casts/classes'
import { MergeOption, WithFieldActions } from '@/modules/database/utils/database'
import { Result, ResultVoid } from 'kiswe-ui'
import { UpdateFunction } from '@/modules/base/types'
import { objectDbDiff } from '@/modules/database/utils/objectDbDiff'
import { AbilityRole } from '@/abilitiesByRole'
import Convertable from '@/classes/Convertable'

type UserContext = ActionContext<UserState, RootState>

let connectedRef: firebase.database.Reference | undefined = undefined

interface AssignParams {
  anonymousProfile: UserProfile,
  existingProfile: UserProfile,
  castId: string,
  updates: {
    streams: boolean,
    profiles: boolean,
    sessions: boolean
  }
}

const defaultUserState: UserState = {
  defaultPageOnLogin: null,
  deviceId: null,
  email: '',
  firebaseLogin: false,
  forceScreenModeEnabled: true,
  id: null,
  isDumping: false,
  legalAgreements: {
    privacyVersion: 0,
    lastUpdateDialog: null,
    tosVersion: 0
  },
  loginComplete: false,
  needRepublish: false,
  online: true,
  presence: null,
  profile: null,
  sessionId: generateRandomString(12),
  showProfile: false,
  teamCheck: null,
  teamId: null,
  teamRole: '',
  teams: {},
  userHasNoTeams: false,
  userLoggedIn: false
}

const userModule = defineModule({
  namespaced: true,
  // We use `cloneDeep()` because `defaultUserState` is also used to reset the state in `userLogout()`. And otherwise
  // `defaultUserState` would get "polluted" with the extra getters and setters Vue adds to `state` (which would in that
  // case then be the same object).
  state: cloneDeep(defaultUserState) as UserState,
  getters: {
    userLoggedIn: (state: UserState) => state.userLoggedIn,
    currentUserId: (state: UserState) => state.id,
    userEmail: (state: UserState) => state.email,
    teamId: (state: UserState) => state.teamId,
    userProfile: (state: UserState) => state.profile,
    hasEchoSuppression: (state: UserState) => state.profile?.echoSuppression === true,
    // TODO: currently if if we enable echo suppresion, we also enable noise suppression, adjust line below if we want
    // to separate them.
    hasNoiseSuppression: (state: UserState) => state.profile?.echoSuppression === true,
    currentUserIsInTeam: (state: UserState) => (castTeam?: KeyDict<UserProfile>): boolean => {
      if (state.id === null) return false
      const team = castTeam ?? store.state.team.currentTeam
      return !!team[state.id]
    },
    currentTriage: (state: UserState) => (invitedCasters?: KeyDict<InvitedCaster>): CallTriage => {
      const defaultTriage = CallTriage.Init
      const userId = state.id
      const casters = invitedCasters ?? store.state.events.currentCast?.invited_casters
      if (!userId || !casters) return defaultTriage
      const invitedCaster = casters[userId]
      return invitedCaster?.triage ?? defaultTriage
    },
    fullName: (state: UserState) => (user?: UserProfile|null): string[]|null => {
      // Using an array of strings so we can easily get the initials of first and last name. Without an array we
      // have no way of knowing where the firstname ends and lastname begins (in case of firstnames with multiple words).
      const profile = user ?? state.profile
      if (!profile) return null
      return [profile.first_name, profile.last_name]
    },
    initials: () => (profile: UserProfile): string => {
      const fullName = store.getters.user.fullName(profile)
      if (!fullName) return ''
      let initials = fullName.map((name) => name.charAt(0)).join('').toUpperCase()
      if (initials.length < 2) initials += fullName[0].charAt(1).toLowerCase()
      return initials.substr(0, 2)
    },
    useUdp: (state: UserState): boolean => {
      //If UDP not enabled, force 'enable' it while user is using Firefox as a browser
      const isCefUser = store.state.events.cefEvent
      const janusEnabled = store.state.events.currentCast?.isUsingJanus ?? false
      if (isCefUser && janusEnabled) return true
      if (state.profile?.udpEnabled) return true
      if (window.navigator.userAgent.includes('Firefox')) {
        //Force UDP as workaround for Firefox handling webRTC video streams
        console.log('User browser is Firefox, temporarily force-setting user to UDP connection.')
        return true
      }
      return false
    },
    myRegion: (state: UserState): Optional<TranscodingRegion> => {
      const region = state.profile?.transcodingregion
      if (region === undefined) return undefined
      return store.state.admin.transcodingregions[region]
    }
  },
  actions: {
    setIsDumping (context: UserContext, dumping: boolean) {
      const { commit } = userModuleActionContext(context)
      commit.setIsDumping(dumping)
    },
    updateDefaultPageOnLogin (context: UserContext, page: string|null) {
      const { commit } = userModuleActionContext(context)
      commit.updateDefaultPageOnLogin(page)
    },
    async signInUser (context: UserContext, user: firebase.User) {
      const { commit } = userModuleActionContext(context)
      commit.userLoggedIn(user)
    },
    async getClosestTranscodingRegion (_context: UserContext): Promise<Optional<string>> {
      const response = await axios.get('https://locationfinder.kiswe.com/region.txt')
      if (response.status !== 200) {
        console.error('Could not find the closest transcoding region')
        return undefined
      }
      let region: string = response.data
      // Ensure that the letter is cut off the region if the format is an availability zone within the region.
      if (region[region.length - 1] >= 'a' && region[region.length - 1] <= 'z') {
        region = region.substring(0, region.length - 1)
      }
      // There is a chance that the transcodingregions are not setup yet
      await sleepUntilTrue(() => Object.keys(store.state.admin.transcodingregions).length > 0, 10000)
      const transRegion = pickBy(store.state.admin.transcodingregions, tregion => {
        return Object.keys(tregion.close_regions).includes(region)
      })
      return Object.keys(transRegion)[0]
    },
    async setClosestTranscodingRegion (context: UserContext) {
      const { state, dispatch } = userModuleActionContext(context)
      const firstKey = await dispatch.getClosestTranscodingRegion()
      if (firstKey !== undefined && state.id !== null) {
        const result = await studio.db.collection(CollectionNames.PROFILES).doc(state.id).set({
          transcodingregion: firstKey
        }, MergeOption.MERGE)
        result.logIfError('Failed to update transcodingregion')
      }
    },
    subscribeOnlineStatus (context: UserContext) {
      const { commit, dispatch } = userModuleActionContext(context)
      if (connectedRef) {
        dispatch.unsubscribeOnlineStatus()
      }
      // FIXME needs to move to the document snapper
      connectedRef = fb.firebase.database().ref(".info/connected")
      if (connectedRef) {
        connectedRef.on("value", snap => {
          const value = snap === null ? false : snap.val() ?? false
          commit.setOnline(value)
        })
      }
    },
    unsubscribeOnlineStatus (_context: UserContext) {
      if (connectedRef !== undefined) {
        connectedRef.off("value")
        connectedRef = undefined
      }
    },
    async setUserProfile (context: UserContext, profile: UserProfile) {
      const { commit, dispatch, state } = userModuleActionContext(context)
      commit.setUserProfile(profile)
      const isCefUser = store.state.events.cefEvent
      if (profile.teams && profile.currentTeam && !isCefUser) {
        // this loads in the teams the user is apart of that are not deleted
        await dispatch.loadUsersTeams(profile.teams)
        // Check that the currentTeam corresponds to a valid, non-deleted team.
        const currentTeamInProfileTeams = state.teams[profile.currentTeam]
        if (profile.currentTeam && (currentTeamInProfileTeams || profile.isSuperUser)) {
          await dispatch.setUserTeam(profile.currentTeam)
          await store.dispatch.team.setActiveTeam(profile.currentTeam)
        } else {
          const teamMember = Object.values(state.teams)[0]
          if (teamMember !== undefined) {
            commit.toggleUserHasNoTeams(false)
            // Note: Updating the user profile with the current team will cause the snapshot listener that is set up in
            // `AuthManager::getUserProfile()` to be triggered again. Which in turn will also cause this method to be
            // called again, but then with a `currentTeam`. And thus the `setActiveTeam()` in the code path above will
            // be taken.
            await dispatch.updateProfile({ currentTeam: teamMember.id })
            return
          } else {
            if (profile.role === undefined) {
              commit.toggleUserHasNoTeams(true)
            }
          }
        }
      }
    },
    async loadUsersTeams (context: UserContext, teams: KeyDict<boolean>) {
      const { commit } = userModuleActionContext(context)
      const activeTeams: KeyDict<Team> = {}
      const promises = []
      const addToActiveTeams = async (key: string) => {
        const team = await studio.db.collection(CollectionNames.TEAMS).doc(key).get()
        if (team.isFailure || team.value.deleted) return
        team.value.id = key
        activeTeams[key] = team.value
      }
      for (const key in teams) {
        promises.push(addToActiveTeams(key))
      }
      await Promise.all(promises)
      commit.setUsersActiveTeams(activeTeams)
    },
    async needRepublish (context: UserContext, state: boolean) {
      const { commit } = userModuleActionContext(context)
      commit.setNeedRepublish(state)
    },
    setUserTeam (context: UserContext, teamId: string|null) {
      const { commit } = userModuleActionContext(context)
      commit.setUserTeam(teamId)
    },
    setUserRoleOnTeam (context: UserContext, role: string) {
      const { commit } = userModuleActionContext(context)
      commit.setUserRoleOnTeam(role === 'guest' ? AbilityRole.GuestCaster : role)
    },
    async updateOtherProfile (_context: UserContext, profile: Partial<UserProfile>) {
      try {
        if (profile.id !== undefined) {
          const docRef = fb.db.collection('profiles').doc(profile.id)
          await docRef.set(Convertable.toObject(profile), { merge: true })
        } else {
          throw new Error('No Id in Profile')
        }
      } catch (error) {
        console.error(`updateOtherProfile : ${error}`)
      }
    },
    async updateProfile (context: UserContext, profile: Partial<UserProfile>) {
      const { state, getters } = userModuleActionContext(context)
      if (!getters.userLoggedIn) return
      try {
        if (state.id === null) {
          throw new Error('My id is an empty string, so cannot update profile')
        }
        //Do we want to prevent updating chrome_version entirely if last browser used is safari, or overwrite it to be blank?
        // @ts-ignore
        // if (profile.agent && !profile.agent.chrome_version) profile.agent.chrome_version = {} //Support Safari which returns undefined for this

        // we delete the role so we don't overwrite the given user-level role: member or superuser
        const profileUpdate = cloneDeep(profile) // Make sure not to delete the role from the provided profile object.
        delete profileUpdate.role
        const result = await studio.db
          .collection(CollectionNames.PROFILES)
          .doc(state.id).set(profileUpdate, MergeOption.MERGE)
        result.logIfError('updateProfile')
      } catch (error) {
        console.error(`updateProfile : ${error}`)
      }
    },
    updateStats (context: UserContext, stats: Partial<UserNetworkStats>) {
      const { state } = userModuleActionContext(context)
      if (state.teamId !== undefined && state.id !== null) {
        const convertedStats = Convertable.toObject(stats)
        fb.db.collection(CollectionNames.NETWORK_STATS)
          .doc(state.id)
          .set({ ...convertedStats, team_id: state.teamId }, { merge: true })
          .catch(error => {
          console.error('Update User Stats error ', error)
        })
      }
    },
    updateScreenshareStats (context: UserContext, stats: Partial<UserNetworkStats>) {
      const { state } = userModuleActionContext(context)
      if (state.teamId !== undefined && state.id !== '') {
        const convertedStats = Convertable.toObject(stats)
        fb.db.collection('networkstats')
          .doc(state.id + '_screenshare')
          .set({ ...convertedStats, team_id: state.teamId,  }, { merge: true })
          .catch(error => {
          console.error('Update User Stats error ', error)
        })
      }
    },
    updateLegalAgreements (context: UserContext, onlyDialogTime: boolean) {
      const { state } = userModuleActionContext(context)
      if (state.id === null) {
        console.error('Cannot update LegalAgreements, userid is null')
        return
      }
      if (onlyDialogTime) { //Only update dialog time. For when prompt was dismissed
        fb.db.collection(CollectionNames.PROFILES).doc(state.id).update({
          "legalAgreements.lastUpdateDialog": (new Date()).getTime() / 1000 //Set to now, in unix seconds
        })
        return
      }
      const tosVersion = store.state.admin.legalinfo.tosLastPublished.seconds
      const privacyVersion = store.state.admin.legalinfo.privacyLastPublished.seconds
      fb.db.collection(CollectionNames.PROFILES).doc(state.id).update({
        legalAgreements: {
          lastUpdateDialog: (new Date()).getTime() / 1000,
          tosVersion,
          privacyVersion
        }
      })
    },
    updateCasterDefaultVolume (_context: UserContext, { userId, newVolume } : {userId: string, newVolume: number}): void {
      // const { state } = userModuleActionContext(context)
      if (userId) fb.db.collection('profiles').doc(userId).set({ 'caster_volume': newVolume }, { merge: true })

    },
    completeLogin (context: UserContext) {
      const { commit } = userModuleActionContext(context)
      commit.completeLogin()
    },
    toggleProfileModal (context: UserContext, showProfile: boolean) {
      const { commit } = userModuleActionContext(context)
      commit.toggleProfileModal(showProfile)
    },
    cleanupAll (_context: UserContext) {
      // FIXME needs to be refactored -> all these subscriptions should go in compositions and the components use
      // it when needed
      store.dispatch.team.unsubscribeActiveTeam()
      store.dispatch.team.unsubscribeTeamStreams()
      store.dispatch.team.unsubscribeCurrentTeams()
      store.dispatch.events.unsubscribeUpdateOutputStreams()
      store.dispatch.events.unsubscribeUpdateSourceStreamDocs()
    },
    async logoutUser (context: UserContext) {
      const { commit, dispatch } = userModuleActionContext(context)
      await dispatch.cleanupAll()
      await fb.firebase.auth().signOut()
      commit.userLogout()
    },
    async resetUser (context: UserContext) {
      const { commit } = userModuleActionContext(context)
      commit.userLogout()
    },
    async setPresence (context: UserContext, presence: FirebasePresence | null) {
      const { commit, state } = userModuleActionContext(context)
      if (state.presence !== null && presence === null) {
        await state.presence.closeCast()
      }
      commit.setPresence(presence)
    },
    addCastToPresence (context: UserContext, castId: string) {
      const { commit } = userModuleActionContext(context)
      commit.addCastToPresence(castId)
    },
    removeCastFromPresence (context: UserContext, castId: string) {
      const { commit } = userModuleActionContext(context)
      commit.removeCastFromPresence(castId)
    },
    async setSelectedDevices (context: UserContext, params: { selectedAudioDevice?: string, selectedVideoDevice?: string }) {
      const { state } = userModuleActionContext(context)
      if (state.id === null) throw new Error('setSelectedDevices: state.id is null')
      const result = await studio.db.collection(CollectionNames.PROFILES).doc(state.id).set(params, MergeOption.MERGE)
      result.logIfError('Failed to set selected devices on profile')
    },
    async assignAnonymousCaster (_context: UserContext, args: AssignParams) {
      try {
        if (args.updates.profiles) {
          const profileUpdate: Partial<UserProfile> = {
            anonymousId: args.anonymousProfile.id,
            agent: args.anonymousProfile.agent,
            devices: args.anonymousProfile.devices,
            selectedAudioDevice: args.anonymousProfile.selectedAudioDevice,
            selectedVideoDevice: args.anonymousProfile.selectedVideoDevice
          }

          // iOS does not pass 'devices', 'selectedAudioDevice' and 'selectedVideoDevice' and the firestore update fails
          // when there's an undefined value, but not when the key is not given, so we delete the entry
          for (const [key, value] of Object.entries(profileUpdate)) {
            // @ts-ignore
            if (value === undefined) delete profileUpdate[key]
          }

          // update existing user so we can track the last session as anonymous
          await fb.db.collection(CollectionNames.PROFILES).doc(args.existingProfile.id).set(profileUpdate, { merge: true })
          // update anonymous user to trigger a refresh of the UserProfile on their session
          await fb.db.collection(CollectionNames.PROFILES).doc(args.anonymousProfile.id).set({ assignedId: args.existingProfile.id }, { merge: true })
        }

        const cast = store.state.events.currentCast
        if (cast === null) throw new Error('Cannot assign caster if current cast is null')
        const anonymousSessionIds = cast.online_sessions?.[args.anonymousProfile.id] ?? []
        const anonymousSessionId = anonymousSessionIds[anonymousSessionIds.length - 1]
        let castUpdates: DeepPartial<Cast> = {}
        if (args.updates.streams) {
          Object.entries(cast.source_streams).forEach(([streamId, stream]) => {
            if (stream.type === StreamType.Caster && stream.user_id === args.anonymousProfile.id) {
              castUpdates = {
                source_streams: {
                  [streamId]: {
                    user_id: args.existingProfile.id,
                    session_id: anonymousSessionId
                  }
                }
              }
            }
          })
        }

        if (args.updates.sessions) {
          const existingSessionIds = cast.online_sessions?.[args.existingProfile.id] ?? []
          castUpdates.online_sessions = {
            [args.existingProfile.id]: existingSessionIds.concat(anonymousSessionIds)
          }
        }

        const feedname = cast.feedname ?? {}
        Object.keys(feedname).forEach((feedId) => {
          if (feedname[feedId] === args.anonymousProfile.id) feedname[feedId] = args.existingProfile.id
        })
        castUpdates.feedname = feedname
        await fb.db.collection('casts').doc(args.castId).set(castUpdates, { merge: true })
      } catch (error) {
        console.error(error)
      }
    },
    async assignTeamGroupToUsers (context: UserContext, { userIds, groupId } : { userIds: string[], groupId: string }) {
      if (groupId === '') {
        console.error("assignTeamGroupToUsers: groupId === ''")
        return
      }
      const { state } = userModuleActionContext(context)
      try {
        const teamId = state.teamId
        if (!teamId) throw new Error("No active Team ID present")
        //Bulk edit group arrays of all users on team to match group's new member list
        await fb.db.collection('profiles').where('teams.' + [teamId], '==', true).get().then(snap => {
          const userBatch = fb.db.batch()

          snap.docs.forEach(user => {
            const currentGroups = user.data().teamGroups?.[teamId]
            //Skip if no change, user is still in group
            if (currentGroups?.includes(groupId) && userIds.includes(user.id)) return
            //Remove group from user if no longer included
            if (currentGroups?.includes(groupId) && !userIds.includes(user.id)) {
              console.log('removing :"', groupId, '" from user', user.id)
              userBatch.update(user.ref, { ['teamGroups.' + state.teamId]: firebase.firestore.FieldValue.arrayRemove(groupId)})
            }
            //Add group to user if newly assigned
            else if ((!currentGroups?.includes(groupId)) && userIds.includes(user.id)) {
                userBatch.update(user.ref, { ['teamGroups.' + teamId]: currentGroups ? firebase.firestore.FieldValue.arrayUnion(groupId) : [groupId] })
            }
          })
          userBatch.commit()
        })
      } catch (error) {
        console.error(`Error updating member group assignments for users.`, error)
      }
    },
    tryReassignAnonymousCaster (_context: UserContext) {
      const castTeam = store.state.team.currentTeam
      const currentCast = store.state.events.currentCast
      if (currentCast === null) throw new Error('Could not assign anonymous caster if currentcast is null')
      if (currentCast.invited_casters === null) throw new Error('Could not assign anonymous caster if invited_casters is null')
      const anonymousProfile = store.state.user.profile
      const id = anonymousProfile?.assignedId
      const existingProfile = id ? castTeam[id] : undefined
      if (!anonymousProfile || !existingProfile || !anonymousProfile.anonymous) return

      const invitedCaster = currentCast.invited_casters[anonymousProfile.id]
      if (!invitedCaster || invitedCaster.triage !== CallTriage.Assigned) return

      const updates = {
        profiles: false,
        sessions: true,
        streams: true
      }

      let count = 0
      const anonymousSessionIds = currentCast.online_sessions[anonymousProfile.id]
      const existingSessionIds = currentCast.online_sessions[existingProfile.id]
      if (!anonymousSessionIds || anonymousSessionIds.length <= 0) return
      for (const id of anonymousSessionIds) {
        if (existingSessionIds.includes(id)) count++
      }
      // no need to update sessions if the existing user's sessionIds contain all anonymous user's sessionIds
      if (count === anonymousSessionIds.length) updates.sessions = false

      // no need to update streams if there's no anonymous' stream
      const anonymousStream = Object.values(currentCast.source_streams).find((stream) => {
        if (stream.type !== StreamType.Caster) return false
        return stream.user_id === anonymousProfile.id
      })
      if (!anonymousStream) updates.streams = false

      if (!updates.sessions && !updates.streams) return
      store.dispatch.user.assignAnonymousCaster({ anonymousProfile, existingProfile, castId: currentCast.id, updates })
    },
    async setDefaultDevices (context: UserContext, deviceList: DeviceList|null) {
      const { commit, state } = userModuleActionContext(context)
      commit.setDefaultDevices(deviceList)
      if (state.profile) await store.dispatch.user.updateProfile(state.profile)
    },
    setUserUdpState (context: UserContext, newState: boolean) {
      const { state } = userModuleActionContext(context)
      if (typeof newState !== 'boolean') throw Error('User UDP must be set to a boolean.')
      if (state.id) fb.db.collection(CollectionNames.PROFILES).doc(state.id).update({ udpEnabled: newState })
    },
    updateBatteryStatus (context: UserContext, { type, battery }: { type: BatteryStatus, battery: BatteryManager }) {
      const { state } = userModuleActionContext(context)
      if (!state.id) {
        console.error('No user ID found in Store. Unable to update user battery status in DB.')
        return
      }
      const percentBattery = battery.level * 100
      fb.db.collection(CollectionNames.PROFILES).doc(state.id).update({
        ...[BatteryStatus.All, BatteryStatus.Charging].includes(type)
          && state.profile?.isBatteryCharging !== battery.charging
          && { isBatteryCharging: battery.charging },
        ...[BatteryStatus.All, BatteryStatus.Level].includes(type)
          && state.profile?.batteryLevel !== percentBattery
          && { batteryLevel: percentBattery }
      }).catch(error => {
        console.error('Error trying to update user battery status in DB.', error)
      })
    },
    async setUserConsent (_context: UserContext, params: { consentInfo: UserConsent, userId: string }) {
      try {
        const consent = params.consentInfo
        if (consent.createdDate === undefined) {
          consent.createdDate = fb.serverTimestamp()
        }
        await fb.db.collection(CollectionNames.USER_CONSENTS).doc(params.userId).set(consent)
      } catch (error) {
        console.error('Failed to store user consent in DB.', params.userId, error)
      }
    },
    async setForceScreenModeEnabled (context: UserContext, enabled: boolean) {
      const { commit } = userModuleActionContext(context)
      commit.setForceScreenModeEnabled(enabled)
    },
    async updateCurrentProfile<E extends string> (
      context: UserContext,
      updateFunction: UpdateFunction<UserProfile, E>
    ): Promise<ResultVoid<'not_exists'|'database'|'unknown'|E>> {
      const { state } = userModuleActionContext(context)
      if (state.profile === null || state.id === null) return Result.fail('not_exists', 'No current cast')
      const oldProfile = state.profile.clone()
      const newProfile = Result.fromPossibleResult(updateFunction(oldProfile))
      let diff: WithFieldActions<DeepPartial<UserProfile>> = {}
      if (newProfile.isFailure) return newProfile.convert()
      diff = objectDbDiff(state.profile, newProfile.value)
      return studio.db.collection(CollectionNames.PROFILES).doc(state.id).set(diff, MergeOption.MERGE)
    }
  },
  mutations: {
    updateDefaultPageOnLogin (state: UserState, page: string|null) {
      state.defaultPageOnLogin = page
    },
    userLoggedIn (state: UserState, data: firebase.User) {
      state.userLoggedIn = true
      state.email = data.email ? data.email : ''
      state.id = data.uid
      state.firebaseLogin = true
      //
      Sentry.configureScope(scope => {
        if (data.email && data.uid) {
          scope.setUser({ email: data.email, id: data.uid })
        } else {
          console.error('Not able to set user for sentry, email not available')
        }
      })
    },
    setUserProfile (state: UserState, profile: UserProfile) {
      if (profile.legalAgreements !== undefined) {
        profile.legalAgreements.lastUpdateDialog = profile.legalAgreements.lastUpdateDialog ?? 0 //Assign fallback default value
        Object.assign(state.legalAgreements, profile.legalAgreements)
      } else {
        state.legalAgreements.lastUpdateDialog = 0 //Assign fallback default value
      }
      if (profile.teams !== undefined && Object.keys(profile.teams).length > 0) {
        state.teamCheck = true
      } else {
        state.teamCheck = false
      }
      if (profile.locale !== null) {
        setCurrentLanguage(profile.locale)
      }

      state.profile = profile
    },
    clearUserProfile (state: UserState) {
      state.profile = null
    },
    setUsersActiveTeams (state: UserState, teams: KeyDict<Team>) {
      state.teams = teams
    },
    setUserTeam (state: UserState, teamId: string|null) {
      state.teamId = teamId
    },
    setOnline (state: UserState, online: boolean) {
      state.online = online
    },
    completeLogin (state: UserState) {
      state.loginComplete = true
    },
    toggleProfileModal (state: UserState, showProfile: boolean) {
      state.showProfile = showProfile
    },
    setUserRoleOnTeam (state: UserState, role: string) {
      state.teamRole = role
    },
    toggleUserHasNoTeams (state: UserState, status: boolean) {
      state.userHasNoTeams = status
    },
    setPresence (state: UserState, presence: FirebasePresence | null) {
      state.presence = presence
    },
    addCastToPresence (state: UserState, castId: string) {
      if (state.presence === null) {
        console.error('Could not add cast to presence, presence is null')
        return
      }
      state.presence.addCast(castId)
    },
    removeCastFromPresence (state: UserState, castId: string) {
      if (state.presence === null) {
        console.error('Could not remove cast from presence, presence is null')
        return
      }
      state.presence.removeCast(castId)
    },
    setDeviceId (state: UserState, deviceId: string) {
      state.deviceId = deviceId
    },
    userLogout (state: UserState) {
      store.dispatch.usermedia.cleanupAll().catch((error) => {
        console.error('User media clean up Error', error)
      })
      store.dispatch.user.cleanupAll()
      store.commit.team.setTeamFeatureLoaded(false)
      // Note: `state = defaultUserState` would update the function parameter, but not the object it references.
      Object.assign(state, cloneDeep(defaultUserState))
    },
    setNeedRepublish (state: UserState, needRepublish: boolean) {
      state.needRepublish = needRepublish
    },
    setDefaultDevices (state: UserState, deviceList: DeviceList|null) {
      if (!state.profile) return
      if (state.profile.selectedAudioDevice === null) {
        const defaultAudioDevice = deviceList?.audio.input[0]?.deviceId
        if (defaultAudioDevice) state.profile.selectedAudioDevice = defaultAudioDevice
      }
      if (state.profile.selectedVideoDevice === null) {
        const defaultVideoDevice = deviceList?.video.input[0]?.deviceId
        if (defaultVideoDevice) state.profile.selectedVideoDevice = defaultVideoDevice
      }
    },
    setForceScreenModeEnabled (state: UserState, enabled: boolean) {
      state.forceScreenModeEnabled = enabled
    },
    setIsDumping (state: UserState, dumping: boolean) {
      state.isDumping = dumping
    }
  }
})

export default userModule
export const userModuleActionContext = (context: UserContext) => moduleActionContext(context, userModule)
