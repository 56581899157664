import { createRouter, createWebHistory, NavigationGuardNext, RouteLocation, RouteRecordRaw } from 'vue-router'
import fb from './firebase'
import firebase from 'firebase/compat/app'

// mobile-not-supported is still here to be backward compatible with 3.7.0, can go once 3.7.0 is depricated

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: true },
    redirect: { name: 'events', params: { subView: 'active' } }
  },
  {
    path: '/debug/:castId',
    name: 'debug',
    props: true,
    component: () => import('./views/Debug')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./modules/user/views/Login')
  },
  {
    path: '/register/:inviteId?',
    name: 'register',
    props: true,
    component: () => import('./modules/user/views/Register')
  },
  {
    path: '/invite/:inviteId',
    redirect: (to: RouteLocation) => {
      return {
        name: 'register',
        params: { inviteId: to.params.inviteId }
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('./modules/user/views/ForgotPassword')
  },
  {
    path: '/auth/action',
    name: 'auth',
    component: () => import('./modules/user/views/ResetPassword')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./views/Logout')
  },
  {
    path: '/link/:pointerId',
    name: 'link',
    props: true,
    component: () => import('./modules/user/views/RedirectGuestLink')
  },
  {
    path: '/end-session',
    name: 'end-session',
    component: () => import('./modules/user/views/EndSession')
  },
  {
    path: '/team/:subView',
    name: 'team',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('./views/MemberManagement')
  },
  {
    path: '/teamsettings/:subView',
    name: 'teamsettings',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('./views/TeamManagement')
  },
  {
    path: '/clients/:subView',
    name: 'clients',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./modules/teams/views/ClientsManagement')
  },
  {
    path: '/addfacebook',
    name: 'addFacebook',
    meta: { requiresAuth: true },
    component: () => import('./views/AddFacebook')
  },
  {
    path: '/streams',
    redirect: { name: 'streams', params: { subView: 'input' } }
  },
  {
    path: '/streams/:subView',
    name: 'streams',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./views/StreamsManager')
  },
  {
    path: '/events',
    redirect: { name: 'events', params: { subView: 'active' } }
  },
  {
    path: '/events/:subView',
    name: 'events',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./views/EventList')
  },
  {
    path: '/clipper',
    name: 'clipper',
    props: { subView: 'clipper' },
    meta: { requiresAuth: true },
    component: () => import('./views/EventList')
  },
  {
    path: '/mobile-not-supported',
    name: 'mobileNotSupported',
    component: () => import('./views/EventList')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { requiresAuth: true },
    component: () => import('./views/ContactInfo')
  },
  {
    path: '/layout',
    name: 'layout',
    meta: { requiresAuth: true },
    component: () => import('./views/LayoutManager')
  },
  {
    path: '/layouteditor/:id',
    name: 'layouteditor',
    meta: { requiresAuth: true },
    component: () => import('./modules/layouts/views/SelectLayoutEditor')
  },
  {
    path: '/marker',
    name: 'marker',
    meta: { requiresAuth: true },
    component: () => import('./views/MarkerManager')
  },
  {
    path: '/moderation',
    name: 'moderation',
    meta: { requiresAuth: true },
    component: () => import('./modules/moderation/views/Moderation')
  },
  {
    path: '/monitor',
    name: 'monitor',
    meta: { requiresAuth: true },
    component: () => import('./views/Monitor')
  },
  {
    path: '/start-stop-manager',
    name: 'startStopManager',
    meta: { requiresAuth: true },
    component: () => import('./modules/startstopmanager/views/StartStopManager')
  },
  {
    path: '/wrtc-servers',
    name: 'wrtcServers',
    meta: { requiresAuth: true },
    component: () => import('./modules/transcodingregions/views/WRTCOverview')
  },
  {
    path: '/transcoding',
    name: 'transcoding',
    meta: { requiresAuth: true },
    component: () => import('./views/OpsMonitorTranscoding')
  },
  {
    path: '/archiver',
    name: 'archiver',
    meta: { requiresAuth: true },
    component: () => import('./modules/archiver/views/OpsArchiver')
  },
  {
    path: '/backend-services',
    name: 'backendServices',
    meta: { requiresAuth: true},
    component: () => import('./modules/releases/views/OpsMonitorBackendServices')
  },
  {
    path: '/stream-monitor/:subView',
    name: 'streamMonitor',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('./views/SRTMonitor')
  },
  {
    path: '/ops/stats',
    name: 'ops_stats',
    meta: { requiresAuth: true },
    component: () => import('./modules/statistics/views/OpsStats')
  },
  {
    path: '/ops/details/:id',
    name: 'opsdetails',
    meta: { requiresAuth: true },
    component: () => import('./views/OpsMonitorDetails')
  },
  {
    path: '/rooms-processing',
    name: 'roomsProcessing',
    meta: { requiresAuth: true },
    component: () => import('./modules/rooms/views/RoomsProcessing')
  },
  {
    path: '/assets/:selectedFolderId?',
    name: 'assets',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('./views/AssetManagement')
  },
  {
    path: '/event/new/:editMode/:castType',
    name: 'createEvent',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@/modules/events/views/EventWizard')
  },
  {
    path: '/event/copy/:editMode/:castId',
    name: 'copyEvent',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@/modules/events/views/EventWizard')
  },
  {
    path: '/event/edit/:editMode/:castId',
    name: 'editEvent',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@/modules/events/views/EventWizard')
  },
  {
    path: '/event/:id',
    name: 'liveEvent',
    meta: { requiresAuth: true },
    component: () => import('./views/LiveEvent')
  },
  {
    path: '/event/panel/:id/:mode',
    name: 'liveEventBreakout',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('./views/LiveEventBreakout')
  },
  {
    path: '/template/:templateId/cast/:castId',
    name: 'castTemplate',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@/modules/templates/views/CastTemplate')
  },
  {
    path: '/station/:stationId',
    name: 'station',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./views/LiveEvent')
  },
  {
    path: '/preview/:castId',
    name: 'preview',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./views/Preview')
  },
  {
    path: '/cast-templates',
    name: 'castTemplates',
    meta: { requiresAuth: true },
    component: () => import('./views/CastTemplates')
  },
  {
    path: '/cef/:id',
    name: 'cefEvent',
    component: () => import('./views/CefEvent'),
    beforeEnter: (_to: RouteLocation, _from: RouteLocation, next: NavigationGuardNext) => next() // login via token
  },
  {
    path: '/ios-front/:id',
    name: 'iosFront',
    component: () => import('./views/CefEvent'),
    // beforeEnter: (_to, _from, next) => next() // login via token
  },
  {
    path: '/ios-back/:id',
    name: 'iosBack',
    component: () => import('./views/CefEvent'),
    // beforeEnter: (_to, _from, next) => next() // login via token
  },
  {
    path: '/ios/:id',
    name: 'iosWrapper',
    component: () => import('./views/IosWrapper'),
    // beforeEnter: (_to, _from, next) => next() // login via token
  },
  {
    path: '/billing',
    name: 'billing',
    meta: { requiresAuth: true },
    component: () => import('./views/Billing')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { requiresAuth: true },
    component: () => import('./modules/dashboard/views/Dashboard')
  },
  {
    path: '/roomredirect',
    name: 'defaultRedirectRooms',
    component: () => import('./views/RedirectRooms')
  },
  {
    path: '/athletemoments',
    name: 'defaultRedirectAthleteMoments',
    component: () => import('./views/RedirectAthleteMoments')
  },
  {
    path: '/docs',
    redirect: 'https://kiswestudio.zendesk.com/'
  },
  {
    path: '/flags',
    name: 'flags',
    component: () => import('./views/FeatureFlags')
  },
  {
    path: '/ingest/:castId',
    name: 'ingest',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('./modules/screenshare/views/HighQualityInput')
  },
  {
    path: '/styleguide',
    name: 'styleguide',
    component: () => import('./modules/ui/views/StyleguideHome')
  },
  {
    path: '/styleguide/icon',
    name: 'styleguideKisweIcon',
    component: () => import('./modules/ui/views/StyleguideIcon')
  },
  {
    path: '/styleguide/button',
    name: 'styleguideKisweButton',
    component: () => import('./modules/ui/views/StyleguideButton')
  },
  {
    path: '/styleguide/input',
    name: 'styleguideKisweInput',
    component: () => import('./modules/ui/views/StyleguideInput')
  },
  {
    path: '/styleguide/select',
    name: 'styleguideKisweSelect',
    component: () => import('./modules/ui/views/StyleguideSelect')
  },
  {
    path: '/404',
    component: () => import('./views/NotFound')
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/404'
  }
]

const history = createWebHistory(process.env.BASE_URL ?? '')
const router = createRouter({ history, routes })

let authStateChangeUnsubscriber: firebase.Unsubscribe|null = null
/*
  Make sure that the 'next' function is called exactly once in any given pass through
  the navigation guard. It can appear more than once, but only if the logical paths
  have no overlap, otherwise the hook will never be resolved or produce errors.
*/
router.beforeEach((to: RouteLocation, _from: RouteLocation, next: NavigationGuardNext) => {
  // Unsubscribe any previous auth state change observer when moving to another
  // route. If the new route requires authentication, we'll subscribe again for
  // that route.
  if (authStateChangeUnsubscriber !== null) {
    authStateChangeUnsubscriber()
    authStateChangeUnsubscriber = null
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    authStateChangeUnsubscriber = fb.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        next()
      } else {
        next({ name: 'login', query: { redirect: to.fullPath } })
      }
    })
  } else {
    next()
  }
})

export default router
