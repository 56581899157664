import { TeamState } from '@/store/types'
import { KeyDict } from '@/types'
import { CastProperty, CastPropertyType } from '@/types/admin'
import { DropdownOption } from '@/types/dropdownOption'
import store from '@/store'
import router from '@/router'
import { REDIRECT_VERSION_PLACEHOLDER } from '@/types/pointers'
import { Stream, StreamProtocol, StreamType } from '@/types/streams'
import { pickBy } from 'lodash'
import { MemberRole } from '@/modules/teams/types'
import { KisweSelectOption } from 'kiswe-ui'
import { Feature, hasFeature } from '@/featureFlags/featureFlags'
import { getQualityResolution } from '@/modules/streams/utils/quality'

// getters
const getters = {
  joinNotification: (state: TeamState) => (userId: string, link: string) => {
    const member = state.currentTeam[userId]
    if (!member) return false
    return member.notifications
      && Object.values(member.notifications).find(notification => notification.link === link)
  },
  allowedStreamQualities: (state: TeamState): KeyDict<CastProperty> => {
    const allow4k = hasFeature(Feature.Uhd4k)
    const allCastProperties = state.castProperties
    const allowedQualities: KeyDict<CastProperty> = {}
    for (const [castPropertyId, castProperty] of Object.entries(allCastProperties)) {
      if (castProperty.type !== CastPropertyType.Quality) continue
      if (getQualityResolution(castPropertyId) > 1080 && !allow4k) continue
      allowedQualities[castPropertyId] = castProperty
    }
    return allowedQualities
  },
  allowedStreamFramerates: (state: TeamState): KeyDict<CastProperty> => {
    const allCastProperties = state.castProperties
    const allowedFramerates: KeyDict<CastProperty> = {}
    for (const [castPropertyId, castProperty] of Object.entries(allCastProperties)) {
      if (castProperty.type !== CastPropertyType.Framerate) continue
      allowedFramerates[castPropertyId] = castProperty
    }
    return allowedFramerates
  },
  allowedStreamQualityDropdownOptions: (_state: TeamState): DropdownOption[] => {
    const allowedQualities = store.getters.team.allowedStreamQualities
    const sortedKeys = Object.keys(allowedQualities).sort()
    return sortedKeys.map((key) => ({ name: allowedQualities[key].name, key: key }))
  },
  allowedStreamFramerateDropdownOptions: (_state: TeamState): DropdownOption[] => {
    const allowedFramerates = store.getters.team.allowedStreamFramerates
    const sortedKeys = Object.keys(allowedFramerates).sort()
    return sortedKeys.map(key => ({ name: allowedFramerates[key].name, key: key }))
  },
  allowedStreamFramerateSelectOptions: (_state: TeamState): KisweSelectOption[] => {
    const allowedFramerates = store.getters.team.allowedStreamFramerates
    const sortedKeys = Object.keys(allowedFramerates).sort()
    return sortedKeys.map(key => ({ label: allowedFramerates[key].name, value: key }))
  },
  redirectUrl: (state: TeamState): string => {
    const defaultRedirectUrl = router.resolve({ name: 'defaultRedirectRooms' })
    if (state.team === null) return defaultRedirectUrl.href
    let teamRedirectUrl = state.team.redirectUrl
    if (teamRedirectUrl?.includes(REDIRECT_VERSION_PLACEHOLDER) && state.team.version) {
      teamRedirectUrl = teamRedirectUrl.replaceAll(REDIRECT_VERSION_PLACEHOLDER, state.team.version)
    }
    return teamRedirectUrl ?? defaultRedirectUrl.href
  },
  getOutputStreams: (state: TeamState) => (allowedProtocols: StreamProtocol[]): KeyDict<Stream> => {
    return pickBy(state.streams, (stream: Stream) => {
      if (stream.deleted) return false
      if (stream.type !== StreamType.Output) return false
      if (!stream.name) return false
      if (stream.protocol === undefined) return false
      if (!allowedProtocols.includes(stream.protocol)) return false
      return true
    })
  },
  memberRole: (state: TeamState) => (userId: string): MemberRole|string|null => {
    return state.team?.members[userId]?.role ?? null
  }
}

export default getters
