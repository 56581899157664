import CheckFeature from '../checkfeature'
import { Team } from '@/modules/teams/classes'

export default class Dashboard extends CheckFeature {
  hasFeature (): boolean {
    return this.currentTeam?.features?.dashboard?.enabled ?? false
  }

  override get description (): string {
    return 'Allows embedding the Sigma dashboard'
  }

  teamHasFeature (teamId: string): boolean {
    const team = this.teams[teamId]
    return team?.features?.dashboard?.enabled ?? false
  }

  assignTeamFeature (team: Partial<Team>, enabled: boolean): void {
    team.features = team.features ?? {}
    team.features.dashboard = { enabled }
  }

  isGlobalFeature (): boolean {
    return false
  }
}
