import { useActiveScene } from '@/modules/scenes/compositions'
import store from '@/store'
import { computed } from 'vue'
import { monitorMode } from './local'
import { getMutedName } from './names'
import { CastType } from '@/types/casts'

const monitorVolumes = computed(() => store.state.events.monitorVolumes)
const monitorMutes = computed(() => store.state.events.monitorMutes)
const programVolumes = computed(() => store.state.events.programVolumes)
const programMutes = computed(() => store.getters.events.currentMuteLevels)
const applyMonitorToLive = async () => {
  await store.dispatch.events.applyMonitorToLive()
}

const useVolumes = () => {
  const { activeScene, currentClipAssetId } = useActiveScene()

  const isPlaylistMuted = (assetId: string|null, defaultMuted: boolean) => {
    if (activeScene.value?.contents !== undefined && assetId !== null) {
      const assetState = activeScene.value.contents[assetId]
      if (assetState?.muted !== undefined) {
        return assetState.muted
      }
    }
    return defaultMuted
  }

  const isProgramMuted = (key: string) => {
    let muted = true
    let defaultMuted = false
    if (key !== 'playlist' || store.state.events.currentCast?.castType === CastType.Switcher) {
      const mutedName = getMutedName(key)
      if (mutedName === 'leftMuted') {
        defaultMuted = true
      }
      const activeSceneId = store.state.events.currentCast?.activeScene ?? null
      const mutes = store.state.events.currentCast?.getVisibleMutes(activeSceneId, [key]) ?? {}
      muted = mutes[key] ?? defaultMuted
    } else { // it's a playlist
      muted = isPlaylistMuted(currentClipAssetId.value, defaultMuted)
    }
    return muted
  }

  const isMonitorMuted = (key: string) => {
    let muted = true
    const tmpMuted = monitorMutes.value[key]
    if (tmpMuted !== undefined) {
      muted = tmpMuted
    } else {
      // this used to be `false` but that allows, 2 channels to be unmuted at the same time.
      // a better default would be `true`, but we don't want that so this is the alternative
      muted = isProgramMuted(key)
    }
    return muted
  }

  const isMuted = (key: string) => {
    let muted = true
    if (!monitorMode.value) {
      muted = isProgramMuted(key)
    } else {
      muted = isMonitorMuted(key)
    }
    return muted
  }

  return {
    monitorVolumes,
    monitorMutes,
    programVolumes,
    programMutes,
    isMuted,
    isProgramMuted,
    isMonitorMuted,
    applyMonitorToLive
  }
}

export default useVolumes
