import store from '@/store'
import { Ref } from 'vue'
import useVideoPlayerSubscription from './videoPlayerSubscription'
import { isVideoAsset } from '@/types/assets'
import { CueType } from '../types'

const getClipDuration = (videoClipId: string) => {
  if (!videoClipId) return 0
  const clipSceneAsset = store.state.assets.sceneAssets[videoClipId]
  if (!clipSceneAsset || !isVideoAsset(clipSceneAsset)) return 0
  const duration = clipSceneAsset?.metadata?.duration || 0
  if (typeof duration !== 'number') return 0
  return duration
}

const useClipInfo = (castId: Ref<string|null|undefined>, videoPlayerId: Ref<string>) => {
  useVideoPlayerSubscription(castId)

  const getClipCue = (videoClipId: string, cueType: CueType = CueType.CueIn) => {
    if (!videoPlayerId.value || !videoClipId) return 0
    // eslint-disable-next-line @stylistic/max-len
    const clipProperties = store.state.videoPlayer.videoPlayers[videoPlayerId.value]?.desired_state?.clipProperties?.[videoClipId]
    return (cueType === CueType.CueIn ? clipProperties?.cueIn : clipProperties?.cueOut) ?? 0
  }

  const getClipDelayedPlayhead = () => {
    if (!videoPlayerId.value) return 0
    return store.state.videoPlayer.videoPlayers[videoPlayerId.value]?.getDelayedPlayhead() ?? 0
  }

  return {
    getClipCue,
    getClipDelayedPlayhead,
    getClipDuration
  }
}

export default useClipInfo
