import { Loader, Result } from 'kiswe-ui'
import { Transcode, Region } from '@/types/ops'
import { KeyDict } from '@/types'
import { SrtStats, SystemLoad } from '../types'

export default class TranscodingStats {
  srt: SrtStats = {}
  systemload: SystemLoad = { cpu: 0, disk: 0, mem: 0, net: {} }
  timestamp: number = 0
  transcodes: KeyDict<Transcode> = {}
  transcodingclustername: Region = Region.USEast

  constructor (data: unknown) {
    this.srt = Loader.loadAndCast<SrtStats>(data, 'srt', this.srt)
    this.systemload = Loader.loadAndCast<SystemLoad>(data, 'systemload', this.systemload)
    this.timestamp = Loader.loadNumber(data, 'timestamp', this.timestamp)
    this.transcodes = Loader.loadAndCast<KeyDict<Transcode>>(data, 'transcodes', this.transcodes)
    this.transcodingclustername = Loader.loadString(data,
      'transcodingclustername', this.transcodingclustername) as Region
  }

  static load (data: unknown): Result<TranscodingStats, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new TranscodingStats(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
