import { createClass } from '@/modules/classBuilder/classBuilder'
import { ClassType } from '@/modules/classBuilder/mixins/base'
import { CastType } from '../../../types/casts'
import { CastBase } from './CastBase'

class CastSwitcherLeftOvers extends CastBase {
  constructor (data: unknown) {
    super(data)
  }

  override get canSelectMultipleStreams () {
    return false
  }

  override get showInputStreamsAsList () {
    return true
  }

  override get showInviteLink (): boolean {
    return true
  }
}

export const CastSwitcher = createClass(CastSwitcherLeftOvers)
  .addCastType(CastType.Switcher)
  .addCasters()
  .addOnlineSessions()
  .addCustomOutputs()
  .addPresets()
  .addInputs()
  .addMutes({ useCastLevelMutes: true })
  .addAudioTracks()
  .get()

export type CastSwitcher = ClassType<typeof CastSwitcher>
