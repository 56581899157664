import { KeyDict } from '../../../types'
import { CollectionNames } from '@/modules/database/utils'
import { Asset, AssetGroup } from '../../../types/assets'
import firebase from 'firebase/compat/app'
import { CreateTeamErrorCode } from './errors'
import { Result } from 'kiswe-ui'


interface DefaultAssets {
  assetGroups: KeyDict<AssetGroup>,
  assets: KeyDict<Asset>,
  assetGroupIds: Map<string, string>,
  assetIds: Map<string, string>
}

// eslint-disable-next-line max-lines-per-function
export const getAllDefaultAssets = async (
  db: firebase.firestore.Firestore
): Promise<Result<DefaultAssets, CreateTeamErrorCode>> => {
  const result: DefaultAssets = { assetGroups: {}, assets: {}, assetGroupIds: new Map(), assetIds: new Map() }
  const assetGroups = await db
    .collection(CollectionNames.ASSET_GROUPS)
    .where('team_id', '==', 'all')
    .where('deleted', '==', false)
    .get()
  assetGroups.docs.forEach((doc) => {
    const newId = db.collection(CollectionNames.ASSET_GROUPS).doc().id
    result.assetGroups[newId] = { ...doc.data() as AssetGroup, id: newId }
    result.assetGroupIds.set(doc.id, newId)
  })
  const assets = await db
    .collection(CollectionNames.ASSETS)
    .where('team_id', '==', 'all')
    .where('deleted', '==', false)
    .get()
  let error: null|Result<DefaultAssets, CreateTeamErrorCode> = null
  assets.docs.forEach((doc) => {
    const asset = doc.data() as Asset
    asset.timestamp = Date.now()
    const assetGroupId = result.assetGroupIds.get(asset.asset_group)
    if (assetGroupId === undefined) {
      const msg = `Asset with id=${doc.id} has an assetgroup that does not exists (asset_group=${asset.asset_group})`
      error = Result.fail(CreateTeamErrorCode.ASSET_HAS_NO_EXISTING_ASSETGROUP, msg)
      return
    }
    asset.asset_group = assetGroupId
    const newId = db.collection(CollectionNames.ASSETS).doc().id
    result.assetIds.set(doc.id, newId)
    result.assets[newId] = { ...asset, id: newId }
  })
  if (error !== null) return error
  return Result.success(result)
}

export const addAssetsToTeam = (
  transaction: firebase.firestore.Transaction,
  db: firebase.firestore.Firestore,
  assets: DefaultAssets,
  teamId: string
) => {
  Object.entries(assets.assetGroups).forEach(([id, assetGroup]) => {
    const doc = db.collection(CollectionNames.ASSET_GROUPS).doc(id)
    transaction.set(doc, { ...assetGroup, team_id: teamId })
  })
  Object.entries(assets.assets).forEach(([id, asset]) => {
    const doc = db.collection(CollectionNames.ASSETS).doc(id)
    transaction.set(doc, { ...asset, team_id: teamId })
  })
}
