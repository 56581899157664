import sleep from '@/testing/sleep'
import { KeyDict, Optional } from '@/types'
import { TranscodingRegion, WRTCServerType } from '@/types/admin'
import { pickBy } from 'lodash'
import store from '@/store'
import { LogLevel } from '@/types/logs'
import { WrtcServerStat } from '../types'
import { JANUS_CONNECTION_LIMIT, WOWZA_CONNECTION_LIMIT, WOWZA_THREAD_LIMIT } from './WrtcServerStat'
import { WowzaStat } from './WowzaStat'

export interface SelectedWowzaServer {
  chinaProxyUrl: string|null
  url: string,
  numConnections?: number,
  healthy: boolean
}

export default class WowzaLoadBalancer {

  private async getServer (region: TranscodingRegion, isJanus?: boolean, isLive: boolean = true) {
    await store.dispatch.opsmonitor.fetchWrtcStats()
    const wrtcstats = store.state.opsmonitor.wrtcstats
    return this.getServerUrlToStreamTo(region, wrtcstats, { isLive, isJanus })
  }

  async getServerWhenReady (region: TranscodingRegion, maxIterations: number = 12, isJanus: boolean = false) {
    let iterations = 0
    let server = await this.getServer(region, isJanus)
    while (!server && iterations < maxIterations) {
      await sleep(5000)
      iterations++
      server = await this.getServer(region, isJanus)
    }
    if (!server) {
      server = await this.getServer(region, isJanus, false)
      store.dispatch.logs.addLog({
        message: `No active server found for ${region.name} user time ${new Date()}`, level: LogLevel.Info
      }).catch((error) => console.error(error))
    }
    return server
  }

  private getCount (stats: WrtcServerStat|undefined) {
    if (!stats) return 0
    if (stats.serverType === WRTCServerType.JANUS) {
      return stats.subscriberCount + stats.publisherCount
    } else {
      return stats.connectionCount
    }
  }

  private checkWowzaLimit (stats: WowzaStat|undefined) {
    if (!stats) return true
    return stats.threadCount < WOWZA_THREAD_LIMIT
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  getServerUrlToStreamTo (
    transcodingregion: TranscodingRegion, wowzastats: KeyDict<WrtcServerStat>,
    options?: { isLive?: boolean, isJanus?: boolean }
  ): Optional<SelectedWowzaServer> {
    let keys: string[]
    const isJanus = options?.isJanus ? options?.isJanus : false
    if (isJanus) {
      keys = Object.keys(pickBy(transcodingregion.wrtcserver, (server) => (
        server.enabled && server.type === WRTCServerType.JANUS))).sort()
    } else {
      keys = Object.keys(pickBy(transcodingregion.wrtcserver, (server) => (
        server.enabled && server.type !== WRTCServerType.JANUS))).sort()
    }
    const keysWithStats = keys.filter((key) => {
      const stats = wowzastats[key]
      return stats !== undefined && (stats.serverType === WRTCServerType.JANUS ? true : this.checkWowzaLimit(stats))
    })
    keysWithStats.sort((key1, key2) => {
      const count1 = this.getCount(wowzastats[key1])
      const count2 = this.getCount(wowzastats[key2])
      return count1 < count2 ? -1 : 1
    })
    const now = Date.now() / 1000
    const workableKeys = Object.values(keysWithStats).length > 0 ? keysWithStats : keys
    for (const workableKey of workableKeys) {
      const numConnections = this.getCount(wowzastats[workableKey])
      const lastUpdated = wowzastats[workableKey]?.lastUpdate.seconds
      if (lastUpdated > now - 60 || !options?.isLive) {
        return {
          url: transcodingregion.wrtcserver[workableKey]?.url,
          chinaProxyUrl: transcodingregion.wrtcserver[workableKey]?.chinaProxyUrl ?? null,
          numConnections,
          healthy: isJanus ? numConnections <  JANUS_CONNECTION_LIMIT
           : numConnections < WOWZA_CONNECTION_LIMIT
        }
      } else {
        console.log(`Server ${workableKey} not yet started checking next`)
      }
    }
    return undefined
  }
}
