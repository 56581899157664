<template>
  <Modal
    v-if="showLegalPrompt"
    title="Terms and Policies"
    submitText="I Accept"
    cancelText="Later"
    customClass="legal-prompt"
    :buttonActive="true"
    @submitted="updateUserLegalProps"
    @close="updateUserLegalProps(true)"
  >
    <div>
      <Interval
        :timeout="6000"
        @onInterval="tryShowLegalPrompt"
      />
      <p v-if="dialogType === 'initial'">
        Please read and accept the <a class="legal-url" :href="tosUrl" target="_blank">Terms of Service</a> and <a class="legal-url" :href="privacyUrl" target="_blank">Privacy Policy</a> to continue using Kiswe Studio.
      </p>
      <p v-else-if="dialogType === 'all'">
        The <a class="legal-url" :href="tosUrl" target="_blank">Terms of Service</a> and <a class="legal-url" :href="privacyUrl" target="_blank">Privacy Policy</a> have been updated. Please review and accept them to continue using Kiswe Studio.
      </p>
      <p v-else-if="dialogType === 'tos'">
        The <a class="legal-url" :href="tosUrl" target="_blank">Terms of Service</a> have been updated. Please review and accept them to continue using Kiswe Studio.
      </p>
      <p v-else-if="dialogType === 'privacy'">
        The <a class="legal-url" :href="privacyUrl" target="_blank">Privacy Policy</a> has been updated. Please review and accept it to continue using Kiswe Studio.
      </p>
    </div>
  </Modal>
</template>

<script lang="ts">
import './LegalUpdatePrompt.scss'
import { defineComponent } from 'vue'
import Modal from '@/components/Modal'
import Interval from '@/components/Common/Interval'
import store from '@/store'
import moment from 'moment'
moment().format()

export default defineComponent({
  name: 'LegalUpdatePrompt',
  async mounted () {
    await store.dispatch.admin.subscribeLegalInfo()
  },
  async beforeUnmount () {
    await store.dispatch.admin.unsubscribeLegalInfo()
  },
  data: () => ({
    showLegalPrompt: false
  }),
  computed: {
    tosUrl (): string {
      return this.$store.direct.state.admin.legalinfo.tosUrl
    },
    privacyUrl (): string {
      return this.$store.direct.state.admin.legalinfo.privacyUrl
    },
    tosVersion (): number {
      return this.$store.direct.state.admin.legalinfo.tosLastPublished.seconds
    },
    privacyVersion (): number {
      return this.$store.direct.state.admin.legalinfo.privacyLastPublished.seconds
    },
    tosUserVersion (): number {
      return this.$store.direct.state.user.legalAgreements.tosVersion
    },
    privacyUserVersion (): number {
      return this.$store.direct.state.user.legalAgreements.privacyVersion
    },
    lastUpdateDialog (): number | null {
      return this.$store.direct.state.user.legalAgreements.lastUpdateDialog
    },
    dialogType (): string {
      //If first time accepting terms, e.g., existing users
      if (this.tosUserVersion === 0 || this.privacyUserVersion === 0) {
        return 'initial'
      }

      //If both terms updated
      else if (this.tosVersion > this.tosUserVersion && this.privacyVersion > this.privacyUserVersion) {
        return 'all'
      }

      //If only ToS updated
      else if (this.tosVersion > this.tosUserVersion && this.privacyVersion <= this.privacyUserVersion) {
        return 'tos'
      }

      //If only Privacy Policy updated
      else if (this.tosVersion <= this.tosUserVersion && this.privacyVersion > this.privacyUserVersion) {
        return 'privacy'
      }
      return 'initial'
    }
  },
  methods: {
    updateUserLegalProps (onlyDialogTime: boolean = false) {
      //Match user's legal versions with latest if param is true.
      //Else only update user's lastUpdateDialog time
      this.$store.direct.dispatch.user.updateLegalAgreements(onlyDialogTime)
      this.showLegalPrompt = false
     },
    tryShowLegalPrompt () {
      /* Only show agreement dialog if there is an updated doc version
      and it's been over 15 minutes since the user was last asked.
      Inherently accounts for non-existing values, as Store defaults them to 0. */
      if (this.lastUpdateDialog !== null
      && (this.tosVersion > this.tosUserVersion || this.privacyVersion > this.privacyUserVersion)
      && moment().subtract(15, 'minutes').unix() > this.lastUpdateDialog) {
        this.showLegalPrompt = true
      }
      this.showLegalPrompt = false
    }
  },
  components: {
    Modal,
    Interval
  }
})
</script>
