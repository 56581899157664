import store from '@/store'
import { computed,  ComputedRef, watch } from 'vue'
import { useCrewControls } from '@/modules/actionpalette/compositions'
import useTalkback from './talkback'
import { KeyDict } from '@/types'
import { Modals } from '@/modules/ui/types'
import { useCastStreams } from '@/modules/streams/compositions'
import { StreamType } from '@/types/streams'

// Last non-zero talkback volume by user
const lastUserTalkbackVolume: KeyDict<number> = {}

const useCrewTalkback = (crewUserId: ComputedRef<string>) => {

  const { crewTalkbackStreams, getCrewCastStream } = useCrewControls()
  const currentTalkbackTarget = useTalkback().talkbackTarget
  const isUserMediaConnected = computed(() => store.state.usermedia.isConnected)
  const { sourceStreams } = useCastStreams()

  const crewTalkbackStream = computed(() => crewTalkbackStreams.value[crewUserId.value])
  const crewMediaStream = computed(() => getCrewCastStream(crewUserId.value)?.srcObject ?? null)

  const isCrewConnected = computed(() => !!crewMediaStream.value)
  const isTalkbackOn = computed(() => !!currentTalkbackTarget.value && currentTalkbackTarget.value === crewUserId.value)
  const talkbackVolume = computed({
    get (): number {
      return crewTalkbackStream.value?.id ? store.state.events.talkbackVolumes[crewTalkbackStream.value.id] : 0
    },
    async set (newValue: number) {
      if (crewTalkbackStream.value?.id) {
        await store.dispatch.events.updateTalkbackVolumes({ [crewTalkbackStream.value.id]: newValue })
        if (newValue > 0) lastUserTalkbackVolume[crewUserId.value] = newValue
      }
    }
  })
  const startCrewTalkback = async () => {
    if (isTalkbackOn.value) return
    if (isUserMediaConnected.value) {
      await store.dispatch.talkback.startTalkback(crewUserId.value)
    } else {
      await store.dispatch.ui.openModal(Modals.USER_MEDIA_CONNECT)
    }
  }
  const stopCrewTalkback = async () => {
    if (!isTalkbackOn.value) return
    await store.dispatch.talkback.stopTalkback()
  }

  watch(crewMediaStream, (newValue: MediaStream|null|undefined, oldValue: MediaStream|null|undefined) => {
    if (newValue && !oldValue) {
      const default_value = sourceStreams.value[crewUserId.value]?.type === StreamType.RemoteTalkback ? 0 : 100
      talkbackVolume.value = lastUserTalkbackVolume[crewUserId.value] || default_value
    }
  })

  return {
    isCrewConnected,
    isTalkbackOn,
    talkbackVolume,
    crewTalkbackStream,
    crewMediaStream,
    startCrewTalkback,
    stopCrewTalkback
  }
}

export default useCrewTalkback
