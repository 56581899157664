import { Loader } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'

export function hasScheduledDate<TBase extends StudioBaseMixin> (Base: TBase) {
  return class ScheduledDateClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.scheduledDate = Loader.loadDateOrNull(arg[0], 'scheduledDate', null)
    }
    scheduledDate: Date|null
    // @ts-ignore
    private __hasScheduledDate: true = true

    setScheduledDate (date: Date|null) {
      this.scheduledDate = date
      return this
    }
  }
}

export type ScheduledDate = Mixin<typeof hasScheduledDate>

export const hasScheduledDateMixin = (obj: unknown): obj is ScheduledDate => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasScheduledDate' in obj) return true
  return false
}

