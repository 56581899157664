import { AbilityAction, AbilitySubject } from '@/abilitiesByRole'
import { can } from '@/ability'
import helpers from '@/helpers'
import { hasCastersMixin } from '@/modules/classBuilder/mixins/hasCasters'
import store from '@/store'
import { EventsState } from '@/store/types'
import { KeyDict } from '@/types'
import { AssetGroup, AssetType } from '@/types/assets'
import { AudioMixType, CallTriage, CastStatus, InvitedCaster } from '@/types/casts'
import { Scene } from '@/types/scenes'
import { StreamDoc } from '@/types/streams'
import { PreviewVideoQualityType, UserProfile } from '@/types/users'
import { cloneDeep, pickBy, sortBy } from 'lodash'

const getters = {
  numTakenSlots (_state: EventsState): number {
    const numberOfGuests = store.getters.events.guestsInSlots.length
    const numberAllGuests = Object.keys(store.getters.events.activeInvitedCasters).length
    const freeAnonymousSlots = store.getters.events.maxAnonymousGuests - numberOfGuests
    const freeAllCastersSlots = store.getters.events.maxAllowedCasters - numberAllGuests
    const minOpenSlots = Math.min(freeAnonymousSlots, freeAllCastersSlots)
    return store.getters.events.maxAnonymousGuests - minOpenSlots
  },
  guestsInSlots (state: EventsState): InvitedCaster[] {
    if (state.currentCast === null) return []
    if (state.currentCast.invited_casters === null) return []
    const allowedTriagesInSlots = [CallTriage.Accepted, CallTriage.InModeration, CallTriage.Unverified]
    return Object.values(state.currentCast.invited_casters).filter((caster) => {
      return caster.anonymous && allowedTriagesInSlots.includes(caster.triage) && !caster.deleted
    })
  },
  maxAnonymousGuests (state: EventsState): number {
    return state.currentCast?.anonymous_caster_rules?.max_anonymous_casters ?? 0
  },
  maxAllowedCasters (state: EventsState): number {
    return state.currentCast?.anonymous_caster_rules?.max_total_casters ?? 0
  },
  activeInvitedCasters (state: EventsState): KeyDict<InvitedCaster> {
    if (state.currentCast?.invited_casters === undefined) return {}
    return pickBy(state.currentCast.invited_casters, (value) => {
      if (value.deleted === true) return false
      if (value.triage === CallTriage.Rejected) return false
      if (value.triage === CallTriage.Assigned) return false
      return true
    })
  },
  playListVideoIds (state: EventsState): string[] {
    const allVideoIDs : string[] = []
    if (!state.currentCast || !state.currentCast.scenes) return []
    Object.values(state.currentCast.scenes).forEach(scene => {
      if (!scene.contents) return
      Object.values(scene.contents).forEach(asset => {
        if (asset.type === AssetType.Video && !allVideoIDs.includes(asset.id)) {
          allVideoIDs.push(asset.id)
        }
      })
    })
    return allVideoIDs
  },
  streamDoc: (state: EventsState) => (rawStreamId: string) : StreamDoc | undefined => {
    const streamDocs = state.streamDocs
    const streamId = (rawStreamId || '').split('_')[0] // remove '_lowres' suffixes etc

    if (!streamDocs || Object.values(streamDocs).length === 0 || !streamDocs.hasOwnProperty(streamId)) {
      return undefined
    }
    return streamDocs[streamId]
  },
  currentUserIsACaster (_state: EventsState): boolean {
    const activeInvitedCasters = store.getters.events.activeInvitedCasters
    const currentUserId = store.state.user.id ?? ''
    if (currentUserId === '' || activeInvitedCasters[currentUserId] === undefined) return false
    return true
  },
  currentCastId: (state: EventsState) => { return state.currentCastId },
  castAssetGroups: (state: EventsState): KeyDict<AssetGroup> => {
    if (!state.currentCast?.id) {
      return {}
    }
    const castgroups = state.currentCast.asset_groups
    return pickBy(store.state.assets.folderList, folder => castgroups[folder.id] === true)
  },
  // used for debugging
  availableStreams: (state: EventsState): any[] => {
    const activeScene = getters.activeScene(state)
    return Object.values(activeScene!.contents || {}).filter((o: any) => o.type === AssetType.Broadcast)
  },
  numIngests (_state: EventsState): KeyDict<KeyDict<number>> {
    if (can.value(AbilityAction.Do, AbilitySubject.Ops)) {
      return {
        '1080': {
          '30': 10,
          '60': 10
        },
        '720': {
          '30': 10,
          '60': 10
        }
      }
    }
    return {
      '1080': {
        '30': 6,
        '60': 4
      },
      '720': {
        '30': 8,
        '60': 6
      }
    }
  },
  availableWidgets: (state: EventsState): AssetType[] => {
    const widgetTypes: AssetType[] = []
    if (!!state.currentCast?.widgets) {
      Object.values(state.currentCast.widgets).forEach(widget => {
        widgetTypes.push(widget.type)
      })
    }
    return widgetTypes
  },
  activeScene (state: EventsState): Scene|undefined {
    if (!state.currentCast?.scenes) return undefined
    if (!state.currentCast.activeScene) return undefined
    if (!state.currentCast.scenes[state.currentCast.activeScene]) return undefined
    return state.currentCast.scenes[state.currentCast.activeScene]
  },
  openScene (state: EventsState): Scene|undefined {
    if (!state.openScene) return undefined
    if (!state.currentCast?.scenes) return undefined
    if (!state.currentCast.scenes[state.openScene]) return undefined
    return state.currentCast.scenes[state.openScene]
  },
  currentMuteLevels (state: EventsState): KeyDict<boolean> {
    const cast = state.currentCast
    if (cast === null) return {}
    return cast.getSceneMutesForChannels(cast.activeScene, Object.keys(state.programVolumes))
  },
  selectedAudioLevels (state: EventsState): KeyDict<number> {
    let volumes = cloneDeep(state.programVolumes)
    if (state.audioMix === AudioMixType.Monitor) {
      volumes = state.monitorVolumes
    } else if (state.audioMix === AudioMixType.LiveEdit) {
      for (const key in volumes) {
        if (state.liveEditVolumes[key] !== undefined) {
          volumes[key] = state.liveEditVolumes[key]
        } else {
          // REFACTOR >>> Fairly similar to AudioControls::isInActiveScene()
          let isActiveInScene = false
          const keyBase = key.split('_')[0]
          const activeScene = store.getters.events.activeScene
          if (activeScene !== undefined && activeScene.contents !== undefined && activeScene.contents[keyBase] !== undefined) {
            if (activeScene.contents[keyBase].active && activeScene.scene_layout.boxes[activeScene.contents[keyBase].box] !== undefined) {
              isActiveInScene = true
            }
          }
          if (key === 'playlist') {
            isActiveInScene = true
          }
          // REFACTOR <<<
          if (store.getters.events.currentMuteLevels[key] || !isActiveInScene) {
            volumes[key] = 0
          }
        }
      }
    } else {
      for (const key in volumes) {
        if (store.getters.events.currentMuteLevels[key]) {
          volumes[key] = 0
        }
      }
    }
    return volumes
  },
  invitedCasters (state: EventsState): KeyDict<InvitedCaster> {
    const casters = state.currentCast?.invited_casters ?? {}
    for (const [key, value] of Object.entries(casters)) {
      if (typeof value === 'boolean') {
        casters[key] = new InvitedCaster({ userId: key })
      } else {
        casters[key].userId = key
      }
    }
    return casters
  },
  currentUserIsModerator: (state: EventsState) => (castTeam?: KeyDict<UserProfile>): boolean => {
    const currentUserId = store.state.user.id
    if (!currentUserId) return false
    if (state.currentCast === null) return false
    if (!hasCastersMixin(state.currentCast)) return false
    const moderators = state.currentCast.moderators
    const moderator = moderators?.[currentUserId]
    const team = castTeam ?? store.state.team.currentTeam
    return !!moderator?.connected && store.getters.user.currentUserIsInTeam(team)
  },
  orderedScenes: (state: EventsState): Scene[] => {
    let scenes: Scene[] = []
    if (state.currentCast?.scenes === undefined) return scenes
    for (const [key, currentScene] of Object.entries(state.currentCast.scenes)) {
      if (currentScene.scene_layout?.boxes === undefined) continue
      scenes.push({ ...currentScene, id: key, name: helpers.initialCaps(currentScene.name) })
    }
    scenes = sortBy(scenes, 'order')
    return scenes
  },
  currentCastEnded (state: EventsState): boolean {
    return state.currentCast?.status === CastStatus.Archived
  },
  thumbnailRate (_state: EventsState): 'high'|'low' {
    return (store.state.user.profile?.previewVideoQuality === PreviewVideoQualityType.Low) ? 'low' : 'high'
  },
  isMonitorMixActive (state: EventsState): boolean {
    return state.audioMix === AudioMixType.Monitor
  }
}

export default getters
