import { Loader } from 'kiswe-ui'

export enum AssetType {
  Broadcast = 'broadcast',
  Cast = 'cast',
  Caster = 'caster',
  Chat = 'chat',
  FacebookChat = 'fb_chat',
  Graphic = 'graphic',
  Playlist = 'playlist',
  ScreenShare = 'screenshare',
  SimpleCG = 'simplecg',
  Stinger = 'stinger',
  Tweet = 'tweet',
  TweetPoll = 'tweetpoll', // Same as AssetGroupType (for legacy reasons)
  Unknown = 'unknown',
  Video = 'video',
  Widget = 'widget',
  YouTubeChat = 'yt_chat'
}

export enum AssetGroupType {
  FacebookChat = 'fb_chat',
  Folder = 'folder',
  RootFolder = '',
  Tweet = 'tweet', // Single tweet
  TwitterHandle = 'handle',
  TwitterHashtag = 'hashtag',
  TwitterPoll = 'tweetpoll', // Same as AssetType (for legacy reasons)
  Unknown = 'unknown',
  Widget = 'widget',
  YouTubeChat = 'yt_chat'
}

export interface AssetFolderQuery {
  id: string,
  assetGroupId: string,
  minStars: number,
  teamId: string
}

export enum AssetGroupErrors {
  FolderNotEmpty = 'FolderNotEmpty'
}

export const SocialMediaTypes = [
  AssetGroupType.TwitterHashtag,
  AssetGroupType.TwitterHandle,
  AssetGroupType.TwitterPoll,
  AssetGroupType.YouTubeChat,
  AssetGroupType.FacebookChat
] as string[]

export const SocialMediaAssetTypes = [
  AssetType.TweetPoll,
  AssetType.Tweet
] as string[]

export type AssetPreview = Pick<Asset, 'id' | 'name' | 'type' | 'url'>

export class AssetBase {
  type: AssetType
  asset_group: string
  deleted: boolean
  name: string

  constructor (data: unknown) {
    this.type = AssetType.Unknown
    this.asset_group = Loader.loadString(data, 'asset_group')
    this.deleted = Loader.loadBoolean(data, 'deleted', false)
    this.name = Loader.loadString(data, 'name')
  }
}

export class Asset extends AssetBase {
  id: string = ''
  data?: any
  url?: string
  stars: number = 0
  thumbnail?: string
  original_url?: string // our thumbnails don't have transparency
  team_id: string
  user_id?: string
  timestamp?: number
  transitionDelay?: number

  constructor (data: unknown) {
    super(data)

    this.stars = Loader.loadNumber(data, 'stars', 0)

    // @ts-ignore
    if (data.data) this.data = data.data
    // @ts-ignore
    if (data.url)
      this.url = Loader.loadString(data, 'url')
    // @ts-ignore
    if (data.thumbnail)
      this.thumbnail = Loader.loadString(data, 'thumbnail')
    // @ts-ignore
    if (data.original_url)
      this.original_url = Loader.loadString(data, 'original_url')
    this.team_id = Loader.loadString(data, 'team_id')
    // @ts-ignore
    if (data.user_id)
      this.user_id = Loader.loadString(data, 'user_id')
    // @ts-ignore
    if (data.timestamp)
      this.timestamp = Loader.loadNumber(data, 'timestamp')
    // @ts-ignore
    if (data.transitionDelay)
      this.transitionDelay = Loader.loadNumber(data, 'transitionDelay')
    // @ts-ignore
    if (data.id)
      this.id = Loader.loadString(data, 'id', '')
    // @ts-ignore
    if (data.type)
      this.type = Loader.loadString(data, 'type', AssetType.Unknown) as AssetType

  }
}

export interface AssetSummary {
  id: string,
  name?: string,
  url?: string,
  type: AssetType,
  timestamp?: number,
  stars: number,
  state?: ClipTranscodingState
}

export interface TweetAsset extends Asset {
  tweet_id?: string,
  data?: {
    created_at?: number,
    lang?: string,
    ref?: string,
    text: string,
    user: {
      name?: string,
      profile_image_url?: string,
      screen_name?: string,
      verified?: boolean
    },
    twitterref?: string
  },
  type: AssetType.Tweet,
  parsed?: boolean
}

export interface WidgetAsset extends Asset {
  url: string,
  reloadKey?: string
}

export interface NewAssetRequest {
  name: string,
  team_id: string,
  cast_id?: string,
  parent_id?: string,
  remote_url?: string,
  type: AssetGroupType
}

export interface PollAnswer {
  answer: string,
  percent: number
}

export interface TweetPollAsset extends Asset {
  active?: boolean,
  answers?: PollAnswer[],
  num_votes?: 0,
  question?: string,
  timeleft?: string,
  url?: string,
  type: AssetType.TweetPoll,
  up_to_date?: boolean
}

// Metadata from remote service
export interface LiveChatAssetData {
  id: string,
  created_at: number,
  text: string,
  user: string,
  ref: string // YouTube/FB video ID (view?v=id)
}

export interface LiveChatAsset extends Asset {
  data: string | LiveChatAssetData,
  type: AssetType.YouTubeChat
}

export interface GraphicAsset extends Asset {
  thumbnail: string,
  url: string,
  type: AssetType.Graphic
}

export enum ClipTranscodingState {
  Done = 'done',
  Error = 'error',
  Transcoding = 'transcoding'
}

export interface VideoAssetMetaData {
  duration: number
}

export interface VideoAsset extends Asset {
  errors?: string | null,
  metadata?: VideoAssetMetaData,
  original_url?: string,
  progress?: number,
  state?: ClipTranscodingState,
  thumbnail?: string,
  url?: string,
  type: AssetType.Video,
  volume?: number
}

/** @deprecated Would be better if we could just use discriminated unions for the Asset types to narrow the actual type
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#discriminated-unions
 */
export const isVideoAsset = (asset?: Asset): asset is VideoAsset => asset?.type === AssetType.Video
export const isTweetAsset = (asset?: Asset): asset is TweetAsset => asset?.type === AssetType.Tweet

export interface AssetGroup {
  id: string,
  type?: AssetGroupType,
  cast_id?: string,
  deleted?: boolean,
  name: string,
  team_id?: string,
  parent_id?: string,
  remote_url?: string, // eg YouTube live video chat url
  timestamp?: number,
  scrape?: boolean // only for hashtags
}
