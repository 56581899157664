import store from '@/store'
import { computed, onUnmounted, watch } from 'vue'

const streams = computed(() => store.state.team.streams)
const activeTeam = computed(() => store.state.team.activeTeam)

const useTeamStreams = () => {
  let subscribed = false
  watch(activeTeam, async (newVal, oldVal) => {
    if (oldVal) {
      subscribed = false
      await store.dispatch.team.unsubscribeTeamStreams()
    }
    if (newVal) {
      subscribed = true
      await store.dispatch.team.subscribeTeamStreams(newVal)
    }
  }, { immediate: true })

  onUnmounted(async () => {
    if (subscribed) {
      await store.dispatch.team.unsubscribeTeamStreams()
    }
  })

  return {
    streams
  }
}

export default useTeamStreams
