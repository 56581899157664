import { LocalAudioSource, LocalAudioSourceType, RootState } from '@/store/types'
import { AudioContext } from '@/types/audio'
import { audioModuleActionContext } from '.'
import { AudioPanelState } from './audio.state'
import { ActionContext } from 'vuex'
import store from '@/store'
import { AudioMixType } from '@/types/casts'

export type AudioStateContext = ActionContext<AudioPanelState, RootState>

export const actions = {
  async setActiveAudioContext (context: AudioStateContext, panelType: AudioContext) {
    const { commit } = audioModuleActionContext(context)
    commit.setActiveAudioContext(panelType)
  },
  setActiveAudioChannelsState (context: AudioStateContext, channels: { streamChannelId: string, enabled: boolean }|null) {
    const { commit } = audioModuleActionContext(context)
    commit.setActiveAudioChannelsState(channels)
  },
  setActiveAudioLocalSourceState (context: AudioStateContext, source: LocalAudioSource|null) {
    const { commit } = audioModuleActionContext(context)
    commit.setActiveAudioLocalSourceState(source)
  },
  async setPreviouslyActiveStateForScene (context: AudioStateContext, sceneId: string) {
    const previousContext = store.state.audio.audioContextStates[store.state.audio.activeAudioContext]
    if (!previousContext) return
    const { commit } = audioModuleActionContext(context)
    const localAudioSource: LocalAudioSource = { type: LocalAudioSourceType.Scene , sceneId: sceneId }
    const sourceWasPreviouslyMuted = store.getters.audio.localSource === null
    const source = sourceWasPreviouslyMuted ? null : localAudioSource
    commit.setActiveAudioLocalSourceState(source)
    await store.dispatch.events.changeAudioMix(AudioMixType.Program)
  },
  clearAudioState (context: AudioStateContext) {
    const { commit } = audioModuleActionContext(context)
    commit.setActiveAudioLocalSourceState(null)
    commit.setActiveAudioChannelsState(null)
    commit.clearAudioState()
  }
}
