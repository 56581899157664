import { Loader } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CastType, InvitedCaster } from '@/types/casts'
import { CastTemplateBase } from '../castTemplateBase'

export class CastTemplateSwitcher extends CastTemplateBase {
  invited_casters: KeyDict<InvitedCaster>
  override castType: CastType.Switcher = CastType.Switcher

  constructor (data: unknown) {
    super(data)
    this.invited_casters = {}
    const casters = Loader.loadAndCast<KeyDict<InvitedCaster>>(data, 'invited_casters', {})
    for (const key of Object.keys(casters)) {
      this.invited_casters[key] = new InvitedCaster(casters[key])
    }
  }
}
