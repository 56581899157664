import WRTCPreviewManager from '@/classes/WRTCPreviewManager'
import { KeyDict } from '@/types'
import { ref } from 'vue'
import store from '@/store'
import env from '@/env'

const wrtcPlayerReady = ref<KeyDict<boolean>>({})
const wrtcPreviewManager = ref(new WRTCPreviewManager({
  onNewStreamCallback: (streamId: string) => wrtcPlayerReady.value[streamId] = true,
  onKillStreamCallback: (streamId: string) => wrtcPlayerReady.value[streamId] = false
}))

const useWrtcPreviewManager = () => {

  const addTalentStream = async (userId: string, sourceStreamId: string|null, isUdpEnabled: boolean = true) => {
    const streamDoc = await store.dispatch.streams.getStreamDocById(sourceStreamId ?? '')
    if (streamDoc.isFailure || sourceStreamId === null) {
      console.error(`Can't find stream [${ sourceStreamId }] in streamDocs`)
      return
    }
    const isChinaBuild = env.chinaBuild
    const streamSrcUrl = (isChinaBuild) ? streamDoc.value.srcUrlChina : streamDoc.value.srcurl
    if (!streamSrcUrl) {
      console.error('Cannot add stream to wrtcPreviewManager, streamSrcUrl does not exist', { userId })
      return
    }
    const existingStream = wrtcPreviewManager.value.streams[userId] ?? null
    if (existingStream?.url === streamSrcUrl) {
      console.warn(`Stream for user ${ userId } already exists in wrtcPreviewManager`)
      return
    }
    removeTalentStream(userId)
    wrtcPreviewManager.value.addStream(streamSrcUrl, userId, false, isUdpEnabled)
  }

  const removeTalentStream = (userId: string) => {
    if (!wrtcPreviewManager.value.streams[userId]) return
    wrtcPreviewManager.value.removeStream(userId)
  }

  return {
    addTalentStream,
    removeTalentStream,
    wrtcPreviewManager,
    wrtcPlayerReady
  }
}

export default useWrtcPreviewManager
