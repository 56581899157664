import { SystemStatus } from './ops'
import { CustomRtmpDestination } from './streams'
import { KeyDict } from '../types'
import firebase from 'firebase/compat/app'
import { TranslationKeys } from '../plugins/translations/types'
import { AudioTrackInfoDict } from '../modules/audio/types'
import { Loader, Result } from 'kiswe-ui'

export const WATERMARK_DEFAULT = false
export interface PlaylistStatus {
  active?: null | string,
  position: number,
  team_id: string
}

export interface JSMemoryConsumption {
  limit: number,
  total: number,
  used: number
}

export interface PlaylistConnection {
  id: string,
  connections: {
    [ip: string]: number
  },
  memory: {
    [ip: string]: JSMemoryConsumption
  },
  volumes: {
    [ip: string]: {
      [streamname: string]: number
    }
  }
}

export interface CastOutputStream {
  ngcvp_status: SystemStatus,
  stream: string
}

export enum CastStatus {
  // TODO: there's also a 'pregame' status - how is this different from "scheduled"?
  Scheduled = 'scheduled',
  Warmup = 'warmup',
  InProgress = 'in-progress',
  Archiving = 'archiving',
  Pregame = 'pregame',
  Archived = 'archived',
  Unknown = 'unknown',
  Error = 'error',
  OK = 'ok',
  Booting = 'booting'
}

export enum EventWizardStep {
  EVENT = 'Event',
  INPUTS = 'Inputs',
  OUTPUTS = 'Outputs',
  CASTERS = 'Casters',
  ASSETS = 'Assets',
  INTEGRATIONS = 'Integrations'
}

export const MIXER_DEFAULT_GOP = 4

// this output stream is a composite of outputStream and custom stream
// we override the type prop to be inline with other output types
export interface CompositeOutputStream extends CustomRtmpDestination {
  networkslow?: boolean,
  connected?: boolean,
  ngcvp_status?: SystemStatus,
}

export interface ISORecord {
  name: string,
  url: string,
  start: number,
  end: number
}

export interface CastSummary {
  id: string,
  name: string,
  start_date: firebase.firestore.Timestamp
}

export interface CasterRequestCustomAudioMix {
  volumes: KeyDict<number>
  audioMixType: AudioMixType
}

export enum CastType {
  Switcher = 'Switcher',
  FanRoom = 'FanRoom',
  StationSwitcher = 'StationSwitcher'
}

export interface CastMessage {
  id: TranslationKeys,
  icon: string,
  color?: string
}

export enum CallTriage {
  Init = 'CallTriage.Init',                   // 0.  user needs to fill in his display name and click continue (interaction so we can play audio)
  Selftest = 'CallTriage.Selftest',           // 1.  user is doing selftest
  Queued = 'CallTriage.Queued',               // 2.  all slots are taken, waiting for a free slot
  Unverified = 'CallTriage.Unverified',       // 3.  got (automatically) assigned to a free slot
  InModeration = 'CallTriage.InModeration',   // 4.  currently videochatting with a moderator
  Rejected = 'CallTriage.Rejected',           // 5a. interviewed by moderator & rejected
  Accepted = 'CallTriage.Accepted',           // 5b. interviewed by moderator & accepted
  Assigned = 'CallTriage.Assigned'            // 5c. interviewed by moderator & assigned to an existing caster
}

export enum CasterDeletionReason {
  PresenceOffline = 'presence_offline',
  ModeratorRemoved = 'moderator_removed'
}

export class InvitedCaster {
  userId: string
  anonymous: boolean = false
  moderator: string | null = null
  triage: CallTriage = CallTriage.Accepted
  timestamp: number = Date.now()
  deleted: boolean = false
  deleted_reason: CasterDeletionReason|null
  audioTracks: AudioTrackInfoDict = {}
  isStreamReady: KeyDict<boolean> = {}

  constructor (config: unknown = {}) {
    if (config === null || typeof config !== 'object') throw new Error(`invalid params: InvitedCaster data is not an object. Received: ${config}`)

    this.userId = Loader.loadString(config, 'userId', '')
    this.anonymous = Loader.loadBoolean(config, 'anonymous', false)
    this.moderator = Loader.loadStringOrNull(config, 'moderator', null)
    this.timestamp = Loader.loadNumber(config, 'timestamp', Date.now())
    this.deleted = Loader.loadBoolean(config, 'deleted', false)
    this.deleted_reason = Loader.loadStringOrNull(config, 'deleted_reason', null ) as CasterDeletionReason|null
    this.triage = Loader.loadString(config, 'triage', this.anonymous ? CallTriage.Init : CallTriage.Accepted) as CallTriage
    this.audioTracks = Loader.loadAndCast<AudioTrackInfoDict>(config, 'audioTracks', {})
    this.isStreamReady = Loader.loadAndCast<KeyDict<boolean>>(config, 'isStreamReady', this.isStreamReady)
  }

  get isCommentator () {
    return Object.keys(this.audioTracks).length > 0
  }

  static load (data: unknown): Result<InvitedCaster, 'invalid param' | 'unknown'> {
    try {
      return Result.success(new InvitedCaster(data))
    } catch (error) {
      return Result.fromUnknownError('invalid param', error)
    }
  }
}

export interface CastModerator {
  connected: boolean
}

export interface AnonymousCasterSlot {
  currentCasterId: string
  casterHistory: KeyDict<AnonymousCasterSlotHistory>
}

export interface AnonymousCasterSlotHistory {
  casterId: string,
  startTime: number,
  endTime: number
}

export enum AudioMixType {
  Program = 'program',
  LiveEdit = 'liveedit',
  Monitor = 'monitor'
}

export interface Clip {
  start: number
  end: number
  name: string
  url: string
  clip_id?: string // Optionally use for clips with known properties, like Replay clips
  deleted?: boolean // Optionally present when clip marked deleted
  status?: string // Optionally present when updated by clip service. Values 'success', 'failed'
}
