import { Result, ResultVoid } from 'kiswe-ui'
import { useAddLog } from '@/modules/logging/compositions'
import store from '@/store'
import { PlaybackAction, PlaybackEndAction, VideoPlayerPlayState } from '@/types/videoplayer'
import { computed, ref, Ref } from 'vue'
import useVideoPlayerSubscription from './videoPlayerSubscription'
import useClipInfo from './clipInfo'
import { CueType } from '../types'
import { consoleError } from '@/modules/logging/utils'

const { addLog, addLogError, addLogNoWait } = useAddLog()

const useVideoPlayer = (videoPlayerId?: Ref<string>) => {
  const currentCastId = computed(() => store.state.events.currentCastId)
  useVideoPlayerSubscription(currentCastId)
  const { getClipDuration, getClipCue } = useClipInfo(currentCastId, videoPlayerId ?? ref('player1'))

  const configuredPlaybackAction = computed(() => {
    if (!videoPlayerId) return null
    return store.state.videoPlayer.videoPlayers[videoPlayerId.value]?.playback_action ?? null
  })

  const configuredPlaybackEndAction = computed(() => {
    if (!videoPlayerId) return null
    return store.state.videoPlayer.videoPlayers[videoPlayerId.value]?.playback_end_action ?? null
  })

  const currentClipPlayId = computed(() => {
    if (videoPlayerId?.value) {
      const clipStates = store.state.videoPlayer.localStates[videoPlayerId.value] ?? {}
      // TODO: Replace iteration with store.state.videoPlayer.currentSelectedClip when delay/flip-flop issue is fixed
      for (const [clipAssetId, clipState] of Object.entries(clipStates)) {
        if ([VideoPlayerPlayState.PLAYING, VideoPlayerPlayState.PAUSED].includes(clipState.playstate)) {
          return clipAssetId
        }
      }
    }
    return null
  })

  const currentClipPlayState = computed(() => {
    if (videoPlayerId?.value && currentClipPlayId.value) {
      return store.state.videoPlayer.localStates[videoPlayerId.value][currentClipPlayId.value]
    } else {
      return null
    }
  })

  const updatePlaybackAction = async (action: PlaybackAction): Promise<ResultVoid<'invalid_params'|'database'>> => {
    if (!videoPlayerId) {
      return Result.fail('invalid_params',
        'The composition should have been initialized with a video player id when using updatePlaybackAction()')
    }
    return await store.dispatch.videoPlayer.updatePlaybackAction({ playerId: videoPlayerId.value, action })
  }

  const updatePlaybackEndAction = async (action: PlaybackEndAction): Promise<ResultVoid<'invalid_params'|'database'>> =>
  {
    if (!videoPlayerId) {
      return Result.fail('invalid_params',
        'The composition should have been initialized with a video player id when using updatePlaybackEndAction()')
    }
    return await store.dispatch.videoPlayer.updatePlaybackEndAction({ playerId: videoPlayerId.value, action })
  }

  const playCurrentClip = async (): Promise<void> => {
    if (!videoPlayerId) {
      addLogError('Cannot play current clip', 'useVideoPlayer() requires videoPlayerId for playCurrentClip()')
        .catch((error) => console.error(error))
      return
    }
    if (!currentClipPlayId.value) {
      addLogError('Cannot play current clip', 'No clip current paused', { playerId: videoPlayerId.value })
        .catch((error) => console.error(error))
      return
    }

    await store.dispatch.videoPlayer.playAsset({
      playerName: videoPlayerId.value,
      assetId: currentClipPlayId.value
    })
  }

  const pauseCurrentClip = async (): Promise<void> => {
    if (!videoPlayerId) {
      addLogError('Cannot pause current clip', 'useVideoPlayer() requires videoPlayerId for pauseCurrentClip()')
        .catch((error) => console.error(error))
      return
    }
    if (!currentClipPlayId.value) {
      addLogError('Cannot pause current clip', 'No clip current playing', { playerId: videoPlayerId.value })
        .catch((error) => console.error(error))
      return
    }

    await store.dispatch.videoPlayer.pauseAsset({
      playerName: videoPlayerId.value,
      assetId: currentClipPlayId.value
    })
  }

  const restartCurrentClip = async (): Promise<void> => {
    if (!videoPlayerId) {
      addLogError('Cannot restart current clip', 'useVideoPlayer() requires videoPlayerId for playCurrentClip()')
        .catch((error) => console.error(error))
      return
    }
    if (!currentClipPlayId.value) {
      addLogError('Cannot restart current clip', 'No clip current playing or paused',
        { playerId: videoPlayerId.value }).catch((error) => console.error(error))
      return
    }

    await store.dispatch.videoPlayer.playAsset({
      playerName: videoPlayerId.value,
      assetId: currentClipPlayId.value,
      action: PlaybackAction.Start
    })
  }

  const setClipCueIn = async (cueTime: number, clipId: string|null) => {
    if (!currentCastId.value || !videoPlayerId?.value || !clipId) {
      consoleError('setClipCueIn: Invalid state', { currentCastId: currentCastId.value,
        videoPlayerId: videoPlayerId?.value, clipId })
      return Result.fail('invalid_params', 'currentCastId or videoPlayerId or clipId is missing')
    }
    const minCueIn = 1
    const cueOut = getClipCue(clipId, CueType.CueOut)
    const maxCueIn = (cueOut > 0 ? cueOut : getClipDuration(clipId)) - 1
    if (cueTime && (cueTime < minCueIn || cueTime > maxCueIn)) {
      addLogNoWait(`Set cue-in is out of bounds on Clip Id: ${clipId}`, 'error',
        { clipId, cueTime, minCueIn, maxCueIn })
      return Result.fail('invalid_params', `cueTime is out of bounds on Clip Id: ${clipId}`)
    }
    addLog(`Setting cue-in for clip: ${clipId} to ${cueTime}`, 'info')
      .catch((error) => console.error(error))
    await store.dispatch.videoPlayer.setClipCue({
      castId: currentCastId.value,
      playerName: videoPlayerId.value,
      assetId: clipId,
      cueIn: cueTime
    })
    return Result.ok()
  }

  const setClipCueOut = async (cueTime: number, clipId: string|null) => {
    if (!currentCastId.value || !videoPlayerId?.value || !clipId) {
      consoleError('setClipCueOut: Invalid state', { currentCastId: currentCastId.value,
        videoPlayerId: videoPlayerId?.value, clipId })
      return Result.fail('invalid_params', 'currentCastId or videoPlayerId or clipId is missing')
    }
    const minCueOut = getClipCue(clipId, CueType.CueIn) + 1
    const maxCueOut = getClipDuration(clipId) - 1
    if (cueTime && (cueTime < minCueOut || cueTime > maxCueOut)) {
      addLogNoWait(`Set cue-out is out of bounds on Clip Id: ${clipId}`, 'error',
        { clipId, cueTime, minCueOut, maxCueOut })
      return Result.fail('invalid_params', `cueTime is out of bounds on Clip Id: ${clipId}`)
    }
    addLog(`Setting cue-out for clip: ${clipId} to ${cueTime}`, 'info')
      .catch((error) => console.error(error))
    await store.dispatch.videoPlayer.setClipCue({
      castId: currentCastId.value,
      playerName: videoPlayerId.value,
      assetId: clipId,
      cueOut: cueTime
    })
    return Result.ok()
  }

  return {
    configuredPlaybackAction,
    configuredPlaybackEndAction,
    currentClipPlayId,
    currentClipPlayState,
    updatePlaybackAction,
    updatePlaybackEndAction,
    playCurrentClip,
    pauseCurrentClip,
    restartCurrentClip,
    setClipCueIn,
    setClipCueOut
  }
}

export default useVideoPlayer
