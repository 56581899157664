import { StreamChangeHandlerFactory } from '@/modules/streams/utils/streamChangeHandlerFactory'
import { KeyDict } from '@/types'
import { CastStream, StreamDoc } from '@/types/streams'
import { StreamsState } from './streams.state'

const streamChangeHandlers = StreamChangeHandlerFactory.getChangeHandlers()

export const mutations = {
  setVuMeter (state: StreamsState, vumeter: { streamId: string, channelVolumes: number[] }) {
    const timestamp = state.vumeters[vumeter.streamId] ? Date.now() : 0
    state.vumeters[vumeter.streamId] = {
      channelVolumes: vumeter.channelVolumes,
      timestamp
    }
  },
  removeVuMeter (state: StreamsState, streamId: string) {
    delete state.vumeters[streamId]
  },
  setVuMeterInterval (state: StreamsState, interval: number) {
    state.vumeterUpdateInterval = interval
  },
  setStreamsManager (state: StreamsState, payload: KeyDict<CastStream>) {
    state.castStreams = payload
  },
  addStream (state: StreamsState, stream: CastStream) {
    state.castStreams[stream.id] = stream
  },
  deleteStreamFromManager (state: StreamsState, payload: string) {
    state.castStreams[payload]
  },
  updateSubscribedStream (state: StreamsState, stream: StreamDoc) {
    // @ts-ignore
    const oldStream = state.subscribedStreams[stream.id] ?? null
    streamChangeHandlers.forEach((handler) => handler.handleChanges(oldStream, stream))
    // @ts-ignore
    state.subscribedStreams[stream.id] = stream
  },
  setDesktopStreamId (state: StreamsState, streamId: string|null) {
    state.desktopStreamId = streamId
  }
}
