import CastFilter, { CastFilterConfig } from '@/classes/CastFilter'
import { lazyUpdate } from '@/store/storehelper'
import { CastsState } from '@/store/types'
import { deleteUnwantedCasts } from './casts.helpers'
import { KeyDict } from '@/types'
import { Cast } from '@/modules/casts/classes'
import { QuerySnapShotWithParams } from '@/modules/database/utils/databaseQuerySubscriptions'
import { CastFanRoom } from '@/modules/casts/classes/CastFanRoom'
import { CastChangeHandlerSourceStreams } from '../events/castchangehandlers/castChangeHandlerSourceStreams'
import store from '@/store'

const mutations = {
  setFilter (state: CastsState, filter: CastFilter) {
    state.filters[filter.id] = filter
  },
  updateFilter (state: CastsState, params: CastFilterConfig) {
    for (const [key, value] of Object.entries(params)) {
      // @ts-ignore
      state.filters[params.id][key] = value
    }
  },
  removeFilter (state: CastsState, filterId: string) {
    delete state.filters[filterId]
  },
  cleanupCasts (state: CastsState) {
    deleteUnwantedCasts(state)
  },
  updateCasts (state: CastsState, snap: QuerySnapShotWithParams) {
    const filterId = snap.params?.filterId
    if (typeof filterId !== 'string') throw new Error('updateCasts mutation should contain a filterId')
    const filter = state.filters[filterId]
    if (filter === undefined) throw new Error(`updateCasts requires ${filterId} to be an existing filter`)

    const otherFilterDocIds = Object.values(state.filters).filter((f) => f.id !== filterId).map((f) => f.castIds).flat()
    const allCasts: KeyDict<Cast> = {}
    const isEmpty = Object.keys(state.filteredCasts).length === 0
    snap.docChanges().forEach((docChange) => {
      const doc = docChange.doc
      const isDocInAnyOtherFilter = otherFilterDocIds.includes(doc.id)
      if (docChange.type === 'removed' && !isDocInAnyOtherFilter) {
        delete state.filteredCasts[doc.id]
        return
      }
      const cast = Cast.load({ ...doc.data(), id: doc.id })
      if (cast.isFailure) {
        console.warn('Cast could not be loaded', cast.message)
        return
      }
      // 'added' || 'modified'
      allCasts[doc.id] = cast.value
      if (!isEmpty) lazyUpdate(state.filteredCasts, doc.id, allCasts[doc.id])
    })
    if (isEmpty) {
      state.filteredCasts = allCasts
    }
    filter.castIds = snap.docs.map(doc => doc.id)
    if (snap.empty) {
      filter.firstDoc = null
      filter.lastDoc = null
    } else {
      filter.firstDoc = snap.docs[0]
      filter.lastDoc = snap.docs[snap.docs.length - 1]
    }

    deleteUnwantedCasts(state)
  },
  updateAllActiveFanRooms (state: CastsState, snapData: KeyDict<CastFanRoom>) {
    state.allActiveFanRooms = snapData
  },
  updateSubscribedCast (state: CastsState, cast: Cast) {
    const oldCast = state.subscribedCasts[cast.id] ?? null
    state.subscribedCasts[cast.id] = cast
    // TODO: We only need to do this on the CEF page (and maybe in StationView)
    if (state.processSourceStreams) {
      const sourceStreamCastChangeHandler = new CastChangeHandlerSourceStreams(cast.id)
      sourceStreamCastChangeHandler.handleChanges(oldCast, cast)
      store.commit.events.updateProgramVolumes()
    }
  },
  clearSubscribedCast (state: CastsState, castId: string) {
    delete state.subscribedCasts[castId]
    store.commit.events.clearSourceStreamsOfCast(castId)
    store.commit.events.updateProgramVolumes()
  },
  setProcessSourceStreams (state: CastsState, processSourceStreams: boolean) {
    state.processSourceStreams = processSourceStreams
  }
}

export default mutations
