
export enum CreateTeamErrorCode {
  UNKNOWN = 'unknown',
  ASSET_HAS_NO_EXISTING_ASSETGROUP = 'asset_no_assetgroup',
  TEAMSTREAM_REQUIRES_TEMPLATE_ID = 'teamstream_template_id',
  COULD_NOT_CREATE_STREAM = 'could_not_create_stream',
  DUPLICATE_TEAM_NAME = 'duplicate_team_name'
}

export class CreateTeamError extends Error {
  code: CreateTeamErrorCode

  constructor (code: CreateTeamErrorCode, message: string) {
    super(message)
    this.code = code
  }
}
