<!-- Monitors the user's battery level and charging status, and stores the values in the user's DB profile. -->

<template>
  <div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BatteryStatus } from '@/types/users'

export default defineComponent({
  name: 'BatteryMonitor',
  data: () => ({
    intervalChecker: undefined as undefined | number
  }),
  mounted () {
    if (typeof navigator.getBattery !== 'function') {
      // Only Chromium browsers currently support deprecated getBattery API
      console.log('Chromium browser undetected, thus unable to retrieve user device battery status.')
      return
    }
    navigator.getBattery()?.then(battery => {
      //Give time for store to settle so profile id is available in state
      //Interval repeat so anonymous casters with full battery will update as soon as they finish initializing their anon profile in db
      this.updateBatteryStatus(BatteryStatus.All, battery)
      this.intervalChecker = window.setInterval(() => this.updateBatteryStatus(BatteryStatus.All, battery), 30000)
      //Alternatively, we can use the above means of updating exclusively instead of using event listeners below
      battery.addEventListener('chargingchange', () => {
        this.updateBatteryStatus(BatteryStatus.Charging, battery)
      })
      battery.addEventListener('levelchange', () => {
        this.updateBatteryStatus(BatteryStatus.Level, battery)
      })
    }).catch(error => {
      console.error('Failed to monitor battery charging status.', error)
    })
  },
  beforeUnmount () {
    window.clearInterval(this.intervalChecker)
    // Do we need to use removeEventListener?
  },
  methods: {
    updateBatteryStatus (type: BatteryStatus, battery: BatteryManager) {
      this.$store.direct.dispatch.user.updateBatteryStatus({ type, battery })
    },
  },
})
</script>
