import WRTCLoadbalancer from './WRTCLoadbalancer'
import store from '@/store'
import { UserProfile } from '@/types/users'

const getWrtcForCasting = (profile: UserProfile) => {
  let server = 'wrtc-va1.kiswe.com'
  if (profile.transcodingregion !== undefined) {
    const loadbalancer = new WRTCLoadbalancer()
    const region = store.state.admin.transcodingregions[profile.transcodingregion]
    const wrtcstats = store.state.opsmonitor.wrtcstats
    if (region !== undefined && wrtcstats !== undefined) {
      const loadbalancedServer = loadbalancer.getServerUrlToStreamTo(region, wrtcstats)
      if (loadbalancedServer !== undefined) server = loadbalancedServer.url
    }
  }
  return server
}

export default getWrtcForCasting
