import { KENV } from './types/admin'
import { firebase } from './env.default.json'
import { cloneDeep } from 'lodash'

export enum VueEnv {
  Development = 'development',
  Test = 'test',
  Production = 'production'
}

const DEFAULT_ENV = KENV.Dev
const LOCAL_DEV_HOST = 'localhost' // default: 'localhost' // don't forget to update vue.config.js as well
const settings = {
  [KENV.Dev]: firebase.dev,
  [KENV.Stage]: firebase.stage,
  [KENV.Prod]: firebase.prod
}

const setTestSettings = (conf: any) => {
  let data = conf
  if (!conf) {
    data = cloneDeep(settings[DEFAULT_ENV])
  }
  Object.keys(data).forEach((key) => {
    //@ts-ignore
    currentSettings[key] = data[key]
  })
}

export {
  DEFAULT_ENV,
  LOCAL_DEV_HOST,
  setTestSettings
}
const currentSettings = cloneDeep(settings[DEFAULT_ENV])
export default currentSettings
