import store from '@/store'
import { Ref, computed, onMounted, onUnmounted, watch } from 'vue'

const useVideoPlayerSubscription = (castId: Ref<string|null|undefined>) => {
  let subscribedCastId: string|null = null
  const loggedIn = computed(() => store.state.user?.userLoggedIn ?? false)

  const subscribeVideoPlayer = async () => {
    const oldSubscribedId = subscribedCastId
    if (castId.value && castId.value !== subscribedCastId && loggedIn.value) {
      subscribedCastId = castId.value
      if (oldSubscribedId) await store.dispatch.videoPlayer.unsubscribeVideoPlayer({ castId: oldSubscribedId })
      await store.dispatch.videoPlayer.subscribeVideoPlayer({ castId: subscribedCastId })
    } else if (!loggedIn.value && oldSubscribedId) {
      subscribedCastId = null
      await store.dispatch.videoPlayer.unsubscribeVideoPlayer({ castId: oldSubscribedId })
    }
  }

  watch(castId, async () => await subscribeVideoPlayer())
  watch(loggedIn, async () => await subscribeVideoPlayer())

  onMounted(async () => {
    await subscribeVideoPlayer()
  })

  onUnmounted(async () => {
    if (subscribedCastId) {
      await store.dispatch.videoPlayer.unsubscribeVideoPlayer({ castId: subscribedCastId })
      subscribedCastId = null
    }
  })

  return {
  }
}

export default useVideoPlayerSubscription
