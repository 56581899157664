<template>
  <div class="user-encoder-settings" :class="{ 'user-encoder-settings--single-column': !canEditCast }">
    <slot name="region" />
    <template v-if="canEditCast">
      <KisweFormField>
        <KisweSelect
          data-qa="select-resolution"
          label="Video quality"
          v-model="editEncoderSettings.resolution"
          :options="resolutionOptions"
        />
      </KisweFormField>
      <KisweFormField>
        <KisweInput
          type="number"
          label="Video bitrate"
          placeholder="Select"
          data-qa="input-videobitrate"
          v-model="validator.videoBitrate.$model"
          :min="200"
          :max="maxVideoBitrate"
        />
      </KisweFormField>
      <KisweFormField>
        <KisweInput
          type="number"
          label="Audio bitrate"
          placeholder="Select"
          data-qa="input-audiobitrate"
          v-model="validator.audioBitrate.$model"
          :min="16"
          :max="256"
        />
      </KisweFormField>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { KisweInput, KisweFormField, KisweSelect } from 'kiswe-ui'
import { useCanDo } from '@/modules/abilities/compositions'
import { encoderSettingsRules } from '@/modules/user/compositions/useUserProfile'
import useVuelidate from '@vuelidate/core'
import './UserEncoderSettings.scss'
import { WebRtcEncoderSettings } from '@/modules/usermedia/classes'

export default defineComponent({
  name: 'UserEncoderSettings',
  components: {
    KisweFormField,
    KisweInput,
    KisweSelect
  },
  props: {
    encoderSettings: {
      type: Object as () => WebRtcEncoderSettings,
      required: true
    }
  },
  emits: {
    update (_newEncoderSettings: {
      encoderSettings: WebRtcEncoderSettings,
      validator: ReturnType<typeof encoderSettingsRules>
    }) { return true }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props, { emit }) {
    const { canEditCast } = useCanDo()
    const editEncoderSettings = ref(new WebRtcEncoderSettings(props.encoderSettings))
    const maxVideoBitrate = ref(10000)
    const validator = useVuelidate(encoderSettingsRules(maxVideoBitrate.value), editEncoderSettings)

    const resolutionOptions = ref([
      { label: '1080p', value: 1080 },
      { label: '720p', value: 720 },
      { label: '360p', value: 360 }
    ])

    watch(editEncoderSettings, (newVal: WebRtcEncoderSettings) => {
      emit('update', {
        encoderSettings: newVal,
        validator: validator.value
      } )
    }, { deep: true })

    return {
      canEditCast,
      editEncoderSettings,
      maxVideoBitrate,
      resolutionOptions,
      validator
    }
  }
})
</script>
