import { Loader, Result } from 'kiswe-ui'
import { KeyDict } from '.'
import { RegionZone } from './ops'
import { NgcvpSystemLoad } from '@/modules/statistics/types'

export const NGCVP_STATS_MULTIPLIER = 1.25
export const CLIENT_STATS_MULTIPLIER = 1.25

export class NetworkStats {
  bandwidth?: number = 0
  jitter?: number = 0
  latency?: number = 0
  score?: number = 0
  region?: string
}

export class EncoderStats {
  adaptation: 'none' | 'cpu' | 'bandwidth' = 'none'  // FIXME sweep code for this
  audio_bitrate: number = 0
  cpu_usage: number = 0
  fps_in: number = 0
  fps_out: number = 0
  height: number = 0
  video_bitrate: number = 0
  width: number = 0
}

export class UserNetworkStats {
  download?: NetworkStats = new NetworkStats()
  upload?:  NetworkStats = new NetworkStats()
  encoder?: EncoderStats = new EncoderStats()
  /** @deprecated */
  team_id?: string = ''
  userId: string

  constructor (data: unknown) {
    this.userId = Loader.loadString(data, 'id')
    this.team_id = Loader.loadString(data, 'team_id', '')
    this.download = Loader.loadAndCast<NetworkStats>(data, 'download', new NetworkStats())
    this.upload = Loader.loadAndCast<NetworkStats>(data, 'upload', new NetworkStats())
    this.encoder = Loader.loadAndCast<EncoderStats>(data, 'encoder', new EncoderStats())
  }

  static load (data: unknown): Result<UserNetworkStats, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new UserNetworkStats(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  get teamId (): string {
    return this.team_id ?? ''
  }

  set teamId (teamId: string) {
    this.team_id = teamId
  }
}

export class CastMachineStats {
  availability_zone?: RegionZone
  load: NgcvpSystemLoad = {
    cpu: 0, disk: 0, mem: 0, machine: '', net: {},
    max_cpu: 0, max_disk: 0, max_mem: 0,
  }
}

export class NgcvpStats {
  ngcvp_machines: KeyDict<CastMachineStats> = {}
  castId: string

  constructor (data: unknown) {
    this.castId = Loader.loadString(data, 'id')
    this.ngcvp_machines = Loader.loadAndCast<KeyDict<CastMachineStats>>(data, 'ngcvp_machines')
  }

  static load (data: unknown): Result<NgcvpStats, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new NgcvpStats(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  get ngcvpMachines () {
    return this.ngcvp_machines
  }

  get cpu () {
    const cpuValues = Object.values(this.ngcvpMachines).map((machine) => machine.load.cpu)
    return Math.max(...cpuValues)
  }

  get mem () {
    const memValues = Object.values(this.ngcvpMachines).map((machine) => machine.load.mem)
    return Math.max(...memValues)
  }

  get disk () {
    const diskValues = Object.values(this.ngcvpMachines).map((machine) => machine.load.disk)
    return Math.max(...diskValues)
  }
}
