import { Loader } from 'kiswe-ui'
import { KeyDict } from '.'

export class LastBotAction {
  message: string
  db_update: string

  constructor (data: unknown) {
    this.message = Loader.loadString(data, 'message')
    this.db_update = Loader.loadString(data, 'db_update')
  }
}

export class BotAction {
  type: string
  frequency: number

  constructor (data: unknown) {
    this.type = Loader.loadString(data, 'type')
    this.frequency= Loader.loadNumber(data, 'frequency')
  }
}

export class Bot {
  name: string
  cast: string
  last_actions: KeyDict<LastBotAction>
  actions: BotAction[]

  constructor (data: unknown) {
    if (typeof data !== 'object') throw new Error('Cannot load Bot from non-object')
    this.name = Loader.loadString(data, 'name', '')
    this.cast = Loader.loadString(data, 'cast', '')

    const lastActionsData = Loader.loadAndCast<KeyDict<Partial<LastBotAction>>>(data, 'last_actions', {})
    this.last_actions = {}
    for (const [key, actionData] of Object.entries(lastActionsData)) {
        this.last_actions[key] = new LastBotAction(actionData)
    }

    const actionsData = Loader.loadArray<Partial<BotAction>>(data, 'actions', [])
    this.actions = []
    for (const actionData of actionsData) {
      this.actions.push(new BotAction(actionData))
    }
  }
}
