import { KeyDict } from '@/types'
import { Cast } from '@/modules/casts/classes'
import { UserProfile } from '@/types/users'
import { computed, ComputedRef } from 'vue'
import { mapValues, pickBy } from 'lodash'
import { CallTriage } from '@/types/casts'

const useCastProfiles = (cast: ComputedRef<Cast|null>, profiles: ComputedRef<KeyDict<UserProfile>>) => {
  const casters = computed(() => {
    if (cast.value === null) return {}
    const casters = mapValues(cast.value.invited_casters, (caster) => profiles.value[caster.userId])
    return pickBy(casters, (caster) => caster !== undefined)
  })

  const onlineUsers = computed(() => {
    const sessions = pickBy(cast.value?.online_sessions ?? {}, (session) => session.length > 0)
    const users = mapValues(sessions, (_, id) => profiles.value[id])
    return pickBy(users, (user) => user !== undefined)
  })

  const crew = computed(() => {
    if (cast.value === null) return {}
    return pickBy(onlineUsers.value, (profile, id) => {
      return activeInvitedCasters.value[id] === undefined && !profile.anonymous
    })
  })

  const presence = computed(() => cast.value?.online_sessions ?? {})
  const activeInvitedCasters = computed(() => {
    return pickBy(cast.value?.invited_casters ?? {}, (value) => {
      if (value.deleted === true) return false
      if (value.triage === CallTriage.Rejected) return false
      if (value.triage === CallTriage.Assigned) return false
      return true
    })
  })

  return {
    casters,
    onlineUsers,
    crew,
    presence,
    activeInvitedCasters
  }
}

export default useCastProfiles
