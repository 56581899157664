import { Loader, Result } from 'kiswe-ui'
import { Region } from '@/types/ops'

export enum TranscodingHostingMode {
  // @ts-ignore
  Host = true,
  // @ts-ignore
  Guest = false
}
type TranscodingKey = `cast_${string}`
type TranscodingRegions = Partial<Record<Region, TranscodingHostingMode>>
export type Transcoding = Partial<Record<TranscodingKey, TranscodingRegions>>

export class TranscodingLoader {
  static load (data: unknown): Result<Transcoding, 'invalid_params'|'unknown'> {
    try {
      const transcoding: Transcoding = {}
      if (typeof data !== 'object' || data === null) throw new Error('data is not an object')
      Object.entries(data).forEach(([key, value]) => {
        // We need to add this here as the database loader will pass it along as well and we need to ignore it
        if (key === 'id') return
        if (!(key.startsWith('cast_'))) throw new Error(`Transcoding key ${key} is not valid: [cast_{CAST_ID}]`)
        if (typeof value !== 'object' || value === null) throw new Error('Transcoding region is not a valid object')
        transcoding[key as TranscodingKey] = Loader.loadAndCast<TranscodingRegions>(data, key)
      })
      return Result.success(transcoding)
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
