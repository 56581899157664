import { Loader, Result } from 'kiswe-ui'
import { DelayBuffer } from '@/modules/video/classes'
import { KeyDict } from '@/types'
import { isEqual } from 'lodash'

export enum VideoPlayerPlayState {
  LOADING = 'loading',
  PLAYING = 'playing',
  PAUSED = 'paused',
  READY = 'ready'
}

export class VideoPlayerLastClicked {
  stopTime: number = 0
  pauseTime: number = 0
  playTime: number = 0
}

export class VideoPlayerLocalState {
  playstate: VideoPlayerPlayState = VideoPlayerPlayState.LOADING
  playhead: number = 0
}

export interface VideoPlayerActualStateDict {
  videos: KeyDict<boolean>
  video: string | null
  playing: boolean
  paused: boolean
}

export class VideoPlayerActualState implements VideoPlayerActualStateDict {
  videos: KeyDict<boolean> = {}
  video: string | null = null
  playing: boolean = false
  paused: boolean = false

  constructor (data: Partial<VideoPlayerActualStateDict> = {}) {
    this.fromJSON(data)
  }

  fromJSON (data: Partial<VideoPlayerActualStateDict>) {
    if (data.videos) {
      const videos: KeyDict<boolean> = {}
      for (const url in data.videos)
        videos[url] = data.videos[url]
      this.videos = videos
    }
    if (data.video)
      this.video = data.video
    if (data.playing !== undefined)
      this.playing = data.playing
    if (data.paused !== undefined)
      this.paused = data.paused
  }
}

export class VideoPlayerClipProperties {
  cueIn: number = 0
  cueOut: number = 0
}

export enum ClipEndAction {
  Loop = 'loop',  // Loop current clip again after it ends
  Next = 'next',  // Play next clip once the current one ends
  Stop = 'stop'   // Stop current clip when the end is reached
}

export enum PlaybackAction {
  Pause = 'pause',    // Pause current clip
  Resume = 'resume',  // Unpause current clip, or start playing (= default)
  Start = 'start'     // Start playing current clip from the beginning
}

export enum PlaybackEndAction {
  Resume = 'resume',                // Continue playing from the beginning of the playlist, basically a NOOP
  SwitchToPreview = 'switchPreview' // Continue playing, but switch to the current previewSceneId
}

// Based on: https://github.com/Kiswe/ngcvp/blob/07ef790127e5d0eba606e3f84f8ac073c28f517c/ingest/avclip2gdp.py#L279
export enum ClipPlayerTransitionType {
  CLIP_BEGIN = 'clip_begin',
  CLIP_END = 'clip_end',
  CLIP_PAUSE = 'clip_pause',
  CLIP_RESUME = 'clip_resume'
}

export enum ClipType {
  VIDEO = 'video',
  REPLAY = 'replay'
}

// Based on:
// https://github.com/Kiswe/ngcvp/blob/07ef790127e5d0eba606e3f84f8ac073c28f517c/mixer/clip_transition_manager.py#L7
export interface ClipTransitionInfo {
  clip_id: string,
  clip_type: ClipType,
  playlist_id: string,
  pts: number
}

export type ClipTransitionCallback = (type: ClipPlayerTransitionType, payload: string) => void

interface VideoPlayerDesiredStateInterface {
  action: PlaybackAction
  clipProperties: KeyDict<VideoPlayerClipProperties>
  /** @deprecated Prefer use of `action` */
  paused: boolean
  timestamp: number
  video: string
}

export class VideoPlayerDesiredState {
  action: PlaybackAction = PlaybackAction.Resume
  clipProperties: KeyDict<VideoPlayerClipProperties> = {}
  timestamp: number = 0
  video: string = ''

  constructor (data: Partial<VideoPlayerDesiredStateInterface>) {
    this.fromJSON(data)
  }

  fromJSON (data: Partial<VideoPlayerDesiredStateInterface>) {
    if (data.video !== undefined)
      this.video = data.video
    if (data.timestamp !== undefined)
      this.timestamp = data.timestamp
    if (data.clipProperties) {
      this.clipProperties = data.clipProperties
    }
    const paused = Loader.loadBoolean(data, 'paused', false)
    const fallbackAction = paused ? PlaybackAction.Pause : PlaybackAction.Resume
    this.action = Loader.loadString(data, 'action', fallbackAction) as PlaybackAction
  }

  toJSON () {
    return {
      'video': this.video,
      'timestamp': this.timestamp
    }
  }
}

interface VideoPlayerOptions {
  castId?: string|null,
  playheadDelayTime?: number
}

export class VideoPlayer {
  actual_states: KeyDict<VideoPlayerActualState> = {}
  actual_playhead: KeyDict<number> = {}
  desired_state: VideoPlayerDesiredState = new VideoPlayerDesiredState({})
  id: string = ''
  playback_action: PlaybackAction = PlaybackAction.Resume
  playback_end_action: PlaybackEndAction = PlaybackEndAction.Resume
  private delayedPlayheadBuffer: DelayBuffer
  private lastKnownSimultaneousClipId: string|null = null
  castId: string|null = null

  constructor (id: string, options?: VideoPlayerOptions) {
    this.id = id
    this.castId = options?.castId ?? null
    this.delayedPlayheadBuffer = new DelayBuffer(options?.playheadDelayTime ?? 4)
  }

  static load (data: unknown): Result<VideoPlayer, 'invalid_params'|'unknown'> {
    try {
      const videoPlayer = new VideoPlayer(Loader.loadString(data, 'id', ''))
      videoPlayer.fromJSON(data)
      return Result.success(videoPlayer)
    } catch (e) {
      return Result.fromUnknownError('invalid_params', e)
    }
  }

  toJSON () {
    return {
      actual_states: {},
      desired_state: this.desired_state.toJSON(),
      playback_action: this.playback_action,
      playback_end_action: this.playback_end_action
    }
  }

  fromJSON (data: any) {
    if (data.desired_state)
      this.desired_state.fromJSON(data.desired_state)

    const actualStatesUpdate: KeyDict<VideoPlayerActualState> = {}
    if (data.actual_states) {
      for (const machineName in data.actual_states) {
        actualStatesUpdate[machineName] = new VideoPlayerActualState()
        actualStatesUpdate[machineName].fromJSON(data.actual_states[machineName])
      }
    }
    if (!isEqual(this.actual_states, actualStatesUpdate)) {
      this.actual_states = actualStatesUpdate
    }

    const actualPlayheadUpdate: KeyDict<number> = {}
    if (data.actual_playhead) {
      let actual_playhead = 0
      for (const machineName in data.actual_playhead) {
        if (machineName && machineName in this.actual_states) {
          for (const videoName in data.actual_playhead[machineName]) {
            if (videoName === this.actual_states[machineName].video) {
              actual_playhead = data.actual_playhead[machineName][videoName]
            }
          }
        }
        actualPlayheadUpdate[machineName] = actual_playhead
      }
    }
    if (!isEqual(this.actual_playhead, actualPlayheadUpdate)) {
      this.actual_playhead = actualPlayheadUpdate
    }

    this.playback_action = Loader.loadString(data, 'playback_action', PlaybackAction.Resume) as PlaybackAction
    this.playback_end_action = Loader.loadString(data, 'playback_end_action',
      PlaybackEndAction.Resume) as PlaybackEndAction

    this.updateDelayedPlayhead(data?.actual_playhead ?? {})

    return this
  }

  private updateLastKnownSimultaneousClipId () {
    let lastClipId: string|null = null
    let simultaneousCount = 0
    const actualStates = Object.values(this.actual_states)
    for (const actualState of actualStates) {
      if (!actualState.video) continue
      if (actualState.video === lastClipId) {
        simultaneousCount++
      } else {
        lastClipId = actualState.video
        simultaneousCount = 1
      }
    }
    if (simultaneousCount === actualStates.length) this.lastKnownSimultaneousClipId = lastClipId
  }

  private getPlayheadForLastKnownSimultaneousClipIdFromData (dataActualPlayheadMachines: KeyDict<any>) {
    let simultaneousPlayhead = 0
    if (this.lastKnownSimultaneousClipId) {
      const playheads: number[] = []
      for (const playheadsData of Object.values(dataActualPlayheadMachines)) {
        if (typeof playheadsData !== 'object' || playheadsData === null) continue
        for (const [clipId, playhead] of Object.entries(playheadsData)) {
          if (clipId !== this.lastKnownSimultaneousClipId) continue
          if (typeof playhead !== 'number') continue
          playheads.push(playhead)
        }
      }
      if (playheads.length > 0) simultaneousPlayhead = playheads.sort()[0]
    }
    return simultaneousPlayhead
  }

  private updateDelayedPlayhead (dataActualPlayheadMachines: KeyDict<any>) {
    this.updateLastKnownSimultaneousClipId()
    const playheadUpdate = this.getPlayheadForLastKnownSimultaneousClipIdFromData(dataActualPlayheadMachines)
    this.delayedPlayheadBuffer.push(playheadUpdate)
  }

  loadedAssets (): string [] {
    const assetList: string[] = []
    for (const state of Object.values(this.actual_states)) {
      for (const assetid of Object.keys(state.videos)) {
        if (!assetList.includes(assetid)) {
          assetList.push(assetid)
        }
      }
    }
    return assetList
  }

  getPlayhead (assetid: string): number {
    let playhead = -1
    if (this.actual_states) {
      for (const machine in this.actual_states) {
        if (this.actual_states[machine].video === assetid && this.actual_states[machine].playing) {
          const ph = this.actual_playhead[machine]
          if (ph && (ph < playhead || playhead < 0)) {
            playhead = ph
          }
        } else if (this.desired_state.clipProperties[assetid]) {
          playhead = this.desired_state.clipProperties[assetid].cueIn
        }
      }
    }
    if (playhead < 0) playhead = 0
    return playhead
  }

  getDelayedPlayhead (): number {
    return this.delayedPlayheadBuffer.read()
  }

  getState (assetid: string, lastClicked: VideoPlayerLastClicked = new VideoPlayerLastClicked()): VideoPlayerPlayState {
    let lastChangeTime = lastClicked.stopTime
    const lastPlayTime = lastClicked.playTime
    const lastPauseTime = lastClicked.pauseTime
    let lastChangeState = VideoPlayerPlayState.LOADING
    if (lastPlayTime > lastChangeTime) {
      lastChangeTime = lastPlayTime
      lastChangeState = VideoPlayerPlayState.PLAYING
    }
    if (lastPauseTime > lastChangeTime) {
      lastChangeTime = lastPauseTime
      lastChangeState = VideoPlayerPlayState.PAUSED
    }

    if (Date.now() - lastChangeTime < 1500) {
      return lastChangeState
    }

    let numberOfMachineReady: number = 0
    let numberOfMachinePlaying: number = 0
    let numberOfMachinePaused: number = 0
    if (this.actual_states) {
      for (const machine of Object.values(this.actual_states)) {
        if (assetid && machine.videos[assetid]) {
          numberOfMachineReady += 1
        }
        if (machine.video === assetid) {
          if (machine.playing) numberOfMachinePlaying += 1
          if (machine.paused) numberOfMachinePaused += 1
        }
      }
    }
    if (numberOfMachinePaused > 1) {
      return VideoPlayerPlayState.PAUSED
    }
    if (numberOfMachinePlaying > 1) {
      return VideoPlayerPlayState.PLAYING
    }
    return numberOfMachineReady > 1 ? VideoPlayerPlayState.READY : VideoPlayerPlayState.LOADING
  }
}
