import { Cast } from '@/modules/casts/classes'
import { Loader, Result, ResultVoid } from 'kiswe-ui'
import { maybeReplaceAssetBoxContents } from '@/modules/scenes/utils/sceneAssets'
import { AssetType } from '@/types/assets'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import { SceneContent } from '@/types/sceneContent'
import { CastTemplateParameterType, CastTemplateParameterValueType } from './castTemplateParameter'
import { CastTemplateParameterBase } from './castTemplateParameterBase'
import { SceneContentType } from '@/modules/scenes/types'

export class CastTemplateParameterSimpleCG extends CastTemplateParameterBase {
  override type = CastTemplateParameterType.SimpleCG
  override valueType: CastTemplateParameterValueType.Text
  boxId: string

  constructor (data: unknown) {
    super(data)
    // FIXME: Is there a way to let TypeScript know `valueType` is being loaded inside super()?
    // @ts-ignore
    if (this.valueType !== CastTemplateParameterValueType.Text) {
      // @ts-ignore
      throw new Error(`Unsupported CastTemplateParameterValueType ${this.valueType}`)
    }
    this.boxId = Loader.loadString(data, 'boxId')
  }

  async applyUpdate (templateOrCast: CastTemplate|Cast, paramValue: unknown):
                     Promise<ResultVoid<'casttemplate_param_update'>> {
    if (typeof paramValue !== 'string') {
      return Result.fail('casttemplate_param_update', 'Parameter value must be a string')
    }
    const newSceneContent: SceneContent = {
      active: true,
      box: this.boxId,
      id: this.boxId,
      name: this.boxId,
      order: 0,
      simplecgText: paramValue,
      type: AssetType.SimpleCG,
      contentType: SceneContentType.Content
    }
    const forceUpdate = true
    maybeReplaceAssetBoxContents(templateOrCast.scenes, this.boxId, newSceneContent, this.boxId, forceUpdate)

    return Result.ok()
  }

  override isCompatible (param: CastTemplateParameterBase): boolean {
    return super.isCompatible(param) && (param as CastTemplateParameterSimpleCG).boxId === this.boxId
  }
}
