import firebase from 'firebase/compat/app'
import { KeyDict } from "."
import { Region } from './ops'
import { Loader } from 'kiswe-ui'
import { ClipContainerFormatType } from '@/modules/archiver/types'

export enum KENV {
  Dev = 'dev',
  Stage = 'stage',
  Prod = 'prod'
}

export interface CloudCastConfig {
  firebase: {
    apiKey: string,
    authDomain: string,
    databaseURL: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string
  },
  environment: KENV,
  version: string,
  k360_channel_id: string,
  portal_url: string,
  sentry_loc: string
}

export enum AWSRegion {
  UNKNOWN = 'unknown',
  MIDDLE_EAST = 'me-south-1',
  BRASIL = 'sa-east-1',
  FRANKFURT = 'eu-central-1',
  KOREA = 'ap-northeast-2',
  LONDON = 'eu-west-2',
  MUMBAI = 'ap-south-1',
  SINGAPORE = 'ap-southeast-1',
  SIDNEY = 'ap-southeast-2',
  USEAST = 'us-east-1',
  USWEST = 'us-west-1'
}

export enum WRTCServerType {
  JANUS = 'janus',
  WOWZA = 'wowza'
}

export class WRTCServer {
  url: string = ''
  enabled: boolean = true
  type?: WRTCServerType = WRTCServerType.WOWZA
  chinaProxyUrl: string|null = null
  transcode: boolean = false

  constructor (data: any) {
    if (typeof data === 'string') {
      // be backward compatible with how the database used to look like
      this.url = data
      return
    }
    this.url = Loader.loadString(data, 'url', '')
    this.enabled = Loader.loadBoolean(data, 'enabled', true)
    this.type = Loader.loadAndCast<WRTCServerType>(data, 'type', WRTCServerType.WOWZA)
    if (this.url === '') {
      console.error('No url available for WRTCServer while loading')
    }
    this.chinaProxyUrl = Loader.loadStringOrNull(data, 'chinaProxyUrl', null)
    this.transcode = Loader.loadBoolean(data, 'transcode', false)
  }
}

export interface ClosestRegion {
  enabled: boolean
}

export interface TurnOnUntil {
  timestamp: number,
  duration: number
}

export class TranscodingRegion {
  awsregion: AWSRegion = AWSRegion.UNKNOWN
  name: string = ''
  close_regions: KeyDict<ClosestRegion> = {}
  keep_region_warm = false
  chinaProxy = false
  wrtcserver: KeyDict<WRTCServer> = {}
  rtmpserver: string|null = null
  nimbleserver: string|null = null
  visible: boolean = true
  turnOnUntil: TurnOnUntil|null = null


  constructor (data: Partial<TranscodingRegion>) {
    if (typeof data.awsregion === 'string') this.awsregion = data.awsregion
    if (typeof data.name === 'string') this.name = data.name
    if (typeof data.rtmpserver === 'string') this.rtmpserver = data.rtmpserver
    if (typeof data.keep_region_warm === 'boolean') this.keep_region_warm = data.keep_region_warm
    if (typeof data.nimbleserver === 'string') this.nimbleserver = data.nimbleserver
    if (typeof data.chinaProxy === 'boolean') this.chinaProxy = data.chinaProxy
    if (typeof data.visible === 'boolean') this.visible = data.visible
    if (typeof data.wrtcserver === 'object') {
      for (const [key, wowzadata] of Object.entries(data.wrtcserver)) {
        this.wrtcserver[key] = new WRTCServer(wowzadata)
      }
    } else if (typeof data.wrtcserver === 'string') {
      this.wrtcserver.default = new WRTCServer(data.wrtcserver)
    }
    if (typeof data.close_regions === 'object') {
      Object.entries(data.close_regions).forEach(([regionid, data]) => {
        this.close_regions[regionid] = data as ClosestRegion
      })
    }
    if (typeof data.turnOnUntil === 'object') {
      this.turnOnUntil = data.turnOnUntil
    }
  }
}

export class NimbleRegion {
  name: string = ''
  nimbleserver: string|null = null

  constructor (data: any) {
    if (typeof data.name === 'string') this.name = data.name
    if (typeof data.nimbleserver === 'string') this.nimbleserver = data.nimbleserver
  }
}

export class IOSSettings {
  enabled: boolean = true
  mimimumVersion: string = '1'

  constructor (data: any) {
    if (typeof data.enabled === 'boolean') this.enabled = data.enabled
    if (typeof data.minimumVersion === 'string') this.mimimumVersion = data.minimumVersion
  }
}

export enum CastPropertyType {
  Quality = 'quality',
  Framerate = 'framerate',
  Machine = 'machinetype',
  Region = 'region'
}

export enum CastPropertyAudioType {
  Stereo = 'Stereo',
  Surround51 = 'Surround51'
}

interface CastPropertyBase {
  name: string,
  presets: string[],
  type: CastPropertyType
}

interface CastPropertyFramerate extends CastPropertyBase {
  type: CastPropertyType.Framerate
}

interface CastPropertyMachineType extends CastPropertyBase {
  type: CastPropertyType.Machine
}

interface CastPropertyQuality extends CastPropertyBase {
  audioType?: CastPropertyAudioType,
  machineType?: string,
  type: CastPropertyType.Quality
}

interface CastPropertyRegion extends CastPropertyBase {
  media_upload_url: string,
  transcodingregion: Region,
  type: CastPropertyType.Region,
  wrtcserver: string
}

export type CastProperty = CastPropertyFramerate|CastPropertyMachineType|CastPropertyQuality|CastPropertyRegion

export interface LegalInfo {
  tosUrl: string,
  privacyUrl: string,
  tosLastPublished: firebase.firestore.Timestamp,
  privacyLastPublished: firebase.firestore.Timestamp,
}

export interface LibratoConfig {
  token: string,
  user: string
}

export interface Notifications {
  [id: string]: Notification
}

export interface Notification {
  content: string
}

export enum CreateClipType {
  Clip = 'clip',
  Full = 'full',
  Iso = 'iso'
}

interface CreateClipBase {
  k360event: number|string,
  mode: CreateClipType
  message: string|null
  progress_pct: number,
  status: 'new' | 'queue' | 'running'
}

interface CreateClipClip extends CreateClipBase {
  mode: CreateClipType.Clip,
  start: number,
  end: number,
  name: string,
  tracks: string[]|null,
  stream: string,
  clip_id?: string,
  containerFormat?: ClipContainerFormatType
}

interface CreateClipIso extends CreateClipBase {
  mode: CreateClipType.Iso
}

interface CreateClipFull extends CreateClipBase {
  mode: CreateClipType.Full
}

export type CreateClip = CreateClipClip | CreateClipFull | CreateClipIso

export interface MP4Create {
  [castId: string]: KeyDict<CreateClip>
}
