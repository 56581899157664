import store from "@/store"
import { UserMediaState } from "@/store/types"
import { StreamType } from "@/types/streams"
import { MediaDevice } from '@/types/usermedia'

const getters = {
  currentUserCanCast: (state: UserMediaState): boolean => {
    let isCaster = false
    const userId = store.state.user.profile?.assignedId ?? store.state.user.profile?.id
    const cast = store.state.events.currentCast
    if (cast && userId) {
      const caster = cast.invited_casters?.[userId]
      if (caster?.anonymous) isCaster = true
      if (caster && !caster.deleted) isCaster = true
    }
    if (state.isRunningSelftest) {
      isCaster = true
    }

    // TODO: fix below, it is currently causing an infinite loop on initial connect...
    // if (store.state.events.casting && isCaster && store.getters.usermedia.publishType === StreamType.NonCaster) {
    //   console.log('stop casting as noncaster after inviting a talkback user to an event')
    //   store.dispatch.usermedia.killLocalCameraStream()
    // }
    return isCaster
  },
  publishType: (state: UserMediaState): StreamType.Caster|StreamType.Moderator|StreamType.NonCaster => {
    if (store.getters.usermedia.currentUserCanCast) return StreamType.Caster
    return state.isModerator ? StreamType.Moderator : StreamType.NonCaster
  },
  hasPermissions: (state: UserMediaState): boolean => {
    const permissions = state.permissions
    return (permissions.audio && permissions.video) ?? false
  },
  needsCamera (_state: UserMediaState): boolean {
    const isModerator = store.state.usermedia.isModerator
    if (isModerator) return true
    const isModeratorInCast = store.getters.events.currentUserIsModerator(store.state.team.currentTeam)
    const allowedMediaDevices = store.state.events.currentCast?.allowedMediaDevices ?? Object.values(MediaDevice)
    if (!allowedMediaDevices.includes(MediaDevice.Camera) && !isModeratorInCast) {
      return false
    }
    const canCast = store.getters.usermedia.currentUserCanCast
    if ((canCast && store.state.usermedia.supportCamera) || isModeratorInCast) {
      return true
    } else {
      return false
    }
  }
}

export default getters
