import { Result } from 'kiswe-ui'

const dummyCast = <T>() => {
  return (data: unknown): Result<T, 'invalid params'|'unknown'> => {
    if (typeof data !== 'object') {
      return Result.fail('invalid params', 'Data is not an object')
    }
    if (data === null) {
      return Result.fail('unknown', 'Data is null')
    }
    return Result.success(data as unknown as T)
  }
}

export default dummyCast
