import { Layout } from './layouts'
import { SceneContent } from '../types/sceneContent'
import { KeyDict } from '../types'
import { SceneSourceType } from '@/modules/scenes/types'
import { HexCode } from 'kiswe-ui'
import { Loader } from 'kiswe-ui'

export interface SceneSwitchRules {
  numAnonymousGuests?: number
}

export class Scene {
  /**
   * @deprecated
   */
  id: string = ''
  name: string = ''
  initials?: string
  color: HexCode|null = null
  contents: KeyDict<SceneContent> = {}
  scene_layout: Layout = new Layout()
  status?: string
  layout_id: string = ''
  order?: number
  autoPlayClips?: boolean
  switchRules?: SceneSwitchRules
  scenePresetIds: string[] = []
  sourceType?: SceneSourceType

  constructor (data?: unknown) {
    if (data === undefined) return
    this.id = Loader.loadString(data, 'id', this.id)
    this.name = Loader.loadString(data, 'name', this.name)
    const initials = Loader.loadOptionalString(data, 'initials')
    if (initials !== undefined) this.initials = initials
    this.color = Loader.loadHexCodeOrNull(data, 'color', this.color)
    this.contents = Loader.loadKeyDict(data, 'contents', (data: unknown) => data as SceneContent, this.contents)
    this.scene_layout = Loader.loadClass(data, 'scene_layout', (data: unknown) => new Layout(data), this.scene_layout)
    const status = Loader.loadOptionalString(data, 'status')
    if (status !== undefined) this.status = status
    this.layout_id = Loader.loadString(data, 'layout_id', this.layout_id)
    const order = Loader.loadOptionalNumber(data, 'order')
    if (order !== undefined) this.order = order
    const autoPlayClips = Loader.loadOptionalBoolean(data, 'autoPlayClips')
    if (autoPlayClips !== undefined) this.autoPlayClips = autoPlayClips
    const switchRules = Loader.loadAndCast<SceneSwitchRules>(data, 'switchRules', {})
    if (switchRules !== undefined) this.switchRules = switchRules
    this.scenePresetIds = Loader.loadArray(data, 'scenePresetIds', this.scenePresetIds)
    const sourceType = Loader.loadOptionalEnum(data, 'sourceType', Object.values(SceneSourceType))
    if (sourceType !== undefined) this.sourceType = sourceType
  }
}
