import { StreamDoc, StreamVariantType, StreamVariant } from './types/streams'
import { UserAgent } from '@sentry/browser/dist/integrations'
import { Cast } from './modules/casts/classes'
import store from './store'
import { KeyDict } from './types'
import { generateRandomString } from './modules/common/utils'
import { LOCAL_DEV_HOST } from './env'
import { VersionNumber } from './modules/classBuilder/mixins/hasVersion'
import { HexCode, isHexCode } from 'kiswe-ui'
import UAParser from 'ua-parser-js'

export default {
  hexToRGBA (hex: string): { r: number, g: number, b: number, a: number } {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    const a = (hex.length === 9) ? parseInt(hex.slice(7, 9), 16) : 255
    return { r, g, b, a }
  },
  getStreamForVideo (video: StreamDoc, quality: string = 'lowrate'): string {
    let url = ''
    if (video.hasOwnProperty('variants')) {
      if (video.variants.hasOwnProperty(quality)) {
        url = video.variants[quality].url
      } else {
        for (const key in video.variants) {
          if (video.variants[key].hasOwnProperty('url')) {
            url = video.variants[key].url
          }
        }
      }
    }
    const urlSegments = url.split('/')
    return urlSegments[urlSegments.length - 1]
  },
  getVariant (streamDoc: StreamDoc|undefined, variantType: StreamVariantType, transcodingServer: string[]): StreamVariant|null {
    const allowedTypes: StreamVariantType[] = ['original', 'lowratenosrt', 'highratenosrt', 'lowratecaster']
    if (streamDoc?.variants !== undefined) {
      for (const variant of Object.values(streamDoc.variants)) {
        if (variant?.type !== variantType) continue
        if (allowedTypes.includes(variantType) || transcodingServer.includes(variant.server)) {
          return variant
        }
      }
    }
    return null
  },
  getRandomInt (min: number, max: number): number {
    const rmin = Math.ceil(min)
    const rmax = Math.floor(max)
    const val = Math.floor(Math.random() * (rmax - rmin)) + rmin
    return val
  },
  makeId (): string {
    return generateRandomString(12)
  },
  getAnalyticsAgent (): { osVersion: string, deviceType: string} {
    const parser = new UAParser()
    const clientAgent = parser.getResult()
    const device = clientAgent.device.type !== undefined ? clientAgent.device.type + ' ' + clientAgent.device.vendor + ' ' + clientAgent.device.model : 'undefined'
    const osVersion = clientAgent.browser.name + ' ' + clientAgent.browser.version
    const deviceType = clientAgent.os.name + ' ' + clientAgent.os.version + ' ' + device
    return {
      osVersion,
      deviceType
    }
  },
  getAgent (): UserAgent {
    return {
      // @ts-ignore
      browser_codename: navigator.appCodeName,
      browser: navigator.appName,
      cookies_enabled: navigator.cookieEnabled,
      browser_language: navigator.language,
      platform: navigator.platform,
      agent_header: navigator.userAgent,
      is_chrome: window.hasOwnProperty('chrome'),
      chrome_version: this.chromeVersion() ?? {},
      screen: {
        width: screen.width,
        height: screen.height,
        colors: screen.colorDepth
      },
      timezone: this.getTimezone(),
      cpus: navigator.hardwareConcurrency ?? 0, //undefined in Safari
      // @ts-ignore
      memory: navigator.deviceMemory ?? 0 //undefined in Safari and FF
    }
  },
  chromeVersion (): { major: number, minor: number, build: number, patch: number } | undefined {
    const pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/)
    if (pieces === null || pieces.length !== 5) {
      return undefined
    }
    const piecesNumbers = pieces.map(piece => parseInt(piece, 10))
    return {
      major: piecesNumbers[1],
      minor: piecesNumbers[2],
      build: piecesNumbers[3],
      patch: piecesNumbers[4]
    }
  },
  getTimezone () {
    const timezone = (new Date()).getTimezoneOffset() / 60
    if (timezone > 0) {
      return 'GMT -' + timezone
    } else {
      return 'GMT +' + (-timezone)
    }
  },
  androidVersion (ua: string) {
    const agent = (ua || navigator.userAgent).toLowerCase()
    let match = agent.match(/android\s([0-9\.]*)/)  // eslint-disable-line
    if (!match) {
      return 0
    }
    const l = match[1].split('.')
    return [parseInt(l[0], 10), parseInt(l[1], 10), parseInt(l[2] || '0', 10)]
  },

  isMac (): boolean {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0
  },
  initialCaps (s: string): string {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  },
  getURL (router: VueRouter, name: string, params: KeyDict<any>): string {
    const route = router?.resolve({ name, params })
    return route?.href ? route.href : ''
  },
  getAtLeastStartDate (obj: { start_date: any }, dateNow?: Date): Date {
    let endDate = dateNow ?? new Date()
    const startDate = obj.start_date.seconds !== undefined ? new Date(obj.start_date.seconds * 1000) : obj.start_date
    if (startDate > endDate) endDate = startDate
    return endDate
  },
  getProgressText (progress: number|undefined): string {
    if (progress === undefined) return 'In Queue'
    return `${progress.toFixed(1)}%`
  },
  replaceUrlVersion (url: string, newVersion: VersionNumber|null): string {
    try {
      const urlObj = new URL(url)
      if (urlObj.hostname === LOCAL_DEV_HOST) return url

      let currentVersion: null|string = null
      // '/3.8.0/events' -> ['', '3.8.0', 'events']
      const pathParts = urlObj.pathname.split('/')
      if (pathParts.length >= 2 && pathParts[1].match(/^\d+\.(?:\d+\.)*\d+$/)) {
        currentVersion = pathParts[1]
      }

      if (currentVersion === null && newVersion !== null) {
        urlObj.pathname = `/${newVersion}${urlObj.pathname}`
      } else if (currentVersion !== null && newVersion === null) {
        urlObj.pathname = urlObj.pathname.replace(`/${currentVersion}`, '')
      } else if (currentVersion !== null && newVersion !== null && currentVersion !== newVersion) {
        urlObj.pathname = urlObj.pathname.replace(currentVersion, newVersion)
      }

      return urlObj.toString()
    } catch {
      return ''
    }
  },
  getCastById (castId: string): Cast|null {
    const cast = store.state.casts.filteredCasts[castId]
    if (cast !== undefined) return cast
    const currentCast = store.state.events.currentCast
    if (currentCast?.id === castId) return currentCast
    else return null
  },
  isColorLight (color: HexCode|string|null): boolean {
    if (color === null) return false
    if (!isHexCode(color)) return false
    const rgb = parseInt(color.substring(1), 16)    // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff
    const g = (rgb >>  8) & 0xff
    const b = (rgb >>  0) & 0xff
    const luma = (0.2126 * r) + (0.7152 * g) + (0.0722 * b)  // per ITU-R BT.709
    return luma > 180
  }
}
