import { RootState } from '@/store/types'
import { ActionContext } from 'vuex'
import { MODERATION_QUEUE_SUB_KEY, ModerationQueueContextState } from '../types'
import studio, { CollectionNames } from '@/modules/database/utils'
import { defineModule } from 'direct-vuex'
import store, { moduleActionContext } from '@/store'
import { ModerationGuest } from '../classes/ModerationGuest'
import { KeyDict } from '@/types'

// Store module components
type ModerationQueueContext = ActionContext<ModerationQueueContextState, RootState>

const state = {
  guests: {}
} as ModerationQueueContextState

const getters = {
}

const actions = {
  async subscribeModerationQueue (context: ModerationQueueContext, teamId: string) {
    const { commit } = moderationQueueModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: `${MODERATION_QUEUE_SUB_KEY}_${teamId}`,
      query: studio.db.collection(CollectionNames.MODERATION_QUEUE)
        .where('teamId', '==', teamId),
      callback: commit.updateModerationQueue
    })
    result.logIfError('subscribeModerationQueue')
  },
  async unsubscribeModerationQueue (_context: ModerationQueueContext, teamId: string) {
    const key = `${MODERATION_QUEUE_SUB_KEY}_${teamId}`
    studio.subscriptions.unsubscribe(key, () => { void store.dispatch.moderationQueue.cleanup() })
  },
  cleanup (context: ModerationQueueContext) {
    const { commit } = moderationQueueModuleActionContext(context)
    commit.cleanup()
  }
}

const mutations = {
  updateModerationQueue (state: ModerationQueueContextState, snapData: KeyDict<ModerationGuest>) {
    if (snapData) {
      state.guests = snapData
    } else {
      console.warn('Received empty ModerationGuest list. Ignoring')
    }
  },
  cleanup (state: ModerationQueueContextState) {
    state.guests = {}
  }
}

const moderationQueueModule = defineModule({
  namespaced: true,
  state,
  getters,
  mutations,
  actions
})

export default moderationQueueModule
export const moderationQueueModuleActionContext = (context: ModerationQueueContext) =>
  moduleActionContext(context, moderationQueueModule)
