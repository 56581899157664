import { Result } from 'kiswe-ui'
import { isObjectWithKey, KeyDict } from '@/types'
import { CastTemplateParameterBox } from './castTemplateParameterBox'
import { CastTemplateParameterSimpleCG } from './castTemplateParameterSimpleCG'

export const getOnlyParamsWithValue = (params: KeyDict<CastTemplateParameter>|undefined):
                                       CastTemplateParametersWithValue => {
  const filteredParams: CastTemplateParametersWithValue = {}
  if (params) {
    for (const [paramId, param] of Object.entries(params)) {
      if (param.value) {
        // FIXME: This cast shouldn't be needed, but at the time of writing TypeScript doesn't see that, because
        //        of the if-test, the value exists on the object (it only seems to look at the type itself)
        filteredParams[paramId] = param as CastTemplateParameter & { value: unknown }
      }
    }
  }
  return filteredParams
}

export const loadCastTemplateParameterType = (data: unknown, key: string,
                                       defaultValue: CastTemplateParameterType|undefined = undefined
                                      ): CastTemplateParameterType => {
  if (isObjectWithKey(data, key)) {
    const value = data[key] as CastTemplateParameterType
    if (Object.values(CastTemplateParameterType).includes(value)) {
      return value
    }
  }
  if (defaultValue !== undefined) {
    return defaultValue
  }
  throw new Error(`Could not load data, expected ${key} to be of type loadCastTemplateParameterValueType`)
}

export const loadCastTemplateParameterValueType = (data: unknown, key: string,
                                            defaultValue: CastTemplateParameterValueType|undefined = undefined
                                           ): CastTemplateParameterValueType => {
  if (isObjectWithKey(data, key)) {
    const value = data[key] as CastTemplateParameterValueType
    if (Object.values(CastTemplateParameterValueType).includes(value)) {
      return value
    }
  }
  if (defaultValue !== undefined) {
    return defaultValue
  }
  throw new Error(`Could not load data, expected ${key} to be of type loadCastTemplateParameterValueType`)
}

export enum CastTemplateParameterType {
  Box = 'box',
  SimpleCG = 'simplecg'
}

export enum CastTemplateParameterValueType {
  Country = 'country',
  Text = 'text',
  Url = 'url'
}

// TODO: Can we use a better type?
export type CastTemplateParametersWithValue = KeyDict<Partial<CastTemplateParameter> & { value: unknown }>

export type CastTemplateParameter = CastTemplateParameterBox|CastTemplateParameterSimpleCG
export const CastTemplateParameter = {
  load (data: unknown) {
    if (!isObjectWithKey(data, 'type')) return Result.fail('load_casttemplate_param', 'Data is missing key: type')
    try {
      switch (data.type) {
        case CastTemplateParameterType.Box:
          return Result.success(new CastTemplateParameterBox(data))
        case CastTemplateParameterType.SimpleCG:
          return Result.success(new CastTemplateParameterSimpleCG(data))
        default:
          return Result.fail('load_casttemplate_param', `Unsupported type ${data.type}`)
      }
    } catch (error) {
      return Result.fromUnknownError('load_casttemplate_param', error)
    }
  }
}
