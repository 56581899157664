import { AbilitiesState, RootState } from '@/store/types'
import store, { moduleActionContext } from '@/store'
import { ActionContext } from 'vuex'
import { TalkbackState } from '@/store/types'
import { PossibleTalkbackTargets, TalkbackTargetType } from '@/types/streams'
import { CollectionNames } from '@/modules/database/utils/collectionNames'
import { defineModule } from 'direct-vuex'
import { MergeOption } from '@/modules/database/utils/database'
import studio from '@/modules/database/utils'

type TalkbackContext = ActionContext<AbilitiesState, RootState>

const doTalkback = async (context: TalkbackContext, target: PossibleTalkbackTargets) => {
  const currentCasterStreamId = store.state.events.currentCasterStream
  if (currentCasterStreamId === null) throw new Error('Cannot start talkback if currentCasterStream is null')

  const { commit } = talkbackModuleActionContext(context)
  commit.setTarget(target)
  commit.enableMicrophone(true)

  const result = await studio.db.collection(CollectionNames.STREAMS).doc(currentCasterStreamId).set({
    talkbackTarget: target
  }, MergeOption.MERGE)
  result.logIfError('Could not start Talkback')
}

const talkbackModule = defineModule({
  namespaced: true,
  state: {
    previousTarget: TalkbackTargetType.NONE,
    target: TalkbackTargetType.NONE
  } as TalkbackState,
  actions: {
    async startTalkback (context: TalkbackContext, target: Exclude<PossibleTalkbackTargets, TalkbackTargetType.CREW>) {
      const { commit, state } = talkbackModuleActionContext(context)
      commit.setPreviousTarget(state.target)
      await doTalkback(context, target)
    },
    async stopTalkback (context: TalkbackContext) {
      const { commit, dispatch, state } = talkbackModuleActionContext(context)
      const currentCasterStreamId = store.state.events.currentCasterStream
      if (currentCasterStreamId === null) throw new Error('Cannot stop talkback if currentCasterStream is null')

      const shouldSwitchToPreviousTarget = (state.previousTarget === TalkbackTargetType.CREW)
        || (state.target === TalkbackTargetType.ALL && state.previousTarget !== TalkbackTargetType.NONE)

      commit.setTarget(TalkbackTargetType.NONE)
      // The isConnected check is added to handle an edge case where on disconnect, ConnectionTabs would call both
      // stopTalkback() and stopCrewTalkback() when crew talkback was active. Which could cause this async
      // startCrewTalkback() to be called after stopCrewTalkback() got called, which could result in an incorrect state.
      if (shouldSwitchToPreviousTarget && store.state.usermedia.isConnected) {
        await dispatch.startTalkback(state.previousTarget)
        return
      }

      const result = await studio.db.collection(CollectionNames.STREAMS).doc(currentCasterStreamId).set({
        talkbackTarget: TalkbackTargetType.NONE
      }, MergeOption.MERGE)
      result.logIfError('could not stop talkback')
      try {
        if (store.state.usermedia.localCameraStream !== null) {
          commit.enableMicrophone(false)
        }
      } catch (error) {
        console.warn('Stopping microphone while stream already stopped, ignored', error)
      }
    },
    async startCrewTalkback (context: TalkbackContext) {
      const { state, dispatch } = talkbackModuleActionContext(context)
      if (state.target !== TalkbackTargetType.CREW) {
        await dispatch.startTalkback(TalkbackTargetType.CREW)
      }
    },
    async stopCrewTalkback (context: TalkbackContext) {
      const { dispatch, state } = talkbackModuleActionContext(context)
      if (state.target === TalkbackTargetType.CREW) {
        await dispatch.stopTalkback()
      }
    },
    clearTalkback (context: TalkbackContext) {
      const { commit } = talkbackModuleActionContext(context)
      commit.setTarget(TalkbackTargetType.NONE)
      commit.setPreviousTarget(TalkbackTargetType.NONE)
      try { commit.enableMicrophone(false) } catch {}
    }
  },
  mutations: {
    setPreviousTarget (state: TalkbackState, target: PossibleTalkbackTargets|TalkbackTargetType.NONE) {
      state.previousTarget = target
    },
    setTarget (state: TalkbackState, target: PossibleTalkbackTargets|TalkbackTargetType.NONE) {
      state.target = target
    },
    enableMicrophone (_state: TalkbackState, enabled: boolean) {
      if (store.getters.events.currentUserIsACaster) return
      const stream = store.state.usermedia.localCameraStream
      if (stream === null) {
        throw new Error(`Talkback store module: Could not update stream to ${enabled}, it is null`)
      }
      if (stream.getAudioTracks().length === 0) {
        throw new Error(`Talkback store module: could not update stream to ${enabled}, no audio tracks`)
      }
      stream.getAudioTracks()[0].enabled = enabled
    }
  }
})

export default talkbackModule
export const talkbackModuleActionContext = (context: TalkbackContext) => moduleActionContext(context, talkbackModule)
