export class ExportableClass {
  toDict (): Object {
    return JSON.parse(JSON.stringify(this))
  }
}

export interface KeyDict<T> {
  [id: string]: T
}

export type Optional<T> = T|undefined

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
}

export type PartialMap<T,V> = {
  [P in keyof T]?: V;
}

export interface MenuItem {
  label: string,
  action: string,
  icon: string
}

export interface DescriptionListElement {
  term: string,
  description: string
}

export interface SelectOption {
  key: string|null
  label: string
  disabled?: boolean
}
export interface ErrorMessage {
  message: string
  hint?: string
}

export enum BreakoutMode {
  ActionPalette = 'palette',
  PreviewLocal = 'preview'
}

export enum TalkbackMode {
  Global = 'Global',
  Custom = 'Custom'
}

export enum SocialMedia {
  Facebook = 'facebook',
  Youtube = 'youtube',
  Periscope = 'periscope',
  Twitch = 'twitch'
}

export type ObjectKeys<T> =
  T extends object ? (keyof T)[] :
  T extends number ? [] :
  T extends Array<any> | string ? string[] :
  never

// https://stackoverflow.com/a/70029241 https://tsplay.dev/mbGb3W
export function isObjectWithKey<T, K extends string> (obj: T, key: K): obj is T & object & Record<K, unknown> {
  return typeof obj === "object" && obj !== null && key in obj;
}

export type PartialRequired<T, K extends keyof T> = Partial<T> & { [P in K]-?: T[P] }
