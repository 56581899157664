import { KeyDict, TalkbackMode } from '@/types'
import { UserProfile } from '@/types/users'
import { Bot } from '@/types/bots'
import {
  TranscodingRegion, CastProperty, IOSSettings, LegalInfo, LibratoConfig, Notifications, NimbleRegion
} from '@/types/admin'
import { WarningMessage } from '@/classes/Admin/WarningMessage'
import { MemberInvite, TeamGroup } from '@/types/teams'
import { Team, TeamBranding } from '@/modules/teams/classes'
import { Layout } from '@/types/layouts'
import { Billing } from '@/modules/billing/types'
import {
  Stream, SourceStream, StreamDoc, DefaultStream, PossibleTalkbackTargets, TalkbackTargetType, StreamType,
  StreamStatusCode, CustomRtmpDestination, SourceStreamWithCast
} from '@/types/streams'
import { PlaylistStatus, AudioMixType, CastSummary } from '@/types/casts'
import { CastChatMessage } from '@/modules/chat/classes'
import { TranscodingMachine, SocialMediaStats, BackupOverview, GlobalAccelerator, SystemStatus } from '@/types/ops'
import { LogMessage, ClientInfo } from '@/types/logs'
import { VideoPlayer, VideoPlayerLocalState, VideoPlayerLastClicked } from '@/types/videoplayer'
import { Asset, AssetGroup, AssetSummary, AssetFolderQuery } from '@/types/assets'
import { CloudCastEvent } from '@/types/events'
import { ChatWidget } from '@/types/chatwidget'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import { NetworkstatsState } from "@/store/modules/networkstats/networkstats.state"
import { StreamsState } from '@/store/modules/streams/streams.state'
import { TeamRole } from '@/abilitiesByRole'
import { Feature } from '@/featureFlags/featureFlags'
import FirebasePresence from '@/classes/FirebasePresence'
import { ConnectStatus, DeviceList, Resolution, UserMediaPermissions } from '@/types/usermedia'
import CastFilter from '@/classes/CastFilter'
import { MixerTrackLabelWithDefault } from '@/modules/audio/types'
import { Cast } from '@/modules/casts/classes'
import { APIToken } from '@/modules/api/utils'
import { Release } from '@/modules/ops/types/release'
import { RenderMode } from '@/modules/scenes/types'
import { Marker } from '@/modules/markers/classes/Marker'
import { DatabaseSubscription } from '@/modules/database/utils/databaseQuerySubscriptions'
import { ActionPaletteTabs, UIContext } from '@/modules/ui/types'
import { ActiveLocalAudio, AudioContext } from '@/types/audio'
import { CastFanRoom } from '@/modules/casts/classes/CastFanRoom'
import { Olympics } from '@/modules/olympics/classes'
import { WrtcServerStat } from '@/modules/transcodingregions/types'
export type UnsubscribeFunction = () => void

export interface UserState {
  defaultPageOnLogin: string|null,
  deviceId: string|null
  displayName?: string
  email: string,
  firebaseLogin: boolean,
  forceScreenModeEnabled: boolean,
  id: string|null,
  isDumping: boolean,
  legalAgreements: {
    lastUpdateDialog: number|null
    privacyVersion: number,
    tosVersion: number
  },
  loginComplete: boolean,
  needRepublish: boolean,
  notification?: string
  online: boolean,
  presence: FirebasePresence|null
  profile: UserProfile|null,
  sessionId: string,
  showProfile: boolean,
  teamCheck: boolean|null,
  teamId: string|null,
  teamRole: string,
  teams: KeyDict<Team>,
  userHasNoTeams: boolean,
  userLoggedIn: boolean,
}

export interface BotsState {
  bots: KeyDict<Bot>
}

export interface AdminState {
  featureFlags: Map<Feature, boolean>,
  hasLoadedFeatureFlags: boolean,
  iOS: IOSSettings,
  legalinfo: LegalInfo,
  librato: LibratoConfig|null,
  nimbleregions: KeyDict<NimbleRegion>,
  notifications: Notifications|null,
  olympics: Olympics|null,
  release: Release|null,
  transcodingregions: KeyDict<TranscodingRegion>
}

export interface ArchiverTask {
  [id: string]: {
    k360event: number
    mode: string
    progress_pct?: number
    stream?: string
    clip_id?: string
  }
}

export interface TalkbackState {
  previousTarget: PossibleTalkbackTargets|TalkbackTargetType.NONE,
  target: PossibleTalkbackTargets|TalkbackTargetType.NONE
}

export interface OpsMonitorState {
  archiverQueue: KeyDict<ArchiverTask>,
  archivingCasts: KeyDict<Cast>,
  backupOverview: BackupOverview,
  brokenArchivedCasts: KeyDict<Cast>
  debugCastScenes: boolean
  globalAccelerator: GlobalAccelerator,
  opsmonitorcasts: KeyDict<Cast>,
  pendingSupportRequests: Cast[],
  socialMediaMonitor: SocialMediaStats|null,
  srtstreams: { [streamId: string]: Stream },
  streams: { [streamId: string]: Stream },
  transcodingMachines: KeyDict<TranscodingMachine>,
  users: KeyDict<UserProfile>,
  wrtcstats: KeyDict<WrtcServerStat>
}

export class VideoPlayerState {
  autoStartPlaybackRequested: boolean = false
  currentSelectedClip: KeyDict<string|null> = {}
  lastClicked: KeyDict<KeyDict<VideoPlayerLastClicked>> = {}
  localStates: KeyDict<KeyDict<VideoPlayerLocalState>> = {}
  videoPlayerStatus: KeyDict<SystemStatus> = {}
  videoPlayers: KeyDict<VideoPlayer> = {}
}

// TODO: move to /modules/hotkeys/types
export enum HotkeysConnectionStatus {
  BUSY = 'busy',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  CONNECTING = 'connecting'
}

export interface HotkeyState {
  connectionState: HotkeysConnectionStatus,
  initialized: boolean,
  mediaButtonTargetScene: 'program'|'preview'
  uiContext: UIContext
}

export interface TeamState {
  activeTeam: string,
  allTeams: KeyDict<Team>,
  apiTokens: KeyDict<APIToken>,
  billing: Billing,
  castProperties: KeyDict<CastProperty>,
  currentTeam: KeyDict<UserProfile>,
  customTheme: string|null,
  defaultStreams: DefaultStream[],
  defaultTeamLayout: string,
  invites: MemberInvite[],
  isFeatureLoaded: boolean,
  mixerPresetsOverrides: KeyDict<string>
  streams: KeyDict<Stream>,
  streamsTeamId: string|null,
  team: Team|null,
  teamGroups: TeamGroup[]|undefined,
  teamLayouts: Layout[],
  teamLoaded: boolean,
  teamSize: number,
  version: string,
  warningMessages: KeyDict<WarningMessage>,
  tempBranding: TeamBranding|null // when user is not logged in
}

export interface LogsState {
  clientInfo: ClientInfo,
  messages: LogMessage[]
}

export interface UserMediaState {
  autoConnect: boolean,
  extraChinaWrtcServer: string|null,
  checkedResolution: number,
  connectStatus: ConnectStatus,
  constraints: MediaStreamConstraints,
  currentRate: number,
  deleteOnCastCleanup: boolean,
  deviceList: DeviceList|null,
  hasDeviceError: boolean,
  forceChinaProxy: boolean,
  isConnected: boolean,
  isDetectingDevices: boolean,
  isModerator: boolean,
  isRunningSelftest: boolean,
  isSelfMuted: boolean,
  isSelfTestInSessionDone: boolean,
  isStreamReady: boolean,
  localCameraStream: MediaStream|null,
  localDesktopStream: MediaStream|null,
  permissions: UserMediaPermissions,
  remoteStreamSrcUrl: string|null,
  streamDocId: string|null,
  // streamType: StreamType|null,
  supportCamera: boolean,
  supportedResolutions: Resolution[],
  wrtcServer: string|null,
  wrtcServerChina: string|null,
  wrtcServerChinaProxy: string|null
}

export enum LocalAudioSourceType {
  Preview = 'preview',
  Program = 'program',
  Scene = 'scene',
  Tracks = 'tracks'
}

interface LocalAudioSourceBase {
  type: LocalAudioSourceType
}
interface LocalAudioSourcePreview extends LocalAudioSourceBase {
  type: LocalAudioSourceType.Preview
}
interface LocalAudioSourceProgram extends LocalAudioSourceBase {
  type: LocalAudioSourceType.Program
}
interface LocalAudioSourceScene extends LocalAudioSourceBase {
  sceneId: string,
  type: LocalAudioSourceType.Scene
}
interface LocalAudioSourceTracks extends LocalAudioSourceBase {
  trackLabels: MixerTrackLabelWithDefault[]
  type: LocalAudioSourceType.Tracks
}

export type LocalAudioSource = LocalAudioSourcePreview | LocalAudioSourceProgram | LocalAudioSourceScene
  | LocalAudioSourceTracks

export interface EventsState {
  actionPaletteTab: ActionPaletteTabs,
  activeAudioTracks: { [castId: string]: { [key in MixerTrackLabelWithDefault]?: boolean }}, // Crew local active tracks
  activeCasts: KeyDict<CastSummary>,
  allevents: CloudCastEvent[],
  assets: Asset[],
  audioInit: boolean,
  audioMix: AudioMixType,
  castReady: boolean,
  casting: boolean,
  castingAs: StreamType.Caster|StreamType.NonCaster|StreamType.Moderator|null,
  cefEvent: boolean,
  cefMixer: string,
  cefMode: RenderMode,
  completedEvents: CloudCastEvent[],
  completedEventsLoaded: boolean,
  currentCast: Cast|null,
  currentCastId: string|null,
  currentCasterStream: string|null,
  currentPlaylist: PlaylistStatus,
  customStreams: KeyDict<CustomRtmpDestination>,
  editPresetId: string|null,
  editSceneId: string|null,
  events: CloudCastEvent[],
  eventsLoaded: boolean,
  fullscreen: boolean,
  inCast: boolean,
  isPreviewingOutput: boolean,
  liveEditVolumes: KeyDict<number>,
  localGlobalVolume: number,
  monitorMutes: KeyDict<boolean>,
  monitorVolumes: { [id: string]: number },
  onlineUsers: string[],
  openScene: string|null,
  outputStreams: { [streamId: string]: Stream },
  preloadSourceStreams: KeyDict<SourceStream>,
  programVolumes: KeyDict<number>,
  sceneFilter: string,
  sceneInPreview: string|null,
  selfMute: boolean,
  simpleMode: boolean,
  sourceStreams: KeyDict<SourceStreamWithCast>,
  streamDocs: KeyDict<StreamDoc>,
  streamImages: KeyDict<string>,
  streamNames: KeyDict<string>,
  talkbackGlobalMute: boolean,
  talkbackGlobalVolume: number,
  talkbackMode: TalkbackMode,
  talkbackMutes: { [id: string]: boolean },
  talkbackVolumes: { [id: string]: number },
  watermark?: boolean // gets watermarked output etc
}

export interface AudioPanelState {
  audioContextStates: KeyDict<ActiveLocalAudio>|null,
  activeAudioContext: AudioContext|null
}

export interface CastsState {
  allActiveFanRooms: KeyDict<CastFanRoom>,
  currentCast: Cast|null, // TODO: use this in cast page iso EventsState currentCast
  filteredCasts: KeyDict<Cast>,
  filters: KeyDict<CastFilter>,
  processSourceStreams: boolean,
  subscribedCasts: KeyDict<Cast>
}

export interface ChatWidgetState {
  id: string|null,
  widget: ChatWidget
}

export class AssetsState {
  assetGroupAssets: KeyDict<KeyDict<Asset>> = {}
  assetGroupList: KeyDict<KeyDict<AssetSummary>> = {}
  assetGroupSubscribes: KeyDict<DatabaseSubscription<KeyDict<AssetGroup>>> = {}
  folderAssetsLoading: boolean = false
  folderList: KeyDict<AssetGroup> = {}
  folderQuery: AssetFolderQuery|null = null
  initializing: boolean  = false
  openFolders: string[] = []
  sceneAssets: KeyDict<Asset> = {}
  uploadingAssets: KeyDict<KeyDict<number>> = {}
  selectedFolder: string = ''
  subFolders: KeyDict<AssetGroup> = {}
  subscribedAssets: KeyDict<Asset> = {} // Assets subscribed to via subscribeAsset()
}

export interface LayoutsState {
  layouts: Layout[]
}

export interface AbilitiesState {
  teamRoles: KeyDict<TeamRole>,
  teamRolesReady: boolean
}

export interface CastTemplatesState {
  templates: KeyDict<CastTemplate>
}

// TODO: move to /modules/.../types
export type UserCastStatus = {
  hasVariants?: boolean,
  isCaster: boolean,
  isJanus: boolean,
  isPresent: boolean,
  ngcvpStatus?: SystemStatus,
  sourceStreamStartTime?: number|null,
  statusCode: StreamStatusCode
}

export interface StatusState {
  userState: KeyDict<KeyDict<UserCastStatus>> // <userId>: { <castId>: { state: ... } }
}

export interface ChatState {
  chatLastSeen: number,
  chatOpen: boolean,
  messages: KeyDict<CastChatMessage[]>
}

export interface MarkerState {
  markers: KeyDict<Marker>
}

export interface StartStopRequests {
  request_on: string[][],
  requested_by: string
}

export interface StartStopMachine {
  always_on: boolean
  aws_state: string
  desired_state: string
  idle_time: number
  instance_id: string
  region: string
}

export interface StartStopState {
  all_services_healthy: boolean,
  managed_machines: {
    [key: string]: StartStopMachine
  },
  pollTimer: any|undefined,
  requests: StartStopRequests[],
  services_healthy: string[],
  services_to_look_for: string[],
  services_unhealthy: string[]
}

export interface RootState {
  abilities: AbilitiesState,
  admin: AdminState,
  assets: AssetsState,
  audio: AudioPanelState,
  bots: BotsState,
  chatwidget: ChatWidgetState,
  events: EventsState,
  hotkeys: HotkeyState,
  layouts: LayoutsState,
  logs: LogsState,
  markers: MarkerState,
  networkStats: NetworkstatsState,
  opsmonitor: OpsMonitorState,
  startstop: StartStopState,
  status: StatusState,
  streams: StreamsState,
  team: TeamState,
  user: UserState,
  usermedia: UserMediaState,
  videoplayer: VideoPlayerState
}
