import { Loader, Result } from 'kiswe-ui'
import { JanusStat } from '../classes/JanusStat'
import { WowzaStat } from '../classes/WowzaStat'
import { WRTCServerType } from '@/types/admin'

export type WrtcServerStat = JanusStat|WowzaStat

export const WrtcServerStat = {
  load (data: unknown): Result<WrtcServerStat, 'invalid_params'|'unknown'> {
    try {
      const serverType = Loader.loadEnum(data, 'serverType', WRTCServerType.WOWZA, Object.values(WRTCServerType))
      if (serverType === WRTCServerType.WOWZA) return Result.success(new WowzaStat(data))
      if (serverType === WRTCServerType.JANUS) return Result.success(new JanusStat(data))
      return Result.fromUnknownError('unknown', 'Not supported server Type')
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
