/* eslint-disable max-lines */
import fb from '@/firebase'
import { Result, ResultVoid } from 'kiswe-ui'
import { KeyDict } from '@/types'
import firebase from 'firebase/compat/app'
import { Order, QueryInfo } from '../database'
import DatabaseInterface from '../databaseInterface'

type UnsubscribeFunction = () => void

// eslint-disable-next-line complexity
const convertDataWithFlags = (data: unknown): unknown => {
  if (typeof data !== 'object') return data
  if (data === null) return data
  if ('databaseAction' in data) {
    // @ts-ignore
    if (data.databaseAction === 'addToArray' && 'value' in data) {
      // @ts-ignore
      return fb.arrayUnionField(data.value)
    }
    // @ts-ignore
    if (data.databaseAction === 'removeFromArray' && 'value' in data) {
      // @ts-ignore
      return fb.arrayRemoveField(data.value)
    }
    // @ts-ignore
    if (data.databaseAction === 'deleteField') {
      return fb.deleteField
    }
  }
  for (const [key, value] of Object.entries(data)) {
    // @ts-ignore
    data[key] = convertDataWithFlags(value)
  }
  return data
}

const orderToFirestoreOrder = (order: Order) => order === Order.DESCENDING ? 'desc' : 'asc'

export class FirebaseDatabase extends DatabaseInterface {
  firestore: firebase.firestore.Firestore
  constructor (firestore: firebase.firestore.Firestore) {
    super()
    this.firestore = firestore
  }

  override async get (collectionName: string, docId: string): Promise<Result<unknown, 'database'>> {
    try {
      const doc = await this.firestore.collection(collectionName).doc(docId).get()
      if (!doc.exists) {
        return Result.fail('database', `Document with id ${docId} not found in collection ${collectionName}`)
      }
      return Result.success(doc.data())
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
      return Result.fail('database', `Unknown error from the database ${error}`)
    }
  }

  override async set (collectionName: string, docId: string, data: unknown): Promise<ResultVoid<'database'>> {
    try {
      await this.firestore.collection(collectionName).doc(docId).set(data as firebase.firestore.DocumentData)
      return Result.ok()
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
      return Result.fail('database', 'Unknown error from the database')
    }
  }

  override async update (collectionName: string, docId: string, data: unknown): Promise<ResultVoid<'database'>> {
    try {
      await this.firestore.collection(collectionName).doc(docId).update(data as firebase.firestore.DocumentData)
      return Result.ok()
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
      return Result.fail('database', 'Unknown error from the database')
    }
  }

  override async merge (collectionName: string, docId: string, data: unknown): Promise<ResultVoid<'database'>> {
    try {
      const convertedData = convertDataWithFlags(data) as firebase.firestore.DocumentData
      await this.firestore.collection(collectionName).doc(docId).set(convertedData, { merge: true })
      return Result.ok()
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
      return Result.fail('database', 'Unknown error from the database')
    }
  }

  override async delete (collectionName: string, docId: string): Promise<ResultVoid<'database'>> {
    try {
      await this.firestore.collection(collectionName).doc(docId).delete()
      return Result.ok()
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
      return Result.fail('database', 'Unknown error from the database')
    }
  }

  private constructFirestoreQueryRef (collectionName: string, query: QueryInfo[]) {
    const col = this.firestore.collection(collectionName)
    const first = query[0]
    let ref: firebase.firestore.Query<firebase.firestore.DocumentData>
    if (first === undefined) ref = col
    else if (first.type === 'key') ref = col.where(first.key, first.operation, first.value)
    else if (first.type === 'order') ref = col.orderBy(first.key, orderToFirestoreOrder(first.order))
    else ref = col.limit(first.limit)
    if (first !== undefined) {
      query.shift()
      query.forEach((q) => {
        if (q.type === 'key') ref = ref.where(q.key, q.operation, q.value)
        else if (q.type === 'order') ref = ref.orderBy(q.key, orderToFirestoreOrder(q.order))
        else if (q.type) ref = ref.limit(q.limit)
      })
    }
    return ref
  }

  override async query (collectionName: string, query: QueryInfo[]): Promise<Result<KeyDict<unknown>, 'database'>> {
    try {
      const ref = this.constructFirestoreQueryRef(collectionName, query)
      const docs = await ref.get()
      const data: KeyDict<unknown> = {}
      docs.forEach((doc) => { data[doc.id] = doc.data() })
      return Result.success(data)
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
    }
    return Result.fail('database', 'Unknown error from the database')
  }

  override querySnapshot (collectionName: string, query: QueryInfo[], callback: (data: KeyDict<unknown>) => void):
      Result<UnsubscribeFunction, 'database'> {
    try {
      const ref = this.constructFirestoreQueryRef(collectionName, query)
      const unsub = ref.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
        // const markNameStart = `querySnapshot_${collectionName}-start`
        // const markNameEnd = `querySnapshot_${collectionName}-end`
        // const measureName = `querySnapshot_${collectionName}`
        // performance.mark?.(markNameStart)

        const data: KeyDict<unknown> = {}
        //@ts-ignore
        snapshot.docs.forEach((doc) => { data[doc.id] = doc.data() })
        callback(data)
        // performance.mark?.(markNameEnd)
        // performance.measure?.(measureName, markNameStart, markNameEnd)
      })
      return Result.success(unsub)
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
    }
    return Result.fail('database', 'Unknown error from the database')
  }

  override docSnapshot (collectionName: string, docId: string, callback: (data: unknown) => void):
      Result<UnsubscribeFunction, 'database'> {
    try {
      const unsub = this.firestore.collection(collectionName)
                                  .doc(docId).onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
        // const markNameStart = `docSnapshot_${collectionName}.${docId}-start`
        // const markNameEnd = `docSnapshot_${collectionName}.${docId}-end`
        // const measureName = `docSnapshot_${collectionName}.${docId}`
        // performance.mark?.(markNameStart)
        if (snapshot.exists) {
          callback(snapshot.data())
        }
        // performance.mark?.(markNameEnd)
        // performance.measure?.(measureName, markNameStart, markNameEnd)
      })
      return Result.success(unsub)
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
    }
    return Result.fail('database', 'Unknown error from the database')
  }

  override async close (): Promise<ResultVoid<'database'>> {
    try {
      // await this.firestore.terminate()   // TODO FIX -> need to make a new app for each test
      return Result.ok()
    } catch (error) {
      if (error instanceof Error) {
        return Result.fail('database', error.message)
      }
      return Result.fail('database', `Unknown error from the database ${error}`)
    }
  }
}
