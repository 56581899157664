import { moduleActionContext } from '@/store'
import state from './team.state'
import getters from './team.getters'
import actions from './team.actions'
import mutations from './team.mutations'
import { ActionContext } from 'vuex'
import { RootState, TeamState } from '@/store/types'
import { defineModule } from 'direct-vuex'

export type TeamContext = ActionContext<TeamState, RootState>

const teamModule = defineModule({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})

export default teamModule
export const teamModuleActionContext = (context: TeamContext) => moduleActionContext(context, teamModule)

