import { KeyDict } from '@/types'
import { Team } from '@/modules/teams/classes'
import CheckFeature from './checkfeature'
import H264StreamingFeature from './features/h264Streaming'
import JanusFeature from './features/janus'
import LiveClippingFeature from './features/liveClipping'
import RtspTranscodingFeature from './features/rtsptranscoding'
import SCTEMarkersFeature from './features/scteMarkers'
import SwitcherAnonymousGuestsFeature from './features/switcherAnoymousGuests'
import FanRoomFeature from './features/fanRoom'
import LayoutFeature from './features/layout'
import SkipSelftestFeature from './features/skipSelftest'
import ChatTranslationsFeature from './features/chatTranslations'
import DashboardFeature from './features/dashboard'
import LiveOutputFeature from './features/liveOutput'
import Uhd4kFeature from './features/uhd4k'

export enum Feature {
  ChatTranslations = 'Chat translations',
  Dashboard = 'Dashboard',
  FanRoom = 'Fan room',
  H264_STREAMING = 'H264',
  JANUS = 'Janus',
  Layout = 'Layout',
  LIVE_CLIPPING = 'Live clipping',
  RTSP_TRANSCODING = 'RTSP',
  Markers = 'Markers',
  SKIP_SELFTEST = 'Skip selftest',
  SWITCHER_ANONYMOUS_GUESTS = 'Anonymous guest',
  LiveOutput = 'Live Output',
  Uhd4k = '4K UHD'
}

export type FeatureMap = {
  [id in Feature]?: boolean
}

const features: Record<Feature, CheckFeature> = {
  [Feature.Dashboard]: new DashboardFeature(),
  [Feature.FanRoom]: new FanRoomFeature(),
  [Feature.H264_STREAMING]: new H264StreamingFeature(),
  [Feature.JANUS]: new JanusFeature(),
  [Feature.Layout]: new LayoutFeature(),
  [Feature.LIVE_CLIPPING]: new LiveClippingFeature(),
  [Feature.RTSP_TRANSCODING]: new RtspTranscodingFeature(),
  [Feature.Markers]: new SCTEMarkersFeature(),
  [Feature.SKIP_SELFTEST]: new SkipSelftestFeature(),
  [Feature.SWITCHER_ANONYMOUS_GUESTS]: new SwitcherAnonymousGuestsFeature(),
  [Feature.ChatTranslations]: new ChatTranslationsFeature(),
  [Feature.LiveOutput]: new LiveOutputFeature(),
  [Feature.Uhd4k]: new Uhd4kFeature()
}

export const hasFeature = (feature: Feature): boolean|null => {
  const checker = features[feature]
  if (checker === undefined) {
    console.warn(`hasFeature: Trying to check for ${feature} -> does not exist`)
    return false
  }
  if (checker.featuresLoaded === false) return null
  return checker.hasFeature()
}

export const featureDescription = (feature: Feature): string => {
  return features[feature].description
}

export const teamHasFeature = (teamId: string, feature: Feature): boolean => {
  const checker = features[feature]
  if (checker === undefined) {
    console.warn(`teamHasFeature: Trying to check for ${feature} -> does not exist`)
    return false
  }
  // this is not a team specific feature
  if (checker.isGlobalFeature()) return false
  return checker.teamHasFeature(teamId)
}

export const updateFeaturesTeamInfo = (teams: KeyDict<Team>, currentTeamId: string) => {
  Object.values(features).forEach(feature => feature.updateTeamInfo(teams, currentTeamId))
}

export const updateGlobalFeatureFlags = (flags: Map<Feature, boolean>) => {
  Object.values(features).forEach(feature => feature.updateGlobalFeatureFlags(flags))
}

export const updateTeamFeature = (teamId: string, feature: Feature, enabled: boolean): void => {
  const checker = features[feature]
  if (checker === undefined) {
    console.warn(`updateTeamFeature: Trying to update for ${feature} -> does not exist`)
  }
  checker.updateTeamFeature(teamId, enabled)
}

export const assignTeamFeature = (team: Partial<Team>, feature: Feature, enabled: boolean): void => {
  const checker = features[feature]
  if (checker === undefined) {
    console.warn(`assignTeamFeature: Trying to update for ${feature} -> does not exist`)
  }
  checker.assignTeamFeature(team, enabled)
}

export const isGlobalFeature = (feature: Feature): boolean => {
  const checker = features[feature]
  if (checker === undefined) {
    console.warn(`isGlobalFeature: Trying to access for ${feature} -> does not exists`)
    return false
  }
  return checker.isGlobalFeature()
}
