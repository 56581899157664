import { NetworkstatsState } from './networkstats.state'
import { UserNetworkStats } from '@/types/networkstats'
import { CefStatInstance } from '@/modules/statistics/classes'
import { CefStats, CefMode } from '@/modules/statistics/types'
import { KeyDict } from '@/types'
import store from '@/store'

const isAliveInstance = (instance: CefStatInstance, now: number, maxAge: number): boolean => {
  const timestamp = instance?.timestamp
  return !!timestamp && (now - timestamp.getTime() < maxAge)
}

const checkInstancesAlive = (instances: KeyDict<CefStatInstance>, instanceData: CefStatInstance|undefined, mode: CefMode): boolean => {
  const maxAge = 60 * 1000
  const now = Date.now()
  if (instanceData && instanceData.cefmode === mode) {
    return isAliveInstance(instanceData, now, maxAge)
  } else {
    const allInstances = Object.values(instances || {})
    return !allInstances.some((instance) => isAliveInstance(instance, now, maxAge))
  }
}

export const getters = {
  userStats: (state: NetworkstatsState) => {
    // legacy code expected download to be undefined
    // that's why at this point we don't simply use new UserNetworkStats()
    const emptyStats = new UserNetworkStats({
      download: undefined,
      upload: undefined,
      encoder: undefined,
      team_id: '',
      id: ''
    })

    return (userId: string): UserNetworkStats|undefined => state.userStats[userId] || emptyStats
  },
  myNetworkStats (state: NetworkstatsState): UserNetworkStats|undefined {
    const id = store.state.user.id
    if (id === null) return undefined
    return state.userStats[id]
  },
  isAlive: (state: NetworkstatsState) => (castId: string, cefMode: string = '', instanceData: CefStatInstance|undefined): boolean => {
    const stats: CefStats|undefined = state.cefStats[castId]
    if (!stats) return false

    const frontAlive = stats.front ? checkInstancesAlive(stats.front.instances, instanceData, CefMode.Front) : false
    const backAlive = stats.back ? checkInstancesAlive(stats.back.instances, instanceData, CefMode.Back) : false

    switch (cefMode) {
      case CefMode.Front:
        return frontAlive
      case CefMode.Back:
        return backAlive
      default:
        return frontAlive && backAlive
    }
  }
}
