import { moduleActionContext } from '@/store'
import { ActionContext } from 'vuex'
import { MarkerState, RootState } from '@/store/types'
import { defineModule } from 'direct-vuex'
import { Marker } from '../classes/Marker'
import { KeyDict } from '@/types'
import studio, { CollectionNames } from '@/modules/database/utils'

type MarkersContext = ActionContext<MarkerState, RootState>

const markersModule = defineModule({
  namespaced: true,
  state: {
    markers: {}
  } as MarkerState,
  actions: {
    async subscribeMarkers (context: MarkersContext, teamId: string) {
      const { commit } = markersModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        key: `markers_${teamId}`,
        query: studio.db.collection(CollectionNames.MARKERS).where('team_id', '==', teamId),
        callback: commit.update
      })
      result.logIfError('Could not subscribe to markers')
    },
    unsubscribeMarkers (_context: MarkersContext, teamId: string) {
      studio.subscriptions.unsubscribe(`markers_${teamId}`)
    },
    async setMarkers (context: MarkersContext, markers: KeyDict<Marker>) {
      const { commit } = markersModuleActionContext(context)
      commit.update(markers)
    }
  },
  mutations: {
    update (state: MarkerState, markers: KeyDict<Marker>) {
      state.markers = markers
    }
  }
})

export default markersModule
export const markersModuleActionContext = (context: MarkersContext) => moduleActionContext(context, markersModule)
