import store from "@/store"
import { Optional } from "@/types"
import { DeviceList, DeviceOption, MediaDevice, MediaType, Resolution, UserMediaPermissions } from "@/types/usermedia"
import { UserMediaDevice, UserProfile } from "@/types/users"
import { cloneDeep } from "lodash"
import { isMobile } from 'mobile-device-detect'
export const availableResolutions = [
  { width: 640, height: 360 },
  { width: 320, height: 180 },
  { width: 160, height: 90 },
  { width: 1280, height: 720 },
  { width: 1920, height: 1080 }
] as Resolution[]

export const constraintToUserMedia = async  (constraints: MediaStreamConstraints): Promise<Optional<SelectedStream>> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints)
    return { stream }
  } catch (error) {
    console.log('Could not connect with constraint', constraints, error)
  }
  return undefined
}

interface SelectedStream {
  stream: MediaStream,
  deviceId?: string
}

export const getCameraStream = async (constraints: MediaStreamConstraints): Promise<Optional<SelectedStream>> => {
  const stream = await constraintToUserMedia(constraints)
  if (stream !== undefined) return stream
  const result = await navigator.mediaDevices.enumerateDevices()
  const audioDevice = result.find(device => device.kind === 'audioinput')?.deviceId
  for (const device of result) {
    if (device.kind === 'videoinput') {
      const newConstraint: MediaStreamConstraints = {
        ...constraints,
        ...{
          video: {
            deviceId: { exact: device.deviceId }
          },
        }
      }
      newConstraint.audio = audioDevice ? { deviceId: { exact: audioDevice } } : true
      const stream = await constraintToUserMedia(newConstraint)
      if (stream !== undefined) {
        return { ...stream, deviceId: device.deviceId }
      }
    }
  }
  return undefined
}

export const getAllMediaPermissions = async (): Promise<UserMediaPermissions> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
    stream.getTracks().forEach((track) => track.stop())
    return { audio: true, video: true }
  } catch (error) {
    throw error
  }
}

export const getMediaPermission = async (device: MediaDevice, userMediaPermissions: UserMediaPermissions): Promise<UserMediaPermissions> => {
  const type = device === MediaDevice.Microphone ? MediaType.Audio : MediaType.Video
  if (userMediaPermissions[type]) return userMediaPermissions
  try {
    const constraints: MediaStreamConstraints = {
      audio: type === MediaType.Audio,
      video: type === MediaType.Video
    }
    // const result = await navigator.permissions.query({ name: device })
    //Consider adding a timeout since nothing will happen and no error thrown if user only dismisses permission dialog
    const stream = await navigator.mediaDevices.getUserMedia(constraints)
    stream.getTracks().forEach((track) => track.stop())
    //If not rejected and no errors, indicate as permitted
    userMediaPermissions[type] = true
  } catch (error: unknown) {
    // This list of broken device errors is created because we only want to check the permissions. If the user granted
    // permissions but one of their devices is broken, we still want to set the permissions correctly.
    const brokenDeviceErrors = [
      'NotReadableError: Could not start video source',
      'NotReadableError: Could not start audio source'
    ]
    if (error instanceof DOMException && brokenDeviceErrors.includes(error.toString())) {
      userMediaPermissions[type] = true
    } else {
      userMediaPermissions[type] = false
      throw error
    }
  }
  return userMediaPermissions
}

export const setupDevice = (device: MediaDeviceInfo): { userMediaDevice: UserMediaDevice|null, deviceOption: DeviceOption|null, media: 'audio'|'video'|null, type: string|null } => {
  const mediaDevices = store.state.usermedia.deviceList ?? new DeviceList()
  let count = 0
  let text = ''
  let userMediaDevice: UserMediaDevice|null = null
  let deviceOption: DeviceOption|null = null
  let media: 'audio'|'video'|null = null
  let type: string|null = null
  switch (device.kind) {
    case 'audioinput':
      count = mediaDevices.audio.input.length + 1
      text = device.label ?? `microphone ${ count }`
      deviceOption = { text, value: device.label, deviceId: device.deviceId }
      userMediaDevice = { type: 'audio', name: text, id: device.deviceId }
      media = 'audio'
      type = 'input'
      break
    case 'videoinput':
      count = mediaDevices.video.input.length + 1
      text = device.label ?? `camera ${ count }`
      deviceOption = { text, value: device.label, deviceId: device.deviceId }
      userMediaDevice = { type: 'video', name: device.label, id: device.deviceId }
      media = 'video'
      type = 'input'
      break
    case 'audiooutput':
      count = mediaDevices.audio.output.length + 1
      text = device.label ?? `speaker ${ count }`
      deviceOption = { text, value: device.label, deviceId: device.deviceId }
      userMediaDevice = { type: 'output', name: device.label, id: device.deviceId }
      media = 'audio'
      type = 'output'
      break
  }
  return { userMediaDevice, deviceOption, media, type }
}

export const getAudioConstraints = (audioSource: string|null): MediaTrackConstraints|boolean => {
  if (audioSource === null) return false
  if (isMobile) {
    return {
      deviceId: audioSource ? { exact: audioSource } : undefined
    }
  }
  return {
    deviceId: audioSource ? { exact: audioSource } : undefined,
    echoCancellation: store.getters.user.hasEchoSuppression,
    // @ts-ignore  -> has this changed?
    noiseSuppression: store.getters.user.hasNoiseSuppression
  }
}

export const getVideoConstraints = (videoSource: string|null): MediaTrackConstraints|boolean => {
  if (videoSource === null) return false
  if (!store.getters.usermedia.needsCamera) return false
  const resolution = getOptimalSupportedResolution(store.state.usermedia.supportedResolutions)
  if (!resolution) {
    console.error('CameraMicControls::onSupportedResolutionFinished: no supported resolutions')
    return false
  }
  const width = resolution.width
  const height = resolution.height
  const constraints = {
    width: { exact: width },
    height: { exact: height },
    deviceId: videoSource ? { exact: videoSource } : undefined,
    frameRate: { max: 30 }
  }
  return constraints
}

export const getOptimalSupportedResolution = (supportedResolutions: Resolution[]): Resolution|null => {
  if (supportedResolutions.length === 0) return null
  const profile = store.state.user.profile
  if (profile === null) console.error('getOptimalSupportedResolution - profile is null')
  const requiredVideoWidth = profile?.encoderSettings?.width ?? null
  const requiredVideoHeight = profile?.encoderSettings?.height ?? null
  const resolution = supportedResolutions.find((resolution: Resolution) => {
    return resolution.width === requiredVideoWidth && resolution.height === requiredVideoHeight
  })
  if (resolution) return resolution
  return supportedResolutions[0]
}

export const updateDefaultDevicesIfNecessary = (profile: Partial<UserProfile>): Partial<UserProfile> => {
  const copyProfile = cloneDeep(profile)
  if (profile === null) console.error('updateDefaultDevicesIfNecessary - profile is null')
  const videoDevices = profile?.devices?.filter(device => device.type === MediaType.Video).map(device => device.id)
  const selectedVideoDevice = store.state.user.profile?.selectedVideoDevice ?? null
  if (selectedVideoDevice === null) {
    copyProfile.selectedVideoDevice = videoDevices?.[0]
  } else if (videoDevices !== undefined && !videoDevices.includes(selectedVideoDevice)) {
    copyProfile.selectedVideoDevice = videoDevices[0]
  }
  const audioDevices = profile?.devices?.filter(device => device.type === MediaType.Audio).map(device => device.id)
  const selectedAudioDevice = store.state.user.profile?.selectedAudioDevice ?? null
  if (selectedAudioDevice === null) {
    copyProfile.selectedAudioDevice = audioDevices?.[0]
  } else if (audioDevices !== undefined && !audioDevices.includes(selectedAudioDevice)) {
    copyProfile.selectedAudioDevice = audioDevices[0]
  }
  if (copyProfile.selectedAudioDevice === undefined) delete copyProfile.selectedAudioDevice
  if (copyProfile.selectedVideoDevice === undefined) delete copyProfile.selectedVideoDevice
  return copyProfile
}
