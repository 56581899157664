import { SocialMedia } from "@/types"
import { StreamRate } from "@/types/streams"
import { toInteger } from "lodash"

export interface StreamRateWithProps extends StreamRate {
  fps: string,
  height: number,
  width: number,
  video_bitrate: number
}

interface StreamRateWithNumberProps extends Omit<StreamRate, 'fps'> {
  fps: number,
  height: number,
  width: number,
  video_bitrate: number
}

export const SocialMediaQuality = {
  getSocialMedia (url: string) {
    if (url.indexOf('rtmp.youtube') !== -1) {
      return 'youtube'
    }
    if (url.indexOf('facebook.com') !== -1) {
      return 'facebook'
    }
    if (url.indexOf('pscp.tv') !== -1) {
      return 'periscope'
    }
    //twitch has also other ingests based on regions https://stream.twitch.tv/ingests
    if (url.indexOf('twitch.tv') !== -1 || url.indexOf('live-video.net') !== -1) {
      return 'twitch'
    }
    return ''
  },
  __convert_fps_to_float (framerate: string) {
    if (framerate.includes('/')) {
      const nomDenom = framerate.split('/')
      return parseFloat(nomDenom[0]) / parseFloat(nomDenom[1])
    }
    return parseFloat(framerate)
  },
  __convert_float_to_fps (framerate: number) {
    if (framerate == Math.round(framerate)) {
      return '' + framerate
    }
    const nom = toInteger(framerate * 100) / 100
    return '' + nom
  },
  getMaxQuality (socialMedia: string, rate: StreamRateWithProps) {
    let newrate: StreamRateWithNumberProps = {
      ...rate,
      fps: this.__convert_fps_to_float(rate.fps)
    }
    newrate.adjusted = false
    switch (socialMedia) {
      case SocialMedia.Facebook:
        newrate = this.__getFacebookMaxQuality(newrate)
        break;
      case SocialMedia.Youtube:
        newrate = this.__getYoutubeMaxQuality(newrate)
        break;
      case SocialMedia.Periscope:
        newrate = this.__getPeriscopeMaxQuality(newrate)
        break;
      case SocialMedia.Twitch:
        newrate = this.__getTwitchMaxQuality(newrate)
        break;
    }
    const returnedRate: StreamRateWithProps = {
      ...newrate,
      fps: this.__convert_float_to_fps(newrate.fps)
    }
    return returnedRate
  },
  __getFacebookMaxQuality (newrate: StreamRateWithNumberProps) {
    // https://www.facebook.com/help/1534561009906955
    if (newrate.height === 1080) {
      if (newrate.fps > 30 && newrate.video_bitrate > 9000) {
        newrate.adjusted = true
        newrate.video_bitrate = 9000
      }
      if (newrate.fps <= 30 && newrate.video_bitrate > 6000) {
        newrate.adjusted = true
        newrate.video_bitrate = 6000
      }
    }
    if (newrate.height === 720) {
      if (newrate.fps > 30 && newrate.video_bitrate > 6000) {
        newrate.adjusted = true
        newrate.video_bitrate = 6000
      }
      if (newrate.fps <= 30 && newrate.video_bitrate > 1000) {
        newrate.adjusted = true
        newrate.video_bitrate = 1000
      }
    }
    // minimal 2s
    newrate.gop_size = 2
    newrate.adjusted = true
    return newrate
  },
  __getPeriscopeMaxQuality (newrate: StreamRateWithNumberProps) {
    // https://help.twitter.com/en/using-twitter/periscope-producer
    if (newrate.fps > 30) {
      // facebook does not support more then 30 fps
      newrate.fps = newrate.fps / 2
      newrate.adjusted = true
    }
    if (newrate.height > 720) {
      // 720p max
      newrate.width = 720 * newrate.width / newrate.height
      newrate.height = 720
      newrate.adjusted = true
    }
    if (newrate.video_bitrate > 4000) {
      // max 4Mbps
      newrate.video_bitrate = 4000
      newrate.adjusted = true
    }
    //3 seconds
    newrate.gop_size = 3
    newrate.adjusted = true
    return newrate
  },
  __getTwitchMaxQuality (newrate: StreamRateWithNumberProps) {
    // https://help.twitch.tv/s/article/guide-to-broadcast-health-and-using-twitch-inspector?language=en_US#BroadcastURLsandStreamKeys
    if ([1080, 900].includes(newrate.height) && newrate.video_bitrate > 6000) {
      newrate.video_bitrate = 6000
      newrate.adjusted = true
    }
    else if (newrate.height === 720 && newrate.fps >= 50 && newrate.video_bitrate > 4500) {
      newrate.video_bitrate = 4500
      newrate.adjusted = true
    }
    else if (newrate.height === 720 && newrate.fps < 50 && newrate.video_bitrate > 3000) {
      newrate.video_bitrate = 3000
      newrate.adjusted = true
    }
    newrate.gop_size = 2
    newrate.adjusted = true
    return newrate
  },
  __getYoutubeMaxQuality (newrate: StreamRateWithNumberProps) {
    // https://support.google.com/youtube/answer/2853702?hl=en
    if (newrate.height === 1080 && newrate.fps >= 50 && newrate.video_bitrate > 9000) {
      newrate.video_bitrate = 9000
      newrate.adjusted = true
    }
    else if (newrate.height === 1080 && newrate.fps < 50 && newrate.video_bitrate > 6000) {
      newrate.video_bitrate = 6000
      newrate.adjusted = true
    }
    else if (newrate.height === 720 && newrate.fps >= 50 && newrate.video_bitrate > 6000) {
      newrate.video_bitrate = 6000
      newrate.adjusted = true
    }
    else if (newrate.height === 720 && newrate.fps < 50 && newrate.video_bitrate > 4000) {
      newrate.video_bitrate = 4000
      newrate.adjusted = true
    }
    newrate.gop_size = 2
    newrate.adjusted = true
    return newrate
  }
}
