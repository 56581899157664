import { computed, ref } from 'vue'
import { browserName, browserVersion, isFirefox, isMobile,
  isMobileOnly, isIOS, isAndroid, osVersion } from 'mobile-device-detect'

/*
  Chrome Headless got added per request of TomJ for testing purposes with Selenium.
  We can spoof this browser by entering the custom user agent for Selenium.
  Copy/paste the user agent string below into the chrome devtools under "network conditions"

  Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/89.0.4389.114 Safari/537.36
*/

export enum Browser {
  CHROME = 'Chrome',
  CHROME_HEADLESS = 'Chrome Headless',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
  MOBILE_SAFARI = 'Mobile Safari',
  EDGE = 'Edge',
  SAMSUNG = 'Samsung',
  OTHER = 'other'
}

export enum BrowserMinVersion {
  CHROME = 79,
  CHROME_HEADLESS = 79,
  FIREFOX = 88,
  SAFARI = 14,
  EDGE = 88,
  SAMSUNG = 14,
  MOBILE_SAFARI = 13
}

export const MIN_ANDROID_VERSION = 8
export const MIN_NUM_OF_CPU = 4
export const MIN_NUM_OF_MEMORY = 4

const useBrowser = () => {

  const isIos = ref(isIOS)

  const browser = computed(() => {
    switch (browserName) {
      case Browser.CHROME: return Browser.CHROME
      case Browser.CHROME_HEADLESS: return Browser.CHROME_HEADLESS
      case Browser.FIREFOX: return Browser.FIREFOX
      case Browser.SAFARI: return Browser.SAFARI
      case Browser.EDGE: return Browser.EDGE
      case Browser.MOBILE_SAFARI: return Browser.MOBILE_SAFARI
      default:
        const isSamsungBrowser = navigator.userAgent.match(/samsungbrowser/i)
        if (isSamsungBrowser) return Browser.SAMSUNG
        else return Browser.OTHER
    }
  })

  // Model detection based on GPU: https://github.com/joyqi/mobile-device-js/blob/master/device.js

  // eslint-disable-next-line complexity
  const isSystemSupported = computed(() => {

    // @ts-ignore
    const numRam = navigator.deviceMemory ?? 0
    const numCpu = navigator.hardwareConcurrency ?? 0
    const isLowRam = isFirefox ? false : numRam < MIN_NUM_OF_MEMORY
    if (!isMobile) return true
    // We currently allow all iOS devices, the support centre will include a document with recommended specs.
    if (isIos.value) return true
    if (Number.parseInt(osVersion) < MIN_ANDROID_VERSION) return false
    if (isLowRam || numCpu < MIN_NUM_OF_CPU) return false

    return true
  })

  // eslint-disable-next-line complexity
  const isBrowserSupported = computed(() => {
    const version = Number.parseInt(browserVersion)
    switch (browser.value) {
      case Browser.CHROME:
        return version >= BrowserMinVersion.CHROME
      case Browser.CHROME_HEADLESS:
        return version >= BrowserMinVersion.CHROME_HEADLESS
      case Browser.FIREFOX:
        return !isMobile && version >= BrowserMinVersion.FIREFOX
      case Browser.SAFARI:
        return version >= BrowserMinVersion.SAFARI
      case Browser.EDGE:
        return !isMobile && version >= BrowserMinVersion.EDGE
      case Browser.SAMSUNG:
        return false
      case Browser.MOBILE_SAFARI:
        return version >= BrowserMinVersion.MOBILE_SAFARI
      default:
        return false
    }
  })

  return {
    browser,
    browserName,
    browserVersion,
    isSystemSupported,
    isBrowserSupported,
    isMobile,
    isMobileOnly,
    isIos,
    isAndroid
  }

}

export default useBrowser
