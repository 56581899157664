import { ResultVoid, Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { QueryInfo } from './database'

type UnsubscribeFunction = () => void

abstract class DatabaseInterface {
  abstract get (collectionName: string, docId: string): Promise<Result<unknown, 'database'>>
  abstract set (collectionName: string, docId: string, data: unknown): Promise<ResultVoid<'database'>>
  abstract update (collectionName: string, docId: string, data: unknown): Promise<ResultVoid<'database'>>
  abstract merge (collectionName: string, docId: string, data: unknown): Promise<ResultVoid<'database'>>
  abstract delete (collectionName: string, docId: string): Promise<ResultVoid<'database'>>
  abstract query (collectionName: string, query: QueryInfo[]): Promise<Result<KeyDict<unknown>, 'database'>>
  abstract querySnapshot (collectionName: string, query: QueryInfo[], callback: (data: KeyDict<unknown>) => void):
              Result<UnsubscribeFunction, 'database'>
  abstract docSnapshot (collectionName: string, docId: string, callback: (data: unknown) => void):
              Result<UnsubscribeFunction, 'database'>
  abstract close (): Promise<ResultVoid<'database'>>
}

export default DatabaseInterface

