
const mbit = 1024 * 1024 * 1024 / 8

const mos2score = (mos: number): 0|1|2|3|4|5 => {
  // according to
  // https://www.voipfuture.com/wordpress/wp-content/uploads/2015/10/VPF123_WP_MOS-Calculation-And-Aggregation.pdf
  if (mos > 4.34) {
    return 5
  } else if (mos > 4.03) {
    return 4
  } else if (mos > 3.6) {
    return 3
  } else if (mos > 3.1) {
    return 2
  } else if (mos > 2.58) {
    return 1
  }
  return 0
}

export const getNetworkScore = (latency: number, jitter: number, packetslost: number = 0) => {
  const effectiveLatency = latency + 2 * jitter + 10
  let R = 0
  if (effectiveLatency < 160) {
    R = 93.2 - (effectiveLatency) / 40
  } else {
    R = 93.2 - (effectiveLatency - 120) / 10
  }
  R = R - 2.5 * packetslost
  let mos = 0
  if (R < 0) {
    mos = 1
  } else if (R < 100) {
    mos = 1 + 0.035 * R + 0.000007 * R * (R - 60) * (100 - R)
  } else {
    mos = 4.5
  }
  return mos2score(mos) || 0
}

export const getScoreBasedOnBandwidth = (bandwidthInMByte: number): number => {
  const minBandwidth = 5 * 0.4 * mbit
  const maxBandwidth = 3 * minBandwidth
  const score = Math.floor((bandwidthInMByte * mbit * 8 - minBandwidth) / (maxBandwidth - minBandwidth))
  const roundedScore = Math.max(Math.min(score, 5), 0)
  return roundedScore
}
