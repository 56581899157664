import { StatusState, RootState, UserCastStatus } from '../types'
import { ActionContext } from 'vuex'
import store, { moduleActionContext } from '@/store'
import { cloneDeep } from 'lodash'
import { StreamStatusCode, StreamType } from '@/types/streams'
import { lazyUpdate } from '../storehelper'
import { getStreamStatus, StreamStatusInfo } from '@/helpers/statusHelper'
import { SystemStatus } from '@/types/ops'
import { defineModule } from 'direct-vuex'
import { Feature, hasFeature } from '@/featureFlags/featureFlags'


type StatusContext = ActionContext<StatusState, RootState>
type UserAndCastId = { userId: string, castId: string }

const defaultStatusState: StatusState = {
  userState: {}
}

const isJanus = function (): boolean {
  return hasFeature(Feature.JANUS) ?? false
}

const statusModule = defineModule({
  namespaced: true,
  state: cloneDeep(defaultStatusState) as StatusState,
  actions: {
    touchUserState (context: StatusContext, payload: UserAndCastId & { isCaster?: boolean, isJanus?: boolean }): void {
      const { commit, state } = statusModuleActionContext(context)
      const { userId, castId, isCaster } = payload
      const userState = state.userState[userId]
      if (userState?.[castId] !== undefined) return
      commit.updateUserState({
        userId,
        castId,
        state: {
          isJanus: isJanus(),
          statusCode: StreamStatusCode.None,
          isPresent: false,
          isCaster: isCaster ?? true
        }
      })
    },
    clearUserState (context: StatusContext): void {
      const { commit } = statusModuleActionContext(context)
      commit.clearUserState()
    },
    clearUserStateForCast (context: StatusContext, payload: UserAndCastId): void {
      const { commit } = statusModuleActionContext(context)
      commit.clearUserStateForCast(payload)
    }
  },
  mutations: {
    updateNetworkStats (state: StatusState, userId: string): void {
      const stats = store.getters.networkStats.userStats(userId)
      if (stats === undefined) {
        console.warn(`No network stats available for caster: ${userId}`)
        return
      }
      const userCasts = state.userState[userId]
      if (userCasts === undefined) return
      for (const [castId, userState] of Object.entries(userCasts)) {
        const isCaster = userState.isCaster ?? true
        const fakeStreamInfo: StreamStatusInfo = {
          type: isCaster ? StreamType.Caster : StreamType.NonCaster,
          ngcvp_status: userState.ngcvpStatus,
          hasVariants: !!userState.hasVariants
        }
        const isPresent = userState.isPresent ?? false
        const sourceStreamStartTime = userState?.sourceStreamStartTime ?? null
        const delta = sourceStreamStartTime === null ? 0 : Date.now() / 1000 - sourceStreamStartTime
        const isJanusWRTC = isJanus()
        store.commit.status.updateUserState({
          userId,
          castId,
          state: {
            statusCode: getStreamStatus(fakeStreamInfo, stats, isPresent, delta, isJanusWRTC),
            isPresent,
            isCaster,
            isJanus: isJanusWRTC
          }
        })
      }
    },
    updatePresence (state: StatusState, payload: UserAndCastId & { isPresent: boolean, isCaster: boolean }): void {
      const { userId, castId, isPresent, isCaster } = payload
      const userState = state.userState[userId]?.[castId]
      const networkStats = store.getters.networkStats.userStats(userId)
      const fakeStreamInfo: StreamStatusInfo = {
        type: isCaster ? StreamType.Caster : StreamType.NonCaster,
        ngcvp_status: userState?.ngcvpStatus,
        hasVariants: userState?.hasVariants
      }
      const sourceStreamStartTime = userState?.sourceStreamStartTime ?? null
      const delta = sourceStreamStartTime === null ? 0 : Date.now() / 1000 - sourceStreamStartTime
      const isJanusWRTC = isJanus()
      store.commit.status.updateUserState({
        userId,
        castId,
        state: {
          statusCode: getStreamStatus(fakeStreamInfo, networkStats, isPresent, delta, isJanusWRTC),
          isPresent,
          isCaster: isCaster,
          isJanus: isJanusWRTC
        }
      })
    },
    updateNgcvp (
      state: StatusState, payload: UserAndCastId & { ngcvpStatus: SystemStatus, startTime: number|null }
    ): void {
      const { userId, castId, ngcvpStatus, startTime } = payload
      const userState = state.userState[userId]?.[castId]
      const networkStats = store.getters.networkStats.userStats(userId)
      const isCaster = userState?.isCaster ?? true
      const fakeStreamInfo: StreamStatusInfo = {
        type: isCaster ? StreamType.Caster : StreamType.NonCaster,
        ngcvp_status: ngcvpStatus,
        hasVariants: userState?.hasVariants
      }
      const isPresent = userState?.isPresent ?? false
      const delta = startTime === null ? 0 : Date.now() / 1000 - startTime
      const isJanusWRTC = isJanus()
      store.commit.status.updateUserState({
        userId,
        castId,
        state: {
          statusCode: getStreamStatus(fakeStreamInfo, networkStats, isPresent, delta, isJanusWRTC),
          ngcvpStatus: ngcvpStatus,
          isPresent,
          isCaster,
          isJanus: isJanusWRTC,
          sourceStreamStartTime: startTime
        }
      })
    },
    updateVariants (state: StatusState, payload: { userId: string, hasVariants: boolean }): void {
      const { userId, hasVariants } = payload
      const userCasts = state.userState[userId]
      if (userCasts === undefined) return
      const networkStats = store.getters.networkStats.userStats(userId)
      for (const [castId, userState] of Object.entries(userCasts)) {
        const isCaster = userState?.isCaster ?? true
        const fakeStreamInfo: StreamStatusInfo = {
          type: isCaster ? StreamType.Caster : StreamType.NonCaster,
          ngcvp_status: userState?.ngcvpStatus,
          hasVariants
        }
        const isPresent = userState?.isPresent ?? false
        const sourceStreamStartTime = userState?.sourceStreamStartTime ?? null
        const delta = sourceStreamStartTime === null ? 0 : Date.now() / 1000 - sourceStreamStartTime
        const isJanusWRTC = isJanus()
        store.commit.status.updateUserState({
          userId,
          castId,
          state: {
            statusCode: getStreamStatus(fakeStreamInfo, networkStats, isPresent, delta, isJanusWRTC),
            isPresent,
            isCaster,
            isJanus: isJanusWRTC,
            hasVariants,
          }
        })
      }
    },
    updateUserState (state: StatusState, payload: UserAndCastId & { state: UserCastStatus }): void {
      lazyUpdate(state.userState, payload.userId, { [payload.castId]: payload.state }, false)
    },
    clearUserState (state: StatusState): void {
      state.userState = {}
    },
    clearUserStateForCast (state: StatusState, payload: UserAndCastId): void {
      if (state.userState[payload.userId]?.[payload.castId] !== undefined) {
        // FIXME: The `!` shouldn't be needed here, but TS was complaining at the time of writing.
        delete state.userState[payload.userId]![payload.castId]
        if (Object.keys(state.userState[payload.userId]!).length === 0) {
          delete state.userState[payload.castId]
        }
      }
    }
  }
})

export default statusModule
export const statusModuleActionContext = (context: StatusContext) => moduleActionContext(context, statusModule)
