import { SrtInfo, StreamType, Stream } from '../../../types/streams'
import firebase from 'firebase/compat/app'
import { CollectionNames } from '@/modules/database/utils'
import { generateRandomString } from '@/modules/common/utils'
import { Result } from 'kiswe-ui'

const generateStreamId = (teamId: string): string => {
  const hash = Date.now().toString() + generateRandomString(8)
  return `${ teamId }${ hash }`
}

const makeSrtInfo = (form_info: any): SrtInfo => {
  const srtInfo: SrtInfo = {
     nimbleserver : form_info.nimbleserver,
     port : Number.parseInt(form_info.port),
     ip: form_info?.ip ?? '0.0.0.0',
     mode: form_info?.mode ?? 'listen'
  }
  return srtInfo
}

// eslint-disable-next-line complexity, max-lines-per-function
const addOptionalParamsToStream = (original: Stream, updates: Stream): Stream => {
  const stream = {
    ...original,
    //Only includes below props if they are defined
    ...(updates.ingest_region && { ingest_region: updates.ingest_region }),
    ...(updates.protocol && { protocol: updates.protocol }),
    ...(updates.nimble_server && { nimble_server: updates.nimble_server }),
    ...((typeof updates.nimble_configured !== 'undefined') && { nimble_configured: updates.nimble_configured }),
    ...(updates.videopid && { videopid: updates.videopid }),
    ...(updates.nimble_params && { nimble_params: updates.nimble_params }),
    ...(updates.mixer_params && { mixer_params: updates.mixer_params }),
    ...(updates.nimble_server && { nimble_server: updates.nimble_server }),
    ...(updates.nimble_server_old && { nimble_server_old: updates.nimble_server_old }),
    ...(updates.audioTracks && { audioTracks: updates.audioTracks }),
    ...(updates.tracks && { tracks: updates.tracks }),
    ...(updates.templateId && { templateId: updates.templateId }),
    ...(updates.srt_info && { srt_info: makeSrtInfo(updates.srt_info) }),
    ...(updates.provider && { provider: updates.provider }),
    ...(updates.nimble_stream_url && { nimble_stream_url: updates.nimble_stream_url }),
    ...(updates.audioOnly !== undefined && { audioOnly: updates.audioOnly }),
    ...(updates.srt_state && { srt_state: updates.srt_state }),
    ...(updates.srt_state_override && { srt_state_override: updates.srt_state_override }),
    ...(updates.passthrough && { passthrough: updates.passthrough }),
    ...(updates.type === StreamType.Output && {
      reencode:  updates.reencode,
      framerate: updates.framerate,
      resolution: updates.resolution,
      ...(updates.rate && { rate: updates.rate })
    }),
    ...(updates.type === StreamType.Broadcast && {
      leftrightsplit: !!updates.leftrightsplit,
      ...(updates.auth_info && { auth_info: updates.auth_info })
    })
  }
  if (updates.type === StreamType.Broadcast && updates.delay !== undefined) {
    stream.delay = updates.delay
  }
  return stream
}

const createTeamStream = (transaction: firebase.firestore.Transaction,
                          db: firebase.firestore.Firestore,
                          stream: Stream,
                          teamId: string): Result<string, 'invalid params'> => {
  const streamDocId = generateStreamId(teamId)
  const streamDoc: Stream = {
    active: false,
    persistent: true,
    srcurl: stream.srcurl,
    name: stream.name,
    type: stream.type,
    team_id: teamId,
    team: teamId,
    deleted: false
  }
  const updatedStreamDoc = addOptionalParamsToStream(streamDoc, stream)
  try {
    const doc = db.collection(CollectionNames.STREAMS).doc(streamDocId)
    transaction.set(doc, { ...updatedStreamDoc, id: streamDocId })
  } catch (error) {
    return Result.fail('invalid params', `Error creating stream: ${error}`)
  }
  return Result.success(streamDocId)
}

export default createTeamStream
