import { KeyDict } from '@/types'
import { CastStream, Stream } from '@/types/streams'

interface VuMeterStatus {
  channelVolumes: number[],
  timestamp: number
}

export const state = {
  castStreams: {} as KeyDict<CastStream>,
  subscribedStreams: {} as KeyDict<Stream>,
  desktopStreamId: null as null|string,
  vumeters: {} as KeyDict<VuMeterStatus>,
  vumeterUpdateInterval: 100
}

export type StreamsState = typeof state
