import { Email } from '@/modules/profiles/types'
import { AuthInterface } from './authInterface'

// Note: The values currently match those of firebase.auth.Auth.Persistence
export enum AuthPersistence {
  Local = 'local',    // Persisted locally until explicit logout
  None = 'none',      // Persisted in memory until page refresh
  Session = 'session' // Persisted until tab is closed
}

const createDeleteUsers = (auth: AuthInterface) => async (userIds: []) => {
  return auth.deleteUsers(userIds)
}

const createSignInWithEmailAndPassword = (auth: AuthInterface) => async (email: Email, password: string) => {
  return auth.signInWithEmailAndPassword(email, password)
}

const createCreateUser = (auth: AuthInterface) => async (email: Email, password: string) => {
  return auth.createUser({ email, password })
}

/* const createGetUserByEmail = (auth: AuthInterface) => async (email: Email) => {
  return auth.getUserByEmail(email)
} */



export const createAuth = (authInterface: AuthInterface) => {
  return {
    deleteUsers: createDeleteUsers(authInterface),
    signInWithEmailAndPassword: createSignInWithEmailAndPassword(authInterface),
    createUser: createCreateUser(authInterface)
    // getUserByEmail: createGetUserByEmail(authInterface)
  }
}
