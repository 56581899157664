import { Loader, Result } from 'kiswe-ui'
import { CefMode, CefMemory } from '../types'

export default class CefStatInstance {
  castId: string
  cefmode: CefMode
  key: string|null = null
  cpu: number = 0
  memory: CefMemory = { limit: 0, total: 0, used: 0 }
  timestamp: Date

  constructor (data: unknown) {
    this.castId = Loader.loadString(data, 'castId')
    this.cefmode = Loader.loadEnum(data, 'cefmode', CefMode.Front, Object.values(CefMode))
    this.key = Loader.loadStringOrNull(data, 'key', this.key)
    this.cpu = Loader.loadNumber(data, 'cpu', this.cpu)
    this.memory = Loader.loadAndCast<CefMemory>(data, 'memory', this.memory)
    this.timestamp = Loader.loadDate(data, 'timestamp')
  }

  static load (data: unknown): Result<CefStatInstance, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new CefStatInstance(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
