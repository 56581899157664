import { useCurrentCast } from '@/modules/casts/compositions'
import { AssetType } from '@/types/assets'
import { computed, ComputedRef, Ref } from 'vue'
import { SceneContentType } from '../types'
import { isSceneContentTransition, SceneContent, SceneContentTransition } from '@/types/sceneContent'
import { ScenePreset } from '@/modules/scenes/classes'

type SceneContentWithUrl = SceneContent & { url: string }

const isValidSceneContent = (content: SceneContent, contentType: SceneContentType): content is SceneContentWithUrl => {
  if (content.contentType !== contentType) return false
  if (content.type === AssetType.Broadcast) return false
  if (content.url === undefined) return false
  if (!content.box || content.box === 'none') return false
  return true
}

const useScenePresets = (params?: { sceneId?: Ref<string>, scenePresetId?: Ref<string> }) => {
  const { sceneId, scenePresetId } = params ?? {}
  const { currentCast } = useCurrentCast()

  const currentScenePresetId = computed(() => {
    if (scenePresetId?.value) return scenePresetId.value
    if (!sceneId?.value) return null
    return currentCast.value?.getScenePresetIdBySceneId(sceneId.value) ?? null
  })

  const currentScenePreset = computed(() => {
    if (scenePresetId?.value) return currentCast.value?.scenePresets[scenePresetId.value] ?? null
    if (!sceneId?.value) return null
    return currentCast.value?.getScenePresetBySceneId(sceneId.value) ?? null
  })

  const sceneAllContents = computed(() => {
    if (scenePresetId?.value) return currentScenePreset.value?.contents ?? {}
    if (!sceneId?.value) return {}
    else return currentCast.value?.getSceneContents(sceneId.value) ?? {}
  })

  const sceneContentsOrdered: ComputedRef<SceneContentWithUrl[]> = computed(() => {
    return Object
      .values(sceneAllContents.value)
      .filter((content): content is SceneContentWithUrl => isValidSceneContent(content, SceneContentType.Content))
      .sort((a, b) => a.order > b.order ? 1 : -1)
  })

  const sceneLayersOrdered: ComputedRef<SceneContentWithUrl[]> = computed(() => {
    return Object
      .values(sceneAllContents.value)
      .filter((content): content is SceneContentWithUrl => isValidSceneContent(content, SceneContentType.Layer))
      .sort((a, b) => a.order > b.order ? 1 : -1)
  })

  const sceneTransition: ComputedRef<SceneContentTransition|null> = computed(() => {
    const sceneContentTransition = Object
      .values(sceneAllContents.value)
      .find((content): content is SceneContentTransition => isSceneContentTransition(content))
    return sceneContentTransition ?? null
  })

  // Sorted in desc order because the z-index for scene content is derived
  // from the global layer id and we want the left most global layer to be on top.
  const globalScenePresets = computed<ScenePreset[]>(() => {
    return Object
      .values(currentCast.value?.scenePresets ?? {})
      .filter((preset) => preset.isGlobalPreset && !preset.isHiddenGlobalPreset)
      .sort((a, b) => a.id > b.id ? -1 : 1)
  })

  return {
    isAssetVisible: (assetId: string) => sceneAllContents.value[assetId]?.active ?? false,
    isValidSceneContent,
    currentScenePreset,
    currentScenePresetId,
    globalScenePresets,
    sceneAllContents,
    sceneContentsOrdered,
    sceneLayersOrdered,
    sceneTransition
  }
}

export default useScenePresets
