/* eslint-disable max-lines */
import { CastInputStream } from '@/modules/streams/types'
import { KeyDict } from '@/types'
import { AssetType } from '@/types/assets'
import { Scene } from '@/types/scenes'
import { StreamType } from '@/types/streams'
import { GConstructor, Mixin } from './base'
import { HasScenes } from './hasScenes'
import { HasStreams } from './hasStreams'
import { createScene } from './hasInputs/fullscreenScene'
import { pickBy } from 'lodash'
import { SceneContentType, SceneSourceType } from '@/modules/scenes/types'
import { Result } from 'kiswe-ui'

export interface AddSourceStreamOptions {
  audioOnly?: boolean,
  delay?: number
}

const getSceneContentFromStreamId = (streamId: string, name: string) => {
  return {
    id: streamId,
    type: AssetType.Broadcast,
    muted: false, leftMuted: true, rightMuted: false,
    active: true,
    box: 'fullscreen',
    name: name,
    order: 0,
    contentType: SceneContentType.Content
  }
}

const addRemoteTalkbackStream = (cast: HasInputs, streamId: string, options?: AddSourceStreamOptions) => {
  if (!cast.input_streams[streamId]) cast.input_streams[streamId] = new CastInputStream()
  if (cast.source_streams[streamId] !== undefined) return
  cast.source_streams[streamId] = {
    active: true,
    type: StreamType.RemoteTalkback,
    volume: 0,
    start_time: Date.now() / 1000
  }
  const delay = options?.delay
  if (delay !== undefined) cast.source_streams[streamId].delay = delay
}

const addSourceStream = (cast: HasInputs, streamId: string, options?: AddSourceStreamOptions) => {
  if (!cast.input_streams[streamId]) cast.input_streams[streamId] = new CastInputStream()
  if (cast.source_streams[streamId] !== undefined) return
  cast.source_streams[streamId] = {
    active: true,
    type: StreamType.Broadcast,
    volume: 100,
    volumeLeft: 100,
    volumeRight: 100,
    start_time: Date.now() / 1000,
    audioOnly: options?.audioOnly ?? false
  }
  const delay = options?.delay
  if (delay !== undefined) cast.source_streams[streamId].delay = delay
}

const addReplayStream = (cast: HasInputs, streamId: string, streamName: string) => {
  if (cast.source_streams[streamId] !== undefined) return
  cast.source_streams[streamId] = {
    active: true,
    name: streamName,
    start_time: Date.now() / 1000,
    type: StreamType.Replay,
    volume: 100,
    volumeLeft: 100,
    volumeRight: 100
  }
}

const cleanupSourceStreams = (cast: HasInputs) => {
  const streamIds = new Set<string>()
  for (const scene of Object.values(cast.scenes)) {
    const streamId = getMainStreamSceneContent(scene)?.id ?? null
    if (streamId) streamIds.add(streamId)
  }

  Object.keys(cast.input_streams).forEach((streamId) => {
    if (!streamIds.has(streamId) && !cast.source_streams[streamId]?.audioOnly) delete cast.input_streams[streamId]
  })

  const allowedTypes = new Set([
    StreamType.Playlist, StreamType.RemoteTalkback, StreamType.Moderator, StreamType.NonCaster
  ])
  const existing = Object.entries(cast.source_streams)
  existing.forEach(([streamId, sourceStream]) => {
    if (sourceStream.type === StreamType.RemoteTalkback && !streamIds.has(streamId.split('_')[1])) {
      delete cast.source_streams[streamId]
    } else if (!streamIds.has(streamId) && !allowedTypes.has(sourceStream.type) && !sourceStream.audioOnly) {
      delete cast.source_streams[streamId]
    }
  })

}

const addAndCleanupHiddenSourcesInScenes = (cast: HasInputs) => {
  const streamIds = Object.keys(pickBy(cast.source_streams, (stream) =>
    [StreamType.Broadcast, StreamType.Replay].includes(stream.type)))
  Object.values(cast.scenes).forEach((scene) => {
    for (const [contentId, content] of Object.entries(scene.contents)) {
      if (!streamIds.includes(contentId) && content.type === AssetType.Broadcast) delete scene.contents[contentId]
    }
    streamIds.filter((id) => scene.contents[id] === undefined).forEach((streamId) => {
      scene.contents[streamId] = {
        active: true,
        muted: true, leftMuted: true, rightMuted: true,
        box: 'none',
        id: streamId,
        name: streamId,
        order: 0,
        type: AssetType.Broadcast,
        contentType: SceneContentType.Content
      }
    })
  })
}

const FULLSCREEN_STREAM_TYPES = new Set([AssetType.Broadcast, AssetType.Cast, AssetType.ScreenShare])

const getMainStreamSceneContent = (scene: Scene) => {
  const sceneContents = Object.values(scene.contents ?? {})
  return sceneContents.find((content) => {
    if (!content.active || !content.box || content.box === 'none') return false
    if (content.type === AssetType.Caster) return scene.scene_layout.boxes[content.box]?.active === true
    return FULLSCREEN_STREAM_TYPES.has(content.type) && content.box === 'fullscreen'
  }) ?? null
}

export interface Input {
  delay?: number
  name: string
  remoteTalkbackVariants?: string[]
  sceneId: string
  streamId: string|null
}

// eslint-disable-next-line max-lines-per-function
export function hasInputs<TBase extends GConstructor<HasScenes> & GConstructor<HasStreams>> (
  Base: TBase
) {
  return class InputsClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
    }
    // @ts-ignore
    private __hasInputs: true = true

    get numInputs (): number {
      return this.numScenes
    }

    set numInputs (value: number) {
      this.numScenes = value
    }

    get inputs () {
      const inputsArray: Input[] = []
      for (let index = 0; index < Object.entries(this.scenes).length; index++) {
        // TODO these names should go
        const sceneId = `scene_${index}`
        const scene = this.scenes[sceneId] ?? null
        const broadcastSceneContentActive = scene && getMainStreamSceneContent(scene)
        inputsArray.push({
          streamId: broadcastSceneContentActive?.id ?? null,
          name: scene?.name ?? 'unknown',
          sceneId
        })
      }
      return inputsArray
    }

    set inputs (value: Input[]) {
      const scenes: KeyDict<Scene> = {}
      value.forEach((input, index) => {
        const sceneId = `scene_${index}`
        scenes[sceneId] = createScene(sceneId, input.name ?? '', '', index)
        if (input.streamId !== null) {
          addSourceStream(this, input.streamId, { delay: input.delay })
          scenes[input.sceneId].contents[input.streamId] = getSceneContentFromStreamId(input.streamId, input.name)
          scenes[input.sceneId].sourceType = SceneSourceType.Broadcast
        }
      })
      this.scenes = scenes
      cleanupSourceStreams(this)
      addAndCleanupHiddenSourcesInScenes(this)
    }

    clearSceneAndInputs (sceneId: string): Result<this, 'invalid_scene_id'> {
      const result = this.clearScene(sceneId)
      if (result.isFailure) return result.convert()
      cleanupSourceStreams(this)
      addAndCleanupHiddenSourcesInScenes(this)
      return Result.success(this)
    }

    setStreamIdInScene (input: Input) {
      if (input.streamId === null) {
        this.scenes[input.sceneId].contents = {}
        cleanupSourceStreams(this)
      } else {
        addSourceStream(this, input.streamId, { delay: input.delay })
        if (input.remoteTalkbackVariants) input.remoteTalkbackVariants.forEach((rtbVariantName, _) => {
          addRemoteTalkbackStream(this, `${rtbVariantName}_${input.streamId}`, { delay: input.delay })
        })
        this.scenes[input.sceneId].name = input.name
        this.scenes[input.sceneId].contents[input.streamId] = getSceneContentFromStreamId(input.streamId, input.name)
      }
      addAndCleanupHiddenSourcesInScenes(this)
      return this
    }

    setIndependentSourceStream (streamId: string|null, options: AddSourceStreamOptions) {
      if (!streamId) return this
      addSourceStream(this, streamId, options)
      addAndCleanupHiddenSourcesInScenes(this)
      return this
    }

    removeIndependentSourceStream (streamId: string|null) {
      if (!streamId) return this
      if (this.source_streams[streamId]) delete this.source_streams[streamId]
      if (this.input_streams[streamId]) delete this.input_streams[streamId]
      addAndCleanupHiddenSourcesInScenes(this)
      return this
    }

    updateSourceStream (streamId: string, options?: AddSourceStreamOptions) {
      if (!this.input_streams[streamId]) return this
      if (!this.source_streams[streamId]) return this
      const delay = options?.delay
      const audioOnly = options?.audioOnly
      if (delay !== undefined) this.source_streams[streamId].delay = delay
      if (audioOnly !== undefined) this.source_streams[streamId].audioOnly = audioOnly
      return this
    }

    setReplayPlaylistInScene (input: Input) {
      if (input.streamId === null) {
        this.scenes[input.sceneId].contents = {}
        cleanupSourceStreams(this)
      } else {
        addReplayStream(this, input.streamId, input.name)
        this.scenes[input.sceneId].name = input.name
        this.scenes[input.sceneId].contents[input.streamId] = getSceneContentFromStreamId(input.streamId, input.name)
      }
      addAndCleanupHiddenSourcesInScenes(this)
      return this
    }
  }
}

export type HasInputs = Mixin<typeof hasInputs>

export const hasInputsMixin = (obj: unknown): obj is HasInputs => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasInputs' in obj) return true
  return false
}
