import 'kiswe-ui/style.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
// @ts-ignore
// import lang from 'element-plus/lib/locale/lang/en'
// @ts-ignore
// import locale from 'element-plus/lib/locale'
import ElementPlus, { ElMessage, ElMessageBox } from 'element-plus'
import { KisweUI } from 'kiswe-ui'
import { KENV } from './types/admin'
import setupDebug from './setupDebug'
import env from './env'
import { i18n } from '@/plugins/translations/i18n'
import { setupVueEnv } from './setupVueEnv'
import { setupVueAbilities } from './ability'

const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(store.original)
  .use(ElementPlus)
  .use(KisweUI)

setupVueEnv(app)
setupDebug(app, env.environment as KENV)
setupVueAbilities(app)

app.config.globalProperties.$store = store.original
app.config.globalProperties.$msgbox = ElMessageBox
app.config.globalProperties.$alert = ElMessageBox.alert
app.config.globalProperties.$confirm = ElMessageBox.confirm
app.config.globalProperties.$prompt = ElMessageBox.prompt
app.config.globalProperties.$message = ElMessage

// app.config.productionTip = false

/* To support Safari's version of AudioContext,
without needing to take it into consideration elsewhere in the app when used. */
if (!window.AudioContext && window.webkitAudioContext !== undefined) window.AudioContext = window.webkitAudioContext

app.mount('#app')
