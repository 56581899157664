import { UserNetworkStats, NgcvpStats } from '@/types/networkstats'
import { TranscodingStats } from '@/modules/statistics/classes'
import { CefStats } from '@/modules/statistics/types'
import { KeyDict } from '@/types'

export class NetworkstatsState {
  userStats: { [userId: string]: UserNetworkStats } = {}
  ngcvpStats: { [castId: string]: NgcvpStats } = {}
  transcodingStats: KeyDict<TranscodingStats> = {}
  cefStats: KeyDict<CefStats> = {}
}
