import { Loader } from 'kiswe-ui'
import { Region } from '@/types/ops'
import { GConstructor, Mixin, StudioBaseMixin } from './base'

export function hasRegion<TBase extends StudioBaseMixin> (Base: TBase) {
  return class hasRegionClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.region = Loader.loadAndCast(arg[0], 'region', Region.USEast)
      this.regionBackup = Loader.loadAndCast(arg[0], 'regionBackup', null)
    }
    region: Region
    regionBackup: Region|null

    static __constructsHasRegion = true

    setRegion (region: Region) {
      this.region = region
      return this
    }

    setBackupRegion (region: Region|null) {
      this.regionBackup = region
      return this
    }
  }
}

export type HasRegion = Mixin<typeof hasRegion>

export const constructsRegionMixin = (func: unknown): func is GConstructor<HasRegion> => {
  if (typeof func !== 'function' || func === null) return false
  if ('__constructsHasRegion' in func) return true
  return false
}
