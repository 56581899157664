import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method } from 'axios'
import { ThorApiRequest } from './ThorApiRequest'
import { ThorApiResponse } from '../types'
import { Result } from 'kiswe-ui'

export default class ThorApiCall {
  private _request: ThorApiRequest

  constructor (request: ThorApiRequest) {
    this._request = request
  }

  private getHeaders (auth: boolean): Partial<AxiosRequestHeaders> {
    const authHeader = auth && this._request.apiToken ? { Authorization: `Token ${this._request.apiToken}` } : undefined
    const headers: Partial<AxiosRequestHeaders> = {
      Accept: this._request.acceptHeader,
      'Content-Type': this._request.contentType,
      ...authHeader
    }
    return headers
  }

  private getUrl (path: string): string {
    return `${this._request.baseUrl}${path}`
  }

  private createResult (completed: boolean, success: boolean, message: string = '', status: number = -1,
    data: any = {}): Result<ThorApiResponse, 'axios_error'|'http_error'>
  {
    if (!completed) return Result.fail('axios_error', message)
    if (!success) return Result.fail('http_error', message, `${status}`)
    return Result.success({ message, status, data })
  }

  // eslint-disable-next-line complexity
  async sendRequest (method: Method, path: string, data: any = null,
    auth: boolean = false): Promise<Result<ThorApiResponse, 'axios_error'|'http_error'>>
  {
    try {
      const config: AxiosRequestConfig = {
        method,
        headers: this.getHeaders(auth),
        url: this.getUrl(path),
        ...(data && { [method === 'get' ? 'params' : 'data']: data })
      }
      const res: AxiosResponse = await axios(config)
      if (!res) return this.createResult(false, false, 'Internal Error')
      const success = ['1', '2', '3'].includes(String(res.status)[0])
      const msg = res.statusText || (success ? 'OK' : 'Error')
      return this.createResult(true, success, msg, res.status, res.data)
    } catch (error: any) {
      if (error.response) {
        const status = Number.parseInt(error?.response?.status ?? '-1')
        const message = error?.response?.statusText ?? error?.message ?? 'Error'
        const data = error?.response?.data ?? error?.response ?? error ?? {}
        return this.createResult(true, false, message, status, data)
      } else if (error.request) {
        return this.createResult(true, false, 'No response')
      } else {
        const message = error?.message ?? 'Internal Error'
        return this.createResult(false, false, message)
      }
    }
  }
}
