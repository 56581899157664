import { RootState } from '../types'
import store, { moduleActionContext } from '..'
import { VideoPlayerState } from '@/store/types'
import { ActionContext } from 'vuex'
import {
  ClipEndAction, PlaybackAction, PlaybackEndAction, VideoPlayer, VideoPlayerLastClicked, VideoPlayerPlayState
} from '@/types/videoplayer'
import { AssetType, VideoAsset } from '@/types/assets'
import { contentSortByOrder, SceneContent, VideoAssetContent } from '@/types/sceneContent'
import { SystemStatus } from '@/types/ops'
import { LogLevel } from '@/types/logs'
import { KeyDict } from '@/types'
import { defineModule } from 'direct-vuex'
import studio, { CollectionNames } from '@/modules/database/utils'
import { MergeOption } from '@/modules/database/utils/database'
import { CastType } from '@/types/casts'
import { Result, ResultVoid } from 'kiswe-ui'
import { throttle } from 'lodash'
import { hasActiveClipsContent } from '@/modules/scenes/utils/sceneClips'
import { can } from '@/ability'
import { AbilityAction, AbilitySubject } from '@/abilitiesByRole'

type VideoPlayerContext = ActionContext<VideoPlayerState, RootState>

export class StatusBlur {
  lastStatuses: SystemStatus[] = []
  count: Record<SystemStatus, number>
  maxNumStatus: number

  constructor (maxCount: number) {
    this.maxNumStatus = maxCount
    this.count = {
      [SystemStatus.ERROR]: 0,
      [SystemStatus.NETWORK_SLOW]: 0,
      [SystemStatus.NONE]: 0,
      [SystemStatus.OK]: 0,
      [SystemStatus.WARNING]: 0
    }
  }

  clear () {
    this.count = {
      [SystemStatus.ERROR]: 0,
      [SystemStatus.NETWORK_SLOW]: 0,
      [SystemStatus.NONE]: 0,
      [SystemStatus.OK]: 0,
      [SystemStatus.WARNING]: 0
    }
    this.lastStatuses = []
  }

  blurredStatus (status: SystemStatus): SystemStatus {
    this.add(status)
    return this.maxCount()
  }

  private add (status: SystemStatus) {
    this.lastStatuses.push(status)
    this.count[status]++
    if (this.lastStatuses.length > this.maxNumStatus) {
      const oldStatus = this.lastStatuses.shift()
      if (oldStatus !== undefined) this.count[oldStatus]--
    }
  }

  private maxCount (): SystemStatus {
    let maxStatus: null|SystemStatus = null
    let maxCount = 0
    Object.entries(this.count).forEach(([status, count]) => {
      if (count > maxCount) {
        maxCount = count
        maxStatus = status as SystemStatus
      }
    })
    return maxStatus ?? SystemStatus.NONE
  }
}

const statusBlur = new StatusBlur(15)

const calculateVideoPlayerStatus = (state: VideoPlayerState, id: string): SystemStatus => {
  const videoPlayer = state.videoPlayers[id]
  if (videoPlayer === undefined) return SystemStatus.NONE
  const playheads = Object.values(videoPlayer.actual_playhead)
  if (playheads.length !== 2) return SystemStatus.WARNING
  if (Math.abs(playheads[0] - playheads[1]) > 5) return SystemStatus.ERROR
  const states = Object.values(videoPlayer.actual_states)
  if (states.length !== 2) return SystemStatus.WARNING
  if (states[0].video !== states[1].video) return SystemStatus.ERROR
  return SystemStatus.OK
}

interface currentSelectedClipUpdate {
  player: string,
  assetId: string|null
}

let calculateLocalStatesTimeoutId: any|null = null
const setTimeoutWrapper = (callback: () => void, delay: number): void => {
  if (calculateLocalStatesTimeoutId !== null) clearTimeout(calculateLocalStatesTimeoutId)
  calculateLocalStatesTimeoutId = setTimeout(() => {
    callback()
    calculateLocalStatesTimeoutId = null
  }, delay)
}
const clearCalculateLocalStatesTimeout = () => {
  if (calculateLocalStatesTimeoutId !== null) {
    clearTimeout(calculateLocalStatesTimeoutId)
    calculateLocalStatesTimeoutId = null
  }
}

const tryToAutoStartPlayback = throttle(() => {
  let actualPlayingClipCount = 0
  for (const machine of Object.values(store.state.videoPlayer.videoPlayers.player1?.actual_states ?? {})) {
    if (machine.playing === true) actualPlayingClipCount++
  }
  if (actualPlayingClipCount >= 2) {
    store.commit.videoPlayer.requestPlaybackOnceClipsAvailable(false)
    return
  }
  const playerBooting = Object.keys(store.state.videoPlayer.videoPlayers.player1?.actual_playhead ?? {}).length === 0
  const currentClipId = store.state.videoPlayer.currentSelectedClip.player1 ?? null
  if (!playerBooting && currentClipId !== null) {
    store.dispatch.videoPlayer.playAsset({ playerName: 'player1', assetId: currentClipId })
      .catch((error) => console.error(error))
  }
}, 1000)

const videoPlayerModule = defineModule({
  namespaced: true,
  state: {
    autoStartPlaybackRequested: false,
    currentSelectedClip: {},
    lastClicked: {},
    localStates: {},
    scte: {},
    videoPlayerStatus: {},
    videoPlayers: {}
  } as VideoPlayerState,
  getters: {
    numOfClips: (_state: VideoPlayerState) => (_videoPlayerId: string): number => {
      return store.getters.videoPlayer.orderedClips.length
    },
    currentClipIndex: (state: VideoPlayerState) => (videoPlayerId: string): number => {
      const currentClipId = state.currentSelectedClip[videoPlayerId]
      if (currentClipId === undefined || currentClipId === null) return 0
      const index = store.getters.videoPlayer.orderedClips.findIndex((sceneAsset) => sceneAsset.id === currentClipId)
      return index ?? 0
    },
    orderedClips (_state: VideoPlayerState): VideoAssetContent[] {
      if (store.state.events.currentCast === null) return []
      const clips: VideoAssetContent[] = []
      const allVideoIDs : string[] = []
      Object.values(store.state.events.currentCast.scenes).forEach(scene => {
        if (!scene.contents) return
        Object.values(scene.contents).forEach((content) => {
          if (content.type === AssetType.Video && !allVideoIDs.includes(content.id)) {
            const sceneAsset = store.state.assets.sceneAssets[content.id]
            if (sceneAsset !== undefined && sceneAsset.type === AssetType.Video) {
              const clipEndActionDefault = content.auto_play_next ? ClipEndAction.Next : ClipEndAction.Stop
              clips.push({
                ... sceneAsset as VideoAsset,
                auto_play_next: content.auto_play_next ?? false,
                clip_end_action: content.clip_end_action ?? clipEndActionDefault,
                muted: content.muted ?? false,
                order: content.order
              })
              allVideoIDs.push(content.id)
            }
          }
        })
      })
      return clips.sort(contentSortByOrder)
    },
    isPlayingAnyClip (state: VideoPlayerState): boolean {
      return Object
        .values(state.localStates.player1 ?? {})
        .some((asset) => asset.playstate === VideoPlayerPlayState.PLAYING)
    },
    desiredStateTimestamp (state: VideoPlayerState): number {
      const lastTimestamp = state.videoPlayers?.player1?.desired_state?.timestamp ?? 0
      return lastTimestamp > Date.now() ? lastTimestamp + 1 : Date.now()
    }
  },
  actions: {
    async subscribeVideoPlayer (context: VideoPlayerContext, query: { castId: string }) {
      const { commit } = videoPlayerModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        key: `videoplayer_${query.castId}`,
        query: studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(query.castId),
        callback: commit.updateVideoPlayerDoc
      })
      result.logIfError('subscribeVideoPlayer')
    },
    unsubscribeVideoPlayer (_context: VideoPlayerContext, query: { castId: string }) {
      const key = `videoplayer_${query.castId}`
      studio.subscriptions.unsubscribe(key, () => { store.commit.videoPlayer.cleanUpVideoPlayer() })
    },
    async playAsset (context: VideoPlayerContext, query: {
      playerName: string, assetId: string, castId?: string, action?: PlaybackAction
    }) {
      const { commit, getters } = videoPlayerModuleActionContext(context)
      const doc = {
        [query.playerName]: {
          desired_state: {
            action: query.action ?? PlaybackAction.Resume,
            video: query.assetId,
            paused: false,
            timestamp: getters.desiredStateTimestamp
          }
        },
        currentSelectedClip: {
          [query.playerName]: query.assetId
        }
      }
      commit.setLastPlayClicked(query)
      commit.calculateLocalStates()
      const castId = query.castId ?? store.state.events.currentCast?.id
      if (!castId) throw new Error('Could not play Asset if there is no current Cast')
      store.dispatch.logs.addLog({ message: `playAsset at ${new Date()}`, level: LogLevel.Info, data: doc })
      const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(castId).set(doc, MergeOption.MERGE)
      result.logIfError('playAsset')
      setTimeoutWrapper(() => { commit.calculateLocalStates() }, 1900)
    },
    async pauseAsset (context: VideoPlayerContext, query: { playerName: string, assetId: string, castId?: string }) {
      const { commit, getters } = videoPlayerModuleActionContext(context)
      const doc = {
        [query.playerName]: {
          desired_state: {
            action: PlaybackAction.Pause,
            video: query.assetId,
            paused: true,
            timestamp: getters.desiredStateTimestamp
          }
        }
      }
      commit.setLastPauseClicked(query)
      commit.calculateLocalStates()
      const castId = query.castId ?? store.state.events.currentCast?.id
      if (!castId) throw new Error('cannot pause asset if no current cast is set')
      store.dispatch.logs.addLog({ message: `pauseAsset at ${new Date()}`, level: LogLevel.Info, data: doc })
      const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(castId).set(doc, MergeOption.MERGE)
      result.logIfError('pauseAsset')
      setTimeoutWrapper(() => { commit.calculateLocalStates() }, 1900)
    },
    async stopAsset (context: VideoPlayerContext, query: { playerName: string, assetId: string, castId?: string }) {
      const { commit,getters } = videoPlayerModuleActionContext(context)
      const doc = {
        [query.playerName]: {
          desired_state: {
            action: PlaybackAction.Pause,
            video: '',
            paused: true,
            timestamp: getters.desiredStateTimestamp
          }
        }
      }
      commit.setLastStoppedClicked(query)
      commit.calculateLocalStates()
      const castId = query.castId ?? store.state.events.currentCast?.id
      if (!castId) throw new Error('Cannot stop asset, no cast available')
      store.dispatch.logs.addLog({ message: `stopAsset at ${new Date()}`, level: LogLevel.Info, data: doc })
      const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(castId).set(doc, MergeOption.MERGE)
      result.logIfError('stopAsset')
      setTimeoutWrapper(() => { commit.calculateLocalStates() }, 1900)
    },
    async setClipCue (_context: VideoPlayerContext, query: { castId: string, playerName: string, assetId: string,
      cueIn?: number, cueOut?: number })
    {
      const cueInNode = query.cueIn !== undefined ? { cueIn: query.cueIn } : undefined
      const cueOutNode = query.cueOut !== undefined ? { cueOut: query.cueOut } : undefined
      if (!cueInNode && !cueOutNode) {
        console.error('No cue provided to setCue', query)
        return
      }
      const doc = {
        [query.playerName]: {
          desired_state: {
            clipProperties: {
              [query.assetId]: {
                ...cueInNode,
                ...cueOutNode
              }
            }
          }
        }
      }
      const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(query.castId).set(doc, MergeOption.MERGE)
      result.logIfError('setClipCue')
    },
    async setCurrentSelectedClip (context: VideoPlayerContext, params: currentSelectedClipUpdate ) {
      const { state } = videoPlayerModuleActionContext(context)
      if (store.state.events.currentCast === null) {
        throw new Error('Could not set Current Selected Clip if there is no current Cast')
      }
      let assetId = params.assetId
      if (params.assetId === null ||
          state.localStates[params.player] === undefined ||
          state.localStates[params.player][params.assetId] === undefined) {
        assetId = null
      }
      const updates = { currentSelectedClip: { [params.player]: assetId } }
      const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER)
                                    .doc(store.state.events.currentCast.id).set(updates, MergeOption.MERGE)
      result.logIfError('setCurrentSelectedClip')
    },
    async updateSelectedClipIfNecessary (context: VideoPlayerContext) {
      const { state } = videoPlayerModuleActionContext(context)
      if (store.state.events.currentCast === null) return
      let updates = {}
      let needsUpdates = false
      for (const playerName of Object.keys(state.videoPlayers)) {
        const clips = Object.keys(state.localStates[playerName])
        if (!state.currentSelectedClip[playerName] && clips.length > 0) {
          updates = { currentSelectedClip: {
            [playerName]: clips[0]
          }}
          needsUpdates = true
        }
        if (state.currentSelectedClip[playerName] && clips.length === 0) {
          updates = { currentSelectedClip: {
            [playerName]: null
          }}
          needsUpdates = true
        }
      }
      if (needsUpdates) {
        const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(store.state.events.currentCast.id)
                                                                               .set(updates, MergeOption.MERGE)
        result.logIfError('updateSelectedClipIfNecessary')
      }
    },
    async playNextClip (context: VideoPlayerContext) {
      const { dispatch } = videoPlayerModuleActionContext(context)
      const currentCast = store.state.events.currentCast
      if (currentCast === null) {
        throw new Error('playNextClip not possible if there is no current Cast')
      }
      let activeClipId = store.state.videoPlayer.currentSelectedClip.player1 ?? null
      const activeSceneId = currentCast.activeScene
      if (activeSceneId === null) return
      const activeScene = currentCast.scenes[activeSceneId]
      const sceneContents: KeyDict<SceneContent>|undefined = activeScene?.contents
      if (sceneContents === undefined) return
      const clips = Object.values(sceneContents)
                          .filter((content: SceneContent) => content.type === AssetType.Video)
                          .sort((clip1, clip2) => clip1.order < clip2.order ? -1 : 1)
      const index = clips.findIndex((asset) => asset.id === activeClipId)
      if (index !== -1) {
        activeClipId = clips[(index + 1) % clips.length].id
      }
      if (activeClipId === null) return
      try {
        await dispatch.playAsset({ playerName: 'player1', assetId: activeClipId })
      } catch (error) {
        console.error('playNextClip', error)
      }
    },
    async handleClipPlaybackOnSceneChange (context: VideoPlayerContext,
      payload: { newSceneId: string, oldSceneId: string|null })
    {
      const playerName = 'player1'
      const { dispatch, getters, state } = videoPlayerModuleActionContext(context)
      if (store.state.events.currentCast?.castType !== CastType.Switcher) {
        if (getters.isPlayingAnyClip) {
          await dispatch.playNextClip()
        } else {
          const activeClipId = state.currentSelectedClip.player1 ?? null
          if (activeClipId === null) return
          await dispatch.playAsset({ playerName: playerName, assetId: activeClipId })
        }
      } else {
        const oldSceneId = payload.oldSceneId
        const oldScene = (oldSceneId && store.state.events.currentCast?.scenesWithPresets[oldSceneId]) || null
        if (hasActiveClipsContent(oldScene)) return
        const configuredPlaybackAction = state.videoPlayers[playerName]?.playback_action
        let clipToPlay = state.currentSelectedClip[playerName] ?? null
        const firstClipInPlaylist = getters.orderedClips[0] ?? null
        const clipsConfiguredToChain = firstClipInPlaylist?.clip_end_action === ClipEndAction.Next
        const startPlaybackFromFirstClip = configuredPlaybackAction === PlaybackAction.Start && clipsConfiguredToChain
        if (startPlaybackFromFirstClip || clipToPlay === null ) {
          clipToPlay = firstClipInPlaylist.id
          if (!clipToPlay) {
            console.error('Cannot start playing first clip if no clips are present')
            return
          }
        }
        console.log('handleClipPlaybackOnSceneChange. Sent action for asset', configuredPlaybackAction, clipToPlay)
        await dispatch.playAsset({ playerName, assetId: clipToPlay, action: configuredPlaybackAction })
      }
    },
    async updatePlaybackAction (_context: VideoPlayerContext,
                                payload: { playerId: string, action: PlaybackAction, castId?: string })
                                : Promise<ResultVoid<'invalid_params'|'database'>>
    {
      const castId = payload.castId ?? store.state.events.currentCast?.id
      if (!castId) {
        return Result.fail('invalid_params', 'cannot update playlist playback_action if no current cast is set')
      }
      const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(castId).set({
        [payload.playerId]: {
          playback_action: payload.action
        }
      }, MergeOption.MERGE)
      return result
    },
    async updatePlaybackEndAction (_context: VideoPlayerContext,
                                   payload: { playerId: string, action: PlaybackEndAction, castId?: string })
                                   : Promise<ResultVoid<'invalid_params'|'database'>>
    {
      const castId = payload.castId ?? store.state.events.currentCast?.id
      if (!castId) {
        return Result.fail('invalid_params', 'cannot update playlist playback_end_action if no current cast is set')
      }
      const result = await studio.db.collection(CollectionNames.VIDEO_PLAYER).doc(castId).set({
        [payload.playerId]: {
          playback_end_action: payload.action
        }
      }, MergeOption.MERGE)
      return result
    },
    requestPlaybackOnceClipsAvailable (context: VideoPlayerContext) {
      const { commit } = videoPlayerModuleActionContext(context)
      commit.requestPlaybackOnceClipsAvailable(true)
      tryToAutoStartPlayback()
    },
    async stopPlayback (context: VideoPlayerContext) {
      const { dispatch, state } = videoPlayerModuleActionContext(context)
      const currentClipId = state.currentSelectedClip.player1 ?? null
      if (!currentClipId) return
      await dispatch.stopAsset({ playerName: 'player1', assetId: currentClipId })
    }
  },
  mutations: {
    updateVideoPlayerDoc (state: VideoPlayerState, snapData: KeyDict<VideoPlayer>) {
      if (snapData.currentSelectedClip !== undefined) {
        state.currentSelectedClip = snapData.currentSelectedClip
        delete snapData.currentSelectedClip
      }
      for (const [playerName, videoPlayerData] of Object.entries(snapData)) {
        if (typeof videoPlayerData === 'object') {
          let player = state.videoPlayers[playerName]
          const currentCast = store.state.events.currentCast
          if (!player || player.castId !== currentCast?.id) {
            // Note: Assuming the subscription that calls updateVideoPlayerDoc is for the current cast, is not ideal.
            const playheadDelayTime = store.state.events.currentCast?.isCrossContinent === true ? 2.65 : 0.65
            player = new VideoPlayer(playerName, { playheadDelayTime, castId: currentCast?.id })
          }
          player.fromJSON(videoPlayerData)
          state.videoPlayers[playerName] = player
          const status = statusBlur.blurredStatus(calculateVideoPlayerStatus(state, playerName))
          if (state.videoPlayerStatus[playerName] !== status) {
            state.videoPlayerStatus[playerName] = status
            if (status === SystemStatus.ERROR) {
              store.dispatch.logs.addLog({
                message: 'Error in clipplayer',
                level: LogLevel.Error,
                data: {
                  castId: store.state.events.currentCast?.id ?? 'unknown',
                  name: playerName,
                  state: { ...state }
                }
              })
            }
          }
        }
      }

      store.commit.videoPlayer.calculateLocalStates()
      store.dispatch.videoPlayer.updateSelectedClipIfNecessary()
      if (state.autoStartPlaybackRequested) tryToAutoStartPlayback()
    },
    setLastPauseClicked (state: VideoPlayerState, query: { assetId: string, playerName: string }) {
      if (!state.lastClicked[query.playerName]) {
        state.lastClicked[query.playerName] = { [query.assetId]: new VideoPlayerLastClicked() }
      }
      if (!state.lastClicked[query.playerName][query.assetId]) {
        state.lastClicked[query.playerName][query.assetId] = new VideoPlayerLastClicked()
      }
      state.lastClicked[query.playerName][query.assetId].pauseTime = Date.now()
    },
    setLastPlayClicked (state: VideoPlayerState, query: { assetId: string, playerName: string}) {
      if (!state.lastClicked[query.playerName]) {
        state.lastClicked[query.playerName] = { [query.assetId]: new VideoPlayerLastClicked() }
      }
      if (!state.lastClicked[query.playerName][query.assetId]) {
        state.lastClicked[query.playerName][query.assetId] = new VideoPlayerLastClicked()
      }
      state.lastClicked[query.playerName][query.assetId].playTime = Date.now()
    },
    setLastStoppedClicked (state: VideoPlayerState, query: { assetId: string, playerName: string }) {
      if (!state.lastClicked[query.playerName]) {
        state.lastClicked[query.playerName] = { [query.assetId]: new VideoPlayerLastClicked() }
      }
      if (!state.lastClicked[query.playerName][query.assetId]) {
        state.lastClicked[query.playerName][query.assetId] = new VideoPlayerLastClicked()
      }
      state.lastClicked[query.playerName][query.assetId].stopTime = Date.now()
    },
    calculateLocalStates (state: VideoPlayerState) {
      for (const [playerName, videoPlayer] of Object.entries(state.videoPlayers)) {
        if (state.localStates[playerName] === undefined) {
          state.localStates[playerName] = {}
        }
        for (const assetId of videoPlayer.loadedAssets()) {
          // Only update state if the clip is present in all actual_states.
          let inAllActualStates = true
          for (const actualState of Object.values(videoPlayer.actual_states)) {
            // Check for mismatch of missing asset in actual state.
            if (actualState.videos && actualState.videos[assetId] === undefined) {
              inAllActualStates = false
              break
            }
          }
          if (inAllActualStates) { // Update state
            const lastClicked = state.lastClicked[playerName]?.[assetId] ?? new VideoPlayerLastClicked()
            state.localStates[playerName][assetId] = {
              playstate: videoPlayer.getState(assetId, lastClicked),
              playhead: videoPlayer.getPlayhead(assetId)
            }
            if (state.localStates[playerName][assetId].playstate === VideoPlayerPlayState.PLAYING
              && state.localStates[playerName][assetId].playhead > 2)
            {
              // if a clip is moved to the next one and is playing because of chaining, we need to adjust the
              // currentselectedclip to the one that is playing
              const canUpdateVideoPlayer = can.value(AbilityAction.Edit, AbilitySubject.Cast)
              if (assetId !== state.currentSelectedClip[playerName] && canUpdateVideoPlayer) {
                store.dispatch.videoPlayer.setCurrentSelectedClip({
                  assetId,
                  player: playerName
                })
              }
            }
          }
        }
        // Check for mismatch of clip ids, or their removal from the actual states. And remove them from the local state
        // correspondingly.
        const actualStates = Object.values(videoPlayer.actual_states)
        const localAssetIds = Object.keys(state.localStates[playerName])
        for (const assetId of localAssetIds) {
          const noLongerInAllActualStates = !actualStates.every((actualState) => {
            return !actualState.videos || actualState.videos[assetId] !== undefined
          })
          if (noLongerInAllActualStates) delete state.localStates[playerName][assetId]
        }
      }
    },
    cleanUpVideoPlayer (state: VideoPlayerState) {
      state.autoStartPlaybackRequested = false
      state.currentSelectedClip = {}
      state.videoPlayers = {}
      state.videoPlayerStatus = {}
      state.localStates = {}
      state.lastClicked = {}
      store.commit.videoPlayer.clearStatusBlur()
      clearCalculateLocalStatesTimeout()
    },
    clearStatusBlur () {
      statusBlur.clear()
    },
    requestPlaybackOnceClipsAvailable (state: VideoPlayerState, enabled: boolean) {
      state.autoStartPlaybackRequested = enabled
    }
  }
})

export default videoPlayerModule
export const videoPlayerModuleActionContext = (context: VideoPlayerContext) => moduleActionContext(context, videoPlayerModule)
