import { UserAgent } from '@sentry/browser/dist/integrations'
import { VideoObjectNetworkStats } from '@/classes/VideoObjectNetworkStats'
import { KENV } from './admin'

export enum LogLevel {
  Info = 'info',
  Error = 'error',
  Warning = 'warning'
}

// FIXME; there is also 1 in src/store/module, which one to choose
export interface LogMessage {
  '@timestamp'?: number,
  cc_uid?: string,
  message?: string,
  level?: LogLevel,
  clientip?: string
  caster?: string,
  k360event?: string,
  jitter?: number,
  latency?: number,
  logger_name?: string
  stats?: VideoObjectNetworkStats
  [detail: string]: any
}

export interface ClientInfo {
  agent: UserAgent|string,
  application: string,
  KENV: KENV,
  version: string,
  cc_cast?: string,
  cc_event?: string
}
