import { Loader } from 'kiswe-ui'
import { CastStatus } from '@/types/casts'
import { GConstructor, Mixin } from './base'
import { HasDuration } from './hasDuration'
import { HasQuality } from './hasQuality'


export function hasMixer<TBase extends GConstructor<HasQuality> & GConstructor<HasDuration>,
                         C extends boolean> (Base: TBase, allowCreateMixer: C) {
  return class MixerClass extends Base {
    status: CastStatus
    m3u8: string|null
    k360_event_id: number|string|null
    video_start_time: number|null
    create_event: boolean               // TODO: make into C so that the compiler knows this at compile-time

    constructor (...data: any[]) {
      super(...data)
      const shouldCreateMixer = Loader.loadBooleanOrNull(data[0], 'create_event', null)
      this.create_event = allowCreateMixer && shouldCreateMixer !== false
      this.status = Loader.loadString(data[0], 'status', CastStatus.Unknown) as CastStatus
      this.m3u8 = Loader.loadStringOrNull(data[0], 'm3u8', null)
      this.k360_event_id = Loader.loadAndCast<number | string>(data[0], 'k360_event_id', null)
      this.video_start_time = Loader.loadNumberOrNull(data[0], 'video_start_time', null)
    }

    setVideoStartTime (time: Date|null) {
      if (time === null) this.video_start_time = null
      else this.video_start_time = time.getTime() / 1000
      return this
    }

    get isBooting () {
      return !this.video_start_time
    }
  }
}

export type HasMixer = Mixin<typeof hasMixer>
