import fb from '../../firebase'
import { ChatWidgetState, RootState, UnsubscribeFunction } from '../types'
import { ChatWidget, ChatMessage, ChatMessageType } from '@/types/chatwidget'
import { moduleActionContext } from '@/store'
import { ActionContext } from 'vuex'
import firebase from 'firebase/compat/app'
import { KeyDict } from '@/types'
import { CollectionNames } from '@/modules/database/utils'
import { defineModule } from 'direct-vuex'
import getUrls from 'get-urls'

const subscriptions: KeyDict<{ destroy: UnsubscribeFunction|null, count: number }> = {}

type ChatWidgetContext = ActionContext<ChatWidgetState, RootState>
type ChatWidgetCreateTransaction = {
  transaction: firebase.firestore.Transaction,
  docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  data: {
    teamid: string,
    castid: string
  }
}

const chatWidgetModule = defineModule({
  namespaced: true,
  state: {
    id: null,
    widget: {
      cast_id: '',
      istyping: {},
      messages: [],
      team_id: '',
      type: 'chat'
    }
  } as ChatWidgetState,
  mutations: {
    update (state: ChatWidgetState, chatwidget: { id: string, data: ChatWidget }) {
      state.id = chatwidget.id
      state.widget = chatwidget.data
      state.widget.messages = chatwidget.data.messages
    },
    addMessage (state: ChatWidgetState, message: ChatMessage) {
      state.widget.messages.push(message)
    },
    settyping (state: ChatWidgetState, data: { [ userId: string ]: number }) {
      if (!state.widget.istyping) {
        state.widget.istyping = {}
      }
      state.widget.istyping[data.userid] = data.now
    }
  },
  actions: {
    subscribe (context: ChatWidgetContext, widgetid: string) {
      const { commit } = chatwidgetModuleActionContext(context)
      if (!subscriptions[widgetid]) {
        subscriptions[widgetid] = { destroy: null, count: 0 }
      }
      if (subscriptions[widgetid].count === 0) {
        subscriptions[widgetid].destroy = fb.db.collection(CollectionNames.WIDGETS).doc(widgetid).onSnapshot(snap => {
          const data = snap.data()
          if (data && data.type !== 'chat') {
            console.error(`Subscribing to chatwidget that is not of the type chat ${widgetid}`)
          } else if (data) {
            const chatwidget = data as ChatWidget
            commit.update({ id: snap.id, data: chatwidget })
          }
        })
      }
      subscriptions[widgetid].count += 1
    },
    unsubscribe (_context: ChatWidgetContext, widgetid: string) {
      if (!subscriptions[widgetid]) {
        console.error(`Trying to unsubscribe from chatwidget ${widgetid}, but was neversubscribed before`)
        return
      }
      if (subscriptions[widgetid].count === 0) {
        console.error(`Trying to unsubscribe from chatwidget ${widgetid}, but were not subscribed to it`)
        return
      }
      subscriptions[widgetid].count--
      const destroyFunction = subscriptions[widgetid].destroy
      if (subscriptions[widgetid].count === 0 && destroyFunction !== null) {
        destroyFunction()
        subscriptions[widgetid].destroy = null
      }
    },
    async uploadImage (context: ChatWidgetContext, file: File) : Promise<string> {
      const { state } = chatwidgetModuleActionContext(context)
      return new Promise((resolve, reject) => {
        const timestamp = (new Date()).getTime()
        const uploadTask = fb.firestorage.ref(`widget/chat/${state.id}/${timestamp}`).put(file)
          uploadTask.on('state_changed', sp => {
            console.log('upload state ', sp)
          },
          error => {
            reject(error)
          }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              resolve(downloadURL)
            }).catch(error => {
              reject(error)
            })
          })
      })
    },
    async addMessage (context: ChatWidgetContext, message: ChatMessage) {
      const { state, dispatch } = chatwidgetModuleActionContext(context)
      if (!state.id) {
        throw new Error('Not subscribed to a chat widget yet')
      }
      message.timestamp = (new Date()).getTime()
      if (message.type === ChatMessageType.File && message.data.file instanceof File) {
        const url  = await dispatch.uploadImage(message.data.file)
        delete message.data.file
        message.data.file = { url }
      }
      if (message.type === 'text') {
        const urls = getUrls(message.data.text)
        for (const url of urls) {
          const urlLow = url.toLowerCase()
          if (urlLow.endsWith('.gif') || urlLow.endsWith('.jpg') || urlLow.endsWith('.png') || urlLow.endsWith('.jpeg')) {
            message.type = ChatMessageType.File
            if (message.data.text !== undefined) message.data.text = message.data.text.replace(url, '')
            message.data.file = { url: url.replace('http://', 'https://') }
          } else if (urlLow.startsWith('https://giphy.com')) {
            const ids = url.split('/')[4].split('-')
            const id = ids[ids.length - 1]
            message.type = ChatMessageType.File
            if (message.data.text) message.data.text = message.data.text.replace(url, '')
            message.data.file = { url: `https://media.giphy.com/media/${id}/source.gif` }
          } else if (urlLow.startsWith('https://twitter.com/')) {
            message.data.twitterId = url.split('/')[5]
            message.type = ChatMessageType.Tweet
          }
        }
      }
      await fb.db.collection('widgets').doc(state.id).update({
        messages: fb.arrayUnionField(message)
      })
    },
    async delete (context: ChatWidgetContext, message: ChatMessage) {
      const { state } = chatwidgetModuleActionContext(context)
      const index = state.widget.messages.findIndex((mes: ChatMessage) => mes.timestamp === message.timestamp && mes.author === message.user)
      if (index === -1) {
        throw (new Error('message not found'))
      }
      const messages = JSON.parse(JSON.stringify(state.widget.messages))
      messages[index].deleted = true
      // tcuypers: This could give race conditions, but I am unable to update a single entry in the array
      if (state.id) {
        await fb.db.collection('widgets').doc(state.id).set({ messages }, { merge: true })
      }
    },
    async istyping (context: ChatWidgetContext, userid: string) {
      const { state, commit } = chatwidgetModuleActionContext(context)
      if (!state.id) {
        throw new Error('Not subscribed to a chat widget yet')
      }
      const now = (new Date()).getTime()
      if (!state.widget.istyping || !state.widget.istyping[userid] || state.widget.istyping[userid] < now - 1000) {
        commit.settyping({ userid: now })
        await fb.db.collection('widgets').doc(state.id).set({
          istyping: {
            [userid]: now
          }
        }, { merge: true })
      }
    },
    async create (_context: ChatWidgetContext, { teamid, castid }: { teamid: string, castid: string }): Promise<string> {
      const response = await fb.db.collection('widgets').add({
        type: 'chat',
        team_id: teamid,
        cast_id: castid,
        istyping: {},
        messages: []
      })
      return response.id
    },
    createWithTransaction (_context: ChatWidgetContext, { transaction, docRef, data }: ChatWidgetCreateTransaction): string {
      transaction.set(docRef, {
        type: 'chat',
        team_id: data.teamid,
        cast_id: data.castid,
        istyping: {},
        messages: []
      })
      return docRef.id
    }
  }
})

export default chatWidgetModule
export const chatwidgetModuleActionContext = (context: ChatWidgetContext) => moduleActionContext(context, chatWidgetModule)
