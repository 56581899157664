import { RecordedSourceDoc, ReplayClipDoc, ReplayPlaylistDoc } from '.'

// Classes for state object type in Replay store state
export class RecordedSource {
  id: string
  url: string
  name: string|null
  inputStreamId: string|null

  constructor (doc: RecordedSourceDoc) {
    this.id = doc.id
    this.url = doc.url
    this.name = doc.name
    this.inputStreamId = doc.input
  }
}

export class ReplayClip {
  id: string
  sourceId: string
  name: string
  start: number
  end: number
  thumbnailUrl: string|null
  thumbnailTime: number
  tags: Set<string>

  constructor (doc: ReplayClipDoc) {
    this.id = doc.id
    this.sourceId = doc.source_id
    this.name = doc.name
    this.start = doc.start
    this.end = doc.end
    this.thumbnailUrl = doc.thumbnail
    this.thumbnailTime = doc.thumbnail_time
    this.tags = (doc.tags) ? new Set(doc.tags) : new Set([])
  }
}

export class ReplayPlaylist {
  id: string
  name: string
  clipIdList: string[]
  chainClips: boolean
  restartClips: boolean
  switchSceneAtEnd: boolean

  constructor (doc: ReplayPlaylistDoc) {
    this.id = doc.id
    this.name = doc.name
    this.clipIdList = doc.clip_id_list
    this.chainClips = doc.chain_clips
    this.restartClips = doc.restart_clips
    this.switchSceneAtEnd = doc.switch_scene_at_end
  }
}
