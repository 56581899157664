import { KeyDict } from '../types'
import fb from '@/firebase'

const convertArray = (oldArray: unknown[] = []): unknown[] => {
  const newArray: unknown[] = []
  oldArray.forEach((item) => {
    const isObject = (typeof item === 'object' && item !== null)
    const isArray = Array.isArray(item)
    const isDate = item instanceof Date
    if (isObject && !isArray && !isDate) {
      newArray.push(Convertable.toObject({ ...item }))
    } else if (isArray) {
      newArray.push(convertArray(item))
    } else {
      newArray.push(item)
    }
  })
  return newArray
}

export default class Convertable {
  [key: string]: unknown

  constructor (config: KeyDict<unknown> = {}) {
    for (const [key, value] of Object.entries(config)) {
      this[key] = value
    }
  }

  // Firebase does not support custom classes like Convertable, InvitedCaster, etc so we need to convert it back to
  // regular objects, see error below.
  // Error creating cast FirebaseError: [code=invalid-argument]: Function DocumentReference.set() called with
  // invalid data. Unsupported field value: a custom InvitedCaster object [...]

  toObject (): KeyDict<unknown> {
    return Convertable.toObject(this)
  }

  static toObject (oldDict: unknown = {}): KeyDict<unknown> {
    if (typeof oldDict !== 'object') return {}
    if (Array.isArray(oldDict)) return {}
    if (oldDict === null) return {}
    const newDict: KeyDict<unknown> = {}
    for (const [key, value] of Object.entries(oldDict)) {
      if (key.startsWith('_')) continue
      const isFirebaseDelete = value === fb.deleteField
      const isObject = (typeof value === 'object' && value !== null)
      const isArray = Array.isArray(value)
      const isDate = value instanceof Date
      if (isObject && !isArray && !isDate && !isFirebaseDelete) {
        newDict[key] = this.toObject({ ...value })
      } else if (isArray) {
        newDict[key] = convertArray(value)
      } else {
        newDict[key] = value
      }
    }
    return newDict
  }

}
