import { StringTranslation } from "../types"

const translation: StringTranslation = {
  "cast_letsgetstarted_text": "Commençons.",
  "cast_getstarted_button": "Commençons.",
  "cast_loadingcast_text": "Chargement de l'évènement",
  "cast_pleasefillinname_question": "Veuillez remplir vos informations détaillées avant de joindre la session.",
  "cast_consentterms_text": "J’ai lu et j’accepte les <a href=\"{tosUrl}\" target=\"_blank\">conditions générales et la politique de confidentialité</a> de l’Athlete Moment qui stipulent que mon image, mon portrait, ma voix et une partie ou l’ensemble de ma contribution puissent être distribuées et utilisées par OBS, le CIO, leurs cessionnaires et leurs sous-licenciés (par ex., les diffuseurs autorisés) dans le contexte des Jeux olympiques et de leur diffusion, ainsi que les épreuves olympiques.",
  "cast_consentterms_error": "Vous devez accepter les termes et conditions.",
  "cast_consentage_text": "J’ai au moins <span>{age}</span> ans et j’ai l’âge légal, conformément aux lois aplicables.",
  "cast_consentage_error": "Vous devez avoir au moins {age} ans.",
  "cast_is_scheduled_text": "This production is not live and the video previews are disabled",
  "cast_enter_text": "Press to enter the production",
  "cast_enter_button": "Enter",
  "cast_tryingtoconnect_text": "Trying to connect.",
  "cast_waitingforcast_title": "Rendez le moment légendaire",
  "cast_waitingforcast_text": "Attendez le prochain modérateur disponible. Cela peut prendre un certain temps. Ne rafraîchissez pas la page.",
  "cast_waitingforcast_guide1_title": "Positionnez-vous correctement",
  "cast_waitingforcast_guide2_title": "Exprimez votre enthousiasme !",
  "cast_waitingforcast_guide3_title": "Veillez à votre apparence et à votre comportement",
  "cast_waitingforcast_guide1_text": "Assurez-vous d'être bien positionné(e), assis(e) au centre de l'écran et en maintenant une bonne posture. N'oubliez pas, le monde vous regarde !",
  "cast_waitingforcast_guide2_text": "Soyez enthousiaste et applaudissez fort pour exprimer votre exaltation et votre engagement.",
  "cast_waitingforcast_guide3_text": "Évitez les marques visibles, les déclarations politiques et toute forme d'insulte pour observer le décorum.",
  "cast_waitingforcastautoaccept_text": "Vous êtes prêt(e) à rencontrer un(e) modérateur(trice).",
  "cast_switch_team_text": "This cast requires you to switch to a different team",
  "cast_switch_team_button": "Switch Team",
  "complete_join_text": "S'inscrire pour le Moment de l'athlète",
  "general_pleasewait_text": "Veuillez s'il vous plaît patienter.",
  "general_yourname_label": "Votre nom ",
  "general_firstname_label": "Prénom",
  "general_lastname_label": "Nom de famille",
  "general_email_label": "Adresse électronique",
  "general_country_label": "Pays",
  "general_firstname_error": "Veuillez remplir votre prénom.",
  "general_lastname_error": "Veuillez remplir votre nom.",
  "general_requiredallfields_error": "Tous les champs sont obligatoires.",
  "general_pleasefillinyourname_placeholder": "Veuillez remplir votre nom et prénom.",
  "general_pleasefillinfirstname_placeholder": "Veuillez remplir votre prénom.",
  "general_pleasefillinlastname_placeholder": "Veuillez remplir votre nom.",
  "general_pleasefillinemail_placeholder": "Veuillez remplir votre adresse email.",
  "general_join_button": "Enregistrement",
  "general_send_button": "Envoyer",
  "general_yes_button": "Oui",
  "general_no_button": "Non",
  "general_ok_button": "OK",
  "general_close_button": "Fermer",
  "general_done_button": "Terminé",
  "general_register_button": "S'inscrire",
  "general_restart_button": "Redémarrer",
  "general_continue_button": "Continuer",
  "chat_nomessages_placeholder": "Il n'y a pas encore de message sur ce cast.",
  "chat_enteryourmessage_placeholder": "Veuillez écrire votre message ici.",
  "chat_chat_label": "Discussion",
  "chat_unreadmessage_text": "{count} Nouveau message",
  "chat_unreadmessages_text": "{count} Nouveaux messages",
  "redirect_welcometokiswe_text": "Bienvenue",
  "redirect_pleasewaittoredirect_text": "Veuillez patienter pendant que nous vous connectons à …",
  "redirect_end_cast_text": "La modération sera disponible une heure avant le début de l'épreuve de votre athlète. Veuillez revenir plus tard.",
  "redirect_cast_reach_maximum_text": "Ce Moment de l'athlète a atteint actuellement le nombre maximum de participants. Veuillez attendre qu'une place se libère.",
  "redirect_disabled_existing_caster_text": "Nous avons rencontré un problème. Veuillez fermer cet onglet de navigateur ou fermer complètement le navigateur et cliquer à nouveau sur le lien d'origine.",
  "redirect_invalidlink_title": "Lien invalide",
  "redirect_invalidlink_text": "Le lien dont vous avez profité n'est plus valide.",
  "redirect_castnotactive_title": "Il n'y a plus de chambre activée ci-jointe à ce présent lien.",
  "redirect_castnotactive_paragraph": "Veuillez bien réessayer plus tard ou utiliser un lien différent.",
  "actiontab_assets_label": "Assets",
  "actiontab_casters_label": "Casters",
  "actiontab_inputs_label": "Inputs",
  "actiontab_outputs_label": "Output",
  "actiontab_audio_label": "Audio Mixer",
  "actiontab_settings_label": "Settings",
  "actiontab_clips_label": "Clips",
  "actiontab_commentators_label": "Tracks",
  "actiontab_crew_label": "Crew",
  "actiontab_talkback_label": "Talkback",
  "actiontab_chat_label": "TextCast",
  "actiontab_simplecg_label": "Simple CG",
  "actiontab_interactive_label": "Interactive ",
  "actiontab_sctemarkers_label": "SCTE Markers",
  "actiontab_switcher_label": "Scenes",
  "actiontab_layout_label": "Layers",
  "actiontab_preset_label": "Preset",
  "topbar_untileventstarts_text": "jusqu'au début de l'évènement",
  "topbar_untileventends_text": "jusqu'à la fin de l'évènement",
  "usermedia_startcasting_button": "Commencer le cast.",
  "usermedia_connect_button": "Connexion",
  "usermedia_detectdevices_button": "Appareils de détection",
  "usermedia_detecting_text": "Détecter",
  "usermedia_clickbutton_tooltip": "Veuillez cliquer sur le bouton de cast pour le commencer.",
  "usermedia_echocancellation_text": "Activer l'annulation de l'écho",
  "usermedia_camera_label": "Caméra",
  "usermedia_microphone_label": "Microphone",
  "usermedia_learnaccess_button": "Cliquez ici pour savoir comment accéder",
  "usermedia_pressallow_text": "Appuyez sur 'Autoriser' sur le pop-up",
  "castgrawl_mutedbycrew_label": "The crew muted you",
  "castgrawl_mutedyourself_label": "You are muted",
  "castgrawl_streamhasissues_label": "There are connection issues. Refresh the page to solve it",
  "audio_copyfromprogram_button": "Copy from Program Mix",
  "audio_applytoprogram_button": "Apply to Program Mix",
  "audio_programmix_label": "Program mix",
  "audio_monitor_label": "Monitor mix",
  "talkback_nomembers_text": "No crew members are connected to talkback",
  "talkback_setincomingmaster_button": "Set incoming master levels",
  "talkback_setincomingindividual_button": "Set incoming individual levels",
  "error_offlinenotice_text": "Vous êtes actuellement déconnecté(e). Nous réessayons la connexion. Si cet état est maintenu pour quelques secondes, veuillez renouveler cette page après avoir vérifié l'état d'Internet.",
  "cast_waitforqueue_text": "Il semble être encombré. Veuillez patienter.",
  "cast_yourpositioninqueue_text": "Votre emplacement en queue : <strong class=\"primary\">{currentPositionInQueue}</strong> / {totalInQueue}",
  "cast_needpermissions_text": "Veuillez autoriser l'accès à vos appareils.",
  "cast_deviceerror_text": "Nous avons détecté un problème avec votre {device}. Fermez le navigateur, vérifiez la connexion de votre {device} et cliquez à nouveau sur le lien invité.",
  "cast_deniedpermission_text": "Vous avez refusé la demande d'accès et vous ne pouvez pas entrer tant que vous n'autorisez pas l'accès via les paramètres du navigateur. Vous pouvez fermer le navigateur et revenir au lien fourni.",
  "cast_deniedguestlink_text": "You are already logged in. If you want to use this link, please log out first.",
  "cast_deniednotinvited_text": "Vous n'êtes pas autorisé(e) à rejoindre cet événement.",
  "cast_everythingsetup_text": "Merci, <span class=\"primary\">{display_name}</span>, vous êtes maintenant prêt à joindre la chambre.",
  "cast_moderatorpickyouup_text": "L'administrateur va bientôt accepter votre accès.",
  "cast_chattingwithmoderator_text": "Bienvenue dans l'espace de modération",
  "cast_moderatorrejected_text": "Nous nous excusons de vous informer que l'administrateur n'a pas autorisé votre accès.",
  "endsession_thanks_text": "Merci de vous joindre à nous. Cette page se fermera dans {remainingSecs} secondes.",
  "endsession_thankforcoming_text": "Nous vous remercions de nous joindre{castName}.",
  "endsession_thankforcomingtocast_text": "Nous vous remercions de nous joindre aujourd'hui{castName}.",
  "endsession_closetab_text": "Vous pouvez maintenant fermer cet onglet ou vous connecter avec le compte existant.",
  "endsession_backtologin_button": "Réserver la connexion",
  "tally_roomactive_text": "La chambre est prête à la diffusion",
  "tally_roomlive_text": "La chambre est en direct !",
  "leavecast_popup_title": "Leave production",
  "leavecast_popup_description": "Are you sure you want to leave the production? The production will not stop, you just leave it.",
  "leavecast_popupanonymous_title": "Leave production",
  "leavecast_popupanonymous_description": "Are you sure you want to leave the production? The production will not stop, you just leave it. You won't be able to come back.",
  "browsercheck_browser_label": "Navigateur",
  "browsercheck_version_label": "Version",
  "browsercheck_supportbrowser_text": "L'accès au Moment de l'athlète ne peut se faire qu'avec un navigateur Chrome, Edge, Firefox, Safari.",
  "browsercheck_supportmobilebrowser_text": "L'accès au Moment de l'athlète ne peut se faire qu'avec un navigateur Chrome, Safari.",
  "browsercheck_download_chrome": "Téléchargez Chrome",
  "browsercheck_download_edge": "Téléchargez Edge",
  "browsercheck_download_safari": "Téléchargez Safari",
  "browsercheck_download_firefox": "Téléchargez Firefox",
  "browsercheck_appstore_paragraph": "Kiswe Studio est disponible sur Apple Store.",
  "browsercheck_systemnotsupported_paragraph": "This device does not meet the minimal requirements for using Kiswe Studio.",
  "statusstrip_leaveevent_tooltip": "Leave production",
  "statusstrip_selectdevices_tooltip": "Sélectionnez les appareils",
  "screenmode_pleaserotate_title": "Veuillez tourner l'appareil.",
  "screenmode_pleaserotateios_text": "Athlete Moment vous permet d'avoir la meilleure expérience en mode paysage. Vous êtes maintenant en mode portrait. Veuillez tourner votre appareil.",
  "screenmode_pleaserotateandroid_text": "Athlete Moment vous permet d'avoir la meilleure expérience en mode paysage. Vous êtes maintenant en mode portrait. Veuillez tourner votre appareil ou utiliser le bouton ci-dessous.",
  "screenmode_pleaserotate_actionbutton": "Tourner l'appareil",
  "athletemoments_welcome_title": "Bienvenue à Athlete Moment!",
  "athletemoments_redirect_paragraph": "Vous serez bientôt redirigé(e) vers une autre page.",
  "castwelcome_wowzaissues_error": "Le système gère actuellement beaucoup de charges. Veuillez réessayer dans un peu de temps.",
  "privatecall_nolocalstream_error": "Nous demandons votre accord de configurer un stream. Si vous l'avez déjà autorisé, il y a probablement une erreur de votre webcam.",
  "privatecall_sendwarning_notification": "administrateur ne pouvait pas vous écouter ni regarder. Veuillez vérifier si votre microphone et caméra fonctionnent bien et ensuite réessayer.",
  "privatecall_sendwarning_label": "Veuillez réessayer.",
  "privatecall_sendwarning_footer": "Vous n’avez pas entendu le moniteur (clairement) ? Cliquez ici.",
  "browsercheck_ioscontinueweb_paragraph": "Bienvenue ! Si votre appareil iOS a trois ans ou plus (2018 ou avant), veuillez installer l’application Kiswe Studio depuis l’Apple Store. Sinon, sélectionnez ‘Continuer sur le web’ ci-dessous pour une expérience optimale.",
  "browsercheck_ioscontinueweb_button": "Continuer sur le web",
  "statusstrip_selectdevicesdisabled_tooltip": "Le changement d’appareil n’est pas actuellement pas autorisé.",
  "cast_moderatorrequestedstreamrestart_text": "Le modérateur a demandé le redémarrage du stream. Un moment, s’il vous plaît.",
  "selftest_checkcpuandmemory_message": "Nous observons actuellement vos appareils.",
  "selftest_setup_text": "Sélectionnez votre configuration",
  "selftest_running_text": "Testez votre connexion",
  "selftest_fail_text": "Cette configuration ne fonctionne pas",
  "selftest_restart_text": "Vérifiez votre connexion Internet ou sélectionnez un autre appareil",
  "selftest_casting_text": "Connexion, veuillez patienter",
  "selftest_noreply_text": "Nous ne pouvons pas vous connecter. Vérifiez la connexion Internet.",
  "selftest_pleasecloseapps_question": "Veuillez fermer toutes les applications et tous les onglets.",
  "selftest_selectothercamera_question": "Veuillez choisir autre caméra.",
  "selftest_checkyourvolume_question": "Please check if your volume is on",
  "selftest_seeotherguests_question": "Can you see the other 4 streams?",
  "selftest_checkyourheadset_question": "If you are using a headset? Can you hear yourself now?",
  "selftest_hearotherguests_question": "Can you hear this stream?",
  "selftest_performance_question": "selftest_performance_question",
  "selftest_canyouhearlocal_question": "Pouvez-vous vous entendre ?",
  "selftest_canyouhearremote_question": "Entendez-vous le son ?",
  "selftest_canyouseelocal_question": "Pouvez-vous vous voir ?",
  "selftest_canyouseeremote_question": "Pouvez-vous voir la vidéo ?",
  "selftest_canyouseeyourselflocal_body": "selftest_canyouseeyourselflocal_body",
  "selftest_canyouseeyourselfremote_body": "selftest_canyouseeyourselfremote_body",
  "selftest_onsuccess_button": "selftest_onsuccess_button",
  "selftest_onfail_button": "selftest_onfail_button",
  "selftest_successresult_message": "selftest_successresult_message",
  "selftest_successresult_body": "selftest_successresult_body",
  "selftest_failresult_message": "selftest_failresult_message",
  "selftest_failresult_body": "selftest_failresult_body",
  "selfteststats_canseeselflocal_term": "selfteststats_canseeselflocal_term",
  "selfteststats_canseeselfremote_term": "selfteststats_canseeselfremote_term",
  "selfteststats_canhearselfremote_term": "selfteststats_canhearselfremote_term",
  "selfteststats_canseeothers_term": "selfteststats_canseeothers_term",
  "selfteststats_canhearothers_term": "selfteststats_canhearothers_term",

  "cast_consentprivacy_text": "본인은 참여가 개인 정보의 의무적인 수집과 사용을 요구한다는 것, 또한 특정 <a href=\"https://www.obs.tv/prx/asset.php?tgt=DelegatesSKAMOonboardingENSK-208d50ae628f.pdf&gen=1\" target=\"_blank\">해외 법인들</a>과 함께 그러한 처리를 위임할 수 있음을 설명한 <a href=\"https://www.obs.tv/prx/asset.php?tgt=B2022ATHLETESMOMENTTERMSANDCONDITIONSEN-SK-e74519b05996.pdf&gen=1\" target=\"_blank\">개인정보 보호정책</a>을 읽고 승인했습니다.",
  "cast_consentprivacy_error": "",  // cast_consentprivacy_error is only available for Korean

  "casttypeselector_switcher_name": "",
  "casttypeselector_switcher_description": "",
  "casttypeselector_switcher_short_description": "",
  "casttypeselector_fanroom_name": "",
  "casttypeselector_fanroom_description": "",
  "casttypeselector_fanroom_short_description": "",
  "casttypeselector_stationswitcher_name": "Station",
  "casttypeselector_stationswitcher_description": "Broadcast your Rooms",
  "casttypeselector_stationswitcher_short_description": "Station",
  "srt_type_passthrough_label": "Passthrough",
  "srt_type_multitrack_label": "Multi-Track"
}

export default translation
