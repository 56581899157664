import { isStringWrapped } from '@/modules/common/utils/string'
import { Layout, LayoutBoxField } from '@/types/layouts'
import { CAST_PROPERTY_BOX_MARKER } from '../types'
import { LayoutBox, LayoutBoxType } from '@/types/layouts'
import { AssetType } from '@/types/assets'
import { KisweFont, KisweSelectOption } from 'kiswe-ui'
import { Feature, hasFeature } from '@/featureFlags/featureFlags'

export const BOX_MIN_WIDTH = 10
export const BOX_MAX_WIDTH = 1920

export const BOX_MIN_HEIGHT = 10
export const BOX_MAX_HEIGHT = 1080

export const hasCastPropertyBoxes = (layout: Layout): boolean => {
  return Object.values(layout.boxes).some((box) => isStringWrapped(box.name.toLowerCase(), CAST_PROPERTY_BOX_MARKER))
}

export const getBoxTypeOptions = (): KisweSelectOption[]  => {
  return [
    { label: 'Video', value: AssetType.Video },
    { label: 'Image', value: AssetType.Graphic },
    ...(hasFeature(Feature.FanRoom) ? [{ label: 'Special Field', value: AssetType.SimpleCG }] : [])
  ]
}

export const getDefaultBoxInstance = (
  boxId: string,
  name: string,
  zIndex: number
): LayoutBox => {
  return new LayoutBox({
    id: boxId,
    name: name,
    field: LayoutBoxField.BACK,
    stretchContent: true,
    stretchVertical: true,
    type: LayoutBoxType.BOX,
    types: [AssetType.Graphic],
    zindex: zIndex
  })
}

export const FONT_FAMILIES_FALLBACK = [KisweFont.WorkSans, KisweFont.Helvetica, KisweFont.Arial, KisweFont.SansSerif]

export const getFullFontFamily = (fontFamily?: KisweFont|string|null) => {
  const allFontFamilies = fontFamily ? [fontFamily, ...FONT_FAMILIES_FALLBACK] : FONT_FAMILIES_FALLBACK
  const allFonts = new Set(allFontFamilies)
  return [...allFonts].join(', ')
}



