import CastFilter from '@/classes/CastFilter'
import { CastsState } from '@/store/types'
import { pickBy } from 'lodash'

const getters = {
  filteredCasts: (state: CastsState) => (filter: CastFilter = state.filters.default) => {
    return pickBy(state.filteredCasts, (cast) => filter.castIds.includes(cast.id))
  }
}

export default getters
