/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable-next-line max-lines-per-function */
import { CollectionNames } from '@/modules/database/utils'
import { AssetType } from '../../../types/assets'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import { Layout } from '../../../types/layouts'
import { Region } from '../../../types/ops'
import { Scene } from '../../../types/scenes'
import firebase from 'firebase/compat/app'
import DefaultIdsMapping from './defaultIdsMapping'
import { CreateTeamErrorCode } from './errors'
import { CastInputStream } from '@/modules/streams/types'
import Convertable from '@/classes/Convertable'
import { Result } from 'kiswe-ui'

// Changes ids of the default assets to those of the corresponding assets of the team.
export const updateContents = (
  sceneOrLayout: Partial<Scene|Layout>,
  idsMapping: Partial<DefaultIdsMapping>
): void => {
  if (sceneOrLayout?.contents === undefined) return
  for (const [contentId, content] of Object.entries(sceneOrLayout.contents)) {
    let newContentId = idsMapping.assetIds?.get(contentId)
    if (content.type === AssetType.Broadcast) {
      newContentId = idsMapping.streamIds?.get(contentId)
    }
    if (newContentId !== undefined) {
      if (content.type === AssetType.Broadcast && content.name === contentId) {
        content.name = newContentId
      }
      sceneOrLayout.contents[newContentId] = content
      delete sceneOrLayout.contents[contentId]
      content.id = newContentId
    }
  }
}

/** @deprecated we don't add any default templates anymore */
const addDefaultCastTemplatesToTeam = async (
  transaction: firebase.firestore.Transaction,
  db: firebase.firestore.Firestore,
  teamId: string,
  idsMapping: DefaultIdsMapping,
  defaultRegion: Region
): Promise<Result<number, CreateTeamErrorCode>> => {
  const templates = await db
    .collection(CollectionNames.CAST_TEMPLATES)
    .where('team_id', '==', 'all')
    .where('deleted', '==', false)
    .get()
  templates.docs.forEach((doc) => {
    const castTemplate = doc.data() as CastTemplate
    castTemplate.team_id = teamId
    for (const scene of Object.values(castTemplate.scenes)) {
      updateContents(scene, idsMapping)

      const sceneLayout = scene.scene_layout
      if (sceneLayout.id === undefined) continue

      const newLayoutId = idsMapping.layoutIds.get(sceneLayout.id)
      if (newLayoutId !== undefined) sceneLayout.id = newLayoutId
      sceneLayout.team_id = teamId
      updateContents(sceneLayout, idsMapping)
      if (sceneLayout.existingLayout) updateContents(sceneLayout.existingLayout, idsMapping)
    }

    if (castTemplate.asset_groups) {
      for (const assetGroupId of Object.keys(castTemplate.asset_groups)) {
        const newAssetGroupId = idsMapping.assetGroupIds.get(assetGroupId)
        if (newAssetGroupId !== undefined && castTemplate.asset_groups[newAssetGroupId] === undefined) {
          delete castTemplate.asset_groups[assetGroupId]
          castTemplate.asset_groups[newAssetGroupId] = true
        }
      }
    }

    if (castTemplate.input_streams) {
      for (const streamId of Object.keys(castTemplate.input_streams)) {
        const newStreamId = idsMapping.streamIds.get(streamId)
        if (newStreamId !== undefined && castTemplate.input_streams[newStreamId] === undefined) {
          delete castTemplate.input_streams[streamId]
          castTemplate.input_streams[newStreamId] = new CastInputStream()
        }
      }
    }

    if (defaultRegion) castTemplate.region = defaultRegion
    castTemplate.created_by = ''
    // FIXME: we should be able to run this in the cloud function as well, to the timestamp should come from there.
    // @ts-ignore
    castTemplate.created_date = Date.now()

    // Clear any unwanted props.
    castTemplate.invited_casters = {}
    castTemplate.custom_rtmp_destinations = {}
    if (castTemplate.asset_groups === undefined) castTemplate.asset_groups = {}

    const docRef = db.collection(CollectionNames.CAST_TEMPLATES).doc()
    castTemplate.id = docRef.id
    transaction.set(docRef, Convertable.toObject(castTemplate))
  })

  return Result.success(templates.size)
}

export default addDefaultCastTemplatesToTeam
