import { Loader, Result } from 'kiswe-ui'
import { REPLAY_PLAYLIST_ALL_CLIPS } from '../types'
import { KeyDict } from '@/types'
import { generateRandomString, trimOrError } from '@/modules/common/utils'

// Classes for types in ReplayConfig collection

export class RecordedSourceDoc {
  id: string
  url: string
  name: string|null
  input: string|null

  constructor (snapData: unknown) {
    this.id = Loader.loadString(snapData, 'id')
    this.url = Loader.loadString(snapData, 'url')
    this.name = Loader.loadStringOrNull(snapData, 'name', null)
    this.input = Loader.loadStringOrNull(snapData, 'input', null)
  }
}

export class ReplayClipDoc {
  id: string
  source_id: string
  name: string
  start: number
  end: number
  thumbnail: string|null
  thumbnail_time: number
  tags: string[]

  constructor (snapData: unknown) {
    this.id = Loader.loadString(snapData, 'id')
    this.source_id = Loader.loadString(snapData, 'source_id')
    this.name = Loader.loadString(snapData, 'name', '')
    this.start = Loader.loadNumber(snapData, 'start')
    this.end = Loader.loadNumber(snapData, 'end')
    this.thumbnail = Loader.loadStringOrNull(snapData, 'thumbnail', null)
    this.thumbnail_time = Loader.loadNumber(snapData, 'thumbnail_time')

    const rawTags = Loader.loadArrayOrNull(snapData, 'tags') ?? []
    for (const tag of rawTags) {
      if (typeof tag !== 'string') {
        throw new TypeError(`ReplayClip: tags must be of type string array. Found: ${rawTags}`)
      }
    }
    this.tags = rawTags as string[]
  }
}

export class ReplayPlaylistDoc {
  id: string
  name: string
  clip_id_list: string[]
  chain_clips: boolean
  restart_clips: boolean
  switch_scene_at_end: boolean

  constructor (snapData: unknown) {
    this.id = Loader.loadString(snapData, 'id')
    this.name = Loader.loadString(snapData, 'name')

    const rawClipList = Loader.loadArrayOrNull(snapData, 'clip_id_list') ?? []
    this.clip_id_list = []
    for (const clip of rawClipList) {
      if (typeof clip === 'string') this.clip_id_list.push(clip)
      else throw new Error(`ReplayPlaylist clipIdList must be a string ID array. Found: ${rawClipList}`)
    }
    this.chain_clips = Loader.loadBoolean(snapData, 'chain_clips', true)
    this.restart_clips = Loader.loadBoolean(snapData, 'restart_clips', true)
    this.switch_scene_at_end = Loader.loadBoolean(snapData, 'switch_scene_at_end', true)
  }
}

export class ReplayConfigDoc {
  cast_id: string
  team_id: string
  record_mixers_count: number
  recorded_sources: KeyDict<RecordedSourceDoc>
  replay_clips: KeyDict<ReplayClipDoc>
  replay_playlists: KeyDict<ReplayPlaylistDoc>

  constructor (snapData: unknown) {
    this.cast_id = Loader.loadString(snapData, 'cast_id')
    this.team_id = Loader.loadString(snapData, 'team_id')
    this.record_mixers_count = Loader.loadNumber(snapData, 'record_mixers_count')
    this.recorded_sources = Loader.loadKeyDict(snapData, 'recorded_sources',
      (data: unknown) => new RecordedSourceDoc(data))
    this.replay_clips = Loader.loadKeyDict(snapData, 'replay_clips', (data: unknown) => new ReplayClipDoc(data))
    this.replay_playlists = Loader.loadKeyDict(snapData, 'replay_playlists',
      (data: unknown) => new ReplayPlaylistDoc(data))
  }

  static load (snapData: unknown): Result<ReplayConfigDoc, 'invalid_params' | 'unknown'> {
    try {
      return Result.success(new ReplayConfigDoc(snapData))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  static getNewReplayId () {
    return 'replay'+generateRandomString(20)
  }

  static newDocForCast (castId: string, teamId: string, recorderCount: number): ReplayConfigDoc {
    const defPlaylistId = this.getNewReplayId()

    return {
      cast_id: trimOrError(castId, 'castId', 'newDocForCast'),
      team_id: trimOrError(teamId, 'teamId', 'newDocForCast'),
      record_mixers_count: recorderCount,
      recorded_sources: {},
      replay_clips: {},
      replay_playlists: {
        [defPlaylistId]: {
          id: defPlaylistId,
          name: REPLAY_PLAYLIST_ALL_CLIPS,
          clip_id_list: [],
          chain_clips: true,
          restart_clips: false,
          switch_scene_at_end: false
        }
      }
    }
  }
}
