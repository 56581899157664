/* eslint-disable max-lines */
import { computed } from 'vue'
import { AbilityAction, AbilitySubject } from '@/abilitiesByRole'
import { can } from '@/ability'
import { StreamSubView } from '@/modules/dashboard/types'
import { Feature, hasFeature } from '@/featureFlags/featureFlags'
import { cloneDeep } from 'lodash'

export interface DashboardMenuItem {
  nodeKey: string
  label: string
  pathName: string
  allow?: AbilitySubject
  icon?: string
  subView?: string
  children?: DashboardMenuItem[]
  hasBreak?: boolean,
  checkFeature?: Feature
}

const DASHBOARD_ITEMS: DashboardMenuItem[] = [
  {
    label: 'Productions',
    nodeKey: 'events',
    pathName: 'events',
    subView: 'active',
    allow: AbilitySubject.UiProductions,
    icon: 'events',
    children: [
      { label: 'Active', pathName: 'events', subView: 'active', nodeKey: 'events/active' },
      { label: 'Scheduled', pathName: 'events', subView: 'scheduled', nodeKey: 'events/scheduled' }
    ]
  }, {
    label: 'Clipper',
    pathName: 'clipper',
    nodeKey: 'clipper',
    allow: AbilitySubject.UiClipper,
    icon: 'clipper',
    hasBreak: true
  }, {
    label: 'Streams',
    pathName: 'streams',
    nodeKey: 'streams',
    allow: AbilitySubject.UiStreams,
    icon: 'streams-square',
    subView: StreamSubView.Input,
    children: [
      { label: 'Input', pathName: 'streams', subView: StreamSubView.Input, nodeKey: 'streams/input' },
      { label: 'Output', pathName: 'streams', subView: StreamSubView.Output, nodeKey: 'streams/output' }
    ]
  }, {
    label: 'Team',
    pathName: 'team',
    subView: 'active',
    nodeKey: 'team',
    allow: AbilitySubject.UiTeams,
    icon: 'user-star',
    children: [
      { label: 'Active', pathName: 'team', subView: 'active', nodeKey: 'team/active' },
      { label: 'Pending', pathName: 'team', subView: 'pending', nodeKey: 'team/pending' }
    ]
  }, {
    label: 'Media',
    pathName: 'assets',
    nodeKey: 'assets',
    allow: AbilitySubject.UiMedia,
    icon: 'assets',
    children: [
      { label: 'Assets', pathName: 'assets', nodeKey: 'assets/media' }
    ]
  }, {
    label: 'Templates',
    pathName: 'castTemplates',
    nodeKey: 'cast-templates',
    allow: AbilitySubject.UiTemplates,
    icon: 'templates'
  }, {
    label: 'Layouts',
    pathName: 'layout',
    nodeKey: 'layout',
    allow: AbilitySubject.UiLayouts,
    icon: 'scenes'
  }, {
    label: 'Markers',
    pathName: 'marker',
    nodeKey: 'marker',
    allow: AbilitySubject.UiMarkers,
    icon: 'hairpin',
    checkFeature: Feature.Markers
  }, {
    label: 'Settings',
    pathName: 'teamsettings',
    subView: 'branding',
    nodeKey: 'teamsettings',
    icon: 'settings',
    hasBreak: true,
    children: [
      { label: 'Branding', pathName: 'teamsettings', allow: AbilitySubject.UiBranding,
        subView: 'branding', nodeKey: 'teamsettings/branding' },
      { label: 'Teams', pathName: 'teamsettings', allow: AbilitySubject.UiGroups,
        subView: 'groups', nodeKey: 'teamsettings/teams' },
      { label: 'Roles', pathName: 'teamsettings', allow: AbilitySubject.UiRoles,
        subView: 'roles', nodeKey: 'teamsettings/roles' }
    ]
  }, {
    label: 'Billing',
    pathName: 'billing', // hidden except for admin, superadmin
    nodeKey: 'billing',
    allow: AbilitySubject.UiBilling,
    icon: 'billing',
    hasBreak: true
  }, {
    label: 'Dashboard',
    pathName: 'dashboard',
    nodeKey: 'dashboard',
    allow: AbilitySubject.UiDashboard,
    icon: 'fan-polls',
    hasBreak: true,
    checkFeature: Feature.Dashboard
  }, {
    label: 'Moderation',
    pathName: 'moderation',
    nodeKey: 'moderation',
    allow: AbilitySubject.UiModeration,
    icon: 'eye'
  }, {
    label: 'Operations',
    pathName: 'monitor',
    nodeKey: 'ops',
    icon: 'ops',
    allow: AbilitySubject.UiOperations,
    children: [
      { label: 'Monitor', pathName: 'monitor', icon: 'monitor', nodeKey: 'monitor' },
      {
        label: 'Streams',
        pathName: 'streamMonitor',
        subView: 'input',
        icon: 'streams-square',
        nodeKey: 'stream-monitor',
        children: [
          {
            label: 'Input',
            pathName: 'streamMonitor',
            subView: 'input',
            icon: 'input',
            nodeKey: 'stream-monitor/input'
          },
          { label: 'Output',
            pathName: 'streamMonitor',
            subView: 'output',
            icon: 'output',
            nodeKey: 'stream-monitor/output'
          }
        ]
      }, {
        label: 'Clients',
        pathName: 'clients',
        nodeKey: 'clients',
        subView: 'active',
        icon: 'user-star',
        children: [
          { label: 'Active', pathName: 'clients', subView: 'active', nodeKey: 'clients/active' },
          { label: 'Inactive', pathName: 'clients', subView: 'inactive', nodeKey: 'clients/inactive' }
        ]
      }
    ]
  }, {
    label: 'Technology',
    pathName: 'wrtcServers',
    nodeKey: 'technology',
    icon: 'rocket',
    allow: AbilitySubject.UiTechnology,
    children: [
      { label: 'Servers', pathName: 'wrtcServers', icon: 'server-2', nodeKey: 'wrtc-servers' },
      { label: 'Stop manager', pathName: 'startStopManager', icon: 'pause', nodeKey: 'start-stop-manager' },
      { label: 'Transcoders', pathName: 'transcoding', icon: 'rotate', nodeKey: 'transcoding' },
      { label: 'Releases', pathName: 'backendServices', icon: 'rocket', nodeKey: 'backend-services' },
      { label: 'Archiver', pathName: 'archiver', icon: 'assets', nodeKey: 'archiver' },
      { label: 'Flags', pathName: 'flags', icon: 'flag', nodeKey: 'flags' },
      { label: 'Fan rooms', pathName: 'roomsProcessing', icon: 'csv', nodeKey: 'rooms-processing' },
      {
        label: 'Styleguide',
        pathName: 'styleguide',
        nodeKey: 'kiswe-styleguide',
        icon: 'color-palette',
        children: [
          { label: 'Themes', pathName: 'styleguide', nodeKey: 'styleguide' },
          { label: 'Icon', pathName: 'styleguideKisweIcon', nodeKey: 'styleguide/icon' },
          { label: 'Button', pathName: 'styleguideKisweButton', nodeKey: 'styleguide/button' },
          { label: 'Input', pathName: 'styleguideKisweInput', nodeKey: 'styleguide/input' },
          { label: 'Select', pathName: 'styleguideKisweSelect', nodeKey: 'styleguide/select' }
        ]
      }
    ]
  }
]

const filterAllowedItems = (items: DashboardMenuItem[]): DashboardMenuItem[] => {
  const dashMenuCopy = cloneDeep(items)
  return dashMenuCopy.reduce((filteredItems: DashboardMenuItem[], item) => {
    if (!!item.checkFeature && !hasFeature(item.checkFeature))  {
      return filteredItems
    }
    if (item.children) item.children = item.children.filter((child) => isAllowed(child))
    if (isAllowed(item) && (!item.children || item.children.length > 0)) {
      filteredItems.push({ ...item })
    }
    return filteredItems
  }, [])
}

const isAllowed = (item: { allow?: AbilitySubject }) => !item.allow || can.value(AbilityAction.Manage, item.allow)

const useDashboardItems = () => {
  const dashboardItems = computed<DashboardMenuItem[]>(() => filterAllowedItems(DASHBOARD_ITEMS))
  return {
    dashboardItems
  }
}

export default useDashboardItems
