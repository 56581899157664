import { AudioContext } from '@/types/audio'
import { AudioPanelState } from './audio.state'
import { LocalAudioSource } from '@/store/types'

export const mutations = {
  setActiveAudioContext (state: AudioPanelState, panelType: AudioContext) {
    state.activeAudioContext = panelType
  },
  setActiveAudioChannelsState (state: AudioPanelState, payload: { streamChannelId: string, enabled: boolean }|null) {
    let audioState = state.audioContextStates[state.activeAudioContext]
    if (!audioState) {
      audioState = {
        localChannels: [],
        localSource: null
      }
      state.audioContextStates[state.activeAudioContext] = audioState
    }
    if (!payload) {
      audioState.localChannels = []
      return
    }
    const existingIndex = audioState.localChannels.indexOf(payload.streamChannelId)
    if (payload.enabled && existingIndex === -1) {
      audioState.localChannels.push(payload.streamChannelId)
    } else if (!payload.enabled && existingIndex !== -1) {
      audioState.localChannels.splice(existingIndex, 1)
    }
  },
  setActiveAudioLocalSourceState (state: AudioPanelState, source: LocalAudioSource|null) {
    let audioState = state.audioContextStates[state.activeAudioContext]
    if (!audioState) {
      audioState = {
        localSource: source,
        localChannels: []
      }
      state.audioContextStates[state.activeAudioContext] = audioState
    } else {
      audioState.localSource = source
    }
  },
  clearAudioState (state: AudioPanelState) {
    state.activeAudioContext = AudioContext.GridPanel
    state.audioContextStates = {}
  }
}
