<template>
  <KisweFormGroup gap="lg">
    <KisweFormField>
      <KisweSelect
        label="Video preview"
        data-qa="select-video-quality"
        v-model="previewQuality.video"
        :options="videoPreviewOptions"
      />
    </KisweFormField>
    <!--
      TODO: fix this so an empty field is no longer needed. This was a quick fix to remove chromakey preview quality.
      Leaving the formfield empty is an easy way to retain the field's placement and dimensions so the video quality
      selector above does not fill up all the available width but stays the same.
    -->
    <KisweFormField />
  </KisweFormGroup>
</template>

<script lang='ts'>
import { defineComponent, computed, reactive, watch } from 'vue'
import { PreviewVideoQualityType, PreviewSettings } from '@/types/users'
import { KisweFormField, KisweFormGroup, KisweSelect } from 'kiswe-ui'
import { KisweSelectOption } from 'kiswe-ui'

export default defineComponent({
  name: 'UserPreviewQualitySettings',
  components: {
    KisweFormField,
    KisweFormGroup,
    KisweSelect
  },
  props: {
    previewVideoQuality: {
      type: String as () => PreviewVideoQualityType,
      required: true
    }
  },
  emits: {
    update (_previewSettings: PreviewSettings) { return true }
  },
  setup (props , { emit }) {
    const previewQuality = reactive({
      video: props.previewVideoQuality
    })
    const videoPreviewOptions = computed(() => {
      const options: KisweSelectOption[] = []
      for (const key in PreviewVideoQualityType) {
        const targetKey = key as keyof typeof PreviewVideoQualityType
        options.push({ label: PreviewVideoQualityType[targetKey], value: PreviewVideoQualityType[targetKey] })
      }
      return options
    })

    watch(previewQuality, (newPreviewQuality) => emit('update', newPreviewQuality))

    return {
      previewQuality,
      videoPreviewOptions
    }
  }
})
</script>
