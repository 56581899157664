import { createFirebaseDatabase } from '@/modules/firebase/utils/firebaseInit'

// @ts-ignore
if (process.env.NODE_ENV === 'test' && !createFirebaseDatabase.isTestDb) {
  const errorMsg = ''
    + 'Not using test Firebase while in test mode.\n'
    + 'Your test environment should be overwriting the import path:\n'
    + '  @/modules/firebase/utils/firebaseInit\n'
    + 'with:\n'
    + '  @/modules/firebase/utils/firebaseInitTest'
  throw new Error(errorMsg)
}

const fb = createFirebaseDatabase()
export default fb
