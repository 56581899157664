import { StringTranslation } from "../types"

const translation: StringTranslation = {
  "cast_letsgetstarted_text": "Let\'s get started",
  "cast_getstarted_button": "Get Started",
  "cast_loadingcast_text": "Loading...",
  "cast_pleasefillinname_question": "Please fill in your details before joining the session.",
  "cast_consentterms_text": "Ho letto e accetto i <a href=\"{tosUrl}\" target=\"_blank\">Termini e l’Informativa sulla Privacy</a> Athlete Moment in modo che la mia immagine, la mia voce e il mio contributo nel suo complesso, o parte di esso, possano essere distribuiti e utilizzati da OBS, dal CIO e dai loro sub-licenziatari e assegnatari (ad esempio le emittenti autorizzate) nel contesto dei Giochi Olimpici e della loro trasmissione, così come nelle sedi Olimpiche.",
  "cast_consentterms_error": "You must agree to the Terms and Conditions",
  "cast_consentage_text": "Ho almeno <span>{age}</span> anni e sono maggiorenne ai sensi delle leggi applicabili.",
  "cast_consentage_error": "You must be at least {age} years old",
  "cast_is_scheduled_text": "This production is not live and the video previews are disabled",
  "cast_enter_text": "Press to enter the production",
  "cast_enter_button": "Enter",
  "cast_tryingtoconnect_text": "Trying to connect.",
  "cast_waitingforcast_title": "Fai si che il momento sia leggendario",
  "cast_waitingforcast_text": "Attendi il prossimo moderatore disponibile. L'operazione può richiedere del tempo. Non aggiornare la pagina.",
  "cast_waitingforcast_guide1_title": "Posizionati correttamente",
  "cast_waitingforcast_guide2_title": "Esprimi entusiasmo!",
  "cast_waitingforcast_guide3_title": "Assicurati del tuo aspetto e condotta",
  "cast_waitingforcast_guide1_text": "Assicurati di essere ben posizionato, di fronte alla camera e con una buona postura. Ricorda, il mondo ti sta guardando!",
  "cast_waitingforcast_guide2_text": "Sii entusiasta e fai il tifo apertamente, mostra il tuo entusiasmo!",
  "cast_waitingforcast_guide3_text": "Evita di mostrare marchi, simboli politici ed evita insulti, mantieni un certo decoro.",
  "cast_waitingforcastautoaccept_text": "Sei pronti ad essere messo in contatto con un moderatore.",
  "cast_switch_team_text": "This cast requires you to switch to a different team",
  "cast_switch_team_button": "Switch Team",
  "complete_join_text": "Registrati per Il \"Momento Atleta\"",
  "general_pleasewait_text": "Please wait",
  "general_yourname_label": "Your name",
  "general_firstname_label": "Nome",
  "general_lastname_label": "Cognome",
  "general_email_label": "Email",
  "general_country_label": "Paese",
  "general_firstname_error": "You must provide a first name",
  "general_lastname_error": "You must provide a last name",
  "general_requiredallfields_error": "Tutti i campi sono necessari.",
  "general_pleasefillinyourname_placeholder": "Please fill in your name",
  "general_pleasefillinfirstname_placeholder": "Please fill in your first name",
  "general_pleasefillinlastname_placeholder": "Please fill in your last name",
  "general_pleasefillinemail_placeholder": "Please fill in your email address",
  "general_join_button": "Join",
  "general_send_button": "Send",
  "general_yes_button": "Si",
  "general_no_button": "No",
  "general_ok_button": "OK",
  "general_close_button": "Chiudere",
  "general_done_button": "Fatto",
  "general_register_button": "Registrati",
  "general_restart_button": "Riavvia",
  "general_continue_button": "Continua",
  "chat_nomessages_placeholder": "No messages sent",
  "chat_enteryourmessage_placeholder": "Type here",
  "chat_chat_label": "Chat",
  "chat_unreadmessage_text": "{count} new message",
  "chat_unreadmessages_text": "{count} new messages",
  "redirect_welcometokiswe_text": "Welcome",
  "redirect_pleasewaittoredirect_text": "Please wait while we redirect you...",
  "redirect_end_cast_text": "Il moderatore sara' disponibile una ora prima dell'inizio del evento del vostro atleta. Per cortesia, ritorni piu' tardi.",
  "redirect_cast_reach_maximum_text": "Il momento dell'altleta ha raggiunto il massimo numero di participanti. Per favore attendere il prossimo accesso disponibile.",
  "redirect_disabled_existing_caster_text": "Abbiamo riscontrato un problema. Chiudi questa scheda del browser o chiudi completamente il browser e clicca di nuovo sul link originale.",
  "redirect_invalidlink_title": "Invalid link",
  "redirect_invalidlink_text": "The link you used is no longer valid.",
  "redirect_castnotactive_title": "There's no active room attached to this link",
  "redirect_castnotactive_paragraph": "Please come back later or try a different link.",
  "actiontab_assets_label": "Assets",
  "actiontab_casters_label": "Casters",
  "actiontab_inputs_label": "Inputs",
  "actiontab_outputs_label": "Output",
  "actiontab_audio_label": "Audio Mixer",
  "actiontab_settings_label": "Settings",
  "actiontab_clips_label": "Clips",
  "actiontab_commentators_label": "Tracks",
  "actiontab_crew_label": "Crew",
  "actiontab_talkback_label": "Talkback",
  "actiontab_chat_label": "TextCast",
  "actiontab_simplecg_label": "Simple CG",
  "actiontab_interactive_label": "Interactive",
  "actiontab_sctemarkers_label": "SCTE Markers",
  "actiontab_switcher_label": "Scenes",
  "actiontab_layout_label": "Layers",
  "actiontab_preset_label": "Preset",
  "topbar_untileventstarts_text": "until event starts",
  "topbar_untileventends_text": "until event ends",
  "usermedia_startcasting_button": "Start streaming",
  "usermedia_connect_button": "Connect",
  "usermedia_detectdevices_button": "Detect Devices",
  "usermedia_detecting_text": "Detecting",
  "usermedia_clickbutton_tooltip": "Click the cast button to begin streaming",
  "usermedia_echocancellation_text": "Enable echo cancellation",
  "usermedia_camera_label": "Camera",
  "usermedia_microphone_label": "Microfono",
  "usermedia_learnaccess_button": "Impara come ottenere accesso",
  "usermedia_pressallow_text": "Premi 'Consenti' nel popup",
  "castgrawl_mutedbycrew_label": "The crew muted you",
  "castgrawl_mutedyourself_label": "You are muted",
  "castgrawl_streamhasissues_label": "There are connection issues. Refresh the page to solve it",
  "audio_copyfromprogram_button": "Copy from Program Mix",
  "audio_applytoprogram_button": "Apply to Program Mix",
  "audio_programmix_label": "Program mix",
  "audio_monitor_label": "Monitor mix",
  "talkback_nomembers_text": "No crew members are connected to talkback",
  "talkback_setincomingmaster_button": "Set incoming master levels",
  "talkback_setincomingindividual_button": "Set incoming individual levels",
  "error_offlinenotice_text": "You are currently disconnected. We are trying to reconnect you. If this continues for more than a couple seconds, please check your internet connection and refresh this page.",
  "cast_waitforqueue_text": "It seems like it's a bit crowded. Please wait...",
  "cast_yourpositioninqueue_text": "Your position in queue: <strong class=\"primary\">{currentPositionInQueue}</strong> / {totalInQueue}",
  "cast_needpermissions_text": "Per favore consenti l'accesso al tuo dispositivo",
  "cast_deviceerror_text": "Abbiamo rilevato un problema con il tuo {device}. Chiudi il browser, controlla la connessione del {device} e fai nuovamente clic sul collegamento ospite.",
  "cast_deniedpermission_text": "Non ti e' stato consentito accedere.",
  "cast_deniedguestlink_text": "You are already logged in. If you want to use this link, please log out first.",
  "cast_deniednotinvited_text": "Non ti e' permesso entrare in questa sessione.",
  "cast_everythingsetup_text": "Thank you, <span class=\"primary\">{display_name}</span>, you are now ready to enter the room!",
  "cast_moderatorpickyouup_text": "The moderator will let you in soon.",
  "cast_chattingwithmoderator_text": "Il moderatore ti da il benvenuto",
  "cast_moderatorrejected_text": "Siamo spiacenti, un moderatore ha deciso di non farti entrare.",
  "endsession_thanks_text": "Grazie per unirti, la pagina si chiudera' in {remainingSecs} secondi.",
  "endsession_thankforcoming_text": "Thank you for joining",
  "endsession_thankforcomingtocast_text": "Thank you for joining {castName} today",
  "endsession_closetab_text": "You can close this tab now, or login using an existing account.",
  "endsession_backtologin_button": "Back to login",
  "tally_roomactive_text": "Room Ready for Air",
  "tally_roomlive_text": "Room is live",
  "leavecast_popup_title": "Leave production",
  "leavecast_popup_description": "Are you sure you want to leave the production? The production will not stop, you just leave it.",
  "leavecast_popupanonymous_title": "Leave production",
  "leavecast_popupanonymous_description": "Are you sure you want to leave the production? The production will not stop, you just leave it. You won't be able to come back.",
  "browsercheck_browser_label": "Browser",
  "browsercheck_version_label": "Version",
  "browsercheck_supportbrowser_text": "Il momento dell'atleta puo' solo essere utilizzato con un Navigatore Chrome, Edge, Safari, Firefox",
  "browsercheck_supportmobilebrowser_text": "Il momento dell'atleta puo' solo essere utilizzato con un Navigatore Chrome, Safari",
  "browsercheck_download_chrome": "Scarica Navigatore Chrome",
  "browsercheck_download_edge": "Scarica Navigatore Edge",
  "browsercheck_download_safari": "Scarica Navigatore Safari",
  "browsercheck_download_firefox": "Scarica Navigatore Firefox",
  "browsercheck_appstore_paragraph": "Kiswe Studio is available from the apple store.",
  "browsercheck_systemnotsupported_paragraph": "This device does not meet the minimal requirements for using Kiswe Studio.",
  "browsercheck_ioscontinueweb_paragraph": "Welcome! If your iOS device is 3 years or older (2018 or earlier), please install the Kiswe Studio app from the Apple Store. If not, select 'Continue on web' below for the best experience.",
  "browsercheck_ioscontinueweb_button": "Continue on web",
  "statusstrip_leaveevent_tooltip": "Leave production",
  "statusstrip_selectdevices_tooltip": "Seleziona i dispositivi",
  "statusstrip_selectdevicesdisabled_tooltip": "Changing devices is currently not allowed.",
  "screenmode_pleaserotate_title": "Ruotare il dispositivo",
  "screenmode_pleaserotateios_text": "Il momento dell'atleta si vive meglio in modalità orizzontale. Al momento si sta utilizzando la modalità verticale. Ruotare il dispositivo.",
  "screenmode_pleaserotateandroid_text": "Il momento dell'atleta si vive meglio in modalità orizzontale. Al momento si sta utilizzando la modalità verticale. Ruotare il dispositivo o utilizzare il pulsante sottostante.",
  "screenmode_pleaserotate_actionbutton": "Ruota il mio dispositivo",
  "athletemoments_welcome_title": "Welcome to Athlete Moment!",
  "athletemoments_redirect_paragraph": "You will be redirected shortly.",
  "castwelcome_wowzaissues_error": "The system is handling a lot of load at this moment, please try again in a few minutes",
  "privatecall_nolocalstream_error": "We need your permission to set up a stream. If you've already granted permission there's likely an issue with your webcam.",
  "privatecall_sendwarning_notification": "The moderator could not hear or see you. Please verify your microphone and camera are working properly, then try again.",
  "privatecall_sendwarning_label": "Try again",
  "privatecall_sendwarning_footer": "You did not hear the moderator (clearly)? Click here.",
  "selftest_checkcpuandmemory_message": "We are currently checking your device",
  "selftest_setup_text": "Seleziona il tuo microfono e la tua camera",
  "selftest_running_text": "Controlla la tua connessione",
  "selftest_fail_text": "Questa configurazione non funziona",
  "selftest_restart_text": "Controlla la tua connessione Internet o usa un altro dispositivo",
  "selftest_casting_text": "Connessione in corso, attendere",
  "selftest_noreply_text": "Non possiamo connetterti. Controlla la connessione Internet.",
  "selftest_pleasecloseapps_question": "Please close all other apps/tabs",
  "selftest_selectothercamera_question": "Try to select another camera",
  "selftest_checkyourvolume_question": "Please check if your volume is on. Can you hear yourself now?",
  "selftest_seeotherguests_question": "Can you see the other 4 streams?",
  "selftest_hearotherguests_question": "Can you hear this stream?",
  "selftest_checkyourheadset_question": "If you are using a headset? Can you hear yourself now?",
  "selftest_performance_question": "selftest_performance_question",
  "selftest_canyouhearlocal_question": "Puoi ascoltare la tua voce?",
  "selftest_canyouhearremote_question": "Puoi sentire l'audio?",
  "selftest_canyouseelocal_question": "Puoi vedere te stesso?",
  "selftest_canyouseeremote_question": "Puoi vedere il Video?",
  "selftest_canyouseeyourselflocal_body": "Please verify whether or not the stream to your left works fine. This is your local stream and should work real-time.",
  "selftest_canyouseeyourselfremote_body": "Please verify whether or not the stream to your left works fine. Please note this is the remote stream and thus, will have a slight delay.",
  "selftest_onsuccess_button": "Continue",
  "selftest_onfail_button": "Something went wrong!",
  "selftest_successresult_message": "Selftest complete!",
  "selftest_successresult_body": "Everything is working fine.",
  "selftest_failresult_message": "Selftest failed!",
  "selftest_failresult_body": "Please try again using another device",
  "selfteststats_canseeselflocal_term": "selfteststats_canseeselflocal_term", // TODO
  "selfteststats_canseeselfremote_term": "selfteststats_canseeselfremote_term", // TODO
  "selfteststats_canhearselfremote_term": "selfteststats_canhearselfremote_term", // TODO
  "selfteststats_canseeothers_term": "selfteststats_canseeothers_term", // TODO
  "selfteststats_canhearothers_term": "selfteststats_canhearothers_term", // TODO
  "cast_moderatorrequestedstreamrestart_text": "The moderator requested to restart your stream. One moment please.",

  "cast_consentprivacy_text": "본인은 참여가 개인 정보의 의무적인 수집과 사용을 요구한다는 것, 또한 특정 <a href=\"https://www.obs.tv/prx/asset.php?tgt=DelegatesSKAMOonboardingENSK-208d50ae628f.pdf&gen=1\" target=\"_blank\">해외 법인들</a>과 함께 그러한 처리를 위임할 수 있음을 설명한 <a href=\"https://www.obs.tv/prx/asset.php?tgt=B2022ATHLETESMOMENTTERMSANDCONDITIONSEN-SK-e74519b05996.pdf&gen=1\" target=\"_blank\">개인정보 보호정책</a>을 읽고 승인했습니다.",
  "cast_consentprivacy_error": "",  // cast_consentprivacy_error is only available for Korean

  "casttypeselector_switcher_name": "Switcher",
  "casttypeselector_switcher_description": "",
  "casttypeselector_switcher_short_description": "Fast low latency switching",
  "casttypeselector_fanroom_name": "Connector",
  "casttypeselector_fanroom_description": "In Rooms & Videocalls, you can connect a software or physical station with rooms of guests. Your talent, celebrity or athlete can join their guests while the station ‘travels’ from room to room. Each room has its own unique link. The event allows to switch between rooms, slates and a live ingest feed.",
  "casttypeselector_fanroom_short_description": "",
  "casttypeselector_stationswitcher_name": "Station",
  "casttypeselector_stationswitcher_description": "Broadcast your Rooms",
  "casttypeselector_stationswitcher_short_description": "Station",
  "srt_type_passthrough_label": "Passthrough",
  "srt_type_multitrack_label": "Multi-Track"
}

export default translation
