/* eslint-disable max-lines */
import { ActionContext } from 'vuex'
import { REPLAY_CONFIG_SUB_KEY, ReplayConfigState } from '../types'
import { RootState } from '@/store/types'
import { defineModule } from 'direct-vuex'
import store, { moduleActionContext } from '@/store'
import studio, { CollectionNames } from '@/modules/database/utils'
import { DatabaseAction, MergeOption } from '@/modules/database/utils/database'
import { DeepPartial, KeyDict, PartialRequired } from '@/types'
import { errorIfNegative, generateRandomString, trimOrError } from '@/modules/common/utils'
import { ReplayConfigDoc, ReplayPlaylistDoc } from '../classes'
import { RecordedSource, ReplayClip, ReplayPlaylist } from '../classes/ReplayStore'


// Utility functions


// Store module components
type ReplayConfigContext = ActionContext<ReplayConfigState, RootState>

const state = {
  castId: '',
  recordedSources: {},
  replayClips: {},
  replayPlaylists: {}
} as ReplayConfigState

const getters = {
}

const actions = {
  async subscribeReplayConfig (context: ReplayConfigContext, query: { castId: string }) {
    const { commit } = replayConfigModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: `${REPLAY_CONFIG_SUB_KEY}_${query.castId}`,
      query: studio.db.collection(CollectionNames.REPLAY_CONFIG).doc(query.castId),
      callback: commit.updateReplayConfig
    })
    result.logIfError('subscribeReplayConfig')
  },
  async unsubscribeReplayConfig (_context: ReplayConfigContext, query: { castId: string }) {
    const key = `${REPLAY_CONFIG_SUB_KEY}_${query.castId}`
    studio.subscriptions.unsubscribe(key, () => { void store.dispatch.replayConfig.cleanup() })
  },
  async createReplayConfig (context: ReplayConfigContext, params: { castId: string, teamId: string,
    recorderCount: number }) {
    if (!params.castId) throw new Error('Cannot create ReplayConfig. Missing Cast ID')
    if (!params.teamId) throw new Error('Cannot create ReplayConfig. Missing Team ID')

    const { state, dispatch } = replayConfigModuleActionContext(context)
    if (state.castId === params.castId) {
      console.warn(`Attempt to recreate replay config for cast ${params.castId}. Skipping create.`)
      return
    }

    const replayConfigDoc = ReplayConfigDoc.newDocForCast(params.castId, params.teamId, params.recorderCount)
    const result = await dispatch.updateReplayConfigDoc(replayConfigDoc)
    result.logIfError('createReplayConfig')
  },
  cleanup (context: ReplayConfigContext) {
    const { commit } = replayConfigModuleActionContext(context)
    commit.cleanup()
  },
  async updateReplayConfigDoc (_context: ReplayConfigContext, configDoc: DeepPartial<ReplayConfigDoc>) {
    if (!configDoc.cast_id) throw new Error('Cannot update replay config. Cast ID not set')

    return await studio.db.collection(CollectionNames.REPLAY_CONFIG).doc(configDoc.cast_id)
      .set(configDoc, MergeOption.MERGE)
  },
  async setRecordedSource (context: ReplayConfigContext, params: RecordedSource) {
    const { state, dispatch } = replayConfigModuleActionContext(context)

    const replayConfigDoc: DeepPartial<ReplayConfigDoc> = {
      cast_id: state.castId,
      recorded_sources: {
        [params.id]: {
          id: trimOrError(params.id, 'sourceId', 'setRecordedSource'),
          name: params.name,
          url: trimOrError(params.url, 'url', 'setRecordedSource'),
          input: params.inputStreamId
        }
      }
    }
    const result = await dispatch.updateReplayConfigDoc(replayConfigDoc)
    result.logIfError('addRecordedSource')
  },
  // eslint-disable-next-line @stylistic/max-len
  async addReplayClips (context: ReplayConfigContext, params: Omit<ReplayClip,'id'|'thumbnailUrl'>[] ): Promise<string[]> {

    const { state, dispatch } = replayConfigModuleActionContext(context)
    const replayConfigDoc: DeepPartial<ReplayConfigDoc> = {}
    replayConfigDoc.cast_id = state.castId
    replayConfigDoc.replay_clips = {}

    for (const param of params) {
      const clipId = generateRandomString(20)
      const sourceId = trimOrError(param.sourceId, 'sourceId', 'addReplayClips')

      replayConfigDoc.replay_clips[clipId] = {
        id: clipId,
        source_id: sourceId,
        name: param.name,
        start: errorIfNegative(param.start, 'start', 'addReplayClip'),
        end: errorIfNegative(param.end, 'end', 'addReplayClip'),
        thumbnail_time: errorIfNegative(param.thumbnailTime, 'thumbnailTime', 'addReplayClip'),
        tags: [...(param.tags ?? [])]
      }
    }

    const result = await dispatch.updateReplayConfigDoc(replayConfigDoc)
    if (result.isFailure) {
      result.logIfError('addReplayClips')
      return []
    }
    return Object.keys(replayConfigDoc.replay_clips)
  },
  async updateReplayClips (context: ReplayConfigContext, params: PartialRequired<ReplayClip, 'id'>[] ) {
    const { state, dispatch } = replayConfigModuleActionContext(context)
    const replayConfigDoc: DeepPartial<ReplayConfigDoc> = {}
    replayConfigDoc.cast_id = state.castId
    replayConfigDoc.replay_clips = {}

    for (const param of params) {
      const startElem = (param.start !== undefined) ? { start: errorIfNegative(param.start, 'start',
        'updateReplayClips') } : null
      const endElem = (param.end !== undefined) ? { end: errorIfNegative(param.end, 'end', 'updateReplayClips') } : null
      const nameElem = (param.name !== undefined) ? { name: param.name } : null
      const thumbnailUrlElem = (param.thumbnailUrl !== undefined) ? { thumbnail: param.thumbnailUrl } : null
      const thumbnailTimeElem = (param.thumbnailTime !== undefined) ? {
        thumbnail_time: errorIfNegative(param.thumbnailTime, 'thumbnailTime', 'updateReplayClips') } : null
      const tagsElem = (param.tags) ? { tags: [...param.tags] } : null
      replayConfigDoc.replay_clips[param.id] = {
        id: param.id,
        ...startElem,
        ...endElem,
        ...nameElem,
        ...thumbnailUrlElem,
        ...thumbnailTimeElem,
        ...tagsElem
      }
    }
    const result = await dispatch.updateReplayConfigDoc(replayConfigDoc)
    result.logIfError('updateReplayClips')
  },
  // eslint-disable-next-line max-lines-per-function
  async deleteReplayClip (context: ReplayConfigContext, clipId: string) {
    const { state } = replayConfigModuleActionContext(context)
    if (!state.castId) throw new Error(`Cannot delete replay clip ${clipId}. No active cast`)

    // Gather clip references from existing playlists
    const updatedPlaylist = {} as KeyDict<Partial<ReplayPlaylistDoc>>
    for (const [playlistId, playlist] of Object.entries(state.replayPlaylists)) {
      const playlistWithoutClip = playlist.clipIdList.filter((aClipId) => aClipId !== clipId)
      if (playlistWithoutClip.length < playlist.clipIdList.length) {
        updatedPlaylist[playlistId] = { clip_id_list: playlistWithoutClip }
      }
    }
    let updatedPlaylistConfigDoc: DeepPartial<ReplayConfigDoc>|null = null
    if (Object.keys(updatedPlaylist).length > 0) {
      updatedPlaylistConfigDoc = {
        replay_playlists: {
          ...updatedPlaylist
        }
      }
    }

    // Delete clip and its references from all playlists
    const replayConfigDoc = {
      replay_clips: {
        [clipId]: DatabaseAction.deleteField()
      },
      ...updatedPlaylistConfigDoc
    }
    const result = await studio.db.collection(CollectionNames.REPLAY_CONFIG).doc(state.castId)
      .set(replayConfigDoc, MergeOption.MERGE)
    result.logIfError('deleteReplayClip')
  },
  async addReplayPlaylist (context: ReplayConfigContext, params: Omit<ReplayPlaylist, 'id'>) {

    const { state, dispatch } = replayConfigModuleActionContext(context)
    const id = ReplayConfigDoc.getNewReplayId()
    const replayConfigDoc: DeepPartial<ReplayConfigDoc> = {
      cast_id: state.castId,
      replay_playlists: {
        [id]: {
          id,
          name: trimOrError(params.name, 'name', 'addReplayPlaylist'),
          clip_id_list: params.clipIdList ?? [],
          chain_clips: params.chainClips,
          restart_clips: params.restartClips,
          switch_scene_at_end: params.switchSceneAtEnd
        }
      }
    }
    const result = await dispatch.updateReplayConfigDoc(replayConfigDoc)
    if (result.isFailure) {
      result.logIfError('addReplayPlaylist')
      return ''
    }
    return id
  },
  async deleteReplayPlaylist (context: ReplayConfigContext, playlistId: string) {
    const { state } = replayConfigModuleActionContext(context)
    if (!state.castId) throw new Error(`Cannot delete replay playlist ${playlistId}. No active cast`)

    const replayConfigDoc = {
      replay_playlists: {
        [playlistId]: DatabaseAction.deleteField()
      }
    }
    const result = await studio.db.collection(CollectionNames.REPLAY_CONFIG).doc(state.castId)
      .set(replayConfigDoc, MergeOption.MERGE)
    result.logIfError('deleteReplayClip')
  },
  async updateReplayPlaylist (context: ReplayConfigContext, params: PartialRequired<ReplayPlaylist, 'id'>) {
    const { state, dispatch } = replayConfigModuleActionContext(context)
    const nameElem = (params.name !== undefined ?
      { name: trimOrError(params.name, 'name', 'updateReplayPlaylist') } : null)
    const clipIdListElem = (params.clipIdList ? { clip_id_list: params.clipIdList } : null)
    const chainClipsElem = (params.chainClips !== undefined ? { chain_clips: params.chainClips } : null)
    const restartClipsElem = (params.restartClips !== undefined ? { restart_clips: params.restartClips } : null)
    const switchSceneElem = (params.switchSceneAtEnd !== undefined ?
      { switch_scene_at_end: params.switchSceneAtEnd } : null)

    const replayConfigDoc: DeepPartial<ReplayConfigDoc> = {
      cast_id: state.castId,
      replay_playlists: {
        [params.id]: {
          id: params.id,
          ...nameElem,
          ...clipIdListElem,
          ...chainClipsElem,
          ...restartClipsElem,
          ...switchSceneElem
        }
      }
    }
    const result = await dispatch.updateReplayConfigDoc(replayConfigDoc)
    result.logIfError('updateReplayPlaylist')
  }
}

const mutations = {
  updateReplayConfig (state: ReplayConfigState, snapData: ReplayConfigDoc) {
    if (snapData) {
      const recordedSources = {} as KeyDict<RecordedSource>
      for (const [id, sourceDoc] of Object.entries(snapData.recorded_sources)) {
        recordedSources[id] = new RecordedSource(sourceDoc)
      }
      const replayClips = {} as KeyDict<ReplayClip>
      for (const [id, clipDoc] of Object.entries(snapData.replay_clips)) {
        replayClips[id] = new ReplayClip(clipDoc)
      }
      const replayPlaylists = {} as KeyDict<ReplayPlaylist>
      for (const [id, playlistDoc] of Object.entries(snapData.replay_playlists)) {
        replayPlaylists[id] = new ReplayPlaylist(playlistDoc)
      }
      state.castId = snapData.cast_id
      state.recordedSources = recordedSources
      state.replayClips = replayClips
      state.replayPlaylists = replayPlaylists
    } else {
      console.warn('Received empty ReplayConfigDoc. Ignoring')
    }
  },
  cleanup (state: ReplayConfigState) {
    state.castId = ''
    state.recordedSources = {}
    state.replayClips = {}
    state.replayPlaylists = {}
  }
}

const replayConfigModule = defineModule({
  namespaced: true,
  state,
  getters,
  mutations,
  actions
})

export default replayConfigModule
export const replayConfigModuleActionContext = (context: ReplayConfigContext) =>
  moduleActionContext(context, replayConfigModule)
