import { Loader } from 'kiswe-ui'
import { HexCode } from 'kiswe-ui'
import { BoxStylingBase, BoxStylingBaseCss } from './BoxStylingBase'
import { BoxStyling, UNIT_PX } from './BoxStyling'
import { AssetType } from '@/types/assets'
import { isAssetTypeVideoOrText, SimplecgCSS } from '@/types/layouts'
import { getFullFontFamily } from '../utils'

export type BoxStylingSimpleCgCss = BoxStylingBaseCss & Pick<
  CSSStyleDeclaration,
  'fontFamily'|'fontSize'|'justifyContent'|'paddingLeft'|'paddingTop'|'color'|'backgroundColor'|'borderRadius'
>

export class BoxStylingSimpleCg extends BoxStylingBase {
  fontFamily: string = 'Work Sans'
  fontSize: number = 16
  justifyContent: boolean = true
  paddingLeft: number = 10
  paddingTop: number = 10
  color: HexCode = '#FFFFFFFF'
  backgroundColor: HexCode = '#818181'
  borderRadius: number = 3

  constructor (data?: unknown) {
    super(data)
    if (!data) return
    this.fontFamily = Loader.loadString(data, 'fontFamily', this.fontFamily)
    this.fontSize = Loader.loadNumber(data, 'fontSize', this.fontSize)
    this.justifyContent = Loader.loadBoolean(data, 'justifyContent', this.justifyContent)
    this.paddingLeft = Loader.loadNumber(data, 'paddingLeft', this.paddingLeft)
    this.paddingTop = Loader.loadNumber(data, 'paddingTop', this.paddingTop)
    this.borderRadius = Loader.loadNumber(data, 'borderRadius', this.borderRadius)
    this.backgroundColor = Loader.loadHexCode(data, 'backgroundColor', this.backgroundColor)
    this.color = Loader.loadHexCode(data, 'color', this.color)
  }

  override get css (): BoxStylingSimpleCgCss {
    return {
      ...super.css,
      fontFamily: getFullFontFamily(this.fontFamily),
      fontSize: this.fontSize + UNIT_PX,
      justifyContent: this.justifyContent ? 'left' : 'normal',
      paddingLeft: this.paddingLeft + UNIT_PX,
      paddingTop: this.paddingTop + UNIT_PX,
      borderRadius: this.borderRadius + UNIT_PX,
      backgroundColor: this.backgroundColor,
      color: this.color
    }
  }

  static getBoxStylingFromLegacyLayoutBox (data: unknown, types?: AssetType[]): BoxStyling {
    const styling = {
      // TODO: use ContentAlignment.Center, move classes out of layouts.ts
      alignContent: Loader.loadString(data, 'contentalign', 'center'),
      aspectRatio: Loader.loadBoolean(data, 'aspectRatio', false),
      height: Loader.loadNumber(data, 'height', 1080),
      left: Loader.loadNumber(data, 'left', 0),
      top: Loader.loadNumber(data, 'top', 0),
      width: Loader.loadNumber(data, 'width', 1920)
    }
    return isAssetTypeVideoOrText(types) ? new BoxStylingSimpleCg(styling) : new BoxStylingBase(styling)
  }

  static mapSimpleCgCssToBoxStyling (simplecgcss: SimplecgCSS, baseBoxStyling: BoxStyling): BoxStylingSimpleCg {
    const background = simplecgcss.background ?? {}
    const text = simplecgcss.text ?? {}
    const styling = new BoxStylingSimpleCg({
      ...baseBoxStyling,
      backgroundColor: background.color,
      borderRadius: background.borderradius,
      color: text.color,
      fontSize: text.fontsize,
      fontFamily: text.font,
      justifyContent: text.ltr,
      paddingLeft: text.left,
      paddingTop: text.top
    })
    return styling
  }
}
