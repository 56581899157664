import { Result } from 'kiswe-ui'
import { KeyDict } from '../types/index'

export enum Region {
  Argentina = 'argentina',
  Bahrain = 'bahrain',
  Brazil = 'brazil',
  Frankfurt = 'frankfurt',
  Korea = 'korea',
  London = 'london',
  Mumbai = 'mumbai',
  Singapore = 'singapore',
  Sydney = 'sydney',
  USEast = 'us-east',
  USWest = 'us-west'
}

export enum RegionZone {
  USEast1a = 'us-east-1a',
  USEast1b = 'us-east-1b',
  USEast1c = 'us-east-1c'
}

export enum AWSStatus {
  Running = 'running'
}

export interface SrtStats {
  id?: string
}

export enum SystemStatus {
  OK = 'green',
  WARNING = 'warning',
  NETWORK_SLOW = 'network slow',
  ERROR = 'red',
  NONE = 'none',
}

export interface SystemNetworkStats {
  bytes_recv_per_sec: number,
  bytes_send_per_sec: number
}

export interface SystemLoad {
  cpu: number,
  disk: number,
  mem: number,
  // Nov 12 2020: these needs to be optional until the ngcvp on 20.04 is live -> then they can be made non-optional
  max_cpu?: number,
  max_disk?: number,
  max_mem?: number,
  machine?: string,
  net: KeyDict<SystemNetworkStats>
}

export interface Transcode {
  serverkey: string,
  status: SystemStatus,
  statusstr: string,
  streamid: string,
  variantid: string
}

export interface NGCVPMachineStats {
  ngcvp_machines: KeyDict<NGCVPMachineStat>
}

export interface NGCVPMachineStat {
  _id: string,
  availability_zone: RegionZone,
  clocksrv: string,
  load: SystemLoad,
  public_ip: string
}

export interface ProcessStatus {
  processid?: string,
  streamid?: string,
  type?: 'required_transcoding_processes' | 'required_srt_links',
  status?: SystemStatus,
  statusclass?: string
}

export interface TranscodingProcess extends ProcessStatus {
  command: string,
  commandjson: any,
  cpu: number,
  delay: number,
  dsturl: string,
  srcurl: string,
  transcodingregion: Region,
  transcodingserver: string,

}

export interface RequiredSrtLinks extends ProcessStatus {
  sink_server: string,
  sink_name: string,
  src_server: string,
  src_name: string,
  linkstr?: string
}

export interface TranscodingProcesses {
  required_transcoding_processes: KeyDict<TranscodingProcess>,
  required_srt_links?: KeyDict<RequiredSrtLinks>
}

export interface TranscodingMachine {
  availability_zone: RegionZone,
  awsregion: Region,
  awsstatus: AWSStatus,
  imageid: string,
  ip: string,
  last_unallocated: number,
  machine_id: string,
  privateip: string
  processes: KeyDict<TranscodingProcesses>,
  region: Region,
  resources: boolean,
  srtwowzaservers: string[],
  status: AWSStatus,
  timestamp: number,
  total_cpu: number
}

export interface SocialMediaTrack {
  query: { [ hashtag: string ]: boolean },
  track: { [ handle: string ]: boolean },
}

export enum SocialMediaName {
  Twitter = 'twitter'
}

export interface SocialMediaQuery {
  id: string,
  social_media: SocialMediaName,
  team_id: string
}

export interface SocialMediaQueries {
  handles: { [ id: string]: SocialMediaQuery[] }
  hashtags: { [ id: string]: SocialMediaQuery[] }
}

export interface SocialMediaStats {
  asset_groups: {[ assetId: string ]: SocialMediaStats },
  interval: number,
  last_checked: number,
  limit: {
    limit: number,
    remaining: number,
    reset: number
  },
  max_age_entry: number,
  max_entries_per_asset: number,
  max_tweets_per_query: number,
  num_polls: number,
  num_tweets: number,
  query: SocialMediaQueries
}

export class BackupOverview {
  num_users: number = 0
  collections: number = 0
  documents: number = 0
  zipped_bytes: number = 0
  s3_location: string = ''
  time: number = 0
}

export class GlobalAccelerator {
  mapping: KeyDict<string> = {}

  constructor (data: any|undefined = undefined) {
    if (typeof data !== 'object') return
    if (typeof data.mapping === 'object') {
      this.mapping = data.mapping
    }
  }

  static load (data: unknown): Result<GlobalAccelerator, 'invalid param'|'unknown'> {
    try {
      return Result.success(new GlobalAccelerator(data))
    } catch (error) {
      return Result.fromUnknownError('invalid param', error)
    }
  }
}
