import { trimmedOrNullEmail, trimmedOrNullString } from '@/modules/common/utils'
import Convertable from '@/classes/Convertable'
import { Email } from '@/modules/profiles/types'
import { Loader } from 'kiswe-ui'

export class ParticipantInfo extends Convertable {
  name: string
  email: Email|null
  phoneNumber: string|null
  relation: string|null
  deleted: boolean = false
  notes: string = ''
  lastCheckedIn: Date|null = null

  constructor (config: Partial<ParticipantInfo> = {}) {
    super(config)

    this.name = config.name ?? ''
    this.email = config.email ?? null
    this.phoneNumber = config.phoneNumber ?? null
    this.relation = config.relation ?? null
    this.deleted = config.deleted ?? false
    this.notes = config.notes ?? ''
    this.lastCheckedIn = Loader.loadDateOrNull(config, 'lastCheckedIn', null)

    this.normalize()
  }

  normalize () {
    this.name = trimmedOrNullString(this.name) ?? ''
    this.email = trimmedOrNullEmail(this.email)
    this.phoneNumber = trimmedOrNullString(this.phoneNumber)
    this.relation = trimmedOrNullString(this.relation)
  }
}
