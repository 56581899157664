import moment from 'moment'
import { clamp } from 'lodash'
import { CastStatus, CastType } from '@/types/casts'
import { Order } from '@/modules/database/utils/database'
import firebase from 'firebase/compat/app'


export const MAX_ENTRIES_PER_PAGE = 100

export interface CastFilterConfig {
  status?: CastStatus[]|null
  castType?: CastType[]|null
  requestedCastIds?: string[]
  startDate?: moment.Moment|null
  endDate?: moment.Moment|null
  prefix?: null|string
  pageIndex?: number
  pageCount?: number
  entriesPerPage?: number|null
  teamId?: string|null
  id: string
  lastDoc?: firebase.firestore.DocumentData|null
  firstDoc?: firebase.firestore.DocumentData|null,
  startDateOrder?: Order,
  activeOnly?: boolean
}

export enum CastFilterPageDirection {
  Forward = 'forward',
  Backward = 'backward'
}

export default class CastFilter {

  private _config: CastFilterConfig|undefined
  private _castIds: string[] = []
  private _requestedCastIds: string[] = []
  private _id: string
  private _status: CastStatus[]|null = null
  private _castType: CastType[]|null = null
  private _startDate: moment.Moment|null = null
  private _endDate: moment.Moment|null = null
  private _prefix: string|null = null
  private _pageIndex: number = 0
  private _pageCount: number = 1
  private _entriesPerPage: number|null = 20
  private _teamId: string|null = null
  private _lastDoc: firebase.firestore.DocumentData|null = null
  private _firstDoc: firebase.firestore.DocumentData|null = null
  private _pageDirection = CastFilterPageDirection.Forward
  private _startDateOrder = Order.ASCENDING
  private _activeOnly = false

  constructor (id: string, config?: CastFilterConfig) {
    this._config = config
    this._id = id
    this.setFilter(config)
  }

  reset () {
    const fakeResetConfig = { ...new CastFilter(this._id, this._config), id: this._id }
    this.setFilter(fakeResetConfig)
  }

  setFilter (config?: CastFilterConfig) {
    if (!config) return
    for (const [key, value] of Object.entries(config)) {
      // @ts-ignore
      this[key] = value
    }
  }

  nextPage () {
    this.pageIndex++
    this._pageDirection = CastFilterPageDirection.Forward
  }

  prevPage () {
    this.pageIndex--
    this._pageDirection = CastFilterPageDirection.Backward
  }

  // Private

  // ...

  // Getters & setters

  get id (): string {
    return this._id
  }

  set id (newVal: string) {
    this._id = newVal
  }

  get castIds (): string[] {
    return this._castIds
  }

  set castIds (newVal: string[]) {
    this._castIds = newVal
    this._resetDocPointersIfNeeded()
  }

  get requestedCastIds (): string[] {
    return this._requestedCastIds
  }

  set requestedCastIds (newVal: string[]) {
    this._requestedCastIds = newVal
  }

  get status (): CastStatus[]|null {
    return this._status
  }

  set status (newVal: CastStatus[]|null) {
    this._status = newVal
  }

  get castType (): CastType[]|null {
    return this._castType
  }

  set castType (newVal: CastType[]|null) {
    this._castType = newVal
  }

  get startDate (): moment.Moment|null {
    return this._startDate
  }

  set startDate (newVal: moment.Moment|null) {
    if (this.endDate !== null && newVal?.isAfter(this.endDate)) {
      // throw new Error('Start date cannot succeed the end date')
      console.error('CastFilter: Start date cannot succeed the end date')
      this._startDate = null
    } else {
      this._startDate = newVal
    }
  }

  get endDate (): moment.Moment|null {
    return this._endDate
  }

  set endDate (newVal: moment.Moment|null) {
    if (this.startDate !== null && newVal?.isBefore(this.startDate)) {
      // throw new Error('End date cannot precede the start date')
      console.error('CastFilter: End date cannot precede the start date')
      this._endDate = null
    } else {
      this._endDate = newVal
    }
  }

  get prefix (): string|null {
    return this._prefix
  }

  set prefix (newVal: string|null) {
    this._prefix = newVal
  }

  get pageIndex (): number {
    return this._pageIndex
  }

  set pageIndex (newVal: number) {
    this._pageIndex = (newVal >= 0) ? newVal : 0
  }

  get pageCount (): number {
    return this._pageCount
  }

  set pageCount (newVal: number) {
    this._pageCount = newVal
  }

  get entriesPerPage (): number|null {
    return this._entriesPerPage
  }

  set entriesPerPage (newVal: number|null) {
    if (newVal === null) return
    this._entriesPerPage = clamp(newVal, 0, MAX_ENTRIES_PER_PAGE)
  }

  get teamId (): string|null {
    return this._teamId
  }

  set teamId (newVal: string|null) {
    this._teamId = newVal
  }

  get lastDoc (): firebase.firestore.DocumentData|null {
    return this._lastDoc
  }

  set lastDoc (newVal: firebase.firestore.DocumentData|null) {
    this._lastDoc = newVal
    this._resetDocPointersIfNeeded()
  }

  get firstDoc (): firebase.firestore.DocumentData|null {
    return this._firstDoc
  }

  set firstDoc (newVal: firebase.firestore.DocumentData|null) {
    this._firstDoc = newVal
    this._resetDocPointersIfNeeded()
  }

  get pageDirection (): CastFilterPageDirection {
    return this._pageDirection
  }

  get startDateOrder (): Order {
    return this._startDateOrder
  }

  set startDateOrder (order: Order) {
    this._startDateOrder = order
  }

  get activeOnly (): boolean {
    return this._activeOnly
  }

  set activeOnly (newVal: boolean) {
    this._activeOnly = newVal
  }

  private _resetDocPointersIfNeeded (): void {
    if (this._entriesPerPage === null) {
      this._lastDoc = null
      this._firstDoc = null
    } else if (this._castIds.length < this._entriesPerPage) {
      if (this._pageDirection === CastFilterPageDirection.Forward) this._lastDoc = null
      if (this._pageDirection === CastFilterPageDirection.Backward) this._firstDoc = null
    }
  }

}
