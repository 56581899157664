// import { Result } from 'kiswe-ui'
import { cloneDeep } from 'lodash'

/**
 * This type can take a complex type such as a cast that looks like this:
 *
 * ```
 * type CastPlain = hasLoader<{
 *  new (...arg: any[]): hasMutes<{
 *       new (...arg: any[]): hasInputs<{
 *           new (...arg: any[]): hasPresets<{
 *             ...
 * ```
 *
 *  and turn it into a regular object with parameters and functions
 *  This type is already added to the classBuilder, but it makes the compiler slower.
 *
 *  Eg it needs to create twice as many types for casts and all its possible variations in our code
 *  and can sometimes lead to type exceed errors.
 */
export type Flatten<T> = T/* extends (...any: infer A) => infer S ? (...any: A) => Flatten<S> :
                  T extends (...any: infer A) => void ? (...any: A) => void :
                  T extends StudioBase ? { [P in keyof T]: Flatten<T[P]> } :
                  T*/


export type Constructor<T = {}> = new (...arg: any[]) => T
export type GConstructor<T = {}> = new (...args: any[]) => T
export type ClassType<C> = C extends GConstructor<infer T> ? Flatten<T> : never

type AnyFunction<A = any> = (...input: any[]) => A
export type Mixin<T extends AnyFunction> = InstanceType<ReturnType<T>>


export function hasStudioBase<TBase extends Constructor> (Base: TBase) {
  return class extends Base {
    public clone (): this {
      return cloneDeep(this)
    }

    /*
    // @ts-ignore
    static load (data: unknown): Result<InstanceType<typeof this>, 'invalid params'|'unknown'> {
      try {
        // @ts-ignore
        return Result.success(new Base(data) as InstanceType<typeof this>)
      } catch (error) {
        return Result.fromUnknownError('invalid params', error)
      }
    }*/
  }
}

export type StudioBase = Mixin<typeof hasStudioBase>
export type StudioBaseMixin = GConstructor<StudioBase>
