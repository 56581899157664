import { SceneTransition, TransitionType } from "@/types/layouts"

export const normalizeTransition = (transition: SceneTransition | string = '0'): SceneTransition => {
  if (typeof transition !== 'string') return transition as SceneTransition
  return {
    type: (transition === '0' ? TransitionType.NONE : TransitionType.TRANSLATE),
    duration: parseFloat(transition) * 1000, // legacy transitions are stored in seconds, not ms
    actions: []
  }
}
