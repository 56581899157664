import { Ref, computed } from 'vue'
import useAssetMediaController from './assetMediaController'
import useReplayMediaController from './replayMediaController'
import { useCurrentCast } from '@/modules/casts/compositions'
import { SceneSourceType } from '../types'

const { currentCast } = useCurrentCast()

const useMediaController = (sceneId: Ref<string|null>) => {

  const assetMediaController = useAssetMediaController(sceneId)
  const replayMediaController = useReplayMediaController(sceneId)
  const mediaController = computed(() => {
    if (sceneId.value === null) return null
    const sceneSourceType = currentCast.value?.getSceneSourceType(sceneId.value ?? '')
    return sceneSourceType === SceneSourceType.Replay ? replayMediaController : assetMediaController
  })

  return {
    mediaController
  }
}

export default useMediaController
