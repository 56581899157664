/* eslint-disable max-lines */
import { KeyDict } from '@/types'
import { RecordedSource, ReplayClip, ReplayIngestDoc, ReplayPlaylist } from '../classes'

// Constants
export const DEFAULT_CLIP_DURATION = 10
export const REPLAY_PLAYLIST_ALL_CLIPS = 'All Replay Clips'
export const REPLAY_DURATIONS = [15, 30, 45]

// Action on reaching end of clip in a playlist
export enum ClipEndAction {
  STOP = 'stop',  // Stop playing playlist
  NEXT = 'next',  // Start playing next clip
  LOOP = 'loop'   // Restart playing this clip
}

// Replay Player commands
export enum IngestCommand {
  START = 'start',  // Play playlist from the beginning of the indicated replay clip
  STOP = 'stop',    // Stop playing playlist. Cannot be resumed.
  PAUSE = 'pause',  // Pause playing playlist
  RESUME = 'resume' // Resume playing playlist from where it was paused
}

// Current status of a Replay Ingest (Replay Ingest aka Replay Player in video backend)
export enum IngestStatus {
  PLAYING = 'playing',   // Ingest is playing the playlist
  PAUSED = 'paused',     // Ingest is paused on a clip-frame in the playlist
  STOPPED = 'stopped'    // Ingest is stopped. Blank output
}

// Status of a clip in the ingest
export enum IngestClipStatus {
  LOADING = 'loading',  // Clip is loading
  LOADED = 'loaded',    // Clip is loaded
  ERROR = 'error'       // Clip had an error loading or playing
}

// Simplified subset status of download clip. Reference state from mp4create.py in cloudcast-backend repo
export enum DownloadableClipState {
  RUNNING = 'running',
  FAILED = 'failed',
  SUCCESS = 'success'
}

export const REPLAY_CONFIG_SUB_KEY = 'replay_config'
export const CAST_REPLAYS_SUB_KEY = 'cast_replays'

export interface ReplayConfigState {
  castId: string
  recordedSources: KeyDict<RecordedSource>
  replayClips: KeyDict<ReplayClip>
  replayPlaylists: KeyDict<ReplayPlaylist>
}

export interface CastReplaysState {
  castId: string
  ingests: KeyDict<ReplayIngestDoc>
}

export interface SwitchItem {
  key: string
  label: string
  value: boolean
  disabled?: boolean
}

export interface DownloadableClip {
  clipId: string
  taskTime: number
  start: number
  end: number
  name: string
  url: string
  status: DownloadableClipState
}
