
export enum CollectionNames {
  ADMIN = 'admin',
  APITOKENS = 'apitokens',
  ASSETS = 'assets',
  ASSET_GROUPS = 'asset-groups',
  BOTS = 'bots',
  BILLING_SUMMARIES = 'billing_summaries',
  CASTS = 'casts',
  CAST_DUMPS = 'cast_dumps',
  CAST_TEMPLATES = 'cast_templates',
  CAST_PROPERTIES = 'cast_properties',
  CAST_REPLAYS = 'cast_replays',
  CEF_STATS = 'cefstats',
  CHATS = 'chats',
  EVENTS = 'events',
  INVITES = 'invites',
  LAYOUTS = 'layouts',
  MARKERS = 'markers',
  MODERATION_QUEUE = 'moderation_queue',
  NETWORK_STATS = 'networkstats',
  NGCVP_STATS = 'ngcvpstats',
  PLAYLISTCONNECTIONS = 'playlistconnections',
  PLAYLIST_STATUS = 'playliststatus',
  POINTERS = 'pointers',
  PROFILES = 'profiles',
  REPLAY_CONFIG = 'replay_config',
  SESSION = 'session',
  STREAMS = 'streams',
  TEAM_GROUPS = 'team_groups',
  TEAMS = 'teams',
  TEAMS_ROLES = 'teams_roles',
  TEAMS_FTP = 'teamsftp',
  TRANSCODING = 'transcoding',
  TRANSCODING_STATS = 'transcodingstats',
  USER_CONSENTS = 'userconsents',
  VIDEO_PLAYER = 'videoplayer',
  WIDGETS = 'widgets',
  WOWZA_STATS = 'wowzastats'
}

export enum AdminDocNames {
  CLOUDCAST_MANAGER_DATA = 'cloudcast_manager_data',
  DEFAULT_STREAMS = 'defaultstreams',
  FEATURE_FLAGS = 'featureflags',
  GLOBAL_ACCELERATOR = 'globalaccelerator',
  MP4_CREATE = 'mp4create',
  NIMBLE_REGIONS = 'nimbleregions',
  OLYMPICS = 'olympics',
  RELEASE = 'release',
  STREAMS_AUTH = 'streams_auth',
  TRANSCODING_REGIONS = 'transcodingregions'
}
