import { Feature, hasFeature } from '@/featureFlags/featureFlags'
import { computed } from 'vue'
import { VideoCodec } from '../types'

const useCodecs = () => {
  const videoCodec = computed<VideoCodec>(() => {
    return hasFeature(Feature.H264_STREAMING) ? VideoCodec.H264 : VideoCodec.VP8
  })

  return {
    videoCodec
  }
}

export default useCodecs
