import store from '@/store'
import { computed,  onMounted,  onUnmounted } from 'vue'

const useFeatureFlags = () => {
	const featureFlags = computed(() => store.state.admin.featureFlags)
	const hasLoadedFeatureFlags = computed(() => store.state.admin.hasLoadedFeatureFlags)

  onMounted(() => {
    store.dispatch.admin.subscribeFeatureFlags()
  })
	onUnmounted(() => {
    store.dispatch.admin.unsubscribeFeatureFlags()
	})

	return {
		featureFlags,
		hasLoadedFeatureFlags
	}
}

export default useFeatureFlags
