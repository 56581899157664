import { KeyDict } from '@/types'
import { SceneContent } from '@/types/sceneContent'
import { Scene } from '@/types/scenes'

/* eslint-disable complexity */
export const maybeReplaceAssetBoxContents = (scenes: KeyDict<Scene>, assetId: string, newContent: SceneContent,
                                             boxId: string, forceUpdate: boolean = false) => {
  let shouldUpdateAsset = false
  for (const scene of Object.values(scenes)) {
    const hasBox = !!scene.scene_layout.boxes[boxId]
    if (!hasBox) continue
    shouldUpdateAsset = true
    const contents = scene.contents ?? {}
    let existingAssetId: string|null = null
    for (const [contentKey, content] of Object.entries(contents)) {
      if (content.box === boxId) {
        existingAssetId = contentKey
        break
      }
    }
    if (existingAssetId !== assetId || forceUpdate) {
      if (existingAssetId !== null) delete contents[existingAssetId]
      contents[assetId] = newContent
      scene.contents = contents
    }
  }
  return shouldUpdateAsset
}
/* eslint-enable complexity */
