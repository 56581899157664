import { Loader, Result } from 'kiswe-ui'

interface APITokenPermission {
  read: boolean,
  write: boolean,
  delete: boolean
}

enum APITokenPermissionType {
  casts = 'casts',
  rooms = 'rooms'
}

class APIToken {
  token: string
  deleted: boolean
  name: string
  team_id: string
  permissions: Record<APITokenPermissionType, APITokenPermission> = {
    [APITokenPermissionType.casts]: { read: false, write: false, delete: false },
    [APITokenPermissionType.rooms]: { read: false, write: false, delete: false }
  }

  constructor (data: unknown) {
    this.token = Loader.loadString(data, 'token')
    this.team_id = Loader.loadString(data, 'team_id')
    this.name = Loader.loadString(data, 'name', '')
    this.deleted = Loader.loadBoolean(data, 'deleted', false)
    // @ts-ignore
    const permissions = data.permissions ?? {}
    Object.entries(permissions).forEach(([key, value]) => {
      // @ts-ignore
      this.permissions[key] = {
        read: Loader.loadBoolean(value, 'read', false),
        write: Loader.loadBoolean(value, 'write', false),
        delete: Loader.loadBoolean(value, 'delete', false)
      }
    })
  }

  hasPermission (type: APITokenPermissionType, permission: 'read' | 'write' | 'delete') {
    return this.permissions[type][permission]
  }

  permissionsToString () {
    return Object.entries(this.permissions).map(([key, value]) => {
      return `${key}: ${value.read ? 'read' : 'no-read'} / ${value.write ? 'write' : 'no-write'}`
    }).join(',')
  }

  static load (data: unknown): Result<APIToken, 'invalid params'|'unknown'> {
    try {
      return Result.success(new APIToken(data))
    } catch (error) {
      return Result.fromUnknownError('invalid params', error)
    }
  }
}

export {
  APIToken,
  APITokenPermissionType
}
