import { Loader } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'

interface HasNameProps {
  required: boolean
}

export function hasName<TBase extends StudioBaseMixin> (Base: TBase, props: Partial<HasNameProps> = {}) {
  return class NamedClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.name = Loader.loadString(arg[0], 'name', (props.required ?? true) ? undefined : '')
    }
    name: string
    // @ts-ignore
    private __hasName = true

    setName (name: string) {
      this.name = name
      return this
    }
  }
}

export type Named = Mixin<typeof hasName>

export const hasNameMixin = (obj: unknown): obj is Named => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasName' in obj) return true
  return false
}
