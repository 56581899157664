import studio, { CollectionNames } from '@/modules/database/utils';
import { NetworkStateContext, networkStatsModuleActionContext } from "."
import { CefMode } from '@/modules/statistics/types'

// see https://codeburst.io/vuex-and-typescript-3427ba78cfa8

export const actions = {
  async subscribeUserStats (context: NetworkStateContext, payload: { userId: string, waitForIt?: boolean }) {
    const { commit } = networkStatsModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'userstats_' + payload.userId,
      query: studio.db.collection(CollectionNames.NETWORK_STATS).doc(payload.userId),
      callback: commit.updateUserStats,
      waitForIt: !!payload.waitForIt
    })
    result.logIfError('subscribeUserStats')
  },
  unsubscribeUserStats (_context: NetworkStateContext, userId: string) {
    const subscriptionKey = 'userstats_' + userId
    studio.subscriptions.unsubscribe(subscriptionKey)
  },
  async subscribeNgcvpStats (context: NetworkStateContext, payload: { castId: string, waitForIt?: boolean }) {
    const { commit } = networkStatsModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'ngcvpstats_' + payload.castId,
      query: studio.db.collection(CollectionNames.NGCVP_STATS).doc(payload.castId),
      callback: commit.updateNgcvpStats,
      waitForIt: !!payload.waitForIt
    })
    result.logIfError('subscribeNgcvpStats')
  },
  async unsubscribeNgcvpStats (_context: NetworkStateContext, castId: string) {
    const subscriptionKey = 'ngcvpstats_' + castId
    studio.subscriptions.unsubscribe(subscriptionKey)
  },
  async subscribeTranscodingStats (context: NetworkStateContext) {
    const { commit } = networkStatsModuleActionContext(context)
    const result = await studio.subscriptions.subscribe({
      key: 'transcodingStats',
      query: studio.db.collection(CollectionNames.TRANSCODING_STATS),
      callback: commit.updateTranscodingStats
    })
    result.logIfError('error to subscribe transcodingStats')
  },
  async unsubscribeTranscodingStats (_context: NetworkStateContext) {
    studio.subscriptions.unsubscribe('transcodingStats')
  },
  async subscribeCefStats (context: NetworkStateContext, castId: string) {
    const { commit } = networkStatsModuleActionContext(context)
    await Promise.all(
      Object.values(CefMode).map((mode) => {
        const docId = `${ castId }_${ mode }`
        return studio.subscriptions.subscribe({
          key: `cefStats_${ docId }`,
          query: studio.db.collection(CollectionNames.CEF_STATS).doc(docId),
          callback: commit.updateCefStats
        })
      })
    )
  },
  async unsubscribeCefStats (_context: NetworkStateContext, castId: string) {
    Object.values(CefMode).forEach((mode) => {
      const docId = `${ castId }_${ mode }`
      studio.subscriptions.unsubscribe(`cefStats_${ docId }`)
    })
  }
}
