import store from '@/store'
import { onUnmounted, watch, computed } from 'vue'

const useCurrentTeamRoles = () => {
  const activeTeam = computed(() => store.state.team.activeTeam)
  let subscribed: null|string = null
  onUnmounted(() => {
    if (subscribed) store.dispatch.abilities.unsubscribeTeamRoles(activeTeam.value)
  })

  watch(activeTeam, async (newVal, oldVal) => {
    if (oldVal) await store.dispatch.abilities.unsubscribeTeamRoles(oldVal)
    if (newVal) {
      await store.dispatch.abilities.subscribeTeamRoles(newVal)
      subscribed = newVal
    }
  }, { immediate: true })
  const teamRoles = computed(() => store.state.abilities.teamRoles)
  const teamRolesReady = computed(() => store.state.abilities.teamRolesReady)

  return {
    teamRoles,
    teamRolesReady
  }
}

export default useCurrentTeamRoles
