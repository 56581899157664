import { Loader } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { ISORecord } from '@/types/casts'
import { GConstructor, Mixin } from './base'
import { HasMixer } from './hasMixer'

export function hasArchive<TBase extends GConstructor<HasMixer>> (Base: TBase) {
  return class hasArchiveClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.record_isos = Loader.loadBoolean(arg[0], 'record_isos', false)
      this.mp4 = Loader.loadStringOrNull(arg[0], 'mp4', null)
      this.isos = Loader.loadAndCast<KeyDict<ISORecord>>(arg[0], 'isos', {})
    }
    record_isos: boolean
    mp4: string|null
    isos: KeyDict<ISORecord>
  }
}

export type HasArchive = Mixin<typeof hasArchive>
