import { findLastIndex } from 'lodash'

interface DelayBufferOptions {
  maxTotalEntries?: number,
  staleEntriesLimit?: number
}

export default class DelayBuffer {
  private _defaultValue: number
  private _delay: number // [s]
  private _entries: { timestamp: number, value: number }[]
  protected maxTotalEntries: number
  protected staleEntriesLimit: number

  constructor (delay: number, defaultValue: number = 0, options?: DelayBufferOptions) {
    this._delay = delay
    this._defaultValue = defaultValue
    this._entries = []
    this.maxTotalEntries = options?.maxTotalEntries ?? 1000
    this.staleEntriesLimit = options?.staleEntriesLimit ?? 10
  }

  get defaultValue () {
    return this._defaultValue
  }

  get delay () {
    return this._delay
  }

  get length () {
    return this._entries.length
  }

  protected cleanupBefore (idx: number) {
    if (idx < 1 || idx > this.length) return
    this._entries.splice(0, idx)
  }

  protected getLastEntryBeforeDelayedNowIdx () {
    const delayedNow = Date.now() - (this._delay * 1000)
    return findLastIndex(this._entries, (entry) => entry.timestamp <= delayedNow)
  }

  push (value: number) {
    const lastEntryBeforeDelayedNowIdx = this.getLastEntryBeforeDelayedNowIdx()
    if (lastEntryBeforeDelayedNowIdx >= this.staleEntriesLimit) this.cleanupBefore(lastEntryBeforeDelayedNowIdx)
    this._entries.push({ timestamp: Date.now(), value })
    if (this.length >= this.maxTotalEntries) {
      console.error('Reached max allowed buffer size. Clearing half buffer')
      this._entries.splice(0, Math.floor(this.maxTotalEntries / 2))
    }
  }

  read () {
    if (this.length === 0) return this._defaultValue
    const lastEntryBeforeDelayedNowIdx = this.getLastEntryBeforeDelayedNowIdx()
    if (lastEntryBeforeDelayedNowIdx === -1) return this._defaultValue
    const lastEntryBeforeDelayedNow = this._entries[lastEntryBeforeDelayedNowIdx]
    if (!lastEntryBeforeDelayedNow) return this._defaultValue
    if (lastEntryBeforeDelayedNowIdx >= this.staleEntriesLimit) this.cleanupBefore(lastEntryBeforeDelayedNowIdx)
    return lastEntryBeforeDelayedNow.value
  }
}
