import { Loader, Result } from 'kiswe-ui'

export class Olympics {
  startDate: Date|null

  constructor (data: unknown) {
    this.startDate = Loader.loadDateOrNull(data, 'startDate', null)
  }

  static load (data: unknown): Result<Olympics, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new Olympics(data))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
