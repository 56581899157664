import { AbilityAction, AbilitySubject } from '@/abilitiesByRole'
import { Translation } from '@/compositions/translations'
import { KisweSpin } from 'kiswe-ui'
import { KeyDict } from '@/types'

export interface UiState {
  modes: KeyDict<boolean>,
  openModals: Array<Modals>,
  popovers: KeyDict<boolean>
}

export enum Modals {
  GLOBAL_LAYER_PANEL = 'global_layer_panel',
  USER_MEDIA_CONNECT = 'user_media_connect'
}

export enum Modes {
  EDIT_GRID = 'edit_grid',
  HOT_SWITCH = 'hot_switch'
}

export enum Popover {
  SceneContentSelector = 'SceneContentSelector',
  SceneLayerSelector = 'SceneLayerSelector'
}

export enum ActionPaletteTabs {
  Assets = 'Assets',
  Audio = 'Audio Mixer',
  Casters = 'Casters',
  Chat = 'TextCast',
  Clips = 'Clips',
  Commentators = 'Commentators',
  Crew = 'Crew',
  Inputs = 'Inputs',
  Interactive = 'Interactive',
  Layout = 'Layout',
  Preset = 'Preset',
  Outputs = 'Outputs',
  SCTEMarkers = 'Markers',
  Settings = 'Settings',
  SimpleCG = 'Simple CG',
  Switcher = 'Switcher',
  Talkback = 'Talkback'
}

export interface ActionTabDefinition {
  action: AbilityAction,
  allow: AbilitySubject|undefined,
  component: any,
  icon: string,
  label: Translation,
  panel: ActionPaletteTabs,
  props?: any,
  value: number,
  hasTopbar?: boolean
}

export enum UIContext {
  AUDIO = 'Audio',
  CREW = 'Crew',
  GLOBAL_LAYERS = 'Layers',
  OTHER = 'Other',
  SCENES = 'Scenes',
  TWITTER = 'Twitter'
}

export enum InfoSlateType {
  NONE = 'none',
  CREATING_REPLAY = 'creating_replay',
  FANROOM_INACTIVE = 'fanroom_inactive'
}

export interface InfoSlate {
  type: InfoSlateType
  message: string
  icon: string
  iconSpin: KisweSpin
}

export const SLATE_INFO_MAP: KeyDict<InfoSlate> = {
  [InfoSlateType.NONE]: {
    type: InfoSlateType.NONE,
    message: '',
    icon: '',
    iconSpin: KisweSpin.Off
  },
  [InfoSlateType.CREATING_REPLAY]: {
    type: InfoSlateType.CREATING_REPLAY,
    message: 'Creating replay',
    icon: 're-activate',
    iconSpin: KisweSpin.AntiClockwise
  },
  [InfoSlateType.FANROOM_INACTIVE]: {
    type: InfoSlateType.FANROOM_INACTIVE,
    message: 'The fan room is not visible',
    icon: 'warning',
    iconSpin: KisweSpin.Off
  }
}
