import { SupportedLocale } from "./types"
import { i18n } from "./i18n"

const navigatorLanguages = [ ...navigator.languages ].map((locale) => locale.split('-')[0])
const availableLanguages = [ ...navigatorLanguages, SupportedLocale.English ]

export const setCurrentLanguage = (language: SupportedLocale) => {
  i18n.locale = language
}

export const setLanguageOfBrowser = () => {
  const defaultLanguage = availableLanguages.find(language => {
    return Object.values(SupportedLocale).includes(language as SupportedLocale)
  })
  if (defaultLanguage === undefined) throw new Error('setLanguageOfBrowser: no available language detected')
  setCurrentLanguage(defaultLanguage as SupportedLocale)
}

