import { Loader } from 'kiswe-ui'
import { HexCode } from 'kiswe-ui'

export class TwitterStylingAvatar {
  visible: boolean = true
  left: number = 1
  top: number = 25
  width: number = 5

  constructor (data?: unknown) {
    if (data === undefined) return
    this.visible = Loader.loadBoolean(data, 'visible', this.visible)
    this.left = Loader.loadNumber(data, 'left', this.left)
    this.top = Loader.loadNumber(data, 'top', this.top)
    this.width = Loader.loadNumber(data, 'width', this.width)
  }
}

export class TwitterStylingBackground {
  borderradius: number = 0
  color: HexCode|null = '#DC2626FF'
  image: string|null = null
  name: string|null = null

  constructor (data?: unknown) {
    if (data === undefined) return
    this.borderradius = Loader.loadNumber(data, 'borderradius', this.borderradius)
    this.color = Loader.loadHexCodeOrNull(data, 'color', this.color)
    this.image = Loader.loadStringOrNull(data, 'image', this.image)
    this.name = Loader.loadStringOrNull(data, 'name', this.name)

    if (this.image !== null) this.color = null
  }
}

export class TwitterStylingTag {
  visible: boolean = false
  color: HexCode = '#FFFFFFFF'
  font: string = 'Work Sans'
  fontsize: number = 5
  left: number = 80
  top: number = 10
  width: number = 0

  constructor (data?: unknown) {
    if (data === undefined) return
    this.visible = Loader.loadBoolean(data, 'visible', this.visible)
    this.color = Loader.loadHexCode(data, 'color', this.color)
    this.font = Loader.loadString(data, 'font', this.font)
    this.fontsize = Loader.loadNumber(data, 'fontsize', this.fontsize)
    this.left = Loader.loadNumber(data, 'left', this.left)
    this.top = Loader.loadNumber(data, 'top', this.top)
    this.width = Loader.loadNumber(data, 'width', this.width)
  }
}

export class TwitterStylingQuestion {
  color: HexCode = '#FFFFFFFF'
  font: string = 'Work Sans'
  fontsize: number = 7
  left: number = 9
  top: number = 13

  constructor (data?: unknown) {
    if (data === undefined) return
    this.color = Loader.loadHexCode(data, 'color', this.color)
    this.font = Loader.loadString(data, 'font', this.font)
    this.fontsize = Loader.loadNumber(data, 'fontsize', this.fontsize)
    this.left = Loader.loadNumber(data, 'left', this.left)
    this.top = Loader.loadNumber(data, 'top', this.top)
  }
}

export class TwitterStylingText {
  visible: boolean = true
  color: HexCode = '#FEFEFEFF'
  font: string = 'Work Sans'
  fontsize: number = 5
  left: number = 8
  top: number = 40
  width: number = 80
  ltr: boolean = true

  constructor (data?: unknown) {
    if (data === undefined) return
    this.visible = Loader.loadBoolean(data, 'visible', this.visible)
    this.color = Loader.loadHexCode(data, 'color', this.color)
    this.font = Loader.loadString(data, 'font', this.font)
    this.fontsize = Loader.loadNumber(data, 'fontsize', this.fontsize)
    this.left = Loader.loadNumber(data, 'left', this.left)
    this.top = Loader.loadNumber(data, 'top', this.top)
    this.width = Loader.loadNumber(data, 'width', this.width)
    this.ltr = Loader.loadBoolean(data, 'ltr', this.ltr)
  }
}

export class TwitterStylingUsername {
  visible: boolean = true
  color: HexCode = '#FEFEFEFF'
  font: string = 'Work Sans'
  fontsize: number = 8
  left: number = 8
  top: number = 10

  constructor (data?: unknown) {
    if (data === undefined) return
    this.visible = Loader.loadBoolean(data, 'visible', this.visible)
    this.color = Loader.loadHexCode(data, 'color', this.color)
    this.font = Loader.loadString(data, 'font', this.font)
    this.fontsize = Loader.loadNumber(data, 'fontsize', this.fontsize)
    this.left = Loader.loadNumber(data, 'left', this.left)
    this.top = Loader.loadNumber(data, 'top', this.top)
  }
}
