import { AbilityAction, AbilitySubject } from '@/abilitiesByRole'
import { can } from '@/ability'
import { computed } from 'vue'

const canReadAssets = computed(() => can.value(AbilityAction.Read, AbilitySubject.AssetGroups))
const canEditAsset = computed(() => can.value(AbilityAction.Edit, AbilitySubject.Asset))
const canDeleteAsset = computed(() => can.value(AbilityAction.Delete, AbilitySubject.Asset))
const canEditAssetGroups = computed(() => can.value(AbilityAction.Edit, AbilitySubject.AssetGroups))
const canDeleteAssetGroups = computed(() => can.value(AbilityAction.Delete, AbilitySubject.AssetGroups))
const canChangeAudio = computed(() => can.value(AbilityAction.Edit, AbilitySubject.Audio))
const canCreateCast = computed(() => can.value(AbilityAction.Create, AbilitySubject.Cast))
const canCreateCastTemplate = computed(() => can.value(AbilityAction.Create, AbilitySubject.CastTemplates))
const canCreateMembers = computed(() => can.value(AbilityAction.Create, AbilitySubject.Members))
const canCopyCast = computed(() => can.value(AbilityAction.Copy, AbilitySubject.Cast))
const canDeleteArchive = computed(() => can.value(AbilityAction.Delete, AbilitySubject.Archive))
const canDeleteCast = computed(() => can.value(AbilityAction.Delete, AbilitySubject.Cast))
const canDeleteMembers = computed(() => can.value(AbilityAction.Delete, AbilitySubject.Members))
const canDeleteTemplates = computed(() => can.value(AbilityAction.Delete, AbilitySubject.CastTemplates))
const canEditCast = computed(() => can.value(AbilityAction.Edit, AbilitySubject.Cast))
const canEditCasterStreams = computed(() => can.value(AbilityAction.Edit, AbilitySubject.CasterStream))
const canEditTemplates = computed(() => can.value(AbilityAction.Edit, AbilitySubject.CastTemplates))
const canReadArchive = computed(() => can.value(AbilityAction.Read, AbilitySubject.Archive))
const canReadCast = computed(() => can.value(AbilityAction.Read, AbilitySubject.Cast))
const canReadMembers = computed(() => can.value(AbilityAction.Read, AbilitySubject.Members))
const canReadTemplates = computed(() => can.value(AbilityAction.Read, AbilitySubject.CastTemplates))
const canEditMembers = computed(() => can.value(AbilityAction.Edit, AbilitySubject.Members))
const canCreateTeam = computed(() => can.value(AbilityAction.Create, AbilitySubject.Team))
const canCreateTeamSettings = computed(() => can.value(AbilityAction.Create, AbilitySubject.TeamSettings))
const canDeleteTeamSettings = computed(() => can.value(AbilityAction.Delete, AbilitySubject.TeamSettings))
const canEditTeamSettings = computed(() => can.value(AbilityAction.Edit, AbilitySubject.TeamSettings))
const canReadTeamSettings = computed(() => can.value(AbilityAction.Read, AbilitySubject.TeamSettings))
const canNotifyCaster = computed(() => can.value(AbilityAction.Notify, AbilitySubject.Cast))
const canReadReport = computed(() => can.value(AbilityAction.Read, AbilitySubject.Report))
const canCreateLayout = computed(() => can.value(AbilityAction.Create, AbilitySubject.Layout))
const canEditLayout = computed(() => can.value(AbilityAction.Edit, AbilitySubject.Layout))
const canDeleteLayout = computed(() => can.value(AbilityAction.Delete, AbilitySubject.Layout))
const canReadLayout = computed(() => can.value(AbilityAction.Read, AbilitySubject.Layout))
const canCreateMarker = computed(() => can.value(AbilityAction.Read, AbilitySubject.Marker))
const canDeleteMarker = computed(() => can.value(AbilityAction.Delete, AbilitySubject.Marker))
const canEditMarker = computed(() => can.value(AbilityAction.Edit, AbilitySubject.Marker))
const canReadMarker = computed(() => can.value(AbilityAction.Read, AbilitySubject.Marker))
const canReadFtp = computed(() => can.value(AbilityAction.Read, AbilitySubject.Ftp))
const canReadStreamAuth = computed(() => can.value(AbilityAction.Read, AbilitySubject.StreamAuth))
const canManageAllTeams = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiAllTeams))
const canManageBilling = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiBilling))
const canManageBranding = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiBranding))
const canManageClipper = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiClipper))
const canManageDashboard = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiDashboard))
const canManageGroups = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiGroups))
const canManageLayouts = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiLayouts))
const canManageMarkers = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiMarkers))
const canManageMedia = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiMedia))
const canManageModeration = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiModeration))
const canManageOperations = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiOperations))
const canManageProductions = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiProductions))
const canManageRoles = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiRoles))
const canManageStreams = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiStreams))
const canManageSwitcherCastLayout = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiSwitcherCastLayout))
const canManageTalentCastLayout = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiTalentCastLayout))
const canManageTeams = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiTeams))
const canManageTechnology = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiTechnology))
const canManageTemplates = computed(() => can.value(AbilityAction.Manage, AbilitySubject.UiTemplates))

const useCanDo = () => {
  return {
    canChangeAudio,
    canCopyCast,
    canCreateCast,
    canCreateCastTemplate,
    canCreateLayout,
    canCreateMarker,
    canCreateMembers,
    canCreateTeam,
    canCreateTeamSettings,
    canDeleteArchive,
    canDeleteAsset,
    canDeleteAssetGroups,
    canDeleteCast,
    canDeleteLayout,
    canDeleteMarker,
    canDeleteMembers,
    canDeleteTeamSettings,
    canDeleteTemplates,
    canEditAsset,
    canEditAssetGroups,
    canEditCast,
    canEditCasterStreams,
    canEditLayout,
    canEditMarker,
    canEditMembers,
    canEditTeamSettings,
    canEditTemplates,
    canManageAllTeams,
    canManageBilling,
    canManageBranding,
    canManageClipper,
    canManageDashboard,
    canManageGroups,
    canManageLayouts,
    canManageMarkers,
    canManageMedia,
    canManageModeration,
    canManageOperations,
    canManageProductions,
    canManageRoles,
    canManageStreams,
    canManageSwitcherCastLayout,
    canManageTalentCastLayout,
    canManageTeams,
    canManageTechnology,
    canManageTemplates,
    canReadArchive,
    canReadAssets,
    canReadCast,
    canReadFtp,
    canReadReport,
    canReadLayout,
    canReadMarker,
    canReadMembers,
    canReadStreamAuth,
    canReadTemplates,
    canReadTeamSettings,
    canNotifyCaster
  }
}

export default useCanDo
