import { Loader } from 'kiswe-ui'
import {
  TwitterStylingAvatar, TwitterStylingBackground, TwitterStylingQuestion,
  TwitterStylingTag, TwitterStylingText, TwitterStylingUsername
} from './TwitterStylingOptions'

export default class TwitterStyling {
  avatar = new TwitterStylingAvatar()
  background = new TwitterStylingBackground()
  question = new TwitterStylingQuestion()
  username = new TwitterStylingUsername()
  /** @deprecated use 'tag' instead */
  handle = new TwitterStylingTag()
  /** @deprecated use 'text' instead */
  tweet = new TwitterStylingText()

  constructor (data?: unknown) {
    if (data === undefined) return
    this.avatar = Loader.loadClass(data, 'avatar', (data) => new TwitterStylingAvatar(data), this.avatar)
    this.background = Loader.loadClass(data, 'background', (data)=> new TwitterStylingBackground(data), this.background)
    this.handle = Loader.loadClass(data, 'handle', (data) => new TwitterStylingTag(data), this.handle)
    this.question = Loader.loadClass(data, 'question', (data) => new TwitterStylingQuestion(data), this.question)
    this.tweet = Loader.loadClass(data, 'tweet', (data) => new TwitterStylingText(data), this.tweet)
    this.username = Loader.loadClass(data, 'username', (data) => new TwitterStylingUsername(data), this.username)
  }

  get text () {
    return this.tweet
  }

  set text (newVal: TwitterStylingText) {
    this.tweet = newVal
  }

  get tag () {
    return this.handle
  }

  set tag (newVal: TwitterStylingTag) {
    this.handle = newVal
  }

  setAvatar (props: Partial<TwitterStylingAvatar>) {
    this.avatar = new TwitterStylingAvatar({ ...this.avatar, ...props })
    return this
  }

  setBackground (props: Partial<TwitterStylingBackground>) {
    this.background = new TwitterStylingBackground({ ...this.background, ...props })
    return this
  }

  setTag (props: Partial<TwitterStylingTag>) {
    this.handle = new TwitterStylingTag({ ...this.handle, ...props })
    return this
  }

  setQuestion (props: Partial<TwitterStylingQuestion>) {
    this.question = new TwitterStylingQuestion({ ...this.question, ...props })
    return this
  }

  setText (props: Partial<TwitterStylingText>) {
    this.text = new TwitterStylingText({ ...this.text, ...props })
    return this
  }

  setUsername (props: Partial<TwitterStylingUsername>) {
    this.username = new TwitterStylingUsername({ ...this.username, ...props })
    return this
  }
}
