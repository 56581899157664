import store from '@/store'
import { computed } from 'vue'
import useVolumes from '@/modules/audio/compositions/volumes/volumes'


const localStream = computed(() => store.state.usermedia.localCameraStream)
const isStreaming = computed(() => localStream.value !== null)

const useCurrentCasterStream = () => {
  const streamId = computed(() => store.state.usermedia.streamDocId)
  const activeContents = computed(() => store.getters.events.activeScene?.contents ?? {})

  const isCasterVisible = computed(() => {
    if (streamId.value === null) return false
    return activeContents.value[streamId.value]?.active ?? false
  })
  const { programVolumes, isMuted } = useVolumes()
  const casterVolume = computed(() => {
    if (streamId.value === null) return 0
    if (isMuted(streamId.value)) return 0
    return programVolumes.value?.[streamId.value] ?? 0
  })

  const connectStatus = computed(() => store.state.usermedia.connectStatus)

  return {
    streamId,
    isStreaming,
    localStream,
    casterVolume,
    connectStatus,
    isCasterVisible
  }
}

export default useCurrentCasterStream
