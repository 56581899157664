import CheckFeature from '../checkfeature'
import { Team } from '@/modules/teams/classes'

export default class LiveClippingFeature extends CheckFeature {

  override hasFeature (): boolean {
    return this.currentTeam?.features?.liveClipping?.enabled ?? false
  }

  teamHasFeature (teamId: string): boolean {
    const team = this.teams[teamId]
    return team?.features?.liveClipping?.enabled ?? false
  }

  assignTeamFeature (team: Partial<Team>, enabled: boolean): void {
    team.features = team.features ?? {}
    team.features.liveClipping = { enabled }
  }

  override isGlobalFeature () : boolean {
    return false
  }

  override get description (): string {
    return `Advanced setting on the create event, this allows the clipper to be used on live events.`
  }
}
