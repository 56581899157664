import { Ref, WatchStopHandle, computed, ref, watch } from 'vue'
import useReplayObjects from './replayObjects'
import { ReplayIngestStatusDoc } from '../classes'
import { useCurrentCast } from '@/modules/casts/compositions'

const useReplayIngestStatusWatcher = () => {

  const { currentCastId } = useCurrentCast()
  const { replayIngests } = useReplayObjects(currentCastId)

  let stopWatchIngest: WatchStopHandle|null = null
  const watchIngestId: Ref<string|null> = ref(null)
  const watchIngestStatus = computed(() => {
    if (!watchIngestId.value) return undefined
    const ingest = replayIngests.value[watchIngestId.value]
    return ingest?.status ?? null
  })

  const clearIngestStatusWatcher = () => {
    if (stopWatchIngest) stopWatchIngest()
  }

  // Execute async action when given condition on ingest status is met for every machine
  const doOnIngestStatus = (params: { ingestId: string, condition: (ingestStatus: ReplayIngestStatusDoc) => boolean,
    action: () => Promise<void>, noStatusResult?: boolean }): void =>
  {
    clearIngestStatusWatcher()
    watchIngestId.value = params.ingestId
    stopWatchIngest = watch(watchIngestStatus, async (newStatus) => {
      let result = params.noStatusResult ?? false
      if (newStatus && Object.values(newStatus).length > 0) {
        result = true
        for (const ingestStatus of Object.values(newStatus)) {
          result = result && params.condition(ingestStatus)
        }
      }
      if (result) {
        watchIngestId.value = null
        await params.action()
        clearIngestStatusWatcher()
      }
    }, { immediate: true })
  }

  return {
    doOnIngestStatus,
    clearIngestStatusWatcher
  }
}

export default useReplayIngestStatusWatcher
