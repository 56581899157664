import { Cast } from '@/modules/casts/classes'
import { Loader, ResultVoid } from 'kiswe-ui'
import { isObjectWithKey } from '@/types'
import { CastTemplate } from '@/modules/templates/utils/casttemplates'
import {
  CastTemplateParameterType, CastTemplateParameterValueType,
  loadCastTemplateParameterType, loadCastTemplateParameterValueType
} from './castTemplateParameter'

export abstract class CastTemplateParameterBase {
  label: string|null // Label to show in UI
  type: CastTemplateParameterType // Determines what the parameter will affect
  valueType: CastTemplateParameterValueType // Determines how UI should look like
  value?: unknown

  constructor (data: unknown) {
    this.label = Loader.loadString(data, 'label')
    this.type = loadCastTemplateParameterType(data, 'type')
    this.valueType = loadCastTemplateParameterValueType(data, 'valueType')
    this.value = isObjectWithKey(data, 'value') ? data.value : null
  }

  abstract applyUpdate (templateOrCast: CastTemplate|Cast, paramsData: unknown):
                        Promise<ResultVoid<'casttemplate_param_update'>>

  isCompatible (param: CastTemplateParameterBase) {
    return this.type === param.type && this.valueType === param.valueType
  }
}
