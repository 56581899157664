import { StreamChangeHandler } from './streamChangeHandler'
import { StreamChangeHandlerVariants } from './streamchangehandlers/streamChangeHandlerVariants'

export class StreamChangeHandlerFactory {
  static getChangeHandlers (): StreamChangeHandler[] {
    return [
      new StreamChangeHandlerVariants()
    ]
  }
}
