import { filterTransportProtocolFromSdp, filterIpsFromSdp, filterSdpDebugInfo } from '@/modules/janus/utils/utils'
import { IceTransport } from '@/modules/janus/classes/IceCandidateEntry'

export interface WHEPClientJanusOptions {
	force_protocol: IceTransport|null,
	on_whep_error: WHEPErrorCallback|null,
	chinaIceCandidateIpList: string[]
}

export type WHEPErrorCallback = (WhepResponse: Response) => void

export class WHEPClientJanus extends EventTarget
{
	options: WHEPClientJanusOptions
	name: string = ''
	resourceURL: URL|null = null
	peerConnection: RTCPeerConnection|null = null
	on_whep_error: WHEPErrorCallback|null = null
	debugSdp: boolean = false

	constructor (options: Partial<WHEPClientJanusOptions>, debugSdp = false)
	{
		super()
		const defaultOptions = {
			force_protocol: null,
      chinaIceCandidateIpList: [],
      on_whep_error: null
		}
		// Merge default config with provided config
		this.options = {
			...defaultOptions,
			...options
    }
    this.on_whep_error = this.options.on_whep_error
	  this.debugSdp = debugSdp
	}

	destructor () {
	  console.info(`${this.name} is destroyed`)
	}

	public logSdp (sdp: string|undefined, url: string) {
    if (!sdp) return
    const filteredLogSdp = filterSdpDebugInfo(sdp)
    console.warn(`WhipClient::SDP ${url}`, filteredLogSdp)
    return
	}
	
	public removeCandidates (sdp: string|undefined, removeProtocol: IceTransport|null) {
	  if (!sdp) return undefined
		let modifiedSdp = sdp
		if (removeProtocol) {
			modifiedSdp = filterTransportProtocolFromSdp(sdp, removeProtocol)
		}
		if (this.options.chinaIceCandidateIpList && this.options.chinaIceCandidateIpList.length > 0) {
			modifiedSdp = filterIpsFromSdp(modifiedSdp, this.options.chinaIceCandidateIpList)
		}
		return modifiedSdp
	}

	public async stop () {
		if (!this.resourceURL) return
		const headers = {
		}
		//Send a delete
		await fetch(this.resourceURL.toString(), {
			method: 'DELETE',
			headers
		})
  	}

	async view (peerConnection: RTCPeerConnection, url: string) {
    this.peerConnection = peerConnection
		const headers = {
			'Content-Type': 'application/sdp'
		}
		const fetched = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({}),
			headers
		})
		if (!fetched.ok) {
			if (this.on_whep_error) {
				this.on_whep_error(fetched)
			} else {
				console.warn('Request rejected with status ' + fetched.status)
			}
			return
		}
		if (!fetched.headers.get('location')) {
			console.warn('Response missing location header')
			return
		}
		this.peerConnection.onconnectionstatechange = (_event) => {
      		console.debug('connectionState: ', peerConnection.connectionState)
			switch (peerConnection.connectionState) {
				case 'connected':
					// The connection has become fully connected
          			console.debug('peerConnection Connected')
					break;
				case 'disconnected':
					// One or more transports has terminated unexpectedly or in an error
          			console.debug('peerConnection Disconnected')
					break;
				case 'failed':
					// One or more transports has terminated unexpectedly or in an error
          			console.warn('peerConnection Failed')
					break;
				case 'closed':
					// The connection has been closed
          			console.warn('peerConnection Closed')
					if (!this.peerConnection) break;
					this.peerConnection.close()
					this.peerConnection.onconnectionstatechange = null
					this.peerConnection.oniceconnectionstatechange = null
					this.peerConnection.onsignalingstatechange = null
					this.peerConnection.onnegotiationneeded = null
					this.peerConnection.ontrack = null
					this.peerConnection.onicecandidate = null
					this.peerConnection = null
					break
			}
	  }

    this.peerConnection.oniceconnectionstatechange = (_event) => {
        console.info('ICE Connection state change')
    }

    this.peerConnection.onsignalingstatechange = (_event) => {
        console.info('onsignalingstatechange state change')
    }

    this.peerConnection.onnegotiationneeded = (_event) => {
        console.info('negotiationneeded state change')
    }

    const sdp = await fetched.text()
    this.resourceURL = new URL(fetched.headers.get('location') ?? '', url)
    const jsep: RTCSessionDescriptionInit = {
      type: 'offer',
      sdp: sdp
    }
    let remove_protocol = null
    if (this.options.force_protocol == 'tcp') {
      remove_protocol = IceTransport.UDP
    }
    if (this.options.force_protocol == 'udp') {
      remove_protocol = IceTransport.TCP
    }
	  if (this.debugSdp) this.logSdp(jsep.sdp, url)
    jsep.sdp = this.removeCandidates(jsep.sdp, remove_protocol)
    await this.peerConnection.setRemoteDescription(jsep)
      const answer = await this.peerConnection.createAnswer({})
    if (answer.sdp) answer.sdp = answer.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1')
    await this.peerConnection.setLocalDescription(answer)
    console.debug('Sending answer to WHEP server');
    // Send the answer to the resource address
    await fetch(this.resourceURL.toString(), {
      method: 'PATCH',
      body: answer.sdp,
      headers
    })
  }
}
export default WHEPClientJanus
