import { computed, Ref } from 'vue'
import useCurrentUserProfile from '@/modules/user/compositions/useCurrentUserProfile'
import { CallTriage, InvitedCaster } from '@/types/casts'
import { useCurrentTeam } from '@/modules/teams/compositions'
import { Cast } from '../classes'

const useCurrentInvitedCaster = (cast: Ref<Cast|null>) => {

  const { currentUserId, currentUserProfile } = useCurrentUserProfile()
  const { currentTeamMembers } = useCurrentTeam()

  const invitedCasters = computed(() => cast.value?.invited_casters ?? {})
  const invitedCastersQueued = computed(() => Object.values(invitedCasters.value)
    .filter((caster) => caster.triage === CallTriage.Queued && !caster.deleted)
    .sort((a, b) => a.timestamp - b.timestamp))

  const currentInvitedCaster = computed<InvitedCaster|null>(() => {
    const id = currentUserProfile.value?.assignedId ?? currentUserId.value
    return (id && invitedCasters.value[id]) ? invitedCasters.value[id] : null
  })

  const currentTriage = computed(() => currentInvitedCaster.value?.triage ?? CallTriage.Init)

  const currentQueuePosition = computed(() => {
    if (invitedCastersQueued.value.length <= 0) return 0
    return invitedCastersQueued.value.findIndex((caster) => caster.userId === currentInvitedCaster.value?.userId) + 1
  })

  const moderatorUserProfile = computed(() => {
    if (!currentInvitedCaster.value?.moderator) return null
    return currentTeamMembers.value[currentInvitedCaster.value.moderator] ?? null
  })

  return {
    currentInvitedCaster,
    currentQueuePosition,
    currentTriage,
    isAnonymous: computed(() => !!currentInvitedCaster.value?.anonymous),
    isAccepted: computed(() => currentTriage.value === CallTriage.Accepted),
    isInit: computed(() => currentTriage.value === CallTriage.Init),
    isInModeration: computed(() => currentTriage.value === CallTriage.InModeration),
    isInvitedCaster: computed(() => !!currentInvitedCaster.value && !currentInvitedCaster.value.deleted),
    isQueued: computed(() => currentTriage.value === CallTriage.Queued),
    isRejected: computed(() => currentTriage.value === CallTriage.Rejected),
    isSelfTest: computed(() => currentTriage.value === CallTriage.Selftest),
    isUnverified: computed(() => currentTriage.value === CallTriage.Unverified),
    moderatorUserProfile
  }
}

export default useCurrentInvitedCaster
