import { TwitterStyling } from '@/modules/layouts/classes'
import { BoxStylingBase } from '@/modules/layouts/classes/BoxStylingBase'
import { SceneSourceType } from '@/modules/scenes/types'
import { AssetType } from '@/types/assets'
import {
  LayoutBox, LayoutBoxType, Layout, CasterCSS, ChatCSS, LiveChatCSS, LayoutBoxField, TransitionType
} from '@/types/layouts'
import { Scene } from '@/types/scenes'

const fullscreenBox: LayoutBox = {
  active: true,
  field: LayoutBoxField.BACK,
  id: 'fullscreen',
  name: 'fullscreen',
  styling: new BoxStylingBase(),
  type: LayoutBoxType.BOX,
  types: [AssetType.Broadcast],
  zindex: 1
}

const createFullScreenLayout = (teamId: string) => {
  const layout: Layout = {
    name: 'fullscreen',
    info: 'fullscreen',
    boxes: {
      fullscreen: fullscreenBox
    },
    transition: {
      type: TransitionType.NONE,
      actions: []
    },
    castercss: new CasterCSS(),
    chatcss: new ChatCSS(),
    tweetcss: new TwitterStyling(),
    livechatcss: new LiveChatCSS(),
    simplecgcss: {},
    clips: {
      automute: false
    },
    team_id: teamId
  }
  return layout
}

export const createScene = (id: string, name: string, teamId: string, order: number): Scene => {
  const scene: Scene = {
    id,
    name,
    color: null,
    scenePresetIds: [],
    contents: {},
    scene_layout: createFullScreenLayout(teamId),
    sourceType: SceneSourceType.Generic,
    layout_id: 'fullscreen',
    order
  }
  return scene
}
