interface CodecOption {
  name: string,
  id: number,
  connection: number|null
}

interface Codecs {
  audio: CodecOption[],
  video: CodecOption[]
}

export const getCodecOptions = (sdp: string): Codecs => {
  const codecs: Codecs = {
    audio: [],
    video: []
  }
  let channel: 'video'|'audio' = 'audio'
  sdp.replaceAll('\r\n', '\n').split('\n').forEach(line => {
    if (line === 'a=mid:0') channel = 'audio'
    if (line === 'a=mid:1') channel = 'video'
    if (line.startsWith('a=rtpmap')) {
      const id = Number.parseInt(line.split(':')[1].split(' ')[0])
      const name = line.split(' ')[1]
      codecs[channel].push({ id, name, connection: null })
    }
    if (line.includes('apt=')) {
      const id = Number.parseInt(line.split(':')[1].split(' ')[0])
      const connection = Number.parseInt(line.split('apt=')[1])
      codecs[channel].forEach(codec => {
        if (codec.id === id) {
          codec.connection = connection
        }
      })
    }
  })
  return codecs
}

export const removeVpCodecs = (sdp: string): string => {
  const codecs = getCodecOptions(sdp)
  let sdpCopy = sdp
  const vpCodecIds: number[] = []
  codecs.video.forEach((codec) => {
    if (codec.name.includes('VP')) {
      sdpCopy = removeCodec(sdpCopy, codec.id)
      vpCodecIds.push(codec.id)
    }
    if (codec.connection !== null && vpCodecIds.includes(codec.connection)) {
      sdpCopy = removeCodec(sdpCopy, codec.id)
    }
  })
  sdp.split('\n').forEach((line) => {
    if (line.startsWith('m=video')) {
      const preLine = line
      let postLine = line
      vpCodecIds.forEach((id) => {
        postLine = postLine.replace(`${id} `, '')
        postLine = postLine.replace(`${id+1} `, '')
      })
      sdpCopy = sdpCopy.replace(preLine, postLine)
    }
  })
  return sdpCopy
}

const removeCodec = (sdp: string, id: number): string => {
  let sdpCopy = ''
  sdp.split('\n').forEach((line) => {
    if (line === '') return
    if (line.startsWith(`a=rtcp-fb:${id}`)) return
    if (line.startsWith(`a=rtpmap:${id}`)) return
    if (line.startsWith(`a=fmtp:${id}`)) return
    sdpCopy += line + '\n'
  })
  // remove last \n
  return sdpCopy.trimEnd()
}

