import { Result } from 'kiswe-ui'
import { UserRole } from './types/users'

export enum AbilitySubject {
  Cast = 'Cast',
  Event = 'Event',
  Clip = 'Clip',
  Archive = 'Archive',
  AssetGroups = 'AssetGroups',
  Asset = 'Asset',
  Scene = 'Scene',
  Stream = 'Stream',
  Layout = 'Layout',
  Marker = 'Marker',
  Members = 'Member',
  User = 'User',
  Team = 'Team',
  Ftp = 'Ftp',
  StreamAuth = 'StreamAuth',
  Ops = 'Ops',
  CastTemplates = 'CastTemplates',
  Report = 'Report',
  OutputStream = 'OutputStream',
  CasterStream = 'CasterStream',
  TeamSettings = 'TeamSettings',
  Audio = 'Audio',
  //These subjects represent UI assets: pages, sections, concepts
  UiAllTeams = 'UiAllTeams',
  UiBilling = 'UiBilling',
  UiBranding = 'UiBranding',
  UiClipper = 'UiClipper',
  UiDashboard = 'UiDashboard',
  UiGroups = 'UiGroups',
  UiLayouts = 'UiLayouts',
  UiMarkers = 'UiMarkers',
  UiMedia = 'UiMedia',
  UiModeration = 'UiModeration',
  UiOperations = 'UiOperations',
  UiProductions = 'UiProductions',
  UiRoles = 'UiRoles',
  UiStreams = 'UiStreams',
  UiSwitcherCastLayout = 'UiSwitcher',
  UiTalentCastLayout = 'UiTalent',
  UiStationCastLayout = 'UiStation',
  UiTeams = 'UiTeams',
  UiTechnology = 'UiTechnology',
  UiTemplates = 'UiTemplates'
}

export enum AbilityAction {
  Read = 'read',
  Cast = 'cast',
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
  Copy = 'copy',
  Notify = 'notify',
  Do = 'do',
  PlayResume = 'play_resume',
  Manage = 'manage'
}

export type AbilityMap = Record<TeamRoleSubjects, Record<TeamRoleActions, boolean>>

export enum TeamRoleSubjects {
  CAST = 'casts',
  CASTER = 'caster',
  STREAMS = 'streams',
  ASSETS = 'assets',
  SCENES = 'scenes',
  LAYOUTS = 'layouts',
  MARKERS = 'markers',
  CLIPS = 'clips',
  ARCHIVE = 'archive',
  REPORTS = 'reports',
  TEAMSETTINGS = 'teamsettings',
  AUDIO = 'audio',
  MEMBERS = 'members',
  TEMPLATES = 'templates'
}

export enum TeamRoleActions {
  READ = 'read',
  WRITE = 'write',
  DELETE = 'delete'
}

export const TEAM_ROLE_SUBJECTS_UI: Record<TeamRoleSubjects, string> = {
  [TeamRoleSubjects.CASTER]: 'Caster',
  [TeamRoleSubjects.CAST]: 'Events',
  [TeamRoleSubjects.SCENES]: 'Scenes',
  [TeamRoleSubjects.CLIPS]: 'Clips',
  [TeamRoleSubjects.AUDIO]: 'Audio',
  [TeamRoleSubjects.ARCHIVE]: 'Archive',
  [TeamRoleSubjects.STREAMS]: 'Streams',
  [TeamRoleSubjects.ASSETS]: 'Assets',
  [TeamRoleSubjects.MEMBERS]: 'Members',
  [TeamRoleSubjects.LAYOUTS]: 'Layouts',
  [TeamRoleSubjects.MARKERS]: 'Markers',
  [TeamRoleSubjects.TEMPLATES]: 'Templates',
  [TeamRoleSubjects.REPORTS]: 'Reports',
  [TeamRoleSubjects.TEAMSETTINGS]: 'Team Settings'
}

export interface TeamRoleActionMapping {
  subjects: AbilitySubject[],
  text: string,
  actions: Record<TeamRoleActions, AbilityAction[] | null>
}

export const TEAM_ROLE_ABILITY_ACTIONS: Record<TeamRoleSubjects, TeamRoleActionMapping> = {
  [TeamRoleSubjects.CAST]: {
    subjects: [AbilitySubject.Cast, AbilitySubject.Event, AbilitySubject.CasterStream],
    text: 'Managing and entering events.',
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.Notify, AbilityAction.Copy],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.CASTER]: {
    subjects: [AbilitySubject.Cast],
    text: 'Allowing to view, connect and cast as caster/talent to events you are invited to.',
    actions: {
      [TeamRoleActions.READ]: null,
      [TeamRoleActions.WRITE]: [AbilityAction.Cast],
      [TeamRoleActions.DELETE]: null
    }
  },
  [TeamRoleSubjects.STREAMS]: {
    text: 'Managing input and output streams.',
    subjects: [AbilitySubject.Stream, AbilitySubject.OutputStream],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.PlayResume],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.ASSETS]: {
    subjects: [AbilitySubject.AssetGroups, AbilitySubject.Asset],
    text: 'Managing assets and social. Adding assets to scenes.',
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Create, AbilityAction.Edit],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.SCENES]: {
    text: 'Managing scenes. Scene switching and activation of assets.',
    subjects: [AbilitySubject.Scene],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Create, AbilityAction.Edit],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.LAYOUTS]: {
    text: 'Managing scene layouts.',
    subjects: [AbilitySubject.Layout],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Create, AbilityAction.Edit],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.MARKERS]: {
    text: 'Managing SCTE markers.',
    subjects: [AbilitySubject.Marker],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Create, AbilityAction.Edit],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.CLIPS]: {
    text: 'Prepping & playout of clips.',
    subjects: [AbilitySubject.Clip],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Create, AbilityAction.Edit],
      [TeamRoleActions.DELETE]: null
    }
  },
  [TeamRoleSubjects.REPORTS]: {
    text: 'Consulting consumption reports.',
    subjects: [AbilitySubject.Report],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: null,
      [TeamRoleActions.DELETE]: null
    }
  },
  [TeamRoleSubjects.TEAMSETTINGS]: {
    text: 'Access to team settings (e.g. default region, custom roles & permissions, ftp, ...)',
    subjects: [AbilitySubject.TeamSettings, AbilitySubject.Ftp, AbilitySubject.StreamAuth, AbilitySubject.Team],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Edit, AbilityAction.Create],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.AUDIO]: {
    text: 'Access to Audio Program mixer.',
    subjects: [AbilitySubject.Audio],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Edit],
      [TeamRoleActions.DELETE]: null
    }
  },
  [TeamRoleSubjects.MEMBERS]: {
    text: 'Managing members and assigning roles.',
    subjects: [AbilitySubject.Members, AbilitySubject.User],  // Maybe needs to be merged
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Edit, AbilityAction.Create],
      [TeamRoleActions.DELETE]: null
    }
  },
  [TeamRoleSubjects.TEMPLATES]: {
    text: 'Managing event templates.',
    subjects: [AbilitySubject.CastTemplates],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Edit, AbilityAction.Create],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  },
  [TeamRoleSubjects.ARCHIVE]: {
    text: 'Managing archives and create clips.',
    subjects: [AbilitySubject.Archive],
    actions: {
      [TeamRoleActions.READ]: [AbilityAction.Read],
      [TeamRoleActions.WRITE]: [AbilityAction.Edit, AbilityAction.Create],
      [TeamRoleActions.DELETE]: [AbilityAction.Delete]
    }
  }
}

export class TeamRole {
  team_id: string = ''
  name: string = ''
  abilities: AbilityMap = {
    [TeamRoleSubjects.CAST]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.CASTER]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.STREAMS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.ASSETS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.SCENES]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.LAYOUTS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.MARKERS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.CLIPS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.REPORTS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.TEAMSETTINGS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.AUDIO]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.MEMBERS]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.TEMPLATES]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    },
    [TeamRoleSubjects.ARCHIVE]: {
      [TeamRoleActions.READ]: false,
      [TeamRoleActions.WRITE]: false,
      [TeamRoleActions.DELETE]: false
    }
  }
  description: string = ''
  deleted: boolean = false

  constructor (data: any) {
    // TODO: Should we also disallow empty string here?
    if (typeof data.team_id !== 'string') {
      throw new Error ('Could not parse team_id')
    }
    if (typeof data.deleted === 'boolean') this.deleted = data.deleted
    this.team_id = data.team_id
    if (typeof data.name !== 'string') throw new Error ('Could not parse name')
    this.name = data.name
    if (typeof data.description !== 'string') throw new Error ('Could not parse description')
    this.description = data.description
    if (typeof data.abilities !== 'object') throw new Error ('Could not parse abilities')
    for (const [subject, actions] of Object.entries(data.abilities)) {
      if (!Object.values(TeamRoleSubjects).includes(subject as TeamRoleSubjects)) {
        console.warn('Could not parse subject ' + subject)
        continue
      }
      if (typeof actions !== 'object') throw new Error (`Actions of subject ${subject} is no object`)
      for (const [action, enabled] of Object.entries(data.abilities[subject])) {
        if (!Object.values(TeamRoleActions).includes(action as TeamRoleActions)) {
          console.warn(`Could not parse action ${action} for subject ${subject}`)
          continue
        }
        const abilitySubject = this.abilities[subject as TeamRoleSubjects]
        abilitySubject[action as TeamRoleActions] = enabled as boolean
      }
    }
  }

  static load (data: unknown): Result<TeamRole, 'invalid param'|'unknown'> {
    try {
      return Result.success(new TeamRole(data as any))
    } catch (error) {
      return Result.fromUnknownError('invalid param', error)
    }
  }

  setName (name: string): this {
    this.name = name
    return this
  }

  toAbilities (): Ability[] {
    const abilities: Ability[] = []
    for (const subject of Object.keys(this.abilities)) {
      const actions = TEAM_ROLE_ABILITY_ACTIONS[subject as TeamRoleSubjects]
      for (const abilitySubject of actions.subjects) {
        const ability: Ability = {
          subject: abilitySubject,
          actions: []
        }
        for (const [action, enabled] of Object.entries(this.abilities[subject as TeamRoleSubjects])) {
          if (enabled) {
            const localActions = actions.actions[action as TeamRoleActions]
            if (localActions !== null) ability.actions.push(...localActions)
          }
        }
        abilities.push(ability)
      }
    }
    return abilities
  }

  // TODO, needs to be more genereric
  toJSON (): any {
    return {
      team_id: this.team_id,
      name: this.name,
      abilities: this.abilities,
      description: this.description,
      deleted: this.deleted
    }
  }
}

export interface Ability {
  subject: AbilitySubject,
  actions: AbilityAction[]
}

export enum AbilityRole {
  Admin = 'admin',
  Analyst = 'analyst',
  Developer = 'superuser_developer', //UserRole.Developer
  GuestCaster = 'guestcaster',
  Member = 'member',
  Moderator = 'moderator',
  Operator = 'superuser_operator', //UserRole.Operator
  Sales = 'superuser_sales', //UserRole.Sales
  Station = 'station',
  SuperUser = 'superuser' //UserRole.SuperUser
}

export const getAbilityRoleFromUserRole = (role: UserRole): AbilityRole|null => {
  switch (role) {
    case UserRole.SuperUser:
      return AbilityRole.SuperUser
    case UserRole.Sales:
      return AbilityRole.Sales
    case UserRole.Operator:
      return AbilityRole.Operator
    case UserRole.Developer:
      return AbilityRole.Developer
  }
  return null
}


function addAbility (abilities: Ability[], newAbility: Ability) {
  const index = abilities.findIndex((existingAbility) => existingAbility.subject == newAbility.subject)
  if (index === -1) {
    abilities.push(newAbility)
    return
  }
  for (const action of newAbility.actions) {
    if (!abilities[index].actions.includes(action)) {
      abilities[index].actions.push(action)
    }
  }
}

function addAbilityFromRole (abilities: Ability[], role: AbilityRole) {
  const abilitiesByRole = getAbilitiesByRole(role)
  for (const ability of abilitiesByRole) {
    addAbility(abilities, ability)
  }
}

const getAbilitiesByRole = (role: AbilityRole): Ability[] => {
  const abilities: Ability[] = []
  switch (role) {
    // Only add the abilities that are new for that role
    case AbilityRole.Moderator:
      addAbility(abilities, { subject: AbilitySubject.UiModeration, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.Analyst:
      addAbility(abilities, { subject: AbilitySubject.UiDashboard, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.GuestCaster:
      addAbility(abilities, { subject: AbilitySubject.Cast, actions: [AbilityAction.Cast] })
      addAbility(abilities, { subject: AbilitySubject.Event, actions: [AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.OutputStream, actions: [AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Audio, actions: [AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.UiProductions, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiTalentCastLayout, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.Station:
      addAbilityFromRole(abilities, AbilityRole.GuestCaster)
      addAbility(abilities, { subject: AbilitySubject.UiStationCastLayout, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.Member:
      addAbilityFromRole(abilities, AbilityRole.GuestCaster)
      addAbility(abilities, { subject: AbilitySubject.AssetGroups, actions: [AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Scene, actions: [AbilityAction.Read, AbilityAction.Edit, AbilityAction.Create, AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Audio, actions: [AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.Asset, actions: [AbilityAction.Read, AbilityAction.Edit, AbilityAction.Create, AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Clip, actions: [AbilityAction.Read, AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.OutputStream, actions: [AbilityAction.Read, AbilityAction.Edit, AbilityAction.PlayResume] })
      addAbility(abilities, { subject: AbilitySubject.CasterStream, actions: [AbilityAction.Read, AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.Cast, actions: [AbilityAction.Read, AbilityAction.Create, AbilityAction.Edit, AbilityAction.Copy, AbilityAction.Notify] })
      addAbility(abilities, { subject: AbilitySubject.CastTemplates, actions: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete, AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Event, actions: [AbilityAction.Create, AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.Stream, actions: [AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Layout, actions: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete, AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Marker, actions: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete, AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.AssetGroups, actions: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete, AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.User, actions: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete, AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Team, actions: [AbilityAction.Edit, AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Archive, actions: [AbilityAction.Read, AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Members, actions: [AbilityAction.Read, AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.TeamSettings, actions: [AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.Report, actions: [AbilityAction.Read] })
      addAbility(abilities, { subject: AbilitySubject.UiProductions, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiClipper, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiStreams, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiMedia, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiTemplates, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiLayouts, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiMarkers, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiDashboard, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiModeration, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiSwitcherCastLayout, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.Admin:
      addAbilityFromRole(abilities, AbilityRole.Member)
      addAbility(abilities, { subject: AbilitySubject.Cast, actions: [ AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Event, actions: [ AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Stream, actions: [AbilityAction.Create, AbilityAction.Edit, AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Ftp, actions: [AbilityAction.Read, AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.Members, actions: [AbilityAction.Create, AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.StreamAuth, actions: [AbilityAction.Read, AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.TeamSettings, actions: [AbilityAction.Create, AbilityAction.Delete, AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.UiTeams, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiGroups, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiBranding, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiBilling, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.Sales:
      addAbility(abilities, { subject: AbilitySubject.Team, actions: [AbilityAction.Create, AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Cast, actions: [AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Event, actions: [AbilityAction.Delete] })
      addAbility(abilities, { subject: AbilitySubject.Report, actions: [AbilityAction.Edit] })
      addAbility(abilities, { subject: AbilitySubject.UiAllTeams, actions: [AbilityAction.Manage] })
      addAbility(abilities, { subject: AbilitySubject.UiDashboard, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.Operator:
      addAbilityFromRole(abilities, AbilityRole.Sales)
      addAbilityFromRole(abilities, AbilityRole.Admin)
      addAbility(abilities, { subject: AbilitySubject.UiOperations, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.Developer:
      addAbilityFromRole(abilities, AbilityRole.Operator)
      addAbility(abilities, { subject: AbilitySubject.UiTechnology, actions: [AbilityAction.Manage] })
      break
    case AbilityRole.SuperUser:
      addAbilityFromRole(abilities, AbilityRole.Developer)
      break
    }
  return abilities
}

export const abilitiesToTeamRole = (role: AbilityRole): TeamRole => {
  const abilities = getAbilitiesByRole(role)
  const teamrole = new TeamRole({
    team_id: 'all',
    deleted: false,
    name: role,
    description: '',
    abilities: {}
  })
  abilities.forEach((ability) => {
    ability.actions.forEach((action) => {
      Object.entries(TEAM_ROLE_ABILITY_ACTIONS).forEach(([subject, actions]) => {
        if (actions.subjects.includes(ability.subject)) {
          Object.entries(actions.actions).forEach(([actionkey, teamactions]) => {
            if (teamactions !== null && teamactions.includes(action)) {
              teamrole.abilities[subject as TeamRoleSubjects][actionkey as TeamRoleActions] = true
            }
          })
        }
      })
    })
  })
  return teamrole
}

export default getAbilitiesByRole
