import { AudioPanelState } from './audio.state'

export const getters = {
  hasActiveChannelsForLocalAudio (state: AudioPanelState): boolean {
    if (state.activeAudioContext && state.audioContextStates[state.activeAudioContext]) {
      return state.audioContextStates[state.activeAudioContext].localChannels.length > 0
    }
    return false
  },
  localChannels (state: AudioPanelState) {
    if (state.activeAudioContext && state.audioContextStates[state.activeAudioContext]) {
      return state.audioContextStates[state.activeAudioContext].localChannels
    }
    return null
  },
  localSource (state: AudioPanelState) {
    if (state.activeAudioContext && state.audioContextStates[state.activeAudioContext]) {
      return state.audioContextStates[state.activeAudioContext].localSource
    }
    return null
  }
}
