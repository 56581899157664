import store from '@/store'
import { computed,  onMounted,  onUnmounted } from 'vue'
import { pickBy } from 'lodash'

const useTranscodingRegions = () => {
	const transcodingregions = computed(() => {
    return store.state.admin.transcodingregions
  })

  onMounted(async () => {
    try {
      await store.dispatch.admin.subscribeTranscodingRegions()
    } catch (error) {
      console.error('Could not register useTranscodingRegions', error)
    }
  })
	onUnmounted(async () => {
    try {
      await store.dispatch.admin.unsubscribeTranscodingRegions()
    } catch (error) {
      console.error('Could not unregister useTranscodingRegions', error)
    }
	})

  const myRegion = computed(() => store.getters.user.myRegion)

  const visibleRegions = computed(() => pickBy(transcodingregions.value, (region) => region.visible))

	return {
		transcodingregions,
    visibleRegions,
    myRegion
	}
}

export default useTranscodingRegions
