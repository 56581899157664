import fb from '../../firebase'
import {
  OpsMonitorState,
  RootState,
  ArchiverTask
} from '../types'
import { ActionContext } from 'vuex'
import store, { moduleActionContext } from '..'
import { lazyUpdate } from '@/store/storehelper'
import firebase from 'firebase/compat/app'
import { BackupOverview, SocialMediaStats, TranscodingMachine, GlobalAccelerator } from '@/types/ops'
import { CastStatus } from '@/types/casts'
import { Stream } from '@/types/streams'
import { UserProfile } from '@/types/users'
import { KeyDict } from '@/types'
import studio, { CollectionNames, AdminDocNames } from '@/modules/database/utils'
import { CastChangeHandlerFactory } from '@/store/modules/events/castChangeHandlerFactory'
import { defineModule } from 'direct-vuex'
import { Cast } from '@/modules/casts/classes'
import { DatabaseAction, MergeOption } from '@/modules/database/utils/database'
import { FirestoreDocSnapper, FirestoreQuerySnapper, FirestoreSnapperType } from '@/modules/database/utils/databaseQuerySubscriptions'
import { can } from '@/ability'
import { AbilityAction, AbilitySubject } from '@/abilitiesByRole'
import { MP4Create, WRTCServerType } from '@/types/admin'
import { WrtcServerStat } from '@/modules/transcodingregions/types'

const castChangeHandlers = CastChangeHandlerFactory.getChangeHandlers(null)

type OpsMonitorContext = ActionContext<OpsMonitorState, RootState>

const OpsMonitorModule = defineModule({
  namespaced: true,
  state: {
    archiverQueue: {} as KeyDict<ArchiverTask>,
    archivingCasts: {},
    backupOverview: new BackupOverview(),
    brokenArchivedCasts: {},
    debugCastScenes: false,
    globalAccelerator: new GlobalAccelerator(),
    opsmonitorcasts: {},
    pendingSupportRequests: [],
    socialMediaMonitor: null,
    srtstreams: {},
    streams: {},
    transcodingMachines: {},
    users: {},
    wrtcstats: {}
  } as OpsMonitorState,
  actions: {
    subscribeDBBackupOverview (_context: OpsMonitorContext) {
      const snapper: FirestoreDocSnapper = {
        doc: fb.db.collection('admin').doc('db_backup'),
        mutation: store.commit.opsmonitor.updateDBBackupOverview,
        type: FirestoreSnapperType.Document
      }
      studio.subscriptions.subscribeFB('dbbackupoverview', snapper)
    },
    unsubscribeDBBackupOverview (_context: OpsMonitorContext) {
      studio.subscriptions.unsubscribeFB('dbbackupoverview')
    },
    async subscribeGlobalAccelerator (_context: OpsMonitorContext) {
      const snapper: FirestoreDocSnapper = {
        doc: fb.db.collection(CollectionNames.ADMIN).doc('globalaccelerator'),
        mutation: store.commit.opsmonitor.updateGlobalAccelerator,
        type: FirestoreSnapperType.Document
      }
      await studio.subscriptions.subscribeFB('globalaccelerator', snapper, undefined, true)
    },
    unsubscribeGlobalAccelerator (_context: OpsMonitorContext) {
      studio.subscriptions.unsubscribeFB('globalaccelerator')
    },
    async subscribeWrtcStats (context: OpsMonitorContext) {
      const { commit } = opsMonitorModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        key: 'wrtcstats',
        query: studio.db.collection(CollectionNames.WOWZA_STATS),
        callback: commit.updateWrtcStats
      })
      result.logIfError('Failed to subscribe to WrtcStats')
    },
    unsubscribeWrtcStats (context: OpsMonitorContext) {
      const { commit } = opsMonitorModuleActionContext(context)
      studio.subscriptions.unsubscribe('wrtcstats', commit.cleanupWrtcStats)
    },
    async fetchWrtcStats (context: OpsMonitorContext) {
      const { commit } = opsMonitorModuleActionContext(context)
      console.log(`OpsMonitorModule::fetchWrtcStats Start getting wowza stats logging ${Date.now()}`)
      const statsResult = await studio.db.collection(CollectionNames.WOWZA_STATS).get()
      if (statsResult.isFailure) {
        statsResult.log('Failed to get server stats')
        return
      }
      console.log(`OpsMonitorModule::fetchWrtcStats fetched and update ${Date.now()}`, statsResult.value)
      commit.updateWrtcStats(statsResult.value)
    },
    subscribeSRTStreams (_context: OpsMonitorContext) {
      const snapper: FirestoreQuerySnapper = {
        query: fb.db.collection(CollectionNames.STREAMS).where('srt_state', '>', ''),
        mutation: store.commit.opsmonitor.updateSRTStreams,
        type: FirestoreSnapperType.Query
      }
      studio.subscriptions.subscribeFB('srtstreams', snapper)
    },
    unsubscribeSRTStreams (_context: OpsMonitorContext) {
      studio.subscriptions.unsubscribeFB('srtstreams')
    },
    subscribeSocialMediaMonitor (_context: OpsMonitorContext) {
      const snapper: FirestoreDocSnapper = {
        doc: fb.db.collection('admin').doc('twitter'),
        mutation: store.commit.opsmonitor.updateSocialMediaMonitor,
        type: FirestoreSnapperType.Document
      }
      studio.subscriptions.subscribeFB('socialmediamonitor', snapper)
    },
    unsubscribeSocialMediaMonitor (_context: OpsMonitorContext) {
      studio.subscriptions.unsubscribeFB('socialmediamonitor')
    },
    async subscribeArchiverQueue (context: OpsMonitorContext) {
      const { commit } = opsMonitorModuleActionContext(context)
      const resultQueue = await studio.subscriptions.subscribe({
        key: 'archiverqueue',
        query: studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.MP4_CREATE),
        callback: (archiverQueue: MP4Create) => {
          commit.updateArchiverQueue(archiverQueue)
          const promises: Promise<void>[] = []
          for (const key in archiverQueue) {
            if (!studio.subscriptions.exists(`archivercast_${key}`)) {
              promises.push(store.dispatch.opsmonitor.subscribeArchiverCast(key))
            }
          }
          for (const key of studio.subscriptions.runningKeys('archivercast_')) {
            const castid = key.split('archivercast_')[1]
            if (!Object.keys(archiverQueue).includes(castid)) {
              promises.push(store.dispatch.opsmonitor.unsubscribeArchiverCast(castid))
            }
          }
          Promise.all(promises).catch((error) => console.error(error))
        }
      })
      resultQueue.logIfError('archiverqueue, ops store module')

      let query = studio.db.collection(CollectionNames.CASTS).where('status', '==', CastStatus.Archived)
      if (!store.state.user.profile?.isSuperUser) {
        query = query.where('team_id', '==', store.state.team.activeTeam)
      }
      query = query.where('start_date', '>', new Date(Date.now() - 48 * 3600 * 1000))
      const resultBroken = await studio.subscriptions.subscribe({
        key: `brokenarchivedcasts`,
        query,
        callback: commit.updateBrokenArchivedCasts
      })
      resultBroken.logIfError('brokenarchivedcasts, ops store module')
    },
    unsubscribeArchiverQueue (context: OpsMonitorContext) {
      const { commit } = opsMonitorModuleActionContext(context)
      studio.subscriptions.unsubscribe('archiverqueue', () => {
        const promises: Promise<void>[] = []
        for (const key of studio.subscriptions.runningKeys('archivercast_')) {
          const castid = key.split('archivercast_')[1]
          promises.push(store.dispatch.opsmonitor.unsubscribeArchiverCast(castid))
        }
        Promise.all(promises).then(() => {
          commit.clearArchiverQueue()
        }).catch((error) => console.error(error))
      })
      studio.subscriptions.unsubscribe('brokenarchivedcasts')
    },
    async subscribeArchiverCast (context: OpsMonitorContext, castid: string) {
      const { commit } = opsMonitorModuleActionContext(context)
      const key = `archivercast_${castid}`
      const result = await studio.subscriptions.subscribe({
        key,
        query: studio.db.collection(CollectionNames.CASTS).doc(castid),
        callback: commit.updateArchiverCast
      })
      result.logIfError(`${key}, ops store module`)
    },
    async unsubscribeArchiverCast (_context: OpsMonitorContext, castid: string) {
      studio.subscriptions.unsubscribe(`archivercast_${castid}`)
    },
    async requestRearchive (_context: OpsMonitorContext, castid: string) {
      const cast = await studio.db.collection(CollectionNames.CASTS).doc(castid).get()
      if (cast.isFailure) {
        cast.logIfError('Failed to get cast info for rearchiving')
        return
      }
      const entry: KeyDict<{ k360event: number|string, mode: string, stream?: string}> = {}
      entry[`full${cast.value.k360_event_id}`] = {
            k360event: cast.value.k360_event_id ?? 0,
            mode: 'full'
      }
      if (cast.value.record_isos === true) {
        entry[`ingests_${cast.value.k360_event_id}`] = {
            k360event: cast.value.k360_event_id ?? 0,
            mode: 'full',
            stream: 'ingestcc'
          }
      }
      const data = {
        [cast.value.id]: entry
      }
      const result = await studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.MP4_CREATE)
                                    .set(data, MergeOption.MERGE)
      result.logIfError('Failed to update mp4_create with rearchiving data')
    },
    async addGlobalAcceleratorMapping (_context: OpsMonitorContext, { gaUrl, wrtcUrl}: { gaUrl: string, wrtcUrl: string }) {
      return studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.GLOBAL_ACCELERATOR).set({
        mapping: {
          [gaUrl]: wrtcUrl
        }
      }, MergeOption.MERGE)
    },
    async removeGlobalAcceleratorMapping (_context: OpsMonitorContext, gaUrl: string) {
      return studio.db.collection(CollectionNames.ADMIN).doc(AdminDocNames.GLOBAL_ACCELERATOR).set({
        mapping: {
          [gaUrl]: DatabaseAction.deleteField()
        }
      }, MergeOption.MERGE)
    },
    async updateSocialMediaMonitorSettings (_context: OpsMonitorContext, settings: Partial<SocialMediaStats>) {
      await fb.db.collection('admin').doc('twitter').set(settings, { 'merge': true })
    },
    async subscribeOpsMonitorCasts (context: OpsMonitorContext) {
      const { commit } = opsMonitorModuleActionContext(context)
      const casts = studio.db.collection(CollectionNames.CASTS)
        .where('create_event', '==', true)
        .where('status', 'in', [CastStatus.Booting, CastStatus.InProgress, CastStatus.Pregame])
      const result = await studio.subscriptions.subscribe({
        key: 'opsmonitorcasts',
        query: casts.limit(75),
        callback: commit.updateOpsMonitorCasts
      })
      result.logIfError('subscribeOpsMonitorCasts')
    },
    async unsubscribeOpsMonitorCasts (_context: OpsMonitorContext) {
      studio.subscriptions.unsubscribe('opsmonitorcasts')
    },
    async subscribeTranscodingMachines (context: OpsMonitorContext) {
      const { commit } = opsMonitorModuleActionContext(context)
      const snapper: FirestoreQuerySnapper = {
        query: fb.db.collection('transcodingmachines'),
        mutation: commit.updateTranscodingMachines,
        type: FirestoreSnapperType.Query
      }
      studio.subscriptions.subscribeFB('transcodingmachines', snapper)
    },
    async unsubscribeTranscodingMachines (_context: OpsMonitorContext) {
      studio.subscriptions.unsubscribeFB('transcodingmachines')
    },
    async subscribeStreams (context: OpsMonitorContext, teamId: string) {
      const { commit } = opsMonitorModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        key: `opsstreams_${teamId}`,
        query: studio.db.collection(CollectionNames.STREAMS).where('team_id', '==', teamId).where('deleted', '==', false),
        callback: commit.updateStreams
      })
      result.logIfError('subscribeStreams, ops store module')
    },
    async unsubscribeStreams (_context: OpsMonitorContext, teamId: string) {
      studio.subscriptions.unsubscribe(`opsstreams_${teamId}`)
    },
    async subscribeUsers (context: OpsMonitorContext, teamId: string) {
      const { commit } = opsMonitorModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        query: studio.db.collection(CollectionNames.PROFILES).where(`teams.${teamId}`, '==', true),
        callback: commit.updateUsers,
        key: `opsusers_${teamId}`
      })
      result.logIfError(`Could not subscribe to user of team ${teamId}`)
    },
    async unsubscribeUsers (_context: OpsMonitorContext, teamId: string) {
      studio.subscriptions.unsubscribe(`opsusers_${teamId}`)
    },
    updateDebugCastScenes (context: OpsMonitorContext, enabled: boolean) {
      const { commit } = opsMonitorModuleActionContext(context)
      if (!can.value(AbilityAction.Do, AbilitySubject.Ops)) return
      commit.updateDebugCastScenes(enabled)
    }
  } as const,
  mutations: {
    updateSupportRequests (state: OpsMonitorState, snap: firebase.firestore.QuerySnapshot) {
      const requests: any[] = []
      snap.forEach(request => {
        requests.push(request.data())
      })
      state.pendingSupportRequests = requests
    },
    updateOpsMonitorCasts (state: OpsMonitorState, casts: KeyDict<Cast>) {
      Object.entries(casts).forEach(([castId, cast]) => {
        if (!cast.deleted) {
          const oldCast = state.opsmonitorcasts[castId] ?? null
          castChangeHandlers.forEach(handler => handler.handleChanges(oldCast, cast))
          if (cast.status === CastStatus.Booting && cast.end_date < new Date()) return
          state.opsmonitorcasts[castId] = cast
        }
      })
      Object.keys(state.opsmonitorcasts).forEach((castId) => {
        if (!Object.keys(casts).includes(castId)) {
          delete state.opsmonitorcasts[castId]
        }
      })
    },
    updateTranscodingMachines (state: OpsMonitorState, snap: firebase.firestore.QuerySnapshot) {
      state.transcodingMachines = {}
      snap.forEach(transcoder => {
        state.transcodingMachines[transcoder.id] = transcoder.data() as TranscodingMachine
      })
    },
    updateStreams (state: OpsMonitorState, streams: KeyDict<Stream>) {
      Object.entries(streams).forEach(([streamId, stream]) => {
        if (!state.streams[streamId]) {
          state.streams[streamId] = stream
        } else {
          lazyUpdate(state.streams, streamId, stream)
        }
      })
    },
    updateUsers (state: OpsMonitorState, profiles: KeyDict<UserProfile>) {
      Object.entries(profiles).forEach(([id, profile]) => {
        if (state.users[id] === undefined) {
          state.users[id] = profile
        } else {
          lazyUpdate(state.users, id, profile)
        }
      })
    },
    updateSocialMediaMonitor (state: OpsMonitorState, snap: firebase.firestore.DocumentSnapshot) {
      const socialmonitor = snap.data()
      if (socialmonitor) {
        state.socialMediaMonitor = socialmonitor as SocialMediaStats
      }
    },
    updateArchiverQueue (state: OpsMonitorState, archiverQueue: MP4Create) {
      state.archiverQueue = archiverQueue
    },
    clearArchiverQueue (state: OpsMonitorState) {
      state.archiverQueue = {}
    },
    updateArchiverCast (state: OpsMonitorState, cast: Cast) {
      state.archivingCasts[cast.id] = cast
    },
    updateBrokenArchivedCasts (state: OpsMonitorState, casts: KeyDict<Cast>) {
      const newlist: string[] = []
      Object.values(casts).forEach((cast) => {
        if (!cast.mp4 && cast.deleted !== true && cast.video_start_time !== null) {
          newlist.push(cast.id)
          state.brokenArchivedCasts[cast.id] = cast
        }
      })
      for (const key in state.brokenArchivedCasts) {
        if (!newlist.includes(key)) {
          delete state.brokenArchivedCasts[key]
        }
      }
    },
    updateSRTStreams (state: OpsMonitorState, snap: firebase.firestore.QuerySnapshot) {
      const newlist: string[] = []
      snap.forEach(doc => {
        const streamid = doc.id
        newlist.push(streamid)
        const srtstream = doc.data()
        state.srtstreams[streamid] = srtstream
      })
      for (const key in state.srtstreams) {
        if (!newlist.includes(key)) {
          delete state.srtstreams[key]
        }
      }
    },
    updateDBBackupOverview (state: OpsMonitorState, snap: firebase.firestore.DocumentSnapshot) {
      if (snap.exists) {
        lazyUpdate(state, 'backupOverview', snap.data())
      }
    },
    updateGlobalAccelerator (state: OpsMonitorState, snap: firebase.firestore.DocumentSnapshot) {
      if (snap.exists) {
        state.globalAccelerator = new GlobalAccelerator(snap.data())
      }
    },
    updateWrtcStats (state: OpsMonitorState, wrtcServerStats: KeyDict<WrtcServerStat>) {
      // Note: Doing `state.wowzastats={}` first and then `Vue.set(state.wowzastats, <id>, new WowzaStats(doc.data()))`
      //       in the loop caused problems with the reactivity of the `computed()` inside the `wowzaStats` composition.
      const newWrtcStats: KeyDict<WrtcServerStat> = {}
      for (const [serverId, stats] of Object.entries(wrtcServerStats)) {
        newWrtcStats[serverId] = stats
        if (stats.serverType !== WRTCServerType.WOWZA) continue
        const server = 'wrtc-' + serverId + '.kiswe.com'
        Object.entries(state.globalAccelerator.mapping).forEach(([ga, wrtc]) => {
          if (wrtc === server) {
            const gaId = ga.replace('wrtc-', '').replace('.kiswe.com', '')
            newWrtcStats[gaId] = stats
          }
        })
      }
      lazyUpdate(state, 'wrtcstats', newWrtcStats)
    },
    cleanupWrtcStats (state: OpsMonitorState) {
      state.wrtcstats = {}
    },
    updateDebugCastScenes (state: OpsMonitorState, enabled: boolean) {
      state.debugCastScenes = enabled
    }
  }
})

export default OpsMonitorModule
export const opsMonitorModuleActionContext = (context: OpsMonitorContext) => moduleActionContext(context, OpsMonitorModule)
