import { RenderMode } from '@/modules/scenes/types'
import { ActionPaletteTabs } from '@/modules/ui/types'
import { EventsState } from '@/store/types'
import { TalkbackMode } from '@/types'
import { Asset } from '@/types/assets'
import { AudioMixType, PlaylistStatus, WATERMARK_DEFAULT } from '@/types/casts'

const onlineUsers = ['Adam Reinhardt', 'Ryan Mahoney', 'Francis Zane']

const state: EventsState = {
  actionPaletteTab: ActionPaletteTabs.Assets,
  activeAudioTracks: {},
  activeCasts: {},
  allevents: [],
  assets: [] as Asset[],
  audioInit: false,
  audioMix: AudioMixType.Program,
  castReady: false,
  casting: false,
  castingAs: null,
  cefEvent: false,
  cefMixer: '',
  cefMode: RenderMode.Full,
  completedEvents: [],
  completedEventsLoaded: false,
  currentCast: null,
  currentCastId: null,
  currentCasterStream: '',
  currentPlaylist: {} as PlaylistStatus,
  customStreams: {},
  editPresetId: null,
  editSceneId: null,
  events: [],
  eventsLoaded: false,
  fullscreen: false,
  inCast: false,
  isPreviewingOutput: false,
  liveEditVolumes: {},
  localGlobalVolume: 100,
  monitorMutes: {},
  monitorVolumes: {},
  onlineUsers: onlineUsers,
  openScene: null,
  outputStreams: {},
  preloadSourceStreams: {},
  programVolumes: {},
  sceneFilter: '',
  sceneInPreview: null,
  selfMute: false,
  simpleMode: false,
  sourceStreams: {},
  streamDocs: {},
  streamImages: {},
  streamNames: {},
  talkbackGlobalMute: false,
  talkbackGlobalVolume: 100,
  talkbackMode: TalkbackMode.Global,
  talkbackMutes: {},
  talkbackVolumes: {},
  watermark: WATERMARK_DEFAULT
}

export default state
