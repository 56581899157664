export class ThorApiRequest {
  private _baseUrl: string
  private _acceptHeader: string
  private _contentType: string
  private _apiToken: string

  constructor () {
    this._baseUrl = ''
    this._acceptHeader = 'application/json;'
    this._contentType = 'application/json'
    this._apiToken = ''
  }

  get baseUrl (): string {
    return this._baseUrl
  }
  set baseUrl (url: string) {
    this._baseUrl = url
  }

  get acceptHeader (): string {
    return this._acceptHeader
  }
  set acceptHeader (acceptHeader: string) {
    this._acceptHeader = acceptHeader
  }

  get contentType (): string {
    return this._contentType
  }
  set contentType (contentType: string) {
    this._contentType = contentType
  }

  get apiToken (): string {
    return this._apiToken
  }
  set apiToken (apiToken: string) {
    this._apiToken = apiToken
  }
}
