import { ReleaseNotes } from "@/types/releasenotes"

export default () => {
  const releases: ReleaseNotes = {
    currentVersion: '4.0.0',
    commitId: '00128eb517b22ea112b8dd2fb422e8c05ea34fe7',
    tagId: 'v99.99.99',
    releases: {
      '2.5': {},
      '2.6': {},
      '2.6.1': {},
      '2.6.2': {},
      '2.7': {
        features: [
          'Create your own scene layouts',
          'Support for HTML Widgets'
        ]
      },
      '2.8': {
        features: [
          'Provide "My Computer" status to caster',
          'Layout editor improvements'
        ]
      },
      '2.8.1': {
        features: [
          'Improved status messaging of cast and casters',
          'Input and output streams defined as server url and stream key'
        ]
      },
      '2.8.2': {
        features: [
          'Several bugfixes'
        ]
      },
      '2.9': {
        features: [
          'Style your own Twitter Widget',
          'Chat Widget'
        ]
      },
      '2.10': {
        features: [
          'Instagram hashtag integration'
        ]
      },
      '2.10.1': {
        features: [
          'Several stability upgrades'
        ]
      },
      '2.11': {
        features: [
          'Improve system feedback to caster',
          'Change preview mix quality option for casters with weaker network'
        ]
      },
      '3.0': {
        features: [
          'New Caster UI',
          'Folder structure for assets',
          'Chroma keying for casters (Beta)'
        ]
      },
      '3.1': {
        features: [
          'Individual Caster Talkback',
          'Play/pause functionality for clips',
          'Duplicate casters as broadcast streams',
          'Several bugfixes and stability upgrades'
        ]
      },
      '3.2': {
        features: [
          'Cast templates',
          'Create a copy of a running cast',
          'Interlaced outputs',
          'Several bugfixes and UI improvements'
        ]
      },
      '3.2.1': {
        features: [
          'Bug fix for deleted user accounts.',
          'GOP of output streams are adjusted according to social media target.'
        ]
      },
      '3.3': {
        features: [
          'Use assets in the layout editor to improve layouts',
          'Enable to record ISOs of the casters'
        ]
      },
      '3.4': {
        features: [
          'Improvements to volume mixer and talkback',
          'Youtube live stream integration'
        ]
      },
      '3.5': {
        features: [
          'Crew only talkback',
          'Video cropping of broadcast and caster streams',
          'In-cast video player preview and seek options'
        ]
      },
      '3.6': {
        features: [
          'Added additional metrics of casters and system',
          'Cast can be created in Test Mode',
          'Cast support can be requested from the dashboard'
        ]
      },
      '3.6.1': {
        features: [
          'Scene Transitions with Stingers',
          'Stability and Performance updates',
          'Several bugsfixes'
        ]
      },
      '3.7': {
        features: [
          'Create your own custom roles & permission sets',
          'Clips Playlists & audio sliders in the Clips Player',
          'Share your single-click join cast URL with casters and talent',
          'Casters can talk back across open talkback channels when off air',
          'Simple CG, meet our brand new simple text overlay with live updating',
          'Making production life easier',
          [
            'Pop-out the entire control panel on a second screen',
            'Select the tweet text on display',
            'Delete entire Assets folders at once',
            'Set your default mixer region'
          ]
        ]
      },
      '3.8.0': {
        features: [
          'Allow anonymous guests to connect without an account',
          'Moderate guests before they join the production',
          'Force a clip to start playing on switching to a specific scene',
          'Control your clips audio tracks in the audio control panel',
          'Organize your team members in groups',
          'Enable RTMP authentication for your ingest streams'
        ]
      },
      '3.9.0': {
        features: [
          'Merged the concept of casts and events in 1',
          'Reduced scene switching delays'
        ]
      },
      '3.9.3': {
        features: [
          'Integration with Stream Deck'
        ]
      },
      '3.10.0': {
        features: [
          'Improved call in feature'
        ]
      },
      '3.11.0': {
        features: [
          'Improved call in feature'
        ]
      },
      '4.0.0': {
        features: [
          'New UI and workflow',
          'Commentator events'
        ]
      }
    }
  } as ReleaseNotes
  return releases
}
