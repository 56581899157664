/* eslint-disable max-lines */
/* Until depricated function are gone */
import firebase from 'firebase/compat/app'
import { MVar } from '@/helpers/async_helpers'
import { KeyDict } from '@/types'
import { Result, ResultVoid } from 'kiswe-ui'

export interface Subscription {
  count: number,
  destroy: UnsubscribeFunction | null,
  /** @deprecated */
  snapper: FirestoreSnapper | null,
  lastQuerySize?: number
  firstQueryResponse?: number
}

/** @deprecated */
export interface FirestoreSnapper {
  readonly type: FirestoreSnapperType,
  params?: any
}

/** @deprecated */
export type QuerySnapShotWithParams = firebase.firestore.QuerySnapshot & { params?: any }

/** @deprecated */
export type DocMutation = (doc: firebase.firestore.DocumentSnapshot) => void
/** @deprecated */
export type QueryMutation = (doc: QuerySnapShotWithParams) => void

/** @deprecated */
export enum FirestoreSnapperType {
  Query = 'query',
  Document = 'document'
}

/** @deprecated */
export interface FirestoreDocSnapper extends FirestoreSnapper {
  doc: firebase.firestore.DocumentReference,
  mutation: DocMutation,
  readonly type: FirestoreSnapperType.Document
}

/** @deprecated */
export interface FirestoreQuerySnapper extends FirestoreSnapper {
  query: firebase.firestore.Query,
  mutation: QueryMutation,
  readonly type: FirestoreSnapperType.Query,
}

type ErrorCallback = ((error: any) => void) | ((error: any) => Promise<void>)
type UnsubscribeFunction = () => void

type HasSnapshot<T> = {
  onSnapshot: (callback: (snapshot: T) => void) => Result<UnsubscribeFunction, 'database'>
}

type DBSubscription<T> = {
  key: string,
  query: HasSnapshot<T>,
  callback: (data: T) => void,
  waitForIt?: boolean
}

export interface DatabaseSubscription<T> {
  count: number,
  update: (query: HasSnapshot<T>) => ResultVoid<'subscription'>
}

const createUpdateFunction = <T>(subscription: DBSubscription<T>, subscriptionContext: Subscription) => {
  return (query: HasSnapshot<T>): ResultVoid<'subscription'> => {
    if (subscriptionContext.destroy === null) {
      return Result.fail('subscription', 'Subscription was not properly created')
    }
    subscriptionContext.destroy()
    const result = query.onSnapshot((data) => {
      subscription.callback(data)
    })
    if (result.isFailure) return Result.fail('subscription', result.error)
    subscriptionContext.destroy = result.value
    return Result.ok()
  }
}

export class DatabaseQuerySubscriptions {
  subscriptions: KeyDict<Subscription>
  constructor () {
    this.subscriptions = {}
  }
  /** This function creates the link between the query and the callback and remembers how many subscriptions there are
   *  for this key. You can subscribe as much as you want to the same query, only a single snapshot will be created
   *  When the query is no longer needed, call unsubscribe. This will remove the 1 subscription of the snapshot
   *  If the reference counter becomes 0, the query is destroyed.
   */
  async subscribe<T> (subscription: DBSubscription<T>): Promise<Result<DatabaseSubscription<T>, 'subscription'>> {
    if (this.subscriptions[subscription.key] === undefined) {
      this.subscriptions[subscription.key] = {
        count: 0,
        destroy: null,
        snapper: null
      }
    }
    this.subscriptions[subscription.key].count += 1
    if (this.subscriptions[subscription.key].count === 1) {
      const mvar = new MVar<void>()
      const result = subscription.query.onSnapshot((data) => {
        subscription.callback(data)
        mvar.put()
      })
      if (result.isFailure) {
        return Result.fail('subscription', 'Could not subscribe to database')
      }
      if (subscription.waitForIt) {
        await mvar.read()
      }
      this.subscriptions[subscription.key].destroy = result.value
    }
    return Result.success({
      count: this.subscriptions[subscription.key].count,
      update: createUpdateFunction(subscription, this.subscriptions[subscription.key])
    })
  }

  /** unsubscribe on a query, the reference counter for key gets lowered by 1
   *  if the reference counter becomes 0, the query is unsubscribed and the snapshot is removed
   *  if the reference counter becomes 0, an optional cleanup function is called
   */
  unsubscribe (key: string, cleanup?: () => void) {
    if (!this.subscriptions[key]?.count) {
      console.warn(`You should not unsubscribe if you have not yet subscribed to ${key}`)
      return
    }
    this.subscriptions[key].count -= 1
    const count = this.subscriptions[key].count
    if (count === 0) {
      const destroyFunction = this.subscriptions[key].destroy
      if (destroyFunction !== null) {
        destroyFunction()
        if (cleanup) {
          cleanup()
        }
        delete this.subscriptions[key]
      } else {
        console.warn('Unsubscribe called while subscribe is waiting to complete. Key:', key)
      }
    }
    return { key, count }
  }
  /** @deprecated: try to use subscribe instread, we want to get rid of the dependency on firebase in here */
  // eslint-disable-next-line max-lines-per-function
  async subscribeFB (key: string, snapper: FirestoreSnapper,
                   errorCallBack?: ErrorCallback,
                   waitForIt: boolean = false) {
    if (this.subscriptions[key] === undefined) {
      this.subscriptions[key] = {
        count: 0,
        destroy: null,
        snapper: null
      }
    }
    // Note: Increase the count before we potentially await the `onSnapshot()` result. Othwerise if this method gets
    //       called multiple times, we might end up calling `onSnapshot()` multiple times causing previously assigned
    //       destroy functions to become "lost".
    this.subscriptions[key].count += 1
    if (this.subscriptions[key].count === 1) {
      const mvar = new MVar<void>()
      //TODO: don't branch on type.. (template FirestoreSnapper<Query|Doc>)
      if (snapper.type === FirestoreSnapperType.Query) {
        const snap = snapper as FirestoreQuerySnapper
        const localSnapper = snapper
        const start = (new Date()).getTime()
        this.subscriptions[key].destroy = snap.query.onSnapshot((data) => {
          this.subscriptions[key].lastQuerySize = data.size
          if ( this.subscriptions[key].firstQueryResponse === undefined) {
            this.subscriptions[key].firstQueryResponse = (new Date()).getTime() - start
          }
          const dataWithParams: QuerySnapShotWithParams = data
          dataWithParams.params = localSnapper.params
          snap.mutation(dataWithParams)
          if (waitForIt) mvar.put()
        }, (error) => {
          if (waitForIt) mvar.put()
          if (errorCallBack) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            errorCallBack(error)
          } else {
            console.error(`Error subscribing ${key} : ${error}`)
          }
        })
      } else if (snapper.type === FirestoreSnapperType.Document) {
        const snap = snapper as FirestoreDocSnapper
        this.subscriptions[key].destroy = snap.doc.onSnapshot((data) => {
          snap.mutation(data)
          mvar.put()
        }, (error) => {
          mvar.put()
          if (errorCallBack) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            errorCallBack(error)
          } else {
            console.error(`Error subscribing ${key} : ${error}`)
          }
        })
      }
      this.subscriptions[key].snapper = snapper
      if (waitForIt) {
        await mvar.read()
      }
    }
    return { key, count: this.subscriptions[key].count }
  }
  /** @deprecated: try to use unsubscribe instead */
  unsubscribeFB (key: string, mutation?: () => void) {
    if (!this.subscriptions[key]?.count) {
      console.warn(`You should not unsubscribe if you have not yet subscribed to ${key}`)
      return
    } else {
      this.subscriptions[key].count -= 1
      const count = this.subscriptions[key].count
      if (count === 0) {
        const destroyFunction = this.subscriptions[key].destroy
        if (destroyFunction !== null) {
          destroyFunction()
          // eslint-disable-next-line max-depth
          if (mutation) {
            mutation()
          }
          delete this.subscriptions[key]
        }
      }
      return { key, count }
    }
  }
  /** @deprecated: try updating a subscribing received by subscribe */
  updateQueryFB (key: string, query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
    const subscription = this.subscriptions[key]
    if (subscription === undefined) {
      throw new Error('Cannot update query of non-existing subscription')
    }
    if (subscription.snapper?.type !== FirestoreSnapperType.Query) {
      throw new Error('Can only update query subscriptions')
    }
    if (subscription.destroy === null) {
      throw new Error('Subscription was not properly created')
    }
    subscription.destroy()
    const snapper = subscription.snapper as FirestoreQuerySnapper
    subscription.destroy = query.onSnapshot((data) => {
      const dataWithParams: QuerySnapShotWithParams = data
      dataWithParams.params = subscription.snapper?.params
      snapper.mutation(data)
    }, (error) => {
      throw new Error(`Error subscribing ${key} : ${error}`)
    })
  }
  /** @deprecated: try updating a subscribing received by subscribe */
  updateDocQueryFB (key: string, query: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) {
    const subscription = this.subscriptions[key]
    if (subscription === undefined) {
      throw new Error('Cannot update query of non-existing subscription')
    }
    if (subscription.snapper?.type !== FirestoreSnapperType.Document) {
      throw new Error('Can only update query subscriptions')
    }
    if (subscription.destroy === null) {
      throw new Error('Subscription was not properly created')
    }
    subscription.destroy()
    const snapper = subscription.snapper as FirestoreDocSnapper
    subscription.destroy = query.onSnapshot((data) => {
      snapper.mutation(data)
    }, (error) => {
      throw new Error(`Error subscribing ${key} : ${error}`)
    })
  }
  unsubscribeAll () {
    Object.keys(this.subscriptions).forEach((subscription) => {
      console.log('Unsubscribing', subscription)
      this.unsubscribe(subscription)
    })
    this.subscriptions = {}
  }
  exists (key: string): boolean {
    return !!this.subscriptions[key] && this.subscriptions[key].count > 0
  }
  runningKeys (prefix: string = '') {
    return Object.keys(this.subscriptions).filter((key) => key.startsWith(prefix))
  }
}
