import { isObject } from 'lodash'
import { Loader } from 'kiswe-ui'
import { LOCAL_DEV_HOST } from '@/env'
import LinkPointerBase, { PointerTypes } from '../LinkPointerBase'

export default class EventLinkPointer extends LinkPointerBase {
  pointerType: PointerTypes.EVENT = PointerTypes.EVENT
  eventId: string = ''
  token: string = ''

  constructor (data: unknown) {
    super(data)
    if (!data || !isObject(data)) return
    this.eventId = Loader.loadString(data, 'eventId', this.eventId)
    this.token = Loader.loadString(data, 'token', this.token)
  }

  get targetUrl (): string {
    const path = `/event/${ this.eventId }?token=${ this.token }`
    if (location.hostname === LOCAL_DEV_HOST) {
      return window.location.origin + path
    } else {
      const version = window.location.pathname.split('/')[1] // pathname = /3.11.0/assets/...
      return window.location.origin + `/${version}${path}`
    }
  }

  get suffix (): string {
    return this.urlSuffix
  }
}
