import { Result, ResultVoid } from 'kiswe-ui'
import { AuthInterface, AuthUser, CreateUserParams } from '../authInterface'
import firebase from 'firebase/compat/app'


export class FirebaseAuthInterface extends AuthInterface {
  auth: firebase.auth.Auth

  constructor (auth: firebase.auth.Auth) {
    super()
    this.auth = auth
  }

  override async deleteUsers (userIds: string[]): Promise<ResultVoid<'auth' | 'unknown'>> {
    try {
      // @ts-ignore
      await this.auth.deleteUsers (userIds)
      return Result.ok()
    } catch (error) {
      return Result.fromUnknownError('auth', error)
    }
  }

  override async signInWithEmailAndPassword (email: string, password: string):
    Promise<Result<AuthUser, 'auth' | 'unknown'>> {
    try {
      const credentials = await this.auth.signInWithEmailAndPassword(email, password)
      if (credentials.user) {
        const user = AuthUser.load(credentials.user)
        if (user.isSuccess) return Result.success(user.value)
        return Result.fail('auth', 'invalid user')
      } else {
        return Result.fail('auth', 'Unable to sign in')
      }
    } catch (error) {
      return Result.fromUnknownError('auth', error)
    }
  }

  /* override async getUserByEmail (email: Email): Promise<Result<AuthUser, 'auth'|'unknown'>> {
    try {
      const user = await this.auth.getUserByEmail(email)
      if (user) {
        const authUser = AuthUser.load(user)
        if (authUser.isSuccess) return Result.success(authUser.value)
      }
      return Result.fail('auth', 'User not found')
    } catch (error) {
      return Result.fromUnknownError('auth', error)
    }
  }*/

  override async createUser (params: CreateUserParams): Promise<Result<AuthUser, 'auth'|'unknown'>> {
    try {
      const result = await this.auth.createUserWithEmailAndPassword(params.email, params.password)
      const authUser = AuthUser.load(result.user)
      return authUser.isSuccess ? Result.success(authUser.value) : Result.fail('auth', 'Unable to create user')
    } catch (error) {
      return Result.fromUnknownError('auth', error)
    }
  }
}
