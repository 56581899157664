<template>
  <UserProfileFormModal
    v-if="showProfile && editProfile !== null"
    :userId="editProfile.user_id"
    title="Profile"
    @close="toggleProfile(false)"
  />
</template>

<script lang="ts">
import './UserProfileContainer.scss'
import { defineComponent, computed, ref, onMounted, watch } from 'vue'
import { isMobile } from 'mobile-device-detect'
import store from '@/store'
import { UserProfileFormModal } from '@/modules/user/components'
import { UserProfile } from '@/types/users'

export default defineComponent({
  name: 'UserProfileContainer',
  setup () {
    const editProfile = ref<UserProfile|null>(null)
    const profile = computed(() => store.state.user.profile)
    const showProfile = computed(() => store.state.user.showProfile)
    const inCast = computed(() => store.state.events.inCast)

    onMounted(() => {
      editProfile.value = profile.value?.clone() ?? null
    })

    watch(profile, (newProfile) => {
      if (!newProfile) return
      editProfile.value = newProfile.clone()
    })

    return {
      editProfile,
      inCast,
      isMobile,
      profile,
      showProfile
    }
  },
  methods: {
    async updateProfile () {
      try {
        if (this.editProfile !== null) {
          await this.$store.direct.dispatch.user.updateProfile(this.editProfile)
        }
      } catch {
        this.$message({ type: 'error', message: 'Could not update profile' })
      }
      await this.toggleProfile(false)
    },
    async toggleProfile (payload: boolean): Promise<void> {
      return this.$store.direct.dispatch.user.toggleProfileModal(payload)
    }
  },
  components: {
    UserProfileFormModal
  }
})
</script>
