import { isCastTalkbackTarget } from '@/helpers/talkbackHelpers'
import store from '@/store'
import { TalkbackTargetType } from '@/types/streams'
import { computed } from 'vue'

const useTalkback = () => {
  const talkbackTarget = computed(() => store.state.talkback.target)
  const isTalking = computed(() => talkbackTarget.value !== TalkbackTargetType.NONE)
  const isCrewTalkBackActive = computed(() => talkbackTarget.value === TalkbackTargetType.CREW)
  const isAllTalkBackActive = computed(() => talkbackTarget.value === TalkbackTargetType.ALL)
  const isCastTalkbackActive = (castId: string|null) => !!castId && isCastTalkbackTarget(talkbackTarget.value, castId)

  return {
    isTalking,
    talkbackTarget,
    isCrewTalkBackActive,
    isAllTalkBackActive,
    isCastTalkbackActive
  }
}

export default useTalkback
