import moment from 'moment'
import { padStartZero } from '@/modules/common/utils'

// NOTE: this class currently assumes that a unix timestamp is in seconds.

export default class KisweDate {

  static formatOffset (timestamp: number, format: string): string {
    return moment.utc(timestamp*1000).format(format)
  }

  static getTimeOffset (timestamp: number): string {
    return this.formatOffset(timestamp, 'hh:mm a')
  }

  static format (timestamp: number, format: string): string {
    return moment.unix(timestamp).format(format)
  }

  static formatNow (format: string): string {
    return this.format(this.now(), format)
  }

  static formatDate (date: Date, format: string): string {
    return moment.unix(date.getTime()/1000).format(format)
  }

  static toISOString (date: Date): string {
    return date.toISOString()
  }

  static getTime (timestamp: number): string {
    return this.format(timestamp, 'hh:mm a')
  }

  static getFullDateTime (timestamp: number): string {
    return this.format(timestamp, 'dddd, MMMM Do YYYY, hh:mm:ss a')
  }

  static getMonthName (month: number, format = 'MMMM'): string {
    return moment().month(month - 1).format(format)
  }

  static secondsToTime (secondsValue: number) {
    const days = Math.floor(secondsValue / (24 * 3600))
    const hours = Math.floor(secondsValue / 3600)
    const minutes = Math.floor((secondsValue / 60 - hours * 60))
    const seconds = Math.floor(secondsValue - (hours * 3600 + minutes * 60))
    return { days, hours, minutes, seconds }
  }

  static secondsToString (secondsValue: number) {
    const { days, hours, minutes, seconds } = this.secondsToTime(secondsValue)
    return {
      days: padStartZero(days),
      hours: padStartZero(hours),
      minutes: padStartZero(minutes),
      seconds: padStartZero(seconds)
    }
  }

  static minutesToTime (minutesValue: number) {
    const hours = Math.floor(minutesValue / 60)
    const minutes = Math.floor(minutesValue - hours * 60)
    return { hours, minutes }
  }

  static now (): number {
    return Math.round(Date.now() / 1000)
  }

  static yearsLater (years: number): Date {
    return moment().add(years, 'years').toDate()
  }

  static yearsAgo (years: number): Date {
    return moment().subtract(years, 'years').toDate()
  }

  static monthsLater (months: number): Date {
    return moment().add(months, 'months').toDate()
  }

  static monthsAgo (months: number): Date {
    return moment().subtract(months, 'months').toDate()
  }

  static daysLater (days: number): Date {
    return moment().add(days, 'days').toDate()
  }

  static fromDaysLater (from: Date, days: number) {
    return moment(from).add(days, 'days').toDate()
  }

  static daysAgo (days: number): Date {
    return moment().subtract(days, 'days').toDate()
  }

  static fromDaysAgo (from: Date, days: number) {
    return moment(from).subtract(days, 'days').toDate()
  }

  static hoursLater (hours: number): Date {
    return moment().add(hours, 'hours').toDate()
  }

  static fromHoursLater (from: Date, hours: number) {
    return moment(from).add(hours, 'hours').toDate()
  }

  static fromHoursAgo (from: Date, hours: number) {
    return moment(from).subtract(hours, 'hours').toDate()
  }

  static hoursAgo (hours: number): Date {
    return moment().subtract(hours, 'hours').toDate()
  }

  static fromMinutesAgo (from: Date, minute: number) {
    return moment(from).subtract(minute, 'minute').toDate()
  }

  static fromMinutesLater (from: Date, minute: number) {
    return moment(from).add(minute, 'minute').toDate()
  }

  static isBefore (date: Date, dateToCompare: Date, unit?: moment.unitOfTime.StartOf): boolean {
    return moment(date).isBefore(dateToCompare, unit)
  }

  static isAfter (date: Date, dateToCompare: Date, unit?: moment.unitOfTime.StartOf): boolean {
    return moment(date).isAfter(dateToCompare, unit)
  }

  static isBetween (date: Date, startDate: Date, endDate: Date, unit?: moment.unitOfTime.StartOf): boolean {
    return moment(date).isBetween(startDate, endDate, unit)
  }

  static isNowBetween (startDate: Date, endDate: Date, unit?: moment.unitOfTime.StartOf): boolean {
    return moment().isBetween(startDate, endDate, unit)
  }

  static isEqual (date: Date, dateToCompare: Date, unit?: moment.unitOfTime.StartOf): boolean {
    return moment(date).isSame(dateToCompare, unit)
  }

  static isEqualOrAfter (date: Date, dateToCompare: Date, unit?: moment.unitOfTime.StartOf): boolean {
    return moment(date).isSameOrAfter(dateToCompare, unit)
  }

  static inSeconds (date: Date): number {
    return date.getTime() / 1000
  }

  static diff (lhsDate: Date, rhsDate: Date) {
    return moment(lhsDate).diff(moment(rhsDate))
  }

  static diffSeconds (lhsDate: Date, rhsDate: Date) {
    return moment(lhsDate).diff(moment(rhsDate), 'seconds', true)
  }

  static diffMinutes (lhsDate: Date, rhsDate: Date) {
    return moment(lhsDate).diff(moment(rhsDate), 'minutes', true)
  }

  static diffHours (lhsDate: Date, rhsDate: Date) {
    return moment(lhsDate).diff(moment(rhsDate), 'hours', true)
  }

  static diffDays (lhsDate: Date, rhsDate: Date) {
    return moment(lhsDate).diff(moment(rhsDate), 'days', true)
  }

  static duration (lhsDate: Date, rhsDate: Date) {
    return moment.duration(moment(lhsDate).diff(moment(rhsDate)))
  }
}
