import { CastType } from '@/types/casts'
import { Constructor, hasStudioBase, StudioBaseMixin } from './mixins/base'
import { hasCastType } from './mixins/hasCastType'
import { hasDelete } from './mixins/hasDelete'
import { constructsDurationMixin, hasDuration } from './mixins/hasDuration'
import { hasInputs } from './mixins/hasInputs'
import { hasMarkers } from './mixins/hasMarkers'
import { hasArchive } from './mixins/hasArchive'
import { hasMixer } from './mixins/hasMixer'
import { hasName } from './mixins/hasName'
import { constructsQualityMixin, hasQuality } from './mixins/hasQuality'
import { constructsScenesMixin, hasScenes, MixinScenesProps } from './mixins/hasScenes'
import { constructsStreamsMixin, hasStreams } from './mixins/hasStreams'
import { hasTeam } from './mixins/hasTeam'
import { hasVersion, VersionNumber } from './mixins/hasVersion'
import { hasLoader } from './mixins/loader'
import { hasChat } from './mixins/hasChat'
import { hasCasters } from './mixins/hasCasters'
import { hasOnlineSessions } from './mixins/hasOnlineSessions'
import { constructsRegionMixin, hasRegion } from './mixins/hasRegion'
import { hasPresets, HasPresetsProps } from './mixins/hasPresets'
import { hasCustomOutputs } from './mixins/hasCustomOutputs'
import { hasNoCustomOutputs } from './mixins/hasNoCustomOutputs'
import { hasMutes } from './mixins/hasMutes'
import { hasAudioTracks } from './mixins/hasAudioTracks'
import { hasOverrides } from './mixins/hasOverrides'
import { hasScheduledDate } from './mixins/hasScheduledDate'

// eslint-disable-next-line max-lines-per-function
const createOptions = <T extends StudioBaseMixin>(baseClass: T) => {
  return {
    addName: (params: { required?: boolean } = {}) => {
      return createOptions(hasName(baseClass, params))
    },
    addTeam: (params: { required?: boolean } = {}) => {
      return createOptions(hasTeam(baseClass, params))
    },
    isDeletable: () => {
      return createOptions(hasDelete(baseClass))
    },
    addQuality: () => {
      return createOptions(hasQuality(baseClass))
    },
    addDuration: (params: { required?: boolean } = {}) => {
      return createOptions(hasDuration(baseClass, params))
    },
    addChat: () => {
      return createOptions(hasChat(baseClass))
    },
    addVersion: (params: { version?: VersionNumber } = {}) => {
      return createOptions(hasVersion(baseClass, params))
    },
    addCasters: () => {
      return createOptions(hasCasters(baseClass))
    },
    addOnlineSessions: () => {
      return createOptions(hasOnlineSessions(baseClass))
    },
    addCustomOutputs: () => {
      if (!constructsQualityMixin(baseClass)) {
        throw new Error('Outputs requires HasQuality as mixin')
      }
      return createOptions(hasCustomOutputs(baseClass))
    },
    disallowCustomOutputs: () => {
      return createOptions(hasNoCustomOutputs(baseClass))
    },
    addScenes: (props?: MixinScenesProps) => {
      return createOptions(hasScenes(baseClass, props))
    },
    addCastType: <C extends CastType>(castType: C) => {
      return createOptions(hasCastType(baseClass, castType))
    },
    addMarkers: () => {
      return createOptions(hasMarkers(baseClass))
    },
    addMutes: (props: { useCastLevelMutes: boolean }) => {
      // FIXME: Is there a cleaner/better way to solve "Type instantiation is excessively deep and possibly infinite"?
      if (!constructsScenesMixin(baseClass) || !constructsStreamsMixin(baseClass)) {
        throw new Error('Mutes requires both HasScenes and HasStreams mixins')
      }
      // FIXME -> split this up
      return createOptions(hasMutes(baseClass, props))
    },
    addInputs: () => {
      if (!constructsScenesMixin(baseClass) || !constructsStreamsMixin(baseClass)) {
        throw new Error('Inputs requires HasScenes and HasStreams mixins')
      }
      return createOptions(hasInputs(baseClass))
    },
    addPresets: (params: Partial<HasPresetsProps> = {}) => {
      if (!constructsRegionMixin(baseClass) || !constructsQualityMixin(baseClass)) {
        throw new Error('Inputs requires HasRegions and HasQuality and mixins')
      }
      return createOptions(hasPresets(baseClass, params))
    },
    addRegion: () => {
      return createOptions(hasRegion(baseClass))
    },
    addStreams: () => {
      return createOptions(hasStreams(baseClass))
    },
    addAudioTracks: () => {
      return createOptions(hasAudioTracks(baseClass))
    },
    addMixer: <C extends boolean>(physicalMixer: C) => {
      if (!constructsDurationMixin(baseClass) || !constructsQualityMixin(baseClass)) {
        throw new Error('Inputs requires HasDuration mixins')
      }
      return createOptions(hasArchive(hasMixer(baseClass, physicalMixer)))
    },
    addOverrides: () => {
      return createOptions(hasOverrides(baseClass))
    },
    addScheduledDate: () => {
      return createOptions(hasScheduledDate(baseClass))
    },
    get () {
      return hasLoader(baseClass)
    }
  } as const
}

export const createClass = <T extends Constructor>(base: T) => {
  const studioBase = hasStudioBase(base)
  return createOptions(studioBase)
}
