import { Loader, Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { OverrideEntity } from '../types'

export class Overrides {
  streams: KeyDict<Partial<StreamOverrides>>
  users: KeyDict<Partial<UserOverrides>>

  constructor (data: unknown = {}) {
    this.streams = Loader.loadKeyDictOrEmpty(data, OverrideEntity.Streams,
      (data: unknown) => new StreamOverrides(data), {})
    this.users = Loader.loadKeyDictOrEmpty(data, OverrideEntity.Users, (data: unknown) => new UserOverrides(data), {})
  }

  static load (snapData: unknown): Result<Overrides, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new Overrides(snapData))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}

class StreamOverrides {
  public displayName: string|null

  constructor (data: unknown = {}) {
    this.displayName = Loader.loadStringOrNull(data, 'displayName', null)
  }
}

class UserOverrides {
  public displayName: string|null

  constructor (data: unknown = {}) {
    this.displayName = Loader.loadStringOrNull(data, 'displayName', null)
  }
}
