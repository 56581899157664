import { SystemStatus } from './ops'
import { KeyDict } from '.'
import { MixerTrackLabel } from '../modules/audio/types'
import { H264Profile } from '@/classes/StreamDocClass'
import { PreviewVideoQualityType } from './users'


export enum StreamType {
  Broadcast = 'broadcast',
  Caster = 'caster',
  Cef = 'Cef',
  Moderator = 'moderator',
  NonCaster = 'noncaster',
  Output = 'output',
  Playlist = 'playlist',
  RemoteTalkback = 'remotetalkback',
  Replay = 'replay',
  ScreenShare = 'screenshare',
  StationOutput = 'stationoutput',
  Stream = 'stream',
  Url = 'url'
}

export type UserStreamType = StreamType.Caster | StreamType.NonCaster | StreamType.Moderator

export enum StreamProtocol {
  Srt = 'srt',
  Rtmp = 'rtmp'
}

export enum StreamStatusCode {
  StreamGood = 'stream-good',
  StreamWarning = 'stream-warning',
  StreamBooting = 'stream-booting',
  StreamError = 'stream-error',
  SRTError = 'srt-error',
  StreamNotConnected = 'stream-not-connected',
  OutputGood = 'output-good',
  OutputNotConnected = 'output-not-connected',
  OutputLowBandwidth = 'output-low-bandwidth',
  CasterPresent = 'caster-present',
  CasterNotPresent = 'caster-not-present',
  CasterLowFPS = 'caster-low-fps',
  CasterConnecting = 'caster-connecting',
  CasterCastingIssues = 'caster-casting-issues',
  CasterNotCastingBadNetwork = 'caster-not-casting-bad-network',
  CasterError = 'caster-error',
  CasterErrorNotPresent = 'caster-error-not-present',
  CrewPresent = 'crew-present',
  CrewConnected = 'crew-connected',
  CrewLowBandwidth = 'crew-low-bandwidth',
  CrewNotPresent = 'crew-not-present',
  CrewError = 'crew-error',
  CasterCasting = 'caster-casting',
  None = ''
}

export const TRACK_PREFIX_CASTER = 'CasterTrack'
export const TRACK_PREFIX_PASSTHROUGH = 'Passthrough'

// Note: Keep in sync with same mapping in the backend's cecutil.py
export const LABEL_TO_MIXER_TRACK_MAP = {
  'Mixer': 1,
  'Nats': 1,
  'Broadcast': 1,
  'Passthrough1': 14,
  'Passthrough2': 15,
  'Passthrough3': 16,
  'CasterTrack1': 1,
  'CasterTrack2': 2,
  'CasterTrack3': 3,
  'CasterTrack4': 4,
  'CasterTrack5': 5,
  'CasterTrack6': 6,
  'CasterTrack7': 7,
  'CasterTrack8': 8,
  'CasterTrack9': 9,
  'CasterTrack10': 10,
  'CasterTrack11': 11,
  'CasterTrack12': 12,
  'CasterTrack13': 13
} as const

export type IPv4Address = `${number}.${number}.${number}.${number}`

export enum SrtServerType {
  NIMBLE = 'nimble',
  HAIVISION = 'haivision'
}

export enum SrtServerMode {
  Listen = 'listen',
  Pull = 'pull',
  Rendezvous = 'rendezvous'
}

export interface SrtInfo {
  nimbleserver: string,
  ip : IPv4Address,
  port: number,
  mode: 'pull' | 'listen' | 'rendezvous'
}

export type TrackPid = number|'auto'

export type MutedProp = 'muted'|'leftMuted'|'rightMuted'

export enum AudioTrackType {
  DEFAULT = 'default',
  REMOTE_TALKBACK = 'remotetalkback'
}

export const isMutedProp = (muted: string): muted is MutedProp => {
  return muted === 'muted' || muted === 'leftMuted' || muted === 'rightMuted'
}

export enum OutputStreamId {
  RtspPreview = 'rtsppreview',
  RtmpPreview = 'rtmppreview',
  RtspPreviewLow = 'rtsppreviewlow',
  RtmpPreviewLow = 'rtmppreviewlow',
  RtspLowRes = 'rtsplowres',
  RtmpLowRes = 'rtmplowres',
  RtspHighRes = 'rtsphighres'
}

export interface AudioTrack {
  label: MixerTrackLabel,
  pid: TrackPid,
  trackId?: number,
  type?: AudioTrackType
}

export enum TrackType {
  SCTE = 'SCTE',
  SMPTE = 'SMPTE'
}

interface TrackBase {
  label: string,
  pid: number,
  type: TrackType
}

export interface TrackScte extends TrackBase {
  type: TrackType.SCTE
}

export interface TrackSmpte extends TrackBase {
  type: TrackType.SMPTE
}

export type Track = TrackScte | TrackSmpte

export const MIN_PID = 64
export const MAX_PID = 8000

export interface SrtPMTSPids {
  pid?: number,
  t?: number
}

export interface SrtPMTS {
  id?: number,
  pids?: SrtPMTSPids[]
}

export interface SrtMetric {
  link?: {
    rtt: string
  },
  recv?: {
    mbpsRate: string
  },
  send?: {
    mbpsRate: string
  },
  pmts?: SrtPMTS[],
  time?: number
}

export interface Stream {
  mixer_params?: string
  id?: string,
  active?: boolean,
  audioOnly?: boolean,
  delay?: number,
  persistent?: boolean,
  connected?: boolean,
  deleted?: boolean,
  pubts?: number,
  srcurl: string,
  team?: string,
  team_id?: string,
  type: StreamType,
  url?: string,
  stream?: string,
  user?: string,
  user_id?: string,
  name: string,
  templateId?: string,
  quality?: {
    framerate?: string,
    resolution?: string
  }
  reencode?: boolean,
  volume?: number,
  volumeLeft?: number,
  volumeRight?: number,
  ngcvp_status?: SystemStatus,
  streamAvailable?: boolean,
  leftrightsplit?: boolean,
  streamname?: string,
  framerate?: string,
  resolution?: string, // TODO make into a type
  rate?: StreamRate,
  variants?: { [ id: string ]: StreamVariant },
  feedName?: string,
  feedNames?: string[],
  start_time?: number,
  srt_state?: string,
  srt_state_override?: string,
  srt_info?: SrtInfo,
  srt_msg?: string,
  srt_metrics? : SrtMetric,
  interlaced?: boolean,
  gop_size?: number,
  auth_info?: AuthInfo,
  protocol?: StreamProtocol,
  audioTracks?: AudioTrack[],
  tracks?: Track[],
  ingest_region?: string,
  nimble_configured?: boolean,
  nimble_config_time?: number,
  nimble_configuration_error?: string,
  nimble_server?: string,
  nimble_server_old?: string,
  nimble_params?: string,
  nimble_stream_url?: string,
  noCleanup?: boolean,
  videopid?: number,
  provider?: string,
  passthrough?: boolean
}

export interface AuthInfo {
    type: string,
    ingest_url: string,
    error?: string
}

export interface StreamStatus {
  ngcvp_status: SystemStatus,
  stream: string
}

// ! TODO: move some more elements to other types and get rid of ?
interface SourceStreamBase {
  audioOnly?: boolean
  type: StreamType,
  id?: string,
  leftMuted?: boolean,
  rightMuted?: boolean,
  muted?: boolean,
  volume?: number,
  volumeLeft?: number,
  volumeRight?: number,
  active?: boolean,
  connected?: boolean,
  cef_feed_id?: string,
  name?: string,
  ngcvp_status?: SystemStatus,
  // FIXME: Remove this from the Base, as this prop only seems to actually be present on user streams and the playlist.
  //        But completely removing it, causes a fair amount of TS complaints that would have to be fixed.
  start_time?: number,
  delay?: number,
  srt_state?: string
}

export interface SourceStreamCaster extends SourceStreamBase {
  type: StreamType.Caster,
  user_id: string,
  session_id: string,
  start_time: number
}

export interface SourceStreamNonCaster extends SourceStreamBase {
  type: StreamType.NonCaster,
  user_id?: string,
  session_id?: string,
  start_time: number
}

export interface SourceStreamModerator extends SourceStreamBase {
  type: StreamType.Moderator,
  user_id?: string,
  session_id?: string,
  start_time: number
}

export interface SourceStreamPlaylist extends SourceStreamBase {
  type: StreamType.Playlist,
  start_time: number,
  delete?: boolean
}

export interface SourceStreamBroadCast extends SourceStreamBase {
  type: StreamType.Broadcast
}

export interface SourceStreamRemoteTalkBack extends SourceStreamBase {
  type: StreamType.RemoteTalkback,
  start_time: number
}

export interface SourceStreamUrl extends SourceStreamBase {
  type: StreamType.Url
}

export interface SourceStreamStream extends SourceStreamBase {
  type: StreamType.Stream
}

export interface SourceStreamScreenshare extends SourceStreamBase {
  type: StreamType.ScreenShare
  user_id?: string,
  session_id?: string
}

export interface SourceStreamOutput extends SourceStreamBase {
  type: StreamType.Output
}

export interface SourceStreamReplay extends SourceStreamBase {
  type: StreamType.Replay
}

export interface SourceStreamCef extends SourceStreamBase {
  type: StreamType.Cef,
  castId: string
}

export type SourceStream =  SourceStreamCaster |
                            SourceStreamBroadCast |
                            SourceStreamCef |
                            SourceStreamModerator |
                            SourceStreamNonCaster |
                            SourceStreamOutput |
                            SourceStreamPlaylist |
                            SourceStreamRemoteTalkBack |
                            SourceStreamReplay |
                            SourceStreamScreenshare |
                            SourceStreamStream |
                            SourceStreamUrl


export type SourceStreamWithCast = SourceStream & { castId: string|null }

export interface SourceStreamKeyed {
  id: string,
  stream: SourceStream
}

export type StreamVariantType = 'original' | 'lowratenosrt' | 'srt' | 'lowrate' |
                                'lowratecaster' | 'highratenosrt' | 'highrate'

export enum StreamQuality {
  LOW = 'low',
  HIGH = 'high'
}

export interface StreamVariant {
  audioinfo?: {
    channels?: number,
    codec?: string,
    frequency?: number,
    objectType?: string,
    samplesPerFrame?: number
  }
  hasaudio: boolean,
  hasvideo: boolean,
  mongoid: string,
  pubtime: number,
  server: string,
  type?: StreamVariantType,
  url: string,
  videoinfo?: {
    codec?: string,
    displaySize?: string,
    frameRate?: number,
    frameSize?: string,
    level?: number,
    profile?: string
  }
}


export enum TalkbackTargetType {
  ALL = 'ALL',
  CREW = 'CREW',
  NONE = 'NONE'
}

export type PossibleTalkbackTargets = string | TalkbackTargetType.ALL | TalkbackTargetType.CREW | `cast/${string}`

export interface CastStream {
  id: string,
  srcObject: MediaStream | null,
  type: StreamType,
  leftrightsplit: boolean
}

interface StreamDocBase {
  id?: string,
  active: boolean,
  audioOnly?: boolean,
  audioTracks?: AudioTrack[],
  srcUrlChina?: string,
  deleted: boolean,
  leftrightsplit?: boolean,
  name: string,
  noCleanup?: boolean,
  persistent: boolean,
  pubts?: number,
  srcurl: string,
  team: string,
  team_id: string,
  type: StreamType,
  talkbackTarget?: string, // specific caster id or NONE
  user?: string,
  variants: KeyDict<StreamVariant> // FIXME: Make optional, as it can be missing on a stream doc (e.g. social output)
  protocol?: StreamProtocol
}

export interface StreamDocRtmpIn extends StreamDocBase {
  protocol?: StreamProtocol.Rtmp,
  type: StreamType.Broadcast,
  auth_info?: AuthInfo,
  delay?: number, // Optional delay that will be configured on the corresponding SourceStream, and then ingest sink.
  leftrightsplit: boolean
  variants: KeyDict<StreamVariant>
}

// TODO: Ad hoc outputs only have a limited set of properties: active, castid, persistent, srcurl, team_id & type
export interface StreamDocRtmpOut extends StreamDocBase {
  protocol?: StreamProtocol.Rtmp,
  type: StreamType.Output,
  framerate: string,
  rate?: StreamRate,
  reencode: boolean,
  resolution: string
}

interface StreamDocSrtBase extends StreamDocBase {
  audioTracks: AudioTrack[],
  tracks: Track[],
  ingest_region: string,
  nimble_configuration_error: string,
  nimble_configured: boolean,
  nimble_config_time?: number,
  nimble_params: string,
  nimble_server: string,
  nimble_stream_url: string,
  srt_info: SrtInfo,
  srt_metrics: SrtMetric,
  srt_msg: string,
  srt_state: string,
  videopid: number
}

export interface StreamDocSrtIn extends StreamDocSrtBase {
  delay?: number, // Optional delay that will be configured on the corresponding SourceStream, and then ingest sink.
  protocol: StreamProtocol.Srt,
  type: StreamType.Broadcast,
  leftrightsplit: boolean
  variants: KeyDict<StreamVariant>
}

export interface StreamDocSrtOut extends StreamDocSrtBase {
  protocol: StreamProtocol.Srt,
  type: StreamType.Output
  framerate: string,
  rate?: StreamRate,
  reencode: boolean,
  resolution: string
}

export interface StreamDocPlaylist extends StreamDocBase {
  type: StreamType.Playlist
  castid: string
}

// TODO: other stream types: Caster, Moderator, NonCaster, Playlist, ScreenShare, Stream, Url
export type StreamDoc = StreamDocBase | StreamDocRtmpIn | StreamDocRtmpOut | StreamDocSrtIn | StreamDocSrtOut
                        | StreamDocPlaylist

export type StreamChannelInfo = Pick<StreamDoc, 'name'|'type'|'user'|'leftrightsplit'>

export interface CustomRtmpDestination {
  active?: boolean,
  backup?: boolean,
  gop_size?: number,
  rate?: StreamRate,
  url: string,
  socialmedia?: string,
  start_time: number,
  streamname: string,
  type: StreamType,
  valid: boolean
}

export interface StreamRate {
  fps?: string,
  width?: number,
  height?: number,
  video_bitrate?: number,
  adjusted?: boolean,
  interlaced?: boolean,
  gop_size?: number,
  profile?: 'main'|'high'|H264Profile,
  mpegts_cbr?: boolean,
  audio_codec?: 'aac'|'ac3'
}

export interface StreamsManager {
  [ streamKey: string ]: Stream
}

export class DefaultStream {
  name: string = ''
  url: string = ''
  description: string = ''
  leftrightsplit: boolean = false
  add_to_new_teams: boolean = false
  templateId: string = ''

  constructor (data: KeyDict<any>) {
    this.name = data.name ?? ''
    this.url = data.url ?? ''
    this.description = data.description ?? ''
    this.leftrightsplit = data.leftrightsplit ?? false
    this.add_to_new_teams = data.add_to_new_teams ?? false
    this.templateId = data.templateId ?? ''
  }

  toStream (): Stream {
    const data: Stream = {
      deleted: false,
      srcurl: this.url,
      type: StreamType.Broadcast,
      name: this.name,
      leftrightsplit: this.leftrightsplit,
    }
    if (this.templateId) data.templateId = this.templateId
    return data
  }
}

export interface OutputSourceStream {
  streamid: string,
  streamidurl: string,
  streamname: string,
  streamkey: string,
  streamurl: string,
  status: string,
  type: StreamType,
  srcurl: string,
  stream: Stream,
  casterid?: string,
  casteridurl?: string,
  casterfirstname?: string,
  casterlastname?: string,
  casterregion?: string,
}

export interface StreamManagerStreamInfo {
  local: boolean,
  quality: PreviewVideoQualityType,
  remoteTalkbackName?: string,
  streamId: string,
  type?: StreamType
}
