import { StringTranslation } from "../types"

const translation: StringTranslation = {
  "cast_letsgetstarted_text": "始めましょう。",
  "cast_getstarted_button": "始めます。",
  "cast_loadingcast_text": "イベントローディング中",
  "cast_pleasefillinname_question": "セクション参加前にあなたに関する詳しい情報をご記入してください。",
  "cast_consentterms_text": "私は、Athlete Momentの<a href=\"{tosUrl}\" target=\"_blank\">利用規約とプライバシーポリシー</a>を確認して同意し、自分の画像、肖像、声、及び投稿の全体または一部を、五輪競技場だけでなく五輪大会及びその中継放送でOBS、IOC、及びそれらのサブライセンシーや譲受人(認定放送局など)が配布及び使用することに同意します。",
  "cast_consentterms_error": "約款に同意する必要があります。",
  "cast_consentage_text": "本人は<span>{age}</span>歳であり、適用法の下で法定年齢に達しています。",
  "cast_consentage_error": "せめて{age}歳以上でなければなりません。",
  "cast_is_scheduled_text": "This production is not live and the video previews are disabled",
  "cast_enter_text": "Press to enter the production",
  "cast_enter_button": "入場",
  "cast_tryingtoconnect_text": "Trying to connect. 現在",
  "cast_waitingforcast_title": "記憶に残る瞬間を作って下さい",
  "cast_waitingforcast_text": "次のモデレーターが対応可能になるまでお待ちください。これには時間がかかる場合があります。ページを更新しないでください。",
  "cast_waitingforcast_guide1_title": "適切な位置を選定して下さい。",
  "cast_waitingforcast_guide2_title": "熱意を伝えよう！",
  "cast_waitingforcast_guide3_title": "身だしなみと行動に注意して下さい。",
  "cast_waitingforcast_guide1_text": "画面の中央に座り、良い姿勢を保ちながらご自分の位置を確認して下さい。世界が見ていることをお忘れなく！",
  "cast_waitingforcast_guide2_text": "あなたの参加と熱意が伝わるよう、大きな声援を送って下さい。",
  "cast_waitingforcast_guide3_text": "礼儀を守るため、目立つブランド名や政治的発言、侮辱的な表現は避けて下さい。",
  "cast_waitingforcastautoaccept_text": "モデレーターと会う準備ができました。",
  "cast_switch_team_text": "This cast requires you to switch to a different team",
  "cast_switch_team_button": "Switch team",
  "complete_join_text": "アスリートの瞬間に登録する",
  "general_pleasewait_text": "しばらくお待ちください。",
  "general_yourname_label": "あなたのお名前",
  "general_firstname_label": "名",
  "general_lastname_label": "姓",
  "general_email_label": "メールアドレス",
  "general_country_label": "国",
  "general_firstname_error": "お名前をご記入ください。",
  "general_lastname_error": "苗字をご記入ください。",
  "general_requiredallfields_error": "全てご記入下さい。",
  "general_pleasefillinyourname_placeholder": "フルネームをご記入ください。",
  "general_pleasefillinfirstname_placeholder": "お名前をご記入ください。",
  "general_pleasefillinlastname_placeholder": "苗字をご記入ください。",
  "general_pleasefillinemail_placeholder": "メールアドレスをご記入ください。",
  "general_join_button": "加入",
  "general_send_button": "発送",
  "general_yes_button": "はい",
  "general_no_button": "いいえ",
  "general_ok_button": "はい",
  "general_close_button": "閉じる",
  "general_done_button": "完了",
  "general_register_button": "登録する",
  "general_restart_button": "再起動する",
  "general_continue_button": "続ける",
  "chat_nomessages_placeholder": "このキャストについてはまだメッセージがありません。",
  "chat_enteryourmessage_placeholder": "あなたのメッセージをここにご入力ください。",
  "chat_chat_label": "チャット",
  "chat_unreadmessage_text": "{count} 新しいメッセージ",
  "chat_unreadmessages_text": "{count} 新しいメッセージ（複数あり）",
  "redirect_welcometokiswe_text": "ようこそ",
  "redirect_pleasewaittoredirect_text": "接続中です。しばらくお待ちください。",
  "redirect_end_cast_text": "モデレーションは、選手の競技開始一時間前に行います。後ほどお越し下さい。",
  "redirect_cast_reach_maximum_text": "現在、最大参加人数を超えています。空きが出るまでお待ち下さい。",
  "redirect_disabled_existing_caster_text": "問題が発生しました。このブラウザ タブを閉じるか、ブラウザを完全に閉じて元のリンクをもう一度クリックしてください。",
  "redirect_invalidlink_title": "無効なリンクです。",
  "redirect_invalidlink_text": "ご利用になぅたリンクはもう有効ではありません。",
  "redirect_castnotactive_title": "このリンクに接続された有効なルームがありません。",
  "redirect_castnotactive_paragraph": "しばらく経ってから再接続するか、他のリンクをご利用下さい。",
  "actiontab_assets_label": "Assets",
  "actiontab_casters_label": "Casters",
  "actiontab_inputs_label": "Inputs",
  "actiontab_outputs_label": "Output",
  "actiontab_audio_label": "Audio Mixer",
  "actiontab_settings_label": "Settings",
  "actiontab_clips_label": "Clips",
  "actiontab_commentators_label": "Tracks",
  "actiontab_crew_label": "Crew",
  "actiontab_talkback_label": "Talkback",
  "actiontab_chat_label": "TextCast",
  "actiontab_simplecg_label": "Simple CG",
  "actiontab_sctemarkers_label": "SCTE Markers",
  "actiontab_interactive_label": "インタラクティブ",
  "actiontab_switcher_label": "Scenes",
  "actiontab_layout_label": "Layers",
  "actiontab_preset_label": "Preset",
  "topbar_untileventstarts_text": "イベントが始まるまで",
  "topbar_untileventends_text": "イベントが終わるまで",
  "usermedia_startcasting_button": "キャスティングスタート",
  "usermedia_connect_button": "接続",
  "usermedia_detectdevices_button": "機器検索",
  "usermedia_detecting_text": "検索中",
  "usermedia_clickbutton_tooltip": "キャスティングを始める場合は「キャストボタン」をクリックしてください。",
  "usermedia_echocancellation_text": "エコキャンセルを活性化します。",
  "usermedia_camera_label": "カメラ",
  "usermedia_microphone_label": "マイク",
  "usermedia_learnaccess_button": "アクセス方法",
  "usermedia_pressallow_text": "ポップアップで「許可」を押します",
  "castgrawl_mutedbycrew_label": "The crew muted you",
  "castgrawl_mutedyourself_label": "You are muted",
  "castgrawl_streamhasissues_label": "There are connection issues. Refresh the page to solve it",
  "audio_copyfromprogram_button": "Copy from Program Mix",
  "audio_applytoprogram_button": "Apply to Program Mix",
  "audio_programmix_label": "Program mix",
  "audio_monitor_label": "Monitor mix",
  "talkback_nomembers_text": "No crew members are connected to talkback",
  "talkback_setincomingmaster_button": "Set incoming master levels",
  "talkback_setincomingindividual_button": "Set incoming individual levels",
  "error_offlinenotice_text": "あなたとの接続が切断された状態です。 この状態が何秒も続く場合はインターネットの接続状態をご確認の上ページ更新をしてください。",
  "cast_waitforqueue_text": "接続者が多いです。 しばらくお待ちください。",
  "cast_yourpositioninqueue_text": "列に並んでいるあなたの位置: <strong class=\"primary\">{currentPositionInQue}<strong>{totalInQue}",
  "cast_needpermissions_text": "デバイスへのアクセスを許可して下さい。",
  "cast_deviceerror_text": "{device}の問題が検出されました。ブラウザを閉じて{device}の接続を確認し、ゲストリンクをもう一度クリックしてください。",
  "cast_deniedpermission_text": "アクセスを拒否したため、ブラウザの設定でアクセスを許可するまで入ることができません。ブラウザを閉じ、再びリンク先に戻って下さい。",
  "cast_deniedguestlink_text": "You are already logged in. If you want to use this link, please log out first.",
  "cast_deniednotinvited_text": "このイベントに参加することができません。",
  "cast_everythingsetup_text": "ありがとうございます。<spanclass=\"primary\">{display_name}<span>、ルームに入場する準備ができています！",
  "cast_moderatorpickyouup_text": "まもなく、運営者があなたの入場を許可します。",
  "cast_chattingwithmoderator_text": "モデレーションへようこそ。",
  "cast_moderatorrejected_text": "申し訳ございません。運営者があなたを入場許可しません。",
  "endsession_thanks_text": "ご参加ありがとうございます。このページは{remainingSecs}秒後に閉じます。",
  "endsession_thankforcoming_text": "ご参加ありがとうございます。",
  "endsession_thankforcomingtocast_text": "本日は{castName}にご参加いただきありがとうございます.",
  "endsession_closetab_text": "このタブを閉じるか、既存のアカウントを使用してログインすることもできます。",
  "endsession_backtologin_button": "ログインに戻る",
  "tally_roomactive_text": "ライブの準備ができました。",
  "tally_roomlive_text": "ライブ中",
  "leavecast_popup_title": "Leave production",
  "leavecast_popup_description": "Are you sure you want to leave the production? The production will not stop, you just leave it.",
  "leavecast_popupanonymous_title": "Leave production",
  "leavecast_popupanonymous_description": "Are you sure you want to leave the production? The production will not stop, you just leave it. You won't be able to come back.",
  "browsercheck_browser_label": "ブラウザ",
  "browsercheck_version_label": "バージョン",
  "browsercheck_supportbrowser_text": "アスリートの瞬間はクローム、エッジ、ファイアフォックスもしくはサファリのブラウザでしかアクセスできません。",
  "browsercheck_supportmobilebrowser_text": "アスリートの瞬間はクロームまたはサファリでしかアクセスできません",
  "browsercheck_download_chrome": "クロームをダウンロードする",
  "browsercheck_download_edge": "エッジをダウンロードする",
  "browsercheck_download_safari": "サファリをダウンロードする",
  "browsercheck_download_firefox": "ファイアフォックスをダウンロードする",
  "browsercheck_appstore_paragraph": "キスウィスタジオ(Kiswe Studio)はアップルストアでご購入できます。",
  "browsercheck_systemnotsupported_paragraph": "This device does not meet the minimal requirements for using Kiswe Studio.",
  "statusstrip_leaveevent_tooltip": "Leave production",
  "statusstrip_selectdevices_tooltip": "デバイスの選択",
  "screenmode_pleaserotate_title": "機器を回転させてください。",
  "screenmode_pleaserotateios_text": "Athlete Momentは横モードで最も良い経験ができます。 あなたは現在、縦モードを使用しています。 機器を回転させください。",
  "screenmode_pleaserotateandroid_text": "Athlete Momentは横モードで最も良い経験ができます。 あなたは現在、縦モードを使用しています。 機器を回転させるか、以下のボタンをご利用ください。",
  "screenmode_pleaserotate_actionbutton": "機器を回転させてください",
  "athletemoments_welcome_title": "Athlete Momentにようこそお越しくださいました！",
  "athletemoments_redirect_paragraph": "まもなくお繋ぎします。",
  "castwelcome_wowzaissues_error": "現在、接続者の定員を超えました。 しばらくしてからもう一度試してください",
  "privatecall_nolocalstream_error": "ストリームを設定するためには、あなたの許可が必要です。 すでに権限を与えている場合は、ウェブカメラに問題がある可能性があります。",
  "privatecall_sendwarning_notification": "オペレーターが（運営者）あなたのビデオとオーディオを確認できません。 マイクとカメラが正常に作動していることを確認し、再度お試しください。",
  "privatecall_sendwarning_label": "もう一度試してください。",
  "privatecall_sendwarning_footer": "声が（はっきりと）聞こえなかった場合は、ここをクリックしてください。",
  "browsercheck_ioscontinueweb_paragraph": "ようこそ! お使いのiOSデバイスが過去3年（2018年あるいはそれ以前）のものの場合は、Apple StoreからKiswe Studioアプリをインストールしてください。そうでない場合は、以下の「ウェブで続ける」を選択すると、より快適にご利用いただけます。",
  "browsercheck_ioscontinueweb_button": "ウェブで続ける",
  "statusstrip_selectdevicesdisabled_tooltip": "現在、デバイスの変更はできません。",
  "cast_moderatorrequestedstreamrestart_text": "モデレーターがストリームの再開を要求しました。少々お待ちください。",
  "selftest_checkcpuandmemory_message": "現在、機器を確認中です。",
  "selftest_setup_text": "設定を選択してください。",
  "selftest_running_text": "メディア接続テスト",
  "selftest_fail_text": "セットアップが機能していません",
  "selftest_restart_text": "インターネットの接続を確認し、別のデバイスを使用して下さい。",
  "selftest_casting_text": "接続中です、お待ちください。",
  "selftest_noreply_text": "お繋ぎできません。インターネット接続を確認してください。",
  "selftest_pleasecloseapps_question": "ご使用中のすべてのアプリ/タブを閉じてください。",
  "selftest_selectothercamera_question": "他のカメラをご選択ください。",
  "selftest_checkyourvolume_question": "Please check if your volume is on",
  "selftest_seeotherguests_question": "Can you see the other 4 streams?",
  "selftest_checkyourheadset_question": "If you are using a headset? Can you hear yourself now?",
  "selftest_hearotherguests_question": "Can you hear this stream?",
  "selftest_performance_question": "selftest_performance_question",
  "selftest_canyouhearlocal_question": "ご自分の声は聞こえますか？",
  "selftest_canyouhearremote_question": "音は聞こえますか？",
  "selftest_canyouseelocal_question": "ご自分の姿は見えますか？",
  "selftest_canyouseeremote_question": "ビデオはご覧になれますか？",
  "selftest_canyouseeyourselflocal_body": "selftest_canyouseeyourselflocal_body",
  "selftest_canyouseeyourselfremote_body": "selftest_canyouseeyourselfremote_body",
  "selftest_onsuccess_button": "selftest_onsuccess_button",
  "selftest_onfail_button": "selftest_onfail_button",
  "selftest_successresult_message": "selftest_successresult_message",
  "selftest_successresult_body": "selftest_successresult_body",
  "selftest_failresult_message": "selftest_failresult_message",
  "selftest_failresult_body": "selftest_failresult_body",
  "selfteststats_canseeselflocal_term": "selfteststats_canseeselflocal_term",
  "selfteststats_canseeselfremote_term": "selfteststats_canseeselfremote_term",
  "selfteststats_canhearselfremote_term": "selfteststats_canhearselfremote_term",
  "selfteststats_canseeothers_term": "selfteststats_canseeothers_term",
  "selfteststats_canhearothers_term": "selfteststats_canhearothers_term",

  "cast_consentprivacy_text": "본인은 참여가 개인 정보의 의무적인 수집과 사용을 요구한다는 것, 또한 특정 <a href=\"https://www.obs.tv/prx/asset.php?tgt=DelegatesSKAMOonboardingENSK-208d50ae628f.pdf&gen=1\" target=\"_blank\">해외 법인들</a>과 함께 그러한 처리를 위임할 수 있음을 설명한 <a href=\"https://www.obs.tv/prx/asset.php?tgt=B2022ATHLETESMOMENTTERMSANDCONDITIONSEN-SK-e74519b05996.pdf&gen=1\" target=\"_blank\">개인정보 보호정책</a>을 읽고 승인했습니다.",
  "cast_consentprivacy_error": "",  // cast_consentprivacy_error is only available for Korean

  "casttypeselector_switcher_name": "",
  "casttypeselector_switcher_description": "",
  "casttypeselector_switcher_short_description": "",
  "casttypeselector_fanroom_name": "",
  "casttypeselector_fanroom_description": "",
  "casttypeselector_fanroom_short_description": "",
  "casttypeselector_stationswitcher_name": "Station",
  "casttypeselector_stationswitcher_description": "Broadcast your Rooms",
  "casttypeselector_stationswitcher_short_description": "Station",
  "srt_type_passthrough_label": "Passthrough",
  "srt_type_multitrack_label": "Multi-Track"
}

export default translation
