import { Loader, Result } from 'kiswe-ui'
import { CallTriage } from '@/types/casts'

export class ModerationGuest {
  private _teamId: string
  private _userId: string
  private _castId: string
  private _streamId: string|null = null
  private _triage: CallTriage
  private _scheduledDate: Date|null
  private _timestamp: number

  constructor (data: unknown = {}) {
    this._teamId = Loader.loadString(data, 'teamId')
    this._userId = Loader.loadString(data, 'userId')
    this._castId = Loader.loadString(data, 'castId')
    this._streamId = Loader.loadStringOrNull(data, 'streamId', this._streamId)
    this._triage = Loader.loadString(data, 'triage') as CallTriage
    this._scheduledDate = Loader.loadDateOrNull(data, 'scheduledDate', null)
    this._timestamp = Loader.loadNumber(data, 'timestamp', 0)
  }

  static load (snapData: unknown): Result<ModerationGuest, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new ModerationGuest(snapData))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  public get teamId () { return this._teamId }
  public get userId () { return this._userId }
  public get castId () { return this._castId }
  public get streamId () { return this._streamId }
  public get triage () { return this._triage }
  public get scheduledDate () { return this._scheduledDate }
  public get timestamp () { return this._timestamp}
}
