import firebase from 'firebase/compat/app'

/**
 * @deprecated
 */
function lazyUpdate (state: any, key: string|number, newVal: any, deleteObsoleteKeys = true) {
  try {
    if (typeof newVal === 'object' && newVal !== null) {
      if (typeof newVal !== typeof state[key]) {
        state[key] = newVal
      } else if (newVal instanceof Date) {
        if (!(state[key] instanceof Date) || newVal.getTime() !== state[key].getTime()) {
          state[key] = newVal
        }
      } else {
        for (const rightkey in newVal) {
          if (state[key] !== null && state[key][rightkey] === undefined) {
            state[key][rightkey] = newVal[rightkey]
          } else if (state[key] !== null) {
            lazyUpdate(state[key], rightkey, newVal[rightkey], deleteObsoleteKeys)
          } else if (newVal !== null) {
            state[key] = newVal
          }
        }
        if (deleteObsoleteKeys) {
          for (const leftkey in state[key]) {
            if (newVal[leftkey] === undefined) {
              delete state[key][leftkey]
            }
          }
        }
      }
    } else if (state[key] !== newVal) {
      state[key] = newVal
    }
  } catch (error) {
    console.error('lazyUpdate', state, key, newVal, error)
  }

}

/** Logs all document and query snapshot (un)subscriptions.
 *
 * @example
 * import { dbg_injectSnapshotSubscriptionLogging } from '@/store/storehelper'
 * export default Vue.extend({
 * name: 'App',
 * beforeCreate () {
 *   if (env.environment === KENV.Dev) dbg_injectSnapshotSubscriptionLogging()
 * },
 * ...
 */
function dbg_injectSnapshotSubscriptionLogging () {
  const orgOnSnapshot = firebase.firestore.DocumentReference.prototype.onSnapshot
  // @ts-ignore
  firestore.DocumentReference.prototype.onSnapshot = function (...args) {
    console.debug(`DOC ${this.path}`/*, this, args*/)
    // @ts-ignore
    const orgUnsubFunc = orgOnSnapshot.apply(this, args)
    return () => {
      console.debug(`DOC-unsub ${this.path}`)
      orgUnsubFunc()
    }
  }
  const orgQueryOnSnapshot = firebase.firestore.Query.prototype.onSnapshot
  // @ts-ignore
  firestore.Query.prototype.onSnapshot = function (...args) {
    let path = ''
    let filters = ''
    for (const key in this) {
      // @ts-ignore
      const value: any = this[key]
      if (value?.path) {
        path = value.path.segments.join('/')
        if ( value?.filters)
        for (const filter of value.filters) {
          const where = filter?.field?.segments?.join('/') ?? ''
          const op = filter?.op ?? ''
          const val = Object.values(filter?.value ?? {})[0] ?? ''
          filters += `${where}${op}${val},`
        }
      }
    }
    console.debug(`QUERY ${path}:${filters}`/*, this, args*/)
    // @ts-ignore
    const orgUnsubFunc = orgQueryOnSnapshot.apply(this, args)
    return () => {
      console.debug(`QUERY-unsub ${path}:${filters}`)
      orgUnsubFunc()
    }
  }
}

export { lazyUpdate, dbg_injectSnapshotSubscriptionLogging }
