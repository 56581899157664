import { Stream, StreamStatusCode, StreamType } from '@/types/streams'
import { SystemStatus } from '@/types/ops'
import { UserNetworkStats } from '@/types/networkstats'
import { ErrorMessage, Optional } from '@/types'

export interface StreamingErrorParams {
  isPresent: boolean,
  isCasting: boolean,
  badNgcvpStatus: boolean,
  networkStats?: UserNetworkStats,
  castingAs?: StreamType,
  udpEnabled?: boolean,
  showFPSWarning?: boolean
}

export interface StreamStatusInfo {
  type: StreamType,
  ngcvp_status?: SystemStatus,
  active?: boolean,
  hasVariants?: boolean,
  srt_state?: string,
  srt_msg?: string
}

export const getStreamStatus = (
  streamInfo: StreamStatusInfo, stats: Optional<UserNetworkStats> =  undefined,
  presence = false, delta = 0, isJanus = false
): StreamStatusCode => {
  switch (streamInfo.type) {
    case StreamType.Broadcast:
      return calcBroadcastStatus(streamInfo, delta)
    case StreamType.ScreenShare:
      return calcBroadcastStatus(streamInfo, delta)
    case StreamType.Caster:
      return calcCasterStatus(streamInfo, stats, presence, delta, isJanus)
    case StreamType.NonCaster:
      return calcCrewStatus(streamInfo, stats, presence)
    case StreamType.Stream:
    case StreamType.Output:
    case StreamType.Url:
    default:
      return calcStreamStatus(streamInfo, delta)
  }
}

const calcBroadcastStatus = (streamInfo: StreamStatusInfo, delta: number): StreamStatusCode => {
  let status = StreamStatusCode.None
  if (streamInfo.ngcvp_status === SystemStatus.OK) {
    status = StreamStatusCode.StreamGood
  } else if (streamInfo.srt_state !== undefined && streamInfo.srt_state !== SystemStatus.OK) {
    status = StreamStatusCode.SRTError
  } else if (streamInfo.ngcvp_status === SystemStatus.ERROR) {
    // stream boot up grace period
    if (delta < 60) {
      status = StreamStatusCode.StreamBooting
    } else {
      status = StreamStatusCode.StreamError
    }
  } else if (streamInfo.ngcvp_status === SystemStatus.NONE) {
    if (delta < 60) {
      status = StreamStatusCode.StreamBooting
    }
  }
  return status
}

const calcCasterStatus = (
  streamInfo: StreamStatusInfo, stats: UserNetworkStats | undefined,
  presence: boolean = false, delta: number, isJanus: boolean = false
): StreamStatusCode => {
  let status = StreamStatusCode.CasterNotPresent
  const supposedlyCasting = streamInfo.ngcvp_status !== undefined && streamInfo.ngcvp_status !== SystemStatus.NONE
  const isMissingVariants = streamInfo.hasVariants === false && !isJanus
  if (streamInfo.ngcvp_status === SystemStatus.ERROR || (isMissingVariants && supposedlyCasting)) {
    // stream boot up grace period
    if (delta < 60) {
      status = StreamStatusCode.CasterConnecting
    } else {
      status = StreamStatusCode.CasterError
    }
  } else if (streamInfo.ngcvp_status === SystemStatus.NONE) {
    if (presence) {
      status = StreamStatusCode.CasterPresent
    } else {
      status = StreamStatusCode.CasterNotPresent
    }
  } else if (streamInfo.ngcvp_status === SystemStatus.OK) {
    const uploadscore = stats?.upload?.score || 0
    const downloadscore = stats?.download?.score || 0
    if (testPoorNetwork(true, uploadscore, downloadscore)) {
      status = StreamStatusCode.CasterCastingIssues
    } else {
      status = StreamStatusCode.CasterCasting
    }
  } else {
    if (presence) {
      status = StreamStatusCode.CasterPresent
    } else {
      status = StreamStatusCode.CasterNotPresent
    }
  }
  return status
}

const calcCrewStatus = (
  streamInfo: StreamStatusInfo, stats: UserNetworkStats | undefined, presence: boolean = false
): StreamStatusCode => {
  if (streamInfo.ngcvp_status === SystemStatus.OK) {
    return StreamStatusCode.CrewConnected // NOTE: does not work atm, since we don't get ngcvpStats from the mixer
  } else if (stats?.download?.score !== undefined && stats.download.score <= 2 && presence) {
    return (stats.download.score <= 1) ? StreamStatusCode.CrewError : StreamStatusCode.CrewLowBandwidth
  } else if (presence) {
    return StreamStatusCode.CrewPresent
  }
  return StreamStatusCode.CrewNotPresent
}

const calcStreamStatus = (streamInfo: StreamStatusInfo, delta: number): StreamStatusCode => {
  let status = StreamStatusCode.None
  if (streamInfo.ngcvp_status === SystemStatus.OK) {
    status = StreamStatusCode.OutputGood
  } else if (streamInfo.ngcvp_status === SystemStatus.NETWORK_SLOW) {
    status = StreamStatusCode.OutputLowBandwidth
  } else if (streamInfo.ngcvp_status === SystemStatus.ERROR || streamInfo.srt_state === SystemStatus.ERROR) {
    if ((streamInfo.active === undefined || streamInfo.active) && delta < 60) {
      status = StreamStatusCode.StreamBooting
    } else {
      status = StreamStatusCode.StreamError
    }
  }
  return status
}

export const testPoorNetwork = (isCasting: boolean, uploadScore: number, downloadScore: number): boolean => {
  return (isCasting && uploadScore <= 2) || (downloadScore <= 2)
}

export const hasStreamingError = (params: StreamingErrorParams): boolean => {
  if (!params.isPresent && !params.isCasting) return false
  let isError = false
  const stats = params.networkStats
  const uploadScore = stats?.upload?.score ?? null
  if (params.isCasting && uploadScore !== null && uploadScore <= 2) {
    isError = true
  }
  const downloadScore = stats?.download?.score ?? null
  if (downloadScore !== null && downloadScore <= 2) {
    isError = true
  }
  if (params.isCasting && stats?.encoder !== undefined) {
    if (stats.encoder.audio_bitrate === 0) isError = true
    if (stats.encoder.video_bitrate === 0) isError = true
    if (stats.encoder.cpu_usage > 80) isError = true
    // if (this.stats.encoder.fps_out < fpsThreshold) isError = true
  }
  if (params.badNgcvpStatus) isError = true
  return isError
}

export const getStreamingErrorMessages = (params: StreamingErrorParams): ErrorMessage[] => {
  if (!params.isPresent) return []

  const errorList: ErrorMessage[] = []
  const networkMessage = 'Are you sure your home region is set correctly? Go back to the Dashboard -> Account Settings'
  const restartMessage = 'Something is wrong with your live stream, please stop casting and try again'
  const castingStreamTypes = [StreamType.Caster, StreamType.Moderator]
  const castingAs = params.castingAs ?? StreamType.NonCaster
  const stats = params.networkStats ?? null
  const uploadScore = stats?.upload?.score ?? null
  const downloadScore = stats?.download?.score ?? null

  if (params.isCasting && uploadScore !== null && uploadScore <= 1) {
    errorList.push({ message: 'Poor Uplink', hint: networkMessage })
  }
  if (downloadScore !== null && downloadScore <= 1) {
    errorList.push({ message: 'Poor Downlink', hint: networkMessage })
  }
  if (params.isCasting && stats?.encoder !== undefined) {
    if (stats.encoder.audio_bitrate === 0) {
      errorList.push({ message: 'Video Only', hint: restartMessage })
    }
    if (stats.encoder.video_bitrate === 0 && castingStreamTypes.includes(castingAs)) {
      errorList.push({ message: 'Audio Only', hint: restartMessage })
    }
    if (stats.encoder.cpu_usage > 80) {
      errorList.push({ message: 'High CPU Usage' })
    }
    if (stats.encoder.fps_out < 10 && castingStreamTypes.includes(castingAs)) {
      errorList.push({ message: 'No Video' })
    }
    if (stats.encoder.fps_out > 10 && stats.encoder.fps_out < 16 && castingStreamTypes.includes(castingAs)) {
      errorList.push({ message: 'Low Framerate', hint: 'Your environment could be too dark, please check your lighting to increase the framerate' })
    }
    if (params.showFPSWarning && !castingStreamTypes.includes(castingAs)) {
      errorList.push({ message: 'Low Framerate' })
    }
    if (params.udpEnabled) {
      errorList.push({ message: 'UDP Connection', hint: 'Low quality can be expected from this caster' })
    }
    /* if (stats.encoder.video_bitrate < 150000) {
      errorList.push({ message: 'Low Video Bandwidth' })
    } */
  }
  if (params.badNgcvpStatus) {
    errorList.push({ message: 'Stream Issues', hint: restartMessage })
  }

  return errorList
}

export const streamStatusCodeToTooltip = (streamStatus: StreamStatusCode, streamType: StreamType): string|null => {
  switch (streamType) {
    case StreamType.Broadcast:
      switch (streamStatus) {
        case StreamStatusCode.StreamGood: return 'connected'
        case StreamStatusCode.StreamBooting: return 'stream connecting'
        case StreamStatusCode.StreamError: return 'stream error'
        case StreamStatusCode.StreamWarning: return 'stream warning'
      }
      break
    case StreamType.Caster:
    case StreamType.NonCaster:
    case StreamType.Moderator:
      switch (streamStatus) {
        case StreamStatusCode.CasterPresent:
        case StreamStatusCode.CrewPresent:
          return 'present'
        case StreamStatusCode.CasterNotPresent:
        case StreamStatusCode.CrewNotPresent:
          return 'not present'
        case StreamStatusCode.CasterCasting: return 'casting'
        case StreamStatusCode.CasterConnecting: return 'caster connecting'
        case StreamStatusCode.CasterCastingIssues: return 'casting with poor network'
        case StreamStatusCode.CasterNotCastingBadNetwork: return 'caster not in cast due to network issues'
        case StreamStatusCode.CasterError: return 'Error: Caster not in output'
        case StreamStatusCode.CrewError: return 'crew output error'
      }
      break
    case StreamType.Stream:
    case StreamType.Output:
      switch (streamStatus) {
        case StreamStatusCode.OutputGood: return 'streaming'
        case StreamStatusCode.OutputLowBandwidth: return 'low bandwidth'
        case StreamStatusCode.StreamBooting: return 'stream booting'
        case StreamStatusCode.StreamError: return 'stream error'
      }
      break
  }
  return null
}

export const srtInputLine = (stream: Stream): string => {
  let result = ''
  if (stream.srt_info !== undefined) {
    const srt_info = stream.srt_info
    const ip = srt_info.ip ?? '0.0.0.0'
    const server = ip === '0.0.0.0' ? srt_info.nimbleserver : ip
    const port = srt_info.port
    let modeStr = ''
    if (srt_info?.mode !== undefined) {
      modeStr = '?mode=' + srt_info.mode
    }
    result = `srt://${server}:${port}${modeStr}`
  }
  return result
}

export const srtOutputLine = (stream: Stream): string => {
  let result = ''
  if (stream.srt_info !== undefined) {
    const srt_info = stream.srt_info
    const nimbleserver = srt_info.nimbleserver
    const port = srt_info.port
    result = `srt://${nimbleserver}:${port}`
  }
  return result
}

export const srtState = (stream: Stream): string => {
  return 'state = ' + (stream.srt_state ? stream.srt_state : 'orange')
}
