import { createI18n } from 'vue-i18n'
import { StringTranslation, SupportedLocale } from './types'

import english from './translations/english'
// import dutch from './translations/dutch'
import french from './translations/french'
import spanish from './translations/spanish'
import japanese from './translations/japanese'
import korean from './translations/korean'
import chinese from './translations/chinese'
import portuguese from './translations/portuguese'
import russian from './translations/russian'
import italian from './translations/italian'
// import hindi from './translations/hindi'

const messages: Record<SupportedLocale, StringTranslation> = {
  [SupportedLocale.English]: english,
  // [SupportedLocale.Dutch]: dutch,
  [SupportedLocale.French]: french,
  [SupportedLocale.Spanish]: spanish,
  [SupportedLocale.Japanese]: japanese,
  [SupportedLocale.Korean]: korean,
  [SupportedLocale.Chinese]: chinese,
  [SupportedLocale.Portuguese]: portuguese,
  [SupportedLocale.Russian]: russian,
  [SupportedLocale.Italian]: italian
  // [SupportedLocale.Hindi]: hindi,
}

export const i18n = createI18n({
  legacy: false,
  locale: SupportedLocale.English,
  fallbackLocale: SupportedLocale.English,
  messages
})
