import { Loader, Result, ResultVoid } from 'kiswe-ui'
import { Email } from '@/modules/profiles/types'


export class AuthUser {
  uid: string
  email: Email

  constructor (data: unknown) {
    this.uid = Loader.loadString(data, 'uid')
    this.email = Loader.loadString(data, 'email') as Email
  }

  static load (data: unknown) {
    try {
      return Result.success(new AuthUser(data))
    } catch (error) {
      return Result.fromUnknownError('invalid param', error)
    }
  }
}

export interface CreateUserParams {
  email: Email,
  password: string
}

export abstract class AuthInterface {
  abstract deleteUsers (_userIds: []): Promise<ResultVoid<'auth'|'unknown'>>
  abstract signInWithEmailAndPassword (_email: Email, _password: string): Promise<Result<AuthUser, 'auth'|'unknown'>>
  // abstract getUserByEmail (_email: Email): Promise<Result<AuthUser, 'auth'|'unknown'>>
  abstract createUser (params: CreateUserParams): Promise<Result<AuthUser, 'auth'|'unknown'>>
}
