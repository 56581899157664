import { transform, isEqual, isObject, isDate, isArray } from 'lodash'

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 *
 * based on https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
export const difference = (object: any, base: any) => {
  function changes (object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        if (isArray(value)) {
          result[key] = value
        } else {
          result[key] = (isObject(value) && isObject(base[key]) && !isDate(value)) ? changes(value, base[key]) : value
        }
      }
    })
  }
  return changes(object, base)
}
