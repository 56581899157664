import { moduleActionContext } from '../..'
import { defineModule } from 'direct-vuex'
import { state } from './streams.state'
import { actions, StreamsContext } from './streams.actions'
import { getters } from './streams.getters'
import { mutations } from './streams.mutations'

const streamsModule = defineModule({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})

export default streamsModule

export const streamsModuleActionContext = (context: StreamsContext) => moduleActionContext(context, streamsModule)
