import store from '@/store'
import { computed } from 'vue'
import { pickBy } from 'lodash'

const sourceStreams = computed(() => store.state.events.sourceStreams)
const streamDocs = computed(() => store.state.events.streamDocs)
const streamNames = computed(() => store.state.events.streamNames)
const castStreams = computed(() => store.state.streams.castStreams)
const currentCasterStream = computed(() => store.state.events.currentCasterStream)
const sourceStreamsDocs = computed(() => pickBy(streamDocs.value, (_stream, key) => !!sourceStreams.value[key]))
const currentDesktopStream = computed(() => store.state.streams.desktopStreamId)

const useCastStreams = () => {
  return {
    castStreams,
    currentCasterStream,
    currentDesktopStream,
    sourceStreams,
    sourceStreamsDocs,
    streamDocs,
    streamNames
  }
}

export default useCastStreams
