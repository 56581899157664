import { Loader } from 'kiswe-ui'
import { GConstructor, Mixin, StudioBaseMixin } from './base'

interface HasDurationProps {
  required?: boolean
}

export function hasDuration<TBase extends StudioBaseMixin> (Base: TBase, props?: HasDurationProps) {
  return class DurationClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      const in4Hours = new Date(Date.now() + 4 * 60 * 60 * 1000)
      this.start_date = Loader.loadDate(arg[0], 'start_date', props?.required ? undefined : new Date())
      this.end_date = Loader.loadDate(arg[0], 'end_date', props?.required ? undefined : in4Hours)
    }
    start_date: Date
    end_date: Date
    static __constructsHasDuration = true

    get isActive () {
      const now = new Date()
      const startDate = this.start_date
      const endDate = this.end_date
      return now.getTime() >= startDate.getTime() && now.getTime() <= endDate.getTime()
    }

    setStartDate (date: Date) {
      this.start_date = date
      return this
    }

    setEndDate (date: Date) {
      this.end_date = date
      return this
    }
  }
}

export type HasDuration = Mixin<typeof hasDuration>

export const constructsDurationMixin = (func: unknown): func is GConstructor<HasDuration> => {
  if (typeof func !== 'function' || func === null) return false
  if ('__constructsHasDuration' in func) return true
  return false
}
