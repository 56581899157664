import { Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { TranscodingRegion } from '@/types/admin'


export class TranscodingRegionsLoader {
  static load (data: unknown): Result<KeyDict<TranscodingRegion>, 'invalid_params'|'unknown'> {
    try {
      const transcoding: KeyDict<TranscodingRegion> = {}
      if (typeof data !== 'object' || data === null) throw new Error('data is not an object')
      Object.entries(data).forEach(([key, value]) => {
        // We need to add this here as the database loader will pass it along as well and we need to ignore it
        if (key === 'id') return
        if (typeof value !== 'object' || value === null) throw new Error('Transcoding region is not a valid object')
        transcoding[key] = new TranscodingRegion(value)
      })
      return Result.success(transcoding)
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }
}
