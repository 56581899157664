import { GLOBAL_LAYER_PREFIX, HIDDEN_GLOBAL_PRESETS, MAX_GLOBAL_LAYERS } from '../types'
import { ScenePreset } from '@/modules/scenes/classes'

export const getGlobalLayerIds = (): string[] => {
  const ids: string[] = []
  for (let i = 1; i <= MAX_GLOBAL_LAYERS; i++) {
    ids.push(GLOBAL_LAYER_PREFIX + i)
  }
  return [...ids, ...HIDDEN_GLOBAL_PRESETS]
}

export const globalPresetIds = getGlobalLayerIds()

export const getGlobalPresetsBoxIds = (allPresets: ScenePreset[]): string[] => {
  return allPresets
    .filter((preset) => globalPresetIds.includes(preset.id))
    .map((preset) => Object.keys(preset.scene_layout.boxes))
    .reduce((allIds, newIds) => [...allIds, ...newIds])
}
