export interface ClipRange {
  start: number
  end: number
}
export enum ClipContainerFormatType {
  Mov = 'Mov',
  Mp4 = 'Mp4'
}

export interface ClipInfo extends ClipRange {
  cast: string
  k360event: number|string
  name: string
  tracks: string[]
  stream?: string
  clipId?: string
  containerFormat?: ClipContainerFormatType
}
