import { mapValues } from 'lodash'
import english from './translations/english'
import { TranslationKeys } from './types'

const findValues = (text: string): string[] => {
  const starts = text.split('{')
  starts.shift()
  return starts.map(value => value.split('}')[0])
}

export const availableParameters: Record<TranslationKeys, string[]> = mapValues(english, text => findValues(text))
