import { Loader } from 'kiswe-ui'
import { HexCode } from 'kiswe-ui'

export type BrandingImagesKey = keyof Pick<TeamBranding, 'logoUrl'|'backgroundImageUrl'|'placeholderImageUrl'>

export default class TeamBranding {
  backgroundImageUrl: string|null = null
  dashboardUrl: string|null = null
  logoUrl: string|null = null
  placeholderImageUrl: string|null = null
  primaryColor: HexCode = '#DC2626'
  secondaryColor: HexCode = '#64748B'
  selftestStream: string = 'selftest'
  standardFontFamily: string|null = 'Arial'
  supportName: string = 'Kiswe Support'

  constructor (data?: unknown) {
    if (!data) return
    this.backgroundImageUrl = Loader.loadStringOrNull(data, 'backgroundImageUrl', this.backgroundImageUrl)
    this.dashboardUrl = Loader.loadStringOrNull(data, 'dashboardUrl', this.dashboardUrl)
    this.logoUrl = Loader.loadStringOrNull(data, 'logoUrl', this.logoUrl)
    this.placeholderImageUrl = Loader.loadStringOrNull(data, 'placeholderImageUrl', this.placeholderImageUrl)
    this.primaryColor = Loader.loadHexCode(data, 'primaryColor', this.primaryColor)
    this.secondaryColor = Loader.loadHexCode(data, 'secondaryColor', this.secondaryColor)
    this.selftestStream = Loader.loadString(data, 'selftestStream', this.selftestStream)
    this.standardFontFamily = Loader.loadStringOrNull(data, 'standardFontFamily', this.standardFontFamily)
    this.supportName = Loader.loadString(data, 'supportName', this.supportName)
  }

  get fontFamily () {
    return this.standardFontFamily
  }
}
