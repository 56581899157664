import { Loader } from 'kiswe-ui'
import { Mixin, StudioBaseMixin } from './base'

export function hasChat<TBase extends StudioBaseMixin> (Base: TBase) {
  return class ChatClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.chat_id = Loader.loadStringOrNull(arg[0], 'chat_id',null)
    }
    chat_id: string|null

    setChatId<T extends string> (chatId: T extends '' ? never : T) {
      this.chat_id = chatId
      return this
    }
  }
}

export type HasChat = Mixin<typeof hasChat>
