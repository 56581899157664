import fb from '../../firebase'
import { isEqual, reduce } from 'lodash'
import {
  AssetsState,
  RootState,
} from '../types'
import store, { moduleActionContext } from '..'
import {
  Asset,
  AssetFolderQuery,
  AssetGroup,
  AssetGroupErrors,
  AssetGroupType,
  AssetSummary,
  AssetType,
  NewAssetRequest,
  SocialMediaAssetTypes,
  SocialMediaTypes,
  TweetAsset,
  TweetPollAsset,
  VideoAsset,
  WidgetAsset
} from '@/types/assets'
import { ActionContext } from 'vuex'
import { Layout } from '@/types/layouts'
import { KeyDict, ObjectKeys } from '@/types'
import studio, { CollectionNames } from '@/modules/database/utils'
import { lazyUpdate } from '../storehelper'
import { defineModule } from 'direct-vuex'
import { MergeOption, Order } from '@/modules/database/utils/database'
import { generateRandomString } from '@/modules/common/utils'
import { Result } from 'kiswe-ui'
import { getHiddenImportedTweetsFolderId } from '@/modules/socialmedia/utils'
import { AssetManagerUpload } from '@/modules/assets/types'

type AssetsContext = ActionContext<AssetsState, RootState>

const UPDATE_TIME_QUEUE = 500
const MAX_CACHED_OPEN_FOLDERS = 8

interface AssetQueueItem {
  action: 'add' | 'remove',
  data: Asset,
  assetGroupId: string
}
let assetQueue: AssetQueueItem[] = []

const updateSingleAsset = (item: AssetQueueItem) => {
  const existingAsset = store.state.assets.assetGroupAssets[item.assetGroupId][item.data.id]
  if (existingAsset === undefined && item.action === 'add') {
    store.commit.assets.addAssetToGroup({ asset: item.data, assetGroupId: item.assetGroupId })
  }
  if (existingAsset !== undefined && item.action === 'add' && !isEqual(existingAsset, item.data)) {
    store.commit.assets.updateAssetInGroup({ asset: item.data, assetGroupId: item.assetGroupId })
  }
  if (existingAsset !== undefined && item.action === 'remove') {
    store.commit.assets.removeAssetInGroup({ assetId: item.data.id, assetGroupId: item.assetGroupId })
  }
}

let stopAssetQueue = true

const checkAssetQueue = () => {
  if (stopAssetQueue) return
  if (assetQueue.length > 0) {
    const item = assetQueue[0]
    updateSingleAsset(item)
    assetQueue.shift()
  }
  setTimeout(() => {
    checkAssetQueue()
  }, UPDATE_TIME_QUEUE)
}

const startCheckAssetQueue = () => {
  if (stopAssetQueue === true) {
    stopAssetQueue = false
    checkAssetQueue()
  }
}

const stopCheckAssetQueue = () => {
  stopAssetQueue = true
}

const makeAssetGroupQuery = (parents: string[], teamId: string) => {
  if (parents.length > 10) {
    throw new Error(`makeQueryAssetGroup, parents should be smaller then 10 ids ${parents.length}`)
  }
  return studio.db.collection(CollectionNames.ASSET_GROUPS)
    .where('team_id', '==', teamId)
    .where('parent_id', 'in', parents)
    .where('deleted', '==', false)
}

const assetsModule = defineModule({
  namespaced: true,
  state: {
    assetGroupAssets: {},
    assetGroupList: {},
    assetGroupSubscribes: {},
    folderAssetsLoading: false,
    folderList: {},
    folderQuery: null,
    initializing: true,
    openFolders: [AssetGroupType.RootFolder],
    sceneAssets: {},
    uploadingAssets: {},
    selectedFolder: '',
    subFolders: {},
    subscribedAssets: {}
  } as AssetsState,
  getters: {
    activeFolder: (state: AssetsState): AssetGroup|undefined => {
      const folderId = state.selectedFolder
      if (folderId === null) return undefined
      return state.folderList[folderId]
    },
    initializing: (state: AssetsState): boolean => {
      return state.initializing
    }
  },
  actions: {
    assetsLoading (context: AssetsContext, isLoading: boolean) {
      const { commit } = assetsModuleActionContext(context)
      commit.assetsLoading(isLoading)
    },
    async subscribeAssetFolderAssets (context: AssetsContext, query: AssetFolderQuery) {
      const { state, commit, dispatch } = assetsModuleActionContext(context)
      const assetGroup = state.folderList[query.assetGroupId]
      if (!assetGroup) {
        // => don't subscribe the root folder
        return
      }
      const prefix = `assetgroup_${ query.assetGroupId }_${ query.teamId }_${ query.id }_`
      const runningkeys = studio.subscriptions.runningKeys(prefix)

      const key = `${ prefix }${ query.minStars }`
      for (const rkey of runningkeys) {
        if (rkey !== key) {
          const stars = parseInt(rkey.replace(prefix, ''))
          const newQuery: AssetFolderQuery = {
            ...query,
            minStars: stars
          }
          dispatch.unsubscribeAssetFolderAssets(newQuery)
        }
      }
      let teamId = store.state.user.teamId
      if (store.state.user.profile?.isSuperUser) {
        teamId = store.state.user.profile.currentTeam || teamId
      }
      if (teamId === null) throw Error(`Cannot subscribe to ${key} without a team id.`)

      const result = await studio.subscriptions.subscribe({
        key,
        query: studio.db.collection(CollectionNames.ASSETS)
          .where('team_id', '==', teamId)
          .where('asset_group', '==', query.assetGroupId)
          .where('stars', '>=', query.minStars)
          .where('deleted', '==', false)
          .orderBy('stars', Order.DESCENDING)
          .orderBy('timestamp', Order.DESCENDING),
        callback: commit.updateAssetGroupQuery
      })
      result.logIfError(`Failed to subscribe to assetgroup: ${key}`)
    },
    async unsubscribeAssetFolderAssets (_context: AssetsContext, query: AssetFolderQuery) {
      const key = `assetgroup_${ query.assetGroupId }_${ query.teamId }_${ query.id }_${ query.minStars }`
      store.commit.assets.cleanUpAssetGroup(query.assetGroupId)
      studio.subscriptions.unsubscribe(key, () => { store.commit.assets.cleanUpAssetGroup(query.assetGroupId) })
    },
    async subscribeAssetFolderSubfolders (context: AssetsContext, query: AssetFolderQuery) {
      const { state, commit, dispatch } = assetsModuleActionContext(context)
      const assetGroup = state.folderList[query.assetGroupId]
      const isRootLevel = !assetGroup
      const prefix = `assetgroup_${query.assetGroupId}_${query.teamId}_`
      const subfolderKey = `${prefix}subfolders`
      const runningkeys = studio.subscriptions.runningKeys(prefix)
      for (const rkey of runningkeys) {
        if (rkey === subfolderKey) {
          dispatch.unsubscribeAssetFolderSubfolders(query)
        }
      }
      const folderId = (isRootLevel) ? '' : query.assetGroupId
      const result = await studio.subscriptions.subscribe({
        key: subfolderKey,
        query: studio.db.collection(CollectionNames.ASSET_GROUPS)
          .where('team_id', '==', query.teamId)
          .where('parent_id', '==', folderId)
          // .where('type', '==', AssetGroupType.Folder)
          .where('deleted', '==', false),
        callback: commit.setSubFolders
      })
      result.logIfError(`Failed to subscribe to asset subfolders: ${subfolderKey}`)
    },
    async unsubscribeAssetFolderSubfolders (_context: AssetsContext, query: AssetFolderQuery ) {
      const prefix = `assetgroup_${query.assetGroupId}_${query.teamId}_`
      const subfolderKey = `${prefix}subfolders`
      store.commit.assets.cleanUpSubFolders(query.assetGroupId)
      studio.subscriptions.unsubscribe(subfolderKey, () => { store.commit.assets.cleanUpSubFolders(query.assetGroupId) })
    },
    async unsubscribeTeamAssetGroup (context: AssetsContext, payload: { teamId: string, id?: string }) {
      const { teamId, id } = payload
      if (teamId === '') return
      const { state } = assetsModuleActionContext(context)
      const key = `teamassetgroups_${ teamId }_${ id ?? 'main' }`
      studio.subscriptions.unsubscribe(key, () => { delete state.assetGroupSubscribes[key] })
    },
    async subscribeTeamAssetGroup (context: AssetsContext, payload: { teamId: string, id?: string }) {
      const { teamId, id } = payload
      if (teamId === '') return
      const { commit, state } = assetsModuleActionContext(context)
      const key = `teamassetgroups_${ teamId }_${ id ?? 'main' }`
      const result = await studio.subscriptions.subscribe({
        key,
        query: makeAssetGroupQuery(state.openFolders, teamId),
        callback: commit.updateTeamAssetGroups
      })
      result.logIfError(`Could not subscribe to team ${teamId} asset groups`)
      if (result.isSuccess) state.assetGroupSubscribes[key] = result.value
    },
    async clearTeamAssetGroups (_context: AssetsContext) {
      store.commit.assets.clearTeamAssetGroups()
    },
    async addFolderToSubscribedTeamAssetGroups (context: AssetsContext, payload: { folderId: string, id: string }) {
      const { folderId, id } = payload
      const { state, commit } = assetsModuleActionContext(context)
      commit.addOpenFolder(folderId)
      const teamId = store.state.team.activeTeam
      const key = `teamassetgroups_${ teamId }_${ id }`
      const query = makeAssetGroupQuery(state.openFolders, teamId)
      const existingSubscribe = state.assetGroupSubscribes[key]
      if (existingSubscribe) existingSubscribe.update(query).logIfError(`Failed to update query for ${key}`)
      else console.error(`Cannot update query for ${key} if it does not exist yet`)
    },
    async addSceneAssetToLayout (_context: AssetsContext, asset: Asset) {
      store.commit.assets.addSceneAssetToLayout(asset)
    },
    async updateLayoutAssets (_context: AssetsContext, layout: Layout) {
      const contents = layout.contents || {}
      const requiredAssetIds = reduce(contents, (result: string[], content, _key) => {
        result.push(content.id)
        return result
      }, [])

      for (const id of requiredAssetIds) {
        const result = await studio.db.collection(CollectionNames.ASSETS).doc(id).get()
        if (result.isSuccess) {
          store.commit.assets.updateSceneAsset(result.value)
        } else {
          result.log('Error getting assets:')
        }
      }
    },

    // Check for unsubscribes
    async updateSceneAssets (_context: AssetsContext) {
      const currentCast = store.state.events.currentCast
      const prefix = 'asset_'
      // Note: Also includes assets from the preview_scenes[].scene_layout.contents
      const requiredAssetIds = currentCast?.getScenesAssetIds() ?? new Set<string>()
      for (const id of requiredAssetIds) {
        const subid = `${prefix}${id}`
        if (!studio.subscriptions.exists(subid)) {
          const result = await studio.subscriptions.subscribe({
            key: subid,
            query: studio.db.collection(CollectionNames.ASSETS).doc(id),
            callback: store.commit.assets.updateSceneAsset
          })
          result.logIfError('Error subscribing to asset:')
        }
      }
      const runningKeys = studio.subscriptions.runningKeys(prefix)
      for (const id of runningKeys) {
        if (!requiredAssetIds.has(id.substr(prefix.length))) {
          const cleanup = () => {
            store.commit.assets.removeFromSceneAsset(id.substr(prefix.length))
          }
          studio.subscriptions.unsubscribe(id, cleanup)
        }
      }
    },
    async updateAssetStars (_context: AssetsContext, asset: { id: string, stars: number }) {
      const result = await studio.db.collection(CollectionNames.ASSETS).doc(asset.id)
                                    .set({ stars: asset.stars }, MergeOption.MERGE)
      result.logIfError('Error updating asset stars')
    },
    async updateAssetTransitionDelay (_context: AssetsContext, asset: { id: string, delay: number }) {
      const result = await studio.db.collection(CollectionNames.ASSETS).doc(asset.id)
                                    .set({ transitionDelay: asset.delay }, MergeOption.MERGE)
      result.logIfError(`Error updating asset ${asset.id} transition delay to ${asset.delay}`)
    },
    async updateAssetVolume (_context: AssetsContext, asset: { id: string, volume: number }) {
     const result = await studio.db.collection(CollectionNames.ASSETS).doc(asset.id)
                                   // @ts-ignore
                                   .set({ volume: asset.volume }, MergeOption.MERGE)
      result.logIfError('Error updating asset volume')
    },
    async updateAsset (_context: AssetsContext, asset: Partial<Asset> & { id: string }) {
      const saveAsset = { ...asset }
      if (saveAsset.type === AssetType.Tweet) {
        saveAsset.data = JSON.stringify(saveAsset.data)
      }
      const result = await studio.db
        .collection(CollectionNames.ASSETS).doc(saveAsset.id)
        .set(saveAsset, MergeOption.MERGE)
      result.logIfError('Error updating asset:')
    },
    async updateAssetGroup (_context: AssetsContext, folder: Partial<AssetGroup> & { id: string }) {
      const saveFolder = { ...folder }
      const result = await studio.db.collection(CollectionNames.ASSET_GROUPS).doc(saveFolder.id)
                                    .set(saveFolder, MergeOption.MERGE)
      result.logIfError('Error updating asset group')
    },
    setFolderQuery (context: AssetsContext, query: AssetFolderQuery|null) {
      const { commit } = assetsModuleActionContext(context)
      commit.setFolderQuery(query)
    },
    async cleanupSceneAssets (_context: AssetsContext) {
      const prefix = 'asset_'
      const runningKeys = studio.subscriptions.runningKeys(prefix)
      for (const id of runningKeys) {
        const cleanup = () => {
          store.commit.assets.removeFromSceneAsset(id.substr(prefix.length))
        }
        studio.subscriptions.unsubscribe(id, cleanup)
      }
    },
    async getAssetFolder (_context: AssetsContext, filters: {
      name: string,
      type: AssetGroupType,
      team_id: string
    }): Promise<AssetGroup|null> {
      const doc = await studio.db
        .collection(CollectionNames.ASSET_GROUPS)
        .where('name', '==', filters.name)
        .where('type', '==', filters.type)
        .where('team_id', '==', filters.team_id)
        .where('deleted', '==', false)
        .get()
      if (doc.isSuccess) return Object.values(doc.value)[0] ?? null
      else return null
    },
    async createAssetFolder (_context: AssetsContext, folder: NewAssetRequest) {
      const group = {
        timestamp: Date.now(),
        deleted: false,
        assets: {},
        ...folder
      }
      try {
        const docRef = await fb.db.collection(CollectionNames.ASSET_GROUPS).add(group)
        if (!!folder.cast_id) {
          await fb.db.collection(CollectionNames.CASTS).doc(folder.cast_id).set({
            asset_groups: {
              [docRef.id]: true
            }
          }, {
            merge: true
          })
        }
        return docRef.id
      } catch (error) {
        console.error('Error adding group: ', error)
        return null
      }
    },
    async createTwitterPoll (_context: AssetsContext, poll: TweetPollAsset) {
      const asset = {
        ...poll,
        deleted: false,
        stars: 0,
        question: 'Loading...',
        timestamp: (new Date()).getTime(),
      }

      try {
        const doc = await fb.db.collection(CollectionNames.ASSETS).add(asset)
        await fb.db.collection(CollectionNames.ASSETS).doc(doc.id).set({ id: doc.id }, { merge: true })
      } catch (error) {
        console.error('Error createTwitterPoll ', error)
      }

    },
    async createTweetAsset (_context: AssetsContext, tweet: TweetAsset): Promise<string> {
      const asset = {
        ...tweet,
        deleted: false,
        stars: 5,
        id: generateRandomString(20),
        timestamp: (new Date()).getTime(),
      }
      const result = await studio.db.collection(CollectionNames.ASSETS).doc(asset.id).set(asset, MergeOption.OVERWRITE)
      result.logIfError('Error creating tweet asset')
      return asset.id
    },
    async reloadWidget (_context: AssetsContext, widgetId: string) {
      await fb.db.collection(CollectionNames.ASSETS).doc(widgetId).set({
        reloadKey: (new Date()).getTime()
      }, { merge: true })
    },
    async createAsset (_context: AssetsContext, asset: Partial<Asset|VideoAsset>) {
      // @ts-ignore TS complains that some properties are not in Asset but they are in VideoAsset
      const newAsset: Asset|VideoAsset = {
        timestamp: Date.now(),
        stars: 0,
        deleted: false,
        ...asset
      }
      try {
        const assetDoc = await studio.db.collection(CollectionNames.ASSETS).add(newAsset)
        if (assetDoc.isFailure) {
          assetDoc.log(`Failed to add new asset ${newAsset}`)
          return null
        }
        const result = await studio.db.collection(CollectionNames.ASSETS).doc(assetDoc.value)
          .set({ id: assetDoc.value }, MergeOption.MERGE)
        result.logIfError(`Error updating asset id ${assetDoc.value}`)
        return assetDoc.value
      } catch (error) {
        console.error('Error creating asset: ', error)
        return null
      }
    },
    async createWidgetAsset (_context: AssetsContext, url: string): Promise<Result<WidgetAsset, 'database'>> {
      const widgetId = generateRandomString() // TODO: fix... very small chance of generating an existing id
      const newAsset: WidgetAsset = {
        id: widgetId,
        type: AssetType.Widget,
        url,
        stars: 0,
        asset_group: '',
        deleted: false,
        name: 'Weblink',
        team_id: store.state.team.activeTeam,
        timestamp: Date.now()
      }
      const result = await studio.db.collection(CollectionNames.ASSETS).doc(widgetId).set(newAsset, MergeOption.OVERWRITE)
      if (result.isFailure) return result.convert()
      return Result.success(newAsset)
    },
    async updateFolderName (_context: AssetsContext, folder: AssetGroup) {
      try {
        await fb.db.collection(CollectionNames.ASSET_GROUPS).doc(folder.id).set(folder, { merge: true })
      } catch (error) {
        console.log('Error updating folder name: ', error)
      }
    },
    async deleteAssetFolder (context: AssetsContext, { folder, deepDelete = true }: { folder: AssetGroup, deepDelete: boolean }) {
      const { commit, getters, dispatch } = assetsModuleActionContext(context)
      // we need team id to be able to enforce CloudCast Firestore rules
      // (= user is part of a specific team)
      let teamId = store.state.user.teamId
      if (teamId === null) {
        Result.fail('team_error', 'teamId is null')
        return
      }
      if (store.state.user.profile && store.state.user.profile.isSuperUser) {
        teamId = store.state.user.profile.currentTeam || teamId
      }
      const isSocialMediaFolder = !!folder && SocialMediaTypes.some(type => folder.type === type)
      const checkFolderAssets = isSocialMediaFolder === false
      commit.deleteFolder(folder.id)

      if (!deepDelete) {
        // folder should not have any nested folders or assets
        // allowed to be ANY assetGroup type (folder, handle, etc...)
        const subfolders = await studio.db.collection(CollectionNames.ASSET_GROUPS)
          .where('team_id', '==', teamId)
          .where('parent_id', '==', folder.id)
          .where('deleted', '==', false)
          .limit(1)
          .get()

        subfolders.logIfError('Error getting subfolders')
        if (subfolders.isSuccess && Object.keys(subfolders.value).length > 0) {
          throw new Error(AssetGroupErrors.FolderNotEmpty)
        }

        if (checkFolderAssets) {
          const assets = await studio.db.collection(CollectionNames.ASSETS)
                                        .where('team_id', '==', teamId)
                                        .where('asset_group', '==', folder.id)
                                        .where('deleted', '==', false)
                                        .limit(1)
                                        .get()
          assets.logIfError('Error getting assets')
          if (assets.isSuccess && Object.keys(assets.value).length > 0) {
            throw new Error(AssetGroupErrors.FolderNotEmpty)
          }
        }
      }

      try {
        if (deepDelete && checkFolderAssets) { //Delete assets & subfolders first
          const subfolders = await studio.db.collection(CollectionNames.ASSET_GROUPS)
                                            .where('team_id', '==', teamId)
                                            .where('parent_id', '==', folder.id)
                                            .where('deleted', '==', false)
                                            .get()
          subfolders.logIfError('Error getting subfolders')
          if (subfolders.isSuccess) {
            Object.values(subfolders.value).forEach((subfolder) => {
              dispatch.deleteAssetFolder({ folder: subfolder, deepDelete: true })
            })
          }

          const assets = await studio.db.collection(CollectionNames.ASSETS)
                                        .where('team_id', '==', teamId)
                                        .where('asset_group', '==', folder.id)
                                        .where('deleted', '==', false)
                                        .get()

          assets.logIfError('Error getting assets')
          if (assets.isSuccess) {
            Object.values(assets.value).forEach((asset) => { dispatch.deleteAsset(asset.id) })
          }
        }

        // Delete folder
        const activeFolder = getters.activeFolder
        const deletedFolder = await studio.db.collection(CollectionNames.ASSET_GROUPS)
                                             .doc(folder.id).set({ deleted: true }, MergeOption.MERGE)
        deletedFolder.logIfError('Error deleting folder')

        store.dispatch.castTemplates.cleanupTeamTemplates({ id: folder.id, itemType: 'asset_groups' })
        if (activeFolder && activeFolder.id === folder.id) {
          commit.selectFolder(activeFolder.parent_id || '')
        }
      } catch (error) {
        console.log('Error deleting folder: ', error)
      }
    },
    async deleteAsset (_context: AssetsContext, assetId: string) {
      const result = await studio.db.collection(CollectionNames.ASSETS).doc(assetId)
                                    .set({ deleted: true }, MergeOption.MERGE)
      result.logIfError('Error deleting asset')
    },
    async selectFolder (context: AssetsContext, id: string) {
      const assetgroups = store.state.assets.folderList
      const { commit } = assetsModuleActionContext(context)
      let selectedFolder = ''
      if (id !== '' && assetgroups[id] !== undefined) {
        selectedFolder = id
      }
      commit.selectFolder(selectedFolder)
    },
    async retranscodeClip (_context: AssetsContext, assetId: string) {
      const result = await studio.db.collection(CollectionNames.ASSETS).doc(assetId).set({
        // @ts-ignore   TODO: fix this
        progress: 0,
        state: 'transcoding'
      }, MergeOption.MERGE)
      result.logIfError('Error retranscoding clip')
    },
    unsubscribeAsset (context: AssetsContext, assetId: string) {
      const { state } = assetsModuleActionContext(context)
      if (!assetId) throw new Error('An asset id must be provided.')
      studio.subscriptions.unsubscribe(`casts_asset_${assetId}`, () => {
        if (state.subscribedAssets[assetId]) {
          delete state.subscribedAssets[assetId]
        }
      })
    },
    async subscribeAsset (context: AssetsContext, assetId: string) {
      if (!assetId) throw new Error('An asset id must be provided.')
      const { commit } = assetsModuleActionContext(context)
      const result = await studio.subscriptions.subscribe({
        key: `casts_asset_${assetId}`,
        query: studio.db.collection(CollectionNames.ASSETS).doc(assetId),
        callback: commit.updateSubscribedAsset,
      })
      result.logIfError(`casts_asset_${assetId}`)
    },
    async setupImportedTweetsFolder (_context: AssetsContext, castId: string): Promise<Result<string, 'team_error'|'database'>> {
      const teamId = store.state.user.teamId
      if (teamId === null) return Result.fail('team_error', 'teamId is null')
      const folderId = getHiddenImportedTweetsFolderId(castId)
      const doc = await studio.db
        .collection(CollectionNames.ASSET_GROUPS)
        .doc(folderId)
        .get()
      if (doc.isSuccess) return Result.success(folderId)
      const result = await studio.db
        .collection(CollectionNames.ASSET_GROUPS)
        .doc(folderId)
        .set({
          id: folderId,
          name: folderId,
          parent_id: '',
          type: AssetGroupType.Folder,
          team_id: teamId,
          deleted: false
        }, MergeOption.OVERWRITE)
      if (result.isFailure) return result.convert()
      result.logIfError(`Could not setup hidden folder: ${ folderId }`)
      return Result.success(folderId)
    },
    addUploadingAsset (context: AssetsContext, uploadingAsset: AssetManagerUpload) {
      const { commit } = assetsModuleActionContext(context)
      commit.addUploadingAsset(uploadingAsset)
    },
    removeUploadingAsset (context: AssetsContext, uploadingAsset: AssetManagerUpload) {
      const { commit } = assetsModuleActionContext(context)
      commit.removeUploadingAsset(uploadingAsset)
    }
  },
  mutations: {
    addUploadingAsset (state: AssetsState, { uploadingId, progress, folderId }: AssetManagerUpload) {
      state.uploadingAssets[folderId] = { [uploadingId]: progress }
    },
    removeUploadingAsset (state: AssetsState, { uploadingId, folderId }: AssetManagerUpload) {
      delete state.uploadingAssets[folderId][uploadingId]
    },
    updateSceneAsset (state: AssetsState, asset: Asset) {
      if (asset) {
        if (asset.data && typeof asset.data === 'string') {
          asset.data = JSON.parse(asset.data)
        }
        state.sceneAssets[asset.id] = asset
      }
    },
    addSceneAssetToLayout (state: AssetsState, asset: Asset) {
      state.sceneAssets[asset.id] = asset
    },
    assetsLoading (state: AssetsState, isLoading: boolean) {
      state.folderAssetsLoading = isLoading
    },
    removeFromSceneAsset (state: AssetsState, assetId: string) {
      delete state.sceneAssets[assetId]
    },
    clearTeamAssetGroups (state: AssetsState) {
      state.initializing = true
      state.selectedFolder = AssetGroupType.RootFolder
      state.assetGroupSubscribes = {}
      state.assetGroupAssets = {}
      state.assetGroupList = {}
      state.folderList = {}
    },
    updateTeamAssetGroups (state: AssetsState, assetGroups: KeyDict<AssetGroup>) {
      state.initializing = false
      const assetGroupsValues = Object.values(assetGroups)
      assetGroupsValues.forEach((assetGroup) => {
        lazyUpdate(state.folderList, assetGroup.id, assetGroup)
        if (state.folderList[assetGroup.id].deleted !== false) {
          delete state.folderList[assetGroup.id]
        }
      })
      if (assetGroupsValues.length === 0) {
        state.folderList = {}
      }
    },
    deleteFolder (state: AssetsState, assetGroupId: string) {
      if (state.folderList[assetGroupId] !== undefined) {
        delete state.folderList[assetGroupId]
      }
    },
    setFolderQuery (state: AssetsState, query: AssetFolderQuery|null) {
      state.folderQuery = query
    },
    updateAssetGroupQuery (state: AssetsState, assets: KeyDict<Asset>) {
      assetQueue = []
      startCheckAssetQueue()
      let assetGroupId = state.selectedFolder
      const assetIdsToKeep: KeyDict<boolean> = {}
      const assetsValues = Object.values(assets)
      if (assetsValues.length === 0) {
        // empty query - no more assets
        state.assetGroupAssets[assetGroupId] = {}
        state.assetGroupList[assetGroupId] = {}
      } else {
        assetsValues.forEach((asset: Asset, idx: number) => {
          if (typeof asset.data === 'string') {
            asset.data = JSON.parse(asset.data)
          }
          assetGroupId = asset.asset_group
          assetIdsToKeep[asset.id] = true
          if (!!assetGroupId && state.assetGroupAssets[assetGroupId] === undefined) {
            state.assetGroupAssets[asset.asset_group] = {}
            state.assetGroupList[asset.asset_group] = {}
          }
          const existingAsset = state.assetGroupAssets[assetGroupId][asset.id]
          if (existingAsset === undefined || !isEqual(existingAsset, asset)) {
            assetQueue.push({ data: asset, assetGroupId: assetGroupId, action: 'add' })

            // do at least one asset check to immediately signal some assets have been found
            // eg Filespace would otherwise show the "No assets..." text for a fraction of a second
            // skip this for social media assets (big updates freeze the UI)
            const firstMatch = idx === 0
            const isSocialMedia = (SocialMediaAssetTypes.some(type => asset.type === type))
            if (firstMatch && !isSocialMedia) {
              checkAssetQueue()
            }
          }
        })
      }

      if (assetGroupId) {
        for (const existingKey of Object.keys(state.assetGroupAssets[assetGroupId])) {
          if (assetIdsToKeep[existingKey]) continue
          assetQueue.push({ data: state.assetGroupAssets[assetGroupId][existingKey], assetGroupId, action: 'remove' })
        }
      }
      state.folderAssetsLoading = false
    },
    cleanUpAssetGroup (state: AssetsState, assetGroupId: string ) {
      state.assetGroupAssets[assetGroupId] = {}
      state.assetGroupList[assetGroupId] = {}
      stopCheckAssetQueue()
    },
    addAssetToGroup (state: AssetsState, item: { asset: Asset, assetGroupId: string }) {
      state.assetGroupAssets[item.assetGroupId][item.asset.id] = { ...item.asset }
      const assetSummary: AssetSummary = {
        id: item.asset.id,
        url: item.asset.url,
        name: item.asset.name,
        type: item.asset.type,
        timestamp: item.asset.timestamp,
        stars: item.asset.stars
      }
      if (item.asset.type === AssetType.Video) {
        const videoAsset = item.asset as VideoAsset
        assetSummary.state = videoAsset.state
      }
      state.assetGroupList[item.assetGroupId][assetSummary.id] = assetSummary
    },
    updateAssetInGroup (state: AssetsState, item: { asset: Asset, assetGroupId: string }) {
      const orig_keys = Object.keys(state.assetGroupAssets[item.assetGroupId][item.asset.id]) as ObjectKeys<Asset>
      const new_keys = Object.keys(item.asset) as ObjectKeys<Asset>
      for (const key of orig_keys) {
        if (!new_keys.includes(key)) {
          delete state.assetGroupAssets[item.assetGroupId][item.asset.id][key]
        }
      }
      for (const key of new_keys) {
        state.assetGroupAssets[item.assetGroupId][item.asset.id][key] = item.asset[key]
      }
      const assetSummary: AssetSummary = {
        id: item.asset.id,
        url: item.asset.url,
        name: item.asset.name,
        type: item.asset.type,
        timestamp: item.asset.timestamp,
        stars: item.asset.stars
      }
      if (item.asset.type === AssetType.Video) {
        const videoAsset = item.asset as VideoAsset
        assetSummary.state = videoAsset.state
      }
      const existingSummary = state.assetGroupList[item.assetGroupId][item.asset.id]
      if (!isEqual(assetSummary, existingSummary)) {
        state.assetGroupList[item.assetGroupId][item.asset.id] = assetSummary
      }
    },
    removeAssetInGroup (state: AssetsState, item: { assetId: string, assetGroupId: string }) {
      delete state.assetGroupAssets[item.assetGroupId][item.assetId]
      delete state.assetGroupList[item.assetGroupId][item.assetId]
    },
    selectFolder (state: AssetsState, id: string) {
      state.selectedFolder = id
      state.subFolders = {}
    },
    setSubFolders (state: AssetsState, assetGroups: KeyDict<AssetGroup>) {
      state.subFolders = assetGroups
      state.folderAssetsLoading = false
    },
    cleanUpSubFolders (state: AssetsState, _assetGroupId: string ) {
      state.subFolders = {}
    },
    clearOpenFolders (state: AssetsState) {
      state.openFolders = [AssetGroupType.RootFolder]
    },
    addOpenFolder (state: AssetsState, folder: string) {
      if (state.openFolders.length > 0 && state.openFolders[state.openFolders.length - 1] === folder) {
        // everythin is already optimal, dont need to add this
        return
      }
      state.openFolders.push(folder)
      // dont remove the first element as this is the rootfolder
      if (state.openFolders.length > MAX_CACHED_OPEN_FOLDERS) {
        state.openFolders.splice(1, 1)
      }
    },
    updateSubscribedAsset (state: AssetsState, asset: Asset) {
      if (!asset.id) {
        console.warn('Asset has no id', asset)
        return
      }
      state.subscribedAssets[asset.id] = asset
    }
  }
})

export default assetsModule
export const assetsModuleActionContext = (context: AssetsContext) => moduleActionContext(context, assetsModule)
