<template>
  <div>
    <NoTeamMessage v-if="showTeamMessage || userHasNoTeams" :showLogout="userLoggedIn" />
  </div>
</template>

<script lang="ts">
import Axios from 'axios'
import NoTeamMessage from '@/components/NoTeamMessage'
import fb from '@/firebase'
import helper from '@/helpers'
import abilitiesByRole, { AbilityRole, TeamRole, Ability, getAbilityRoleFromUserRole } from '@/abilitiesByRole'
import { updateAbilities } from '@/ability'
import { UserProfile } from '@/types/users'
import { UnsubscribeFunction } from '../../store/types'
import { KeyDict } from '@/types'
import studio, { CollectionNames } from '@/modules/database/utils'
import { defineComponent } from 'vue'
import useCurrentTeamRoles from '@/compositions/teamRoles'
import { useCurrentTeam } from '@/modules/teams/compositions'
import { isEqual } from 'lodash'
import { RenderMode } from '@/modules/scenes/types'
import { sleep } from '@/testing'
import { AuthPersistence } from '@/modules/database/utils/auth'

export default defineComponent({
  name: 'AuthManager',
  setup () {
    return {
      ...useCurrentTeamRoles(),
      ...useCurrentTeam()
    }
  },
  computed: {
    pathName (): null | string {
      return this.$route.name ?? null
    },
    currentUserId (): string|null {
      return this.$store.direct.state.user.id
    },
    userLoggedIn (): boolean {
      return this.$store.direct.state.user.userLoggedIn
    },
    userProfile (): UserProfile | null {
      return this.$store.direct.state.user.profile
    },
    cefEvent (): boolean {
      return this.$store.direct.state.events.cefEvent
    },
    activeTeam (): string {
      return this.$store.direct.state.team.activeTeam
    },
    teamRole (): string {
      return this.$store.direct.state.user.teamRole
    },
    teamCheck (): boolean | null {
      return this.$store.direct.state.user.teamCheck
    },
    userHasNoTeams (): boolean {
      return this.$store.direct.state.user.userHasNoTeams
    },
    showTeamMessage (): boolean {
      let status = false
      if (!this.cefEvent) {
        if (this.teamCheck === false && this.userLoggedIn) {
          status = true
        }
      }
      return status
    },
    loading (): boolean {
      return this.userLoggedIn && !this.teamLoaded && this.teamCheck === null
    },
    loaded (): boolean {
      return !!(this.userLoggedIn && this.teamLoaded && this.teamCheck)
    },
    defaultPageOnLogin (): string|null {
      return this.$store.direct.state.user.defaultPageOnLogin
    }
  },
  watch: {
    defaultPageOnLogin (newVal: string|null) {
      if ((['login', 'invite', 'home', 'events'].includes(this.pathName ?? '')) && newVal !== null && newVal !== this.pathName) {
        const url = new URL(window.location.href)
        const redirect = url.searchParams.get('redirect')
        if (redirect) {
          this.$router.push(redirect)
        } else {
          this.$router.push({ name: newVal })
        }
      }
    },
    activeTeam (newVal: string) {
      // if we switch teams get the new team members
      if (newVal !== '') {
        this.$store.direct.dispatch.team.unsubscribeCurrentTeams()
        this.$store.direct.dispatch.team.subscribeCurrentTeam(newVal)
      }
    },
    userLoggedIn (newVal: boolean) {
      if (newVal) {
        this.getUserProfile()
      } else {
        this.unsubscribe()
        this.$store.direct.dispatch.team.resetTeam()
        updateAbilities([])
      }
    },
    userProfile (newVal: UserProfile | null, oldVal: UserProfile | null) {
      if (this.currentUserId === null) return
      if (this.agentUpdated) return
      if (newVal === null) return
      const agent = helper.getAgent()
      // @ts-ignore
      agent.ipaddress = this.clientIpAddress
      if (newVal.agent === undefined || !isEqual(newVal.agent, agent)) {
        this.$store.direct.dispatch.user.updateProfile({ id: this.currentUserId, agent })
        this.agentUpdated = true
      }
      if (newVal !== null && oldVal !== null && newVal.role !== oldVal.role) {
        this.updateAbilities()
      }
    },
    teamLoaded (newVal: boolean) {
      if (newVal === true && !this.userProfile?.isSuperUser && this.currentUserId !== null) {
        const memberRole = this.$store.direct.getters.team.memberRole(this.currentUserId)
        if (memberRole !== null) {
          this.$store.direct.dispatch.user.setUserRoleOnTeam(memberRole)
          // TeamRole can now also be a teams_roles ID
          // needs to wait till team and rollen
          this.updateAbilities()
        }
      }
    },
    loaded (newVal: boolean, oldVal: boolean) {
      if (newVal === true && oldVal === false && !this.cefEvent) {
        this.$store.direct.dispatch.user.completeLogin()
      }
    },
    teamRoles: {
      handler (_newRoles: KeyDict<TeamRole>) {
        this.updateAbilities()
      },
      deep: true
    },
    teamRole () {
      this.updateAbilities()
    },
    pathName: {
      handler (name: string|null) {
        if (name !== null && !this.hasInit) {
          this.init()
        }
      },
      immediate: true
    }
  },
  beforeUnmount () {
    this.unsubscribe()
    this.$store.direct.dispatch.user.unsubscribeOnlineStatus()
  },
  data: () => ({
    unsubscribeFunction: null as null|UnsubscribeFunction,
    agentUpdated: false,
    clientIpAddress: '',
    hasInit: false
  }),
  methods: {
    async init () {
      if (this.hasInit) {
        console.warn('Auth Manager already init')
        return
      }
      await this.fetchClientIpAddress()
      this.hasInit = true

      // check to see if this is the cef version of the page on load
      if (this.pathName === 'cefEvent') {
        await this.$store.direct.dispatch.events.setCefEvent()
      }

      if (this.userLoggedIn === true) {
        this.getUserProfile()
      }

      if (!this.cefEvent) {
        fb.firebase.auth().onIdTokenChanged(user => {
          if (user === null) {
            if (this.userLoggedIn === true) {
              this.$store.direct.dispatch.user.resetUser()
            }
          } else if (this.userLoggedIn === false) {
            this.$store.direct.dispatch.user.signInUser(user).catch(error => {
              console.error('Sign in user Error', error)
            })
          }
        })
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')
        const cefMode = (urlParams.get('cef-mode') ?? urlParams.get('iosMode') ?? RenderMode.Full) as RenderMode
        const cefMixer = urlParams.get('mixer-id')
        this.$store.direct.dispatch.events.setCefMode(cefMode)
        if (cefMixer !== '' && cefMixer !== null) {
          this.$store.direct.dispatch.events.setCefMixer(cefMixer)
        }
        if (token) {
          const refreshToken = fb.functions.httpsCallable('refreshToken')
          let loggedIn = false
          let attempts = 0
          while (!loggedIn && attempts < 5) {
            try {
              const result = await refreshToken({ token: token })
              await fb.firebase.auth().setPersistence(AuthPersistence.Local)
              const fbuser = await fb.firebase.auth().signInWithCustomToken(result.data.token)
              if (fbuser.user === null) throw new Error('signInWithCustomToken returned a null user')
              await this.$store.direct.dispatch.user.signInUser(fbuser.user)
              this.getUserProfile()
              loggedIn = true
            } catch (error) {
              console.error(`Could not refresh token ${attempts}/5`, error)
              attempts += 1
              await sleep(2000)
            }
          }
          if (!loggedIn) {
            console.error('Unable to log in after 5 attempts')
          }
        }
      }
    },
    unsubscribe () {
      if (this.unsubscribeFunction !== null) {
        this.unsubscribeFunction()
        this.unsubscribeFunction = null
      }
    },
    getUserAbilityRole (role: string): string|undefined {
      for (const ability of Object.values(AbilityRole)) {
        if (role === ability) {
          return ability
        }
      }
      const teamrole: TeamRole|undefined = this.teamRoles[role]
      return teamrole?.name
    },
    getUserProfile () {
      this.unsubscribe()
      if (this.currentUserId === null) {
        console.error('AuthManager::cannot get profile of user with id null')
        return
      }
      // FIXME -> needs to move to the store
      const result = studio.db.collection(CollectionNames.PROFILES).doc(this.currentUserId).onSnapshot((profile) => {
        this.$store.direct.dispatch.user.setUserProfile(profile)
        this.$store.direct.dispatch.user.subscribeOnlineStatus()
        if (this.userLoggedIn && profile.isSuperUser) {
          const abilityRole = getAbilityRoleFromUserRole(profile.role)
          if (abilityRole !== null) updateAbilities(abilitiesByRole(abilityRole))
          else console.error('Invalid super user role', { userId: profile.id, role: profile.role })
        }
      })
      if (result.isSuccess) this.unsubscribeFunction = result.value
      else result.log('AuthManager::getUserProfile')
    },
    async fetchClientIpAddress () {
      /* Fetch IP info, expected response:
        someProp=someValue
        ip=clientIpAddress
        someOtherProp=someOtherValue
      */

      const response = await Axios.get('https://www.cloudflare.com/cdn-cgi/trace')
      const infoArr: string[]|null = response?.data ? response.data.split('\n') : null
      const ipString = infoArr ? infoArr.find((entry) => entry.startsWith('ip=')) : null
      this.clientIpAddress = ipString ? ipString.split('=')[1] : 'Fetching IP address had failed'
    },
    updateAbilities () {
      let abilities: Ability[]|undefined = undefined
      const teamRole = this.teamRoles[this.teamRole]
      if (teamRole) abilities = teamRole.toAbilities()
      if (Object.values(AbilityRole).includes(this.teamRole as AbilityRole)) {
        abilities = abilitiesByRole(this.teamRole as AbilityRole)
      }
      if (abilities !== undefined) updateAbilities(abilities)
    }
  },
  components: {
    NoTeamMessage
  }
})
</script>
