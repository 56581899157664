import WHEPClientJanus, { WHEPClientJanusOptions } from '@/classes/Janus/whep-janus'
import { VideoObjectNetworkStats } from './VideoObjectNetworkStats'
import { includes } from 'lodash'
import env from '@/env'
import { IceTransport } from '@/modules/janus/classes/IceCandidateEntry'

export interface JanusVideoObjectConfig {
  autoRestart?: boolean,
  debugSdp?: boolean,
  id: string,
  name: string,
  url: string,
  logNetwork: boolean,
  killStream?: JanusVideoObjectKillCallback,
  restartStream?: JanusVideoObjectRestartCallback,
  callback?: JanusVideoObjectOnStartCallback,
  streamid?: string,
  udpEnabled?: boolean,
  srcObject?: MediaStream|null,
  chinaSupport: boolean
}

class JanusVideoObject {
  id: string
  name: string
  url: string
  hostname: string
  udpEnabled: boolean = false
  streamId: string
  peerConnection: RTCPeerConnection|null = null
  whepClient: WHEPClientJanus
  srcObject: MediaStream|null = null
  connectionDead: boolean = false
  onKilledCallback?: JanusVideoObjectKillCallback
  onRestartCallback?: JanusVideoObjectRestartCallback
  onStartupCallback?: JanusVideoObjectOnStartCallback
  onLogCallback?: JanusVideoObjectLogCallback
  onNetworkStatsCallback?: JanusVideoObjectNetworkStatsCallback
  statsInterval: number|null = null
  restartInterval: number|null = null
  watchdogInterval: number|null = null
  networkstats: VideoObjectNetworkStats = new VideoObjectNetworkStats()
  bandwidthcounter: number = 0
  logNetwork: boolean = false
  requestRestartSent: boolean = false
  lastTimeRestartAttempt: number = Date.now()
  lastBytesReceived: number = 0
  lastTimeBytesReceived: number = Date.now()
  streamStarted: boolean = false
  autoRestart: boolean = true
  debugSdp: boolean = false

  constructor (config: JanusVideoObjectConfig) {
      this.id = config.id
      this.name = config.name
      this.url = config.url
      this.udpEnabled = config.udpEnabled ?? false
      this.streamId = config.streamid ?? ''
      this.hostname = new URL(this.url).hostname
      this.srcObject = config.srcObject ?? null
      this.logNetwork = config.logNetwork
      this.connectionDead = false
      this.debugSdp = config.debugSdp ?? false
      const whepClientOptions: Partial<WHEPClientJanusOptions> = {
        force_protocol: this.udpEnabled ? IceTransport.UDP : IceTransport.TCP
      }
      if (config.chinaSupport) {
        whepClientOptions.chinaIceCandidateIpList = env.chinaIceCandidateList
      }
      console.warn(`JanusVideoObject::Constructor starting ${this.name} to ${this.url} on ${this.srcObject?.id}`)
      this.whepClient = new WHEPClientJanus(whepClientOptions, this.debugSdp)
      this.onStartupCallback = config.callback
      this.onRestartCallback = config.restartStream
      this.onKilledCallback = config.killStream
      this.initWhep()
      this.startWatchDog()
      this.autoRestart = config.autoRestart ?? true
    }

    private _restartIntervals () {
      if (this.statsInterval) {
        window.clearInterval(this.statsInterval)
      }
      this.statsInterval = window.setInterval(() => {
        this.updateStats()
      }, 1000)

      if (this.restartInterval) {
        window.clearInterval(this.restartInterval)
      }
      this.restartInterval = window.setInterval(() => {
        this.checkForRestart()
      }, 1000)
    }

    private async initWhep () {
      const baseIceConfiguration: RTCConfiguration = {
        iceTransportPolicy: 'all',
            iceServers: [
            {
                    urls: [`turn:${this.hostname}:3478?transport=tcp`],
                    username: 'janususer',
                    credential: 'password'
                },
                {
                    urls: [`turn:${this.hostname}:80?transport=tcp`],
                    username: 'janususer',
                    credential: 'password'
                },
            ]
      } as RTCConfiguration
      try {
        this.peerConnection = new RTCPeerConnection(baseIceConfiguration)
        this.onRemoteTrack = this.onRemoteTrack.bind(this)
        this.peerConnection.ontrack = this.onRemoteTrack
        await this.whepClient.view(this.peerConnection, this.url)
      } catch (error) {
        console.warn(`Error creating Whep view for stream ${this.name}`, error)
        this.peerConnection?.close()
        this.peerConnection = null
      }
      this.requestRestartSent = false
      this.lastBytesReceived = 0
      this.lastTimeBytesReceived = Date.now()
      this.streamStarted = false
      this._restartIntervals()

    }
    private checkForRestart () {
      if (this.connectionDead === true) return
      const now = Date.now()
      if (this.peerConnection !== null && (now - this.lastTimeBytesReceived > 3000)) {
        const iceConnectionState = this.peerConnection.iceConnectionState
        if (['failed', 'checking', 'disconnected'].includes(iceConnectionState) && !this.requestRestartSent) {
          this.requestRestart()
        }
      }
      if (this.hasReceivedNewData()) {
        this.lastTimeBytesReceived = now
        this.streamStarted = true
      }
    }

    private requestRestart () {
      console.warn(`JanusVideoObject::requestRestart: Restart requested for ${this.name} ${this.srcObject?.id} ${this.url}`)
      if (!this.autoRestart) {
        this.connectionDead = true
      }
      this.srcObject = null
      if (this.onKilledCallback) {
        this.onKilledCallback(this)
      }
      if (!this.requestRestartSent) {
        this.cleanUp()
        if (this.onRestartCallback) this.onRestartCallback(this)
        this.requestRestartSent = true
        this.lastTimeRestartAttempt = Date.now()
        if (!this.connectionDead) this.initWhep()
        if (this.onLogCallback) this.onLogCallback(`${this.name} stream request to restart`)
      }
    }


    private watchDog () {
      if (this.connectionDead === true) return
        const now = Date.now()
        if (now - this.lastTimeBytesReceived > 15000 && !this.streamStarted) {
          console.warn(`Stream ${this.name} ${this.srcObject?.id} hasn't started after 15s, requesting to restart`)
          if (this.requestRestartSent && now - this.lastTimeRestartAttempt > 10000) {
            const restartRetryMsg = `Restart of ${this.name} didn't succeed after 10s, retrying restart`
            console.warn(restartRetryMsg)
            this.onLogCallback?.(restartRetryMsg)
            this.requestRestartSent = false
          }
          if (!this.requestRestartSent) this.requestRestart()
        }
        if (now - this.lastTimeBytesReceived > 10000 && this.streamStarted) {
          this.requestRestart()
        }
        if (now - this.lastTimeBytesReceived > 3000 && this.streamStarted) {
          if (this.onLogCallback && now - this.lastTimeBytesReceived < 4000) {
            this.onLogCallback(`${this.name} ${this.srcObject?.id} is down for more than 3 seconds`)
          }
          const delta = Math.floor((now - this.lastTimeBytesReceived) / 1000)
          console.warn(`Seems like stream ${this.name} is down for ${delta} seconds`)
        }
    }

    private startWatchDog () {
      if (this.watchdogInterval) {
        window.clearInterval(this.watchdogInterval)
      }
      this.watchdogInterval = window.setInterval(this.watchDog.bind(this), 1000)
    }

    private async updateStats () {
      if ((this.connectionDead || !this.peerConnection) && this.statsInterval) {
        window.clearInterval(this.statsInterval)
        this.statsInterval = null
      } else {
        const stats = await this.peerConnection?.getStats(null)
        if (stats) {
          this.parseStats(stats)
          if (this.networkstats.averageLatency > 2000) {
            console.warn(`Average latency of videoobject is > 2s (${this.networkstats.averageLatency})`)
          }
          if (this.networkstats.averageLatency > 5000) {
            this.requestRestart()
          }
          this.bandwidthcounter += 1
          if (this.bandwidthcounter % 10 === 0 && this.logNetwork) {
            if (this.onNetworkStatsCallback) {
              this.onNetworkStatsCallback(this.networkstats)
            }
          }
        }
      }
    }

    private parseStats (res: RTCStatsReport) {
      for (const result of res.values()) {
        this.networkstats.updateWithStats(result)
      }
    }

    private hasReceivedNewData () {
      const { video, audio } = this.networkstats
      const mediaBytesReceived = (video?.bytesreceived || 0) + (audio?.bytesreceived || 0)
      const bytesReceived = this.networkstats.bytesReceived || mediaBytesReceived
      const hasNewBytesReceived = this.lastBytesReceived !== bytesReceived
      //const hasNewBytesReceived = true
      this.lastBytesReceived = bytesReceived
      return hasNewBytesReceived
    }

    private onRemoteTrack (event: RTCTrackEvent) {
      const mediaStream = event.streams[0]
      const tracks = mediaStream.getTracks()
      if (!includes((tracks.map(track => track.kind)), 'audio')) {
        this.requestRestart()
        return
      }
      this.srcObject = mediaStream
      if (this.onStartupCallback) {
        this.onStartupCallback(this)
      }
    }

    public onLogs (callback: JanusVideoObjectLogCallback) {
      this.onLogCallback = callback
    }

    public onNetworkStats (callback: JanusVideoObjectNetworkStatsCallback) {
      this.onNetworkStatsCallback = callback
    }

    private cleanUpIntervals () {
      if (this.statsInterval) {
        window.clearInterval(this.statsInterval)
        this.statsInterval = null
      }
      if (this.restartInterval) {
        window.clearInterval(this.restartInterval)
        this.restartInterval = null
      }
      if (this.watchdogInterval) {
        window.clearInterval(this.watchdogInterval)
        this.watchdogInterval = null
      }
    }

    private cleanUp () {
      try {
        this.whepClient.stop()
      } catch (e) {
        console.error(e)
      } finally {
        this.peerConnection?.close()
        this.peerConnection = null
        this.cleanUpIntervals()
      }
    }

    public stopPlay () {
      if (this.connectionDead) return
      this.connectionDead = true
      console.warn(`Cleaning up ${this.name} with ${this.url}`)
      this.cleanUp()
      if (this.onKilledCallback) this.onKilledCallback(this)
    }
}
export default JanusVideoObject
export type JanusVideoObjectKillCallback = (VideoObject: JanusVideoObject) => void
export type JanusVideoObjectRestartCallback = (VideoObject: JanusVideoObject) => void
export type JanusVideoObjectOnStartCallback = (VideoObject: JanusVideoObject) => void
export type JanusVideoObjectNetworkStatsCallback = (VideoObjectNetworkStats: VideoObjectNetworkStats) => void
export type JanusVideoObjectLogCallback = (string: string) => void
