import { Loader, Result } from 'kiswe-ui'
import { MarkerType } from './Marker'
import { MarkerBase } from './MarkerBase'

export interface ScteMarkerParams {
  id: string,
  name: string,
  team_id: string,
  timestamp?: Date
}

export enum ScteSpliceNetworkIndicator {
  InPoint = 0,
  OutPoint = 1
}

export interface ScteSplice {
  splice_event_id: number,
  out_of_network_indicator: ScteSpliceNetworkIndicator,
  duration_flag: boolean,
  splice_immediate_flag: boolean,
  break_duration_auto_return: boolean,
  break_duration: number
}

export interface ScteDescriptor {
  identifier_str: string,
  segmentation_event_id: number,
  segmentation_type_id: ScteSegmentationType
}

export enum ScteSegmentationType {
  ProgramStart = 0x10,
  ProviderPlacementOpportunityStart = 0x34,
  ProviderPlacementOpportunityEnd = 0x35,
  DistributorAdvertisementStart = 0x32
}

export class ScteMarker extends MarkerBase<MarkerType.SCTE> {
  public name: string
  public splices: ScteSplice[] = []
  public descriptors: ScteDescriptor[] = []
  public team_id: string|null = null

  constructor (params: unknown) {
    const id = Loader.loadString(params, 'id')
    const timestamp = Loader.loadDate(params, 'timestamp', new Date())
    super(id, MarkerType.SCTE, timestamp)
    this.name = Loader.loadString(params, 'name')
    this.splices = Loader.loadArray<ScteSplice>(params, 'splices', [])
    this.descriptors = Loader.loadArray<ScteDescriptor>(params, 'descriptors', [])
    this.team_id = Loader.loadStringOrNull(params, 'team_id', null)
  }

  addSplice (splice: ScteSplice) {
    this.splices.push(splice)
    return this
  }

  addDescriptor (descriptor: ScteDescriptor) {
    this.descriptors.push(descriptor)
    return this
  }

  static load (params: unknown): Result<ScteMarker, 'invalid_params'|'unknown'> {
    try {
      return Result.success(new ScteMarker(params))
    } catch (error) {
      return Result.fromUnknownError('invalid_params', error)
    }
  }

  static create (params: ScteMarkerParams): Result<ScteMarker, 'invalid_params'|'unknown'> {
    return ScteMarker.load(params)
  }
}
