import store from '@/store'
import { UserProfile } from '@/types/users'
import { computed, onBeforeUnmount, watch } from 'vue'

const currentTeamMembers = computed(() => store.state.team.currentTeam)
const currentTeam = computed(() => store.state.team.team)
const teamLoaded = computed(() => store.state.team.teamLoaded)
const teamId = computed(() => store.state.team.team?.id ?? null)

const useCurrentTeam = () => {
  let subscribedTeamId: null|string = null

  watch(teamId, async (newVal, oldVal) => {
    if (newVal === subscribedTeamId) return
    if (oldVal && oldVal === subscribedTeamId) {
      subscribedTeamId = null
      await store.dispatch.team.unsubscribeCurrentTeamMembers(oldVal)
    }
    if (newVal) {
      subscribedTeamId = newVal
      await store.dispatch.team.subscribeCurrentTeamMembers(newVal)
    }
  }, { immediate: true })

  onBeforeUnmount(async () => {
    if (subscribedTeamId === null) return
    await store.dispatch.team.unsubscribeCurrentTeamMembers(subscribedTeamId)
    subscribedTeamId = null
  })

  const getProfile = (id: string): UserProfile|null => currentTeamMembers.value[id] ?? null

  return {
    currentTeamMembers,
    currentTeam,
    getProfile,
    teamLoaded,
    teamId
  }
}

export default useCurrentTeam
