import { UserMediaState } from '@/store/types'
import { ConnectStatus } from '@/types/usermedia'

const state: UserMediaState = {
  autoConnect: false,
  checkedResolution: 0,
  extraChinaWrtcServer: null,
  connectStatus: ConnectStatus.Disconnected,
  constraints: { video: true, audio: true },
  currentRate: 0,
  deleteOnCastCleanup: true,
  deviceList: null,
  forceChinaProxy: false,
  hasDeviceError: false,
  isConnected: false,
  isDetectingDevices: false,
  isModerator: false,
  isRunningSelftest: false,
  isSelfMuted: false,
  isSelfTestInSessionDone: false,
  isStreamReady: false,
  localCameraStream: null,
  localDesktopStream: null,
  permissions: { video: null, audio: null },
  remoteStreamSrcUrl: null,
  streamDocId: null,
  // streamType: null,
  supportCamera: true,
  supportedResolutions: [],
  wrtcServer: null,
  wrtcServerChinaProxy: null,
  wrtcServerChina: null
}

export default state
