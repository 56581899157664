import store from '@/store'
import { computed } from 'vue'

const currentUserId = computed(() => store.state.user.id)
const currentUserProfile = computed(() => store.state.user.profile)
const isLoggedIn = computed(() => store.state.user.userLoggedIn)

const useCurrentUserProfile = () => {
  return {
    currentUserId,
    currentUserProfile,
    isLoggedIn
  }
}

export default useCurrentUserProfile
