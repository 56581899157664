import { AbilityRole } from "./abilitiesByRole"
import store from "./store"
import { KeyDict } from "./types"

//Multiple function signatures & return types for different parameters
function roleNames (role: string): string;
function roleNames (): KeyDict<string>;

//Function implementation
function roleNames (role: string = ''): string|KeyDict<string> {
  const defaultNames: Record<AbilityRole, string> = {
    [AbilityRole.Admin]: 'Admin',
    [AbilityRole.Member]: 'Crew',
    [AbilityRole.Moderator]: 'Moderator',
    [AbilityRole.Analyst]: 'Analyst',
    [AbilityRole.GuestCaster]: 'Talent',
    [AbilityRole.Station]: 'Station',
    [AbilityRole.Developer]: 'Kiswe Developer',
    [AbilityRole.Operator]: 'Kiswe Operator',
    [AbilityRole.Sales]: 'Kiswe Sales',
    [AbilityRole.SuperUser]: 'Superuser'
  }
  const names: KeyDict<string> = { ...defaultNames }
  for (const [id, role] of Object.entries(store.state.abilities.teamRoles)) {
    names[id] = role.name
  }

  if (!role) {
    const internalRoles = [AbilityRole.SuperUser, AbilityRole.Sales, AbilityRole.Operator, AbilityRole.Developer]
    internalRoles.forEach((element) => { delete names[element] })
    return names
  }

  return names[role] ?? `Unknown (${role})`
}

export default roleNames
