import { KeyDict } from '@/types'
import { InvitedCaster } from '@/types/casts'
import { ModerationGuest } from '../classes/ModerationGuest'

export const MAX_PRELOADED_GUEST_STREAMS = 6
export const MODERATION_QUEUE_SUB_KEY = 'moderation_queue'

export type InvitedCasterModerationInfo = Pick<InvitedCaster, 'userId'|'triage'|'timestamp'> & { castId: string }

export interface ModerationQueueContextState {
  guests: KeyDict<ModerationGuest>
}

export enum UserPresenceStatus {
  Unknown = 'unknown',
  Online = 'online',
  Offline = 'offline'
}
