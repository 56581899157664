import { StreamChangeHandler } from '../streamChangeHandler'
import { isEqual } from 'lodash'
import store from '@/store'
import { Stream, StreamType } from '@/types/streams'

export class StreamChangeHandlerVariants extends StreamChangeHandler {
  handleChanges (oldStream: Stream|null, newStream: Stream): void {
    if (isEqual(oldStream?.variants, newStream.variants)) return
    if (newStream.type !== StreamType.Caster || !newStream.user) return
    const hasVariants = Object.keys(newStream.variants ?? {}).length > 0
    store.commit.status.updateVariants({ userId: newStream.user, hasVariants })
  }
}
