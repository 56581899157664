import { Team } from '@/modules/teams/classes'
import CheckFeature from '../checkfeature'
import { Feature } from '../featureFlags'

export default class RtspTranscodingFeature extends CheckFeature {

  override hasFeature (): boolean {
    return this.featureFlags.get(Feature.RTSP_TRANSCODING) ?? false
  }

  override teamHasFeature (_teamId: string): boolean {
    return false
  }

  override get description (): string {
    return `Cross continent casts will have preview streams that are transcoded from the rtsp outputs
            of the cast instead of the rtmp outputs. These rtsp outputs are therefore encoded in H264 instead of VP8.`
  }

  override assignTeamFeature (_team: Partial<Team>, _enabled: boolean) {
  }

  override updateTeamFeature (_teamId: string, _enabled: boolean) : void {
  }

  override isGlobalFeature () : boolean {
    return true
  }
}
