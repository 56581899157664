import { MixerTrackLabel } from '@/modules/audio/types'
import { Loader, Result } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CallTriage, CastModerator, CasterDeletionReason, InvitedCaster } from '@/types/casts'
import { pickBy } from 'lodash'
import { Mixin, StudioBaseMixin } from './base'

// eslint-disable-next-line max-lines-per-function
export function hasCasters<TBase extends StudioBaseMixin> (Base: TBase) {
  return class CastersClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      const casters = Loader.loadAndCast<KeyDict<InvitedCaster>>(arg[0], 'invited_casters', {})
      this.invited_casters = {}
      Object.entries(casters).forEach(([key, caster]) => {
        this.invited_casters[key] = new InvitedCaster(caster)
      })
      this.moderators = Loader.loadAndCast<KeyDict<CastModerator>>(arg[0], 'moderators', {})
      this.republishRequested = Loader.loadAndCast<KeyDict<boolean>>(arg[0], 'republishRequested', {})
    }
    invited_casters: KeyDict<InvitedCaster>
    moderators: KeyDict<CastModerator>
    republishRequested: KeyDict<boolean>
    // @ts-ignore
    private __hasCaster: true = true

    removeCaster (casterId: string, reason: CasterDeletionReason|null = null): Result<this, 'not_exists'> {
      if (!this.invited_casters[casterId]) return Result.success(this)
      this.invited_casters[casterId].deleted = true
      this.invited_casters[casterId].triage = CallTriage.Rejected
      if (reason !== null) this.invited_casters[casterId].deleted_reason = reason
      return Result.success(this)
    }

    addCaster (casterId: string, optional: Partial<Pick<InvitedCaster, 'anonymous'|'triage'|'audioTracks'>> = {}):
        Result<this, 'exists'> {
      const existingInvitedCaster = this.invited_casters[casterId]
      if (existingInvitedCaster && !existingInvitedCaster.deleted) return Result.fail('exists', 'caster already exists')
      this.invited_casters[casterId] = new InvitedCaster({
        userId: casterId,
        deleted: false,
        ...optional
      })
      return Result.success(this)
    }

    getInvitedCasterInAudioTrack (track: string) {
      return pickBy(this.invited_casters, (invitedCaster) => Object.keys(invitedCaster.audioTracks).includes(track))
    }

    setModerator (userId: string, connected: boolean = true): this {
      this.moderators[userId] = { connected }
      return this
    }

    getTracksOfCaster (casterId: string): MixerTrackLabel[] {
      const caster = this.invited_casters[casterId]
      if (caster === undefined) return []
      return Object.keys(caster.audioTracks) as MixerTrackLabel[]
    }

    isModerator (userId: string) {
      const moderator = this.moderators[userId]
      return moderator?.connected ?? false
    }

    isAnonymousCaster (userId: string) {
      return this.invited_casters[userId]?.anonymous === true
    }

    setCasterTriage (userId: string, triage: CallTriage) {
      if (this.invited_casters[userId] === undefined) return this
      this.invited_casters[userId].triage = triage
      return this
    }

    setCasterModerator (userId: string, moderatorId: string|null) {
      if (this.invited_casters[userId] === undefined) return this
      this.invited_casters[userId].moderator = moderatorId
      return this
    }

    setCasterStreamReady (casterId: string, currentUserId: string, isReady: boolean = true) {
      const invitedCaster = this.invited_casters[casterId]
      if (!invitedCaster) return this
      invitedCaster.isStreamReady[currentUserId] = isReady
      return this
    }
  }
}

export type HasCasters = Mixin<typeof hasCasters>

export const hasCastersMixin = (obj: unknown): obj is HasCasters => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasCaster' in obj) return true
  return false
}
