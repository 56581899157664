import { CastChangeHandler } from '../castChangeHandler'
import { isEqual } from 'lodash'
import store from '@/store'
import { StreamType } from '@/types/streams'
import { SystemStatus } from '@/types/ops'
import { KeyDict } from '@/types'
import { Cast } from '@/modules/casts/classes'

type CasterStreamsInfo = KeyDict<{ ngcvpStatus: SystemStatus, casterId: string, startTime: number|null }>

export class CastChangeHandlerSourceStreams extends CastChangeHandler {
  handleChanges (oldCast: Cast|null, newCast: Cast|null): void {
    if (isEqual(oldCast?.source_streams, newCast?.source_streams)) return
    const castId = newCast?.id
    if (!castId) return
    if (this.currentCastId !== null && this.currentCastId === castId) {
      store.commit.events.updateSourceStreams({ sourceStreams: newCast.source_streams, castId })
    }

    const oldCasterStreamsInfo = this.getCasterStreamsInfo(oldCast)
    const newCasterStreamsInfo = this.getCasterStreamsInfo(newCast)

    const allStreamIds = new Set<string>()
    Object.keys(oldCasterStreamsInfo).forEach(allStreamIds.add, allStreamIds)
    Object.keys(newCasterStreamsInfo).forEach(allStreamIds.add, allStreamIds)

    for (const streamId of allStreamIds) {
      const newCastStreamInfo = newCasterStreamsInfo[streamId]
      const oldCastStreamInfo = oldCasterStreamsInfo[streamId]
      if (newCastStreamInfo !== undefined) {
        if (isEqual(newCastStreamInfo, oldCastStreamInfo)) continue
        store.commit.status.updateNgcvp({
          userId: newCastStreamInfo.casterId,
          castId,
          ngcvpStatus: newCastStreamInfo.ngcvpStatus,
          startTime: newCastStreamInfo.startTime
        })
      } else { // Was in old cast but no longer in new cast.
        store.commit.status.updateNgcvp({
          userId: oldCastStreamInfo!.casterId,
          castId,
          ngcvpStatus: SystemStatus.NONE,
          startTime: null
        })
      }
    }
  }

  getCasterStreamsInfo (cast: Cast|null): CasterStreamsInfo {
    const casterStreamsInfo: CasterStreamsInfo = {}
    for (const [streamId, stream] of Object.entries(cast?.source_streams ?? {})) {
      if (stream.type === StreamType.Caster && stream.user_id !== undefined ) {
        casterStreamsInfo[streamId] = {
          ngcvpStatus: stream.ngcvp_status ?? SystemStatus.NONE,
          casterId: stream.user_id,
          startTime: stream.start_time ?? null
        }
      }
    }
    return casterStreamsInfo
  }
}
