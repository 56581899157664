<script lang="ts">
import {ICECandidate, StreamInfo} from '@/types/webrtc'
import {VideoObjectNetworkStats} from '@/classes/VideoObjectNetworkStats'
// @ts-ignore
import Network from 'network-js'
import { defineComponent } from 'vue'
import { useTranscodingRegions } from '@/modules/transcodingregions/compositions'
import { CHINA_INDEX_ICE_CANDIDATES } from '@/classes/VideoObject'
import { removeVpCodecs } from './sdpParser'
import { VideoCodec } from '@/modules/usermedia/types'
import { useBrowser } from '@/modules/dashboard/compositions'
import { CASTER_CHAOS_MODE } from '.'
import store from '@/store'

export default defineComponent({
  name: 'CasterCommunication',
  props: {
    streamToPlay: {
      type: String,
      required: true
    },
    wrtcServer: {
      type: String
    },
    localStream: {
      type: MediaStream,
      required: true
    },
    onAir: {
      type: Boolean,
      required: true,
      default: false
    },
    videoBitrate: {
      type: Number,
      required: false,
      default: 320
    },
    audioBitrate: {
      type: Number,
      required: false,
      default: 32
    },
    udpEnabled: {
      type: Boolean,
      default: false
    },
    videoCodec: {
      type: String as () => VideoCodec,
      default: VideoCodec.VP8
    }
  },
  setup () {
    return {
      ...useTranscodingRegions(),
      ...useBrowser()
    }
  },
  data: () => ({
    streamInfo: {
      applicationName: 'livetcp',
      streamName: '',
      sessionId: '[empty]'
    } as StreamInfo,
    description: null as RTCSessionDescriptionInit|null,
    iceCandidates: [] as ICECandidate[],
    wsConnection: null as WebSocket|null,
    peerConnection: null as RTCPeerConnection|null,
    negotiated: false,
    repeaterRetryCount: 0,
    nextICEIndex: 0,
    lastICESwitch: 0,
    networkStats: null as null|VideoObjectNetworkStats,
    net: null as null|Network,
    lastNetworkStatsEmitted: 0,
    statsInterval: null as null|NodeJS.Timeout,
    trackSenders: [] as RTCRtpSender[],
    chaosInterval: null as null|NodeJS.Timeout,
    selfCheckInterval: null as null|NodeJS.Timeout
  }),
  render: () => null as any,
  mounted () {
    this.restartStream()
    this.startSelfChecker()
    if (CASTER_CHAOS_MODE || this.$route.query.chaos === 'true') {
      this.startChaosMode()
    }
  },
  beforeUnmount () {
    this.stopCommunication()
    if (this.chaosInterval !== null) clearInterval(this.chaosInterval)
    if (this.selfCheckInterval !== null) clearInterval(this.selfCheckInterval)
  },
  watch: {
    localStream () {
      if (this.localStream && this.peerConnection && this.onAir) {
        this.sendPlaySendOffer()
      }
    },
    onAir (newVal) {
      if (!newVal) {
        this.stopCommunication()
      }
    }
  },
  created () {
    this.calculateUpload()
  },
  methods: {
    startSelfChecker () {
      let lastRestart = Date.now()
      this.selfCheckInterval = setInterval(() => {
        const connectionClosed = this.peerConnection?.connectionState === 'closed'
        const noAudio = this.peerConnection?.connectionState === 'connected' && this.networkStats?.encoder.audio_bitrate === 0
        // When we setup an audio only stream -> video_bitrate is undefined and therefore noVideo remains false
        const noVideo = this.peerConnection?.connectionState === 'connected' && this.networkStats?.encoder.video_bitrate === 0
        const needRestart = connectionClosed || noAudio || noVideo
        if (needRestart && lastRestart < Date.now() - 10000 && this.onAir) {
          lastRestart = Date.now()
          console.log('restarting own caster stream', { connectionClosed, noAudio, noVideo })
          store.dispatch.logs.addLog({
            level: 'info',
            message: 'restarting own caster stream',
            data: {
              connectionClosed,
              noAudio,
              noVideo
            }
          })
          this.restartStream()
        }
      }, 1000)
    },
    startChaosMode () {
      let stoppingCount = 0
      this.chaosInterval = setInterval(() => {
        console.warn('CHAOS HAPPENING, SABOTAGING THE CURRENT STREAM')
        if (this.peerConnection?.connectionState !== 'closed') {
          if (stoppingCount % 4 === 0) this.peerConnection?.close()
          if (stoppingCount % 4 === 1)  this.peerConnection?.removeTrack(this.trackSenders[0])
          if (stoppingCount % 4 === 2)  this.peerConnection?.removeTrack(this.trackSenders[1])
          if (stoppingCount % 4 === 3)  {
            this.peerConnection?.removeTrack(this.trackSenders[0])
            this.peerConnection?.removeTrack(this.trackSenders[1])
          }
          stoppingCount += 1
        }
      }, 15000)
    },
    wsOpen () {
      console.log('wsConnection.onopen')
      this.peerConnection = new RTCPeerConnection()
      this.negotiated = false
      if (this.localStream) {
        this.sendPlaySendOffer()
      }
    },
    restartStream () {
      this.stopCommunication()
      const port = this.isIos ? ':7443' : ''
    // const port = ':7443'
      const wsURL = `wss://${this.wrtcServer}${port}/webrtc-session.json`
      this.streamInfo.streamName = this.streamToPlay
      this.networkStats = new VideoObjectNetworkStats()
      console.log('Starting websocket', wsURL)
      this.wsConnection = new WebSocket(wsURL)
      this.wsConnection.onopen = this.wsOpen
      this.wsConnection.onmessage = this.wsMessage
      this.wsConnection.onclose = this.wsClose
      this.wsConnection.onerror = this.wsError
    },
    connectTracks () {
      this.trackSenders = []
      this.localStream.getTracks().forEach((track) => {
        if (this.peerConnection) {
          this.trackSenders.push(this.peerConnection.addTrack(track))
        }
      })
    },
    sendPlaySendOffer () {
      if (this.peerConnection) {
        this.connectTracks()
        if (!this.negotiated) {
          this.peerConnection.createOffer().then(this.ondescription).catch(this.errorWebrtcHandler)
        }
        this.negotiated = true
        console.log('sendPlaySendOffer:', this.streamInfo)
      }
    },
    async ondescription (description: RTCSessionDescriptionInit) {
      if (description.sdp) {

        const sdpPre = JSON.stringify(description.sdp)
        description.sdp = this.setMediaBitrates(description.sdp)
        description.sdp = description.sdp.replace('42001F', '42e01f')
        description.sdp = description.sdp.replace('42001f', '42e01f')
        description.sdp = description.sdp.replace('640032', '42e01f')
        description.sdp = description.sdp.replace('640c1f', '42e01f')

        const enhanceData = { audioBitrate: this.audioBitrate, videoBitrate: this.videoBitrate, videoFrameRate: 29.97 }
        if (this.videoCodec === VideoCodec.H264) {
          try {
            description.sdp = removeVpCodecs(description.sdp)
          } catch (error) {
            console.error('Was unable to remove vp8 codec from the sdp', error)
          }
        }
        description.sdp = this.enhanceSDP(description.sdp, enhanceData)
        const sdpPost = JSON.stringify(description.sdp)
        try {
          await this.$store.direct.dispatch.logs.logSDP({ sdpPre, sdpPost })
        } catch (error) {
          console.error('Was unable to log sdp', error)
        }
      }
      const data = {
        direction: 'publish',
        command: 'sendOffer',
        streamInfo: this.streamInfo,
        sdp: description
      }
      if (this.peerConnection) {
        this.peerConnection.setLocalDescription(description).then(() => {
          if (this.wsConnection) {
            this.wsConnection.send(JSON.stringify(data))
          }
        }).catch(error => {
          console.log('setDescription[publish]: error ', error)
        })
      }
    },
    setMediaBitrates (sdp: string): string {
      return this.setMediaBitrate(this.setMediaBitrate(sdp, 'video', this.videoBitrate), 'audio', this.audioBitrate)
    },
    setMediaBitrate (sdp: string, media: 'video' | 'audio', bitrate: number): string {
      const lines = sdp.split('\n')
      let line = -1
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].indexOf('m=' + media) === 0) {
          line = i
          break
        }
      }
      if (line === -1) {
        return sdp
      }
      // Pass the m line
      line++
      // Skip i and c lines
      while (lines[line].indexOf('i=') === 0 || lines[line].indexOf('c=') === 0) {
        line++
      }
      // If we're on a b line, replace it
      if (lines[line].indexOf('b') === 0) {
        lines[line] = 'b=AS:' + bitrate
        return lines.join('\n')
      }
      // Add a new b line
      let newLines = lines.slice(0, line)
      newLines.push('b=AS:' + bitrate)
      newLines = newLines.concat(lines.slice(line, lines.length))
      return newLines.join('\n')
    },
    errorWebrtcHandler (error: Event) {
      console.warn(`problem loading page - media webrtc handshaking issues: ${error}`)
    },
    wsMessage (evt: MessageEvent) {
      console.log('wsConnection.onmessage:', JSON.parse(evt.data))
      const msgJSON = JSON.parse(evt.data)
      const msgStatus = Number(msgJSON.status)

      if (msgStatus === 514) {
        // repeater stream not ready
        this.repeaterRetryCount++
        if (this.repeaterRetryCount < 10) {
          setTimeout(this.sendPlaySendOffer, 500)
        } else {
          console.log(`live stream repeater timeout: ${this.streamInfo.streamName}`)
          this.stopCommunication()
        }
      } else if (msgStatus !== 200) {
        this.stopCommunication()
      } else {
        const streamInfoResponse = msgJSON.streamInfo
        if (streamInfoResponse !== undefined) {
          this.streamInfo.sessionId = streamInfoResponse.sessionId
        }

        const sdpData = msgJSON.sdp
        if (sdpData && this.peerConnection) {
          console.log('sdp:', sdpData)
          this.peerConnection.setRemoteDescription(new RTCSessionDescription(sdpData)).catch(this.errorHandler)
        }
        this.iceCandidates = msgJSON.iceCandidates as ICECandidate[]
        if (this.iceCandidates && this.peerConnection) {
          let indexToLoad = 0
          let foundUDP = false
          if (this.udpEnabled) {
            for (let i = 0; i < this.iceCandidates.length; i++) {
              if (this.iceCandidates[i].candidate.indexOf('UDP') !== -1) {
                indexToLoad = i
                foundUDP = true
              }
            }
          }
          if (this.myRegion?.chinaProxy === true) {
            indexToLoad += CHINA_INDEX_ICE_CANDIDATES
            console.log('china requested, starting ice candidate at index', indexToLoad)
          }
          console.log('CasterCommunications iceCandidates: ', JSON.stringify(this.iceCandidates[indexToLoad]))
          this.peerConnection.addIceCandidate(new RTCIceCandidate(this.iceCandidates[indexToLoad])).catch(error => {
            console.error('CasterCommunication:wsMessage Could not add ICE candidate', error)
          })
          if (foundUDP) {
            this.nextICEIndex = 0
          } else {
            this.nextICEIndex = 1
          }
          this.lastICESwitch = new Date().getTime()
        }
        if (this.statsInterval) {
          clearInterval(this.statsInterval)
        }
        this.statsInterval = setInterval(() => {
          this.checkPeerConnection()
          this.updateStats()
        }, 1000)
      }
    },
    async updateStats () {
      if (this.peerConnection) {
        try {
          const statsReport = await this.peerConnection.getStats(null)
          this.parseStats(statsReport)
          if (this.lastNetworkStatsEmitted < Date.now() - 5000) {
            this.lastNetworkStatsEmitted = Date.now()
            this.$emit('onNetworkStats', this.networkStats)
          }
        } catch (error) {
          console.error('CasterCommunication.updateStats error ', error)
        }
      }
    },
    parseStats (res: RTCStatsReport) {
      for (const result of res.values()) {
        if (this.networkStats) {
          this.networkStats.updateWithStats(result)
        }
      }
    },
    checkPeerConnection () {
      try {
        if (this.peerConnection !== null) {
          const now = new Date().getTime()
          if (this.peerConnection.iceConnectionState === 'failed' || (this.peerConnection.iceConnectionState === 'checking' && now - this.lastICESwitch > 3000)) {
            if (now - this.lastICESwitch > 5000 && this.nextICEIndex < this.iceCandidates.length) {
              this.$store.direct.dispatch.logs.addLog({
                message: `Switching to ICE ${this.iceCandidates[this.nextICEIndex].candidate}`,
                level: 'info'
              })
              this.peerConnection.addIceCandidate(new RTCIceCandidate(this.iceCandidates[this.nextICEIndex])).catch(error => {
                console.error('CasterCommunication:checkPeerConnection Could not add ICE candidate', error)
              })
              this.lastICESwitch = now
              this.nextICEIndex += 1
            }
          }
        }
      } catch (e) {
        this.$store.direct.dispatch.logs.addLog({
          message: `Error Switching to ICE ${this.iceCandidates[this.nextICEIndex].candidate} : ${e}`,
          level: 'error'
        })
      }
    },
    stopCommunication () {
      if (this.peerConnection !== null) {
        this.peerConnection.close()
      }
      this.peerConnection = null

      if (this.wsConnection !== null) {
        // console.log('ABOUT TO CLOSE THE CONNECTION ON THE CASTER SOCKET!!!!!')
        this.wsConnection.close()
        this.wsConnection = null
        //console.log(this.wsConnection)
      }
    },
    gotDescription (description: RTCSessionDescriptionInit) {
      this.description = description
      if (this.peerConnection) {
        this.peerConnection.setLocalDescription(description).then(this.sendAnswer).catch(error => {
          console.error('set description error ', error)
        })
      }
    },
    enhanceSDP (sdpStr: string, enhanceData: { audioBitrate: number, videoBitrate: number, videoFrameRate: number }) {
      const sdpLines = sdpStr.split(/\r\n/)
      let sdpSection = 'header'
      let hitMID = false
      let sdpStrRet = ''
      const audioIndex = -1
      const videoIndex = -1

      for (const sdpIndex in sdpLines) {
        const sdpLine = sdpLines[sdpIndex]

        if (sdpLine.length <= 0) { continue }

        if (sdpLine.indexOf('m=audio') === 0 && audioIndex !== -1) {
          const audioMLines = sdpLine.split(' ')
          sdpStrRet += audioMLines[0] + ' ' + audioMLines[1] + ' ' + audioMLines[2] + ' ' + audioIndex + '\r\n'
          continue
        }

        if (sdpLine.indexOf('m=video') === 0 && videoIndex !== -1) {
          const audioMLines = sdpLine.split(' ')
          sdpStrRet += audioMLines[0] + ' ' + audioMLines[1] + ' ' + audioMLines[2] + ' ' + videoIndex + '\r\n'
          continue
        }

        sdpStrRet += sdpLine

        if (sdpLine.indexOf('m=audio') === 0) {
          sdpSection = 'audio'
          hitMID = false
        } else if (sdpLine.indexOf('m=video') === 0) {
          sdpSection = 'video'
          hitMID = false
        } else if (sdpLine.indexOf('a=rtpmap') === 0) {
          sdpSection = 'bandwidth'
          hitMID = false
        }

        if (sdpLine.indexOf('a=mid:') === 0 || sdpLine.indexOf('a=rtpmap') === 0) {
          if (!hitMID) {
            if ('audio'.localeCompare(sdpSection) === 0) {
              if (enhanceData.audioBitrate !== undefined) {
                sdpStrRet += '\r\nb=CT:' + (enhanceData.audioBitrate)
                sdpStrRet += '\r\nb=AS:' + (enhanceData.audioBitrate)
              }
              hitMID = true
            } else if ('video'.localeCompare(sdpSection) === 0) {
              if (enhanceData.videoBitrate !== undefined) {
                sdpStrRet += '\r\nb=CT:' + (enhanceData.videoBitrate + enhanceData.audioBitrate)
                sdpStrRet += '\r\nb=AS:' + (enhanceData.videoBitrate + enhanceData.audioBitrate)
                // sdpStrRet += '\r\nb=CT:' + (enhanceData.videoBitrate)
                // sdpStrRet += '\r\nb=AS:' + (enhanceData.videoBitrate)
                if (enhanceData.videoFrameRate !== undefined) {
                  sdpStrRet += '\r\na=framerate:' + enhanceData.videoFrameRate
                }
              }
              hitMID = true
            } else if ('bandwidth'.localeCompare(sdpSection) === 0) {
              const rtpmapID = this.getrtpMapID(sdpLine)
              if (rtpmapID !== null) {
                const match = rtpmapID[2].toLowerCase()
                if (('vp9'.localeCompare(match) === 0) || ('vp8'.localeCompare(match) === 0) || ('h264'.localeCompare(match) === 0) ||
                                ('red'.localeCompare(match) === 0) || ('ulpfec'.localeCompare(match) === 0) || ('rtx'.localeCompare(match) === 0)) {
                  if (enhanceData.videoBitrate !== undefined) {
                    sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' x-google-min-bitrate=' + (enhanceData.videoBitrate + enhanceData.audioBitrate) + ';x-google-max-bitrate=' + (enhanceData.videoBitrate + enhanceData.audioBitrate)
                    // sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' x-google-min-bitrate=' + (enhanceData.videoBitrate) + ';x-google-max-bitrate=' + (enhanceData.videoBitrate)
                  }
                }

                if (('opus'.localeCompare(match) === 0) || ('isac'.localeCompare(match) === 0) || ('g722'.localeCompare(match) === 0) || ('pcmu'.localeCompare(match) === 0) ||
                                    ('pcma'.localeCompare(match) === 0) || ('cn'.localeCompare(match) === 0)) {
                  if (enhanceData.audioBitrate !== undefined) {
                    // sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' x-google-min-bitrate=' + (enhanceData.audioBitrate) + ';x-google-max-bitrate=' + (enhanceData.audioBitrate)
                    sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' maxaveragebitrate=' + (enhanceData.audioBitrate * 1000)
                  }
                }
              }
            }
          }
        }
        sdpStrRet += '\r\n'
      }
      this.$store.direct.dispatch.logs.addLog({ message: 'Proposed SDP ', data: sdpStrRet, level: 'info' })
      return sdpStrRet
    },
    getrtpMapID (line: string) {
      const findid = new RegExp('a=rtpmap:(\\d+) (\\w+)/(\\d+)')
      const found = line.match(findid)
      return (found && found.length >= 3) ? found : null
    },
    sendAnswer () {
      this.$store.direct.dispatch.logs.addLog({
        message: 'Answer SDP ',
        data: this.description,
        level: 'info'
      })
      const data = {
        direction: 'play',
        command: 'sendResponse',
        streamInfo: this.streamInfo,
        sdp: this.description
      }
      if (this.wsConnection) {
        this.wsConnection.send(JSON.stringify(data))
      }
    },
    errorHandler (error: Error) {
      console.error(error)
    },
    wsClose () {
      // console.log('wsConnection.onclose')
      this.$emit('wsConnectionClosed')
      this.negotiated = false
      if (this.statsInterval) {
        clearInterval(this.statsInterval)
      }
    },
    wsError (evt: Event) {
      console.error('wsConnection.onerror: ', evt)
    },
    average (data: number[]) {
      const sum = data.reduce(function (sum, value) {
        return sum + value
      }, 0)
      const avg = sum / data.length
      return avg
    },
    standardDeviation (values: number[]) {
      const avg = this.average(values)
      const squareDiffs = values.map(function (value) {
        const diff = value - avg
        const sqrDiff = diff * diff
        return sqrDiff
      })
      const avgSquareDiff = this.average(squareDiffs)
      const stdDev = Math.sqrt(avgSquareDiff)
      return stdDev
    },
    calculateUpload () {
      const endpoint = `https://${this.wrtcServer}/benchmark/server.php`
      const netsettings = {
        endpoint,
        latency: {
          measures: 30,
          attemps: 1
        },
        upload: {
          delay: 1500,
          data: {
            // The amount of data to initially use.
            size: 0.1 * 1024 * 1024, // 0.1 MB
            // If the measure period can't reach the delay defined in the settings,
            // the data amount is multiplied by the following value.
            multiplier: 1.5
          }
        },
        download: {
          endpoint,
          delay: 1500,
          data: {
            // The amount of data to initially use.
            size: 0.1 * 1024 * 1024, // 0.1 MB
            // If the measure period can't reach the delay defined in the settings,
            // the data amount is multiplied by the following value.
            multiplier: 1.5
          }
        }
      }
      this.net = new Network(netsettings)
      let calculatedLatency = 0
      let jitter = 0
      this.net.latency.on('end', (averageLatency: number, allLatencies: number[]) => {
        calculatedLatency = averageLatency
        jitter = this.standardDeviation(allLatencies)
        this.net.upload.start()
      })
      this.net.upload
        .on('start', () => {})
        .on('restart', () => {})
        .on('end', (averageSpeed: number, allInstantSpeeds: number[]) => {
          if (this.networkStats) {
            this.networkStats.updateWithUploadSpeed(averageSpeed, allInstantSpeeds, calculatedLatency, jitter)
          }
          this.net.download
            .on('start', () => {})
            .on('restart', () => {})
            .on('end', (averageSpeed: number, _allInstantSpeeds: number[]) => {
              if (this.networkStats) {
                this.networkStats.updateWithDownloadSpeed(averageSpeed)
              }
              this.$emit('onNetworkStats', this.networkStats)
            }).start()
        })
      this.net.upload.start()
    }
  }
})
</script>
