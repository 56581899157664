import moment from 'moment'

export const capitalizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1)
export const wrapString = (value: string, char: string) => `${ char }${ value }${ char }`
export const isStringWrapped = (value: string, char: string) => value.startsWith(char) && value.endsWith(char)

export const snakeToCamel = (str: string) => {
  return str.toLowerCase().replace(/([_-][a-z])/g, (group: string) => {
    return group.toUpperCase().replace('-', '').replace('_', '')
  })
}

/**
 * NOTE: cleanHashtags, formatBytes, formatDateLegacy and maxLen
 * are copy/pasted from the Vue2 filters we used to have.
 */

export const cleanHashtags = (value: string): string => {
  if (!value) return ''
  // make sure hashtags have at least one space between
  // eg '#ABC#DEF' becomes ' #ABC #DEF'
  const reHashtag = /(#[^ #]*)/gi
  const reSpaces = /\s{2,}/
  return value.replace(reHashtag, ' $1').replace(reSpaces, ' ')
}

export const formatBytes = (bytes: number, decimals?: number, isKib: boolean = false): string => {
  if (bytes === 0) return '0 Bytes'
  if (Number.isFinite(bytes)) return 'Not a number'
  const k = isKib ? 1024 : 1000
  const dm = (decimals && !Number.isNaN(decimals) && decimals >= 0) ? decimals : 2
  const sizes = isKib
    ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB', 'BiB']
    : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const formatDateLegacy = (value: Date|string|number): string => {
  const DEFAULT_DATE_FORMAT = 'M/D/YYYY h:mm a'
  // Timestamp
  if (value instanceof Date) {
    return moment(value).format(DEFAULT_DATE_FORMAT)
  }

  if (typeof value === 'string') {
    const date = moment(value, 'ddd MMM DD YYYY HH:mm:ss Z')
    return date.format(DEFAULT_DATE_FORMAT)
  } else {
    return moment(value).format(DEFAULT_DATE_FORMAT)
  }
}

export const maxLen = (value: string, maxLength: number = 100, suffix: string = '...'): string => {
  if (value.length <= maxLength) return value
  return value.slice(0, maxLength - suffix.length) + suffix
}
