import { CastChangeHandler } from '../castChangeHandler'
import { isEqual } from 'lodash'
import store from '@/store'
import { Cast } from '@/modules/casts/classes'

export class CastChangeHandlerOutputStreams extends CastChangeHandler {
  handleChanges (oldCast: Cast|null, newCast: Cast|null): void {
    if (isEqual(oldCast?.output_streams, newCast?.output_streams) || !newCast?.output_streams) return
    if (!newCast.id || this.currentCastId === null || this.currentCastId !== newCast.id) return
    store.commit.events.updateOutputStreams(newCast.output_streams)
  }
}
