import store from '@/store'
import { WRTCServerType } from '@/types/admin'
import { computed, onMounted, onUnmounted } from 'vue'

const useCurrentCast = (castId?: string) => {
  const currentCast = computed(() => store.state.events.currentCast)
  const currentCastId = computed(() => currentCast.value?.id)
  const k360EventId = computed(() => currentCast.value?.k360_event_id ?? null)
  const previewSceneId = computed<string|null>(() => currentCast.value?.previewSceneId ?? null)
  const programSceneId = computed<string|null>(() => currentCast.value?.programSceneId ?? null)
  const isTempCast = computed(() => !!currentCast.value?.isTempCast)
  const hasJanus = computed(() => currentCast.value?.wrtcServerType === WRTCServerType.JANUS)

  if (castId !== undefined) {
    onMounted(async () => store.dispatch.events.subscribeCurrentCast(castId))
    onUnmounted(async () => store.dispatch.events.unsubscribeCurrentCast())
  }

  return {
    currentCast,
    currentCastId,
    hasJanus,
    isTempCast,
    k360EventId,
    previewSceneId,
    programSceneId
  }
}

export default useCurrentCast
