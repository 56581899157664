import { computed, onMounted } from 'vue'
import TeamBranding from '@/modules/teams/classes/TeamBranding'
import store from '@/store'
import useCurrentUserProfile from '@/modules/user/compositions/useCurrentUserProfile'
import fallbackBackgroundImage from '@/assets/background-kiswe-overlay.webp'
import { CssVariablesDict } from 'kiswe-ui'

const useTeamBranding = (selector: string|null = null) => {
  const { isLoggedIn } = useCurrentUserProfile()
  const currentTeam = computed(() => store.state.team.team)
  const tempBranding = computed(() => store.state.team.tempBranding)
  const branding = computed(() => new TeamBranding(currentTeam.value?.branding ?? tempBranding.value))
  const isBranded = computed(() => !!currentTeam.value?.branding || !!tempBranding.value)

  const isBrandingLoaded = computed(() => {
    if (isLoggedIn.value) return true
    else return !!tempBranding.value
  })

  const cssVariables = computed<CssVariablesDict>(() => {
    const bgImage = branding.value.backgroundImageUrl
      ? `url(${ branding.value.backgroundImageUrl })`
      : `url(${ fallbackBackgroundImage })`
    if (!isBranded.value) return { '--background-image': bgImage }
    return {
      '--background-image': bgImage,
      '--opacity': branding.value.backgroundImageUrl ? '0' : '0.85',
      '--font-default': branding.value.fontFamily ?? undefined,
      '--color-primary': branding.value.primaryColor ?? '',
      '--color-secondary': branding.value.secondaryColor ?? ''
    }
  })

  onMounted(() => {
    if (!isBranded.value || selector === null) return
    const elements = Object.values(document.querySelectorAll(selector)) as HTMLElement[]
    for (const el of elements) {
      el.style.setProperty('--color-primary', branding.value.primaryColor ?? '')
      el.style.setProperty('--color-secondary', branding.value.secondaryColor ?? '')
    }
  })

  return {
    branding,
    cssVariables,
    isBranded,
    isBrandingLoaded
  }
}

export default useTeamBranding
