/* eslint-disable max-lines */
import { useCurrentCast } from '@/modules/casts/compositions'
import useReplayObjects from './replayObjects'
import { IngestClipDoc, ReplayClip } from '../classes'
import { ClipEndAction, IngestClipStatus, IngestCommand, IngestStatus } from '../types'
import store from '@/store'
import useReplayIngestStatusWatcher from './replayIngestStatusWatcher'

const setIngestCommand = async (params: { ingestId: string, command: IngestCommand, clipId?: string }):
  Promise<void> =>
{
  if (!store.state.castReplays.ingests[params.ingestId])
    throw new Error(`Ingest command: ${params.command} failed. Ingest for playlist: ${params.ingestId} not found`)
  await store.dispatch.castReplays.setIngestCommand({ ingestId: params.ingestId, command: params.command,
  commandClipId: params.clipId })
}

// NOTE: ingestId is the same as the associated playlist's ID.
// Hence, playlistId can be passed where ever ingestId is needed
const useReplayIngests = () => {

  const { currentCastId } = useCurrentCast()
  const { replayIngests, replayPlaylists, recordedSources, replayClips } = useReplayObjects(currentCastId)
  const ingestStatusWatcher = useReplayIngestStatusWatcher()

  // Converts a replay clip to an IngestClipDoc object. Next clip action defaults to NEXT
  // Return null if clip or source not found in master list
  const getIngestClipFromReplayClip = (clip: ReplayClip, clipEndAction: ClipEndAction): IngestClipDoc|null => {
    const source = recordedSources.value[clip.sourceId]
    if (!source) return null
    return {
      id: clip.id,
      url: source.url,
      start: clip.start,
      end: clip.end,
      next_clip_action: clipEndAction
    }
  }

  // Returns a new IngestClipDoc array from the Replay Clip array. Sets next action to NEXT for all
  // Ignores clip with sourceIds not found in master clip list (prints warning)
  const getIngestClipsFromReplayClips = (clips: ReplayClip[], chainClips: boolean): IngestClipDoc[] => {
    const ingestClips: IngestClipDoc[] = []
    const clipEndAction = chainClips ? ClipEndAction.NEXT : ClipEndAction.LOOP
    for (const clip of clips) {
      const ingestClip = getIngestClipFromReplayClip(clip, clipEndAction)
      if (!ingestClip) {
        console.warn(`Clip ID: ${clip.sourceId} not found. Ignoring.`)
        continue
      }
      ingestClips.push(ingestClip)
    }
    return ingestClips
  }

  // Unlikely to be used directly by component as it does not update playlist
  const addClipsToIngest = async (params: { ingestId: string, clips: ReplayClip[] }): Promise<void> => {
    // Update clip list in replay ingest if ingest exists
    const replayIngest = replayIngests.value[params.ingestId]
    if (replayIngest) {
      const playlist = replayPlaylists.value[params.ingestId]
      const curIngestClips = replayIngest.playlist
      const newIngestClips = getIngestClipsFromReplayClips(params.clips, playlist.chainClips)
      const updatedIngestClips: IngestClipDoc[] = [...curIngestClips, ...newIngestClips]
      await store.dispatch.castReplays.updateIngestPlaylist({ ingestId: params.ingestId,
        clipList: updatedIngestClips })
    }
  }

  // Unlikely to be used directly by component as it does not update playlist
  const removeClipsFromIngest = async (params: { ingestId: string, clipIds: string[] }): Promise<void> => {
    const replayIngest = replayIngests.value[params.ingestId]
    if (replayIngest) {
      const curIngestClips = replayIngest.playlist
      if (!curIngestClips.some((clip) => params.clipIds.includes(clip.id))) return
      const updatedIngestClips = curIngestClips.filter((clip) => !params.clipIds.includes(clip.id))
      if (updatedIngestClips.length > 0) {
        updatedIngestClips[updatedIngestClips.length-1]!.next_clip_action =
          curIngestClips[curIngestClips.length-1].next_clip_action
      }
      await store.dispatch.castReplays.updateIngestPlaylist({ ingestId: params.ingestId,
        clipList: updatedIngestClips })
    }
  }

  // eslint-disable-next-line complexity
  // Unlikely to be used directly by component as it does not update playlist
  const updateClipsInIngests = async (params: { clips: ReplayClip[], removeFromPlaylistIds: string[] }):
    Promise<void> =>
  {
    const updatedClipMap = Object.fromEntries(params.clips.map((clip) => [clip.id, clip]))
    for (const [ingestId, ingest] of Object.entries(store.state.castReplays.ingests)) {
      const curIngestClips = ingest.playlist
      const updatedIngestClips: IngestClipDoc[] = []
      let ingestUpdated = false
      for (const clip of curIngestClips) {
        let updatedIngestClip = clip
        const updatedReplayClip = updatedClipMap[clip.id]
        if (updatedReplayClip) {
          // eslint-disable-next-line max-depth
          if (params.removeFromPlaylistIds.includes(ingestId)) {
            ingestUpdated = true
            continue
          }
          updatedIngestClip = getIngestClipFromReplayClip(updatedReplayClip, clip.next_clip_action) ?? clip
        }
        updatedIngestClips.push(updatedIngestClip)
        ingestUpdated = ingestUpdated || (updatedIngestClip !== clip)
      }
      if (ingestUpdated) {
        await store.dispatch.castReplays.updateIngestPlaylist({ ingestId, clipList: updatedIngestClips })
      }
    }
  }

  // Creates a new replay ingest from a playlist
  const createIngest = async (ingestId: string): Promise<void> => {
    const playlist = replayPlaylists.value[ingestId]
    if (!playlist) return
    const clips: ReplayClip[] = []
    for (const clipId of playlist.clipIdList) {
      const clip = replayClips.value[clipId]
      if (!clip) {
        console.error(`Failed to add clip: ${clipId} to replay ingest. Clip not found.`)
        continue
      }
      clips.push(clip)
    }
    const ingestClips = getIngestClipsFromReplayClips(clips, playlist.chainClips)
    await store.dispatch.castReplays.addReplayIngest({ ingestId, clipList: ingestClips })
  }

  // Stop ingest and delete the ingest when fully stopped on all machines.
  const deleteIngest = async (ingestId: string): Promise<void> => {
    await setIngestCommand({ ingestId, command: IngestCommand.STOP })
    ingestStatusWatcher.doOnIngestStatus({
      ingestId,
      condition: (ingestStatus) => (!ingestStatus.current_status ||
        ingestStatus.current_status === IngestStatus.STOPPED),
      action: async () => { await store.dispatch.castReplays.deleteReplayIngest(ingestId) },
      noStatusResult: true
    })
  }

  // Recreates ingest clips using master clip data list, but using a new reference list for order & selection
  // Use this to reorder, remove clips (not recommended to add), or change clip chaining setting
  // Unlikely to be used by component directly as it does not make corresponding changes in playlist
  const recreateIngest = async (params: { ingestId: string, refClipIds: string[], chainClips: boolean }):
    Promise<void> =>
  {
    const replayIngest = store.state.castReplays.ingests[params.ingestId]
    const clipEndAction = params.chainClips ? ClipEndAction.NEXT : ClipEndAction.LOOP
    if (replayIngest) {
      const newIngestClips: IngestClipDoc[] = []
      for (const clipId of params.refClipIds) {
        const ingestClip = getIngestClipFromReplayClip(replayClips.value[clipId], clipEndAction)
        if (ingestClip) newIngestClips.push(ingestClip)
        else console.error(`Ingest recreate error. Clip: ${clipId} not found. Skipping.`)
      }

      await store.dispatch.castReplays.updateIngestPlaylist({ ingestId: params.ingestId,
        clipList: newIngestClips })
    }
  }

  const getIngestStatus = (ingestId: string): IngestStatus|null => {
    const ingestStatuses = Object.values(replayIngests.value[ingestId]?.status ?? {})
    if (ingestStatuses.length < 2) return null //at least 2 machines - preview/program
    const status: IngestStatus|null = ingestStatuses[0].current_status
    for (let i = 1; i < ingestStatuses.length; i++) {
      if (status !== ingestStatuses[i].current_status) return null
    }
    return status
  }

  const getClipStatus = (ingestId: string, clipId: string): IngestClipStatus|null => {
    const ingestStatuses = Object.values(replayIngests.value[ingestId]?.status ?? {})
    if (ingestStatuses.length < 2) return null //at least 2 machines - preview/program
    const status: IngestClipStatus|null = ingestStatuses[0].replay_status[clipId]?.status ?? null
    for (let i = 1; i < ingestStatuses.length; i++) {
      if (status !== ingestStatuses[i].replay_status[clipId]?.status) return null
    }
    return status
  }

  const getCurrentClipId = (ingestId: string): string|null => {
    const ingestStatuses = Object.values(replayIngests.value[ingestId]?.status ?? {})
    if (ingestStatuses.length < 2) return null //at least 2 machines - preview/program
    return ingestStatuses[0].current_replay
  }

  const getCurrentClipPlayhead = (ingestId: string): number|null => {
    const ingestStatuses = Object.values(replayIngests.value[ingestId]?.status ?? {})
    if (ingestStatuses.length < 2) return null //at least 2 machines - preview/program
    return ingestStatuses[0].current_timestamp
  }

  return {
    addClipsToIngest,
    createIngest,
    deleteIngest,
    getCurrentClipId,
    getClipStatus,
    getCurrentClipPlayhead,
    getIngestStatus,
    recreateIngest,
    removeClipsFromIngest,
    setIngestCommand,
    updateClipsInIngests
  }
}

export default useReplayIngests
