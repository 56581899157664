import fb from '../../firebase'
import { ActionContext } from 'vuex'
import { RootState } from '../types'
import store, { moduleActionContext } from '..'
import { CollectionNames } from '@/modules/database/utils/collectionNames'
import { LinkPointer, PointersState } from '@/classes/LinkPointer/LinkPointer'
import { defineModule } from 'direct-vuex'
import { Cast } from '@/modules/casts/classes'
import studio from '@/modules/database/utils'
import { Result, ResultVoid } from 'kiswe-ui'
import { MergeOption } from '@/modules/database/utils/database'
import { KeyDict } from '@/types'

type PointersContext = ActionContext<PointersState, RootState>

// initial state
const state = {
  currentPointer: null,
  currentPointerId: ''
} as PointersState

// actions
const actions = {
  async subscribeCurrentEventPointer (context: PointersContext, eventId: string): Promise<void> {
    //Theoretically can end up with subs for different id's, consider finding a workaround for this
    if (!eventId) throw new Error(`Cannot subscribe to eventpointer with eventId ${eventId}`)
    try {
      const { commit } = pointersModuleActionContext(context)
      /* Ideal to use below instead of param, to avoid above scenario,
      but it's undefined since currentCast isn't set in Store for event dialogs */
      // const currentEventId = store.getters.events.currentCastID
      const result = await studio.subscriptions.subscribe({
        key: 'eventPointer',
        query: studio.db.collection(CollectionNames.POINTERS)
                        // @ts-ignore DB TS complains that RedirectLinkPointer doesn't have an eventId
                        .where('eventId', '==', eventId)
                        .limit(1),
        callback: commit.updateCurrentEventPointer
      })
      result.logIfError('Could not subscribe to event Pointers')
    } catch (error) {
      throw new Error(`Could not subscribe to event Pointers ${error}`)
    }
  },
  async unsubscribeCurrentEventPointer (_context: PointersContext): Promise<void> {
    studio.subscriptions.unsubscribe('eventPointer')
  },

  async getEventPointer (_context: PointersContext, pointerId: string): Promise<LinkPointer> {
    try {
      const result = await studio.db.collection(CollectionNames.POINTERS).doc(pointerId).get()
      if (result.isFailure) throw Error('pointers::getEventPointer:: pointerId does not exist in the db')
      return result.value
    } catch (error) {
      throw new Error(`Could not getEventPointer ${pointerId}: ${error}`)
    }
  },

  async getEventPointerId (_context: PointersContext, eventId: string): Promise<string|undefined> {
    //Retrieves the pointer data used for static event URLS, such as used for Olympics
    try {
      const result = await fb.db.collection(CollectionNames.POINTERS).where('team_id', '==', store.getters.user.teamId).where('eventId', '==', eventId).limit(1).get()
      return result.docs[0]?.id ?? ''
    } catch (error) {
      console.log('Error retrieving Event Pointer object from fb to get id', error)
      return undefined
    }
  },

  async getPointerForCastId (_context: PointersContext, params: { castId: string, teamId?: string }):
                             Promise<Result<{id: string, pointer: LinkPointer}, 'pointer'>> {
    const teamId = params.teamId ?? store.getters.user.teamId
    const castId = params.castId
    if (teamId === null) return Result.fail('pointer', 'No team id available')
    const result = await studio.db.collection(CollectionNames.POINTERS)
                                  .where('team_id', '==', teamId)
                                  // @ts-ignore DB TS complains that RedirectLinkPointer doesn't have an eventId
                                  .where('eventId', '==', castId)
                                  .limit(1).get()
    if (result.isFailure) return Result.fail('pointer', `Failed to retrieve pointer of cast ${castId}`)
    const pointerIds = Object.keys(result.value)
    if (pointerIds.length < 1) return Result.fail('pointer', `No pointer found for ${castId}`)
    const pointerId = pointerIds[0]
    const pointer = result.value[pointerId]
    if (pointer === undefined) return Result.fail('pointer', `Failed to create pointer for ${pointerId} of ${castId}`)
    return Result.success({ id: pointerId, pointer })
  },

  async getEventPointerUrl (context: PointersContext, params: { castId: string, isFixedRegion: boolean }): Promise<string|undefined> {
    const { dispatch } = pointersModuleActionContext(context)
    if (!params.castId) throw new Error('pointers::getEventPointerUrl:: No cast id provided.')
    try {
      const result = await dispatch.getPointerForCastId({ castId: params.castId })
      if (result.isFailure) throw new Error(`pointers::getEventPointerUrl:: ${result.message}`)
      if (!store.state.team.team) throw new Error('pointers::getEventPointerUrl:: Can\'t get pointer without team')
      const dnsOverrides = store.state.team.team.dnsOverrides
      return result.value.pointer.getPublicPointerUrl(result.value.id, dnsOverrides, params.isFixedRegion)
    } catch (error) {
      console.error('pointers::getEventPointerUrl:: Error retrieving Event Pointer object from database', error)
    }
    return undefined
  },

  async copyEventPointerUrl (_context: PointersContext, { cast, fallbackUrl }: { cast: Cast, fallbackUrl?: string }): Promise<void> {
    try {
      if (!cast.id) throw new Error('No event ID provided.')

      const result = await studio.db.collection(CollectionNames.POINTERS)
                                    .where('team_id', '==', store.getters.user.teamId ?? '')
                                    // @ts-ignore DB TS complains that RedirectLinkPointer doesn't have an eventId
                                    .where('eventId', '==', cast.id)
                                    .limit(1)
                                    .get()
      if (result.isFailure) throw new Error(result.message)
      const pointerEntries = Object.entries(result.value)
      if (pointerEntries.length === 0) {
        /* Fallback: Copy token url.
        For events scheduled before pointers feature was implemented, support copying legacy token url format.
        NOTE: This fallback is deprecated and should be removed once Studio v3.9 is fully phased out.
        /Remove 'fallbackUrl' prop too when this gets removed */

        if (fallbackUrl !== undefined) {
          // throw 'No link pointer found for this event ID!' //Unused until token url is deprecated
          navigator.clipboard.writeText(fallbackUrl)
        } else {
          throw new Error(`No link pointer found for id ${cast.id}!`)
        }
      } else {
        //Copy pointer url to clipboard
        const [pointerId, pointer] = pointerEntries[0]
        if (!pointer) throw new Error('pointers::copyEventPointerUrl:: Type of pointer does not exist')
        if (store.state.team.team === null) throw new Error('pointers::copyEventPointerUrl:: No team id available')
        const dnsOverrides = store.state.team.team.dnsOverrides
        const url = pointer.getPublicPointerUrl(pointerId, dnsOverrides, cast.regionalInfo.fixed)
        navigator.clipboard.writeText(url)
      }
    } catch (error) {
      console.log('Error retrieving Event Pointer object from database', error)
      throw error //Let caller handle error message displays in UI
    }
  },

  async deletePointer (_context: PointersContext, pointerId: string): Promise<void> {
    try {
      if (!pointerId) throw Error('No pointer string provided.')
      await fb.db.collection(CollectionNames.POINTERS).doc(pointerId).delete()
    } catch (error) {
      console.log(`Error deleting pointer '${pointerId}': `, error)
    }
  },

  async setPointerTarget (_context: PointersContext, { pointerId, pointer }: { pointerId: string, pointer: LinkPointer }): Promise<ResultVoid<'pointer'>> {
    if (!pointerId) return Result.fail('pointer', 'Invalid pointer id provided.')
    const result = await studio.db.collection(CollectionNames.POINTERS).doc(pointerId).set(pointer, MergeOption.UPDATE)
    if (result.isFailure) return result.mapError((error) => ['pointer', error])
    else return Result.ok()
  },

  async swapEventPointerTargets (context: PointersContext, cast: Cast) {
    // TODO: Wrap this in a transaction.
    try {
      //Swaps pointer targets of 2 events. Used for transferring over a static share link to an event's duplicate, as will be used for Olympics.
      const { dispatch } = pointersModuleActionContext(context)
      if (!cast.originalCast) throw new Error('Cannot swap pointers if cast has no originalCast')
      const originalCast = Cast.load(JSON.parse(cast.originalCast))
      if (originalCast.isFailure) throw new Error(`Cannot swap pointers: invalid originalCast; ${originalCast.message}`)
      // TODO: Returning the id and pointer object separately is somewhat cumbersome. Can't we store the id in the object?
      const originalPointerResult = await dispatch.getPointerForCastId({ castId: originalCast.value.id })
      if (originalPointerResult.isFailure) throw new Error('swapEventPointerTargets: originalPointerId is undefined')
      const castPointerResult = await dispatch.getPointerForCastId({ castId: cast.id })
      if (castPointerResult.isFailure) throw new Error('swapEventPointerTargets: castPointerId is undefined')
      const { id: originalPointerId, pointer: originalPointer } = originalPointerResult.value
      const { id: castPointerId, pointer: castPointer } = castPointerResult.value
      //Replace original event's pointer
      let result = await dispatch.setPointerTarget({ pointerId: originalPointerId, pointer: castPointer })
      result.logIfError('Failed to assign pointer data to original pointer')
      //Replace copy's pointer
      result = await dispatch.setPointerTarget({ pointerId: castPointerId, pointer: originalPointer })
      result.logIfError('Failed to assign original pointer data to pointer')
    } catch (error) {
      console.error('Event pointer swap failed for castId: ', cast.id, error)
      throw error
    }
    //TODO: Add UI output of action result status?
  }
}

// mutations
const mutations = {
  updateCurrentEventPointer (state: PointersState, pointers: KeyDict<LinkPointer>): void {
    Object.entries(pointers).forEach(([pointerId, pointer]) => {
      state.currentPointer = pointer
      state.currentPointerId = pointerId
    })
  }
}

const pointersModule = defineModule({
  namespaced: true,
  state,
  actions,
  mutations
})

export default pointersModule
export const pointersModuleActionContext = (context: PointersContext) => moduleActionContext(context, pointersModule)
