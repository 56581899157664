import { moduleActionContext } from '../..'
import { defineModule } from 'direct-vuex'
import { actions, AudioStateContext } from './audio.actions'
import { state } from './audio.state'
import { mutations } from './audio.mutations'
import { getters } from './audio.getters'

const audioModule = defineModule({
  namespaced: true,
  actions,
  getters,
  mutations,
  state
})

export default audioModule
export const audioModuleActionContext = (context: AudioStateContext) => moduleActionContext(context, audioModule)

