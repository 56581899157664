import { Loader } from 'kiswe-ui'
import { KeyDict } from '@/types'
import { CastProperty, CastPropertyAudioType, CastPropertyType } from '@/types/admin'
import { GConstructor, Mixin } from './base'
import { HasQuality } from './hasQuality'
import { HasRegion } from './hasRegion'
import { CastType } from '@/types/casts'

const MACHINE_TYPE_DEFAULT = 'c5_24xlarge'

const getAudioType = (withQuality: HasQuality, castProperties: KeyDict<CastProperty>) => {
  const qualityCastProperty = castProperties[withQuality.quality]
  if (qualityCastProperty?.type === CastPropertyType.Quality && qualityCastProperty.audioType) {
    return qualityCastProperty.audioType
  }
  return CastPropertyAudioType.Stereo
}

const getQualityPresets = (withQuality: HasQuality, castProperties: KeyDict<CastProperty>) => {
  let qualityPresets: string[] = []

  if (withQuality.quality !== null) {
    const properties = castProperties[withQuality.quality]
    if (properties !== undefined) qualityPresets = [...qualityPresets, ...properties.presets]
  }
  if (withQuality.framerate !== null) {
    const properties = castProperties[withQuality.framerate]
    if (properties !== undefined) qualityPresets = [...qualityPresets, ...properties.presets]
  }
  return qualityPresets
}

const getRegionPresets = (withRegion: HasRegion, castProperties: KeyDict<CastProperty>) => {
  return castProperties[withRegion.region]?.presets ?? []
}

const getRegionBackupPresets = (withRegion: HasRegion, castProperties: KeyDict<CastProperty>) => {
  if (!withRegion.regionBackup) return []
  return castProperties[withRegion.regionBackup]?.presets ?? []
}

const getMixerConfigPresets = (castType: CastType|undefined) => {
  if (castType === CastType.StationSwitcher) return ['feeds_studio_station']
  return ['feeds_cloudcastv3']
}

const getMixerAudioTracks = (
  mixerAudioTrackCount: Number, castType: CastType|undefined, audioType: CastPropertyAudioType
) => {
  if (castType === CastType.StationSwitcher) return 'mixer_audio_tracks_obs_4'
  let channelSuffix = ''
  let trackCount = mixerAudioTrackCount
  if (audioType === CastPropertyAudioType.Surround51) {
    channelSuffix = '_6channel'
    trackCount = 1
  }
  return `mixer_audio_tracks_${trackCount}-multi-quality${channelSuffix}`
}

const getMachinePresets = (castProperties: KeyDict<CastProperty>, options: CalculatePresetsOptions) => {
  const machineType = options.machineType ?? MACHINE_TYPE_DEFAULT
  return castProperties[machineType]?.presets ?? []
}

export interface HasPresetsProps {
  castType: CastType
  mixerAudioTrackCount: number
  qualityOverride: string[]
}

export interface CalculatePresetsOptions {
  backup?: boolean,
  machineType?: string,
  ngcvpVersion: string|null
}

// eslint-disable-next-line max-lines-per-function
export function hasPresets<TBase extends GConstructor<HasRegion> & GConstructor<HasQuality>
                          > (Base: TBase, props: Partial<HasPresetsProps> = {}) {
  return class hasPresetsClass extends Base {
    constructor (...arg: any[]) {
      super(...arg)
      this.presets = Loader.loadAndCast(arg[0], 'presets', [])
      this.presetsBackup = Loader.loadAndCast(arg[0], 'presetsBackup', [])
    }

    presets: string[]
    presetsBackup: string[]

    calculatePresets (castProperties: KeyDict<CastProperty>, options: CalculatePresetsOptions) {
      let newPresets = getMixerConfigPresets(props.castType)
      newPresets = [...newPresets, ...getMachinePresets(castProperties, options)]
      const qualityPresets = props.qualityOverride ? props.qualityOverride : getQualityPresets(this, castProperties)
      newPresets = [...newPresets, ...qualityPresets]
      if (options.backup) {
        newPresets = [...newPresets, ...getRegionBackupPresets(this, castProperties)]
      } else {
        newPresets = [...newPresets, ...getRegionPresets(this, castProperties)]
      }
      if (options.ngcvpVersion !== null) newPresets = [...newPresets, options.ngcvpVersion]
      const audioType = getAudioType(this, castProperties)
      const mixerAudioTracks = getMixerAudioTracks(props.mixerAudioTrackCount ?? 16, props.castType, audioType)
      newPresets = [...newPresets, mixerAudioTracks]
      if (options.backup) {
        this.presetsBackup = newPresets
      } else {
        this.presets = newPresets
      }
      return this
    }
  }
}

export type HasPresets = Mixin<typeof hasPresets>

