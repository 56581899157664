import { TeamState } from '@/store/types'
import { Layout } from '@/types/layouts'
import { UserProfile } from '@/types/users'
import { MemberInvite, TeamGroup } from '@/types/teams'
import { Team, TeamBranding } from '@/modules/teams/classes'
import { Stream, StreamType, DefaultStream, StreamProtocol } from '@/types/streams'
import { CastProperty } from '@/types/admin'
import { WarningMessage } from '@/classes/Admin/WarningMessage'
import { KeyDict } from '@/types'
import firebase from 'firebase/compat/app'
import { BillingSummary } from '@/modules/billing/types'
import store from '@/store'
import { lazyUpdate } from '@/store/storehelper'
import { APIToken } from '@/modules/api/utils'
import { updateFeaturesTeamInfo } from '@/featureFlags/featureFlags'
import { pickBy } from 'lodash'
import { StreamChangeHandlerFactory } from '@/modules/streams/utils/streamChangeHandlerFactory'

const streamChangeHandlers = StreamChangeHandlerFactory.getChangeHandlers()

const mutations = {
  setActiveTeam (state: TeamState, teamId: string) {
    state.activeTeam = teamId
    updateFeaturesTeamInfo(state.allTeams, teamId)
  },
  setTeam (state: TeamState, team: Team) {
    state.team = team
    if (Object.values(state.allTeams).length === 0) {
      updateFeaturesTeamInfo({ [team.id]: team}, state.activeTeam)
    } else {
      updateFeaturesTeamInfo(state.allTeams, state.activeTeam)
    }
    state.isFeatureLoaded = true
  },
  setTeamMembers (state: TeamState, members: KeyDict<UserProfile>) {
    // FIXME: nicer way to distinguish between anonymous and regular users
    const existingMembers = pickBy(members, (_, id) => state.team?.members[id]?.role)
    lazyUpdate(state, 'currentTeam', existingMembers)
    state.teamLoaded = true
  },
  updateTeamMemberCount (state: TeamState) {
    // TODO -> does not have to be here? What is it for?
    state.teamSize = state.teamSize + 1
  },
  setTeamDefaultLayout (state: TeamState, layout: string) {
    state.defaultTeamLayout = layout
  },
  setTeamLayouts (state: TeamState, layouts: Layout[]) {
    state.teamLayouts = layouts
  },
  setTeamGroups (state: TeamState, groups: TeamGroup[]) {
    state.teamGroups = groups
  },
  clearTeamMembers (state: TeamState) {
    state.teamLoaded = false
    state.currentTeam = {}
  },
  setAllTeams (state: TeamState, teams: KeyDict<Team>) {
    state.allTeams = {}
    Object.entries(teams).forEach(([teamId, team]) => {
      if (!team.version && store.state.admin.release !== null) team.version = store.state.admin.release.default
      state.allTeams[teamId] = { ...team, id: teamId }
    })
    updateFeaturesTeamInfo(state.allTeams, state.activeTeam)
  },
  clearAllTeams (state: TeamState) {
    state.allTeams = {}
    let teams: KeyDict<Team> = {}
    if (state.activeTeam && state.team) teams = { [state.activeTeam]: state.team }
    updateFeaturesTeamInfo(teams, state.activeTeam)
  },
  setInvites (state: TeamState, snap: firebase.firestore.QuerySnapshot) {
    const invites: MemberInvite[] = []
    snap.docs.forEach(invite => {
      const data = invite.data() as MemberInvite
      invites.push({...data, id: invite.id })
    })
    state.invites = invites
  },
  setTeamStreams (state: TeamState, streams: KeyDict<Stream>) {
    const validTypes = [StreamType.Broadcast, StreamType.Output, StreamType.ScreenShare, StreamType.StationOutput]
    const validStreams = pickBy(streams, (stream) => validTypes.includes(stream.type) && stream.deleted !== true)
    Object.entries(validStreams).forEach(([id, stream]) => {
      stream.id = id
      // TODO: This should be done in a Stream object constructor instead
      if (stream.protocol === undefined) stream.protocol = StreamProtocol.Rtmp
      const oldStream = state.streams[id] ?? null
      streamChangeHandlers.forEach((handler) => handler.handleChanges(oldStream, stream))
    })
    state.streams = validStreams
    store.commit.events.setStreamNames({ teamStreams: state.streams })
  },
  setTeamStreamTeamId (state: TeamState, teamId: string|null) {
    state.streamsTeamId = teamId
  },
  setCastProperties (state: TeamState, castProperties: KeyDict<CastProperty>) {
    state.castProperties = castProperties
  },
  setDefaultStreams (state: TeamState, streams: DefaultStream[]) {
    state.defaultStreams = streams
  },
  setTeamFeatureLoaded (state: TeamState, loaded: boolean) {
    state.isFeatureLoaded = loaded
  },
  setWarningMessages (state: TeamState, doc: firebase.firestore.DocumentSnapshot) {
    const messages = doc.data() as KeyDict<WarningMessage>
    state.warningMessages = messages
  },
  clearTeamStreams (state: TeamState) {
    state.streams = {}
  },
  resetTeam (state: TeamState) {
    state.teamLoaded = false
    state.activeTeam = ''
    state.team = new Team()
    state.teamSize = 0
    state.version = ''
    state.currentTeam = {}
  },
  updateBilling (state: TeamState, snap: firebase.firestore.DocumentSnapshot) {
    let summary: BillingSummary = {
      last_update: undefined,
      months: {}
    }
    if (snap.exists) {
      summary = snap.data() as BillingSummary
      state.billing.summary = summary
    } else {
      console.log('updateBilling does not exist')
      state.billing.summary = summary
    }
    state.billing.loaded = true
  },
  updateTestBilling (state: TeamState, summary: BillingSummary) {
    state.billing.summary = summary
    state.billing.loaded = true
  },
  removeBilling (state: TeamState) {
    state.billing.summary = {}
    state.billing.loaded = false
  },
  setCustomTheme (state: TeamState, customTheme: string|null) {
    state.customTheme = customTheme
  },
  updateAPITokens (state: TeamState, snap: firebase.firestore.QuerySnapshot) {
    snap.forEach(doc => {
      const token = new APIToken(doc.data())
      state.apiTokens[doc.id] = token
    })
  },
  setTempTeamBranding (state: TeamState, branding: TeamBranding|null) {
    state.tempBranding = branding
  }
}

export default mutations
