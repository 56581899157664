import { NetworkstatsState } from './networkstats.state'
import { getters } from './networkstats.getters'
import { mutations } from './networkstats.mutations'
import { actions } from './networkstats.actions'
// import { UserNetworkStats } from '@/types/networkstats'
// import { KeyDict } from '@/types'
import { moduleActionContext } from '../..'
import { ActionContext } from 'vuex'
import { RootState } from '@/store/types'
import { defineModule } from 'direct-vuex'

export type NetworkStateContext = ActionContext<NetworkstatsState, RootState>

// initial state
const initialState: NetworkstatsState = new NetworkstatsState()

const networkStatsModule = defineModule({
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
})

export default networkStatsModule
export const networkStatsModuleActionContext = (context: NetworkStateContext) => moduleActionContext(context, networkStatsModule)
