/* eslint-disable max-lines */
import { Loader, Result } from 'kiswe-ui'
import { HexCode } from 'kiswe-ui'
import { generateRandomString } from '@/modules/common/utils'
import { BoxTypesInfo, HIDDEN_GLOBAL_PRESETS, SceneContentType, SceneSourceType } from '@/modules/scenes/types'
import { getGlobalLayerIds } from '@/modules/scenes/utils/globalPresets'
import { createFullscreenBox, getBoxTypeInfoForSceneSourceType } from '@/modules/scenes/utils/sceneBoxes'
import { KeyDict } from '@/types'
import { Asset, AssetType } from '@/types/assets'
import { Layout, LayoutBox, LayoutBoxField } from '@/types/layouts'
import {
  SceneContent, SceneContentCast, SceneContentGlobalLayer, isSceneContentCast, isSceneContentTransition
} from '@/types/sceneContent'
import { Scene } from '@/types/scenes'
import { UserProfile } from '@/types/users'
import { ClipEndAction } from '@/types/videoplayer'
import { cloneDeep, merge, pickBy, uniq } from 'lodash'
import { GConstructor, Mixin, StudioBaseMixin } from './base'
import { ScenePreset, ScenesSettings } from '@/modules/scenes/classes'
import {
  getHighestContentOrder, getZIndex, moveContentUp, removeGlobalStingerIfLocalStinger
} from '@/modules/scenes/utils/sceneContents'
import { UpdateFunction } from '@/modules/base/types'
import { createScene } from './hasInputs/fullscreenScene'
import { hasCastPropertyBoxes } from '@/modules/layouts/utils'
import { ANONYMOUS_GUEST_ID_PLACEHOLDER_PREFIX } from '@/modules/casters/types/anonymousguests'

export interface MixinScenesProps {
  defaultSceneId?: string,
  numScenes?: number
}

interface AddAssetToSceneOrPresetParams {
  sceneId?: string,
  scenePresetId: string,
  asset: Asset,
  contentType: SceneContentType,
  isActive: boolean
}

interface AddAssetsToSceneOrPresetParams {
  sceneId?: string,
  scenePresetId?: string,
  assets: Asset[],
  contentType: SceneContentType
}

interface AddContentToSceneOrPresetParams {
  contentType: SceneContentType,
  sceneContent: SceneContent,
  asset: Asset|null,
  sceneId?: string,
  scenePresetId: string
}

const getMaxOrder = (scenes: Scene[], assetType: AssetType) => {
  let maxOrder = -1
  scenes.forEach((scene: Scene) => {
    if (scene.contents === undefined) return
    Object.values(scene.contents).forEach((content) => {
      if (content.type !== assetType) return
      maxOrder = Math.max(content.order, maxOrder)
    })
  })
  return maxOrder
}

const getExistingClipEndActionAndActiveState = (contents: KeyDict<SceneContent>) => {
  let clipEndAction: ClipEndAction|null = null
  let hasActiveClip = false
  for (const content of Object.values(contents)) {
    if (content.type !== AssetType.Video) continue
    if (content.clip_end_action !== undefined) clipEndAction = content.clip_end_action
    if (content.active === true) hasActiveClip = true
    if (hasActiveClip && clipEndAction) break
  }
  return { clipEndAction: clipEndAction ?? ClipEndAction.Next, hasActiveClip }
}

const getFirstVideoBoxId = (scene: Scene) => {
  for (const [boxId, box] of Object.entries(scene.scene_layout.boxes)) {
    if (box.types?.includes(AssetType.Video)) return boxId
  }
  return 'none'
}

// eslint-disable-next-line max-lines-per-function
export function hasScenes<TBase extends StudioBaseMixin> (Base: TBase, props?: MixinScenesProps) {

  const { numScenes } = { numScenes: 30, ...props }

  // Some code is commented out, please keep it as in the future we'll want it that way. The program would be set
  // to the last scene, so it looks like it's empty. But for now I had to drop it in favour of more important bugs
  // Note that the hasInputs also has some of this logic in its constructor.

  return class ScenedClass extends Base {
    /** @deprecated Use programSceneId instead. */
    activeScene: string = ''
    previewSceneId: string|null = null
    scenes: KeyDict<Scene> = {}
    scenePresets: KeyDict<ScenePreset> = {}
    scenesSettings = new ScenesSettings()
    private __scenesWithPresets: KeyDict<Scene> = {}
    private __globalPresetIds: string[] = getGlobalLayerIds()
    preview_scenes: KeyDict<Scene> = {}
    lastCutTime: Date|null = null

    constructor (...arg: any[]) {
      super(...arg)
      this.scenes = Loader.loadKeyDict(arg[0], 'scenes', (data: unknown) => new Scene(data), {})
      const scenePresetsData = Loader.loadAndCast<KeyDict<ScenePreset>>(arg[0], 'scenePresets', {})
      for (const [presetId, preset] of Object.entries(scenePresetsData)) {
        this.scenePresets[presetId] = new ScenePreset({ ...preset, id: presetId })
      }
      if (Object.keys(this.scenes).length === 0) {
        this.numScenes = numScenes
      }
      this.preview_scenes = Loader.loadKeyDict(arg[0], 'preview_scenes', (data: unknown) => new Scene(data), {})
      this.activeScene = Loader.loadString(arg[0], 'activeScene', this.getFirstSceneId() ?? '')
      this.previewSceneId = Loader.loadStringOrNull(arg[0], 'previewSceneId', this.getFirstSceneId())
      this.scenesSettings = Loader
        .loadClass(arg[0], 'scenesSettings', (data) => new ScenesSettings(data), this.scenesSettings)
      this.lastCutTime = Loader.loadDateOrNull(arg[0], 'lastCutTime', this.lastCutTime)
      this.setupDefaultScenePresetsForEachScene()
      this.determineMissingSceneSourceTypes()
      this.updateScenesWithPresets()
    }

    // @ts-ignore
    private __hasScenes: true = true
    static __constructsHasScenes: true = true

    get numScenes (): number {
      return Object.keys(this.scenes).length
    }

    set numScenes (value: number) {
      const amount = Math.max(value, 1)
      while (Object.entries(this.scenes).length > amount) {
        delete this.scenes[`scene_${Object.entries(this.scenes).length - 1}`]
      }
      while (Object.entries(this.scenes).length < amount) {
        const index = Object.entries(this.scenes).length
        const sceneId = `scene_${index}`
        this.scenes[sceneId] = createScene(sceneId, '', '', index)
      }
      this.updateScenesWithPresets()
    }

    get programSceneId (): string|null {
      return (this.activeScene !== '') ? this.activeScene : null
    }

    set programSceneId (sceneId: string|null) {
      this.activeScene = sceneId ?? ''
    }

    get programSceneCastId (): string|null {
      if (this.programSceneId === null) return null
      return this.getSceneCastId(this.programSceneId) ?? null
    }

    get previewSceneCastId (): string|null {
      if (this.previewSceneId === null) return null
      return this.getSceneCastId(this.previewSceneId) ?? null
    }

    protected setupDefaultScenePresetsForEachScene () {
      for (const [index, presetId] of this.__globalPresetIds.entries()) {
        if (this.scenePresets[presetId] !== undefined) continue
        this.scenePresets[presetId] = new ScenePreset({
          id: presetId,
          name: HIDDEN_GLOBAL_PRESETS.includes(presetId) ? presetId : `Global ${ index + 1 }`,
          isGlobalPreset: true
        })
      }

      for (const [sceneId, scene] of Object.entries(this.scenes)) {
        if (scene.scenePresetIds === undefined) scene.scenePresetIds = []
        const scenePresetIds = uniq([...scene.scenePresetIds, ...this.__globalPresetIds, ...HIDDEN_GLOBAL_PRESETS])
        this.scenes[sceneId].scenePresetIds = scenePresetIds
        this.addMissingScenePresets(sceneId, scene)
      }
    }

    private addMissingScenePresets (sceneId: string, scene: Scene) {
      const nonGlobalPresetIds = scene.scenePresetIds.filter((id: string) => !this.__globalPresetIds.includes(id))
      if (nonGlobalPresetIds.length === 0) {
        this.addScenePreset(sceneId, {})
      } else {
        for (const presetId of nonGlobalPresetIds) {
          if (this.scenePresets[presetId] !== undefined) continue
          console.warn(`Adding missing preset for ${presetId} of scene ${sceneId}`)
          this.addScenePreset(sceneId, {}, presetId)
        }
      }
    }

    protected getFirstSceneId (): string|null {
      let firstSceneId: string|null = null
      let lowestOrder: number|null = null
      for (const [sceneId, scene] of Object.entries(this.scenes)) {
        if (firstSceneId === null) firstSceneId = sceneId // Use first scene as a fallback in case none have an order
        if (scene.order !== undefined  && (lowestOrder === null || scene.order < lowestOrder)) {
          lowestOrder = scene.order
          firstSceneId = sceneId
        }
      }
      return firstSceneId
    }

    addScene (params: { name: string, id: string, layout: Layout }) {
      const order = Object.values(this.scenes).length === 0 ? 0
                      : Object.values(this.scenes).reduce((max, scene) => Math.max(max, scene.order ?? 0), 0) + 1
      this.scenes[params.id] = {
        id: params.id,
        name: params.name,
        color: null,
        layout_id: params.layout.id ?? generateRandomString(20),
        scene_layout: params.layout,
        scenePresetIds: [],
        contents: {},
        order
      }
      this.updateScenesWithPresets()
      return this
    }

    swapSceneOrder (fromSceneId: string, toSceneId: string): Result<this, 'invalid_params'> {
      const fromScene = this.scenes[fromSceneId]
      const toScene = this.scenes[toSceneId]
      if (!fromScene) return Result.fail('invalid_params',
        `Swap scene failed. From scene: ${fromSceneId} does not exist`)
      if (!toScene) return Result.fail('invalid_params',
      `Swap scene failed. To scene: ${toSceneId} does not exist`)
      //TODO: Should not happen anymore, but for old templates. Remove when not needed.
      if ((fromScene.order ?? 0) === (toScene.order ?? 0)) {
        const sortedScenes = Object.values(this.scenes).sort(
          (scene1, scene2) => (scene1.order ?? 0) - (scene2.order ?? 0))
        sortedScenes.forEach((scene, idx) => scene.order = idx)
      }
      const fromSceneOrder = fromScene.order
      fromScene.order = toScene.order
      toScene.order = fromSceneOrder
      return Result.success(this)
    }

    setProgramSceneId (sceneId: string): Result<this, 'not_exists'> {
      if (!this.scenes[sceneId]) return Result.fail('not_exists', `cannot set non-existing scene ${sceneId} as active`)
      this.programSceneId = sceneId
      return Result.success(this)
    }

    setPreviewSceneId (sceneId: string|null) {
      if (this.previewSceneId === sceneId) return this
      if (sceneId === null || !this.scenes[sceneId]) this.previewSceneId = null
      else this.previewSceneId = sceneId
      return this
    }

    swapProgramWithPreview (): Result<this, 'not_exists'> {
      if (this.previewSceneId === null) {
        return Result.fail('not_exists', 'cannot swap active scene id when preview is null')
      }
      const oldProgramSceneId = this.programSceneId
      const result = this.setProgramSceneId(this.previewSceneId)
      if (result.isFailure) return result
      this.setPreviewSceneId(oldProgramSceneId)
      return Result.success(this)
    }

    getFirstActiveStreamIdBySceneId (sceneId: string): string|null {
      const currentScene = this.scenes[sceneId]
      if (currentScene === undefined) return null
      for (const sceneContent of Object.values(currentScene.contents)) {
        if (!sceneContent.active) continue
        if (!sceneContent.box || sceneContent.box === 'none') continue
        if (sceneContent.type !== AssetType.Broadcast) continue
        return sceneContent.id
      }
      return null
    }

    getFirstSceneIdWithActiveStream (streamId: string): string|null {
      for (const sceneId of Object.keys(this.scenes)) {
        if (this.getFirstActiveStreamIdBySceneId(sceneId) === streamId) return sceneId
      }
      return null
    }

    getCasterIdBySceneId (sceneId: string): string|null {
      const scene = this.scenesWithPresets[sceneId]
      if (scene === undefined) return null
      for (const content of Object.values(scene.contents)) {
        if (content.type !== AssetType.Caster || !content.user) continue
        const boxId = content.box
        if (!boxId || boxId === 'none' || !scene.scene_layout.boxes[boxId]) continue
        return content.user
      }
      return null
    }

    public getFirstSceneIdWithCaster (casterId: string): string|null {
      const casterScenes = this.getAllScenesWithSourceType([SceneSourceType.Talent, SceneSourceType.TalentAnonymous])
      for (const scene of casterScenes) {
        if (this.getCasterIdBySceneId(scene.id) === casterId) return scene.id
      }
      return null
    }

    getCasterContentIdForSceneId (casterId: string, sceneId: string): string|null {
      const currentScene = this.scenes[sceneId]
      if (currentScene === undefined) return null
      for (const [contentId, content] of Object.entries(currentScene.contents)) {
        if (content.type !== AssetType.Caster) continue
        if (content.user === casterId) return contentId
      }
      return null
    }

    getFirstActiveAssetIdBySceneId (sceneId: string): string|null {
      const currentScene = this.scenesWithPresets[sceneId]
      if (currentScene === undefined) return null
      for (const sceneContent of Object.values(currentScene.contents)) {
        if (!sceneContent.active) continue
        if (!sceneContent.box || sceneContent.box === 'none') continue
        if (sceneContent.contentType !== SceneContentType.Content) continue
        return sceneContent.id
      }
      return null
    }

    private addScenePreset (sceneId: string, scenePresetContents: KeyDict<SceneContent>, missingPresetId?: string) {
      let presetId = missingPresetId ?? generateRandomString()
      while (this.scenePresets[presetId] !== undefined) presetId = generateRandomString()
      this.scenePresets[presetId] = new ScenePreset({ id: presetId, contents: scenePresetContents })
      if (this.scenes[sceneId].scenePresetIds === undefined) this.scenes[sceneId].scenePresetIds = []
      this.scenes[sceneId].scenePresetIds.push(presetId)
      return this
    }

    public isCasterSourceScene (sceneId: string): boolean {
      const scene = this.scenes[sceneId]
      if (this.hasSceneLayout(sceneId)) return false
      if (scene === undefined) return false
      for (const box of Object.values(scene.scene_layout.boxes)) {
        if (box.field === LayoutBoxField.CASTER) return true
      }
      return false
    }

    public getScenePresetIdBySceneId (sceneId: string): string {
      const allPresetIds = this.scenes[sceneId].scenePresetIds
      return allPresetIds.find((id) => !this.__globalPresetIds.includes(id)) ?? allPresetIds[0]
    }

    public getScenePresetBySceneId (sceneId: string) {
      const presetId = this.getScenePresetIdBySceneId(sceneId)
      return this.scenePresets[presetId]
    }

    public addAssetToSceneOrPreset (payload: AddAssetToSceneOrPresetParams) {
      const { scenePresetId, sceneId, asset, contentType, isActive } = payload
      let highestOrder = 0
      if (sceneId) {
        highestOrder = getHighestContentOrder(this.scenesWithPresets[sceneId]?.contents ?? {}, contentType)
      }
      const sceneContent = {
        active: isActive,
        box: asset.id,
        id: asset.id,
        url: asset.url ?? '',
        name: asset.name,
        order: highestOrder + 1,
        type: asset.type,
        contentType: contentType
      }
      return this.addContentToSceneOrPreset({ contentType, sceneContent, asset, sceneId, scenePresetId })
    }

    private addContentToSceneOrPreset (payload: AddContentToSceneOrPresetParams) {
      const { contentType, sceneContent, asset, sceneId, scenePresetId } = payload
      sceneContent.contentType = contentType
      if (isSceneContentTransition(sceneContent)) {
        sceneContent.url = sceneContent.url ?? ''
        sceneContent.transitionDelay = asset?.transitionDelay ?? 0
      }
      if (contentType === SceneContentType.Content && sceneId) {
        this.addContentToScene(sceneId, sceneContent)
      } else {
        this.scenePresets[scenePresetId].addContent(sceneContent)
      }
      this.updateScenesWithPresets()
      return this
    }

    private addContentToScene (sceneId: string, sceneContent: SceneContent) {
      this.scenes[sceneId].contents[sceneContent.id] = sceneContent
      this.addSceneContents(this.scenes[sceneId])
      return this
    }

    public addCasterToScene (payload: {
      sceneId: string, caster: Partial<UserProfile>, isActive: boolean, contentId?: string
    }) {
      const { sceneId, caster, isActive } = payload
      const highestOrder = getHighestContentOrder(this.scenes[sceneId].contents, SceneContentType.Content)
      const sceneContent: SceneContent = {
        active: isActive,
        box: caster.id ?? '',
        id: payload.contentId ?? generateRandomString(20),
        name: caster.first_name ?? '',
        order: highestOrder + 1,
        type: AssetType.Caster,
        contentType: SceneContentType.Content,
        muted: false,
        user: caster.id
      }
      this.addContentToScene(sceneId, sceneContent)
      this.syncCommonContents()
      return this
    }

    public addAssetsToSceneOrPreset (params: AddAssetsToSceneOrPresetParams): this {
      const { sceneId, scenePresetId, assets, contentType } = params
      let presetId = scenePresetId
      if (sceneId && !presetId) {
        presetId = this.getScenePresetIdBySceneId(sceneId)
      } else if (!presetId) {
        throw new Error('Either sceneId or scenePresetId must be set')
      }
      for (const asset of assets) {
        const isActive = (contentType !== SceneContentType.Content) && (contentType !== SceneContentType.Layer)
        this.addAssetToSceneOrPreset({ asset, contentType, isActive, sceneId, scenePresetId: presetId })
      }
      this.updateScenesWithPresets()
      return this
    }

    public addAssetsToAllScenesPreset (
      assets: Asset[],
      contentType: SceneContentType
    ): Result<this, 'no_scenes'> {
      const scenes = Object.values(this.scenes)
      for (const asset of assets) {
        for (const scene of scenes) {
          const scenePresetId = this.getScenePresetIdBySceneId(scene.id)
          this.addAssetToSceneOrPreset({
            scenePresetId: scenePresetId,
            sceneId: scene.id,
            asset,
            contentType: contentType,
            isActive: contentType === SceneContentType.GlobalLayer ? false : true
          })
        }
      }
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public addAssetsToScenePreset (presetId: string, assets: Asset[]): Result<this, 'not_found'> {
      if (this.scenePresets[presetId] === undefined) {
        return Result.fail('not_found', `Scene preset with id ${ presetId } not found`)
      }
      this.scenePresets[presetId].addAssets(assets)
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public setScenePresetSourceType (presetId: string, sourceType: SceneSourceType): Result<this, 'not_found'> {
      if (this.scenePresets[presetId] === undefined) {
        return Result.fail('not_found', `Global layer with id ${ presetId } not found`)
      }
      this.scenePresets[presetId].addSourceType(sourceType)
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    // eslint-disable-next-line complexity
    public removeAssetFromSceneOrPreset (params: { sceneId?: string, scenePresetId?: string, assetId: string }): this {
      const { sceneId, scenePresetId, assetId } = params
      let scenePreset = this.scenePresets[scenePresetId ?? '']
      if (sceneId) scenePreset = this.getScenePresetBySceneId(sceneId)
      let contents: KeyDict<SceneContent>|null = null
      if (sceneId && this.scenes[sceneId]?.scene_layout.contents?.[assetId]) {
        delete this.scenes[sceneId]?.scene_layout.contents?.[assetId]
        return this
      } else if (sceneId && this.scenes[sceneId]?.contents[assetId]) {
        contents = this.scenes[sceneId].contents
        delete contents[assetId]
      } else if (scenePreset?.contents[assetId]) {
        scenePreset.removeContent(assetId)
        contents = scenePreset.contents
      } else {
        return this
      }
      this.updateScenesWithPresets()
      if (sceneId && this.getFirstActiveAssetIdBySceneId(sceneId) !== null) return this

      for (const [contentId, content] of Object.entries(contents)) {
        if (content.contentType !== SceneContentType.Content) continue
        if (![AssetType.Graphic, AssetType.Stinger].includes(content.type)) continue
        contents[contentId].active = true
        break
      }
      this.updateScenesWithPresets()
      return this
    }

    public removeAssetFromAllScenesPreset (assetId: string): Result<this, 'no_asset_to_remove'> {
      for (const scene of Object.values(this.scenes)) {
        this.getScenePresetBySceneId(scene.id)?.removeContent(assetId)
      }
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public getScenesContentsWithGlobalLayer (): KeyDict<SceneContentGlobalLayer>|null {
      for (const scene of Object.values(this.scenePresets)) {
        for (const content of Object.values((scene.contents) as KeyDict<SceneContentGlobalLayer> ?? {})) {
          if (content.contentType === SceneContentType.GlobalLayer) {
            return (scene.contents) as KeyDict<SceneContentGlobalLayer>
          }
        }
      }
      return null
    }

    public updateContentActiveForScene (sceneId: string, activeStates: KeyDict<boolean>): Result<this, 'not_exists'> {
      const scene = this.scenes[sceneId]
      if (scene === undefined) return Result.fail('not_exists', `No scene found with given sceneId:${ sceneId }`)
      this.setSceneContentActive(scene, activeStates)
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public updateContentActiveForScenePreset (
      scenePresetId: string, activeStates: KeyDict<boolean>
    ): Result<this, 'not_exists'> {
      const scenePreset = this.scenePresets[scenePresetId]
      if (scenePreset === undefined) {
        return Result.fail('not_exists', `No scene preset found with given scenePresetId:${ scenePresetId }`)
      }
      this.setScenePresetContentActive(scenePreset, activeStates)
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    private setSceneContentActive (scene: Scene, activeStates: KeyDict<boolean>) {
      const sceneId = scene.id
      const presetId = this.getScenePresetIdBySceneId(sceneId)
      for (const [assetId, isActive] of Object.entries(activeStates)) {
        if (scene.contents[assetId] !== undefined) {
          scene.contents[assetId].active = isActive
        } else {
          this.scenePresets[presetId].contents[assetId].active = isActive
        }
      }
    }

    private setScenePresetContentActive (scenePreset: ScenePreset, activeStates: KeyDict<boolean>) {
      for (const [assetId, isActive] of Object.entries(activeStates)) {
        if (!scenePreset.contents[assetId]) continue
        scenePreset.contents[assetId].active = isActive
      }
    }

    isLayerActiveOnScene (sceneId: string,  assetId: string): boolean {
      const sceneContents = this.getSceneContents(sceneId)
      if (sceneContents[assetId] !== undefined) {
        return sceneContents[assetId].active
      }
      return false
    }

    public updateAllScenesContentActive (activeStates: KeyDict<boolean>): Result<this, 'not_exists'> {
      for (const scene of Object.values(this.scenes)) {
        if (scene === undefined) return Result.fail('not_exists', 'No scene found with given sceneId')
        this.setSceneContentActive(scene, activeStates)
      }
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public updateSceneBoxContentActive (sceneId: string, activeStates: KeyDict<boolean>): Result<this, 'not_exists'> {
      const scene = this.scenes[sceneId]
      if (!scene) return Result.fail('not_exists', `Invalid scene id ${sceneId}`)
      for (const [boxId, isActive] of Object.entries(activeStates)) {
        if (scene.scene_layout.boxes[boxId] === undefined) {
          return Result.fail('not_exists', `No box with id ${ boxId } found in scene ${ sceneId }`)
        }
        scene.scene_layout.boxes[boxId].active = isActive
      }
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    // eslint-disable-next-line complexity
    private addBoundContentsForBoxOfScene (box: LayoutBox, sceneId: string, scenes: KeyDict<Scene>) {
      if (!box.boundContentSceneId || box.boundContentSceneId === sceneId) return
      const sceneContentsToAddTo = scenes[sceneId]?.contents
      const sceneLayoutToUpdate = scenes[sceneId]?.scene_layout
      if (!sceneContentsToAddTo || !sceneLayoutToUpdate) return
      const boundSceneContents = scenes[box.boundContentSceneId]?.contents ?? {}
      for (const [contentId, content] of Object.entries(boundSceneContents)) {
        if ((!content.active && content.type !== AssetType.Graphic) || content.box === 'none') continue
        if (content.contentType !== SceneContentType.Content) continue
        const existingContentInScene = sceneContentsToAddTo[contentId]
        const contentToBind = existingContentInScene ?? content
        sceneContentsToAddTo[contentId] = { ...contentToBind, box: box.id }
        sceneLayoutToUpdate.boxes[box.id] = { ...sceneLayoutToUpdate.boxes[box.id], types: [content.type] }
      }
    }

    private addBoundContentsToScenes (scenes: KeyDict<Scene>) {
      for (const [sceneId, scene] of Object.entries(scenes)) {
        // Note: the ?. and ?? are mainly there for unit tests that use incomplete scene objects.
        for (const box of Object.values(scene.scene_layout?.boxes ?? {})) {
          this.addBoundContentsForBoxOfScene(box, sceneId, scenes)
        }
      }
    }

    /* eslint-disable-next-line complexity */
    protected updateScenesWithPresets () {
      const mergedScenes = cloneDeep(this.scenes)
      for (const [sceneId, scene] of Object.entries(mergedScenes)) {
        for (const presetId of scene.scenePresetIds) {
          const preset = this.scenePresets[presetId]
          if (!preset) console.warn(`Missing preset ${presetId} for scene ${sceneId}`)
          mergedScenes[sceneId] = new Scene(merge(scene, {
            contents: preset?.contents ?? {},
            scene_layout: preset?.scene_layout ?? {}
          }))
        }
        mergedScenes[sceneId] = removeGlobalStingerIfLocalStinger(mergedScenes[sceneId])
        mergedScenes[sceneId] = this.addSceneContents(mergedScenes[sceneId])
      }
      this.addBoundContentsToScenes(mergedScenes)
      this.__scenesWithPresets = mergedScenes
    }

    get scenesWithPresets (): KeyDict<Scene> {
      return this.__scenesWithPresets
    }

    private addSceneLayoutContents (scene: Scene): Scene {
      scene.contents = {
        ...scene.contents,
        ...scene.scene_layout.contents,
        ...(hasCastPropertyBoxes(scene.scene_layout) ? this.scenesSettings.athlete.castPropertyBoxContents : {})
      }
      return scene
    }

    /* eslint-disable-next-line complexity */
    private addSceneContents (scene: Scene): Scene {
      for (const [contentId, content] of Object.entries(scene.contents ?? {})) {
        // Presets handle their own layout boxes
        const presetTypes = [SceneContentType.GlobalTransition, SceneContentType.GlobalLayer, SceneContentType.Layer]
        if (presetTypes.includes(content.contentType)) continue
        const allowedAssetTypes = [AssetType.Graphic, AssetType.Stinger, AssetType.Widget, AssetType.Caster]
        if (!allowedAssetTypes.includes(content.type)) continue
        // layers should always be on top
        const zIndex = getZIndex(content.contentType, 99, content.order)
        if (scene.scene_layout?.boxes) {
          const isCasterType = content.type === AssetType.Caster
          const videoLayoutBoxField = content.type === AssetType.Video ? LayoutBoxField.BACK : LayoutBoxField.FRONT
          const layoutBoxField = isCasterType ? LayoutBoxField.CASTER : videoLayoutBoxField
          // prevent creating fullscreen box for caster contents that the backend adds to all scenes
          if (isCasterType && content.box === 'none') continue
          if (content.box && scene.scene_layout.boxes[content.box]) continue // Skip if content mapped to valid box
          // FIXME: refactor so the box is created the moment the asset is added, not on every "get scenesWithPresets"
          const boxId = (isCasterType) ? (content.user ?? contentId) : contentId
          scene.scene_layout.boxes[boxId] = createFullscreenBox(boxId, zIndex, content.type, layoutBoxField, true)
        } else {
          console.warn('Scene has no scene_layout', scene)
        }
      }
      if (scene.sourceType === SceneSourceType.Layout) {
        return this.addSceneLayoutContents(scene)
      }
      return scene
    }

    public isSceneSourceType (sceneId: string|null, sourceType: SceneSourceType) {
      if (sceneId === null) return false
      return this.getSceneSourceType(sceneId) === sourceType
    }

    public hasSpecificSourceType (sceneId: string|null): boolean {
      if (sceneId === null) return false
      return this.getSceneSourceType(sceneId) !== SceneSourceType.Generic
    }

    public areAllScenesGenericSources (): boolean {
      for (const sceneId of Object.keys(this.scenes)) {
        if (this.hasSpecificSourceType(sceneId)) return false
      }
      return true
    }

    // eslint-disable-next-line complexity
    public getActiveStreamContentsInScene (sceneId: string, ignoreTypes?: AssetType[], onlyTypes?: AssetType[]):
      Result<KeyDict<SceneContent>, 'not_exists'>
    {
      const scene = this.scenes[sceneId]
      if (scene === undefined) return Result.fail('not_exists', `Cannot get active streams of unknown scene ${sceneId}`)
      const activeStreamContents: KeyDict<SceneContent> = {}
      const assetTypes = onlyTypes ?? [AssetType.Broadcast, AssetType.Caster, AssetType.Playlist, AssetType.Cast]
      const acceptedTypes = new Set(assetTypes.filter((item) => {
        if (!ignoreTypes) return true
        return !ignoreTypes.includes(item)
      }))
      for (const [contentId, content] of Object.entries(scene.contents)) {
        if (!acceptedTypes.has(content.type)) continue
        if (!content.active) continue
        activeStreamContents[contentId] = content
      }
      return Result.success(activeStreamContents)
    }

    // eslint-disable-next-line complexity
    public getStreamContentsInSceneWithPresets (params: { sceneId: string, ignoreTypes?: AssetType[],
      onlyTypes?: AssetType[], isActive?: boolean, isVisible?: boolean }): Result<KeyDict<SceneContent>, 'not_exists'>
    {
      const sceneWithPresets = this.scenesWithPresets[params.sceneId]
      if (sceneWithPresets === undefined) return Result.fail('not_exists',
        `Cannot get active streams of unknown scene ${params.sceneId}`)
      const activeStreamContents: KeyDict<SceneContent> = {}
      const assetTypes = params.onlyTypes ?? [AssetType.Broadcast, AssetType.Caster, AssetType.Playlist]
      const acceptedTypes = new Set(assetTypes.filter((item) => {
        if (!params.ignoreTypes) return true
        return !params.ignoreTypes.includes(item)
      }))
      for (const [contentId, content] of Object.entries(sceneWithPresets.contents)) {
        if (!acceptedTypes.has(content.type)) continue
        if (params.isActive && !content.active) continue
        if (params.isVisible && (!content.box || content.box === 'none')) continue
        activeStreamContents[contentId] = content
      }
      return Result.success(activeStreamContents)
    }

    /** @deprecated Use getSceneSourceType() */
    // eslint-disable-next-line complexity
    private deriveSceneSourceType (sceneId: string): SceneSourceType {
      if (!sceneId.startsWith('scene_')) return SceneSourceType.Generic
      if (this.hasSceneLayout(sceneId)) return SceneSourceType.Layout
      // The `scene_layout?.boxes` check is needed for the unit tests, as they often use partial scenes that don't
      // include a `scene_layout` property.
      if (this.scenes[sceneId]?.scene_layout?.boxes) {
        if (this.isCasterSourceScene(sceneId)) return SceneSourceType.Talent
        if (this.hasClipsBox(sceneId)) return SceneSourceType.Playlist
      }
      for (const content of Object.values(this.scenes[sceneId]?.contents ?? {})) {
        if (content.contentType !== SceneContentType.Content) continue
        if (content.type === AssetType.Graphic) return SceneSourceType.Graphic
        if (content.type === AssetType.Widget) return SceneSourceType.Websource
      }
      const scenePreset = this.getScenePresetBySceneId(sceneId)
      for (const content of Object.values(scenePreset?.contents ?? {})) {
        if (content.contentType !== SceneContentType.Content) continue
        if (content.type === AssetType.Graphic) return SceneSourceType.Graphic
        if (content.type === AssetType.Widget) return SceneSourceType.Websource
      }
      const hasBroadcast = this.getFirstActiveStreamIdBySceneId(sceneId)
      if (hasBroadcast) return SceneSourceType.Broadcast
      return SceneSourceType.Generic
    }

    public getSceneSourceType (sceneId: string): SceneSourceType {
      const sceneSourceType = this.scenes[sceneId]?.sourceType ?? null
      if (sceneSourceType !== null) return sceneSourceType
      else return this.deriveSceneSourceType(sceneId)
    }

    public setSceneSourceType (sceneId: string, sourceType: SceneSourceType) {
      const scene = this.scenes[sceneId]
      if (scene) scene.sourceType = sourceType
      return this
    }

    public hasAnySceneWithSourceType (sourceType: SceneSourceType) {
      for (const sceneId of Object.keys(this.scenes)) {
        if (this.getSceneSourceType(sceneId) === sourceType) return true
      }
      return false
    }

    public getAllScenesWithSourceType (sourceTypes: SceneSourceType[]) {
      return Object.values(this.scenes).filter((scene) => sourceTypes.includes(this.getSceneSourceType(scene.id)))
    }

    public hasScenePresetWithSourceType (sourceType: SceneSourceType) {
      const globalPresets = Object.values(this.scenePresets).filter((preset) => preset.isGlobalPreset)
      for (const preset of globalPresets) {
        if (preset.sourceTypes.includes(sourceType)) return true
      }
      return false
    }

    /** @deprecated
     * This is here only for legacy Switcher casts that didn't include the SceneSourceType in their scenes yet.
     * Also, once we have real Scene classes, this legacy code should move there. (Maybe by that time it's safe to
     * assume there aren't any old Switcher cast templates anymore that didn't include SceneSourceTypes in their scenes,
     * and then this code can get removed completely.)
     */
    private determineMissingSceneSourceTypes () {
      for (const [sceneId, scene] of Object.entries(this.scenes)) {
        if (scene.sourceType !== undefined) continue
        scene.sourceType = this.deriveSceneSourceType(sceneId)
      }
    }

    /* eslint-disable complexity */
    public getSceneActiveStreamsAudible (sceneId: string, programMutes: KeyDict<boolean>,
      onlyTypes?: AssetType[]): Result<KeyDict<boolean>, 'not_exists'>
    {
      const activeStreamContentsResult = this.getActiveStreamContentsInScene(sceneId, undefined, onlyTypes)
      if (activeStreamContentsResult.isFailure) return activeStreamContentsResult.convert()
      else {
        // Sort of similar to getters.events.currentMuteLevels
        // And also getters.events.selectedAudioLevels
        const streamsToActivate: KeyDict<boolean> = {}
        for (const [streamId, content] of Object.entries(activeStreamContentsResult.value)) {
          let isMuted = programMutes[streamId]
          if (isMuted === undefined) isMuted = content.muted === undefined ? false : content.muted
          streamsToActivate[streamId] = !isMuted
          let isLeftMuted = programMutes[`${streamId}_left`]
          if (isLeftMuted === undefined) isLeftMuted = content.leftMuted === undefined ? true : content.leftMuted
          streamsToActivate[`${streamId}_left`] = !isLeftMuted
          let isRightMuted = programMutes[`${streamId}_right`]
          if (isRightMuted === undefined) isRightMuted = content.rightMuted === undefined ? false : content.rightMuted
          streamsToActivate[`${streamId}_right`] = !isRightMuted
        }
        return Result.success(streamsToActivate)
      }
    }
    /* eslint-enable complexity */

    public getSceneNameBySceneId (sceneId: string): string {
      const scene = this.scenesWithPresets[sceneId]
      if (scene?.name) return scene.name
      if ([SceneSourceType.Talent, SceneSourceType.TalentAnonymous].includes(this.getSceneSourceType(sceneId))) {
        const casterId = this.getCasterIdBySceneId(sceneId)
        if (casterId === null) return ''
        return scene.contents[casterId]?.name ?? ''
      }
      const activeAssetId = this.getFirstActiveAssetIdBySceneId(sceneId)
      if (activeAssetId === null) return ''
      return scene.contents[activeAssetId]?.name ?? ''
    }

    private clearSceneBindings (boundSceneId: string) {
      for (const [sceneId, scene] of Object.entries(this.scenes)) {
        if (scene.sourceType !== SceneSourceType.Layout) continue
        Object.entries(scene.scene_layout.boxes).forEach(([boxId, box]) => {
          if (box.boundContentSceneId === boundSceneId) {
            const result = this.setBoundContentOnSceneBox(sceneId, boxId, null)
            result.logIfError(`Failed to clear scene binding of ${boundSceneId} for box ${boxId} in scene ${sceneId}`)
          }
        })
      }
    }

    public clearScene (sceneId: string): Result<this, 'invalid_scene_id'> {
      const scene = this.scenes[sceneId]
      if (!scene) return Result.fail('invalid_scene_id', 'Cannot clear non-existing scene')
      return this.replaceScene(sceneId, createScene(sceneId, '', '', scene.order ?? 0))
    }

    public replaceScene (sceneId: string, newScene: Scene): Result<this, 'invalid_scene_id'> {
      const scene = this.scenes[sceneId]
      if (!scene) return Result.fail('invalid_scene_id', 'Cannot replace non-existent scene')
      this.clearSceneBindings(scene.id)
      const oldScenePresetId = this.getScenePresetIdBySceneId(sceneId)
      if (oldScenePresetId) delete this.scenePresets[oldScenePresetId]
      this.scenes[sceneId] = newScene
      if (newScene.scenePresetIds.length === 0) {
        this.addScenePreset(sceneId, {})
      }
      this.syncCommonContentsForScene(newScene)
      return Result.success(this)
    }

    public addFullscreenClipsBoxToScene (sceneId: string) {
      const scene = this.scenes[sceneId]
      if (!scene) return Result.fail('invalid_scene_id', 'Cannot add box for clips to invalid scene')
      const existingBox = scene.scene_layout.boxes.clips
      if (!existingBox) {
        scene.scene_layout.boxes.clips = createFullscreenBox('clips', 0, AssetType.Video, LayoutBoxField.BACK)
        scene.scene_layout.boxes.clips.active = true
        this.updateScenesWithPresets()
      }
      return Result.success(this)
    }

    public addClipToScenes (asset: Asset) {
      const scenes = Object.values(this.scenes)
      const maxOrder = getMaxOrder(scenes, AssetType.Video)
      const sourceTypesAllowingBox = new Set([SceneSourceType.Generic, SceneSourceType.Playlist])
      for (const scene of scenes) {
        if (scene.contents[asset.id] !== undefined) continue
        const useSceneBoxAsBox = sourceTypesAllowingBox.has(this.getSceneSourceType(scene.id))
        const { clipEndAction, hasActiveClip } = getExistingClipEndActionAndActiveState(scene.contents)
        const box = getFirstVideoBoxId(scene)
        scene.contents[asset.id] = {
          active: !hasActiveClip,
          auto_play_next: true,
          box: useSceneBoxAsBox ? box : 'none',
          clip_end_action: clipEndAction,
          contentType: SceneContentType.Content,
          id: asset.id,
          name: asset.name,
          type: AssetType.Video,
          order: maxOrder + 1
        }
        if (asset.thumbnail) scene.contents[asset.id].url = asset.thumbnail
      }
      this.updateScenesWithPresets()
      return this
    }

    public updateAllClipsEndAction (action: ClipEndAction) {
      for (const scene of Object.values(this.scenes)) {
        for (const content of Object.values(scene.contents)) {
          if (content.type !== AssetType.Video) continue
          content.clip_end_action = action
          content.auto_play_next = action === ClipEndAction.Next
        }
      }
      return this
    }

    public updateActiveClip (clipId: string|null) {
      for (const scene of Object.values(this.scenes)) {
        for (const [contentId, content] of Object.entries(scene.contents)) {
          if (content.type !== AssetType.Video) continue
          content.active = contentId === clipId
        }
      }
      return this
    }

    protected syncCommonContentsForScene (scene: Scene) {
      const contentTypes = [AssetType.Video, AssetType.Caster]
      const allClipsAndCasters: KeyDict<SceneContent> = this.getScenesContents(contentTypes, false)
      const clipBoxId = getFirstVideoBoxId(scene)
      Object.entries(allClipsAndCasters).forEach(([contentId, content]) => {
        if (scene.contents[contentId]) return
        const syncedContent = cloneDeep(content)
        syncedContent.box = content.type === AssetType.Video ? clipBoxId : 'none'
        if (content.type === AssetType.Caster) syncedContent.muted = true
        scene.contents[contentId] = syncedContent
      })
    }

    protected syncCommonContents () {
      Object.values(this.scenes).forEach((scene) => this.syncCommonContentsForScene(scene))
    }

    public removeContentsFromScenes (contentIds: string[], sceneIds?: string[]) {
      const sceneIdsToRemoveFrom = sceneIds ?? Object.keys(this.scenes)
      let changesWereMade = false
      for (const sceneId of sceneIdsToRemoveFrom) {
        const scene = this.scenes[sceneId]
        if (!scene) return Result.fail('invalid_scene', `Cast has no scene with id: ${sceneId}`)
        for (const contentId of contentIds) {
          if (scene.contents[contentId] === undefined) continue
          delete scene.contents[contentId]
          changesWereMade = true
        }
      }
      if (changesWereMade) this.updateScenesWithPresets()
      return Result.success(this)
    }

    public setAutoPlayClipsForScene (sceneId: string, autoPlayClips: boolean): Result<this, 'invalid_scene_id'> {
      const scene = this.scenes[sceneId]
      if (!scene) return Result.fail('invalid_scene_id', 'Cannot update autoPlayClips of invalid scene')
      scene.autoPlayClips = autoPlayClips
      return Result.success(this)
    }

    public hasClipsBox (sceneId: string) {
      if (this.scenes[sceneId]?.scene_layout.boxes.clips?.types?.includes(AssetType.Video)) return true
      return false
    }

    public applySceneLayout (sceneId: string, layout: Layout): Result<this, 'invalid_scene_id'> {
      if (!this.scenes[sceneId]) return Result.fail('invalid_scene_id', 'Cannot apply layout to non-existent scene')
      for (const [boxId, box] of Object.entries(layout.boxes)) {
        layout.boxes[boxId] = { ...box, active: true }
      }
      this.scenes[sceneId].scene_layout = layout
      this.scenes[sceneId].layout_id = layout.id ?? sceneId
      this.scenes[sceneId].name = layout.name
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public hasSceneLayout (sceneId: string) {
      return this.scenes[sceneId]?.layout_id
        && this.scenes[sceneId].layout_id !== 'fullscreen'
        && this.scenes[sceneId].layout_id !== 'none'
    }

    public addAssetToSceneBox (sceneId: string, boxId: string, asset: Asset) {
      const highestOrder = getHighestContentOrder(this.scenesWithPresets[sceneId]?.contents ?? {},
        SceneContentType.Content)
      const sceneContent = {
        active: true,
        box: boxId,
        id: asset.id,
        url: asset.url ?? '',
        name: asset.name,
        order: highestOrder + 1,
        type: asset.type,
        contentType: SceneContentType.Content
      }
      return this.addContentToSceneBox(sceneId, boxId, sceneContent)
    }

    public addContentToSceneBox (sceneId: string, boxId: string, sceneContent: SceneContent, updateOrder = false):
      Result<this, 'not_exists'>
    {
      const scene = this.scenes[sceneId]
      if (!scene) return Result.fail('not_exists', `Invalid scene ${sceneId}`)
      if (!scene.scene_layout.boxes[boxId]) return Result.fail('not_exists', `Scene ${sceneId} has no box ${boxId}`)
      const newContent = { ...sceneContent, box: boxId }
      if (updateOrder) newContent.order = getHighestContentOrder(scene.contents, sceneContent.contentType) + 1
      scene.contents[sceneContent.id] = newContent
      scene.scene_layout.boxes[boxId].active = true
      return Result.success(this)
    }

    public getScenesAssetIds (assetTypes?: Readonly<AssetType[]>) {
      const assetTypesToIgnore = new Set([AssetType.Playlist, AssetType.Broadcast, AssetType.Caster,
        AssetType.ScreenShare])
      const assetIds = new Set<string>()
      const filterAndAdd = (contents: KeyDict<SceneContent>) => {
        Object.values(contents).forEach((content) => {
          if (!assetTypesToIgnore.has(content.type) && (!assetTypes || assetTypes.includes(content.type))) {
            if (content.id) assetIds.add(content.id)
          }
        })
      }
      Object.values(this.scenesWithPresets).forEach((scene) => {
        if (scene.contents !== undefined) {
          filterAndAdd(scene.contents)
          if (scene.id) {
            const previewScene = this.preview_scenes[scene.id]
            if (previewScene?.scene_layout?.contents) {
              filterAndAdd(previewScene.scene_layout.contents)
            }
          }
        }
      })
      return assetIds
    }

    public getSceneContents (
      sceneId: string, assetTypes?: Readonly<AssetType[]>, includePresets: boolean = true
    ): KeyDict<SceneContent> {
      const scenes = includePresets ? this.scenesWithPresets : this.scenes
      return pickBy(scenes[sceneId]?.contents ?? {}, (content) => !assetTypes || assetTypes.includes(content.type))
    }

    public getScenesContents (
      assetTypes?: Readonly<AssetType[]>, includePresets: boolean = true
    ): KeyDict<SceneContent> {
      const scenesContents: KeyDict<SceneContent> = {}
      Object.keys(this.scenesWithPresets).forEach((sceneId) => {
        Object.entries(this.getSceneContents(sceneId, assetTypes, includePresets)).forEach(([contentId, content]) => {
          if (scenesContents[contentId] === undefined) scenesContents[contentId] = content
        })
      })
      return scenesContents
    }

    private setScenePlaylistBox (sceneId: string, boxId: string|null) {
      const playlistContent = this.scenes[sceneId]?.contents.playlist
      if (playlistContent) {
        if (boxId === null) playlistContent.box = 'none'
        else playlistContent.box = boxId
      }
    }

    private overrideScenePresetBoxTypes (
      sceneId: string, boxId: string, boxTypesInfo: BoxTypesInfo|null, isPlaylistSource: boolean = false
    ) {
      const scenePreset = this.getScenePresetBySceneId(sceneId) ?? null
      if (!scenePreset) return
      if (boxTypesInfo !== null) {
        const updatedPresetSceneLayout = scenePreset.scene_layout
        merge(updatedPresetSceneLayout, { boxes: { [boxId]: boxTypesInfo } })
        scenePreset.scene_layout = updatedPresetSceneLayout
      } else {
        const presetBox = scenePreset?.scene_layout?.boxes?.[boxId]
        if (presetBox !== undefined) {
          delete presetBox.types
          delete presetBox.field
        }
      }
      if (isPlaylistSource) this.setScenePlaylistBox(sceneId, boxTypesInfo === null ? null : boxId)
    }

    public setBoundContentOnSceneBox (
      sceneId: string, boxId: string, sceneIdToBind: string|null
    ): Result<this, 'set_bound_content'> {
      const boxToBind = this.scenes[sceneId]?.scene_layout.boxes[boxId]
      if (!boxToBind) return Result.fail('set_bound_content', `Box ${boxId} does not exist in scene`)
      const existingBoundSceneId = boxToBind.boundContentSceneId
      boxToBind.boundContentSceneId = sceneIdToBind
      boxToBind.active = true
      const sourceType = this.getSceneSourceType(sceneIdToBind ?? existingBoundSceneId ?? '')
      const boxTypeInfo = sceneIdToBind !== null ? getBoxTypeInfoForSceneSourceType(sourceType) : null
      const isPlaylistSource = sourceType === SceneSourceType.Playlist
      if (isPlaylistSource) {
        const activateAutoPlay = sceneIdToBind !== null
        this.setAutoPlayClipsForScene(sceneId, activateAutoPlay)
          .logIfError(`Failed to ${activateAutoPlay ? 'set' : 'clear'} autoPlayClips for layout of scene ${sceneId}`)
      }
      this.overrideScenePresetBoxTypes(sceneId, boxId, boxTypeInfo, isPlaylistSource)
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public setSceneName (sceneId: string|null, name: string): Result<this, 'invalid_scene_id'> {
      if (!sceneId || !this.scenes[sceneId]) return Result.fail('invalid_scene_id', 'Scene not found')
      this.scenes[sceneId].name = name
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public setSceneColor (sceneId: string|null, color: HexCode|null): Result<this, 'invalid_scene_id'> {
      if (!sceneId || !this.scenes[sceneId]) return Result.fail('invalid_scene_id', 'Scene not found')
      this.scenes[sceneId].color = color
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public togglePreset (presetId: string, isActive?: boolean): Result<this, 'not_found'> {
      if (!this.scenePresets[presetId]) return Result.fail('not_found', `Preset [${ presetId }] not found`)
      this.scenePresets[presetId].setBoxesActive(isActive)
      return Result.success(this)
    }

    public clearPreset (presetId: string): Result<this, 'not_found'> {
      if (!this.scenePresets[presetId]) return Result.fail('not_found', `Preset [${ presetId }] not found`)
      this.scenePresets[presetId].clear()
      return Result.success(this)
    }

    public moveContentUp (sceneId: string, assetId: string): Result<this, 'not_found'> {
      const presetId = this.getScenePresetIdBySceneId(sceneId)
      const isInScene = !!this.scenes[sceneId]?.contents[assetId]
      const isInPreset = !!this.scenePresets[presetId]?.contents[assetId]
      if (!isInScene && !isInPreset) return Result.fail('not_found',
        `Error moving content [${ assetId }] up in scene [${ sceneId }]: content not found`)
      if (isInScene) {
        const result = this.moveContentUpInContent(sceneId, assetId)
        result.logIfError(`Could not move asset [${ assetId }] in scene [${ sceneId }]`)
      } else if (isInPreset) {
        const result = this.updatePreset(presetId, (preset) => preset.moveContentUp(assetId))
        result.logIfError(`Could not move asset [${ assetId }] in preset [${ presetId }] of scene [${ sceneId }]`)
      } else {
        return Result.fail('not_found',
          `Error moving content [${ assetId }] up in scene [${ sceneId }]: content not found`)
      }
      return Result.success(this)
    }

    private moveContentUpInContent (currSceneId: string, currAssetId: string): Result<this, 'not_found'> {
      const currContent = this.scenes[currSceneId]?.contents?.[currAssetId]
      if (!currContent) return Result.fail('not_found',
        `Error moving content [${ currAssetId }] up in scene [${ currSceneId }]: content not found`)
      const sceneIds = (currContent.type === AssetType.Video) ? Object.keys(this.scenes) : [currSceneId]
      for (const sceneId of sceneIds) {
        this.scenes[sceneId].contents = moveContentUp(currAssetId, this.scenes[sceneId].contents)
      }
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public updatePreset<E extends string> (
      presetId: string,
      updateFn: UpdateFunction<ScenePreset, E>
    ): Result<this, 'not_found'|E> {
      const preset = this.scenePresets[presetId] ?? null
      if (preset === null) return Result.fail('not_found', `Preset [${ presetId }] not found`)
      const result = Result.fromPossibleResult(updateFn(preset))
      if (result.isFailure) return result.convert()
      this.scenePresets[presetId] = result.value
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    /** @deprecated Should only be used in unit tests */
    public forceUpdateScenesWithPresets () {
      this.updateScenesWithPresets()
      return this
    }

    public refreshContentReloadKey (sceneId: string, contentId: string):
      Result<this, 'invalid_scene_id'|'invalid_content_id'>
    {
      if (!this.scenes[sceneId]) return Result.fail('invalid_scene_id', `Scene: ${sceneId} not found`)
      const sceneContents = this.scenes[sceneId].contents
      if (!sceneContents[contentId]) return Result.fail('invalid_content_id', `Content: ${contentId} not found`)
      sceneContents[contentId].reloadKey = Date.now()
      this.updateScenesWithPresets()
      return Result.success(this)
    }

    public getAnonymousBoxId (sceneId: string): string|null {
      const scene = this.scenes[sceneId]
      if (!scene) return null
      for (const boxId of Object.keys(scene.scene_layout.boxes)) {
        if (boxId.startsWith(ANONYMOUS_GUEST_ID_PLACEHOLDER_PREFIX)) return boxId
      }
      return null
    }

    public findContentWithBox (sceneId: string, boxId: string): SceneContent|null {
      const scene = this.scenes[sceneId]
      if (!scene) return null
      for (const content of Object.values(scene.contents)) {
        if (content.box === boxId) return content
      }
      return null
    }

    public hasAnonymousGuest (sceneId: string): boolean {
      if (!this.isSceneSourceType(sceneId, SceneSourceType.TalentAnonymous)) return false
      const scene = this.scenes[sceneId]
      const contents = this.getSceneContents(sceneId, [AssetType.Caster], false)
      for (const content of Object.values(contents)) {
        const boxId = content.box
        if (!boxId || boxId === 'none' || !scene?.scene_layout.boxes[boxId]) continue
        if (!content.user || content.user.startsWith(ANONYMOUS_GUEST_ID_PLACEHOLDER_PREFIX)) continue
        return true
      }
      return false
    }

    public anonymousGuestsCount (sceneId: string): number {
      const contents = this.getSceneContents(sceneId, [AssetType.Caster], false)
      let nrGuests = 0
      for (const content of Object.values(contents)) {
        if (!content.user || content.user.startsWith(ANONYMOUS_GUEST_ID_PLACEHOLDER_PREFIX)) continue
        nrGuests = nrGuests + 1
      }
      return nrGuests
    }


    get hasFreeGuestSlots (): boolean {
      for (const sceneId of Object.keys(this.scenes)) {
        if (!this.isSceneSourceType(sceneId, SceneSourceType.TalentAnonymous)) continue
        if (this.hasAnonymousGuest(sceneId)) continue
        return true
      }
      return false
    }

    private replaceContentAndCopyMute (
      contents: KeyDict<SceneContent>, oldContentId: string, newContentId: string, newContent: SceneContent
    ): void {
      const oldContent = contents[oldContentId]
      if (oldContent) {
        delete contents[oldContentId]
        if (oldContent.muted !== undefined) newContent.muted = oldContent.muted
        newContent.order = oldContent.order
      }
      contents[newContentId] = newContent
    }

    private replaceScenePresetMutes (sceneId: string, oldContentId: string, newContentId: string) {
      const scenePreset = this.getScenePresetBySceneId(sceneId)
      if (!scenePreset) return
      const oldPresetContentMute = scenePreset.contents[oldContentId]?.muted
      if (oldPresetContentMute === undefined) return
      // FIXME: Contents can contain partial SceneContent, but type is currently defined as if it will always contain
      //        full SceneContent objects.
      scenePreset.contents[newContentId] = { muted: oldPresetContentMute } as SceneContent
      delete scenePreset.contents[oldContentId]
    }

    public isCasterAudioEnabled (contentId: string, sceneId: string): boolean {
      const scene = this.scenesWithPresets[sceneId]
      if (scene === undefined) return false
      for (const content of Object.values(scene.contents)) {
        if (content.type === AssetType.Caster && content.id === contentId) {
          return !content.muted
        }
      }
      return false
    }

    public isCasterBoxVisible (contentId: string, sceneId: string): boolean {
      const scene = this.scenesWithPresets[sceneId]
      if (scene === undefined) return false
      for (const content of Object.values(scene.contents)) {
        const boxName = content.box
        if (boxName !== 'none' && content.id === contentId) {
          return scene.scene_layout.boxes[boxName]?.active ?? false
        }
      }
      return false
    }

    public replaceCasterContent (params: {
      sceneId: string, boxId: string, oldContentId: string, casterId: string, casterName?: string,
      newContentId?: string
    }): string {
      const now = Date.now()
      const newContentId = params.newContentId ?? generateRandomString(20)
      for (const [loopSceneId, loopScene] of Object.entries(this.scenes)) {
        const isCurrentSource = loopSceneId === params.sceneId
        const newContent: SceneContent = {
          active: true,
          box: isCurrentSource ? params.boxId : 'none',
          contentType: SceneContentType.Content,
          id: newContentId,
          muted: !isCurrentSource,
          name: params.casterName ?? '',
          order: 1,
          timestamp: now,
          type: AssetType.Caster,
          user: params.casterId
        }
        this.replaceContentAndCopyMute(loopScene.contents, params.oldContentId, newContentId, newContent)
        this.replaceScenePresetMutes(loopSceneId, params.oldContentId, newContentId)
      }
      return newContentId
    }

    public getSceneCastId (sceneId: string): string|null {
      const content: SceneContentCast|null = Object
        .values(this.getSceneContents(sceneId, [AssetType.Cast], false))
        .find((content): content is SceneContentCast => isSceneContentCast(content)) ?? null
      return content?.castId ?? null
    }

    public getAllSceneCastIds () {
      return this.getAllScenesWithSourceType([SceneSourceType.Cast])
        .map((scene) => this.getSceneCastId(scene.id))
        .filter((castId): castId is string => !!castId)
    }

    public setLastCutTime (cutTime: Date|null) {
      this.lastCutTime = cutTime
      return this
    }

    public isSceneAutoClipEnabled (sceneId: string): boolean {
      return this.getSceneSourceType(sceneId) === SceneSourceType.Cast
    }

    public isSceneSwitchLogEnabled (sceneId: string): boolean {
      return this.getSceneSourceType(sceneId) === SceneSourceType.Cast
    }
  }
}

export type HasScenes = Mixin<typeof hasScenes>

export const hasScenesMixin = (obj: unknown): obj is HasScenes => {
  if (typeof obj !== 'object' || obj === null) return false
  if ('__hasScenes' in obj) return true
  return false
}

export const constructsScenesMixin = (func: unknown): func is GConstructor<HasScenes> => {
  if (typeof func !== 'function' || func === null) return false
  if ('__constructsHasScenes' in func) return true
  return false
}
